import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { fetchNutrientByIds } from "apis/CATALOGUE";
import { useReferenceListsItems } from "hooks";
import { NutrientItemDictionary } from "components/SpecificationSection/components/FormSchema/components/NutritionBasic/types";
import { useAppDispatch } from "store";
import { selectLanguageCode } from "store/user/selectors";
import { updateCatalogDictionary } from "store/generalData/asyncActions";
import {
  collectMandatoryNutrients,
  createNutritionDictionary,
  prepareNutritionTreeData,
} from "../../../utils";
import { isListEmpty, isObjectEmpty } from "utils/general";
import { Dictionary } from "types/general";
import { getNutrientsForCatalogDictionary } from "utils/nutrition";
import {
  NutrientFamilyData,
  NutritionFormData,
  NutrientItemData,
} from "models";

export type UseNutritionTableProps = {
  formData: NutritionFormData;
  reFetchNutrients?: boolean;
};

const useNutritionTable = ({
  formData,
  reFetchNutrients,
}: UseNutritionTableProps) => {
  const [unitOfMeasureIds, setUnitOfMeasureIds] = useState<string[]>([]);
  const [existingNutrientIds, setExistingNutrientIds] = useState<string[]>([]);
  const [nutrientDictionary, setNutrientDictionary] = useState<
    Dictionary<NutrientItemDictionary | string>
  >({});
  const [mandatoryNutrientIds, setMandatoryNutrientIds] = useState<string[]>(
    []
  );
  const [
    mandatoryNutrientIdsIncludingParentsWithMandatoryChild,
    setMandatoryNutrientIdsIncludingParentsWithMandatoryChild,
  ] = useState<string[]>([]);
  const [nutrients, setNutrients] = useState<NutrientFamilyData[]>([]);

  const dispatch = useAppDispatch();

  const languageCode = useSelector(selectLanguageCode);

  const { refListDictionary: unitOfMeasuresRefList } = useReferenceListsItems({
    refListIds: unitOfMeasureIds,
  });

  const fetchExistingNutrients = async (nutrientIds: string[]) => {
    try {
      const { data: nutrients } = await fetchNutrientByIds({
        nutrientIds,
        languageCode,
      });
      setNutrients(nutrients);
      setNutrientDictionary(prev => {
        return { ...prev, ...createNutritionDictionary(nutrients) };
      });

      dispatch(
        updateCatalogDictionary(getNutrientsForCatalogDictionary(nutrients))
      );

      const {
        mandatoryNutrientIdsIncludingParentsWithMandatoryChild,
        mandatoryNutrientIds,
      } = collectMandatoryNutrients(nutrients);

      setMandatoryNutrientIdsIncludingParentsWithMandatoryChild(
        mandatoryNutrientIdsIncludingParentsWithMandatoryChild
      );
      setMandatoryNutrientIds(mandatoryNutrientIds);
    } catch (_) {}
  };

  useEffect(() => {
    if (!isObjectEmpty(formData)) {
      const nutrientIds = formData?.nutrients?.map(
        ({ nutrientId }) => nutrientId
      );

      const unitOfMeasureIds = formData?.nutrients?.map(({ unitId }) => unitId);

      setUnitOfMeasureIds(unitOfMeasureIds);
      setExistingNutrientIds(nutrientIds);
    }
  }, [JSON.stringify(formData?.nutrients)]);

  useEffect(() => {
    if (!isListEmpty(existingNutrientIds) || reFetchNutrients) {
      fetchExistingNutrients(existingNutrientIds);
    }
  }, [
    JSON.stringify(unitOfMeasureIds),
    JSON.stringify(existingNutrientIds),
    reFetchNutrients,
  ]);

  useEffect(() => {
    setNutrientDictionary(prev => {
      return { ...prev, ...unitOfMeasuresRefList };
    });
  }, [unitOfMeasuresRefList]);

  const additionalNutrientData = formData?.nutrients?.reduce((acc, cur) => {
    return [
      ...acc,
      {
        nutrientId: cur.nutrientId,
        unroundedValue: cur.unroundedValue ?? null,
        unitId: cur.unitId,
      },
    ];
  }, []);

  const nutrientsWithoutFamily: NutrientItemData[] = useMemo(() => {
    return nutrients.reduce((acc, cur) => {
      acc = [...acc, ...(cur?.nutrients || [])];
      return acc;
    }, []);
  }, [nutrients]);

  const dataSource = useMemo(() => {
    if (isListEmpty(formData?.nutrients)) {
      return [];
    }

    return (
      prepareNutritionTreeData({
        nutrients,
        additionalNutrientData,
        mandatoryNutrientIds: mandatoryNutrientIdsIncludingParentsWithMandatoryChild,
      }) || []
    );
  }, [
    nutrients,
    additionalNutrientData,
    mandatoryNutrientIdsIncludingParentsWithMandatoryChild,
  ]);

  return {
    dataSource,
    existingNutrientIds,
    nutrientDictionary,
    mandatoryNutrientIds,
    nutrients,
    nutrientsWithoutFamily,
  };
};

export default useNutritionTable;
