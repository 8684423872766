import { useMemo } from "react";
import { useIntl } from "react-intl";
import { Col, Row } from "antd";
import classNames from "classnames";
import { Button, Typography } from "@trace-one/design-system";
import SelectItem from "components/Library/components/SelectItem";
import { getLinkedComponentId } from "../../utils";
import { useLinksDetailsContext } from "contexts/LinksDetailsContext";
import { packagingMessages } from "messages";
import { UnsavedComponentLinkViewModel } from "viewModels";
import styles from "../../styles.module.less";

export type LinkItemProps = {
  componentLink: UnsavedComponentLinkViewModel;
};

const LinkItem: React.FC<LinkItemProps> = ({ componentLink }) => {
  const { formatMessage } = useIntl();

  const {
    options: defaultOptions,
    onChangeComponentIdToLink,
    selectedComponentId,
    linkedComponentIds,
    unsavedLinkedComponentIds,
    saving,
    onUnlink,
    isModalContentScrollable,
  } = useLinksDetailsContext();

  const popupContainer = useMemo(
    () =>
      isModalContentScrollable
        ? document.querySelector(".design-system-modal-content")
        : document.body,
    [isModalContentScrollable]
  );

  const idsToFilter = Array.from(
    new Set([selectedComponentId, ...unsavedLinkedComponentIds])
  );

  const options = defaultOptions.filter(
    ({ value }) => !idsToFilter.includes(value)
  );

  const linkedComponentId = getLinkedComponentId({
    componentLink,
    selectedComponentId,
  });

  const showItemAsReadOnly =
    componentLink?.packagingComponentId1 &&
    componentLink?.packagingComponentId2 &&
    linkedComponentIds.includes(linkedComponentId);

  const prefixCls = "links-details-modal";

  return (
    <Row
      gutter={[8, 0]}
      align="middle"
      className={classNames("w-100", styles[`${prefixCls}__link-item`])}
    >
      <Col
        span={15}
        data-testid={
          showItemAsReadOnly ? "linked-component" : "select-component-to-link"
        }
      >
        {showItemAsReadOnly ? (
          <Typography
            variant="body-regular"
            color="grey-5"
            className="pt-1 pb-1"
            data-test-id="linked-component"
          >
            {
              defaultOptions.find(({ value }) => value === linkedComponentId)
                ?.label
            }
          </Typography>
        ) : (
          <SelectItem
            options={options}
            onChange={onChangeComponentIdToLink(componentLink?.id)}
            placeholder={formatMessage(packagingMessages.selectAComponent)}
            getPopupContainer={() => popupContainer}
            dropdownClassName={
              styles[`${prefixCls}__link-item__select-component`]
            }
            data-test-id="select-component-to-link"
          />
        )}
      </Col>
      <Col span={9}>
        <Button
          type="tertiary"
          color="red"
          size="small"
          iconName="trash"
          onClick={() => onUnlink(componentLink?.id)}
          disabled={saving}
          data-testid="remove-link"
        />
      </Col>
    </Row>
  );
};

export default LinkItem;
