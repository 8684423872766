import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useIntl } from "react-intl";
import { useRouteMatch } from "react-router-dom";
import { Form } from "antd";
import { RcFile } from "antd/lib/upload";
import { isEqual } from "lodash";
import { Alert } from "@trace-one/design-system";
import ClaimGeneralInfo from "./components/ClaimGeneralInfo";
import ClaimConfigure from "./components/ClaimConfigure";
import DeleteAndKeepDraftModal from "../DeleteAndKeepDraftModal";
import { useClaimFormActions, useClaimFormInitialization } from "./hooks";
import { useLibraryFormActions } from "components/Library/hooks";
import { useErrorStatusCheck, useQuery } from "hooks";
import {
  prepareClaimFormData,
  prepareClaimFormDataForComparison,
} from "dtos/claim";
import {
  initialClaimForm,
  selectClaimForm,
  setClaimFormData,
  setClaimFormFile,
} from "store/claimForm/claimFormSlice";
import { useAppDispatch } from "store";
import { ClaimFormProvider } from "./contexts/ClaimFormContext";
import { MAX_TEXTAREA_LENGTH, ROUTES } from "utils/constants";
import libraryMessages from "messages/library";
import {
  isLibraryObjectCreatePage,
  isLibraryObjectEditPage,
} from "utils/library";
import { isClaimTypeHealth } from "utils/claim";
import {
  getConfirmationModalProps,
  getIsPrimaryButtonDisabled,
  shouldDisplayConfirmationModal,
} from "./utils";
import { LibraryItemDetails } from "pages/Library/hocs/withLibraryItemDetails/types";
import { LibraryPageType } from "types/library";

export interface ClaimFormProps {
  updateActionsInformation?: Function;
  updateSecondaryActionInformation?: Function;
  page: LibraryPageType;
  initialValues?: LibraryItemDetails;
}

const ClaimForm: React.FC<ClaimFormProps> = ({
  updateActionsInformation,
  updateSecondaryActionInformation,
  page,
  initialValues,
}) => {
  const [form] = Form.useForm();
  const { formatMessage } = useIntl();

  const isNewVersionPage = useRouteMatch(ROUTES.LIBRARY.CREATE_NEW_VERSION)
    ?.isExact;

  const query = useQuery();
  const previousVersionId = query.get("prevVersionId");

  const [logoFile, setLogoFile] = useState<RcFile>(null);
  const [rawClaimData, setRawClaimData] = useState(initialValues);

  const claimForm = useSelector(selectClaimForm);
  const { generalInfo, sentenceTranslations, regulation } = claimForm;

  const [previousClaimData, setPreviousClaimData] = useState(
    prepareClaimFormData({
      libraryItemDetails: initialValues,
      shouldSaveTagAsArrayOfString: true,
      regulation,
    })
  );

  useEffect(() => {
    if (regulation) {
      setPreviousClaimData(
        prepareClaimFormData({
          libraryItemDetails: rawClaimData,
          shouldSaveTagAsArrayOfString: true,
          regulation,
        })
      );
    }
  }, [regulation]);

  const { isDesiredErrorStatus: isConflictErrorStatus } = useErrorStatusCheck({
    statusCode: 412,
    scrollToTopOfPage: true,
  });

  const dispatch = useAppDispatch();

  const claimBaseRequiredFields = ["name", "productTypeId", "claimType"];
  const claimRequiredFields = isClaimTypeHealth(generalInfo.claimType)
    ? [...claimBaseRequiredFields, "regulatoryClaimId"]
    : claimBaseRequiredFields;

  const hasClaimDataChanged = useMemo(() => {
    return !isEqual(
      previousClaimData,
      prepareClaimFormDataForComparison(claimForm)
    );
  }, [previousClaimData, claimForm]);

  const state = initialValues?.state;

  const isClaimEmptyIfRequired = useMemo(() => {
    if (isClaimTypeHealth(generalInfo?.claimType)) {
      return !generalInfo?.regulatoryClaimId;
    }

    const translationsAreValid = sentenceTranslations.every(item => {
      const trimmedText = item.text.trim();
      return trimmedText !== "" && trimmedText.length <= MAX_TEXTAREA_LENGTH;
    });

    return !translationsAreValid;
  }, [claimForm, form]);

  const { isLoading } = useClaimFormInitialization({
    initialValues: rawClaimData,
    form,
    page,
  });

  const { areActionButtonsDisabled, onFieldsChange } = useLibraryFormActions({
    requiredFields: claimRequiredFields,
    libraryItem: generalInfo,
    isUpdateInProgress: false,
    disabled: isClaimEmptyIfRequired,
  });

  const {
    onKeepAsDraftFromDropdown,
    onKeepAsDraft,
    onPublish,
    onPublishDraftClaim,
    onSaveClaim,
    onCancelVersionCreation,
    isClaimCreating,
    isClaimPublishing,
    isClaimSaving,
  } = useClaimFormActions({
    claimForm,
    logoFile,
    form,
    setPreviousClaimData,
    setRawClaimData,
    isNewVersionPage,
    previousVersionId,
  });

  const actionButtonItems =
    isLibraryObjectCreatePage(page) || isNewVersionPage
      ? [
          {
            name: formatMessage(libraryMessages.publishButton),
            label: formatMessage(libraryMessages.publishButton),
            onClick: onPublish,
          },
          {
            name: formatMessage(libraryMessages.keepAsDraftButton),
            label: formatMessage(libraryMessages.keepAsDraftButton),
            onClick: onKeepAsDraftFromDropdown,
          },
        ]
      : null;

  useEffect(() => {
    return () => {
      dispatch(setClaimFormData(initialClaimForm));
      dispatch(setClaimFormFile(initialClaimForm.file));
    };
  }, []);

  useEffect(() => {
    const isPrimaryActionDisabled = getIsPrimaryButtonDisabled({
      areActionButtonsDisabled,
      isLoading,
      isClaimPublishing,
      hasClaimDataChanged,
      isNewVersionPage,
    });

    updateActionsInformation({
      props: {
        disabled: isPrimaryActionDisabled,
        items: actionButtonItems,
        loading: isClaimSaving,
      },
      onClick: isNewVersionPage ? () => {} : onSaveClaim,
    });

    if (isLibraryObjectEditPage(page)) {
      const isSecondaryActionDisabled =
        areActionButtonsDisabled || isLoading || isClaimSaving;

      updateSecondaryActionInformation({
        props: {
          disabled: isSecondaryActionDisabled,
          loading: isClaimPublishing,
        },
        onClick: onPublishDraftClaim,
      });
    }
  }, [
    areActionButtonsDisabled,
    logoFile,
    claimForm,
    hasClaimDataChanged,
    isClaimPublishing,
    isClaimSaving,
    isLoading,
  ]);

  const isConfirmationModalOpen = shouldDisplayConfirmationModal({
    page,
    hasClaimDataChanged,
    isNewVersionPage,
  });

  const modalProps = getConfirmationModalProps({
    claimId: generalInfo?.id,
    page,
    isNewVersionPage,
    isClaimCreating,
    onKeepAsDraft,
    onCancelVersionCreation,
    formatMessage,
  });

  return (
    <ClaimFormProvider
      value={{ setLogoFile, form, isLoading, isNewVersionPage }}
    >
      {isConflictErrorStatus && (
        <Alert
          type="warning"
          showIcon={true}
          closable={false}
          description={formatMessage(libraryMessages.conflictAlert, {
            libraryObjectType: formatMessage(libraryMessages.claim),
          })}
        />
      )}

      <Form
        name="claimForm"
        layout="vertical"
        form={form}
        initialValues={generalInfo}
        onFieldsChange={onFieldsChange}
        validateMessages={{
          required: formatMessage(libraryMessages.formRequired),
          whitespace: formatMessage(libraryMessages.formRequired),
          string: {
            max: formatMessage(libraryMessages.formMaxCharLength, {
              max: MAX_TEXTAREA_LENGTH,
            }),
          },
        }}
      >
        <ClaimGeneralInfo form={form} page={page} state={state} />
        <ClaimConfigure
          claimType={generalInfo?.claimType}
          productTypeId={generalInfo?.productTypeId}
          state={state}
        />
      </Form>

      {isConfirmationModalOpen && (
        <DeleteAndKeepDraftModal {...modalProps} shouldScrollToTop={false} />
      )}
    </ClaimFormProvider>
  );
};

export default ClaimForm;
