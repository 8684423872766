import { useContext } from "react";
import { Col, Row } from "antd";
import classnames from "classnames";
import { Datatable } from "@trace-one/design-system";
import { LibraryContext } from "../../contexts";
import { useColumns } from "./hooks";
import { getRowKeyForTable } from "utils/table";
import { getOnTableChange } from "./utils";
import styles from "./styles.module.less";

const LibraryListView = () => {
  const {
    areItemsClickable,
    libraryList,
    orderBy,
    orderByDescending,
    setOrderBy,
    setOrderByDescending,
    type,
    typeDictionary,
    displayActions,
  } = useContext(LibraryContext);
  const columns = useColumns({
    areItemsClickable,
    orderBy,
    orderByDescending,
    type,
    typeDictionary,
    displayActions,
  });

  return (
    <Row gutter={[0, 30]} data-test-id="library-list">
      <Col xs={24}>
        <Datatable
          tableLayout="fixed"
          columns={columns}
          dataSource={libraryList}
          pagination={false}
          onChange={getOnTableChange({ setOrderBy, setOrderByDescending })}
          rowClassName={classnames(areItemsClickable && styles.isClickable)}
          rowKey={getRowKeyForTable("library")}
        />
      </Col>
    </Row>
  );
};

export default LibraryListView;
