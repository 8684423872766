import { useEffect, useState } from "react";
import {
  fetchChapterVersions,
  fetchClaimVersions,
  fetchRequirementVersions,
} from "apis/SPEC";
import useBoolean from "hooks/useBoolean";
import { LIBRARY_OBJECT_TYPES } from "utils/constants";
import { getLibraryItemApiParams } from "utils/library";
import { LibraryItemApiType } from "types/library";
import { GeneralInfoData, LibraryItemVersionData } from "models";

export type UseLibraryItemVersionHistoryProps = {
  libraryItemId: GeneralInfoData["id"];
  libraryItemType: LibraryItemApiType;
};

const FetchApiMapping = {
  [LIBRARY_OBJECT_TYPES.PRODUCTION]: fetchChapterVersions,
  [LIBRARY_OBJECT_TYPES.TESTING]: fetchChapterVersions,
  [LIBRARY_OBJECT_TYPES.FINISHED_PRODUCTS]: fetchChapterVersions,
  [LIBRARY_OBJECT_TYPES.TEXT_REQUIREMENTS]: fetchRequirementVersions,
  [LIBRARY_OBJECT_TYPES.DOCUMENT_REQUIREMENTS]: fetchRequirementVersions,
  [LIBRARY_OBJECT_TYPES.MARKETING_CLAIMS]: fetchClaimVersions,
  [LIBRARY_OBJECT_TYPES.HEALTH_CLAIMS]: fetchClaimVersions,
};

const useLibraryItemVersionHistory = ({
  libraryItemId,
  libraryItemType,
}: UseLibraryItemVersionHistoryProps) => {
  const [versionHistoryList, setVersionHistoryList] = useState<
    LibraryItemVersionData[]
  >([]);
  const [activeLibraryItemId, setActiveLibraryItemId] = useState(null);

  const {
    value: loadingVersions,
    setTrue: setLoadingVersionsTrue,
    setFalse: setLoadingVersionsFalse,
  } = useBoolean(false);

  const getVersionHistoryList = async () => {
    try {
      setLoadingVersionsTrue();

      const { data } = await FetchApiMapping[libraryItemType](
        getLibraryItemApiParams({ libraryItemId, libraryItemType })
      );

      setActiveLibraryItemId(
        data?.find(({ version }) => version.activeVersion)?.id
      );
      setVersionHistoryList(data);
    } catch {
    } finally {
      setLoadingVersionsFalse();
    }
  };

  useEffect(() => {
    if (libraryItemId && libraryItemType) {
      getVersionHistoryList();
    }
  }, [libraryItemId, libraryItemType]);

  return {
    loadingVersions,
    versionHistoryList,
    activeLibraryItemId,
    getVersionHistoryList,
    total: versionHistoryList.length,
  };
};

export default useLibraryItemVersionHistory;
