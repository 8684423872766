import React from "react";
import { CompanySwitcherProps } from "./types";
import classnames from "classnames";
import {
  ConditionalEllipsisWithTooltip,
  Typography,
  Divider,
  Icon,
  Spinner,
  Illustration,
} from "@trace-one/design-system";
import { getPrefixClassName } from "src/utils/general";
import { FormattedMessage } from "react-intl";
import { askAppAccessMessages } from "src/components/AppSwitcher/translations/messages";
import useCompanySwitcher from "../../hooks/useCompanySwitcher";

const CompanySwitcher: React.FC<CompanySwitcherProps> = ({
  selectedCompanyId,
  groupList,
  owningCompanyId,
  companyGroupId,
  owningCompanyName,
  onCompanySwitch,
}) => {
  const prefixClassName = getPrefixClassName("app-switcher");
  const companyDisplayName =
    owningCompanyId === selectedCompanyId
      ? owningCompanyName
      : groupList.find(({ companyId }) => companyId === selectedCompanyId)
        ?.companyDisplayName;
  const { loading, companyGroupDetails } = useCompanySwitcher({
    companyGroupId,
  });

  if (loading) {
    return (
      <div className={`${prefixClassName}-companySwitcher-spinner`}>
        <Spinner size="medium" />
      </div>
    );
  }

  return (
    <div className={`${prefixClassName}-companySwitcher`}>
      <div className={`${prefixClassName}-companySwitcher-heading`}>
        <div
          data-test-id="companySwitcher-heading-logo"
          className={`${prefixClassName}-companySwitcher-heading-logo`}
        >
          <Illustration size={32} name="supplier" />
        </div>
        <div
          className={`${prefixClassName}-companySwitcher-heading-info`}
          data-test-id="companySwitcher-heading-info"
        >
          <ConditionalEllipsisWithTooltip text={companyDisplayName}>
            <Typography variant="body-s">
              <FormattedMessage {...askAppAccessMessages.loggedInAs} />{" "}
              {companyDisplayName}
            </Typography>
          </ConditionalEllipsisWithTooltip>
          <ConditionalEllipsisWithTooltip text={companyGroupDetails?.groupName}>
            <Typography variant="body-xs">
              <FormattedMessage {...askAppAccessMessages.linkedTo} />{" "}
              {companyGroupDetails?.groupName}
            </Typography>
          </ConditionalEllipsisWithTooltip>
        </div>
      </div>
      <Divider color="grey-3" height={16} />
      <ul className={`${prefixClassName}-companySwitcher-companylist`}>
        {groupList.map(company => (
          <li
            key={company.companyId}
            className={classnames(
              `${prefixClassName}-companySwitcher-companylist-item`,
              {
                selectedItem: company.companyId === selectedCompanyId,
              }
            )}
          >
            <Typography
              {...(onCompanySwitch ? { onClick: () => onCompanySwitch(company) } : {})}
              data-test-id={`companyList-item-${company.companyDisplayName}`}
              variant="link-m"
            >
              {company.companyDisplayName}
            </Typography>
            {company.companyId === selectedCompanyId && (
              <Icon name="check" size="small" color="primary" />
            )}
          </li>
        ))}
      </ul>
      {selectedCompanyId !== owningCompanyId && (
        <>
          <Divider color="grey-3" dashed />
          <div className={`${prefixClassName}-companySwitcher-footer`}>
            <Icon name="exit" size="small" color="primary" />
            <Typography
              {...(onCompanySwitch ? { onClick: () => onCompanySwitch({ companyId: owningCompanyId }) } : {})}
              data-test-id="companyList-log-back"
              variant="link-m"
            >
              <FormattedMessage {...askAppAccessMessages.logBackAs} />{" "}
              {owningCompanyName}
            </Typography>
          </div>
        </>
      )}
    </div>
  );
};

export default CompanySwitcher;
