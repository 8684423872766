import { useContext } from "react";
import { Checkbox } from "@trace-one/design-system";
import { AllergensArrayFieldTemplateContext } from "components/SpecificationSection/components/FormSchema/templates/ArrayFieldTemplate/AllergensArrayFieldTemplate/contexts";
import { RiskProps } from "./types";

const Risk: React.FC<RiskProps> = ({
  allergenId,
  areActionsDisabled,
  value,
}) => {
  const { saveTemplateBlock, formData } = useContext(
    AllergensArrayFieldTemplateContext
  );

  return (
    <Checkbox
      checked={value}
      onChange={event => {
        const items = formData.map(item => {
          if (item.allergenId === allergenId) {
            return {
              ...item,
              controlledRisk: event.target.checked,
            };
          }
          return item;
        });

        saveTemplateBlock(JSON.stringify({ items }));
      }}
      disabled={areActionsDisabled}
    />
  );
};

export default Risk;
