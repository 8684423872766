import { QuickViewTradeItemData, SpecificationQuickViewData } from "models";
import { ReactNode } from "react";

export enum QuickViewType {
  SPEC = "spec",
  FOLDER = "folder",
  CHAPTER = "chapter",
  REQUIREMENT = "requirement",
  CLAIM = "claim",
}

export interface SpecificationQuickViewProps {
  type: QuickViewType;
  header: (length: number, sectionNumber?: number) => ReactNode;
  body: (
    items: SpecificationQuickViewData[] | QuickViewTradeItemData[]
  ) => ReactNode;
  fetcher: Function;
  shouldRefetch?: boolean;
}
