import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useIntl } from "react-intl";
import { ConditionalEllipsisWithTooltip } from "@trace-one/design-system";
import { fetchSpecificationHistoryChange } from "apis/SPEC";
import { selectLanguageCode } from "store/user/selectors";
import { getHistoryChangesData, getSelectedFiltersQueryParams } from "./utils";
import { columnMessages } from "../../messages";
import { SpecificationData, SpecificationHistoryChange } from "models";
import { HistorisationFilters } from "components/SpecificationHistorisationModal/types";
import { usePrevious, useTableInfiniteScroll } from "hooks";
import { isObjectEmpty } from "utils/general";
import { Dictionary } from "types/general";
import { formatDateToDDMMYYWithTimeKKMM } from "utils/date";

const useSpecificationHistorisation = ({
  specificationId,
  tableContent,
  selectedFilters,
  actionsDictionary,
}: {
  specificationId: SpecificationData["id"];
  tableContent: Element;
  selectedFilters: HistorisationFilters;
  actionsDictionary: Dictionary;
}) => {
  const { formatMessage } = useIntl();
  const languageCode = useSelector(selectLanguageCode);

  const [
    specificationHistoryChanges,
    setSpecificationHistoryChanges,
  ] = useState<SpecificationHistoryChange[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [canLoadMore, setCanLoadMore] = useState(true);
  const [resetApiCall, setResetApiCall] = useState(Symbol());

  const { currentPage, scrollToTop, setCurrentPage } = useTableInfiniteScroll({
    tableContent,
    canLoadMore,
  });

  const previousFilters = usePrevious(selectedFilters);

  const columns = [
    {
      dataIndex: "chapterName",
      title: formatMessage(columnMessages.chapter),
      ellipsis: {
        showTitle: false,
      },
      render: name => (
        <ConditionalEllipsisWithTooltip text={name}>
          {name}
        </ConditionalEllipsisWithTooltip>
      ),
    },
    {
      dataIndex: "sectionName",
      title: formatMessage(columnMessages.section),
      ellipsis: {
        showTitle: false,
      },
      render: name => (
        <ConditionalEllipsisWithTooltip text={name}>
          {name}
        </ConditionalEllipsisWithTooltip>
      ),
    },
    {
      dataIndex: "fieldName",
      title: formatMessage(columnMessages.field),
      ellipsis: {
        showTitle: false,
      },
      render: name => (
        <ConditionalEllipsisWithTooltip text={name}>
          {name}
        </ConditionalEllipsisWithTooltip>
      ),
    },
    {
      dataIndex: "action",
      title: formatMessage(columnMessages.action),
      ellipsis: {
        showTitle: false,
      },
      render: actionType => (
        <ConditionalEllipsisWithTooltip text={actionsDictionary[actionType]}>
          {actionsDictionary[actionType]}
        </ConditionalEllipsisWithTooltip>
      ),
    },
    {
      dataIndex: "oldValue",
      title: formatMessage(columnMessages.lastValue),
      ellipsis: {
        showTitle: false,
      },
      render: name => (
        <ConditionalEllipsisWithTooltip text={name}>
          {name}
        </ConditionalEllipsisWithTooltip>
      ),
    },
    {
      dataIndex: "newValue",
      title: formatMessage(columnMessages.newValue),
      ellipsis: {
        showTitle: false,
      },
      render: name => (
        <ConditionalEllipsisWithTooltip text={name}>
          {name}
        </ConditionalEllipsisWithTooltip>
      ),
    },
    {
      dataIndex: "modifiedByUserName",
      title: formatMessage(columnMessages.userName),
      ellipsis: {
        showTitle: false,
      },
      render: name => (
        <ConditionalEllipsisWithTooltip text={name}>
          {name}
        </ConditionalEllipsisWithTooltip>
      ),
    },
    {
      dataIndex: "modifiedAtUtc",
      title: formatMessage(columnMessages.modificationDate),
      ellipsis: {
        showTitle: false,
      },
      render: modifiedAtUtc => (
        <ConditionalEllipsisWithTooltip
          text={
            modifiedAtUtc &&
            formatDateToDDMMYYWithTimeKKMM(modifiedAtUtc, false)
          }
        >
          {modifiedAtUtc &&
            formatDateToDDMMYYWithTimeKKMM(modifiedAtUtc, false)}
        </ConditionalEllipsisWithTooltip>
      ),
    },
  ];

  const getHistoryChanges = async () => {
    if (canLoadMore) {
      try {
        setIsLoading(true);

        const {
          modifiedByUserIds,
          actions,
          formattedDates,
          chapters,
        } = getSelectedFiltersQueryParams(selectedFilters);

        const { data } = await fetchSpecificationHistoryChange({
          specificationId,
          params: {
            take: 10,
            skip: (currentPage - 1) * 10,
            languageCode,
            fromDate: formattedDates[0],
            toDate: formattedDates[1],
          },
          bodyParams: {
            modifiedByUserIds,
            actions,
            chapters,
          },
        });

        const { items, totalItems } = getHistoryChangesData(data);

        let newItemsLength = items.length;

        setSpecificationHistoryChanges(previousState => {
          const newItemsList =
            currentPage > 1 ? [...previousState, ...items] : [...items];

          newItemsLength = newItemsList.length;

          return newItemsList;
        });

        setCanLoadMore(totalItems > newItemsLength);
      } catch (e) {
      } finally {
        setIsLoading(false);
      }
    }
  };

  const resetPagination = () => {
    setCanLoadMore(true);
    setCurrentPage(1);
    scrollToTop();
    setResetApiCall(Symbol());
  };

  useEffect(() => {
    getHistoryChanges();
  }, [currentPage, canLoadMore, resetApiCall]);

  useEffect(() => {
    if (!isObjectEmpty(previousFilters)) {
      resetPagination();
    }
  }, [selectedFilters]);

  return {
    isLoading,
    columns,
    specificationHistoryChanges,
    getHistoryChanges,
    scrollToTop,
  };
};

export default useSpecificationHistorisation;
