import { Row, Col } from "antd";
import { Datatable } from "@trace-one/design-system";
import {
  ArraySchema,
  ObjectFieldTemplateProps,
} from "components/SpecificationSection/types";
import { useHierarchyData } from "hooks";
import { isParentHalfChecked } from "utils/hierarchy";
import ButtonWithModalTree from "components/ButtonWithModalTree";
import RowTitle from "components/RowTitle";
import { useFormSchemaTranslation } from "pages/Specification/components/Details/hooks";
import { FreeFromAllergensArrayFieldTemplateProvider } from "./contexts";
import { getRowKeyForTable, getTableSize } from "utils/table";
import {
  useFreeFromAllergensColumns,
  useFreeFromAllergensActions,
} from "./hooks";
import {
  findPropertyByKey,
  getFieldTitleFromUiSchemaObjectTemplate,
} from "utils/general";
import { getDefaultCheckedParents, getDefaultDisabledItems } from "./utils";
import { ChapterFreeFromAllergenFormData } from "models";
import styles from "components/SpecificationSection/components/FormSchema/styles.module.less";

interface FreeFromAllergensArrayFieldTemplateProps
  extends Partial<ObjectFieldTemplateProps<ChapterFreeFromAllergenFormData>> {
  noItemsMessage?: string;
}

const FreeFromAllergensArrayFieldTemplate: React.FC<FreeFromAllergensArrayFieldTemplateProps> = ({
  formContext,
  schema,
  uiSchema,
  noItemsMessage,
}) => {
  const { getTranslationForKey } = useFormSchemaTranslation();
  const {
    displayActions,
    catalogDictionary,
    ownerCompanyId,
    isTargetMode,
    saveTemplateBlock,
    materialData,
    getMaterialData,
    formData: defaultFormData = {},
  } = formContext;

  const formData = defaultFormData as ChapterFreeFromAllergenFormData;
  const defaultCheckedParents = getDefaultCheckedParents(formData.allergens);

  const defaultDisabledItems = getDefaultDisabledItems(
    materialData?.allergenDeclarations || []
  );

  const {
    onAddAllergens,
    onRemoveAllergen,
    onUploadFile,
    onRemoveFile,
  } = useFreeFromAllergensActions({
    saveTemplateBlock,
    formData,
  });

  const allergensSchema = findPropertyByKey(schema, "allergens");

  const { columns } = useFreeFromAllergensColumns({
    uiSchema: uiSchema?.["allergens"] || {},
    schema: allergensSchema as ArraySchema,
    catalogDictionary,
    displayActions,
  });

  const { treeData, isLoading } = useHierarchyData({
    defaultDisabledItems: [...defaultCheckedParents, ...defaultDisabledItems],
    isReadyToFetch: true,
  });

  const treeDataWithDisabledParents = treeData?.map(parent => {
    if (isParentHalfChecked({ children: parent.children, key: "disabled" })) {
      return { ...parent, disabled: true };
    }
    return parent;
  });

  return (
    <FreeFromAllergensArrayFieldTemplateProvider
      onRemoveAllergen={onRemoveAllergen}
      onUploadFile={onUploadFile}
      onRemoveFile={onRemoveFile}
      displayActions={displayActions}
      ownerCompanyId={ownerCompanyId}
    >
      <>
        <RowTitle
          title={getFieldTitleFromUiSchemaObjectTemplate(uiSchema, "allergens")}
          displayActions={displayActions}
        />
        <Datatable
          tableLayout="fixed"
          columns={columns}
          dataSource={formData?.allergens || []}
          pagination={false}
          rowKey={getRowKeyForTable()}
          emptyText={noItemsMessage}
          size={getTableSize(displayActions)}
        />

        {displayActions && !isLoading && !isTargetMode ? (
          <Row justify="center" className={styles.rowAddButton}>
            <Col>
              <ButtonWithModalTree
                modalTitle={getTranslationForKey("freeFromModalTitle")}
                openBtnLabel={getTranslationForKey("addAnAllergen")}
                onConfirmModal={onAddAllergens}
                defaultCheckedItems={defaultCheckedParents}
                defaultDisabledItems={defaultDisabledItems}
                treeData={treeDataWithDisabledParents}
                onOpenModal={getMaterialData}
              />
            </Col>
          </Row>
        ) : null}
      </>
    </FreeFromAllergensArrayFieldTemplateProvider>
  );
};

export default FreeFromAllergensArrayFieldTemplate;
