import { PageHeader } from "@trace-one/design-system";
import { useIntl } from "react-intl";
import { getHeadingTitle } from "components/Library/components/utils";
import { useGoBack } from "hooks";
import { isListEmpty } from "utils/general";
import {
  LIBRARY_OBJECTS_PAGES,
  PACKAGING_COMPONENT_TAB_KEYS,
} from "utils/constants";
import { PackagingSystemComponentObjectData } from "models/packaging";
import { usePackagingComponentNavigation } from "./hooks";
import styles from "./styles.module.less";

export const Edit = ({
  packagingComponentDetails,
}: {
  packagingComponentDetails: PackagingSystemComponentObjectData;
}) => {
  const { formatMessage } = useIntl();
  const { goBack } = useGoBack();

  const { name, sections, version, allowsUpdate } = packagingComponentDetails;

  const {
    activeTab,
    setActiveTab,
    tabItems,
    tabContent,
  } = usePackagingComponentNavigation({ sections, allowsUpdate });

  if (isListEmpty(sections)) {
    return null;
  }

  return (
    <>
      <PageHeader
        title={getHeadingTitle({
          name,
          versionNumber: version?.number,
          page: LIBRARY_OBJECTS_PAGES.EDIT,
          formatMessage,
        })}
        primaryButtonLabel={formatMessage({
          id: "general.close",
        })}
        onPrimaryButtonClick={goBack}
        onBack={goBack}
        tabs={{
          defaultActiveKey: PACKAGING_COMPONENT_TAB_KEYS.BASIC_INFORMATION,
          activeKey: activeTab,
          onTabClick: setActiveTab,
          items: tabItems,
        }}
      />
      <div className={styles.packagingFormContainer}>{tabContent}</div>
    </>
  );
};

export default Edit;
