import { LabeledValue } from "antd/lib/select";
import { HistorisationFilters } from "components/SpecificationHistorisationModal/types";
import { SpecificationHistoryChange } from "models";
import { isListEmpty } from "utils/general";
import { DateType, getDate, parseDate } from "utils/date";

export const prepareSelectedActionsQueryParam = (actions: LabeledValue[]) => {
  return actions.map(action => action.value as string);
};

export const prepareSelectedDatesQueryParam = (dates: DateType[]) => {
  if (dates[0] === null && dates[1] === null) {
    return [null, null];
  }

  const toDateMoment = parseDate(dates[1]);
  let toDateHours = "23:59:59";

  if (toDateMoment.isSame(getDate(), "day")) {
    const newDate = new Date();
    toDateHours = `${newDate.getUTCHours()}:${newDate.getUTCMinutes()}:00`;
  }

  const formattedFromDate =
    parseDate(dates[0]).format("YYYY-MM-DD") + " 00:00:00";
  const formattedToDate = `${toDateMoment.format("YYYY-MM-DD")} ${toDateHours}`;

  return [formattedFromDate, formattedToDate];
};

export const getSelectedFiltersQueryParams = (
  selectedFilters: HistorisationFilters
) => {
  const modifiedUsersIds = selectedFilters.users.map(
    user => user.value as string
  );
  const selectedActionsData = prepareSelectedActionsQueryParam(
    selectedFilters.actions
  );

  const formattedSelectedDates = prepareSelectedDatesQueryParam(
    selectedFilters.dates
  );

  return {
    modifiedByUserIds: modifiedUsersIds,
    actions: selectedActionsData,
    formattedDates: formattedSelectedDates,
    chapters: selectedFilters.chapters,
  };
};

export const getHistoryChangesData = ({
  items,
  totalItems,
}: {
  items?: SpecificationHistoryChange[];
  totalItems?: number;
}) => {
  if (isListEmpty(items)) {
    return { items: [], totalItems: 0 };
  }

  return { items, totalItems };
};
