import qs from "qs";
import { getCertificates } from "./CERTIFICATION";
import { isClaimTypeHealth } from "utils/claim";
import { LibraryItemApiType } from "types/library";
import { CertificateData } from "models/certification";

export const getClaimRoute = (type: LibraryItemApiType) =>
  isClaimTypeHealth(type) ? `${type}/regulation` : type;

export const fetchAllCertificates = async (
  certificateIds: CertificateData["id"][],
  languageCode: string
): Promise<CertificateData[]> => {
  const MAX_CERTIF_IDS_PER_REQUEST = 20;

  let allData: CertificateData[] = [];

  // function to fetch data in chunks
  const fetchDataInChunks = async (params: {
    certificateIds?: CertificateData["id"][];
    searchTerm?: string;
    includeCertification?: boolean;
    includeSharedCertificates?: boolean;
    pageNumber?: number;
    pageSize?: number;
    languageCode?: string;
  }): Promise<void> => {
    const { data } =
      (await getCertificates({
        params,
        paramsSerializer: () => qs.stringify(params, { arrayFormat: "comma" }),
      })) || {};

    allData = allData.concat(data?.items || []);
  };

  // Create an array of promises for each chunk of 20 certificateIds
  const promises = [];
  for (let i = 0; i < certificateIds.length; i += MAX_CERTIF_IDS_PER_REQUEST) {
    const chunk = certificateIds.slice(i, i + MAX_CERTIF_IDS_PER_REQUEST);
    const params = {
      certificateIds: chunk,
      languageCode,
      includeCertification: true,
    };

    promises.push(fetchDataInChunks(params));
  }

  await Promise.all(promises);

  return allData;
};
