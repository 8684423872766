import { useContext } from "react";
import Content from "./components/Content";
import { LibraryDetails } from "../../contexts";
import LibraryItemInfo from "components/Library/components/LibraryItemInfo";

const GeneralInformation = () => {
  const { libraryItemDetails } = useContext(LibraryDetails);

  return (
    <>
      <LibraryItemInfo />

      <Content libraryItemDetails={libraryItemDetails} />
    </>
  );
};

export default GeneralInformation;
