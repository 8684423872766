import {
  isLibraryObjectCreatePage,
  isLibraryObjectEditPage,
} from "utils/library";
import { DeleteAndKeepDraftModalProps } from "../DeleteAndKeepDraftModal/types";
import { claimMessages, generalMessages } from "messages";
import { ClaimData } from "models";
import { LibraryPageType } from "types/library";

export const shouldDisplayConfirmationModal = ({
  page,
  hasClaimDataChanged,
  isNewVersionPage = false,
}: {
  page: LibraryPageType;
  hasClaimDataChanged?: boolean;
  isNewVersionPage?: boolean;
}) =>
  isLibraryObjectCreatePage(page) ||
  isNewVersionPage ||
  (isLibraryObjectEditPage(page) && hasClaimDataChanged);

export const getConfirmationModalProps = ({
  claimId,
  page,
  formatMessage,
  onKeepAsDraft,
  onCancelVersionCreation,
  isClaimCreating = false,
  isNewVersionPage = false,
}: {
  claimId: ClaimData["id"];
  page: LibraryPageType;
  formatMessage: Function;
  onKeepAsDraft: Function;
  onCancelVersionCreation?: Function;
  isClaimCreating?: boolean;
  isNewVersionPage?: boolean;
}): DeleteAndKeepDraftModalProps => {
  let modalProps: DeleteAndKeepDraftModalProps;

  const commonProps = {
    id: claimId,
    primaryButtonText: formatMessage(claimMessages.deleteClaim),
    secondaryButtonText: formatMessage(claimMessages.saveAsDraft),
    primaryButtonProps: { disabled: isClaimCreating },
    secondaryButtonProps: { loading: isClaimCreating },
    onKeepAsDraftAction: onKeepAsDraft,
  };

  if (isLibraryObjectCreatePage(page)) {
    modalProps = {
      ...commonProps,
      title: formatMessage(claimMessages.cancelClaimCreationTitle),
      description: formatMessage(claimMessages.cancelCreationDescription),
      onDeleteAction: null,
    };
  } else if (isNewVersionPage) {
    modalProps = {
      ...commonProps,
      title: formatMessage(claimMessages.cancelVersionCreationTitle),
      description: formatMessage(
        claimMessages.cancelVersionCreationDescription
      ),
      onDeleteAction: onCancelVersionCreation,
    };
  } else if (isLibraryObjectEditPage(page)) {
    modalProps = {
      title: formatMessage(claimMessages.cancelSaveModalTitle),
      description: formatMessage(claimMessages.cancelSaveDescription),
      primaryButtonText: formatMessage(generalMessages.confirm),
      secondaryButtonText: formatMessage(generalMessages.cancel),
      onKeepAsDraftAction: () => {
        // this will hit catch block which prevents leaving the page
        throw Error();
      },
      onDeleteAction: null,
    };
  }

  return modalProps;
};

export const getIsPrimaryButtonDisabled = ({
  areActionButtonsDisabled,
  isLoading,
  isClaimPublishing,
  isNewVersionPage,
  hasClaimDataChanged,
}: {
  areActionButtonsDisabled: boolean;
  isLoading?: boolean;
  isClaimPublishing?: boolean;
  hasClaimDataChanged?: boolean;
  isNewVersionPage?: boolean;
}) =>
  areActionButtonsDisabled ||
  isLoading ||
  isClaimPublishing ||
  (!isNewVersionPage && !hasClaimDataChanged);
