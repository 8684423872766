import { Row, Col } from "antd";
import { Heading, Paragraph } from "@trace-one/design-system";
import VersionBox from "../VersionBox";
import { getPropertiesObjectFromArray } from "../../../../templates/utils";
import { formatDateToDDMMYYYYWithSlash, isValidDate } from "utils/date";
import { VersionWidgetReadOnlyProps } from "./types";
import styles from "./styles.module.less";

const VersionWidgetReadOnly: React.FC<VersionWidgetReadOnlyProps> = ({
  properties,
  formData,
  emptyValue,
  versionNumber,
  creationDateUtc,
  signatureDateUtc,
}) => {
  const propertyObjects = getPropertiesObjectFromArray(properties);

  const productionFields = [
    "firstBatchNumber",
    "firstProductionDateUtc",
    "lastBatchNumber",
    "lastProductionDateUtc",
  ];

  const revisionMotiveValue = formData["revisionMotive"]
    ? formData["revisionMotive"]
    : emptyValue;

  return (
    <>
      <Row gutter={[24, 0]} className={styles.versionContainerReadOnly}>
        <Col xs={7}>
          <VersionBox
            versionNumber={versionNumber}
            creationDateUtc={creationDateUtc}
            signatureDateUtc={signatureDateUtc}
          />
        </Col>
        <Col span={12} className={styles.productionFieldsContainer}>
          {productionFields.map(field => (
            <Row key={field} className={styles.productionFields}>
              <Col>
                <Paragraph size="xs" color="grey-5">
                  {propertyObjects[field].content.props.uiSchema[
                    "ui:title"
                  ].trim()}
                </Paragraph>
              </Col>
              <Col>
                <Paragraph size="xs" color="grey-4">
                  {!!formData[field]
                    ? isValidDate(formData[field]) &&
                      ["firstProductionDateUtc", "lastProductionDateUtc"].some(
                        dateField => dateField === field
                      )
                      ? formatDateToDDMMYYYYWithSlash(formData[field])
                      : formData[field]
                    : emptyValue}
                </Paragraph>
              </Col>
            </Row>
          ))}
        </Col>
      </Row>
      <Row className={styles.revisionMotiveReadOnly}>
        <Col span={24}>
          <Row>
            <Heading size="xxs" color="grey-5">
              {propertyObjects["revisionMotive"].content.props.uiSchema[
                "ui:title"
              ].trim()}
            </Heading>
          </Row>
          <Row>
            <Paragraph
              size="xs"
              color="grey-4"
              dangerouslySetInnerHTML={{ __html: revisionMotiveValue }}
            />
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default VersionWidgetReadOnly;
