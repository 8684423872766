import React from "react";
import { useIntl } from "react-intl";
import { CertificationCardProps } from "./types";
import { Card, Graphic, Heading, Typography } from "@trace-one/design-system";
import { getPrefixClassName } from "../../../../../../utils/general";
import { certificateModalMessages } from "../../../../translations/messages";
import { useCertificationDetails } from "../../../../hooks";
import { useLanguagePreference } from "IndexComponents";

const CertificationCard: React.FC<CertificationCardProps> = ({ certificationId, setNotFoundCertificationError }) => {
  const intl = useIntl();
  const prefixClassName = getPrefixClassName("certificate-modal");
  const languageCode = useLanguagePreference();

  const { certificationDetailsData } = useCertificationDetails(certificationId, setNotFoundCertificationError, languageCode);

  const domains = certificationDetailsData?.domains?.join(", ");
  const objects = certificationDetailsData?.objectLinks?.join(", ");

  return (
      <div className={`${prefixClassName}-certification-card`}>
          <Card
              title={intl.formatMessage(certificateModalMessages.certificationCardTitle)}
              size="small"
              type="default"
              children={
              <>
                  <div
                      className={`${prefixClassName}-certification-card-logo`}
                      data-test-id={`${prefixClassName}-certification-card-logo`}
                  >
                      {certificationDetailsData?.logoFileId !== null
                        ? certificationDetailsData?.logoFileId
                        : <Graphic name="Certificates" size="large"/>
                      }

                  </div>

                    <div
                        className={`${prefixClassName}-certification-card-item`}
                        data-test-id={`${prefixClassName}-certification-card-name`}
                    >
                        <Heading size="xxs">{intl.formatMessage(certificateModalMessages.certificationCardName)}</Heading>
                         <Typography variant="body-regular">{certificationDetailsData?.name}</Typography>
                    </div>

                    <div
                        className={`${prefixClassName}-certification-card-item`}
                        data-test-id={`${prefixClassName}-certification-card-owner`}
                    >
                        <Heading size="xxs">{intl.formatMessage(certificateModalMessages.certificationCardOwner)}</Heading>
                         <Typography variant="body-regular">{certificationDetailsData?.owner}</Typography>
                    </div>

                    <div
                        className={`${prefixClassName}-certification-card-item`}
                        data-test-id={`${prefixClassName}-certification-domain`}
                    >
                        <Heading size="xxs">{intl.formatMessage(certificateModalMessages.certificationCardDomain)}</Heading>
                         <Typography variant="body-regular">{domains}</Typography>
                    </div>

                    <div
                        className={`${prefixClassName}-certification-card-item`}
                        data-test-id={`${prefixClassName}-certification-object`}
                    >
                        <Heading size="xxs">{intl.formatMessage(certificateModalMessages.certificationCardObject)}</Heading>
                         <Typography variant="body-regular">{objects}</Typography>
                    </div>

                    <div
                        className={`${prefixClassName}-certification-card-item`}
                        data-test-id={`${prefixClassName}-certification-description`}
                    >
                        <Heading size="xxs">{intl.formatMessage(certificateModalMessages.certificationCardDescription)}</Heading>
                         <Typography variant="body-regular">{certificationDetailsData?.description}</Typography>
                    </div>
                </>
                }
                data-test-id={`${prefixClassName}-certification-card`}
            ></Card>
        </div>

  );
};
export default CertificationCard;
