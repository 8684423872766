import { useState, useEffect } from "react";
import { useIntl } from "react-intl";
import {
  useLibraryItemVersionHistory,
  useModalVisibility,
  useRowSelection,
} from "hooks";
import { generalMessages } from "messages";
import { LIBRARY_OBJECT_TYPES } from "utils/constants";
import {
  LinkedSpecificationsAction,
  LinkedSpecificationsModalProps,
  ModalActionType,
} from "components/LinkedSpecificationsModal/types";
import { LibraryItemApiType } from "types/library";
import { getLinkedSpecificationsModalActionMap } from "components/Library/components/utils";

const useVersionHistory = ({
  chapterType,
  chapterId,
}: {
  chapterType: string;
  chapterId: string;
}) => {
  const { formatMessage } = useIntl();

  const [selectedChapterId, setSelectedChapterId] = useState(null);
  const {
    selectedRowKeys,
    onSelectRow,
    onSelectAllRows,
    resetSelectedRowKeys,
  } = useRowSelection();
  const [modalProps, setModalProps] = useState<LinkedSpecificationsModalProps>(
    null
  );
  const [actionType, setActionType] = useState<ModalActionType>(null);
  const [
    shouldRefetchVersionHistory,
    setShouldRefetchVersionHistory,
  ] = useState(false);
  const [states, setStates] = useState([]);

  const {
    isModalOpen: isDetailsAndLinksModalOpen,
    onOpenModal,
    onCloseModal,
  } = useModalVisibility();

  const {
    versionHistoryList,
    getVersionHistoryList,
    loadingVersions,
    activeLibraryItemId: activeChapterId,
  } = useLibraryItemVersionHistory({
    libraryItemId: chapterId,
    libraryItemType: chapterType as LibraryItemApiType,
  });

  useEffect(() => {
    if (shouldRefetchVersionHistory && chapterType && chapterId) {
      getVersionHistoryList();
    }
  }, [shouldRefetchVersionHistory]);

  const ActionTypeMap = getLinkedSpecificationsModalActionMap(
    LIBRARY_OBJECT_TYPES.CHAPTERS
  );

  const handleOpenModal = ({
    selectedLibraryItemId,
    actionType,
  }: LinkedSpecificationsAction) => () => {
    setSelectedChapterId(selectedLibraryItemId);
    setModalProps({
      secondaryButtonLabel: formatMessage(generalMessages.cancel),
      onSecondaryButtonClick: handleCloseModal,
    });
    setActionType(actionType);

    const actionTypeData = ActionTypeMap[actionType];

    setStates(actionTypeData.states);
    setModalProps(prevState => ({
      ...prevState,
      title: formatMessage(actionTypeData.title),
      subtitle: formatMessage(actionTypeData.subtitle),
      primaryButtonLabel: formatMessage(actionTypeData.primaryButtonLabel),
    }));

    onOpenModal();
  };

  const handleCloseModal = () => {
    resetSelectedRowKeys();
    onCloseModal();
  };

  const rowSelection = {
    selectedRowKeys,
    onSelect: onSelectRow,
    onSelectAll: onSelectAllRows,
  };

  return {
    versionHistoryList,
    loadingVersions,
    isDetailsAndLinksModalOpen,
    modalProps,
    handleOpenModal,
    handleCloseModal,
    rowSelection,
    actionType,
    selectedChapterId,
    activeChapterId,
    shouldRefetchVersionHistory,
    setShouldRefetchVersionHistory,
    states,
  };
};

export default useVersionHistory;
