import { useEffect, useState } from "react";
import { LabeledValue } from "antd/lib/tree-select";
import { useAppDispatch } from "store";
import { updateCategoryDictionary } from "store/generalData/asyncActions";
import {
  addRawMaterialIngredient,
  addCompositeIngredient,
  updateBoughtIngredientType,
  updateIngredient,
} from "store/materialForm/asyncActions";
import {
  addNewIngredientLine,
  removeLastIngredient,
} from "store/materialForm/materialFormSlice";
import { isObjectEmpty } from "utils/general";
import { MATERIAL_TYPES } from "utils/constants";
import { isMaterialRawMaterialType } from "utils/library";
import { getLastAddedIngredientId } from "./utils";
import { MaterialRecipeIngredient } from "../../types";

const useRecipePanelActions = ({
  onPanelClose,
  initialIngredient,
  onAddExpandedRowKey,
}: {
  onPanelClose: Function;
  initialIngredient?: MaterialRecipeIngredient;
  onAddExpandedRowKey: Function;
}) => {
  const dispatch = useAppDispatch();

  const [updateInProgress, setUpdateInProgress] = useState<boolean>(false);

  const [ingredient, setIngredient] = useState<MaterialRecipeIngredient>({
    type: MATERIAL_TYPES.RAW_MATERIAL,
    percentage: null,
    parentIdsPath: [],
    ...initialIngredient,
  });

  const onSetIngredientName = (name: MaterialRecipeIngredient["name"]) => {
    name = name.trim();
    setIngredient(prevState => ({ ...prevState, name }));
  };

  const onSetClassification = (selectedClassification: LabeledValue) => {
    setIngredient(prevState => ({
      ...prevState,
      ...(selectedClassification?.value
        ? {
            classificationId: selectedClassification?.value as string,
            classificationName: selectedClassification?.label as string,
          }
        : {
            classificationId: null,
            classificationName: null,
          }),
    }));
  };

  const onSetPercentage = (percentage: number) => {
    setIngredient(prevState => ({
      ...prevState,
      percentage,
    }));
  };

  const onSaveIngredient = async () => {
    setUpdateInProgress(true);
    const { classificationName } = ingredient;

    let dictionary = {
      ...(classificationName && {
        [ingredient.classificationId]: classificationName,
      }),
    };

    dispatch(updateCategoryDictionary(dictionary));

    if (ingredient.ingredientId) {
      await dispatch(
        updateIngredient({
          ingredientId: ingredient.ingredientId,
          classificationId: ingredient.classificationId,
          percentage: ingredient.percentage,
          name: ingredient.name,
        })
      );
    } else {
      await dispatch(
        addRawMaterialIngredient({
          percentage: ingredient.percentage,
          name: ingredient.name,
          classificationId: ingredient.classificationId,
          parentIngredientId: ingredient.parentIdsPath.slice(-1)[0],
        })
      );
    }

    onPanelClose();

    setIngredient(prev => {
      return {
        ...prev,
      };
    });
    setUpdateInProgress(false);
  };

  const onConvertToComposite = async () => {
    setUpdateInProgress(true);

    const ingredientProps = {
      type: MATERIAL_TYPES.RAW_MATERIAL,
      percentage: null,
      shouldChangeParentType: true,
    };

    // convert already created RM to composite
    if (ingredient.ingredientId) {
      await dispatch(updateBoughtIngredientType(ingredient.ingredientId));

      const parentIdsPath = [
        ...ingredient.parentIdsPath,
        ingredient.ingredientId,
      ];

      dispatch(addNewIngredientLine({ parentIdsPath }));

      setIngredient({
        parentIdsPath,
        ...ingredientProps,
      });
    } else {
      //create ingredient of type composite
      const data = await dispatch(
        addCompositeIngredient({
          percentage: ingredient.percentage,
          name: ingredient.name,
          parentIngredientId: ingredient.parentIdsPath.slice(-1)[0],
        })
      );

      const parentIdsPath = [
        ...ingredient.parentIdsPath,
        getLastAddedIngredientId(
          // @ts-ignore
          data?.payload?.recipe?.ingredients,
          ingredient.parentIdsPath
        ),
      ];

      dispatch(addNewIngredientLine({ parentIdsPath }));

      setIngredient({
        parentIdsPath,
        ...ingredientProps,
      });
    }
    setUpdateInProgress(false);
  };

  const onAddIngredient = () => {
    const parentIdsPath = [
      ...ingredient.parentIdsPath,
      ingredient.ingredientId,
    ];

    dispatch(addNewIngredientLine({ parentIdsPath }));

    onAddExpandedRowKey(ingredient.ingredientId);

    setIngredient({
      type: MATERIAL_TYPES.RAW_MATERIAL,
      percentage: null,
      parentIdsPath,
    });
  };

  const onClose = async () => {
    await checkIfParentIngredientToUpdateToRM();

    setIngredient(previousState => ({
      ...previousState,
    }));

    if (!ingredient.ingredientId && !ingredient.shouldChangeParentType) {
      dispatch(removeLastIngredient(ingredient.parentIdsPath));
    }

    onPanelClose();
  };

  const checkIfParentIngredientToUpdateToRM = async () => {
    if (ingredient.shouldChangeParentType) {
      await dispatch(
        updateBoughtIngredientType(
          ingredient?.parentIdsPath?.[ingredient.parentIdsPath?.length - 1]
        )
      );
    }
  };

  useEffect(() => {
    if (!isObjectEmpty(initialIngredient)) {
      setIngredient(initialIngredient);
    }
  }, [initialIngredient]);

  const isConvertToCompositeDisabled =
    ingredient?.name && ingredient?.percentage && !updateInProgress
      ? false
      : true;

  const isSaveDisabled = () => {
    if (
      isMaterialRawMaterialType(ingredient.type) &&
      !ingredient?.classificationId
    ) {
      return true;
    }
    return isConvertToCompositeDisabled;
  };

  return {
    onSetIngredientName,
    onSetClassification,
    onSetPercentage,
    onSaveIngredient,
    onConvertToComposite,
    onAddIngredient,
    onClose,
    isSaveDisabled,
    isConvertToCompositeDisabled,
    ingredient,
  };
};
export default useRecipePanelActions;
