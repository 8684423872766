import { Row, Col } from "antd";
import { getPropertiesObjectFromArray } from "../../templates/utils";
import VersionWidgetReadOnly from "./components/VersionWidgetReadOnly";
import VersionBox from "./components/VersionBox";
import Progression from "./components/Progression";
import { VersionWidgetProps } from "./types";

const VersionWidget: React.FC<VersionWidgetProps> = ({
  properties,
  formData,
  formContext,
}) => {
  const propertyObjects = getPropertiesObjectFromArray(properties);

  const {
    displayActions,
    emptyValue,
    specificationData,
    chapterData,
  } = formContext;

  const { chapters, specificationId } = specificationData;

  const {
    creationDateUtc,
    signatureDateUtc,
    progress,
    chapterType,
  } = chapterData;
  const { number: versionNumber } = chapterData?.version;

  const colSpans = {
    xs: displayActions ? 24 : 8,
    sm: 8,
    md: 8,
    lg: 8,
  };

  if (!displayActions) {
    return (
      <VersionWidgetReadOnly
        {...{
          properties,
          formData,
          emptyValue,
          versionNumber,
          creationDateUtc,
          signatureDateUtc,
        }}
      />
    );
  }

  return (
    <>
      <Row gutter={[30, 0]} align="middle">
        <Col {...colSpans}>
          <VersionBox
            versionNumber={versionNumber}
            creationDateUtc={creationDateUtc}
            signatureDateUtc={signatureDateUtc}
          />
        </Col>
        <Col {...colSpans}>
          <div>{propertyObjects?.firstBatchNumber?.content}</div>
          <div>{propertyObjects?.firstProductionDateUtc?.content}</div>
        </Col>
        <Col {...colSpans}>
          <div>{propertyObjects?.lastBatchNumber?.content}</div>
          <div>{propertyObjects?.lastProductionDateUtc?.content}</div>
        </Col>
      </Row>
      <Row>
        <Col span={24}>{propertyObjects?.revisionMotive?.content}</Col>
      </Row>
      <Row>
        <Col span={24}>{propertyObjects?.impactOnLabeling?.content}</Col>
      </Row>
      <hr />
      <Progression
        chapters={chapters}
        progress={progress}
        specificationId={specificationId}
        chapterType={chapterType}
      />
    </>
  );
};

export default VersionWidget;
