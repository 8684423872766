import ImportFile from "components/ImportFile";
import { useImportClaim } from "hooks";
import { LIBRARY_OBJECT_TYPES } from "utils/constants";

export type ImportClaimProps = {
  claimType: LIBRARY_OBJECT_TYPES;
  refreshTypeList?: (fetchCurrentPage?: boolean) => Promise<void>;
};

const ImportClaim: React.FC<ImportClaimProps> = ({
  claimType,
  refreshTypeList,
}) => {
  const {
    onDownloadTemplate,
    onImportConfirmation,
    onDownloadErrorFile,
  } = useImportClaim({
    claimType,
  });

  return (
    <ImportFile
      onDownloadTemplate={onDownloadTemplate}
      onImportConfirmation={onImportConfirmation}
      afterImportCallback={refreshTypeList}
      onDownloadErrorFile={onDownloadErrorFile}
    />
  );
};

export default ImportClaim;
