import { useMemo } from "react";
import { useSelector } from "react-redux";
import useRbac from "hooks/useRbac";
import {
  selectCompanyActivity,
  selectUserPermissions,
} from "store/oidc/selectors";
import { selectUserApplications } from "store/user/selectors";
import { PERMISSIONS, SpecificationContactType } from "utils/constants";
import {
  canAccessFAndV,
  canAccessMasterData,
  getIsRetailer,
  getIsSupplier,
  getIsTranslator,
} from "utils/user";
import { isFAndVOnly } from "utils/general";

const useUserInfo = () => {
  const companyActivity = useSelector(selectCompanyActivity);
  const permissions = useSelector(selectUserPermissions);
  const userApplications = useSelector(selectUserApplications);

  const { hasPermission } = useRbac(permissions);

  const isRetailer = useMemo(() => getIsRetailer(companyActivity), [
    companyActivity,
  ]);

  const isSupplier = useMemo(() => getIsSupplier(companyActivity), [
    companyActivity,
  ]);

  const isTranslator = useMemo(() => getIsTranslator(companyActivity), [
    companyActivity,
  ]);

  const hasAdminAccess = useMemo(
    () => hasPermission(PERMISSIONS.USER.HAS_ACCESS_TO_ADMINISTRATION),
    [permissions]
  );

  const isRetailerAdmin = useMemo(() => isRetailer && hasAdminAccess, [
    isRetailer,
    hasAdminAccess,
  ]);

  const isSupplierAdmin = useMemo(() => isSupplier && hasAdminAccess, [
    isSupplier,
    hasAdminAccess,
  ]);

  const hasMasterDataAccess = useMemo(
    () => canAccessMasterData(userApplications),
    [userApplications]
  );

  // will be unnecessary when role-based implementation is done in backend
  const hasFAndVAccess = useMemo(() => canAccessFAndV({ isRetailer }), [
    isRetailer,
  ]);

  // will be unnecessary when role-based implementation is done in backend
  const hasClaimsAccess = useMemo(() => !isFAndVOnly, [isFAndVOnly]);

  // will be unnecessary when role-based implementation is done in backend
  const hasPackagingSystemsAccess = useMemo(() => !isFAndVOnly, [isFAndVOnly]);

  const contactType: SpecificationContactType = useMemo(() => {
    if (isRetailer) {
      return SpecificationContactType.RETAILER;
    }
    if (isSupplier) {
      return SpecificationContactType.SUPPLIER;
    }
    if (isTranslator) {
      return SpecificationContactType.TRANSLATOR;
    }
    return SpecificationContactType.OTHER;
  }, [isRetailer, isSupplier, isTranslator]);

  return {
    isRetailer,
    isRetailerAdmin,
    isSupplier,
    isSupplierAdmin,
    hasAdminAccess,
    isTranslator,
    contactType,
    hasMasterDataAccess,
    hasFAndVAccess,
    hasClaimsAccess,
    hasPackagingSystemsAccess,
  };
};

export default useUserInfo;
