import { Row, Col } from "antd";
import { Button, Tag } from "@trace-one/design-system";
import { useIntl, FormattedMessage } from "react-intl";
import { CollaborationEmailInvitation } from "../";
import { formatDateToDDMMYYYYWithSlash } from "utils/date";
import { CollaborationStatusAndActionsProps } from "./types";
import { messages } from "components/CreateCollaboration/messages";

const CollaborationStatusAndActions: React.FC<CollaborationStatusAndActionsProps> = ({
  collaboration,
  onCreateCollaboration,
  onRemoveCollaborations,
  clearUsersList,
  hideCollaborationActions,
  hideRemoveCollaboration,
}) => {
  const { formatMessage } = useIntl();
  const { status } = collaboration;

  const showRemoveOrCancelAction =
    !hideCollaborationActions && !hideRemoveCollaboration;

  const handleCreateCollaborationInvite = () => {
    onCreateCollaboration({
      collaborationKey: collaboration.key,
      userId: collaboration.userId,
    })();
    clearUsersList();
  };

  if (status === "notInvited") {
    return (
      <Tag
        size="medium"
        mode="light"
        color="grey"
        label={formatMessage(messages[status])}
      />
    );
  }

  if (status === "invite") {
    if (collaboration.userEmail) {
      return (
        <CollaborationEmailInvitation
          collaboration={collaboration}
          onCreateCollaboration={onCreateCollaboration}
        />
      );
    } else {
      return (
        <Button
          type="tertiary"
          onClick={handleCreateCollaborationInvite}
          data-testid="create-new-collaboration"
          loading={collaboration.loading}
        >
          {formatMessage(messages[status])}
        </Button>
      );
    }
  }

  if (status === "Pending") {
    return (
      <Row>
        <Col>
          <Tag
            size="medium"
            mode="light"
            color="orange"
            label={
              <FormattedMessage
                {...messages.pending}
                values={{
                  sentAt: formatDateToDDMMYYYYWithSlash(collaboration.sentAt),
                }}
              />
            }
          />
        </Col>
        {showRemoveOrCancelAction ? (
          <Col>
            <Button
              iconName="kebab"
              type="tertiary"
              items={[
                {
                  label: formatMessage(messages.cancelInvitation),
                  onClick: () => onRemoveCollaborations([collaboration.id]),
                  disabled: !onRemoveCollaborations,
                },
              ]}
              color="grey"
            />
          </Col>
        ) : null}
      </Row>
    );
  }
  if (status === "Active") {
    return (
      <Row>
        <Col>
          <Tag
            color="green"
            size="medium"
            mode="light"
            label={formatMessage(messages.active)}
          />
        </Col>
        {showRemoveOrCancelAction ? (
          <Col>
            <Button
              iconName="kebab"
              type="tertiary"
              items={[
                {
                  label: formatMessage(messages.removeUser),
                  onClick: () => onRemoveCollaborations([collaboration.id]),
                },
              ]}
              color="grey"
            />
          </Col>
        ) : null}
      </Row>
    );
  }

  return null;
};

export default CollaborationStatusAndActions;
