import { Row, Col } from "antd";
import { Heading, Paragraph } from "@trace-one/design-system";
import { getPropertiesObjectFromArray } from "../../templates/utils";
import { LabellingGuaranteedShelfLIfeWidgetProps } from "../GuaranteedShelfLifeWidget/types";
import { isObjectEmpty } from "utils/general";
import { EMPTY_VALUE } from "utils/constants";

const LabellingGuaranteedShelfLifeWidget: React.FC<LabellingGuaranteedShelfLIfeWidgetProps> = ({
  properties,
  formData,
  uiSchema,
  formContext,
  schema,
}) => {
  const propertyObjects = getPropertiesObjectFromArray(properties);

  const {
    displayActions,
    refListDictionary,
    isTargetMode,
    readOnlyFieldIds = [],
  } = formContext;

  const { duration, guaranteedShelfLifeId } = formData;

  const isGuaranteedShelfLifeIdReadOnly = readOnlyFieldIds.includes(
    schema.properties.guaranteedShelfLifeId.propertyId
  );

  const isDurationReadOnly = readOnlyFieldIds.includes(
    schema.properties.duration.propertyId
  );

  const isReadOnly = isGuaranteedShelfLifeIdReadOnly && isDurationReadOnly;

  const { xs, md } = uiSchema["ui:label:col"] || {};

  const labelColumnXS = xs ?? 4;
  const valueColumnXS = xs ? 24 - xs : 20;

  const labelColumnMd = md ?? 8;
  const valueColumMd = md ? 24 - md : 16;

  if (!displayActions || (isReadOnly && !isTargetMode)) {
    const guaranteedShelfLifeValue =
      refListDictionary[guaranteedShelfLifeId] ?? "";
    const durationValue = duration.value ? `, ${duration.value}` : "";
    const durationUnitValue = refListDictionary[duration.unitId] ?? "";

    return (
      <Row className="mb-2">
        <Col xs={labelColumnXS} md={labelColumnMd}>
          <Heading size="xxs">{uiSchema["ui:title"]}</Heading>
        </Col>

        <Col xs={valueColumnXS} md={valueColumMd}>
          {!guaranteedShelfLifeId && isObjectEmpty(duration) ? (
            <span>{EMPTY_VALUE}</span>
          ) : (
            <Paragraph size="m" color="grey-4">
              {`${guaranteedShelfLifeValue}${durationValue} ${durationUnitValue}`}
            </Paragraph>
          )}
        </Col>
      </Row>
    );
  }

  return (
    <Row gutter={[24, 0]} className="mb-2">
      <Col xs={labelColumnXS} md={labelColumnMd}>
        <Heading size="xxs">{uiSchema["ui:title"]}</Heading>
      </Col>

      <Col>
        <Row>
          <Col xs={24} md={17}>
            {propertyObjects["guaranteedShelfLifeId"].content}
          </Col>
          {formData.guaranteedShelfLifeId && (
            <Col xs={24} md={7}>
              {propertyObjects["duration"].content}
            </Col>
          )}
        </Row>
      </Col>
    </Row>
  );
};

export default LabellingGuaranteedShelfLifeWidget;
