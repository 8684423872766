import React, { useState } from "react";
import { Modal } from "@trace-one/design-system";
import { useFormSchemaTranslation } from "pages/Specification/components/Details/hooks";
import { ObjectFieldTemplateProps } from "components/SpecificationSection/types";
import { PackagingSubstanceForm } from "./PackagingSubstanceForm";
import { PackagingSubstance, PackagingSubstanceFormData } from "models";
import { PackagingSubstanceSummary } from "./PackagingSubstanceSummary";
import { isListEmpty } from "utils/general";
import { usePackagingSubstanceActions } from "../hooks";
import styles from "./styles.module.less";

interface AddSubstancesModalProps
  extends ObjectFieldTemplateProps<PackagingSubstanceFormData> {
  onClose?: () => void;
}

export const AddSubstancesModal: React.FC<AddSubstancesModalProps> = props => {
  const { onClose } = props;
  const { getTranslationForKey } = useFormSchemaTranslation();
  const [substances, setSubstances] = useState<PackagingSubstance[]>([]);
  const { formContext, formData } = props;
  const { saveTemplateBlock } = formContext;

  const { onAddSubstances, isSavingInProgress } = usePackagingSubstanceActions({
    onClose,
    saveTemplateBlock,
    substances,
    formData,
  });

  return (
    <Modal.Double
      size="xl"
      title={getTranslationForKey("addSubstances")}
      open={true}
      onCancel={onClose}
      subtitleRight={getTranslationForKey("summary")}
      primaryButtonText={getTranslationForKey("addSubstances")}
      secondaryButtonText={getTranslationForKey("cancel")}
      onSecondaryButtonClick={onClose}
      onPrimaryButtonClick={onAddSubstances}
      primaryButtonProps={{
        disabled: isListEmpty(substances),
        loading: isSavingInProgress,
      }}
      leftContent={
        <PackagingSubstanceForm
          {...props}
          substances={substances}
          setSubstances={setSubstances}
        />
      }
      rightContent={
        <PackagingSubstanceSummary
          substances={substances}
          setSubstances={setSubstances}
          uiSchema={props.uiSchema}
        />
      }
      wrapClassName={styles["add-substance-modal"]}
    ></Modal.Double>
  );
};
