import { IngredientListFormData } from "components/SpecificationSection/components/FormSchema/types";
import { FormContext } from "components/SpecificationSection/types";

export type UseIngredientListWidgetProps = {
  formContext: FormContext;
  formData: IngredientListFormData;
};

const useIngredientListWidget = ({
  formContext,
  formData,
}: UseIngredientListWidgetProps) => {
  const { extraActions, isTranslator, saveTemplateBlock } = formContext;

  const handleChange = (fieldName: string) => (value: string | boolean) => {
    if (fieldName === "modifiedIngredientList" && value === undefined) {
      value = "";
    }

    const dataToSend = {
      ...formData,
      [fieldName]: value,
    };

    if (isTranslator) {
      saveTemplateBlock?.(JSON.stringify(dataToSend));
    } else {
      extraActions?.onUpdateIngredientList({
        ...dataToSend,
      });
    }
  };

  const editIngredientList = () => {
    const { originalIngredientList } = formData;

    extraActions?.onUpdateIngredientList({
      ...formData,
      modifiedIngredientList: originalIngredientList,
    });
  };

  const resetIngredientList = () => {
    const { modifiedIngredientList, ...rest } = formData;
    extraActions?.onUpdateIngredientList({
      ...rest,
    });
  };

  return {
    handleChange,
    resetIngredientList,
    editIngredientList,
  };
};

export default useIngredientListWidget;
