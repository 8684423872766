import { ReactElement } from "react";
import { SPECIFICATION_STATE } from "utils/constants";
import { isStateDraft } from "utils/specifications";
import {
  getDifferenceBetweenDates,
  getDuration,
  getUtcDate,
  isSameDate,
  parseDate,
} from "utils/date";
import { timeMessages } from "messages";

export const displayValueForSpecificationDraftState = ({
  specificationState,
  valueToDisplay,
}: {
  specificationState: SPECIFICATION_STATE;
  valueToDisplay: string | ReactElement;
}) => {
  if (isStateDraft(specificationState)) {
    return null;
  }
  return valueToDisplay;
};

export const getProductOrigin = ({
  companyActivity,
}: {
  companyActivity: string;
}) => (companyActivity === "2" ? "retailer" : "supplier");

export const getDataIndexForProductOrigin = ({
  productOrigin,
}: {
  productOrigin: string;
}) => (productOrigin === "supplier" ? "supplierName" : "ownerCompanyName");

/* istanbul ignore next*/
export const getValueToDisplayforLifeTime = ({
  createdDateUtc,
  todayMessage,
  oneDayMessage,
  formatMessage,
}: {
  createdDateUtc: string;
  todayMessage: React.ReactElement;
  oneDayMessage: React.ReactElement;
  formatMessage: Function;
}) => {
  const today = getUtcDate();
  const parsedCreatedDateUtc = parseDate(createdDateUtc);

  if (isSameDate(parsedCreatedDateUtc, today, "d")) {
    return todayMessage;
  }

  const duration = getDuration(
    getDifferenceBetweenDates(today, parsedCreatedDateUtc)
  );

  // If less than a day.
  if (duration.days() === 0) {
    return oneDayMessage;
  }

  const months = Math.floor(duration.asMonths());
  const days = duration.days();

  let formattedDuration = "";
  if (months > 0) {
    formattedDuration += `${months} ${formatMessage(
      months === 1 ? timeMessages.month : timeMessages.months
    )}`;
    if (days > 0) {
      formattedDuration += ", ";
    }
  }
  if (days > 0) {
    formattedDuration += `${days} ${formatMessage(
      days === 1 ? timeMessages.day : timeMessages.days
    )}`;
  }

  return formattedDuration;
};
