import { Col, Row } from "antd";
import { Divider, Heading } from "@trace-one/design-system";
import { UploadFiles } from "@trace-one/business-components";
import ClaimDescription from "../ClaimDescription";
import { useFilesInfo } from "hooks";
import { isListEmpty } from "utils/general";
import {
  ClaimData,
  ClaimSentenceTranslation,
  RegulatoryHealthClaim,
} from "models";
import { ClaimType } from "types/library";
import styles from "./styles.module.less";
import {
  getTranslationTextByLanguageCode,
  isClaimTypeHealth,
} from "utils/claim";
import { useSelector } from "react-redux";
import { selectLanguageCode } from "store/user/selectors";
import { EMPTY_VALUE } from "utils/constants";

export type ClaimCardReadOnlyProps = {
  name: string;
  sentenceTranslations: ClaimSentenceTranslation[];
  fileIds: string[];
  claimType: ClaimType;
  additionalSentenceTranslations?: ClaimData["additionalSentenceTranslations"];
  regulation?: RegulatoryHealthClaim;
  conditionOfUse?: RegulatoryHealthClaim["conditionOfUse"];
};

const ClaimCardReadOnly: React.FC<ClaimCardReadOnlyProps> = ({
  name,
  sentenceTranslations,
  fileIds,
  claimType,
  additionalSentenceTranslations,
  regulation,
  conditionOfUse: nutritionalClaimConditionOfUse,
}) => {
  const { files } = useFilesInfo({ fileIds });
  const languageCode = useSelector(selectLanguageCode);

  const conditionOfUse = getTranslationTextByLanguageCode({
    translations: isClaimTypeHealth(claimType)
      ? regulation?.conditionOfUse
      : nutritionalClaimConditionOfUse,
    languageCode,
  });
  const claimSentence = isClaimTypeHealth(claimType)
    ? getTranslationTextByLanguageCode({
        translations: regulation?.sentenceTranslations ?? [],
        languageCode,
      })
    : sentenceTranslations.find(translation => translation.isDefault).text;
  const additionalClaimSentence =
    getTranslationTextByLanguageCode({
      translations: additionalSentenceTranslations,
      languageCode: languageCode,
    }) ?? EMPTY_VALUE;

  return (
    <div className={styles["claims-section__wrapper"]}>
      <Heading size="xxs">{name}</Heading>
      <Divider color="grey-2" height={16} />
      <ClaimDescription
        claimType={claimType}
        additionalClaimSentence={additionalClaimSentence}
        claimSentence={claimSentence}
        conditionOfUse={conditionOfUse}
      />

      {!isListEmpty(files) && (
        <Row className="mt-2">
          <Col span={8}>
            <UploadFiles.List fileList={files} />
          </Col>
        </Row>
      )}
    </div>
  );
};

export default ClaimCardReadOnly;
