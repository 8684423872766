import { useContext, useMemo } from "react";
import { LibraryDetails } from "pages/Library/components/Details/contexts";
import { Col, Row } from "antd";
import ClaimInformation from "components/Library/components/ClaimForm/components/ClaimInformation";
import ClaimImage from "components/Library/components/ClaimForm/components/ClaimImage";
import ClaimSentenceTranslation from "components/Library/components/ClaimForm/components/ClaimSentenceTranslation";
import {
  getDefaultTranslationLanguageCode,
  isClaimTypeHealth,
  isClaimTypeNutritional,
} from "utils/claim";
import { useHealthClaimRegulation } from "hooks";

const ClaimDetails = () => {
  const { libraryItemDetails } = useContext(LibraryDetails);

  const defaultTranslationLanguageCode = getDefaultTranslationLanguageCode(
    libraryItemDetails?.sentenceTranslations
  );

  const { regulation } = useHealthClaimRegulation({
    id: libraryItemDetails?.regulatoryClaimId,
  });

  const isNutritionalClaim = isClaimTypeNutritional(
    libraryItemDetails?.claimType
  );

  const claimSentences = useMemo(
    () =>
      isClaimTypeHealth(libraryItemDetails?.claimType)
        ? libraryItemDetails?.additionalSentenceTranslations?.filter(
            ({ languageCode }) =>
              languageCode !== defaultTranslationLanguageCode
          )
        : libraryItemDetails?.sentenceTranslations?.filter(
            ({ isDefault }) => !isDefault
          ),
    [
      libraryItemDetails?.sentenceTranslations,
      libraryItemDetails?.additionalSentenceTranslations,
    ]
  );

  return (
    <div className="mt-3">
      <Row>
        <Col xs={isNutritionalClaim ? 24 : 18}>
          <ClaimInformation
            libraryItemDetails={libraryItemDetails}
            regulation={regulation || libraryItemDetails}
          />
          {!isNutritionalClaim && (
            <ClaimSentenceTranslation
              claimSentences={claimSentences}
              claimType={libraryItemDetails?.claimType}
            />
          )}
        </Col>
        {!isNutritionalClaim && (
          <Col xs={6}>
            <ClaimImage fileId={libraryItemDetails?.logoFileId} />
          </Col>
        )}
      </Row>
    </div>
  );
};

export default ClaimDetails;
