import { useClaimNutrients, useClaimSubstances, useClaimTypes } from "hooks";
import {
  CLAIM_TYPES,
  LIBRARY_OBJECT_TYPES,
  MAX_ITEMS_FOR_QUERY_PARAM,
} from "utils/constants";
import { isListEmpty } from "utils/general";
import { getClaimTypesForParam } from "../utils";
import { UpdateFilterType } from "../../../types";

export type FilterNameType = "claimTypes" | "nutrientIds" | "substanceIds";

export type UseClaimFiltersProps = {
  type: LIBRARY_OBJECT_TYPES;
  isLibraryListingPage: boolean;
  setStates: (_: string[]) => void;
  updateFilter: UpdateFilterType;
};

const useClaimFilters = ({
  type,
  isLibraryListingPage,
  updateFilter,
  setStates,
}: UseClaimFiltersProps) => {
  const { claimNutrientOptions } = useClaimNutrients({
    claimTypes: getClaimTypesForParam({ type, isLibraryListingPage }),
  });

  const { claimSubstanceOptions } = useClaimSubstances({
    claimTypes: getClaimTypesForParam({ type, isLibraryListingPage }),
  });

  const { claimTypeOptions } = useClaimTypes({
    excludedClaimTypes: isLibraryListingPage ? [CLAIM_TYPES.NUTRITION] : [],
  });

  const onStatesFilterChange = ({ value }) => setStates(value);

  const onClaimFilterChange = (filterName: FilterNameType) => ({ value }) => {
    if (!isListEmpty(value) && value.length > MAX_ITEMS_FOR_QUERY_PARAM) {
      return;
    }

    updateFilter(filterName)(value);
  };

  const onClearClaimFilter = (filterName: FilterNameType) => () =>
    updateFilter(filterName)([]);

  const onClearStatesFilter = () => setStates([]);

  return {
    claimTypeOptions,
    claimNutrientOptions,
    claimSubstanceOptions,
    onStatesFilterChange,
    onClearStatesFilter,
    onClaimFilterChange,
    onClearClaimFilter,
  };
};

export default useClaimFilters;
