import React from "react";
import { useIntl } from "react-intl";
import { Modal, Paragraph } from "@trace-one/design-system";
import { useDeleteVersionModal } from "./hooks";
import { isObjectEmpty } from "utils/general";
import { generalMessages, libraryMessages } from "messages";
import { DeleteVersionProps } from "./types";

const DeleteVersionModal: React.FC<DeleteVersionProps> = ({
  libraryItemId,
  libraryItemType,
  libraryItemName,
  versionNumber,
  onCancel,
  redirect,
}) => {
  const { formatMessage } = useIntl();

  const {
    isDeleting,
    onDeleteVersion,
    deleteVersionMessages,
  } = useDeleteVersionModal({
    libraryItemId,
    libraryItemType,
    libraryItemName,
    versionNumber,
    onCancel,
    redirect,
  });

  return (
    <Modal.Simple
      open={true}
      size="s"
      illustrationName="delete"
      illustrationColor="grey"
      keyboard={false}
      title={
        isObjectEmpty(deleteVersionMessages)
          ? null
          : formatMessage(deleteVersionMessages.modalTitle)
      }
      onPrimaryButtonClick={onDeleteVersion}
      primaryButtonText={formatMessage(libraryMessages.deleteVersion)}
      primaryButtonProps={{ loading: isDeleting }}
      onSecondaryButtonClick={onCancel}
      secondaryButtonText={formatMessage(generalMessages.cancel)}
      secondaryButtonProps={{ disabled: isDeleting }}
      onCancel={onCancel}
    >
      <Paragraph>
        {isObjectEmpty(deleteVersionMessages)
          ? null
          : formatMessage(deleteVersionMessages.modalDescription)}
      </Paragraph>
    </Modal.Simple>
  );
};

export default DeleteVersionModal;
