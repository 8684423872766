import React from "react";
import { useSelector } from "react-redux";
import { Row } from "antd";
import { Divider, Heading, Icon } from "@trace-one/design-system";
import RegulationsInfoItem from "../RegulationsInfoItem";
import { selectCatalogDictionary } from "store/generalData/selectors";
import { isObjectEmpty } from "utils/general";
import { RECYCLABILITY_REGULATION_CODES } from "utils/constants";
import { getShouldDisplayDivider } from "./utils";
import { SubstanceRegulationData } from "models";

export type RegulationsInfoProps = {
  regulationsInfo: SubstanceRegulationData["regulations"];
};

const RegulationsInfo: React.FC<RegulationsInfoProps> = ({
  regulationsInfo,
}) => {
  const catalogDictionary = useSelector(selectCatalogDictionary);

  const recyclabilityRegulationCodes = [
    RECYCLABILITY_REGULATION_CODES.EU,
    RECYCLABILITY_REGULATION_CODES.REACH,
    RECYCLABILITY_REGULATION_CODES.AGEC,
  ];

  return (
    <>
      {recyclabilityRegulationCodes.map(code => {
        const displayDivider = getShouldDisplayDivider(code, regulationsInfo);

        return (
          !isObjectEmpty(regulationsInfo?.[code]) && (
            <React.Fragment key={code}>
              {displayDivider && <Divider color="grey-3" height={16} />}

              <Row>
                <Heading size="xxs">{catalogDictionary[code]}</Heading>
              </Row>
              <div className="d-flex pt-1">
                <div className="mr-1">
                  <Icon name="hierarchy-link" size="small" color="grey-5" />
                </div>
                <div>
                  <RegulationsInfoItem
                    regulation={regulationsInfo?.[code]}
                    code={code}
                  />
                </div>
              </div>
            </React.Fragment>
          )
        );
      })}
    </>
  );
};

export default RegulationsInfo;
