import { isObjectEmpty } from "utils/general";
import { NutrientItem, NutritionFormData } from "models";

export const getServingValue = ({
  formData,
  nutrient,
  key,
  servingId,
}: {
  nutrient: NutrientItem;
  formData: NutritionFormData;
  key: string;
  servingId: string;
}) => {
  if (isObjectEmpty(formData) || isObjectEmpty(nutrient)) {
    return null;
  }

  const { nutrients = [] } = formData;

  const serving = nutrients
    .find(({ nutrientId }) => nutrient.nutrientId === nutrientId)
    ?.servings.find(
      ({ servingId: formDataServingId }) => servingId === formDataServingId
    );

  return serving?.[key];
};

export const getServingIndex = ({
  formData,
  nutrient,
  servingId,
}: {
  nutrient: NutrientItem;
  formData: NutritionFormData;
  servingId: string;
}) => {
  if (isObjectEmpty(formData) || isObjectEmpty(nutrient)) {
    return null;
  }

  const { nutrients = [] } = formData;

  return nutrients
    .find(({ nutrientId }) => nutrient.nutrientId === nutrientId)
    ?.servings.findIndex(
      ({ servingId: formDataServingId }) => servingId === formDataServingId
    );
};
