import React, { useContext, useMemo } from "react";
import { useIntl } from "react-intl";
import classNames from "classnames";
import { Typography } from "@trace-one/design-system";
import Spin from "components/Spin";
import IngredientsListTable from "../IngredientsListTable";
import { useFilesInfo } from "hooks";
import TechnicalInformation from "../TechnicalInformation";
import RecipeSummarySuppliers from "../RecipeSummarySuppliers";
import RecipeAllergensDetails from "../RecipeAllergensDetails";
import KPITabs, { KPITabType } from "components/KPITabs";
import { useRecipeAllergensDetails, useRecipeSummary } from "./hooks";
import { SelectedChapterContext } from "pages/Specification/components/Details/components/SelectedChapter/contexts";
import { isMaterialCompositeType } from "utils/library";
import { messages } from "../../messages";
import { FILE_TYPES_NAMES } from "utils/constants";
import { getAllergensKpiValue } from "./utils";
import { Dictionary } from "types/general";
import { FormContext } from "components/SpecificationSection/types";
import styles from "./styles.module.less";

enum RecipeTabsEnum {
  RECIPE = "recipe",
  TECHNICAL_INFORMATION = "technical-information",
  ALLERGEN_DETAILS = "allergen-details",
  SUPPLIERS = "supplier",
}

export type RecipeSummaryProps = {
  formContext?: FormContext;
};

const RecipeSummary: React.FC<RecipeSummaryProps> = ({ formContext }) => {
  const { formatMessage } = useIntl();
  const {
    chapterType,
    specificationHeaderTitle,
    chapterData = {},
    specificationStructure = [],
    chapterRecipe,
  } = useContext(SelectedChapterContext);

  const productionChapter = specificationStructure?.find(
    ({ chapterType: type }) => type === chapterType
  );

  const {
    rawMaterialsAllergens = [],
    crossContaminationAllergens = [],
  } = chapterData;

  const { recipeIngredients = [] } = chapterRecipe || {};

  const {
    isMaterialsDetailsLoading,
    ingredients,
    refListDictionary,
    fileIds,
    suppliers,
  } = useRecipeSummary({
    recipeIngredients,
  });

  const {
    crossContaminationFormData,
    crossContaminationUISchema,
    freeFromAllergenFormData,
    freeFromAllergenSchema,
    freeFromAllergenUISchema,
  } = useRecipeAllergensDetails({
    sections: productionChapter?.sections || [],
  });

  const ingredientsWithoutComposites = ingredients.filter(
    ({ type }) => !isMaterialCompositeType(type)
  );

  const ingredientNameDictionary = ingredientsWithoutComposites.reduce(
    (acc: Dictionary<string>, { ingredientIds, name }) => {
      ingredientIds.forEach(id => (acc[id] = name));
      return acc;
    },
    {}
  );

  const { filesWithUserInfo, isLoading: isFileLoading } = useFilesInfo({
    fileIds,
    shouldHaveUserInformation: true,
    useV2: true,
  });

  const technicalFiles = useMemo(() => {
    return filesWithUserInfo.filter(
      file => file.fileType === FILE_TYPES_NAMES.MATERIAL_TECHNICAL_DATA_SHEET
    );
  }, [filesWithUserInfo]);

  const allergensKpiValue = getAllergensKpiValue({
    rawMaterialsAllergens,
    crossContaminationAllergens,
    freeFromAllergenFormData: freeFromAllergenFormData?.allergens,
  });

  const kpiTabs: KPITabType[] = [
    {
      title: formatMessage(messages.recipeSummaryOverlayIngredients),
      kpiValue: ingredientsWithoutComposites?.length || 0,
      icon: "recipe",
      selectedIcon: "recipe-selected",
      tabKey: RecipeTabsEnum.RECIPE,
      content: (
        <IngredientsListTable
          ingredients={ingredientsWithoutComposites}
          refListDictionary={refListDictionary}
        />
      ),
    },
    {
      title: formatMessage(messages.technicalInformation),
      kpiValue: technicalFiles?.length || 0,
      icon: "info",
      selectedIcon: "more-info-selected",
      tabKey: RecipeTabsEnum.TECHNICAL_INFORMATION,
      content: (
        <TechnicalInformation
          files={technicalFiles}
          ingredients={ingredients}
          isLoading={isFileLoading}
        />
      ),
    },
    {
      title: formatMessage(messages.allergenDetails),
      kpiValue: allergensKpiValue,
      icon: "caution",
      selectedIcon: "caution-selected",
      tabKey: RecipeTabsEnum.ALLERGEN_DETAILS,
      content: (
        <RecipeAllergensDetails
          formContext={{ ...formContext, formData: freeFromAllergenFormData }}
          crossContaminationFormData={crossContaminationFormData}
          crossContaminationUISchema={crossContaminationUISchema}
          freeFromAllergenSchema={freeFromAllergenSchema}
          freeFromAllergenUISchema={freeFromAllergenUISchema}
        />
      ),
    },
    {
      title: formatMessage(messages.suppliers),
      kpiValue: suppliers?.length || 0,
      icon: "contact",
      selectedIcon: "contact-selected",
      tabKey: RecipeTabsEnum.SUPPLIERS,
      content: (
        <RecipeSummarySuppliers
          suppliers={suppliers}
          ingredientNameDictionary={ingredientNameDictionary}
        />
      ),
    },
  ];

  if (isMaterialsDetailsLoading || isFileLoading) {
    return <Spin className="mt-4" />;
  }

  return (
    <div
      className={classNames(styles["recipe-summary"], "hide-scrollbar")}
      data-test-id="recipe-summary-content"
    >
      <Typography variant="heading-m" color="grey-5" className="pr-5">
        {specificationHeaderTitle}
      </Typography>
      <Typography variant="body-regular" color="grey-5" className="mt-0-5">
        {formatMessage(messages.recipeSummaryOverlaySubtitle)}
      </Typography>

      <KPITabs
        kpiTabs={kpiTabs}
        defaultTabKey={RecipeTabsEnum.RECIPE}
        className="mt-2 mb-2"
      />
    </div>
  );
};

export default RecipeSummary;
