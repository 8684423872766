import { useState } from "react";
import { useIntl } from "react-intl";
import { UiSchema } from "@rjsf/core";
import { Modal } from "@trace-one/design-system";
import { PackagingSubstanceForm } from "components/SpecificationSection/components/FormSchema/widgets/SubstanceWidget/components/PackagingSubstanceForm";
import { useBoolean } from "hooks";
import { getIsPrimaryButtonDisabled, prepareSubstanceData } from "./utils";
import { PackagingSubstance, PackagingSubstanceFormData } from "models";
import { PackagingSubstanceTableViewModel } from "viewModels";
import {
  FormContext,
  ObjectSchema,
} from "components/SpecificationSection/types";
import { generalMessages } from "messages";
import { substancesTableMessages } from "components/SubstancesTable/messages";

export type EditSubstanceModalProps = {
  substance: PackagingSubstanceTableViewModel;
  onCloseModal: Function;
  formData: PackagingSubstanceFormData;
  formContext: FormContext;
  schema: ObjectSchema;
  uiSchema: UiSchema;
};

const EditSubstanceModal: React.FC<EditSubstanceModalProps> = ({
  substance,
  onCloseModal,
  formData,
  formContext,
  schema,
  uiSchema,
}) => {
  const { formatMessage } = useIntl();

  const [substanceData, setSubstanceData] = useState<PackagingSubstance>(null);
  const { value: updating, setTrue, setFalse } = useBoolean();

  const onPrimaryButtonClick = async () => {
    const updatedSubstances = formData.substances.map(
      ({ substanceId, ...rest }) => {
        if (substanceData.substanceId === substanceId) {
          return prepareSubstanceData(substanceData);
        }
        return { substanceId, ...rest };
      }
    );

    try {
      setTrue();

      await formContext?.saveTemplateBlock?.(
        JSON.stringify({ substances: updatedSubstances })
      );
    } catch {
    } finally {
      setFalse();
      onCloseModal();
    }
  };

  const isPrimaryButtonDisabled = getIsPrimaryButtonDisabled(substanceData);

  return (
    <Modal.Simple
      open
      keyboard={false}
      onCancel={onCloseModal}
      title={formatMessage(substancesTableMessages.editSubstance)}
      primaryButtonText={formatMessage(generalMessages.confirm)}
      secondaryButtonText={formatMessage(generalMessages.cancel)}
      onPrimaryButtonClick={onPrimaryButtonClick}
      onSecondaryButtonClick={onCloseModal}
      primaryButtonProps={{
        loading: updating,
        disabled: isPrimaryButtonDisabled,
      }}
      secondaryButtonProps={{ disabled: updating }}
    >
      <div className="w-100 overflow-hidden">
        {/* @ts-ignore */}
        <PackagingSubstanceForm
          uiSchema={uiSchema}
          schema={schema}
          formContext={formContext}
          substances={formData.substances || []}
          substanceId={substance?.substanceId}
          displayTitle={false}
          displayAddToSelect={false}
          disableSubstance
          initialValue={{
            substanceId: substance?.substanceId,
            percentage: substance?.percentage,
            presentAtNanoscale: substance?.presentAtNanoscale,
            presenceAtNanoscale: substance?.presenceAtNanoscale,
          }}
          getSubstanceFormData={setSubstanceData}
        />
      </div>
    </Modal.Simple>
  );
};

export default EditSubstanceModal;
