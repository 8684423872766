import { ColumnsType } from "antd/lib/table";
import { useIntl } from "react-intl";
import { Typography, Button } from "@trace-one/design-system";
import { useRedirect } from "hooks";
import {
  EMPTY_VALUE,
  PACKAGING_COMPONENT_TAB_KEYS,
  PACKAGING_SYSTEM_TAB_KEYS,
} from "utils/constants";
import { PackagingComponentSubstanceViewModel } from "viewModels";
import { generalMessages } from "messages";
import { packagingComponentTableMessages } from "messages/packaging";
import { restrictedSubstanceTableMessages } from "../../messages";

export type UseRestrictedSubstancesTableParams = {
  canSort?: boolean;
  packagingSystemId: string;
};

const useRestrictedSubstanceTable = ({
  canSort,
  packagingSystemId,
}: UseRestrictedSubstancesTableParams) => {
  const { formatMessage } = useIntl();

  const { redirectToPackagingComponentEdit } = useRedirect();

  const columns: ColumnsType<PackagingComponentSubstanceViewModel> = [
    {
      title: formatMessage(
        restrictedSubstanceTableMessages.substanceNameAndCASNumber
      ),
      key: "substanceName",
      width: "45%",
      render: ({ name, casNumbers }: PackagingComponentSubstanceViewModel) => {
        const casNumber = casNumbers?.join("; ") ?? EMPTY_VALUE;
        const content = (
          <>
            <Typography variant="body-regular" color="grey-5">
              {name}
            </Typography>

            <Typography variant="body-s" color="grey-5">
              {casNumber}
            </Typography>
          </>
        );

        return content;
      },
    },
    {
      title: formatMessage(packagingComponentTableMessages.componentName),
      key: "componentName",
      dataIndex: "componentName",
      width: "25%",
      sorter: canSort,
      render: (
        componentName: PackagingComponentSubstanceViewModel["componentName"],
        { componentId }: PackagingComponentSubstanceViewModel
      ) => {
        return (
          <Button
            type="link"
            size="small"
            onClick={() =>
              redirectToPackagingComponentEdit({
                packagingComponentId: componentId,
                queryParameters: {
                  packagingSystemId,
                  packagingSystemTab:
                    PACKAGING_SYSTEM_TAB_KEYS.RESTRICTION_SUMMARY,
                  componentTab: PACKAGING_COMPONENT_TAB_KEYS.RECYCLABILITY,
                },
              })
            }
          >
            {componentName}
          </Button>
        );
      },
    },
    {
      title: formatMessage(
        restrictedSubstanceTableMessages.percentageInComponent
      ),
      key: "percentage",
      dataIndex: "percentage",
      width: "10%",
    },
    {
      title: formatMessage(restrictedSubstanceTableMessages.nanoPresence),
      key: "presentAtNanoscale",
      dataIndex: "presentAtNanoscale",
      width: "10%",
      render: (
        presentAtNanoscale: PackagingComponentSubstanceViewModel["presentAtNanoscale"]
      ) =>
        formatMessage(
          presentAtNanoscale ? generalMessages.yes : generalMessages.no
        ),
    },
    {
      title: formatMessage(restrictedSubstanceTableMessages.endocrineDisruptor),
      key: "isEndocrineDisruptor",
      dataIndex: "isEndocrineDisruptor",
      width: "10%",
      render: (
        isEndocrineDisruptor: PackagingComponentSubstanceViewModel["isEndocrineDisruptor"]
      ) =>
        formatMessage(
          isEndocrineDisruptor ? generalMessages.yes : generalMessages.no
        ),
    },
  ];

  return { columns };
};

export default useRestrictedSubstanceTable;
