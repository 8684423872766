import { Datatable, Alert } from "@trace-one/design-system";
import { useIntl } from "react-intl";
import { getRowKeyForTable } from "utils/table";
import FormCard from "../../../FormCard";
import AddAnAllergen from "../../../AddAnAllergen";
import { useMaterialPermissions } from "hooks";
import { useAllergensTable } from "./hooks";
import { AllergensProps } from "./types";
import libraryMessages from "messages/library";
import { messages } from "./messages";
import styles from "../../../../styles.module.less";

const Allergens: React.FC<AllergensProps> = ({
  allergenDictionary,
  displayActions,
  allergens,
  ingredients,
}) => {
  const { formatMessage } = useIntl();
  const { disableActions } = useMaterialPermissions();

  const { columns, onAddAllergens } = useAllergensTable({
    allergenDictionary,
    displayActions,
    disableActions,
    allergens,
    ingredients,
  });

  return (
    <FormCard
      title={formatMessage(libraryMessages.cardTitle["allergens"])}
      name="allergens"
    >
      {displayActions && !disableActions && (
        <Alert
          message={formatMessage(messages.validationMessage)}
          type="info"
          showIcon
          closable
          className={styles.alertReadOnlyTableMargin}
          data-testid="info-banner-material-allergen"
        />
      )}
      <Datatable
        tableLayout="fixed"
        columns={columns}
        dataSource={allergens}
        emptyText={formatMessage(libraryMessages.noAllergens)}
        rowKey={getRowKeyForTable("allergen")}
        pagination={false}
      />
      {displayActions && (
        <AddAnAllergen
          onAddAllergens={onAddAllergens}
          selectedAllergens={allergens.map(({ allergenId }) => allergenId)}
          disableActions={disableActions}
        />
      )}
    </FormCard>
  );
};

export default Allergens;
