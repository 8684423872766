import { toaster as toasterService } from "@trace-one/design-system";
import { useIntl } from "react-intl";
import { useBoolean, useLibraryItemActions } from "hooks";
import {
  isLibraryObjectChapterSubType,
  isLibraryObjectClaimType,
  isLibraryObjectRequirementType,
} from "utils/library";
import {
  deleteChapterVersionMessages,
  deleteClaimVersionMessages,
  deleteRequirementVersionMessages,
} from "../../messages";
import { DeleteVersionMessageProps, DeleteVersionProps } from "../../types";

const useDeleteVersionModal = ({
  libraryItemId,
  libraryItemType,
  libraryItemName,
  versionNumber,
  onCancel,
  redirect,
}: DeleteVersionProps) => {
  const { formatMessage } = useIntl();

  const { deleteHandler } = useLibraryItemActions({
    libraryItemInfo: {
      id: libraryItemId,
      type: libraryItemType,
    },
  });

  const {
    value: isDeleting,
    setTrue: setDeletingTrue,
    setFalse: setDeletingFalse,
  } = useBoolean(false);

  let deleteVersionMessages: DeleteVersionMessageProps;

  if (isLibraryObjectChapterSubType(libraryItemType)) {
    deleteVersionMessages = deleteChapterVersionMessages;
  } else if (isLibraryObjectRequirementType(libraryItemType)) {
    deleteVersionMessages = deleteRequirementVersionMessages;
  } else if (isLibraryObjectClaimType(libraryItemType)) {
    deleteVersionMessages = deleteClaimVersionMessages;
  }

  const onDeleteVersion = async () => {
    try {
      if (
        !libraryItemId ||
        !libraryItemType ||
        typeof deleteHandler !== "function"
      ) {
        return;
      }
      setDeletingTrue();
      await deleteHandler();

      setDeletingFalse();
      onCancel();
      redirect();

      toasterService.confirmation({
        message: formatMessage(deleteVersionMessages.toasterTitle),
        description: formatMessage(deleteVersionMessages.toasterDescription, {
          libraryItemName,
          versionNumber,
        }),
      });
    } catch {
      setDeletingFalse();
    }
  };

  return {
    isDeleting,
    onDeleteVersion,
    deleteVersionMessages,
  };
};

export default useDeleteVersionModal;
