import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "store";
import {
  ClaimFile,
  ClaimSentenceTranslation,
  RegulatoryHealthClaim,
} from "models/claim";
import { TagData, VersionData } from "models";
import { createClaimTag, uploadClaimLogoFile } from "./asyncActions";
import { ClaimType } from "types/library";

export interface ReduxClaimType {
  generalInfo: {
    id: string;
    name: string;
    productTypeId: string;
    claimType: ClaimType;
    tags: TagData[] | string[];
    requiresSupportingDocuments: boolean | null;
    regulatoryClaimId?: string;
    version?: VersionData;
  };
  sentenceTranslations: ClaimSentenceTranslation[];
  file: ClaimFile;
  regulation?: RegulatoryHealthClaim;
}

export const initialClaimForm: ReduxClaimType = {
  generalInfo: {
    id: null,
    name: null,
    productTypeId: null,
    claimType: null,
    tags: null,
    requiresSupportingDocuments: false,
    regulatoryClaimId: null,
  },
  sentenceTranslations: [],
  file: {},
  regulation: undefined,
};

export const slice = createSlice({
  name: "claimForm",
  initialState: initialClaimForm,
  reducers: {
    setClaimFormData: (state, action) => {
      const { generalInfo, sentenceTranslations, regulation } = action.payload;

      state.generalInfo = generalInfo;
      state.sentenceTranslations = sentenceTranslations;
      state.regulation = regulation;
    },
    setClaimFormFile: (state, action) => {
      state.file = action.payload?.file;
    },
    setClaimRegulation: (state, action) => {
      state.regulation = action.payload?.regulation;
    },
  },
  extraReducers: builder => {
    /* istanbul ignore next*/
    builder.addCase(createClaimTag.fulfilled, (state, action) => {
      state.generalInfo.tags = state?.generalInfo?.tags?.map(tag => {
        if (action?.payload?.tagText === tag?.tagText) {
          return { ...tag, tagId: action?.payload?.tagId };
        }
        return tag;
      });
    });

    builder.addCase(uploadClaimLogoFile.fulfilled, (state, action) => {
      state.file = {
        ...state.file,
        id: action?.payload?.fileId,
      };
    });
  },
});

export const selectClaimForm = (state: RootState) => state.claimForm;

export const {
  setClaimFormData,
  setClaimFormFile,
  setClaimRegulation,
} = slice.actions;

export default slice.reducer;
