import { useEffect, useMemo } from "react";
import { useIntl } from "react-intl";
import { Datatable, Typography } from "@trace-one/design-system";
import {
  useCatalogDictionary,
  useClientPagination,
  usePagination,
  useRecyclabilityRegulationsInfo,
} from "hooks";
import { useRestrictedSubstanceTable } from "./hooks";
import { shouldPaginationBeDisplay } from "utils/table";
import { isListEmpty, scrollToTop } from "utils/general";
import { PackagingComponentSubstanceViewModel } from "viewModels";
import { restrictedSubstanceTableMessages } from "./messages";
import RegulationsInfo from "components/SubstancesTable/components/RegulationsInfo";
import { ErrorBoundary } from "react-error-boundary";
import { WarningFallback } from "components";

export type RestrictedSubstanceTableProps = {
  packagingSystemId: string;
  data: PackagingComponentSubstanceViewModel[];
  isLoading?: boolean;
  canSort?: boolean;
};

const RestrictedSubstanceTable: React.FC<RestrictedSubstanceTableProps> = ({
  packagingSystemId,
  data,
  isLoading,
  canSort,
}) => {
  const { formatMessage } = useIntl();

  const { pagination, sorter, onTableChange } = usePagination();
  const {
    filteredItems,
    onPaginationChange,
    totalCount,
  } = useClientPagination({ items: data, pageSize: 10, sorter });
  const { columns } = useRestrictedSubstanceTable({
    canSort,
    packagingSystemId,
  });

  useRecyclabilityRegulationsInfo();

  const {
    isLoading: isRegulationsInfoLoading,
    substanceRegulations,
  } = useCatalogDictionary({
    substanceIds: filteredItems.map(({ substanceId }) => substanceId),
    shouldFilterIds: false,
    shouldGetSubstanceRegulationData: true,
  });

  const substanceWithRegulationsInfo = useMemo(() => {
    if (isListEmpty(filteredItems) || isListEmpty(substanceRegulations)) {
      return [];
    }

    return filteredItems?.map(item => {
      const substanceRegulationsInfo = substanceRegulations.find(
        ({ id }) => id === item.substanceId
      );
      return {
        ...item,
        regulations: substanceRegulationsInfo?.regulations,
      };
    });
  }, [filteredItems, substanceRegulations]);

  useEffect(() => {
    scrollToTop(0, "smooth");
  }, [JSON.stringify(filteredItems)]);

  return (
    <ErrorBoundary FallbackComponent={WarningFallback}>
      <Typography
        variant="body-s"
        color="grey-4"
        className="mb-2 pt-0-5 pb-0-5"
      >
        {formatMessage(restrictedSubstanceTableMessages.displayCount, {
          currentCount: filteredItems.length,
          totalCount,
        })}
      </Typography>

      <Datatable
        columns={columns}
        dataSource={substanceWithRegulationsInfo}
        pagination={{
          sorter,
          pageSizeOptions: pagination?.pageSizeOptions,
          showSizeChanger: pagination?.showSizeChanger,
          total: totalCount,
          hideOnSinglePage: !shouldPaginationBeDisplay(totalCount),
          onShowSizeChange: onPaginationChange,
          onChange: onPaginationChange,
        }}
        onChange={onTableChange}
        rowKey={({
          substanceId,
          componentId,
        }: PackagingComponentSubstanceViewModel) =>
          `${substanceId}-${componentId}`
        }
        loading={isLoading || isRegulationsInfoLoading}
        emptyText={formatMessage(restrictedSubstanceTableMessages.emptyMessage)}
        expandable={{
          expandedRowRender: ({ regulations }) => (
            <div className="p-1">
              <RegulationsInfo regulationsInfo={regulations} />
            </div>
          ),
        }}
      />
    </ErrorBoundary>
  );
};

export default RestrictedSubstanceTable;
