import { useContext } from "react";
import { LibraryDetails } from "pages/Library/components/Details/contexts";
import InformationCard from "components/InformationCard";
import { isClaimTypeNutritional } from "utils/claim";
import {
  isLibraryObjectClaimType,
  isLibraryObjectTemplatesType,
} from "utils/library";

const LibraryItemInfo = () => {
  const { libraryItemDetails, typeDictionary } = useContext(LibraryDetails);
  const { generalInformation = {}, versionNumber } = libraryItemDetails;
  const {
    type,
    lastModificationDate,
    status,
    tags,
    internalCode,
  } = generalInformation;

  const isNutritionalClaim = isClaimTypeNutritional(
    libraryItemDetails?.claimType
  );

  return (
    <InformationCard
      type={typeDictionary?.[type] || type}
      lastModificationDate={lastModificationDate}
      status={status}
      tags={isNutritionalClaim ? undefined : tags}
      internalCode={internalCode}
      versionNumber={versionNumber}
      productTypeId={generalInformation?.productTypeId}
      displayVersionNumber={
        !isLibraryObjectTemplatesType(type) && !isLibraryObjectClaimType(type)
      }
    />
  );
};

export default LibraryItemInfo;
