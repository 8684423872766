import { useIntl } from "react-intl";
import { Icon } from "@trace-one/design-system";
import SubstancesTableInput from "../../components/SubstancesTableInput";
import SubstancesTableActions from "components/SubstancesTable/components/SubstancesTableActions";
import { useSubstancesTableContext } from "components/SubstancesTable/context/SubstancesTableContext";
import { EMPTY_VALUE } from "utils/constants";
import {
  findPropertyByKey,
  getFieldTitleFromUiSchemaArrayTemplate,
  getFieldTitleFromUiSchemaObjectTemplate,
} from "utils/general";
import { generalMessages } from "messages";
import { substancesTableMessages } from "components/SubstancesTable/messages";
import { PackagingSubstanceTableViewModel } from "viewModels";

export type UseSubstancesTableProps = {
  isRestricted?: boolean;
};

const useSubstancesTable = ({ isRestricted }: UseSubstancesTableProps) => {
  const { formatMessage } = useIntl();

  const {
    schema,
    uiSchema,
    onRemove,
    removing,
    actionsDisabled,
    onOpenModal,
  } = useSubstancesTableContext();

  const percentageSchema = findPropertyByKey(schema, "percentage");
  const sizeSchema = findPropertyByKey(schema, "size");
  const specificSurfaceSchema = findPropertyByKey(schema, "specificSurface");

  let columns: {
    title: React.ReactNode;
    dataIndex?: string;
    key: string;
    width: string | number;
    render: (_) => React.ReactNode;
    fixed?: string;
  }[] = [
    {
      title: formatMessage(substancesTableMessages.substanceName),
      dataIndex: "name",
      key: "name",
      width: isRestricted && "20%",
      render: (name: PackagingSubstanceTableViewModel["name"]) => {
        return name || EMPTY_VALUE;
      },
    },

    {
      title: formatMessage(substancesTableMessages.casNumber),
      dataIndex: "casNumbers",
      key: "casNumbers",
      width: "10%",
      render: (casNumbers: PackagingSubstanceTableViewModel["casNumbers"]) => {
        const CASNumber = casNumbers?.join("; ");

        return CASNumber || EMPTY_VALUE;
      },
    },

    {
      title: getFieldTitleFromUiSchemaArrayTemplate(
        uiSchema?.substances,
        "percentage"
      ),
      dataIndex: "percentage",
      key: "percentage",
      width: "10%",
      render: (percentage: PackagingSubstanceTableViewModel["percentage"]) => {
        return (
          <SubstancesTableInput
            value={percentage}
            key={percentageSchema?.propertyId}
            schema={percentageSchema}
          />
        );
      },
    },
    {
      title: formatMessage(substancesTableMessages.nanoPresence),
      dataIndex: "presentAtNanoscale",
      key: "presentAtNanoscale",
      width: "10%",
      render: (
        presentAtNanoscale: PackagingSubstanceTableViewModel["presentAtNanoscale"]
      ) => {
        const message = presentAtNanoscale ? "yes" : "no";

        return formatMessage(generalMessages[message]);
      },
    },
    {
      title: getFieldTitleFromUiSchemaObjectTemplate(
        uiSchema?.substances?.items?.presenceAtNanoscale,
        "size"
      ),
      dataIndex: "presenceAtNanoscale",
      key: "size",
      width: "10%",
      render: (
        presenceAtNanoscale: PackagingSubstanceTableViewModel["presenceAtNanoscale"] = {}
      ) => {
        const { size } = presenceAtNanoscale;

        if (!size) return EMPTY_VALUE;

        return (
          <SubstancesTableInput
            value={size}
            key={sizeSchema?.propertyId}
            schema={sizeSchema}
          />
        );
      },
    },
    {
      title: getFieldTitleFromUiSchemaObjectTemplate(
        uiSchema?.substances?.items?.presenceAtNanoscale,
        "specificSurface"
      ),
      dataIndex: "presenceAtNanoscale",
      key: "specificSurface",
      width: isRestricted && "10%",
      render: (
        presenceAtNanoscale: PackagingSubstanceTableViewModel["presenceAtNanoscale"] = {}
      ) => {
        const { specificSurface } = presenceAtNanoscale;

        if (!specificSurface) return EMPTY_VALUE;

        return (
          <SubstancesTableInput
            value={specificSurface}
            key={specificSurfaceSchema?.propertyId}
            schema={specificSurfaceSchema}
          />
        );
      },
    },
  ];

  if (isRestricted) {
    columns = [
      ...columns,
      {
        title: formatMessage(substancesTableMessages.endocrineDisruptor),
        dataIndex: "isEndocrineDisruptor",
        key: "isEndocrineDisruptor",
        width: "10%",
        render: (
          isEndocrineDisruptor: PackagingSubstanceTableViewModel["isEndocrineDisruptor"]
        ) => {
          if (isEndocrineDisruptor) return formatMessage(generalMessages.yes);
          return formatMessage(generalMessages.no);
        },
      },
      {
        title: formatMessage(substancesTableMessages.FRFApplicable),
        dataIndex: "isFrfApplicable",
        key: "isFrfApplicable",
        width: "10%",
        render: (
          isFrfApplicable: PackagingSubstanceTableViewModel["isFrfApplicable"]
        ) => {
          if (isFrfApplicable === undefined) return EMPTY_VALUE;
          if (isFrfApplicable) return formatMessage(generalMessages.yes);
          return formatMessage(generalMessages.no);
        },
      },
      {
        title: formatMessage(substancesTableMessages.SML),
        dataIndex: "sml",
        key: "sml",
        width: "12%",
        render: (sml: PackagingSubstanceTableViewModel["sml"]) => {
          if (sml) return sml;
          return EMPTY_VALUE;
        },
      },
    ];
  }

  columns.push({
    title: () => <Icon name="settings" color="white" />,
    key: "remove-action",
    width: 50,
    render: (substance: PackagingSubstanceTableViewModel) => (
      <SubstancesTableActions
        substance={substance}
        onRemove={onRemove}
        disabled={actionsDisabled || removing}
        onOpenModal={onOpenModal?.(substance)}
      />
    ),
  });

  return {
    columns,
  };
};

export default useSubstancesTable;
