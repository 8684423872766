import { useMemo } from "react";
import { useSelector } from "react-redux";
import useUserInfo from "hooks/useUserInfo";
import { selectMaterialInformation } from "store/materialForm/selectors";
import { selectOwningCompanyId } from "store/user/selectors";
import { isLibraryObjectLocked, isLibraryObjectPublished } from "utils/library";

const useMaterialPermissions = () => {
  const { state, ownerCompanyId } = useSelector(selectMaterialInformation);
  const owningCompanyId = useSelector(selectOwningCompanyId);

  const { isRetailer } = useUserInfo();

  const disableActions = useMemo(() => {
    if (isRetailer) {
      return ownerCompanyId !== owningCompanyId;
    }

    return isLibraryObjectLocked(state);
  }, [state, isRetailer]);

  const canUpdateInformation = useMemo(() => {
    if (!disableActions && !isLibraryObjectPublished(state)) {
      return true;
    }

    return false;
  }, [state, disableActions]);

  return {
    disableActions,
    canUpdateInformation,
  };
};

export default useMaterialPermissions;
