import { defineMessages } from "react-intl";

const messages = defineMessages({
  category: {
    id: "filters.category",
    defaultMessage: "Category",
  },
  productType: {
    id: "filters.productType",
    defaultMessage: "Product type",
  },
  documentType: {
    id: "filters.documentType",
    defaultMessage: "Document type",
  },
  requirementType: {
    id: "filters.requirementType",
    defaultMessage: "Requirement type",
  },
  chapterType: {
    id: "filters.chapterType",
    defaultMessage: "Chapter type",
  },
  retailers: {
    id: "filters.retailer",
    defaultMessage: "Retailers",
  },
  retailersPlaceholder: {
    id: "filters.retailer.placeholder",
    defaultMessage: "Select a retailer",
  },
  ownerOrRetailerContact: {
    id: "filters.ownerOrRetailerContact",
    defaultMessage: "Owner(s) / Retailer contact(s)",
  },
  ownerPlaceholder: {
    id: "filters.owner.placeholder",
    defaultMessage: "Select a person",
  },
  noOwner: {
    id: "filters.owner.empty",
    defaultMessage: "No person found",
  },
  ownerError: {
    id: "filters.owner.error",
    defaultMessage: "Error, can't load person data",
  },
  suppliers: {
    id: "filters.supplier",
    defaultMessage: "Suppliers",
  },
  suppliersPlaceholder: {
    id: "filters.supplier.placeholder",
    defaultMessage: "Select a supplier",
  },
  status: {
    id: "status",
    defaultMessage: "Status",
  },
  uploadedBy: {
    id: "filters.uploadedBy",
    defaultMessage: "Uploaded by",
  },
  chapterAndSection: {
    id: "filters.chapterAndSection",
    defaultMessage: "Chapter / Section",
  },
  translationFilterSearchPlaceholder: {
    id: "filters.translation.search.placeholder",
    defaultMessage: "Search by product name",
  },
  nutrient: {
    id: "filters.nutrient",
    defaultMessage: "Nutrient",
  },
});

export default messages;
