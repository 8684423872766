import React from "react";
import { EmptyState, Card, Typography } from "@trace-one/design-system";
import { useFormSchemaTranslation } from "pages/Specification/components/Details/hooks";
import { PackagingSubstance } from "models";
import {
  getFieldTitleFromUiSchemaArrayTemplate,
  getFieldTitleFromUiSchemaObjectTemplate,
  isListEmpty,
} from "utils/general";
import { Col, Row } from "antd";
import styles from "./styles.module.less";
import { EMPTY_VALUE } from "utils/constants";
import { UiSchema } from "@rjsf/core";

export interface PackagingSubstanceSummaryProps {
  substances: PackagingSubstance[];
  setSubstances: React.Dispatch<React.SetStateAction<PackagingSubstance[]>>;
  uiSchema: UiSchema;
}

export const PackagingSubstanceSummary: React.FC<PackagingSubstanceSummaryProps> = ({
  substances,
  setSubstances,
  uiSchema,
}) => {
  const { getTranslationForKey } = useFormSchemaTranslation();
  const onRemoveSubstance = (substanceId: string) => {
    setSubstances(
      substances.filter(
        substance => substance.selectedSubstance.id !== substanceId
      )
    );
  };

  const renderRow = (title: string, value: string | number) => {
    return (
      <Row className="mb-2">
        <Col md={7}>
          <Typography variant="heading-xxs" color="grey-5">
            {title}
          </Typography>
        </Col>
        <Col md={17}>
          <Typography variant="body-regular" color="grey-5">
            {value}
          </Typography>
        </Col>
      </Row>
    );
  };

  return (
    <div className="mt-1">
      {isListEmpty(substances) ? (
        <Row
          align="middle"
          justify="center"
          className={styles["substance-empty-containter"]}
        >
          <EmptyState
            description={getTranslationForKey("substanceSelectionEmptyState")}
          />
        </Row>
      ) : (
        <>
          {substances.map(substance => {
            const {
              selectedSubstance,
              percentage,
              presentAtNanoscale,
              presenceAtNanoscale,
            } = substance;
            const { casNumbers: casNumbersList } = selectedSubstance || {};
            const casNumbers = casNumbersList?.join("; ");

            const { size, specificSurface } = presenceAtNanoscale || {};

            return (
              <Card
                key={selectedSubstance.id}
                size="xxsmall"
                type="expandable"
                title={selectedSubstance.name}
                headerActions={[
                  {
                    key: selectedSubstance.id,
                    type: "button",
                    args: {
                      iconName: "trash",
                      type: "tertiary",
                      color: "red",
                      onClick: () => onRemoveSubstance(selectedSubstance.id),
                    },
                  },
                ]}
                className="mb-2"
              >
                {renderRow(
                  getTranslationForKey("casNumber"),
                  casNumbers
                    ? casNumbers
                    : getTranslationForKey("undefinedMessage")
                )}
                {renderRow(
                  getFieldTitleFromUiSchemaArrayTemplate(
                    uiSchema.substances,
                    "percentage"
                  ),
                  percentage >= 0 ? percentage : EMPTY_VALUE
                )}
                {renderRow(
                  getFieldTitleFromUiSchemaArrayTemplate(
                    uiSchema.substances,
                    "presentAtNanoscale"
                  ),
                  presentAtNanoscale
                    ? getTranslationForKey("yes")
                    : getTranslationForKey("no")
                )}
                {presentAtNanoscale &&
                  renderRow(
                    getFieldTitleFromUiSchemaObjectTemplate(
                      uiSchema?.substances?.items?.presenceAtNanoscale,
                      "size"
                    ),
                    size >= 0 ? size : EMPTY_VALUE
                  )}
                {presentAtNanoscale &&
                  renderRow(
                    getFieldTitleFromUiSchemaObjectTemplate(
                      uiSchema?.substances?.items?.presenceAtNanoscale,
                      "specificSurface"
                    ),
                    specificSurface >= 0 ? specificSurface : EMPTY_VALUE
                  )}
              </Card>
            );
          })}
        </>
      )}
    </div>
  );
};
