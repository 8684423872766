import classnames from "classnames";
import { ColumnsType } from "antd/lib/table";
import { GetComponentProps } from "rc-table/lib/interface";
import ActionButton from "components/ActionButton";
import { Tooltip } from "@trace-one/design-system";
import PercentageInput from "components/PercentageInput";
import { isListEmpty } from "utils/general";
import { getSortOrder } from "utils/pagination";
import { Dictionary } from "types/general";
import { Sorter } from "types/pagination";
import libraryMessages from "messages/library";
import generalMessages from "messages/general";
import { MaterialRecipeIngredientViewModel } from "viewModels";
import formStyles from "./styles.module.less";
import styles from "components/Library/components/MaterialForm/styles.module.less";

export const getColumns = ({
  formatMessage,
  onRemoveIngredient,
  onUpdateIngredientPercentage,
  displayActions,
  disableActions,
  catalogDictionary,
  onCell,
  recipeSorter,
  canSort,
}: {
  formatMessage: Function;
  onRemoveIngredient?: Function;
  onUpdateIngredientPercentage?: Function;
  displayActions?: boolean;
  disableActions?: boolean;
  catalogDictionary?: Dictionary;
  onCell?: GetComponentProps<MaterialRecipeIngredientViewModel>;
  recipeSorter?: Sorter;
  canSort?: boolean;
}): ColumnsType<MaterialRecipeIngredientViewModel> => [
  {
    title: formatMessage(libraryMessages["columns"]["name"]),
    dataIndex: "name",
    width: "30%",
    sorter: canSort,
    sortDirections: ["ascend", "descend", "ascend"],
    sortOrder:
      recipeSorter?.orderBy === "name"
        ? getSortOrder(recipeSorter?.orderByDescending)
        : null,
    className: classnames(styles.recipeCell, {
      [styles.actionCell]: !disableActions && displayActions,
    }),
    ellipsis: {
      showTitle: false,
    },
    render: name =>
      name && (
        <Tooltip placement="topLeft" text={name}>
          {name}
        </Tooltip>
      ),
    ...(!disableActions &&
      displayActions && {
        onCell,
      }),
  },
  {
    title: formatMessage(libraryMessages["columns"]["type"]),
    dataIndex: "type",
    width: "15%",
    sorter: canSort,
    sortDirections: ["ascend", "descend", "ascend"],
    sortOrder:
      recipeSorter?.orderBy === "type"
        ? getSortOrder(recipeSorter?.orderByDescending)
        : null,
    className: classnames(styles.recipeCell, {
      [styles.actionCell]: !disableActions && displayActions,
    }),
    render: type => (
      <span data-test-id="ingredient-type">
        {formatMessage(libraryMessages["type"][type])}
      </span>
    ),
    ...(!disableActions &&
      displayActions && {
        onCell,
      }),
  },
  {
    title: formatMessage(libraryMessages["columns"]["classification"]),
    dataIndex: "classificationId",
    width: "35%",
    className: classnames(styles.recipeCell, {
      [styles.actionCell]: !disableActions && displayActions,
    }),
    render: classificationId => catalogDictionary?.[classificationId],
    ...(!disableActions &&
      displayActions && {
        onCell,
      }),
  },
  {
    title: (
      <span>
        %<span className={styles?.["recipe-table__percentage-required"]}></span>
      </span>
    ),
    dataIndex: "percentage",
    width: "15%",
    sorter: canSort,
    sortDirections: ["ascend", "descend", "ascend"],
    sortOrder:
      recipeSorter?.orderBy === "percentage"
        ? getSortOrder(recipeSorter?.orderByDescending)
        : null,
    render: (_, ingredient) => {
      const { percentage, relativePercentage } = ingredient;
      const value = relativePercentage ?? percentage;

      return displayActions ? (
        <PercentageInput
          defaultValue={value}
          onChange={onUpdateIngredientPercentage(ingredient)}
          disabled={disableActions}
          precision={3}
        />
      ) : (
        value
      );
    },
  },
  {
    ...(displayActions && {
      key: "action",
      title: formatMessage(libraryMessages["columns"]["action"]),
      width: 100,
      render: (_, { ingredientId, parentIdsPath }) => (
        <ActionButton
          data-test-id="ingredient-actions"
          className={styles.actionColumnAlignment}
          {...(!disableActions && {
            actions: [
              {
                label: formatMessage(generalMessages.remove),
                "data-test-id": "remove-ingredient",
                tooltipProps: {
                  onConfirmation: onRemoveIngredient(
                    ingredientId,
                    parentIdsPath
                  ),
                },
              },
            ],
          })}
        />
      ),
    }),
  },
];

export const getClassName = (ingredient: MaterialRecipeIngredientViewModel) => (
  record: MaterialRecipeIngredientViewModel
) => {
  let rowClassName = "";

  if (record?.ingredientId === "new-ingredient-id") {
    rowClassName = formStyles.tableHighlight;
  } else {
    if (record?.ingredientId === ingredient?.ingredientId) {
      if (isListEmpty(record?.ingredients)) {
        rowClassName = formStyles.tableHighlight;
      } else {
        rowClassName = classnames({
          [formStyles.tableHighlight]:
            record?.ingredients.findIndex(
              ({ ingredientId }) => ingredientId === "new-ingredient-id"
            ) === -1,
        });
      }
    }
  }

  return rowClassName;
};
