import { createContext, useContext } from "react";
import { PackagingSystemComponentData } from "models";
import { SelectOption } from "types/general";
import { UnsavedComponentLinkViewModel } from "viewModels";

const LinksDetailsContext = createContext<{
  hasUnselectedComponent: boolean;
  options: SelectOption[];
  selectedPackagingComponent: PackagingSystemComponentData;
  onChangeComponentIdToLink: (id: string) => (value: string) => void;
  selectedComponentId: PackagingSystemComponentData["componentId"];
  linkedComponentIds?: PackagingSystemComponentData["componentId"][];
  unsavedLinkedComponentIds?: PackagingSystemComponentData["componentId"][];
  selectedComponentLinks?: UnsavedComponentLinkViewModel[];
  saving?: boolean;
  onSave: () => Promise<void>;
  onUnlink: (_: string) => Promise<void>;
  onUnlinkAll: () => Promise<void>;
  onAddALink?: () => void;
  isModalContentScrollable?: boolean;
} | null>(null);

export const LinksDetailsProvider = LinksDetailsContext.Provider;

export const useLinksDetailsContext = () => {
  const context = useContext(LinksDetailsContext);
  if (!context) {
    throw new Error(
      "useLinksDetailsContext must be used within a LinksDetailsProvider"
    );
  }
  return context;
};
