import { useMemo, useState } from "react";
import { orderBy } from "lodash";
import { useIntl } from "react-intl";
import { SidebarItem } from "components";
import RestrictedSubstanceTable from "../../../RestrictedSubstanceTable";
import { usePackagingSystemContext } from "../../../../context/packagingSystemContext";
import { useCatalogDictionary } from "hooks";
import { PACKAGING_SYSTEM_RESTRICTED_SUBSTANCES_KEYS } from "utils/constants";
import { getUniqueListItems } from "utils/general";
import {
  addComponentDetailToSubstance,
  getRestrictedSubstances,
} from "../../utils";
import { PackagingComponentSubstanceViewModel } from "viewModels";
import { generalMessages } from "messages";

const useRestrictionSummary = () => {
  const { formatMessage } = useIntl();

  const {
    packagingSystemComponentsData,
    isLoading,
    packagingSystemId,
  } = usePackagingSystemContext();

  const [selectedMenuItem, setSelectedMenuItem] = useState<string>(
    PACKAGING_SYSTEM_RESTRICTED_SUBSTANCES_KEYS.ALL
  );

  const sidebarItems: SidebarItem[] = packagingSystemComponentsData.map(
    component => {
      return {
        label: component?.name,
        value: component?.componentId,
      };
    }
  );

  sidebarItems.unshift({
    label: formatMessage(generalMessages.allPlural),
    value: PACKAGING_SYSTEM_RESTRICTED_SUBSTANCES_KEYS.ALL,
  });

  const getSelectedMenuItem = (selectedMenuItem: string) => {
    setSelectedMenuItem(selectedMenuItem);
  };

  const allSubstances: PackagingComponentSubstanceViewModel[] = useMemo(
    () =>
      packagingSystemComponentsData.reduce(
        (acc, { recyclability, componentId, name }) => {
          const substances = recyclability?.substancesSection?.substances ?? [];

          const substancesWithComponentId = substances.map(substance =>
            addComponentDetailToSubstance({
              substance,
              componentId,
              componentName: name,
            })
          );

          acc = [...acc, ...substancesWithComponentId];

          return acc;
        },
        []
      ),
    [JSON.stringify(packagingSystemComponentsData)]
  );

  const substanceIds = getUniqueListItems({
    list: allSubstances,
    fieldName: "substanceId",
  });

  const {
    substanceInformationCollection,
    substanceRegulations,
    isLoading: isSubstancesLoading,
  } = useCatalogDictionary({
    substanceIds: substanceIds as string[],
    shouldFilterIds: false,
    shouldGetSubstanceRegulationData: true,
  });

  const componentDataMap = useMemo(
    () =>
      packagingSystemComponentsData.reduce(
        (acc, { recyclability, componentId, name }) => {
          if (componentId) {
            const substances =
              recyclability?.substancesSection?.substances ?? [];

            const substancesWithComponentId = substances.map(substance =>
              addComponentDetailToSubstance({
                substance,
                componentId,
                componentName: name,
              })
            );
            acc[componentId] = substancesWithComponentId;
          }
          return acc;
        },
        {}
      ),
    [JSON.stringify(packagingSystemComponentsData)]
  );

  componentDataMap[
    PACKAGING_SYSTEM_RESTRICTED_SUBSTANCES_KEYS.ALL
  ] = allSubstances;

  const contentMap = Object.keys(componentDataMap).reduce((acc, cur) => {
    const restrictedSubstances = getRestrictedSubstances({
      substanceInformationCollection,
      substanceRegulations,
      substances: componentDataMap[cur],
    });

    acc[cur] = (
      <RestrictedSubstanceTable
        data={orderBy(restrictedSubstances, "percentage", "desc")}
        isLoading={isSubstancesLoading}
        key={cur}
        canSort={cur === PACKAGING_SYSTEM_RESTRICTED_SUBSTANCES_KEYS.ALL}
        packagingSystemId={packagingSystemId}
      />
    );

    return acc;
  }, {});

  return {
    isLoading,
    selectedMenuItem,
    getSelectedMenuItem,
    sidebarItems,
    contentMap,
  };
};

export default useRestrictionSummary;
