import { useMemo } from "react";
import classnames from "classnames";
import { Row } from "antd";
import { EmptyState, Button } from "@trace-one/design-system";
import ClaimList from "./components/ClaimList";
import SelectClaimsModal from "./components/SelectClaimsModal";
import ClaimsReadOnly from "./components/ClaimsReadOnly";
import { useModalVisibility, useClaimCollection } from "hooks";
import { useFormSchemaTranslation } from "pages/Specification/components/Details/hooks";
import { getArraySchemaMaxItems, isListEmpty } from "utils/general";
import { getClaimIdsWithError, getFormattedRjsfObjects } from "./utils";
import {
  ArraySchema,
  ObjectFieldTemplateProps,
} from "components/SpecificationSection/types";
import { ClaimFormData } from "types/specification";

interface ClaimsFieldTemplateProps
  extends Partial<ObjectFieldTemplateProps<ClaimFormData>> {
  noItemsMessage?: string;
}

const ClaimsFieldTemplate: React.FC<ClaimsFieldTemplateProps> = ({
  noItemsMessage,
  schema: defaultSchema,
  uiSchema: defaultUiSchema,
  formContext,
}) => {
  const {
    extraActions,
    extraErrors,
    isWidgetEditable,
    displayActions,
    formData: defaultFormData = {},
  } = formContext;

  const { getTranslationForKey } = useFormSchemaTranslation();
  const { isModalOpen, onOpenModal, onCloseModal } = useModalVisibility();

  const { schema, uiSchema, formData } = useMemo(
    () =>
      getFormattedRjsfObjects({
        schema: defaultSchema,
        uiSchema: defaultUiSchema,
        formData: defaultFormData,
      }),
    [defaultSchema, defaultUiSchema, defaultFormData]
  );

  const marketingClaimIds =
    formData?.marketingClaims?.map(({ claimId }) => claimId) || [];
  const healthClaimIds =
    formData?.healthClaims?.map(({ claimId }) => claimId) || [];
  const nutritionalClaimIds =
    formData?.nutritionalClaims?.map(({ claimId }) => claimId) || [];

  const {
    marketingClaimCollection,
    healthClaimCollection,
    nutritionalClaimCollection,
  } = useClaimCollection({
    marketingClaimIds,
    healthClaimIds,
    nutritionalClaimIds,
  });

  const isClaimsEmpty =
    isListEmpty(marketingClaimCollection) &&
    isListEmpty(healthClaimCollection) &&
    isListEmpty(nutritionalClaimCollection);

  const disabledClaimIds = useMemo(
    () => [...healthClaimIds, ...marketingClaimIds, ...nutritionalClaimIds],
    [marketingClaimIds, healthClaimIds, nutritionalClaimIds]
  );

  const {
    healthClaimIdsWithError,
    marketingClaimIdsWithError,
    nutritionalClaimIdsWithError,
  } = useMemo(
    () => ({
      healthClaimIdsWithError: getClaimIdsWithError({
        claims: formData?.healthClaims,
        errors: extraErrors?.healthClaims,
      }),
      marketingClaimIdsWithError: getClaimIdsWithError({
        claims: formData?.marketingClaims,
        errors: extraErrors?.marketingClaims,
      }),
      nutritionalClaimIdsWithError: getClaimIdsWithError({
        claims: formData?.nutritionalClaims,
        errors: extraErrors?.nutritionalClaims,
      }),
    }),
    [extraErrors]
  );

  const healthClaimMaxItems = getArraySchemaMaxItems(
    schema?.properties?.healthClaims as ArraySchema
  );
  const marketingClaimMaxItems = getArraySchemaMaxItems(
    schema?.properties?.marketingClaims as ArraySchema
  );
  const nutritionalClaimMaxItems = getArraySchemaMaxItems(
    schema?.properties?.nutritionalClaims as ArraySchema
  );

  const maxItems =
    healthClaimMaxItems + marketingClaimMaxItems + nutritionalClaimMaxItems; // refactor if it is decided to have claim wise limit

  const propertyIds = Object.values(schema?.properties)?.map(
    ({ propertyId }) => propertyId
  );

  const canUpdateClaims = propertyIds?.every(propertyId =>
    isWidgetEditable(propertyId)
  );

  if (!displayActions) {
    return (
      <ClaimsReadOnly
        marketingClaims={marketingClaimCollection}
        healthClaims={healthClaimCollection}
        nutritionalClaims={nutritionalClaimCollection}
        formData={formData}
        noItemsMessage={noItemsMessage}
      />
    );
  }

  return (
    <>
      {isClaimsEmpty ? (
        <Row justify="center">
          <EmptyState heading={noItemsMessage} />
        </Row>
      ) : (
        <>
          {!isListEmpty(nutritionalClaimCollection) && (
            <div>
              <ClaimList
                key="nutritional-claim"
                title={getTranslationForKey("nutritionalClaims")}
                claimCollection={nutritionalClaimCollection}
                uiSchema={uiSchema.nutritionalClaims}
                schema={schema?.properties?.nutritionalClaims as ArraySchema}
                formContext={formContext}
                formData={formData}
                supportingDocErrorClaimIds={nutritionalClaimIdsWithError}
              />
            </div>
          )}

          {!isListEmpty(healthClaimCollection) && (
            <div
              className={classnames({
                "mt-3": !isListEmpty(nutritionalClaimCollection),
              })}
            >
              <ClaimList
                key="health-claim"
                title={getTranslationForKey("healthClaims")}
                claimCollection={healthClaimCollection}
                uiSchema={uiSchema.healthClaims}
                schema={schema?.properties?.healthClaims as ArraySchema}
                formContext={formContext}
                formData={formData}
                supportingDocErrorClaimIds={healthClaimIdsWithError}
              />
            </div>
          )}

          {!isListEmpty(marketingClaimCollection) && (
            <div
              className={classnames({
                "mt-3":
                  !isListEmpty(healthClaimCollection) ||
                  !isListEmpty(nutritionalClaimCollection),
              })}
            >
              <ClaimList
                key="marketing-claim"
                title={getTranslationForKey("marketingClaims")}
                claimCollection={marketingClaimCollection}
                uiSchema={uiSchema.marketingClaims}
                schema={schema?.properties?.marketingClaims as ArraySchema}
                formContext={formContext}
                formData={formData}
                supportingDocErrorClaimIds={marketingClaimIdsWithError}
              />
            </div>
          )}
        </>
      )}

      {canUpdateClaims && (
        <Row
          justify={isClaimsEmpty ? "center" : "start"}
          className={isClaimsEmpty ? "mt-2" : "mt-3"}
        >
          <Button
            onClick={onOpenModal}
            type="secondary"
            disabled={
              // refactor if it is decided to have claim wise limit
              (formData?.healthClaims?.length || 0) +
                (formData?.marketingClaims?.length || 0) +
                (formData?.nutritionalClaims?.length || 0) >=
              maxItems
            }
          >
            {getTranslationForKey("addClaims")}
          </Button>
        </Row>
      )}

      {isModalOpen && (
        <SelectClaimsModal
          onCloseModal={onCloseModal}
          disabledClaimIds={disabledClaimIds}
          updateClaims={extraActions?.onUpdateClaims}
          formData={formData}
          maxItems={maxItems}
        />
      )}
    </>
  );
};

export default ClaimsFieldTemplate;
