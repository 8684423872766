import { useEffect, useMemo, useState } from "react";
import { v4 } from "uuid";
import { isEqual } from "lodash";
import { useBoolean } from "hooks";
import { useAppDispatch } from "store";
import { getPackagingSystemSmartDoc } from "store/packagingSystemForm/asyncActions";
import { PackagingComponentLink } from "models";
import { UnsavedComponentLinkViewModel } from "viewModels";
import { linkPackagingSystemComponents } from "apis/PKG";
import { getLinkedComponentIds } from "../../utils";
import { isListEmpty, selectFieldsFromObjects, sortByKey } from "utils/general";

export type UseLinksDetailsModalProps = {
  packagingSystemId: string;
  selectedComponentId: string;
  componentLinks?: PackagingComponentLink[];
};

const useLinksDetailsModal = ({
  packagingSystemId,
  selectedComponentId,
  componentLinks: allComponentLinks = [],
}: UseLinksDetailsModalProps) => {
  const dispatch = useAppDispatch();

  const { selectedComponentLinks, otherComponentLinks } = useMemo(() => {
    return allComponentLinks.reduce(
      (acc, link) => {
        const { packagingComponentId1, packagingComponentId2 } = link;
        const isSelectedComponentLink = [
          packagingComponentId1,
          packagingComponentId2,
        ].includes(selectedComponentId);

        if (isSelectedComponentLink) {
          acc.selectedComponentLinks.push({
            ...link,
            id: v4(), // assign a new ID to edit selected component's links
          });
        } else {
          acc.otherComponentLinks.push(link);
        }

        return acc;
      },
      {
        selectedComponentLinks: [] as UnsavedComponentLinkViewModel[],
        otherComponentLinks: [] as PackagingComponentLink[],
      }
    );
  }, [allComponentLinks, selectedComponentId]);

  const [unsavedComponentLinks, setUnsavedComponentLinks] = useState<
    UnsavedComponentLinkViewModel[]
  >(selectedComponentLinks);

  useEffect(() => {
    setUnsavedComponentLinks(selectedComponentLinks);
  }, [selectedComponentLinks]);

  const {
    value: saving,
    setTrue: setSavingTrue,
    setFalse: setSavingFalse,
  } = useBoolean(false);

  const linkedComponentIds = useMemo(
    () =>
      getLinkedComponentIds({
        componentLinks: selectedComponentLinks,
        selectedComponentId,
      }),
    [selectedComponentLinks, selectedComponentId]
  );

  const unsavedLinkedComponentIds = useMemo(
    () =>
      getLinkedComponentIds({
        componentLinks: unsavedComponentLinks,
        selectedComponentId,
      }),
    [unsavedComponentLinks, selectedComponentId]
  );

  const hasUnselectedComponent = useMemo(() => {
    const unsavedComponentIdsSet = new Set(unsavedLinkedComponentIds);

    return unsavedComponentIdsSet.has(null);
  }, [linkedComponentIds, unsavedLinkedComponentIds]);

  const { selectedComponentUnsavedLinks, hasUnsavedChanges } = useMemo(() => {
    const selectedComponentUnsavedLinks = selectFieldsFromObjects({
      arr: unsavedComponentLinks,
      fields: ["packagingComponentId1", "packagingComponentId2"],
    });

    const selectedComponentSavedLinks = selectFieldsFromObjects({
      arr: selectedComponentLinks,
      fields: ["packagingComponentId1", "packagingComponentId2"],
    });

    return {
      selectedComponentUnsavedLinks,
      hasUnsavedChanges: !isEqual(
        sortByKey({
          array: [...selectedComponentSavedLinks],
          key: "packagingComponentId1",
        }),
        sortByKey({
          array: [...selectedComponentUnsavedLinks],
          key: "packagingComponentId1",
        })
      ),
    };
  }, [unsavedComponentLinks, selectedComponentLinks]);

  const onSave = async () => {
    try {
      setSavingTrue();

      await linkPackagingSystemComponents({
        packagingSystemId,
        data: {
          componentLinks: [
            ...otherComponentLinks,
            ...selectedComponentUnsavedLinks,
          ],
        },
      });

      await dispatch(getPackagingSystemSmartDoc());

      setSavingFalse();
    } catch {
      setSavingFalse();
    }
  };

  const onUnlinkAll = async () => {
    setUnsavedComponentLinks([]);
  };

  const onUnlink = async (idToUnlink: string) => {
    setUnsavedComponentLinks(prevState =>
      prevState.filter(({ id }) => id !== idToUnlink)
    );
  };

  const onAddALink = () => {
    setUnsavedComponentLinks(prevState => {
      const newComponentLink: UnsavedComponentLinkViewModel = {
        id: v4(),
        packagingComponentId1: null,
        packagingComponentId2: null,
      };

      if (isListEmpty(prevState)) {
        return [newComponentLink];
      }

      return [...prevState, newComponentLink];
    });
  };

  const onChangeComponentIdToLink = (id: string) => (value: string) => {
    setUnsavedComponentLinks(prevState => {
      const updatedComponentLink: PackagingComponentLink = {
        packagingComponentId1: selectedComponentId,
        packagingComponentId2: value ?? null,
      };

      return prevState.map(existingLink => {
        const { id: existingId } = existingLink;
        if (id === existingId) {
          return {
            id,
            ...updatedComponentLink,
          };
        }

        return existingLink;
      });
    });
  };

  return {
    saving,
    onSave,
    onUnlink,
    onUnlinkAll,
    onAddALink,
    onChangeComponentIdToLink,
    unsavedComponentLinks,
    linkedComponentIds,
    unsavedLinkedComponentIds,
    hasUnselectedComponent,
    hasUnsavedChanges,
  };
};

export default useLinksDetailsModal;
