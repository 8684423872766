import { useEffect, useMemo, useState } from "react";
import { fetchClaimSubstances } from "apis/SPEC";
import useCatalogDictionary from "hooks/useCatalogDictionary";
import useBoolean from "hooks/useBoolean";
import { isListEmpty, isObjectEmpty } from "utils/general";
import { ClaimSubstanceData } from "models";
import { paramsSerializer } from "components/Library/components/List/hooks/useLibraryList/utils";
import { ClaimType } from "types/library";

export type UseClaimSubstancesProps = {
  fetchOnEffect?: boolean;
  claimTypes: ClaimType[];
};

const useClaimSubstances = ({
  fetchOnEffect = true,
  claimTypes = [],
}: UseClaimSubstancesProps) => {
  const [claimSubstances, setClaimSubstances] = useState<ClaimSubstanceData>(
    null
  );
  const { catalogDictionary } = useCatalogDictionary({
    substanceIds: claimSubstances?.itemIds || [],
  });
  const { value: loadingClaimSubstances, setTrue, setFalse } = useBoolean();

  useEffect(() => {
    if (!fetchOnEffect) return;

    const getClaimSubstances = async () => {
      try {
        setTrue();

        const { data } = await fetchClaimSubstances({
          params: { claimTypes },
          paramsSerializer,
        });

        setClaimSubstances(data);
      } catch {
      } finally {
        setFalse();
      }
    };

    getClaimSubstances();
  }, [fetchOnEffect, JSON.stringify(claimTypes)]);

  const claimSubstanceOptions = useMemo(() => {
    if (
      isObjectEmpty(claimSubstances) ||
      isListEmpty(claimSubstances?.itemIds)
    ) {
      return [];
    }

    return claimSubstances.itemIds.map(substanceId => ({
      label: catalogDictionary[substanceId],
      value: substanceId,
      key: substanceId,
    }));
  }, [claimSubstances, catalogDictionary]);

  return { loadingClaimSubstances, claimSubstances, claimSubstanceOptions };
};

export default useClaimSubstances;
