import { useIntl, FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import { Modal } from "antd";
import { Button, Heading, Paragraph } from "@trace-one/design-system";
import { selectCompanyName } from "store/specificationDetails/selectors";
import ModalCloseIcon from "components/ModalCloseIcon";
import { messages } from "../RevisionActions/messages";
import styles from "./styles.module.less";
import { AskForRevisionModalProps } from "./types";

const AskForRevisionModal: React.FC<AskForRevisionModalProps> = ({
  onCancel,
  onShareSpecification,
}) => {
  const { formatMessage } = useIntl();
  const companyName = useSelector(selectCompanyName);

  return (
    <Modal
      width="25%"
      visible
      onCancel={onCancel}
      footer={null}
      closeIcon={<ModalCloseIcon />}
    >
      <div className={styles.container}>
        <div>
          <Heading size="s">
            {formatMessage(messages.askForRevisionModalTitle)}
          </Heading>
        </div>
        <div>
          <Paragraph size="m">
            <FormattedMessage
              {...messages.modalDescription}
              values={{
                retailerName: companyName,
                b: (...chunks) => (
                  <span className={styles.green}>&nbsp;{chunks}</span>
                ),
              }}
            />
          </Paragraph>
        </div>
        <div>
          <Button
            type="secondary"
            onClick={onCancel}
            className={styles.marginRight}
          >
            {formatMessage(messages.closeAskForRevisionModal)}
          </Button>
          <Button type="primary" onClick={onShareSpecification}>
            {formatMessage(messages.shareSpecification)}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default AskForRevisionModal;
