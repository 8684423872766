import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { toaster as toasterService } from "@trace-one/design-system";
import { useBoolean, useModalVisibility, useRowSelection } from "hooks";
import {
  deleteSpecificationClaim,
  reviseSpecificationClaim,
  updateToLastClaimVersion,
} from "apis/SPEC";
import { getLinkedSpecificationsModalActionMap } from "components/Library/components/utils";
import { LIBRARY_OBJECT_TYPES } from "utils/constants";
import {
  LinkedSpecificationsAction,
  LinkedSpecificationsModalProps,
  ModalActionType,
} from "components/LinkedSpecificationsModal/types";
import { UseVersionHistoryActionsProps } from "components/Library/components/types";
import { ClaimData } from "models";
import { generalMessages, versionHistoryMessages } from "messages";

export interface UseClaimVersionHistoryActionsProps
  extends UseVersionHistoryActionsProps {
  activeClaimId?: ClaimData["id"];
}

const useClaimVersionHistoryActions = ({
  versionHistoryCallback,
  linkedSpecificationsCallback,
  activeClaimId,
}: UseClaimVersionHistoryActionsProps) => {
  const { formatMessage } = useIntl();

  const [modalProps, setModalProps] = useState<LinkedSpecificationsModalProps>(
    null
  );
  const [actionData, setActionData] = useState<LinkedSpecificationsAction>({});

  const {
    value: shouldRefetchVersionHistory,
    setTrue,
    setFalse,
  } = useBoolean();
  const { isModalOpen, onOpenModal, onCloseModal } = useModalVisibility();
  const {
    selectedRowKeys,
    onSelectRow,
    onSelectAllRows,
    resetSelectedRowKeys,
  } = useRowSelection();

  const rowSelection = {
    selectedRowKeys,
    onSelect: onSelectRow,
    onSelectAll: onSelectAllRows,
  };

  const handleOpenModal = ({
    selectedLibraryItemId,
    actionType,
    libraryItemState,
    versionNumber,
  }: LinkedSpecificationsAction) => () => {
    setActionData({
      selectedLibraryItemId,
      actionType,
      libraryItemState,
      versionNumber,
    });

    onOpenModal();
  };

  const handleCloseModal = () => {
    setModalProps({});
    setActionData({});
    resetSelectedRowKeys();
    onCloseModal();
  };

  const onUnlinkSpecifications = async () => {
    setTrue();

    try {
      for (let specificationId of selectedRowKeys) {
        await deleteSpecificationClaim({
          specificationId,
          claimId: actionData?.selectedLibraryItemId,
        });
      }

      toasterService.confirmation({
        message: formatMessage(
          versionHistoryMessages.unlinkSpecificationsToasterMessage
        ),
        description: formatMessage(
          versionHistoryMessages.unlinkSpecificationsClaimToasterDescription
        ),
      });
    } catch (error) {
      console.error("Error unlinking specifications:", error);
      throw error;
    } finally {
      setFalse();
      await versionHistoryCallback?.();
      await linkedSpecificationsCallback?.();
      handleCloseModal();
    }
  };

  const onUpdateToLastClaimVersion = async () => {
    try {
      setTrue();

      for (let specificationId of selectedRowKeys) {
        await updateToLastClaimVersion({
          specificationId,
          claimId: actionData?.selectedLibraryItemId,
          payload: {
            newClaimId: activeClaimId,
          },
        });
      }

      toasterService.confirmation({
        message: formatMessage(
          versionHistoryMessages.updateToLatestVersionToasterMessage
        ),
        description: formatMessage(
          versionHistoryMessages.updateToLatestVersionClaimToasterDescription
        ),
      });
    } catch (error) {
      console.error("Error updating to last claim version:", error);
      throw error;
    } finally {
      setFalse();
      await versionHistoryCallback?.();
      await linkedSpecificationsCallback?.();
      handleCloseModal();
    }
  };

  const onReviseSpecifications = async () => {
    try {
      setTrue();

      for (let specificationId of selectedRowKeys) {
        await reviseSpecificationClaim({
          specificationId,
          payload: {
            claimId: actionData?.selectedLibraryItemId,
            newClaimId: activeClaimId,
          },
        });
      }

      toasterService.confirmation({
        message: formatMessage(versionHistoryMessages.reviseToasterMessage),
        description: formatMessage(
          versionHistoryMessages.reviseClaimToasterDescription
        ),
      });
    } catch (error) {
      console.error("Error revising specification:", error);
      throw error;
    } finally {
      setFalse();
      await versionHistoryCallback?.();
      await linkedSpecificationsCallback?.();
      handleCloseModal();
    }
  };

  const ActionTypeMap = getLinkedSpecificationsModalActionMap(
    LIBRARY_OBJECT_TYPES.CLAIMS
  );
  const PrimaryActionMap = {
    [ModalActionType.UNLINK]: onUnlinkSpecifications,
    [ModalActionType.UPDATE]: onUpdateToLastClaimVersion,
    [ModalActionType.REVISE]: onReviseSpecifications,
  };

  useEffect(() => {
    const actionType = actionData.actionType;

    if (actionType) {
      const actionTypeData = ActionTypeMap[actionType];

      setModalProps(prevState => ({
        ...prevState,
        ...actionTypeData,
        title: formatMessage(actionTypeData.title),
        subtitle: formatMessage(actionTypeData.subtitle),
        primaryButtonLabel: formatMessage(actionTypeData.primaryButtonLabel),
        secondaryButtonLabel: formatMessage(generalMessages.cancel),
        onPrimaryButtonClick: PrimaryActionMap[actionType],
        onSecondaryButtonClick: handleCloseModal,
      }));
    }
  }, [actionData, selectedRowKeys]);

  return {
    ...actionData,
    ...modalProps,
    isModalOpen,
    handleOpenModal,
    handleCloseModal,
    rowSelection,
    shouldRefetchVersionHistory,
  };
};

export default useClaimVersionHistoryActions;
