import React from "react";
import { useSelector } from "react-redux";
import { UiSchema } from "@rjsf/core";
import classnames from "classnames";
import { Col } from "antd";
import { UploadFiles } from "@trace-one/business-components";
import ExpandableCardWithToggleAndDelete from "components/ExpandableCardWithToggleAndDelete";
import ClaimDescription from "../ClaimDescription";
import ErrorsList from "components/ErrorsList";
import { useClaimCardContent } from "./hooks";
import { useFormSchemaTranslation } from "pages/Specification/components/Details/hooks";
import { selectLanguageCode } from "store/user/selectors";
import {
  getArraySchemaItemsPropertyIdByKey,
  getFieldTitleFromUiSchemaArrayTemplate,
  isListEmpty,
} from "utils/general";
import {
  ArraySchema,
  FormContext,
} from "components/SpecificationSection/types";
import { ClaimData, RegulatoryHealthClaim } from "models";
import { ClaimFormData } from "types/specification";
import { EMPTY_VALUE, LIBRARY_OBJECT_TYPES } from "utils/constants";
import {
  getTranslationTextByLanguageCode,
  isClaimTypeHealth,
} from "utils/claim";
import { getClaimCardTitle, getClaimDataByIdAndType } from "../../utils";
import styles from "../../styles.module.less";

export type ClaimCardContentProps = {
  claim: ClaimData;
  formData: ClaimFormData;
  formContext: FormContext;
  uiSchema: UiSchema;
  schema: ArraySchema;
  supportingDocErrorClaimIds: string[];
  regulation: RegulatoryHealthClaim;
};

const ClaimCardContent: React.FC<ClaimCardContentProps> = ({
  formData,
  formContext,
  uiSchema,
  schema,
  supportingDocErrorClaimIds,
  regulation,
  claim: {
    id,
    name,
    claimType,
    sentenceTranslations,
    additionalSentenceTranslations,
    conditionOfUse: nutritionalClaimConditionOfUse,
  },
}) => {
  const {
    isTargetMode,
    extraActions,
    hasPermissionToSave,
    ownerCompanyId,
    isRetailerContact,
    isSpecContact,
    specificationData,
    isWidgetEditable,
    disableExtraActions,
    readOnlyFieldIds = [],
  } = formContext;

  const languageCode = useSelector(selectLanguageCode);

  const { getTranslationForKey } = useFormSchemaTranslation();

  const {
    isClaimUpdating,
    handleDisplayOnPackToggle,
    handleRemoveClaim,
    handleFilesUpload,
    handleFileRemove,
    handleEnter,
    claimFileMap,
  } = useClaimCardContent({
    formData,
    onUpdateClaims: extraActions?.onUpdateClaims,
  });

  const { displayOnPack: isChecked, supportingDocumentIds } =
    getClaimDataByIdAndType({ formData, claimType, id }) || {};

  const maxSupportingDocuments =
    schema?.items?.properties?.supportingDocumentIds?.maxItems;
  const supportingDocumentsFileType =
    schema?.items?.properties?.supportingDocumentIds?.fileType;

  const propertyId = schema?.propertyId;
  const displayOnPackPropertyId = getArraySchemaItemsPropertyIdByKey(
    schema,
    "displayOnPack"
  );
  const supportingDocumentIdsPropertyId = getArraySchemaItemsPropertyIdByKey(
    schema,
    "supportingDocumentIds"
  );

  const canUpdateFiles = isWidgetEditable(supportingDocumentIdsPropertyId);
  const canUpdateClaims = isWidgetEditable(propertyId);

  const conditionOfUse =
    getTranslationTextByLanguageCode({
      translations: isClaimTypeHealth(claimType)
        ? regulation?.conditionOfUse
        : nutritionalClaimConditionOfUse,
      languageCode,
    }) ?? EMPTY_VALUE;
  const claimSentence =
    getTranslationTextByLanguageCode({
      translations: isClaimTypeHealth(claimType)
        ? regulation?.sentenceTranslations ?? []
        : sentenceTranslations ?? [],
      languageCode,
    }) ?? EMPTY_VALUE;
  const additionalClaimSentence =
    getTranslationTextByLanguageCode({
      translations: additionalSentenceTranslations,
      languageCode: languageCode,
    }) ?? EMPTY_VALUE;

  const cardTitle = getClaimCardTitle({ claimType, name, claimSentence });

  const hasError = supportingDocErrorClaimIds.includes(id);

  const onToggle = handleDisplayOnPackToggle(claimType);
  const onRemove = handleRemoveClaim(claimType);
  const onUploadFile = handleFilesUpload(claimType);
  const onRemoveFile = handleFileRemove(claimType);

  return (
    <React.Fragment key={id}>
      <ExpandableCardWithToggleAndDelete
        key={id}
        title={cardTitle}
        description={
          <ClaimDescription
            claimType={claimType}
            claimSentence={claimSentence}
            additionalClaimSentence={additionalClaimSentence}
            conditionOfUse={conditionOfUse}
          />
        }
        removeConfirmationText={getTranslationForKey("unlinkClaimConfirmation")}
        onEntered={() =>
          handleEnter({
            claimId: id,
            fileIds: supportingDocumentIds,
          })
        }
        onToggle={() => onToggle(id)}
        toggleText={getFieldTitleFromUiSchemaArrayTemplate(
          uiSchema,
          "displayOnPack"
        )}
        toggleTextPlacement="left"
        isChecked={isChecked}
        libraryObjectType={LIBRARY_OBJECT_TYPES.CLAIMS}
        isTargetMode={isTargetMode}
        isToggleDisabled={
          readOnlyFieldIds.includes(displayOnPackPropertyId) ||
          !hasPermissionToSave ||
          isClaimUpdating ||
          disableExtraActions
        }
        {...(canUpdateClaims && {
          onRemove: () => onRemove(id),
        })}
        className={classnames("mb-2", {
          [styles["card--error"]]: hasError,
          [styles["card__action--error"]]: canUpdateClaims && hasError,
        })}
        iconColor={hasError ? "red" : "primary"}
        disableRemoveAction={isClaimUpdating || disableExtraActions}
      >
        <Col span={20}>
          <UploadFiles.Dragger
            key={id}
            disabled={!canUpdateFiles || isClaimUpdating || disableExtraActions}
            fileType={supportingDocumentsFileType}
            fileList={isListEmpty(claimFileMap?.[id]) ? [] : claimFileMap?.[id]}
            multiple={true}
            maxCount={maxSupportingDocuments}
            disabledFileIds={supportingDocumentIds}
            {...(isSpecContact && {
              ownerCompanyId: isRetailerContact
                ? ownerCompanyId
                : specificationData?.supplierId,
            })}
            {...(canUpdateFiles && {
              onUploadFiles: onUploadFile(id),
              onRemove: onRemoveFile(id),
            })}
            {...(hasError && {
              hasError,
              errorMessage: getTranslationForKey(
                "claimSupportingDocumentFieldErrorMsg"
              ),
            })}
          />
        </Col>
      </ExpandableCardWithToggleAndDelete>
      {hasError && (
        <div className={styles["errors__wrapper"]}>
          <ErrorsList
            list={[getTranslationForKey("claimSupportingDocumentCardErrorMsg")]}
          />
        </div>
      )}
    </React.Fragment>
  );
};

export default ClaimCardContent;
