import { PageHeader } from "@trace-one/design-system";
import { useParams } from "react-router-dom";
import { useIntl } from "react-intl";
import { useGoBack } from "hooks";
import { usePageHeadingInformation } from "pages/Library/hooks";
import Library from "components/Library";
import {
  LIBRARY_OBJECTS_PAGES,
  LIBRARY_OBJECT_API_TYPES,
  LIBRARY_OBJECT_TYPES,
} from "utils/constants";
import { getMessageKeyForType } from "utils/library";
import { ParamTypes } from "../types";
import { LibraryPageType } from "types/library";
import libraryMessages from "messages/library";
import styles from "./styles.module.less";

const libraryObjectPathMapping = {
  [LIBRARY_OBJECT_TYPES.REQUIREMENTS]: LIBRARY_OBJECT_API_TYPES.requirement,
  [LIBRARY_OBJECT_TYPES.MATERIALS]: LIBRARY_OBJECT_API_TYPES.material,
  [LIBRARY_OBJECT_TYPES.CHAPTERS]: LIBRARY_OBJECT_API_TYPES.chapter,
  [LIBRARY_OBJECT_TYPES.TEMPLATES]: LIBRARY_OBJECT_API_TYPES.template,
  [LIBRARY_OBJECT_TYPES.CLAIMS]: LIBRARY_OBJECT_API_TYPES.claim,
};

const Creation = () => {
  const { formatMessage } = useIntl();
  const { type } = useParams<ParamTypes>();
  const messageKey = getMessageKeyForType(type);
  const { goBack } = useGoBack(libraryObjectPathMapping[type]);

  const {
    actionsInformation,
    updateActionsInformation,
  } = usePageHeadingInformation();

  const minProps = {
    page: LIBRARY_OBJECTS_PAGES.CREATE as LibraryPageType,
    updateActionsInformation,
  };

  const ComponentContent = {
    [LIBRARY_OBJECT_TYPES.MATERIALS]: <Library.MaterialForm {...minProps} />,
    [LIBRARY_OBJECT_TYPES.REQUIREMENTS]: (
      <Library.RequirementForm {...minProps} />
    ),
    [LIBRARY_OBJECT_TYPES.CHAPTERS]: <Library.ChapterForm {...minProps} />,
    [LIBRARY_OBJECT_TYPES.TEMPLATES]: <Library.TemplateForm {...minProps} />,
    [LIBRARY_OBJECT_TYPES.CLAIMS]: <Library.ClaimForm {...minProps} />,
  };

  return (
    <>
      <PageHeader
        onBack={goBack}
        title={formatMessage(libraryMessages[`${messageKey}Title`])}
        onPrimaryButtonClick={() => {}}
        primaryButtonLabel={formatMessage(libraryMessages.publishButton)}
        primaryButtonProps={{
          ...actionsInformation.props,
          showTooltip: actionsInformation.props.disabled,
          tooltipProps: {
            text: formatMessage(libraryMessages.publishButtonTooltip, {
              libraryObject: formatMessage(
                libraryMessages[libraryObjectPathMapping[type]]
              ),
            }),
            placement: "bottomRight",
          },
        }}
      />
      <div className={styles.pageBody}>{ComponentContent[type]}</div>
    </>
  );
};

export default Creation;
