import { ChapterFreeFromAllergenFormData, File } from "models";

export type UseFreeFromAllergensActionsProps = {
  saveTemplateBlock: Function;
  formData: ChapterFreeFromAllergenFormData;
};

const useFreeFromAllergenActions = ({
  saveTemplateBlock,
  formData = {},
}: UseFreeFromAllergensActionsProps) => {
  const previousFormData = JSON.stringify(formData);

  const onAddAllergens = async (selectedObjects: { id: string }[]) => {
    const newAllergens = selectedObjects.map(({ id }) => ({
      id,
    }));

    const updatedFormData = {
      ...formData,
      allergens: [...(formData.allergens || []), ...newAllergens],
    };

    await saveTemplateBlock(JSON.stringify(updatedFormData), previousFormData);
  };

  const onRemoveAllergen = ({
    allergenId,
  }: {
    allergenId: string;
  }) => async () => {
    const updatedAllergens = formData.allergens.filter(
      ({ id }) => allergenId !== id
    );

    const updatedFormData = {
      ...formData,
      allergens: updatedAllergens,
    };

    await saveTemplateBlock(JSON.stringify(updatedFormData), previousFormData);
  };

  const onUploadFile = ({ allergenId }: { allergenId: string }) => async (
    files: File[]
  ) => {
    const updatedAllergens = formData.allergens.map(allergen => {
      if (allergen.id === allergenId) {
        return {
          ...allergen,
          proofFileId: files[0].id,
        };
      } else {
        return { ...allergen };
      }
    });

    const updatedFormData = { ...formData, allergens: updatedAllergens };

    await saveTemplateBlock(JSON.stringify(updatedFormData), previousFormData);
  };

  const onRemoveFile = ({ allergenId }: { allergenId: string }) => async () => {
    const updatedAllergens = formData.allergens.map(allergen => {
      if (allergen.id === allergenId) {
        return {
          id: allergen.id,
        };
      } else {
        return { ...allergen };
      }
    });

    const updatedFormData = { ...formData, allergens: updatedAllergens };

    await saveTemplateBlock(JSON.stringify(updatedFormData), previousFormData);
  };

  return {
    onAddAllergens,
    onRemoveAllergen,
    onUploadFile,
    onRemoveFile,
  };
};

export default useFreeFromAllergenActions;
