import { useSelector } from "react-redux";
import { useIntl } from "react-intl";
import classNames from "classnames";
import { Heading, Paragraph } from "@trace-one/design-system";
import { selectCatalogDictionary } from "store/generalData/selectors";
import { getFormattedValue } from "./utils";
import { AGECRegulation, EURegulation, ReachRegulation } from "models";
import { apiErrorMessages } from "messages";

export type RegulationsInfoItemProps = {
  regulation?: ReachRegulation | AGECRegulation | EURegulation;
  code: string;
};

const RegulationsInfoItem: React.FC<RegulationsInfoItemProps> = ({
  regulation,
  code,
}) => {
  const { formatMessage } = useIntl();

  const catalogDictionary = useSelector(selectCatalogDictionary);

  const fieldsToDisplayMap: {
    agec: Array<keyof AGECRegulation>;
    reach: Array<keyof ReachRegulation>;
    eu: Array<keyof EURegulation>;
  } = {
    agec: ["inclusionDate", "isHighConcernByArticle59"],
    reach: ["status", "scopes", "annex14", "annex17", "inclusionDate"],
    eu: [
      "isAdditiveOrPolymer",
      "isMonomer",
      "smlGroupRestriction",
      "restrictionSpecification",
      "complianceVerificationNote",
      "inclusionDate",
    ],
  };

  if (
    fieldsToDisplayMap[code].some(
      requiredField => !catalogDictionary[requiredField]
    )
  ) {
    return (
      <Paragraph color="red" data-testid="regulation-info-error-catalog-api">
        {formatMessage(apiErrorMessages.apiError)}
      </Paragraph>
    );
  }

  return (
    <div className={classNames("d-flex", "flex-direction-column", "rg-2")}>
      {Object.entries(regulation).map(([key, value]) => {
        if (!fieldsToDisplayMap[code].includes(key)) {
          return null;
        }

        const formattedValue = getFormattedValue({
          value,
          isDate: key === "inclusionDate",
          formatMessage,
        });

        return (
          catalogDictionary[key] && (
            <div key={`${code}-${key}`}>
              <Heading size="xxs">{catalogDictionary[key]}</Heading>
              <Paragraph className="mt-1">{formattedValue}</Paragraph>
            </div>
          )
        );
      })}
    </div>
  );
};

export default RegulationsInfoItem;
