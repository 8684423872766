import { GeneralInfoData } from "models";
import { LibraryItemState } from "types/library";

export enum ModalActionType {
  UNLINK = "unlink",
  UPDATE = "update",
  REVISE = "revise",
}

export interface LinkedSpecificationsModalProps {
  onCloseModal?: Function;
  libraryItemId?: GeneralInfoData["id"];
  selectedLibraryItemId?: GeneralInfoData["id"];
  libraryItemName?: GeneralInfoData["name"];
  libraryItemType?: GeneralInfoData["type"];
  libraryItemState?: LibraryItemState;
  libraryItemCreationDateUtc?: GeneralInfoData["creationDateUtc"];
  versionNumber?: GeneralInfoData["version"]["number"];
  states?: string[];
  rowSelection?: {
    selectedRowKeys?: string[];
    onSelect?: Function;
    onSelectAll?: Function;
  };
  title?: React.ReactNode;
  subtitle?: React.ReactNode;
  primaryButtonLabel?: React.ReactNode;
  onPrimaryButtonClick?: () => Promise<void>;
  secondaryButtonLabel?: React.ReactNode;
  onSecondaryButtonClick?: Function;
  actionType?: ModalActionType;
}

export type LinkedSpecificationsAction = {
  actionType?: ModalActionType;
  selectedLibraryItemId?: string;
  libraryItemState?: LibraryItemState;
  versionNumber?: number;
};
