import { useAppDispatch } from "store";
import SubstanceFunction from "./components/SubstanceFunction";
import {
  isMaterialAdditiveType,
  isMaterialCompositeType,
  isMaterialProcessingAidType,
  isMaterialRawMaterialType,
} from "utils/library";
import { MaterialIngredientData } from "models";
import { IngredientFunctionDropdownProps } from "./types";

const IngredientFunctionDropdown: React.FC<IngredientFunctionDropdownProps> = ({
  ingredient,
  disableActions,
  update,
}) => {
  const dispatch = useAppDispatch();

  const onUpdateFunction = (
    functionId: MaterialIngredientData["functionId"]
  ) => {
    dispatch(
      update({
        ingredientId: ingredient?.ingredientId,
        percentage: ingredient?.percentage,
        ...{ ...(functionId && { functionId }) },
      })
    );
  };

  if (
    isMaterialCompositeType(ingredient.type) ||
    isMaterialRawMaterialType(ingredient.type)
  ) {
    return <span data-test-id="ingredient-function" />;
  }

  if (
    isMaterialAdditiveType(ingredient.type) ||
    isMaterialProcessingAidType(ingredient.type)
  ) {
    return (
      <SubstanceFunction
        ingredient={ingredient}
        onUpdate={onUpdateFunction}
        disableActions={disableActions}
      />
    );
  }

  return null;
};

export default IngredientFunctionDropdown;
