import { CertificationDetails } from "../../../apis/certification/types";
import React, { useEffect, useState } from "react";
import { fetchCertificationDetails } from "../../../apis/certification";
const useCertificationDetails = (certificationId: string, setNotFoundCertificationError: React.Dispatch<React.SetStateAction<boolean>>, languageCode?: string) => {
  const [certificationDetailsData, setCertificationDetailsData] = useState<CertificationDetails | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const getCertificationDetails = async () => {
    try {
      setIsLoading(true);
      const { data } = await fetchCertificationDetails(certificationId, languageCode);
      setCertificationDetailsData(data);
    } catch (error) {
      console.error(error);
      setCertificationDetailsData(null);
      setNotFoundCertificationError(true);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (certificationId) {
      setCertificationDetailsData(null);
      getCertificationDetails();
    }
  }, [certificationId, languageCode]);

  return {
    certificationDetailsData,
    isLoading,
  };
};
export default useCertificationDetails;
