import { AxiosError } from "axios";
import { documentMessages, generalMessages } from "messages";

export const getErrorData = ({
  error,
  formatMessage,
}: {
  error: AxiosError;
  formatMessage: Function;
}) => {
  const { validFileFormat, errors = [], processedLineCount = 0 } =
    error?.response?.data?.error || {};

  const importStatus = validFileFormat
    ? formatMessage(documentMessages.importFileWithErrors)
    : "";

  const fileStatus = formatMessage(
    validFileFormat
      ? documentMessages.fileFormatOk
      : documentMessages.fileFormatNotOk
  );

  const errorSummary = `${formatMessage(generalMessages.errors)}: ${
    errors.length
  }/${processedLineCount}\n`;

  const errorDetails = validFileFormat
    ? errors.join("\n")
    : formatMessage(documentMessages.importFileFailedInvalidFormat);

  const fileContent = importStatus
    ? `${importStatus}\n${fileStatus}\n${errorSummary}\n${errorDetails}`
    : `${fileStatus}\n${errorSummary}\n${errorDetails}`;

  return new Blob([fileContent], { type: "text/plain" });
};
