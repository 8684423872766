import classnames from "classnames";
import { Popconfirm, Radio } from "antd";
import { useFormSchemaTranslation } from "pages/Specification/components/Details/hooks";
import { useWidgetActions } from "../../hooks";
import { useRadioPopconfirmWidget } from "./hooks";
import { RadioPopconfirmWidgetProps } from "./types";
import widgetStyle from "../styles.module.less";

const RadioPopconfirmWidget: React.FC<RadioPopconfirmWidgetProps> = props => {
  const { formContext, schema, id } = props;

  const { templateBlockId, sectionId } = formContext;

  const { propertyId } = schema;

  const { isWidgetChanged } = useWidgetActions({ templateBlockId, sectionId });

  const {
    onChange,
    value,
    disabled,
    isPopconfirmVisible,
    handlePopconfirmOnVisibleChange,
    onConfirm,
  } = useRadioPopconfirmWidget(props);
  const { getTranslationForKey } = useFormSchemaTranslation();

  return (
    <div
      className={classnames({
        [widgetStyle.inRevisionChange]: isWidgetChanged(propertyId, id),
      })}
    >
      <Radio.Group onChange={onChange} value={value} disabled={disabled}>
        <Radio value="true">
          <span>{getTranslationForKey("yes")}</span>
        </Radio>
        <Popconfirm
          visible={isPopconfirmVisible}
          title={getTranslationForKey("confirmRemoveTitle")}
          okText={getTranslationForKey("yes")}
          cancelText={getTranslationForKey("no")}
          onConfirm={onConfirm}
          onVisibleChange={handlePopconfirmOnVisibleChange}
        >
          <Radio value="false">
            <span>{getTranslationForKey("no")}</span>
          </Radio>
        </Popconfirm>
      </Radio.Group>
    </div>
  );
};

export default RadioPopconfirmWidget;
