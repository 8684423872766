import { Icon, ProgressBar } from "@trace-one/design-system";
import { FormattedMessage } from "react-intl";
import { getPercentValue } from "utils/general";
import Status from "components/Status";
import {
  displayValueForSpecificationDraftState,
  getDataIndexForProductOrigin,
  getValueToDisplayforLifeTime,
} from "pages/Specification/components/List/utils";
import Actions from "./components/Actions";
import { formatDateToLocal } from "utils/date";
import { messages } from "./messages";
import generalMessages from "messages/general";

export const getColumns = ({
  formatMessage,
  onCellClick,
  productOrigin,
  setSpecificationPermissions,
  reloadListing,
  isTranslationListing = null,
}: {
  formatMessage: Function;
  onCellClick: Function;
  productOrigin: "supplier" | "retailer";
  setSpecificationPermissions: Function;
  reloadListing: Function;
  isTranslationListing?: boolean;
}) => [
  {
    title: formatMessage(messages.productName),
    dataIndex: "tradeItemName",
    key: "tradeItemName",
    sorter: !isTranslationListing,
    onCell: onCellClick,
  },
  {
    title: formatMessage(
      isTranslationListing
        ? messages["retailerName"]
        : messages[`${productOrigin}Name`]
    ),
    dataIndex: isTranslationListing
      ? "ownerCompanyName"
      : getDataIndexForProductOrigin({ productOrigin }),
    sorter: !isTranslationListing,
    onCell: onCellClick,
  },
  {
    title: formatMessage(messages.owner),
    dataIndex: "ownerUserName",
    sorter: !isTranslationListing,
    onCell: onCellClick,
  },
  {
    title: formatMessage(messages.productType),
    dataIndex: "productType",
    sorter: !isTranslationListing,
    onCell: onCellClick,
  },
  ...(!isTranslationListing
    ? [
        {
          title: formatMessage(messages.lifeTime),
          dataIndex: "creationDateUtc",
          sorter: !isTranslationListing,
          render: (createdDateUtc, specification) =>
            displayValueForSpecificationDraftState({
              specificationState: specification.state,
              valueToDisplay: getValueToDisplayforLifeTime({
                createdDateUtc,
                todayMessage: <FormattedMessage {...messages.today} />,
                oneDayMessage: <FormattedMessage {...messages.oneDay} />,
                formatMessage: formatMessage,
              }),
            }),
          onCell: onCellClick,
        },
      ]
    : []),
  {
    title: formatMessage(messages.lastUpdate),
    dataIndex: "lastUpdateDateUtc",
    sorter: !isTranslationListing,
    render: (date, specification) =>
      displayValueForSpecificationDraftState({
        specificationState: specification.state,
        valueToDisplay: formatDateToLocal(date),
      }),
    onCell: onCellClick,
  },
  {
    title: formatMessage(generalMessages.version),
    dataIndex: "version",
    onCell: onCellClick,
    render: (version, specification) =>
      displayValueForSpecificationDraftState({
        specificationState: specification.state,
        valueToDisplay: version?.number,
      }),
  },
  {
    title: formatMessage(messages.progression),
    dataIndex: "progression",
    render: (percent, specification) =>
      displayValueForSpecificationDraftState({
        specificationState: specification.state,
        valueToDisplay: (
          <ProgressBar
            responsive
            color="primary"
            percent={getPercentValue(percent)}
          />
        ),
      }),
    onCell: onCellClick,
  },
  {
    title: formatMessage(messages.status),
    dataIndex: "state",
    render: state => <Status.Tag state={state} />,
    onCell: onCellClick,
  },
  ...(!isTranslationListing
    ? [
        {
          title: () => <Icon name="settings" color="white" />,
          render: (_, specification) => (
            <Actions
              specification={specification}
              setSpecificationPermissions={setSpecificationPermissions}
              reloadListing={reloadListing}
            />
          ),
        },
      ]
    : []),
];

export const refetchSpecificationListing = ({
  currentPage,
  setShouldRefetchSpecifications,
  resetCurrentPage,
}: {
  currentPage: number;
  setShouldRefetchSpecifications: Function;
  resetCurrentPage: Function;
}) => () => {
  if (currentPage === 1) return setShouldRefetchSpecifications(true);
  else return resetCurrentPage();
};
