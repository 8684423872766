import { PackagingSubstance } from "models";
import { isObjectEmpty } from "utils/general";

export const prepareSubstanceData = ({
  substanceId,
  presentAtNanoscale,
  presenceAtNanoscale,
  percentage,
}: PackagingSubstance) => {
  const newData: PackagingSubstance = {
    substanceId,
    percentage,
    presentAtNanoscale,
  };

  if (presentAtNanoscale) {
    newData.presenceAtNanoscale = {
      ...(presenceAtNanoscale?.size && { size: presenceAtNanoscale.size }),
      ...(presenceAtNanoscale?.specificSurface && {
        specificSurface: presenceAtNanoscale.specificSurface,
      }),
    };
  } else {
    newData.presenceAtNanoscale = {};
  }

  return newData;
};

export const getIsPrimaryButtonDisabled = (
  substanceData: PackagingSubstance
): boolean => {
  if (isObjectEmpty(substanceData)) return true;

  const { substanceId, selectedSubstance, percentage } = substanceData;

  if (!substanceId) return true;

  if (isObjectEmpty(selectedSubstance) || !selectedSubstance?.id) return true;

  if (percentage === null || percentage === undefined) return true;

  return false;
};
