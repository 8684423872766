import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { FormInstance } from "antd";
import cleanDeep from "clean-deep";
import { useAppDispatch } from "store";
import {
  selectLanguageCode,
  selectOwningCompanyId,
} from "store/user/selectors";
import {
  initialTemplateForm,
  selectTemplateForm,
  setTemplateFormIsLoading,
  setTemplateFormData,
  setTemplateUserGuides,
} from "store/templates/templateFormSlice";
import {
  useAddTag,
  useRemoveTag,
  useCreateTemplate,
  useUpdateTemplateName,
  useDeleteTemplate,
  useShareTemplate,
  useHideChapter,
  useUnhideChapter,
  usePublishTemplate,
  useHideSection,
  useUnhideSection,
  useReorderSections,
  useUpdateUserGuide,
} from "apis/SPEC/templates";
import { useRedirect } from "hooks";
import { LIBRARY_OBJECT_TYPES } from "utils/constants";
import { isLibraryObjectEditPage } from "utils/library";
import { ReorderSectionsPayload } from "models";
import { LibraryPageType } from "types/library";

export const useTemplateForm = ({
  form,
  page,
}: {
  form?: FormInstance<any>;
  page?: LibraryPageType;
}) => {
  const dispatch = useAppDispatch();

  const templateForm = useSelector(selectTemplateForm);
  const languageCode = useSelector(selectLanguageCode);
  const companyId = useSelector(selectOwningCompanyId);

  const { generalInfo, chapters, userGuides } = templateForm;
  const { productTypeId, tagIds, id: templateId } = generalInfo;

  const [showProductChangeModal, setShowProductChangeModal] = useState(false);
  const [
    previousSelectedProductType,
    setPreviousSelectedProductType,
  ] = useState(productTypeId);
  const [newSelectedProductType, setNewSelectedProductType] = useState(
    productTypeId
  );

  const { redirectToLibraryListing, redirectToLibraryDetails } = useRedirect();

  const [
    createTemplate,
    { isLoading: isTemplateCreating },
  ] = useCreateTemplate();

  const [delateTemplate] = useDeleteTemplate();

  const [
    updateTemplateName,
    { isLoading: isNameUpdating },
  ] = useUpdateTemplateName();

  const [addTag, { isLoading: isTagAdding }] = useAddTag();
  const [removeTag, { isLoading: isTagRemoving }] = useRemoveTag();
  const [shareTemplate] = useShareTemplate();
  const [hideChapter] = useHideChapter();
  const [unhideChapter] = useUnhideChapter();
  const [publishTemplate] = usePublishTemplate();
  const [hideSection] = useHideSection();
  const [unhideSection] = useUnhideSection();
  const [reorderSections] = useReorderSections();
  const [
    updateUserGuide,
    { isLoading: isUserGuideUpdating },
  ] = useUpdateUserGuide();

  useEffect(() => {
    dispatch(
      setTemplateFormIsLoading({
        isLoading:
          isTemplateCreating ||
          isNameUpdating ||
          isTagAdding ||
          isTagRemoving ||
          isUserGuideUpdating,
      })
    );
  }, [
    isTemplateCreating,
    isNameUpdating,
    isTagAdding,
    isTagRemoving,
    isUserGuideUpdating,
  ]);

  const createNewTemplate = async (productTypeId: string) => {
    const { name } = form?.getFieldsValue();

    try {
      const template = await createTemplate({
        params: { languageCode },
        payload: {
          templateName: name || null,
          tagIds,
          productTypeId,
        },
      }).unwrap();
      dispatch(setTemplateFormData(template));
      setShowProductChangeModal(false);
    } catch {}
  };

  const onKeepAsDraftTemplateFromDropdown = useCallback(async () => {
    try {
      await shareTemplate({ templateId, companyId }).unwrap();

      redirectToLibraryListing(LIBRARY_OBJECT_TYPES.TEMPLATES, {
        skipDetectLeavePage: true,
      });
    } catch (error) {}
  }, [templateId, companyId]);

  const onKeepAsDraftTemplate = async () => {
    try {
      await form.validateFields(["name", "productTypeId"]);
      await shareTemplate({ templateId, companyId }).unwrap();
    } catch (error) {
      throw error;
    }
  };

  const onPublishTemplate = useCallback(async () => {
    try {
      await publishTemplate(templateId).unwrap();

      if (isLibraryObjectEditPage(page)) {
        redirectToLibraryDetails({
          id: templateId,
          type: LIBRARY_OBJECT_TYPES.TEMPLATES,
          pushState: {
            skipDetectLeavePage: true,
          },
        });
      } else {
        redirectToLibraryListing(LIBRARY_OBJECT_TYPES.TEMPLATES, {
          skipDetectLeavePage: true,
        });
      }
    } catch (e) {}
  }, [templateId]);

  const onDeleteTemplate = async () => {
    try {
      if (templateId) {
        await delateTemplate(templateId);
        dispatch(setTemplateFormData(initialTemplateForm));
      }
    } catch {}
  };

  const onProductChangeConfirm = async () => {
    try {
      setShowProductChangeModal(false);
      await delateTemplate(templateId).unwrap();
      dispatch(
        setTemplateFormData({
          ...templateForm,
          generalInfo: {
            ...generalInfo,
            id: null,
          },
          chapters: [],
        })
      );

      await createNewTemplate(newSelectedProductType);
    } catch {
      form.setFieldValue("productTypeId", previousSelectedProductType);
    }
  };

  const onProductChangeCancel = () => {
    form.setFieldValue("productTypeId", previousSelectedProductType);

    dispatch(
      setTemplateFormData({
        ...templateForm,
        generalInfo: {
          ...generalInfo,
          productTypeId: previousSelectedProductType,
        },
      })
    );
    form.setFieldValue("productTypeId", previousSelectedProductType);
    setShowProductChangeModal(false);
  };

  const onProductSelect = async (selectedProduct: string) => {
    if (productTypeId === selectedProduct) {
      return;
    }

    setNewSelectedProductType(selectedProduct);
    setPreviousSelectedProductType(productTypeId);

    dispatch(
      setTemplateFormData({
        ...templateForm,
        generalInfo: {
          ...generalInfo,
          productTypeId: selectedProduct,
        },
      })
    );

    if (generalInfo?.id && generalInfo?.productTypeId) {
      setShowProductChangeModal(true);
      return;
    } else {
      createNewTemplate(selectedProduct);
    }
  };

  const onNameChange = async name => {
    if (!!name && !templateId && productTypeId) {
      createNewTemplate(newSelectedProductType);
      return;
    }

    if (productTypeId && !!name) {
      try {
        const template = await updateTemplateName({
          params: { languageCode },
          payload: { id: generalInfo?.id, name },
        }).unwrap();

        dispatch(setTemplateFormData(template));
      } catch {}
    }
  };

  const onTagsChange = async (selectedTags: string[]) => {
    if (tagIds?.length === selectedTags.length) return;

    if (templateId) {
      const existingTags = tagIds || [];

      try {
        let template = templateForm;

        if (existingTags?.length > selectedTags.length) {
          const tagToRemove = existingTags.find(
            currentTagId => !selectedTags.includes(currentTagId)
          );

          template = await removeTag({
            templateId: generalInfo.id,
            tagId: tagToRemove,
          }).unwrap();
        } else {
          const tagToAdd = selectedTags.find(
            currentTagId => !existingTags.includes(currentTagId)
          );

          template = await addTag({
            params: { languageCode },
            payload: {
              templateId: generalInfo.id,
              tagId: tagToAdd,
            },
          }).unwrap();
        }

        dispatch(setTemplateFormData(template));
      } catch {}
    } else {
      dispatch(
        setTemplateFormData({
          ...templateForm,
          generalInfo: {
            ...generalInfo,
            tagIds: selectedTags,
          },
        })
      );
    }
  };

  const onUpdateUserGuide = async ({
    sectionId,
    comment,
    chapterType,
  }: {
    sectionId: string;
    comment: string;
    chapterType: string;
  }) => {
    try {
      const template = await updateUserGuide({
        id: templateId,
        payload: { content: comment, sectionId, chapterType },
        params: { languageCode },
      }).unwrap();

      dispatch(setTemplateFormData(template));
      dispatch(
        setTemplateUserGuides({
          userGuides: cleanDeep(userGuides, { cleanKeys: [sectionId] }),
        })
      );
    } catch {}
  };

  const onHideChapter = async (sectionId: string) => {
    if (!generalInfo?.id || !sectionId) {
      return;
    }

    try {
      const chapter = chapters?.find(item => item.id === sectionId);

      const template = await hideChapter({
        templateId: generalInfo?.id,
        payload: {
          chapterType: chapter?.chapterType,
        },
      }).unwrap();

      const updatedUserGuides = chapter?.sections?.reduce(
        (acc, { id, userGuide }) => {
          if (userGuide) acc = { ...acc, [id]: userGuide };
          return acc;
        },
        { ...userGuides }
      );

      dispatch(setTemplateFormData(template));
      dispatch(
        setTemplateUserGuides({
          userGuides: updatedUserGuides,
        })
      );
    } catch {}
  };

  const onUnhideChapter = async (sectionId: string) => {
    if (!generalInfo?.id || !sectionId) {
      return;
    }

    try {
      const chapter = chapters?.find(item => item.id === sectionId);

      const template = await unhideChapter({
        templateId: generalInfo?.id,
        payload: {
          chapterType: chapter?.chapterType,
        },
      }).unwrap();

      dispatch(setTemplateFormData(template));

      for (const { id } of chapter?.sections) {
        if (userGuides[id]) {
          await onUpdateUserGuide({
            sectionId: id,
            chapterType: chapter?.chapterType,
            comment: userGuides[id],
          });
        }
      }
    } catch {}
  };

  const onHideSection = async (sectionId: string) => {
    if (!generalInfo?.id || !sectionId) {
      return;
    }

    try {
      const chapter = chapters?.find(chapter =>
        chapter?.sections?.some(section => section?.id === sectionId)
      );

      const template = await hideSection({
        templateId: generalInfo?.id,
        payload: {
          chapterType: chapter?.chapterType,
          sectionId,
        },
      }).unwrap();

      const updatedUserGuides = chapter?.sections?.reduce(
        (acc, { id, userGuide }) => {
          if (id === sectionId) {
            acc = { ...acc, [id]: userGuide };
          }
          return acc;
        },
        { ...userGuides }
      );

      dispatch(setTemplateFormData(template));
      dispatch(setTemplateUserGuides({ userGuides: updatedUserGuides }));
    } catch {}
  };

  const onUnhideSection = async (sectionId: string) => {
    if (!generalInfo?.id || !sectionId) {
      return;
    }

    try {
      const chapterType = chapters?.find(chapter =>
        chapter?.sections?.some(section => section?.id === sectionId)
      )?.chapterType;

      const template = await unhideSection({
        templateId: generalInfo?.id,
        payload: {
          chapterType,
          sectionId,
        },
      }).unwrap();

      dispatch(setTemplateFormData(template));

      if (userGuides[sectionId]) {
        await onUpdateUserGuide({
          sectionId,
          chapterType,
          comment: userGuides[sectionId],
        });
      }
    } catch {}
  };

  const onReorderSections = async (payload: ReorderSectionsPayload) => {
    if (!templateId) {
      return;
    }

    try {
      const template = await reorderSections({
        templateId,
        payload,
        params: { languageCode },
      }).unwrap();

      dispatch(setTemplateFormData(template));
    } catch {}
  };

  return {
    onProductSelect,
    onNameChange,
    onTagsChange,
    showProductChangeModal,
    onProductChangeCancel,
    onProductChangeConfirm,
    onDeleteTemplate,
    onKeepAsDraftTemplate,
    onKeepAsDraftTemplateFromDropdown,
    onHideChapter,
    onUnhideChapter,
    onPublishTemplate,
    onHideSection,
    onUnhideSection,
    onReorderSections,
    onUpdateUserGuide,
  };
};
