import { defineMessages } from "react-intl";

export const certificateModalMessages = defineMessages({
  title: {
    id: "shared.business.certificateModal.title",
    defaultMessage: "Add new certificate",
  },
  certificateAddition: {
    id: "shared.business.certificateModal.certificateAddition",
    defaultMessage: "Certificate addition",
  },
  aiDescription: {
    id: "shared.business.certificateModal.ai.description",
    defaultMessage: "Let AI populate fields automatically. Please be aware that the AI could overwrite data in fields that have already been populated.",
  },
  uploadFilePlaceholder: {
    id: "shared.business.certificateModal.uploadFile.placeholder",
    defaultMessage: "Drag and drop files here",
  },
  uploadFileBtn: {
    id: "shared.business.certificateModal.uploadFile.btn",
    defaultMessage: "Upload",
  },
  certificateName: {
    id: "shared.business.certificateModal.certificateName",
    defaultMessage: "Certificate name",
  },
  certificateNamePlaceholder: {
    id: "shared.business.certificateModal.certificateName.placeholder",
    defaultMessage: "Upload document to display certificate name",
  },
  validFrom: {
    id: "shared.business.certificateModal.validFrom",
    defaultMessage: "Valid from",
  },
  validTo: {
    id: "shared.business.certificateModal.validTo",
    defaultMessage: "Valid to",
  },
  grade: {
    id: "shared.business.certificateModal.grade",
    defaultMessage: "Grade",
  },
  gradePlaceholder: {
    id: "shared.business.certificateModal.grade.placeholder",
    defaultMessage: "Add grade",
  },
  comments: {
    id: "shared.business.certificateModal.comments",
    defaultMessage: "Comments",
  },
  commentsPlaceholder: {
    id: "shared.business.certificateModal.comments.placeholder",
    defaultMessage: "Add comment",
  },
  certificationTitle: {
    id: "shared.business.certificateModal.certification",
    defaultMessage: "Certification",
  },
  selectCertification: {
    id: "shared.business.certificateModal.selectCertification",
    defaultMessage: "Select a certification",
  },
  selectCertificationPlaceholder: {
    id: "shared.business.certificateModal.selectCertification.placeholder",
    defaultMessage: "Type or select a certification name",
  },

  selectCertificationError: {
    id: "shared.business.certificateModal.selectCertification.error",
    defaultMessage: "Required certification can’t be found, please add certification name",
  },

  certificationCardTitle: {
    id: "shared.business.certificateModal.certificationCard.title",
    defaultMessage: "Certification card",
  },
  certificationCardName: {
    id: "shared.business.certificateModal.certificationCard.name",
    defaultMessage: "Name",
  },
  certificationCardOwner: {
    id: "shared.business.certificateModal.certificationCard.owner",
    defaultMessage: "Owner",
  },
  certificationCardDomain: {
    id: "shared.business.certificateModal.certificationCard.domain",
    defaultMessage: "Domain",
  },
  certificationCardObject: {
    id: "shared.business.certificateModal.certificationCard.object",
    defaultMessage: "Object",
  },
  certificationCardDescription: {
    id: "shared.business.certificateModal.certificationCard.description",
    defaultMessage: "Description",
  },
  addCertificateBtn: {
    id: "shared.business.certificateModal.addCertificateBtn",
    defaultMessage: "Add certificate",
  },
  aiSpinnerText: {
    id: "shared.business.certificateModal.AI.spinnerText",
    defaultMessage: "Please wait while AI extracts your certificate information",
  },
});
