import { defineMessages } from "react-intl";

export const messages = defineMessages({
  search: {
    id: "general.search",
    defaultMessage: "Search",
  },
  searchPlaceholder: {
    id: "library.search.placeholder",
    defaultMessage: "Type a name",
  },
  claimSearchPlaceholder: {
    id: "claim.selectModal.search.placeholder",
    defaultMessage: "Search by claim name or claim sentence",
  },
  noItemFound: {
    id: "select.notFoundMessage",
    defaultMessage: "No item found",
  },
  detailsAndLinksChapterModalTitle: {
    id: "library.table.column.links.detailsAndLinksChapterModalTitle",
    defaultMessage: "Chapter details and linked specifications",
  },
  libraryItemSelectionTitle: {
    id: "library.itemSelection.title",
    defaultMessage: "Your selection",
  },
  nutritionalClaimTabDescription: {
    id: "library.nutritionalClaim.description",
    defaultMessage:
      "Nutritional claims are provided by TraceOne, they are based of the regulation from the EU.",
  },
});
