import { EmptyState, Button } from "@trace-one/design-system";
import { Row } from "antd";
import { ObjectFieldTemplateProps } from "components/SpecificationSection/types";
import Spin from "components/Spin";
import { SubstancesTable } from "components";
import { useCatalogDictionary, useRecyclabilityRegulationsInfo } from "hooks";
import { usePackagingSubstanceActions, useSubstanceWidget } from "./hooks";
import { useFormSchemaTranslation } from "pages/Specification/components/Details/hooks";
import { isListEmpty, isObjectEmpty } from "utils/general";
import { AddSubstancesModal } from "./components/AddSubstancesModal";
import { useState } from "react";
import { ErrorBoundary } from "react-error-boundary";
import WarningFallback from "components/WarningFallback";
import { PackagingSubstanceFormData } from "models";
import { SubstancesTableContextProvider } from "components/SubstancesTable/context/SubstancesTableContext";
import EditSubstanceModal from "components/SubstancesTable/components/EditSubstanceModal";

const SubstanceWidget: React.FC<
  ObjectFieldTemplateProps<PackagingSubstanceFormData>
> = props => {
  const { getTranslationForKey } = useFormSchemaTranslation();
  const { formData, schema, formContext, uiSchema } = props;
  const { substances = [] } = formData;

  const substanceIds = (substances ?? []).map(({ substanceId }) => substanceId);

  const {
    isLoading,
    substanceInformationCollection,
    substanceRegulations,
  } = useCatalogDictionary({
    substanceIds,
    shouldFilterIds: false,
    shouldGetSubstanceRegulationData: true,
  });

  useRecyclabilityRegulationsInfo();

  const { isWidgetEditable, saveTemplateBlock } = formContext;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const isSubstancesEditable = isWidgetEditable(
    schema?.properties?.substances?.propertyId
  );

  const {
    restrictedSubstances,
    unRestrictedSubstances,
    isEditSubstanceModalOpen,
    selectedSubstance,
    onOpenModal,
    onCloseModal,
  } = useSubstanceWidget({
    substanceInformationCollection,
    substanceRegulations,
    substances,
    saveTemplateBlock,
  });

  const { removing, onRemoveSubstance } = usePackagingSubstanceActions({
    formData,
    saveTemplateBlock,
  });

  if (
    isLoading &&
    isListEmpty(restrictedSubstances) &&
    isListEmpty(unRestrictedSubstances)
  )
    return <Spin />;

  return (
    <ErrorBoundary FallbackComponent={WarningFallback}>
      {isObjectEmpty(formData) || isListEmpty(formData.substances) ? (
        <Row justify="center">
          <EmptyState
            description={getTranslationForKey("substancesEmptyState")}
          />
        </Row>
      ) : (
        <>
          {(!isListEmpty(restrictedSubstances) ||
            !isListEmpty(unRestrictedSubstances)) && (
            <SubstancesTableContextProvider
              value={{
                uiSchema,
                schema,
                formData,
                onRemove: onRemoveSubstance,
                removing,
                actionsDisabled: !isSubstancesEditable,
                formContext,
                onOpenModal,
              }}
            >
              {!isListEmpty(restrictedSubstances) && (
                <div className="mb-3">
                  <SubstancesTable
                    key="restricted-substances-table"
                    title={getTranslationForKey("restrictedSubstances")}
                    subtitle={getTranslationForKey(
                      "restrictedSubstancesDescription"
                    )}
                    data={restrictedSubstances}
                    isRestricted
                    isLoading={isLoading}
                  />
                </div>
              )}

              {!isListEmpty(unRestrictedSubstances) && (
                <SubstancesTable
                  key="unrestricted-substances-table"
                  title={getTranslationForKey("unRestrictedSubstances")}
                  data={unRestrictedSubstances}
                  isLoading={isLoading}
                />
              )}
            </SubstancesTableContextProvider>
          )}
        </>
      )}

      <Row justify="center" className="mt-3">
        <Button
          data-testid="add-packaging-substances"
          type="primary"
          disabled={!isSubstancesEditable}
          onClick={() => {
            setIsModalOpen(true);
          }}
        >
          {getTranslationForKey("addSubstances")}
        </Button>

        {isModalOpen && (
          <AddSubstancesModal
            {...props}
            onClose={() => setIsModalOpen(false)}
          />
        )}
      </Row>

      {isEditSubstanceModalOpen && (
        <EditSubstanceModal
          substance={selectedSubstance}
          onCloseModal={onCloseModal}
          formContext={formContext}
          formData={formData}
          uiSchema={uiSchema}
          schema={schema}
        />
      )}
    </ErrorBoundary>
  );
};

export default SubstanceWidget;
