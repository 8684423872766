import { useState } from "react";
import { useIntl } from "react-intl";
import { Col, Row } from "antd";
import { Button, Select, Tag } from "@trace-one/design-system";
import Spin from "components/Spin";
import { useCharacteristicIngredient } from "../../hooks";
import { isListEmpty } from "utils/general";
import { generalMessages } from "messages";
import { IngredientListFormData } from "components/SpecificationSection/components/FormSchema/types";

interface CharacteristicIngredientProps {
  characteristicIngredients: IngredientListFormData["ingredients"];
  formData: IngredientListFormData;
  updateIngredientList: (_: IngredientListFormData) => Promise<void>;
  canEdit?: boolean;
}

const CharacteristicIngredient: React.FC<CharacteristicIngredientProps> = ({
  characteristicIngredients,
  formData,
  updateIngredientList,
  canEdit,
}) => {
  const { formatMessage } = useIntl();

  const [selectedIngredientId, setSelectedIngredientId] = useState<string>(
    null
  );

  const {
    onDropdownVisibleChange,
    onAddCharacteristicIngredient,
    onRemoveCharacteristicIngredient,
    isAdding,
    isRemoving,
    isRecipeLoading,
    options,
    characteristicIngredientsViewData,
  } = useCharacteristicIngredient({
    characteristicIngredients,
    formData,
    setSelectedIngredientId,
    updateIngredientList,
  });

  return (
    <>
      {canEdit && (
        <Row gutter={[16, 0]} className="mb-2">
          <Col span={7}>
            <Select
              name="characteristic-ingredient"
              data-test-id="characteristic-ingredient"
              data-testid="characteristic-ingredient"
              filterOption={false}
              allowClear={true}
              showSearch={false}
              value={selectedIngredientId}
              placeholder={formatMessage(generalMessages.selectAValue)}
              loading={isRecipeLoading}
              options={options}
              onDropdownVisibleChange={onDropdownVisibleChange}
              onSelect={setSelectedIngredientId}
              onClear={() => setSelectedIngredientId(null)}
            />
          </Col>
          <Col span={17}>
            <Button
              type="secondary"
              disabled={!selectedIngredientId}
              loading={isAdding}
              onClick={() =>
                onAddCharacteristicIngredient(selectedIngredientId)
              }
            >
              {formatMessage(generalMessages.add)}
            </Button>
          </Col>
        </Row>
      )}

      <Row gutter={[8, 8]}>
        {isAdding || isRemoving ? (
          <Col span={1}>
            <Spin size="default" />
          </Col>
        ) : (
          !isListEmpty(characteristicIngredientsViewData) &&
          characteristicIngredientsViewData?.map(({ ingredientId, name }) =>
            ingredientId ? (
              <Col key={ingredientId}>
                <Tag
                  mode="full"
                  color="default"
                  label={name}
                  {...(canEdit
                    ? {
                        onClose: () =>
                          onRemoveCharacteristicIngredient(ingredientId),
                      }
                    : {})}
                />
              </Col>
            ) : null
          )
        )}
      </Row>
    </>
  );
};

export default CharacteristicIngredient;
