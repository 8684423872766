import { useState } from "react";
import { useSelector } from "react-redux";
import { selectLanguageCode } from "store/user/selectors";
import { fetchUnitsOfMeasureForNutrient } from "apis/CATALOGUE";
import { LabeledValue } from "antd/lib/select";
import { isListEmpty } from "utils/general";
import { EnhancedExtraActions } from "components/SpecificationSection/types";
import { NutrientItem, NutritionFormData } from "models";

const useUnitOfMeasure = ({
  nutrientData,
  updateNutritionAction,
  formData,
}: {
  nutrientData: NutrientItem;
  updateNutritionAction: EnhancedExtraActions["updateNutrition"];
  formData: NutritionFormData;
}) => {
  const languageCode = useSelector(selectLanguageCode);

  const [unitOptions, setUnitOptions] = useState<LabeledValue[]>([]);
  const [value, setValue] = useState<LabeledValue>();
  const [areOptionsLoading, setAreOptionsLoading] = useState(false);

  const handleVisibleDropdownChange = async (open: boolean) => {
    if (open && isListEmpty(unitOptions)) {
      setAreOptionsLoading(true);
      try {
        const { data } = await fetchUnitsOfMeasureForNutrient({
          nutrientId: nutrientData.nutrientId,
          languageCode,
        });
        setUnitOptions(
          data.map(({ id, name }) => {
            return { value: id, label: name };
          })
        );
      } catch (_) {
      } finally {
        setAreOptionsLoading(false);
      }
    }
  };

  const handleSelect = async (valueItem: LabeledValue) => {
    setValue(valueItem);

    const { nutrients = [] } = formData;

    const updatedNutrients = nutrients.map(nutrient => {
      if (nutrient.nutrientId === nutrientData.nutrientId) {
        return { ...nutrient, unitId: valueItem?.value as string };
      }

      return { ...nutrient };
    });

    const updatedFormData: NutritionFormData = {
      ...formData,
      nutrients: updatedNutrients,
    };

    updateNutritionAction(updatedFormData);
  };

  return {
    handleVisibleDropdownChange,
    handleSelect,
    unitOptions,
    value,
    areOptionsLoading,
  };
};

export default useUnitOfMeasure;
