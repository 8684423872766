import { useEffect, useState } from "react";
import { PackagingSystemData } from "models";
import useReferenceListsItems from "hooks/useReferenceListsItems";
import { preparePackagingComponents } from "dtos/packagingSystem";
import {
  fetchPackagingSystemById,
  fetchComponentsForPackagingSystem,
} from "apis/PKG";
import { PackagingSystemComponentsTableViewModel } from "viewModels";

const usePackagingSystem = ({
  packagingSystemId,
  shouldFetchInitially = true,
}: {
  packagingSystemId: PackagingSystemData["packagingSystemId"];
  shouldFetchInitially?: boolean;
}) => {
  const [
    packagingSystemData,
    setPackagingSystemData,
  ] = useState<PackagingSystemData>(null);

  const [packagingComponents, setPackagingComponents] = useState<
    PackagingSystemComponentsTableViewModel[]
  >([]);

  const [refListIds, setRefListIds] = useState<string[]>([]);

  const [isLoading, setIsLoading] = useState(false);

  const getPackagingSystemData = async () => {
    try {
      const { data } = await fetchPackagingSystemById({
        packagingSystemId,
      });
      setPackagingSystemData(data);
    } catch (error) {
      setPackagingSystemData(null);
    }
  };

  const getPackagingSystemComponents = async () => {
    try {
      setIsLoading(true);

      const { data } = await fetchComponentsForPackagingSystem({
        packagingSystemId,
      });

      setPackagingComponents(preparePackagingComponents(data));
      setRefListIds(
        data?.flatMap(component => {
          const ids = [];
          if (component?.componentTypeId) ids.push(component?.componentTypeId);
          if (component?.materialId) ids.push(component?.materialId);
          return ids;
        })
      );
    } catch (_) {
      setPackagingComponents([]);
    } finally {
      setIsLoading(false);
    }
  };

  useReferenceListsItems({
    refListIds,
  });

  useEffect(() => {
    if (packagingSystemId && shouldFetchInitially) {
      getPackagingSystemData();
      getPackagingSystemComponents();
    } else {
      setPackagingSystemData(null);
    }
  }, [packagingSystemId]);

  return {
    packagingSystemData,
    packagingComponents,
    isLoading,
    getPackagingSystemData,
  };
};

export default usePackagingSystem;
