import { defineMessages } from "react-intl";

export const messages = defineMessages({
  requirementView: {
    id: "library.actions.view.requirement",
    defaultMessage: "View requirement",
  },

  rawMaterialView: {
    id: "library.actions.view.rawMaterial",
    defaultMessage: "View raw material",
  },

  compositeView: {
    id: "library.actions.view.composite",
    defaultMessage: "View composite material",
  },

  characteristicsAndControlPlansEdit: {
    id: "library.actions.edit.characteristicsAndControlPlans",
    defaultMessage: "Edit characteristics and control plans",
  },

  requirementEdit: {
    id: "library.actions.edit.requirement",
    defaultMessage: "Edit requirement",
  },

  rawMaterialEdit: {
    id: "library.actions.edit.rawMaterial",
    defaultMessage: "Edit raw material",
  },

  compositeEdit: {
    id: "library.actions.edit.composite",
    defaultMessage: "Edit composite material",
  },

  characteristicsAndControlPlansDelete: {
    id: "library.actions.delete.libraryObject",
    defaultMessage: "Delete draft",
  },

  requirementDelete: {
    id: "library.actions.delete.libraryObject",
    defaultMessage: "Delete draft",
  },
  materialCreateNewVersionTitle: {
    id: "library.materialForm.createNewVersion.title",
    defaultMessage: "New version of material created",
  },

  templateDelete: {
    id: "library.actions.delete.template",
    defaultMessage: "Delete template",
  },
});
