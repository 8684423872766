import { Row } from "antd";
import { generalMessages } from "messages";
import { EMPTY_VALUE } from "utils/constants";
import { formatDateToDDMMYYYYWithDash, isValidDate } from "utils/date";
import { isListEmpty } from "utils/general";

export const getFormattedValue = ({
  value,
  isDate,
  formatMessage,
}: {
  value: React.ReactNode;
  isDate: boolean;
  formatMessage: Function;
}) => {
  if (Array.isArray(value) && !isListEmpty(value)) {
    return value.map(item => <Row key={item}>{item}</Row>);
  }

  switch (typeof value) {
    case "string":
      if (isDate && isValidDate(value)) {
        return formatDateToDDMMYYYYWithDash(value, false);
      }
      return value;

    case "boolean":
      return formatMessage(value ? generalMessages.yes : generalMessages.no);

    default:
      return value || EMPTY_VALUE;
  }
};
