import { useIntl } from "react-intl";
import { toaster as toasterService } from "@trace-one/design-system";
import { useBoolean } from "hooks";
import { getErrorData } from "../../utils";
import { documentMessages } from "messages";

export type UseImportFileProps = {
  onImportConfirmation: (file: File) => Promise<void>;
  afterImportCallback?: Function;
  onDownloadErrorFile?: ({
    errorData,
    fileName,
  }: {
    errorData: BlobPart;
    fileName: string;
  }) => () => void;
};

const useImportFile = ({
  onImportConfirmation,
  afterImportCallback,
  onDownloadErrorFile,
}: UseImportFileProps) => {
  const { formatMessage } = useIntl();

  const {
    value: open,
    setTrue: setOpenTrue,
    setFalse: setOpenFalse,
  } = useBoolean(false);

  const {
    value: importing,
    setTrue: setImportingTrue,
    setFalse: setImportingFalse,
  } = useBoolean(false);

  const handleImportConfirmation = async (file: File) => {
    const fileName = file?.name || "";

    try {
      setImportingTrue();
      await onImportConfirmation(file);
      setImportingFalse();
      setOpenFalse();

      toasterService.confirmation({
        message: formatMessage(documentMessages.importFileSucceeded),
        description: formatMessage(
          documentMessages.importFileSucceededDescription,
          {
            fileName,
          }
        ),
      });

      if (typeof afterImportCallback === "function") {
        await afterImportCallback();
      }
    } catch (error) {
      setImportingFalse();

      const errorFileName = `${fileName}-${formatMessage(
        documentMessages.errorFile
      )}`;
      const errorData = getErrorData({ error, formatMessage }) || "";

      toasterService.open({
        message: formatMessage(documentMessages.importFileFailed),
        description: formatMessage(
          documentMessages.importFileFailedDescription,
          {
            fileName,
          }
        ),
        ...(typeof onDownloadErrorFile === "function" && {
          actions: [
            {
              text: formatMessage(documentMessages.errorFile),
              onClick: onDownloadErrorFile({
                errorData,
                fileName: errorFileName,
              }),
            },
          ],
        }),
        duration: 0,
        type: "alert",
      });

      throw error;
    }
  };

  return {
    open,
    onClick: setOpenTrue,
    onCloseModal: setOpenFalse,
    handleImportConfirmation,
    importing,
  };
};

export default useImportFile;
