import { Button, ConfirmationTooltip } from "@trace-one/design-system";
import { RemoveActionProps } from "./types";

const RemoveAction: React.FC<RemoveActionProps> = ({
  onRemove,
  removeConfirmationText,
  size = "small",
  color = "primary",
  className = "",
  disabled,
}) => {
  return (
    <div className={className}>
      <ConfirmationTooltip
        onConfirmation={onRemove}
        text={removeConfirmationText}
      >
        <Button
          iconName="trash"
          type="tertiary"
          size={size}
          color={color}
          disabled={disabled}
          data-test-id="remove-action-btn"
        />
      </ConfirmationTooltip>
    </div>
  );
};

export default RemoveAction;
