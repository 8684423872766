import { AxiosInstance, CancelToken } from "axios";
import {
  SpecificationData,
  RequirementData,
  SpecificationFileData,
  SpecificationChapterData,
  ChapterStructureData,
  NutritionServingData,
  SpecificationQuickViewData,
  MaterialRecipeData,
  SpecificationListItemData,
  ChapterTypeData,
  AddCollaborationBody,
  AddTranslatorBody,
  TranslatorData,
  SpecificationContact,
  SpecificationLabelingTranslationData,
  ChapterAllergenData,
  RequirementListItemData,
  ClaimData,
} from "models";
import { API_VERSION } from "apis/constants";
import { Permissions } from "types/general";
import { axiosInstance as axiosInstanceWithInterception } from "./";
import { CHAPTER_TYPES_API } from "utils/constants";
import {
  PaginatedList,
  PaginationParams,
  SpecificationsSearchBody,
} from "types/api";

export type SpecificationRequirementActionParams = {
  specificationId: SpecificationData["id"];
  requirementId: RequirementData["id"];
};

interface SpecificationClaimActionParams {
  specificationId: SpecificationData["id"];
  claimId: ClaimData["id"];
}

export type SpecificationBlockPayload = {
  blockId: string;
  json: string;
};

export const fetchSpecification = ({
  id,
  languageCode,
}: {
  id: SpecificationData["id"];
  languageCode: string;
}) =>
  axiosInstanceWithInterception.get<SpecificationData>(
    `/api/${API_VERSION}specifications/${id}`,
    {
      params: {
        languageCode,
      },
    }
  );

export const fetchSpecificationChapter = ({
  specificationId,
  sectionCode,
}: {
  specificationId: SpecificationData["id"];
  sectionCode: string;
}) =>
  axiosInstanceWithInterception.get<SpecificationChapterData>(
    `/api/${API_VERSION}specifications/${specificationId}/${CHAPTER_TYPES_API[sectionCode]}`
  );

export const fetchSpecificationStructure = ({
  specificationId,
  languageCode,
}: {
  specificationId: SpecificationData["id"];
  languageCode: string;
}) =>
  axiosInstanceWithInterception.get<ChapterStructureData[]>(
    `/api/${API_VERSION}specifications/${specificationId}/structure`,
    {
      params: {
        languageCode,
      },
    }
  );

export const fetchSpecificationHistory = ({ id, languageCode }) =>
  axiosInstanceWithInterception.get<SpecificationQuickViewData[]>(
    `/api/${API_VERSION}specifications/${id}/quick-view`,
    {
      params: {
        languageCode,
      },
    }
  );

export const fetchSpecificationSectionLastChanges = ({
  specificationId,
  sectionId,
  byOwner,
  bySupplier,
}) =>
  axiosInstanceWithInterception.get(
    `/api/${API_VERSION}specifications/${specificationId}/sections/${sectionId}/last-changes`,
    {
      params: {
        byOwner,
        bySupplier,
      },
    }
  );

export const fetchSpecificationPdf = ({ id, languageCode }) =>
  axiosInstanceWithInterception.get(
    `/api/${API_VERSION}specifications/${id}/pdf`,
    {
      params: {
        languageCode,
      },
      responseType: "blob",
    }
  );

export const fetchSpecificationChapterAllergens = ({
  id,
  chapterType,
  unintentionalPresent,
}: {
  id: SpecificationData["id"];
  chapterType: string;
  unintentionalPresent?: boolean;
}) =>
  axiosInstanceWithInterception.get<ChapterAllergenData[]>(
    `/api/${API_VERSION}specifications/${id}/${chapterType}/allergens`,
    {
      params: {
        unintentionalPresent,
      },
    }
  );

export const fetchSpecificationSignatureType = ({ id }) =>
  axiosInstanceWithInterception.get<string>(
    `/api/${API_VERSION}specifications/${id}/signature-type`
  );

export const updateSpecificationDigitalSignature = ({ id, languageCode }) =>
  axiosInstanceWithInterception.post<string>(
    `/api/${API_VERSION}specifications/${id}/digital-signing`,
    {
      languageCode,
    }
  );

export const createSpecification = () =>
  axiosInstanceWithInterception.post(`/api/${API_VERSION}specifications`);

export const createSpecificationContact = ({
  specificationId,
  userId,
  signatory,
  contactType,
}) =>
  axiosInstanceWithInterception.post(
    `/api/${API_VERSION}specifications/${specificationId}/contacts`,
    {
      userId,
      signatory,
      contactType,
    }
  );

export const fetchSpecificationFiles = ({
  specificationId,
}: {
  specificationId: SpecificationData["id"];
}) =>
  axiosInstanceWithInterception.get<SpecificationFileData>(
    `/api/${API_VERSION}specifications/${specificationId}/files`
  );

export const createSpecificationRequirements = ({
  specificationId,
  chapterType,
  requirementIds,
}: {
  specificationId: SpecificationData["id"];
  chapterType: ChapterTypeData["chapterType"];
  requirementIds: RequirementData["id"][];
}) =>
  axiosInstanceWithInterception.post(
    `/api/${API_VERSION}specifications/${specificationId}/requirements`,
    {
      chapterType,
      requirementIds,
    }
  );

export const fetchSpecificationRequirements = ({
  specificationId,
}: {
  specificationId: SpecificationData["id"];
}) =>
  axiosInstanceWithInterception.get<RequirementListItemData[]>(
    `/api/${API_VERSION}specifications/${specificationId}/requirements`
  );

export const createSectionRequirements = ({
  specificationId,
  templateSectionId,
  requirementIds,
}) =>
  axiosInstanceWithInterception.post(
    `/api/${API_VERSION}specifications/${specificationId}/requirements`,
    {
      templateSectionId,
      requirementIds,
    }
  );

export const updateSpecificationProduct = ({ specificationId, tradeItemId }) =>
  axiosInstanceWithInterception.patch(
    `/api/${API_VERSION}specifications/${specificationId}/product`,
    {
      tradeItemId,
    }
  );

export const updateSpecificationSupplier = ({ specificationId, companyId }) =>
  axiosInstanceWithInterception.patch(
    `/api/${API_VERSION}specifications/${specificationId}/supplier`,
    {
      companyId,
    }
  );

export const updateSpecificationNote = ({ id, note }) =>
  axiosInstanceWithInterception.patch(
    `/api/${API_VERSION}specifications/${id}/note`,
    {
      note,
    }
  );

export const updateSpecificationRevision = ({
  id,
}: {
  id: SpecificationData["id"];
}) =>
  axiosInstanceWithInterception.post(
    `/api/${API_VERSION}specifications/${id}/revise`,
    null,
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );

export const acceptSpecificationRevision = ({
  id,
}: {
  id: SpecificationData["id"];
}) =>
  axiosInstanceWithInterception.post(
    `/api/${API_VERSION}specifications/${id}/accept-revision`
  );

export const shareSpecification = ({ id }) =>
  axiosInstanceWithInterception.post(
    `/api/${API_VERSION}specifications/${id}/share`
  );

export const deleteSpecification = ({ id }) =>
  axiosInstanceWithInterception.delete<void>(
    `/api/${API_VERSION}specifications/${id}`
  );

export const deleteSpecificationContact = ({ specificationId, userId }) =>
  axiosInstanceWithInterception.delete<void>(
    `/api/${API_VERSION}specifications/${specificationId}/contacts/${userId}`
  );

export const setSignatoryForSpecificationContact = ({
  specificationId,
  userId,
  signatory,
}) =>
  axiosInstanceWithInterception.put(
    `/api/${API_VERSION}specifications/${specificationId}/contacts/${userId}`,
    { signatory }
  );

export const validateSpecificationSection = ({
  specificationId,
  chapterType,
}: {
  specificationId: SpecificationData["id"];
  chapterType?: string;
}) => (sectionId: string) =>
  axiosInstanceWithInterception.post(
    `/api/${API_VERSION}specifications/${specificationId}/validate`,
    null,
    {
      params: {
        chapterType,
        sectionId,
      },
    }
  );

export const validateSpecificationRequirements = ({
  specificationId,
  requirementIds,
}) =>
  axiosInstanceWithInterception.post(
    `/api/${API_VERSION}specifications/${specificationId}/validateRequirements`,
    {
      requirementIds,
    }
  );

export const deleteSpecificationRequirement = ({
  specificationId,
  requirementId,
}: SpecificationRequirementActionParams) =>
  axiosInstanceWithInterception.delete<void>(
    `/api/${API_VERSION}specifications/${specificationId}/requirements/${requirementId}`
  );

export const linkRequirementToSpecification = ({
  specificationId,
  requirementId,
}: SpecificationRequirementActionParams) =>
  axiosInstanceWithInterception.post<Promise<void>>(
    `/api/${API_VERSION}specifications/${specificationId}/requirements/${requirementId}/active-version`
  );

export const searchSpecificationList = ({
  params,
  data = {},
  cancelToken,
}: {
  params: PaginationParams & {
    tradeItemName?: string;
    mySpecificationsOnly?: boolean;
    languageCode?: string;
    categoryItemId?: string;
  };
  data: SpecificationsSearchBody;
  cancelToken?: CancelToken;
}) =>
  axiosInstanceWithInterception.post<PaginatedList<SpecificationListItemData>>(
    `/api/${API_VERSION}specifications/search`,
    data,
    {
      params,
      ...(cancelToken && {
        cancelToken,
      }),
    }
  );

export const duplicateSpecification = ({
  specificationId,
  supplierId,
}: {
  specificationId: SpecificationData["id"];
  supplierId: SpecificationData["supplierId"];
}) =>
  axiosInstanceWithInterception.post<SpecificationData>(
    `/api/${API_VERSION}specifications/${specificationId}/duplicate`,
    {
      supplierId,
    }
  );

export const fetchSpecificationPermissions = ({
  specificationId,
}: {
  specificationId: SpecificationData["id"];
}) =>
  axiosInstanceWithInterception.get<Permissions>(
    `/api/${API_VERSION}specifications/${specificationId}/permissions`
  );

export const createSpecificationCollaboration = ({
  specificationId,
  payload,
}: {
  specificationId: SpecificationData["id"];
  payload: AddCollaborationBody[];
}) =>
  axiosInstanceWithInterception.post<SpecificationData>(
    `/api/${API_VERSION}specifications/${specificationId}/collaborations`,
    payload
  );

export const deleteSpecificationCollaboration = ({
  specificationId,
  collaborationId,
}: {
  specificationId: SpecificationData["id"];
  collaborationId: string;
}) =>
  axiosInstanceWithInterception.delete<SpecificationData>(
    `/api/${API_VERSION}specifications/${specificationId}/collaborations/${collaborationId}`
  );

// to be removed
export const createSpecificationNutrition = ({
  specificationId,
  nutrientIds,
}: {
  specificationId: SpecificationData["id"];
  nutrientIds: string[];
}) =>
  axiosInstanceWithInterception.post<SpecificationData>(
    `/api/${API_VERSION}specifications/${specificationId}/nutrition`,
    {
      nutrientIds,
    }
  );

export const updateSpecificationLabeling = ({
  specificationId,
  payload,
}: {
  specificationId: SpecificationData["id"];
  payload: {
    blockId: string;
    json: string;
  };
}) =>
  axiosInstanceWithInterception.put<void>(
    `/api/${API_VERSION}specifications/${specificationId}/labeling`,
    payload
  );

export const updateSpecificationChapterBlock = ({
  specificationId,
  chapterType,
  json,
  blockId,
}: {
  specificationId: SpecificationData["id"];
  chapterType: string;
  json: string;
  blockId: string;
}) =>
  axiosInstanceWithInterception.put(
    `/api/${API_VERSION}specifications/${specificationId}/${chapterType}`,
    { blockId, json }
  );

export const createSpecificationSharedDocuments = ({
  specificationId,
  fileIds,
}: {
  specificationId: string;
  fileIds: string[];
}) =>
  axiosInstanceWithInterception.post(
    `/api/${API_VERSION}specifications/${specificationId}/shared-documents`,
    fileIds
  );

export const deleteSpecificationSharedDocument = ({
  specificationId,
  fileId,
}: {
  specificationId: string;
  fileId: string;
}) =>
  axiosInstanceWithInterception.delete<void>(
    `/api/${API_VERSION}specifications/${specificationId}/shared-documents/${fileId}`
  );

export const linkChapterToSpecification = ({
  specificationId,
  chapterType,
  chapterId,
}: {
  specificationId: SpecificationData["id"];
  chapterType: string;
  chapterId: string;
}) =>
  axiosInstanceWithInterception.post(
    `/api/${API_VERSION}specifications/${specificationId}/link-chapter`,
    {
      chapterId,
      chapterType,
    }
  );

export const reviseChapterSpecification = ({
  specificationId,
  chapterType,
  chapterId,
}: {
  specificationId: SpecificationData["id"];
  chapterType: string;
  chapterId: string;
}) =>
  axiosInstanceWithInterception.post(
    `/api/${API_VERSION}specifications/${specificationId}/revise-chapter`,
    {
      chapterId,
      chapterType,
    }
  );

export const reviseRequirementSpecification = ({
  specificationId,
  requirementId,
}: {
  specificationId: SpecificationData["id"];
  requirementId: string;
}) =>
  axiosInstanceWithInterception.post(
    `/api/${API_VERSION}specifications/${specificationId}/revise-requirement`,
    {
      requirementId,
    }
  );

export const unlinkChapterToSpecification = ({
  specificationId,
  chapterType,
}: {
  specificationId: SpecificationData["id"];
  chapterType: string;
}) =>
  axiosInstanceWithInterception.post(
    `/api/${API_VERSION}specifications/${specificationId}/unlink-chapter`,
    {},
    {
      params: { chapterType },
    }
  );

export const updateNutritionServing = ({
  specificationId,
  payload,
}: {
  specificationId: string;
  payload: NutritionServingData[];
}) =>
  axiosInstanceWithInterception.put(
    `/api/${API_VERSION}specifications/${specificationId}/nutrition-servings`,
    payload
  );

export const fetchSpecificationRecipe = ({
  id,
  chapterType,
}: {
  id: string;
  chapterType: string;
}) =>
  axiosInstanceWithInterception.get<MaterialRecipeData>(
    `/api/${API_VERSION}specifications/${id}/${chapterType}/recipe`
  );

export const sortSpecificationChapterRecipe = ({
  id,
  chapterType,
  orderBy,
  orderByDescending,
}: {
  id: string;
  chapterType: string;
  orderBy: string;
  orderByDescending: boolean;
}) =>
  axiosInstanceWithInterception.post<MaterialRecipeData>(
    `/api/${API_VERSION}specifications/${id}/${chapterType}/sort-recipe`,
    {},
    {
      params: {
        orderBy,
        orderByDescending,
      },
    }
  );

export const fetchSpecificationLinkedChapters = ({ id }: { id: string }) =>
  axiosInstanceWithInterception.get<string[]>(
    `/api/${API_VERSION}specifications/${id}/linked-chapters`
  );

export const updateSpecificationSignature = ({ id, languageCode }) =>
  axiosInstanceWithInterception.post(
    `/api/${API_VERSION}specifications/${id}/double-validation-signing`,
    {},
    {
      params: {
        languageCode,
      },
    }
  );

export const fetchSpecificationsWaitingCount = ({ userId }) =>
  axiosInstanceWithInterception.get(
    `/api/${API_VERSION}specifications/waiting-signing-count`,
    {
      params: {
        userid: userId,
      },
    }
  );

export const addDocumentsToSpecification = ({
  specificationId,
  chapterType,
  fileIds,
}: {
  specificationId: SpecificationData["id"];
  chapterType: ChapterTypeData["chapterType"];
  fileIds: string[];
}) =>
  axiosInstanceWithInterception.post(
    `/api/${API_VERSION}specifications/${specificationId}/documents`,
    {
      chapterType,
      fileIds,
    }
  );

export const deleteDocumentFromSpecification = ({
  specificationId,
  fileId,
}: {
  specificationId: SpecificationData["id"];
  fileId: string;
}) =>
  axiosInstanceWithInterception.delete<void>(
    `/api/${API_VERSION}specifications/${specificationId}/documents/${fileId}`
  );

export const validateSpecificationDocuments = ({
  specificationId,
  chapterType,
}: {
  specificationId: SpecificationData["id"];
  chapterType: ChapterTypeData["chapterType"];
}) =>
  axiosInstanceWithInterception.post<void>(
    `/api/${API_VERSION}specifications/${specificationId}/validate-documents`,
    null,
    {
      params: {
        chapterType,
      },
    }
  );

export const createLabelingTranslations = ({
  specificationId,
}: {
  specificationId: SpecificationData["id"];
}) =>
  axiosInstanceWithInterception.post<SpecificationLabelingTranslationData>(
    `/api/${API_VERSION}specifications/${specificationId}/labeling-translations`
  );

export const getLabelingTranslations = ({
  specificationId,
  languageCode,
  axiosInstance = axiosInstanceWithInterception,
}: {
  specificationId: SpecificationData["id"];
  languageCode?: string;
  axiosInstance?: AxiosInstance;
}) =>
  axiosInstance.get<SpecificationLabelingTranslationData>(
    `/api/${API_VERSION}specifications/${specificationId}/labeling-translations`,
    {
      params: {
        languageCode,
      },
    }
  );

export const deleteLabelingTranslations = ({
  specificationId,
}: {
  specificationId: SpecificationData["id"];
}) =>
  axiosInstanceWithInterception.delete<void>(
    `/api/${API_VERSION}specifications/${specificationId}/labeling-translations`
  );

export const startLabelingTranslations = ({
  specificationId,
}: {
  specificationId: SpecificationData["id"];
}) =>
  axiosInstanceWithInterception.post<SpecificationLabelingTranslationData>(
    `/api/${API_VERSION}specifications/${specificationId}/labeling-translations/start`
  );

export const createSpecificationTranslators = ({
  specificationId,
  payload,
}: {
  specificationId: SpecificationData["id"];
  payload: AddTranslatorBody[];
}) =>
  axiosInstanceWithInterception.post<void>(
    `/api/${API_VERSION}specifications/${specificationId}/translators`,
    payload
  );

export const updateSpecificationTranslators = ({
  specificationId,
  payload,
}: {
  specificationId: SpecificationData["id"];
  payload: AddTranslatorBody[];
}) =>
  axiosInstanceWithInterception.put<void>(
    `/api/${API_VERSION}specifications/${specificationId}/translators`,
    payload
  );

export const fetchSpecificationTranslators = ({
  specificationId,
}: {
  specificationId: SpecificationData["id"];
}) =>
  axiosInstanceWithInterception.get<TranslatorData[]>(
    `/api/${API_VERSION}specifications/${specificationId}/translators`
  );

export const fetchSpecificationContacts = ({
  specificationId,
}: {
  specificationId: SpecificationData["id"];
}) =>
  axiosInstanceWithInterception.get<SpecificationContact[]>(
    `/api/${API_VERSION}specifications/${specificationId}/contacts`
  );

export const deleteSpecificationClaim = ({
  specificationId,
  claimId,
}: SpecificationClaimActionParams) =>
  axiosInstanceWithInterception.delete<void>(
    `/api/${API_VERSION}specifications/${specificationId}/claims/${claimId}`
  );

export const updateToLastClaimVersion = ({
  specificationId,
  claimId,
  payload,
}: {
  specificationId: SpecificationClaimActionParams["specificationId"];
  claimId: SpecificationClaimActionParams["claimId"];
  payload: {
    newClaimId?: ClaimData["id"];
  };
}) =>
  axiosInstanceWithInterception.put<void>(
    `/api/${API_VERSION}specifications/${specificationId}/claims/${claimId}`,
    payload
  );

export const reviseSpecificationClaim = ({
  specificationId,
  payload,
}: {
  specificationId: SpecificationClaimActionParams["specificationId"];
  payload: {
    claimId: ClaimData["id"];
    newClaimId?: ClaimData["id"];
  };
}) =>
  axiosInstanceWithInterception.post(
    `/api/${API_VERSION}specifications/${specificationId}/revise-claim`,
    payload
  );
