import React, { useState } from "react";
import { Paragraph } from "@trace-one/design-system";
import { useIntl } from "react-intl";
import { generalMessages } from "messages";
import styles from "./styles.module.less";

export interface SeeMoreProps {
  text: string;
  maxChars?: number;
}

const ReadMore: React.FC<SeeMoreProps> = ({ text, maxChars = 350 }) => {
  const { formatMessage } = useIntl();
  const [isExpanded, setIsExpanded] = useState(false);
  const isLongText = text.length > maxChars;

  const toggleReadMore = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <Paragraph>
      {isExpanded || !isLongText ? text : `${text.substring(0, maxChars)}...`}
      {isLongText && (
        <span onClick={toggleReadMore} className={styles["see-more-text"]}>
          {isExpanded ? "" : formatMessage(generalMessages.seeMore)}
        </span>
      )}
    </Paragraph>
  );
};

export default ReadMore;
