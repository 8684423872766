import { ClaimData, File, RegulatoryHealthClaim } from "models";
import { ReduxClaimType } from "store/claimForm/claimFormSlice";
import {
  getDefaultTranslationLanguageCode,
  isClaimTypeHealth,
  isClaimTypeNutritional,
} from "utils/claim";
import { isObjectEmpty } from "utils/general";
import { TagData } from "models";
import { getNutritionalClaimName } from "./libraryItem";

export const prepareClaimFormData = ({
  libraryItemDetails,
  file = {},
  shouldSaveTagAsArrayOfString = false,
  regulation,
}: {
  libraryItemDetails: ClaimData;
  file?: File;
  shouldSaveTagAsArrayOfString?: boolean;
  regulation?: RegulatoryHealthClaim;
}) => {
  const claimForm: ReduxClaimType = {
    generalInfo: {
      id: libraryItemDetails?.id,
      name: libraryItemDetails?.name,
      productTypeId: libraryItemDetails?.productTypeId,
      claimType: libraryItemDetails?.claimType,
      tags: shouldSaveTagAsArrayOfString
        ? libraryItemDetails?.tagIds
        : libraryItemDetails?.tagIds?.map(tagId => ({
            tagId,
          })),
      requiresSupportingDocuments: isClaimTypeHealth(
        libraryItemDetails?.claimType
      )
        ? regulation?.requiresSupportingDocuments
        : libraryItemDetails?.requiresSupportingDocuments,
      regulatoryClaimId: libraryItemDetails?.regulatoryClaimId,
      version: libraryItemDetails?.version,
    },
    sentenceTranslations: isClaimTypeHealth(libraryItemDetails?.claimType)
      ? libraryItemDetails?.additionalSentenceTranslations
      : libraryItemDetails?.sentenceTranslations,
    file: {
      id: libraryItemDetails?.logoFileId || undefined,
      ...(!isObjectEmpty(file) && {
        fileName: file?.name,
        fileSize: file?.size,
      }),
    },
  };

  return claimForm;
};

export const prepareClaimDetailsData = (
  claimData: ClaimData,
  shouldSetTags: boolean = false,
  languageCode?: string
) => {
  const claimDetails = {
    ...claimData,
    sentenceTranslations: claimData?.sentenceTranslations,
    logoFileId: claimData?.logoFileId,
    versionNumber: claimData?.version?.number,
    generalInformation: {
      name: isClaimTypeNutritional(claimData?.claimType)
        ? getNutritionalClaimName({
            libraryItem: claimData,
            languageCode:
              languageCode ||
              getDefaultTranslationLanguageCode(
                claimData?.sentenceTranslations
              ),
          })
        : claimData?.name,
      type: claimData?.claimType,
      state: claimData?.state,
      lastModificationDate: claimData?.lastUpdateDateUtc,
      tags: shouldSetTags ? claimData?.tagIds : [],
      requiresSupportingDocuments: claimData?.requiresSupportingDocuments,
      productTypeId: claimData?.productTypeId,
    },
  };

  return claimDetails;
};

export const prepareClaimFormDataForComparison = (
  claimForm: ReduxClaimType
) => {
  let updatedClaimForm;

  const { generalInfo, sentenceTranslations, file, regulation } = claimForm;

  updatedClaimForm = {
    generalInfo: {
      ...generalInfo,
      tags: generalInfo?.tags?.map((tag: TagData | string) => {
        if (typeof tag === "string") {
          return tag;
        }
        return tag.tagId;
      }),
      regulatoryClaimId: generalInfo?.regulatoryClaimId,
      requiresSupportingDocuments: isClaimTypeHealth(generalInfo?.claimType)
        ? regulation?.requiresSupportingDocuments
        : generalInfo?.requiresSupportingDocuments,
    },
    sentenceTranslations: sentenceTranslations.filter(
      translation => translation.text.length > 0
    ),
    file: { id: file?.id },
  };

  return updatedClaimForm;
};
