import { NutrientItemDictionary } from "components/SpecificationSection/components/FormSchema/components/NutritionBasic/types";
import {
  getFieldTitleFromUiSchemaObjectTemplate,
  isListEmpty,
  isObjectEmpty,
} from "./general";
import {
  NutrientFamilyData,
  NutrientItemData,
  NutriScoreData,
  NutriscorePointData,
  NutritionServingSizeData,
} from "models/nutrition";
import { Dictionary, UiSchemaInfo } from "types/general";
import { NutriScoreGrade } from "types/nutrition";
import { NUTRISCORE_CALCULATION_STATE } from "./constants";
import { SubstanceData } from "models";

export const getServingGroupingName = ({
  isDefaultServing,
  name,
  size,
  nutrientDictionary,
  getTranslationForKey,
}: {
  isDefaultServing: boolean;
  name: string;
  size: NutritionServingSizeData;
  nutrientDictionary: Dictionary<string | NutrientItemDictionary>;
  getTranslationForKey: Function;
}) => {
  if (isDefaultServing) {
    return name;
  }

  const unitString =
    size?.unitId && nutrientDictionary[size?.unitId]
      ? nutrientDictionary[size?.unitId] + " "
      : "";
  return !!size?.value
    ? `${getTranslationForKey("per")} ${size?.value} ${unitString}- ${name}`
    : name;
};

export const getServingGroupingColumns = (isDefaultServing: boolean) =>
  isDefaultServing
    ? ["unroundedDailyIntake", "roundedValue", "roundedDailyIntake"]
    : [
        "unroundedValue",
        "unroundedDailyIntake",
        "roundedValue",
        "roundedDailyIntake",
      ];

export const getServingGroupingColor = (isDefaultServing: boolean) =>
  isDefaultServing ? "grey-1" : "blue-light";

export const isNutriScoreA = (nutriScore: NutriScoreGrade) =>
  nutriScore.toUpperCase() === "A";

export const isNutriScoreB = (nutriScore: NutriScoreGrade) =>
  nutriScore.toUpperCase() === "B";

export const isNutriScoreC = (nutriScore: NutriScoreGrade) =>
  nutriScore.toUpperCase() === "C";

export const isNutriScoreD = (nutriScore: NutriScoreGrade) =>
  nutriScore.toUpperCase() === "D";

export const isNutriScoreE = (nutriScore: NutriScoreGrade) =>
  nutriScore.toUpperCase() === "E";

export const getNutrientsForCatalogDictionary = (
  data: NutrientFamilyData[] | NutrientItemData[] | SubstanceData[],
  dictionary?: Dictionary<string>
) => {
  if (isObjectEmpty(dictionary)) {
    dictionary = {};
  }

  if (!isListEmpty(data)) {
    data.forEach(tree => {
      if (tree?.nutrients || tree?.children) {
        const nutrientId = tree.familyId || tree.id;
        dictionary[nutrientId] = tree.familyName || tree.name;
        getNutrientsForCatalogDictionary(
          tree.nutrients || tree.children,
          dictionary
        );
      }
    });
  }
  return dictionary;
};

export const isNutriScoreCalculationStateError = (state: string): boolean =>
  state.toUpperCase() === NUTRISCORE_CALCULATION_STATE.ERROR.toUpperCase();

export const isNutriScoreCalculationStateMandatoryFieldsMissing = (
  state: string
): boolean =>
  state.toUpperCase() ===
  NUTRISCORE_CALCULATION_STATE.MANDATORY_FIELDS_MISSING.toUpperCase();

export const getAdditionalNutriScorePoints = ({
  uiSchema,
  nutriScore,
  nutriScoreProperties,
}: {
  uiSchema: UiSchemaInfo;
  nutriScore: NutriScoreData;
  nutriScoreProperties: Dictionary<string>;
}): NutriscorePointData[] => {
  if (isObjectEmpty(nutriScore)) {
    return [];
  }

  let additionalPoints: NutriscorePointData[] = [];

  if (!isObjectEmpty(nutriScore?.fruitsAndVegetablesPoint)) {
    additionalPoints.push({
      ...nutriScore?.fruitsAndVegetablesPoint,
      name: nutriScoreProperties.fruitsAndVegetablesPoint,
    });
  }

  if (!isObjectEmpty(nutriScore?.nonNutritiveSweetenersPoint)) {
    additionalPoints.push({
      ...nutriScore?.nonNutritiveSweetenersPoint,
      name: nutriScoreProperties.nonNutritiveSweetenersPoint,
    });
  }

  if (isListEmpty(additionalPoints)) {
    return [];
  }

  return additionalPoints.map(({ name, ...rest }) => ({
    ...rest,
    name: getFieldTitleFromUiSchemaObjectTemplate(uiSchema, name),
  }));
};
