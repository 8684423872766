import React from "react";
import classNames from "classnames";
import RelativeNetContent from "./components/RelativeNetContent";
import { getPropertiesObjectFromArray } from "../../templates/utils";
import { ObjectFieldTemplateProps } from "components/SpecificationSection/types";
import { ProductInformationFormData } from "../../types";
import styles from "./styles.module.less";

export interface ProductInformationWidgetProps
  extends ObjectFieldTemplateProps<ProductInformationFormData> {}

const ProductInformationWidget: React.FC<ProductInformationWidgetProps> = ({
  formData,
  formContext,
  properties,
  uiSchema,
}) => {
  const propertyObjects = getPropertiesObjectFromArray(properties);

  return (
    <>
      <div
        className={classNames(
          styles["product-information-evenGreyBgColor"],
          "pb-3"
        )}
      >
        {propertyObjects?.displayAllOnPack?.content}
        {propertyObjects?.displayProductNameOnPack?.content}
        {propertyObjects?.displayGtinOnPack?.content}
        {propertyObjects?.netContents?.content}
        {propertyObjects?.additionalNetContents?.content}
        {propertyObjects?.alcoholVolume?.content}
      </div>

      <RelativeNetContent
        formContext={formContext}
        formData={formData}
        propertyObjects={propertyObjects}
        uiSchema={uiSchema}
      />
    </>
  );
};

export default ProductInformationWidget;
