import { useEffect, useMemo, useState } from "react";
import { useBoolean, useMaterialData } from "hooks";
import { getUniqueFlatlistFromIngredients } from "dtos/material";
import { LIBRARY_OBJECT_TYPES } from "utils/constants";
import { sortByKey } from "utils/general";
import { IngredientListFormData } from "components/SpecificationSection/components/FormSchema/types";
import { CharacteristicIngredientViewModel } from "viewModels";

interface UseCharacteristicIngredientProps {
  characteristicIngredients: IngredientListFormData["ingredients"];
  formData: IngredientListFormData;
  updateIngredientList: (_: IngredientListFormData) => Promise<void>;
  setSelectedIngredientId: Function;
}

const useCharacteristicIngredient = ({
  formData,
  setSelectedIngredientId,
  characteristicIngredients,
  updateIngredientList,
}: UseCharacteristicIngredientProps) => {
  const {
    recipeIngredients,
    getMaterialData,
    loadingMaterialData: isRecipeLoading,
  } = useMaterialData({
    materialId: formData?.compositeId,
    materialType: LIBRARY_OBJECT_TYPES.MADE_IN_HOUSE,
    fetchOnEffect: false,
  });

  const [
    characteristicIngredientsViewData,
    setCharacteristicIngredientsViewData,
  ] = useState<CharacteristicIngredientViewModel[]>();

  const {
    value: isAdding,
    setTrue: setIsAddingTrue,
    setFalse: setIsAddingFalse,
  } = useBoolean(false);
  const {
    value: isRemoving,
    setTrue: setIsRemovingTrue,
    setFalse: setIsRemovingFalse,
  } = useBoolean(false);

  const onDropdownVisibleChange = async (visible: boolean) => {
    if (visible) {
      await getMaterialData?.();
    }
  };

  const uniqueSortedFlatList = sortByKey({
    array: getUniqueFlatlistFromIngredients(recipeIngredients),
    key: "name",
  });

  useEffect(() => {
    const charIngWithMaterialIdAndIngredientId =
      characteristicIngredients?.map(ingredient => {
        const ingredientWholeData = uniqueSortedFlatList.find(item =>
          ingredient?.ingredientId
            ? item.ingredientId === ingredient?.ingredientId
            : item.materialId === ingredient.materialId
        );

        return {
          ingredientId: ingredientWholeData?.ingredientId,
          materialId: ingredientWholeData?.materialId,
          name: ingredientWholeData?.name,
        };
      }) || [];

    setCharacteristicIngredientsViewData(charIngWithMaterialIdAndIngredientId);
  }, [JSON.stringify(characteristicIngredients), recipeIngredients]);

  const onUpdateIngredient = async (
    ingredientId: string,
    characteristicIngredient: boolean
  ) => {
    const updatedIngredients = formData?.ingredients?.map(ingredient => {
      if (ingredient?.ingredientId === ingredientId) {
        return {
          ...ingredient,
          characteristicIngredient,
        };
      }

      return { ...ingredient };
    });

    await updateIngredientList({
      ...formData,
      ingredients: updatedIngredients,
    });
  };

  const onAddCharacteristicIngredient = async (ingredientId: string) => {
    try {
      setIsAddingTrue();

      await onUpdateIngredient(ingredientId, true);

      setSelectedIngredientId?.(null);
    } catch {
    } finally {
      setIsAddingFalse();
    }
  };

  const onRemoveCharacteristicIngredient = async (ingredientId: string) => {
    try {
      setIsRemovingTrue();

      await onUpdateIngredient(ingredientId, false);
    } catch (error) {
    } finally {
      setIsRemovingFalse();
    }
  };

  useEffect(() => {
    onDropdownVisibleChange(true);
  }, []);

  const { options } = useMemo(() => {
    const options = uniqueSortedFlatList?.reduce((acc, ingredient) => {
      if (
        !characteristicIngredients?.some(
          item =>
            item?.materialId === ingredient?.materialId ||
            item?.ingredientId === ingredient?.ingredientId
        )
      ) {
        acc.push({
          key: ingredient?.ingredientId,
          label: ingredient?.name,
          value: ingredient?.ingredientId,
        });
      }
      return acc;
    }, []);

    return {
      options,
    };
  }, [recipeIngredients, JSON.stringify(characteristicIngredients)]);

  return {
    onDropdownVisibleChange,
    onAddCharacteristicIngredient,
    onRemoveCharacteristicIngredient,
    isRecipeLoading,
    isAdding,
    isRemoving,
    options,
    characteristicIngredientsViewData,
  };
};

export default useCharacteristicIngredient;
