import { Dispatch, SetStateAction } from "react";
import { updateLabelingTranslationBlock } from "apis/SPEC/labeling-translations";
import {
  createMaterialIngredientFromMaterial,
  deleteMaterialIngredient,
  updateMaterialIngredient,
} from "apis/SPEC";
import { EnhancedExtraActions } from "components/SpecificationSection/types";
import { useAppDispatch } from "store";
import {
  getChapter,
  updateBlock,
  updateChapterNutrition,
  addChapterFreeFromAllergens,
  removeChapterFreeFromAllergen,
  updateChapterFreeFromAllergen,
} from "store/chapterForm/asyncActions";
import { getParsedData } from "utils/general";
import { ChapterStructureFormDataBlock } from "types/library";

export type UseChapterBlockActions = {
  sectionId: string;
  resetSectionValidationErrors: Function;
  setSectionBlocks?: Dispatch<
    SetStateAction<ChapterStructureFormDataBlock[] | null>
  >;
  translationId?: string;
  getTranslation?: Function;
  getLabelingTranslationLanguages?: Function;
};

const useChapterBlockActions = ({
  sectionId,
  resetSectionValidationErrors,
  setSectionBlocks,
  translationId,
  getTranslation,
  getLabelingTranslationLanguages,
}: UseChapterBlockActions) => {
  const dispatch = useAppDispatch();

  const saveTemplateBlock = (blockId: string) => async (blockJson: string) => {
    resetSectionValidationErrors(sectionId);

    setSectionBlocks?.(prevState => {
      return (prevState || []).map(block => {
        if (block.blockId === blockId) {
          const inputData = getParsedData(blockJson);
          const existingData = getParsedData(block.dataAsJson);
          const updatedData = { ...existingData, ...inputData };

          return {
            ...block,
            dataAsJson: JSON.stringify(updatedData),
          };
        }
        return block;
      });
    });

    if (translationId) {
      await updateLabelingTranslationBlock({
        translationId,
        blockId,
        json: blockJson,
      });

      await getTranslation?.(translationId, false);
      await getLabelingTranslationLanguages?.();

      return;
    }

    const { payload, meta } = await dispatch(
      updateBlock({
        blockId,
        blockJson,
      })
    );

    return {
      payload,
      requestStatus: meta.requestStatus,
    };
  };

  const onFinish = () => {
    if (translationId) {
      return;
    }

    dispatch(getChapter());
  };

  const updateNutrition: EnhancedExtraActions["updateNutrition"] = async nutritionData => {
    resetSectionValidationErrors(sectionId);

    await dispatch(updateChapterNutrition({ nutritionData }));
  };

  const addRecipeIngredient: EnhancedExtraActions["addRecipeIngredient"] = async payload => {
    resetSectionValidationErrors(sectionId);

    await createMaterialIngredientFromMaterial(payload);
    onFinish();
  };

  const updateRecipeIngredient: EnhancedExtraActions["updateRecipeIngredient"] = async payload => {
    resetSectionValidationErrors(sectionId);

    await updateMaterialIngredient(payload);
    onFinish();
  };

  const removeRecipeIngredient: EnhancedExtraActions["removeRecipeIngredient"] = async payload => {
    resetSectionValidationErrors(sectionId);

    await deleteMaterialIngredient(payload);
    onFinish();
  };

  const addFreeFromAllergens = async (allergenIds: string[]) => {
    resetSectionValidationErrors(sectionId);

    await dispatch(
      addChapterFreeFromAllergens({
        allergenIds,
      })
    );
  };

  const removeFreeFromAllergen: EnhancedExtraActions["removeFreeFromAllergen"] = async ({
    allergenId,
  }) => {
    resetSectionValidationErrors(sectionId);

    await dispatch(removeChapterFreeFromAllergen({ allergenId }));
  };

  const updateFreeFromAllergen: EnhancedExtraActions["updateFreeFromAllergen"] = async ({
    allergenId,
    fileId,
  }) => {
    resetSectionValidationErrors(sectionId);

    await dispatch(updateChapterFreeFromAllergen({ allergenId, fileId }));
  };

  return {
    onFinish,
    saveTemplateBlock,
    addRecipeIngredient,
    updateRecipeIngredient,
    removeRecipeIngredient,
    addFreeFromAllergens,
    removeFreeFromAllergen,
    updateFreeFromAllergen,
    updateNutrition,
  };
};
export default useChapterBlockActions;
