import { Datatable } from "@trace-one/design-system";
import { useIntl } from "react-intl";
import FormCard from "../FormCard";
import AddAnAllergen from "../AddAnAllergen";
import { useMaterialPermissions } from "hooks";
import { useAllergensActions } from "./hooks";
import { getRowKeyForTable } from "utils/table";
import { getColumns } from "./utils";
import { useAllergensInOrder } from "components/Library/components/MaterialForm/hooks";
import libraryMessages from "messages/library";
import messages from "./messages";
import { AllergenProps } from "./types";
import styles from "components/Library/components/MaterialForm/styles.module.less";

const Allergens: React.FC<AllergenProps> = ({
  allergenDictionary,
  displayActions,
  selectedAllergens,
}) => {
  const { formatMessage } = useIntl();
  const {
    onAddAllergens,
    onChangeAllergenPresent,
    onRemoveAllergen,
  } = useAllergensActions();
  const { disableActions } = useMaterialPermissions();

  const { currentAllergens } = useAllergensInOrder({
    allergenDictionary,
    selectedAllergens,
  });

  return (
    <FormCard
      className={styles.rawMaterialFormWideCardBody}
      title={formatMessage(libraryMessages.cardTitle["allergens"])}
      name="allergens"
    >
      <Datatable
        tableLayout="fixed"
        columns={getColumns({
          displayActions,
          formatMessage,
          allergenDictionary,
          onChangeAllergenPresent,
          onRemoveAllergen,
          disableActions,
        })}
        dataSource={currentAllergens}
        pagination={false}
        rowKey={getRowKeyForTable("allergen")}
        emptyText={formatMessage(messages["noAllergens"])}
      />
      {displayActions && (
        <AddAnAllergen
          selectedAllergens={selectedAllergens.map(
            ({ allergenId }) => allergenId
          )}
          onAddAllergens={onAddAllergens}
          disableActions={disableActions}
        />
      )}
    </FormCard>
  );
};

Allergens.defaultProps = {
  displayActions: true,
  selectedAllergens: [],
};

export default Allergens;
