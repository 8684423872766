import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { toaster as toasterService } from "@trace-one/design-system";
import {
  deleteSpecificationRequirement,
  linkRequirementToSpecification,
  reviseRequirementSpecification,
} from "apis/SPEC";
import { useBoolean, useModalVisibility, useRowSelection } from "hooks";
import { getLinkedSpecificationsModalActionMap } from "components/Library/components/utils";
import { LIBRARY_OBJECT_TYPES } from "utils/constants";
import { generalMessages, versionHistoryMessages } from "messages";
import {
  LinkedSpecificationsAction,
  LinkedSpecificationsModalProps,
  ModalActionType,
} from "components/LinkedSpecificationsModal/types";
import { UseVersionHistoryActionsProps } from "components/Library/components/types";

const useRequirementVersionHistoryActions = ({
  versionHistoryCallback,
  linkedSpecificationsCallback,
}: UseVersionHistoryActionsProps) => {
  const { formatMessage } = useIntl();

  const { isModalOpen, onOpenModal, onCloseModal } = useModalVisibility();
  const {
    selectedRowKeys,
    onSelectRow,
    onSelectAllRows,
    resetSelectedRowKeys,
  } = useRowSelection();

  const rowSelection = {
    selectedRowKeys,
    onSelect: onSelectRow,
    onSelectAll: onSelectAllRows,
  };

  const [
    requirementActionData,
    setRequirementActionData,
  ] = useState<LinkedSpecificationsAction>({});
  const {
    value: shouldRefetchVersionHistory,
    setTrue,
    setFalse,
  } = useBoolean();

  const [modalProps, setModalProps] = useState<LinkedSpecificationsModalProps>(
    null
  );

  const onUnlinkSpecifications = async () => {
    try {
      for (let specificationId of selectedRowKeys) {
        try {
          await deleteSpecificationRequirement({
            specificationId,
            requirementId: requirementActionData.selectedLibraryItemId,
          });
        } catch (error) {
          console.error(error);
          throw error;
        }
      }

      setTrue();

      toasterService.confirmation({
        message: formatMessage(
          versionHistoryMessages.unlinkSpecificationsToasterMessage
        ),
        description: formatMessage(
          versionHistoryMessages.unlinkSpecificationsRequirementToasterDescription
        ),
      });

      await versionHistoryCallback?.();
      await linkedSpecificationsCallback?.();
    } catch {
    } finally {
      handleCloseModal();
      setFalse();
    }
  };

  const onLinkSpecifications = async () => {
    try {
      for (let specificationId of selectedRowKeys) {
        try {
          await linkRequirementToSpecification({
            specificationId,
            requirementId: requirementActionData.selectedLibraryItemId,
          });
        } catch (error) {
          console.error(error);
          throw error;
        }
      }

      setTrue();

      toasterService.confirmation({
        message: formatMessage(
          versionHistoryMessages.updateToLatestVersionToasterMessage
        ),
        description: formatMessage(
          versionHistoryMessages.updateToLatestVersionRequirementToasterDescription
        ),
      });

      await versionHistoryCallback?.();
      await linkedSpecificationsCallback?.();
      handleCloseModal();
    } catch {
    } finally {
      setFalse();
    }
  };

  const onReviseSpecifications = async () => {
    try {
      setTrue();

      for (let specificationId of selectedRowKeys) {
        try {
          await reviseRequirementSpecification({
            specificationId,
            requirementId: requirementActionData.selectedLibraryItemId,
          });
        } catch (error) {
          console.error(error);
          throw error;
        }
      }

      toasterService.confirmation({
        message: formatMessage(versionHistoryMessages.reviseToasterMessage),
        description: formatMessage(
          versionHistoryMessages.reviseRequirementToasterDescription
        ),
      });

      await versionHistoryCallback?.();
      await linkedSpecificationsCallback?.();
    } catch {
    } finally {
      handleCloseModal();
      setFalse();
    }
  };

  const PrimaryActionMapping = {
    [ModalActionType.UNLINK]: onUnlinkSpecifications,
    [ModalActionType.UPDATE]: onLinkSpecifications,
    [ModalActionType.REVISE]: onReviseSpecifications,
  };

  const handleOpenModal = ({
    selectedLibraryItemId,
    actionType,
    libraryItemState,
    versionNumber,
  }: LinkedSpecificationsAction) => () => {
    setRequirementActionData({
      selectedLibraryItemId,
      actionType,
      libraryItemState,
      versionNumber,
    });

    onOpenModal();
  };

  const ActionTypeMap = getLinkedSpecificationsModalActionMap(
    LIBRARY_OBJECT_TYPES.REQUIREMENTS
  );

  useEffect(() => {
    const actionType = requirementActionData.actionType;

    if (actionType) {
      const actionTypeData = ActionTypeMap[actionType];

      setModalProps(prevState => ({
        ...prevState,
        ...actionTypeData,
        title: formatMessage(actionTypeData.title),
        subtitle: formatMessage(actionTypeData.subtitle),
        primaryButtonLabel: formatMessage(actionTypeData.primaryButtonLabel),
        secondaryButtonLabel: formatMessage(generalMessages.cancel),
        onPrimaryButtonClick: PrimaryActionMapping[actionType],
        onSecondaryButtonClick: handleCloseModal,
      }));
    }
  }, [requirementActionData, selectedRowKeys]);

  const handleCloseModal = () => {
    setModalProps({});
    setRequirementActionData({});
    resetSelectedRowKeys();
    onCloseModal();
  };

  return {
    isModalOpen,
    handleOpenModal,
    handleCloseModal,
    rowSelection,
    ...requirementActionData,
    ...modalProps,
    shouldRefetchVersionHistory,
  };
};

export default useRequirementVersionHistoryActions;
