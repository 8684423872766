import { useMemo, useState } from "react";
import { Col, Row } from "antd";
import { useIntl } from "react-intl";
import { Modal, Typography } from "@trace-one/design-system";
import LinkZone from "./components/LinkZone";
import SelectItem from "components/Library/components/SelectItem";
import { useLinksDetailsModal } from "./hooks";
import { useIsScrollable } from "hooks";
import { usePackagingSystemContext } from "../../../../context/packagingSystemContext";
import { LinksDetailsProvider } from "contexts/LinksDetailsContext";
import {
  generalMessages,
  linksDetailsModalMessages,
  packagingMessages,
} from "messages";
import { SelectOption } from "types/general";

export type LinksDetailsModalProps = {
  initialPackagingComponentId: string;
  onCancel: () => void;
};

const LinksDetailsModal: React.FC<LinksDetailsModalProps> = ({
  initialPackagingComponentId,
  onCancel,
}) => {
  const { formatMessage } = useIntl();

  const [selectedComponentId, setSelectedComponentId] = useState<string>(
    initialPackagingComponentId
  );

  const { isScrollable } = useIsScrollable({
    className: ".ant-modal-body",
  });

  const {
    packagingSystemId,
    packagingSystemComponentsData = [],
    componentLinks,
  } = usePackagingSystemContext();

  const {
    saving,
    onSave,
    onUnlink,
    onUnlinkAll,
    onAddALink,
    onChangeComponentIdToLink,
    unsavedComponentLinks,
    linkedComponentIds,
    unsavedLinkedComponentIds,
    hasUnselectedComponent,
    hasUnsavedChanges,
  } = useLinksDetailsModal({
    packagingSystemId,
    selectedComponentId,
    componentLinks,
  });

  const selectedPackagingComponent = useMemo(
    () =>
      packagingSystemComponentsData.find(
        ({ componentId }) => componentId === selectedComponentId
      ),
    [selectedComponentId]
  );

  const onChangeSelectedComponentId = (value: string) => {
    setSelectedComponentId(value);
  };

  const options: SelectOption[] = packagingSystemComponentsData.map(
    ({ componentId, name }) => ({
      key: componentId,
      label: name,
      value: componentId,
    })
  );

  return (
    <Modal
      open
      size="l"
      title={formatMessage(packagingMessages.linksDetails)}
      keyboard={false}
      headerChildren={
        <Col span={24}>
          <Row className="mb-2">
            <Typography variant="body-regular" color="grey-5">
              {formatMessage(
                linksDetailsModalMessages.linksDetailsModalSubtitle
              )}
            </Typography>
          </Row>
          <Row>
            <Col span={11} data-testid="links-details-select-component">
              <SelectItem
                label={formatMessage(generalMessages.component)}
                selectedValue={selectedComponentId}
                options={options}
                onChange={onChangeSelectedComponentId}
                placeholder={formatMessage(packagingMessages.selectAComponent)}
                confirmationTooltipText={
                  hasUnsavedChanges
                    ? formatMessage(
                        linksDetailsModalMessages.changeComponentConfirmation
                      )
                    : null
                }
                data-test-id="links-details-select-component"
              />
            </Col>
          </Row>
        </Col>
      }
      onCancel={onCancel}
      secondaryButtonText={formatMessage(generalMessages.cancel)}
      secondaryButtonProps={{ disabled: saving }}
      onSecondaryButtonClick={onCancel}
      primaryButtonText={formatMessage(generalMessages.save)}
      primaryButtonProps={{
        loading: saving,
        disabled: hasUnselectedComponent || !hasUnsavedChanges,
      }}
      onPrimaryButtonClick={onSave}
      data-testid="links-details-modal"
    >
      <LinksDetailsProvider
        value={{
          options,
          selectedPackagingComponent,
          onChangeComponentIdToLink,
          selectedComponentId,
          onUnlink,
          onUnlinkAll,
          selectedComponentLinks: unsavedComponentLinks,
          onAddALink,
          linkedComponentIds,
          unsavedLinkedComponentIds,
          hasUnselectedComponent,
          saving,
          onSave,
          isModalContentScrollable: isScrollable,
        }}
      >
        <LinkZone />
      </LinksDetailsProvider>
    </Modal>
  );
};

export default LinksDetailsModal;
