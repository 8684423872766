import { Col, Row } from "antd";
import { useIntl } from "react-intl";
import classNames from "classnames";
import {
  Button,
  EmptyState,
  Typography,
  Divider,
} from "@trace-one/design-system";
import { ConfirmationTooltip } from "components";
import ConditionalWrapper from "components/ConditionalWrapper";
import LinkItem from "../LinkItem";
import { isListEmpty } from "utils/general";
import { useLinksDetailsContext } from "contexts/LinksDetailsContext";
import {
  generalMessages,
  linksDetailsModalMessages,
  packagingMessages,
} from "messages";
import styles from "../../styles.module.less";

const LinkZone: React.FC = () => {
  const { formatMessage } = useIntl();

  const {
    selectedComponentId,
    selectedComponentLinks = [],
    selectedPackagingComponent,
    onUnlinkAll,
    onAddALink,
    linkedComponentIds,
    hasUnselectedComponent,
    saving,
  } = useLinksDetailsContext();

  const prefixCls = "links-details-modal";

  const numberOfLinksMessage = `${
    linkedComponentIds?.length || 0
  } ${formatMessage(
    linkedComponentIds?.length === 1
      ? generalMessages.link
      : generalMessages.links
  ).toLocaleLowerCase()}`;

  const renderWrappedComponent = children => (
    <ConfirmationTooltip
      onConfirmation={onUnlinkAll}
      text={formatMessage(linksDetailsModalMessages.unlinkAllConfirmation)}
    >
      {children}
    </ConfirmationTooltip>
  );

  if (!selectedComponentId) {
    return (
      <Row justify="center">
        <EmptyState
          heading={formatMessage(packagingMessages.selectAComponent)}
        />
      </Row>
    );
  }

  return (
    <Col span={24} className={classNames(styles[prefixCls])}>
      <div className={classNames(styles[`${prefixCls}__link-zone`])}>
        <div>
          <div className="d-flex ai-center">
            <div className={styles[`${prefixCls}__selected-component`]}>
              <Row className="mb-1" align="middle">
                <Typography variant="link-m" color="primary">
                  {selectedPackagingComponent?.name}
                </Typography>
              </Row>
              <Row>
                <Typography variant="body-regular" color="grey-4">
                  {numberOfLinksMessage}
                </Typography>
              </Row>
              {!isListEmpty(selectedComponentLinks) && (
                <div className={styles[`${prefixCls}__circle`]} />
              )}
            </div>

            {!isListEmpty(selectedComponentLinks) && (
              <Divider
                dashed
                color="grey-3"
                className={styles[`${prefixCls}__selected-component--divider`]}
              />
            )}
          </div>

          <Row className="mt-1" align="middle">
            <Button
              type="tertiary"
              className="mr-2"
              onClick={onAddALink}
              disabled={
                hasUnselectedComponent || saving || !selectedComponentId
              }
            >
              {formatMessage(linksDetailsModalMessages.addALink)}
            </Button>
            <ConditionalWrapper
              condition={!saving && !isListEmpty(linkedComponentIds)}
              wrapper={renderWrappedComponent}
            >
              <Typography
                variant="link-m"
                color="primary"
                disabled={saving || isListEmpty(linkedComponentIds)}
                className="pr-2"
              >
                {formatMessage(linksDetailsModalMessages.unlinkAll)}
              </Typography>
            </ConditionalWrapper>
          </Row>
        </div>

        <div
          className={classNames(
            styles[`${prefixCls}__selected-component-link-wrapper`]
          )}
        >
          {isListEmpty(selectedComponentLinks) ? (
            <Row justify="center">
              <EmptyState
                heading={formatMessage(linksDetailsModalMessages.emptyState)}
              />
            </Row>
          ) : (
            selectedComponentLinks.map((componentLink, index) => {
              const selectedComponentsCount = selectedComponentLinks.length;

              return (
                <div
                  className={classNames(
                    styles[`${prefixCls}__selected-component-link`],
                    {
                      [styles[`${prefixCls}__selected-component-link--border`]]:
                        index !== 0 && selectedComponentsCount !== index + 1,
                      [styles[
                        `${prefixCls}__selected-component-link--half-border`
                      ]]:
                        (index === 0 ||
                          selectedComponentsCount === index + 1) &&
                        selectedComponentsCount > 1,
                      [styles[
                        `${prefixCls}__selected-component-link--half-border-top`
                      ]]: index === 0 && selectedComponentsCount > 1,
                      [styles[
                        `${prefixCls}__selected-component-link--half-border-bottom`
                      ]]:
                        selectedComponentsCount === index + 1 &&
                        selectedComponentsCount > 1,
                    }
                  )}
                  key={componentLink.id}
                >
                  <Divider
                    dashed
                    className={
                      styles[`${prefixCls}__selected-component-link--divider`]
                    }
                    color="grey-3"
                  />

                  <LinkItem
                    key={componentLink.id}
                    componentLink={componentLink}
                  />
                </div>
              );
            })
          )}
        </div>
      </div>
    </Col>
  );
};

export default LinkZone;
