import { useContext, useEffect, useState } from "react";
import { LabeledValue } from "antd/lib/select";
import { useSelector } from "react-redux";
import { selectLanguageCode } from "store/user/selectors";
import {
  MAX_CLAIM_NUTRIENT_SIZE,
  getDictionary,
  isObjectEmpty,
} from "utils/general";
import { fetchAll } from "utils/api";
import { fetchNutrientByIds, fetchSubstancesByIds } from "apis/CATALOGUE";
import {
  CLAIM_VALIDATION_RULE_TYPE,
  NutrientFamilyData,
  RegulatoryHealthClaim,
  SubstanceData,
} from "models";
import { fetchHealthRegulatoryClaims } from "apis/SPEC";
import { getNutrientsForCatalogDictionary } from "utils/nutrition";
import { useAppDispatch } from "store";
import {
  selectClaimForm,
  setClaimFormData,
} from "store/claimForm/claimFormSlice";
import { updateCatalogDictionary } from "store/generalData/asyncActions";
import { ClaimFormContext } from "components/Library/components/ClaimForm/contexts/ClaimFormContext";

const HEALTH_CLAIM_TAKE_SIZE = 100;

const useHealthClaimConfiguration = () => {
  const dispatch = useAppDispatch();
  const languageCode = useSelector(selectLanguageCode);
  const claimForm = useSelector(selectClaimForm);
  const { generalInfo, regulation } = claimForm;

  const { form } = useContext(ClaimFormContext);

  const [currentPage, setCurrentPage] = useState(1);
  const [hasLoadedAllClaims, setHasLoadedAllClaims] = useState(false);

  const [healthClaims, setHealthClaims] = useState<RegulatoryHealthClaim[]>([]);
  const [allNutrients, setAllNutrients] = useState<LabeledValue[]>([]);
  const [uniqueNutrientIds, setUniqueNutrientIds] = useState(undefined);

  const [nutrientMap, setNutrientMap] = useState(undefined);
  const [healthClaimsMap, setHealthClaimsMap] = useState(undefined);

  const [selectedNutrientId, setSelectedNutrientId] = useState(undefined);
  const [nutrientOptions, setNutrientOptions] = useState<LabeledValue[]>([]);
  const [claimSentenceOptions, setClaimSentenceOptions] = useState<
    LabeledValue[]
  >([]);

  const [isLoading, setIsLoading] = useState(false);
  const [showNutrientError, setShowNutrientError] = useState(false);

  const [substanceMap, setSubstanceMap] = useState(undefined);
  const [uniqueSubstanceIds, setUniqueSubstanceIds] = useState(undefined);

  useEffect(() => {
    if (!isObjectEmpty(regulation)) {
      // Make changes here once we have a way to get the selected nutrient id from response
      // Write now it will show wrong selected substances after save.
      const nutrientId = regulation?.validationRule?.[0]?.id;

      setSelectedNutrientId(nutrientId);
      form.setFieldValue("nutrient", nutrientId);
    }
  }, [generalInfo, selectedNutrientId]);

  /**
   * Nutrient: Methods
   */
  const onNutrientSearch = (value: string) => {
    const foundList = allNutrients.filter(nutrient =>
      nutrient.label.toString().toLowerCase().includes(value?.toLowerCase())
    );
    setNutrientOptions(foundList);
  };

  const resetRegulatoryClaims = () => {
    form.setFieldValue("requiresSupportingDocuments", null);
    form.setFieldValue("regulatoryClaimId", null);
    dispatch(
      setClaimFormData({
        ...claimForm,
        generalInfo: {
          ...generalInfo,
          regulatoryClaimId: null,
          requiresSupportingDocuments: null,
        },
        regulation: null,
      })
    );
  };

  const onNutrientChange = (id: string) => {
    setSelectedNutrientId(id);
    resetRegulatoryClaims();
  };

  const onNutrientClear = () => {
    setSelectedNutrientId(undefined);
    setNutrientOptions(allNutrients);
    resetRegulatoryClaims();
  };

  const fetchAllNutrientsAndSubstances = async () => {
    const claimSentencesMap = {};

    try {
      setIsLoading(true);

      const { nutrientIds, substanceIds } = healthClaims.reduce(
        (acc, claim) => {
          claim.validationRule.forEach(({ id, itemType }) => {
            if (itemType === CLAIM_VALIDATION_RULE_TYPE.NUTRIENT) {
              acc.nutrientIds.push(id);
            } else if (itemType === CLAIM_VALIDATION_RULE_TYPE.SUBSTANCE) {
              acc.substanceIds.push(id);
            }

            const claimIdAlreadyInMap = claimSentencesMap[id];
            if (claimIdAlreadyInMap) {
              claimSentencesMap[id] = [...claimIdAlreadyInMap, claim];
            } else {
              claimSentencesMap[id] = [claim];
            }
          });
          return acc;
        },
        { nutrientIds: [], substanceIds: [] }
      );

      setHealthClaimsMap(claimSentencesMap);

      let uniqueNutrientIds = Array.from(new Set(nutrientIds));
      let uniqueSubstanceIds = Array.from(new Set(substanceIds));

      const totalUniqueIds =
        uniqueNutrientIds.length + uniqueSubstanceIds.length;
      const hasMoreItemsThanAllowed = totalUniqueIds > MAX_CLAIM_NUTRIENT_SIZE;

      if (hasMoreItemsThanAllowed) {
        const allowedNutrients = Math.floor(MAX_CLAIM_NUTRIENT_SIZE / 2);
        const allowedSubstances = MAX_CLAIM_NUTRIENT_SIZE - allowedNutrients;
        uniqueNutrientIds = uniqueNutrientIds.slice(0, allowedNutrients);
        uniqueSubstanceIds = uniqueSubstanceIds.slice(0, allowedSubstances);
      }

      setUniqueNutrientIds(uniqueNutrientIds);
      setUniqueSubstanceIds(uniqueSubstanceIds);
      setShowNutrientError(hasMoreItemsThanAllowed);

      // Fetch nutrients
      const nutrientData = await fetchAll<NutrientFamilyData>({
        ids: uniqueNutrientIds,
        dataFetcher: async ids => {
          const { data } = await fetchNutrientByIds({
            nutrientIds: ids,
            languageCode,
          });
          dispatch(
            updateCatalogDictionary(getNutrientsForCatalogDictionary(data))
          );
          return data;
        },
      });

      // Fetch substances
      const substanceData = await fetchAll<SubstanceData>({
        ids: uniqueSubstanceIds,
        dataFetcher: async ids => {
          const { data } = await fetchSubstancesByIds({
            substanceIds: ids,
            languageCode,
          });

          const substancesDictionary = getDictionary({
            data,
            key: "id",
            value: "name",
          });

          dispatch(updateCatalogDictionary(substancesDictionary));

          return data;
        },
      });

      const flatNutrientObject = getNutrientsForCatalogDictionary(nutrientData);
      const flatSubstanceObject = substanceData?.reduce((acc, substance) => {
        return {
          ...acc,
          [substance.id]: substance.name,
        };
      }, {});

      setNutrientMap(flatNutrientObject);
      setSubstanceMap(flatSubstanceObject);
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };

  /**
   * Regulatory Claims: Methods
   */

  const onClaimChange = (id: string, claim) => {
    const { value, requiresSupportingDocuments } = claim || {};

    form.setFieldValue(
      "requiresSupportingDocuments",
      requiresSupportingDocuments
    );

    dispatch(
      setClaimFormData({
        ...claimForm,
        generalInfo: {
          ...generalInfo,
          regulatoryClaimId: value,
          requiresSupportingDocuments,
        },
      })
    );
  };

  const fetchAllHealthClaims = async () => {
    try {
      setIsLoading(true);
      const {
        data: { items, totalNumberOfItems },
      } = await fetchHealthRegulatoryClaims({
        params: {
          skip: (currentPage - 1) * HEALTH_CLAIM_TAKE_SIZE,
          take: HEALTH_CLAIM_TAKE_SIZE,
        },
      });

      const totalClaimsTillNow = healthClaims.length + items.length;

      setHealthClaims([...healthClaims, ...items]);

      if (totalNumberOfItems > totalClaimsTillNow) {
        setCurrentPage(page => page + 1);
      } else {
        setHasLoadedAllClaims(true);
      }
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };

  /**
   * Nutrient: Hooks
   */

  useEffect(() => {
    if (
      (!isObjectEmpty(nutrientMap) || !isObjectEmpty(substanceMap)) &&
      uniqueNutrientIds &&
      uniqueSubstanceIds
    ) {
      const nutrientList = (uniqueNutrientIds ?? []).map(key => ({
        key,
        value: key,
        label: nutrientMap?.[key],
        type: "nutrient",
      }));

      const substanceList = (uniqueSubstanceIds ?? []).map(key => ({
        key,
        value: key,
        label: substanceMap?.[key],
        type: "substance",
      }));

      const combinedList = [...nutrientList, ...substanceList];

      setNutrientOptions(combinedList);
      setAllNutrients(combinedList);
    }
  }, [nutrientMap, substanceMap, uniqueNutrientIds, uniqueSubstanceIds]);

  /**
   * Claims: Hooks
   */

  useEffect(() => {
    if (selectedNutrientId && healthClaimsMap) {
      const claims: RegulatoryHealthClaim[] =
        healthClaimsMap[selectedNutrientId];

      const claimOptions = claims?.map(claim => {
        const { id, sentenceTranslations, requiresSupportingDocuments } = claim;

        const claimInSelectedLanguage = sentenceTranslations.filter(
          sentence => sentence.languageCode === languageCode
        );

        return {
          key: id,
          value: id,
          label: claimInSelectedLanguage?.[0].text,
          requiresSupportingDocuments: requiresSupportingDocuments,
        };
      });

      const uniqueClaimOptions = claimOptions.filter(
        (item, index, self) =>
          index === self.findIndex(t => t.value === item.value)
      );

      setClaimSentenceOptions(uniqueClaimOptions);
    }
  }, [selectedNutrientId, healthClaimsMap, languageCode]);

  useEffect(() => {
    fetchAllHealthClaims();
  }, [currentPage]);

  useEffect(() => {
    if (hasLoadedAllClaims) {
      fetchAllNutrientsAndSubstances();
    }
  }, [hasLoadedAllClaims]);

  return {
    healthClaims,
    nutrientOptions,
    claimSentenceOptions,
    onNutrientSearch,
    onClaimChange,
    onNutrientChange,
    selectedNutrientId,
    isLoading,
    onNutrientClear,
    showNutrientError,
  };
};

export default useHealthClaimConfiguration;
