import { useEffect } from "react";
import { useMaterialPermissions } from "hooks";
import { MaterialData } from "models";
import { useAppDispatch } from "store";
import {
  getMaterialControlPlans,
  updateMaterialControlPlans,
} from "store/materialForm/asyncActions";
import { MaterialInformationViewModel } from "viewModels";

const useControlPlans = ({
  displayActions,
  materialId,
  materialType,
  recipeType,
}: {
  displayActions: boolean;
  materialId: MaterialData["id"];
  materialType: MaterialData["materialType"];
  recipeType?: MaterialInformationViewModel["recipeType"];
}) => {
  const dispatch = useAppDispatch();
  const { disableActions } = useMaterialPermissions();

  useEffect(() => {
    getControlPlansSchema();
  }, []);

  const getControlPlansSchema = async () => {
    await dispatch(
      getMaterialControlPlans({ materialId, materialType, recipeType })
    );
  };

  const saveTemplateBlock = async (json: string) => {
    await dispatch(
      updateMaterialControlPlans({
        materialId,
        json: JSON.stringify(json),
      })
    );
  };

  const isWidgetEditable = () => displayActions && !disableActions;

  return {
    saveTemplateBlock,
    isWidgetEditable,
  };
};

export default useControlPlans;
