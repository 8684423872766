import { Col, Row, RowProps } from "antd";
import { useIntl } from "react-intl";
import classNames from "classnames";
import { EmptyState, Heading } from "@trace-one/design-system";
import { InformationPanelItem, InformationPanelItems } from "./components";
import { isListEmpty } from "utils/general";
import { FileViewModel } from "viewModels";
import { documentMessages } from "messages";
import styles from "./styles.module.less";

const InformationPanel = ({ items = [] }: { items?: FileViewModel[] }) => {
  const { formatMessage } = useIntl();

  const getComponentForSelectedItems = () => {
    switch (items.length) {
      case 0:
        return (
          <EmptyState
            description={formatMessage(documentMessages.selectADocument)}
          />
        );

      case 1:
        return <InformationPanelItem item={items[0]} />;

      default:
        return <InformationPanelItems items={items} />;
    }
  };

  const panelTitle =
    items?.length > 1
      ? formatMessage(documentMessages.documentsSelectionTitle, {
          numberOfDocumentsSelected: items.length,
        })
      : formatMessage(documentMessages.documentInformationTitle);

  const rowProps: RowProps = isListEmpty(items)
    ? {
        justify: "center",
        align: "middle",
      }
    : {
        justify: "start",
        align: "top",
      };

  return (
    <Col
      span={24}
      className={classNames("bg-white", "h-100", "p-3", "br-regular")}
    >
      <Row>
        <Heading size="xs" className="mb-2">
          {panelTitle}
        </Heading>
      </Row>
      <Row
        {...rowProps}
        className={styles["information-panel__info-area--height"]}
      >
        {getComponentForSelectedItems()}
      </Row>
    </Col>
  );
};

export default InformationPanel;
