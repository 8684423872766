import { PackagingComponentLink, PackagingSystemComponentData } from "models";
import { createContext, useContext } from "react";
import { Dictionary } from "types/general";
import {
  PackagingSystemComponentsTableViewModel,
  PackagingSystemComponentsViewModel,
  PackagingSystemGeneralInformationViewModel,
} from "viewModels";

const PackagingSystemContext = createContext<{
  packagingSystemId: string;
  generalInformation: PackagingSystemGeneralInformationViewModel;
  components: PackagingSystemComponentsViewModel;
  componentLinks: PackagingComponentLink[];
  packagingSystemComponentsData: PackagingSystemComponentData[];
  packagingSystemComponentsTableView: PackagingSystemComponentsTableViewModel[];
  isLoading: boolean;
  refListDictionary: Dictionary<string>;
  packagingComponentIds: string[];
} | null>(null);

export const PackagingSystemProvider = PackagingSystemContext.Provider;

export const usePackagingSystemContext = () => {
  const context = useContext(PackagingSystemContext);
  if (!context) {
    throw new Error(
      "usePackagingSustemContext must be used within a PackagingSystemProvider"
    );
  }
  return context;
};
