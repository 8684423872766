import {
  AxiosInstancesWrapper,
  LanguagePreferenceProvider,
  LoadingPage,
} from "@trace-one/react-components";
import { LocaleProvider } from "@trace-one/design-system";
import {
  AxiosInstancesWrapper as BusinessAxiosInstancesWrapper,
  LanguagePreferenceProvider as BusinessLanguagePreferenceProvider,
} from "@trace-one/business-components";
import { AuthenticationProvider, OidcSecure } from "@axa-fr/react-oidc-context";
import { useSelector } from "react-redux";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { IntlProvider } from "react-intl";
import { getTranslations } from "services/translations.service";
import { getOidcConfiguration } from "store/oidc/utils";
import { selectLanguageCode } from "store/user/selectors";
import { ROUTES } from "utils/constants";
import { Specification } from "pages";
import { axiosInstance as axiosInstanceCUMD } from "apis/CUMD";
import { axiosInstance as axiosInstancePMD } from "apis/PMD";
import { axiosInstance as axiosInstanceRLMD } from "apis/RLMD";
import { axiosInstance as axiosInstanceSMD } from "apis/SMD";
import { axiosInstance as axiosInstanceTAGS } from "apis/TAGS";
import { axiosInstance as axiosInstanceNOTIF } from "apis/NOTIF";
import { axiosInstance as axiosInstancePKG } from "apis/PKG";
import { axiosInstance as axiosInstanceCATALOGUE } from "apis/CATALOGUE";
import { axiosInstance as axiosInstanceCLB } from "apis/CLB";
import { axiosInstance as axiosInstanceDOCUMENT } from "apis/DOCUMENT";
import { axiosInstance as axiosInstanceTON } from "apis/TON";
import { axiosInstance as axiosInstanceDISCUSS } from "apis/DISCUSS";
import { axiosInstance as axiosInstanceAI } from "apis/AI";
import { axiosInstance as axiosInstanceCERTIFICATION } from "apis/CERTIFICATION";
import StarterWrapper from "components/StarterWrapper";
import { configureDayjsPlugins } from "utils/date";

const App = () => {
  configureDayjsPlugins();

  const languageCode = useSelector(selectLanguageCode);

  return (
    <AxiosInstancesWrapper
      axiosInstanceCUMD={axiosInstanceCUMD}
      axiosInstancePMD={axiosInstancePMD}
      axiosInstanceRLMD={axiosInstanceRLMD}
      axiosInstanceSMD={axiosInstanceSMD}
      axiosInstanceTAGS={axiosInstanceTAGS}
      axiosInstanceNOTIF={axiosInstanceNOTIF}
      axiosInstancePKG={axiosInstancePKG}
      axiosInstanceCATALOGUE={axiosInstanceCATALOGUE}
      axiosInstanceCLB={axiosInstanceCLB}
      axiosInstanceTON={axiosInstanceTON}
    >
      <BusinessAxiosInstancesWrapper
        axiosInstanceCUMD={axiosInstanceCUMD}
        axiosInstancePMD={axiosInstancePMD}
        axiosInstanceRLMD={axiosInstanceRLMD}
        axiosInstanceSMD={axiosInstanceSMD}
        axiosInstanceTAGS={axiosInstanceTAGS}
        axiosInstanceNOTIF={axiosInstanceNOTIF}
        axiosInstancePKG={axiosInstancePKG}
        axiosInstanceCATALOGUE={axiosInstanceCATALOGUE}
        axiosInstanceCLB={axiosInstanceCLB}
        axiosInstanceDOCUMENT={axiosInstanceDOCUMENT}
        axiosInstanceTON={axiosInstanceTON}
        axiosInstanceDISCUSS={axiosInstanceDISCUSS}
        axiosInstanceAI={axiosInstanceAI}
        axiosInstanceCertification={axiosInstanceCERTIFICATION}
      >
        <LanguagePreferenceProvider languageCode={languageCode}>
          <BusinessLanguagePreferenceProvider languageCode={languageCode}>
            <IntlProvider
              messages={getTranslations(languageCode)}
              locale={languageCode}
            >
              <LocaleProvider languageCode={languageCode}>
                <AuthenticationProvider
                  configuration={getOidcConfiguration()}
                  callbackComponentOverride={LoadingPage}
                  authenticating={LoadingPage}
                >
                  <Router>
                    <Switch>
                      <Route
                        exact
                        path={ROUTES.SPECIFICATION_GENERATE_PDF}
                        component={Specification.Pdf}
                      />
                      <Route>
                        <OidcSecure>
                          <StarterWrapper />
                        </OidcSecure>
                      </Route>
                    </Switch>
                  </Router>
                </AuthenticationProvider>
              </LocaleProvider>
            </IntlProvider>
          </BusinessLanguagePreferenceProvider>
        </LanguagePreferenceProvider>
      </BusinessAxiosInstancesWrapper>
    </AxiosInstancesWrapper>
  );
};

export default App;
