import { Form, FormInstance } from "antd";
import { FormattedMessage, useIntl } from "react-intl";
import libraryMessages from "messages/library";
import { useSelector } from "react-redux";
import { messages } from "components/Library/components/ChapterForm/messages";
import {
  APPLICATION_TYPE_IDS,
  REFLIST_IDS,
  LIBRARY_OBJECTS_PAGES,
} from "utils/constants";
import StepTitle from "components/Library/components/StepTitle";
import { Tags } from "@trace-one/react-components";
import {
  isLibraryObjectCreatePage,
  isLibraryObjectLocked,
  isLibraryObjectPublished,
} from "utils/library";
import LibraryItemName from "components/Library/components/LibraryItemName";
import { selectLanguageCode } from "store/user/selectors";
import ReflistSelect from "components/ReflistSelect";
import { isFAndVOnly } from "utils/general";
import { selectRefListDictionary } from "store/generalData/selectors";
import { generalMessages, templateMessages } from "messages";
import { useTemplateForm } from "./hooks";
import ConfirmationModal from "components/ConfirmationModal";
import { selectTemplateGeneraInfo } from "store/templates/templateFormSlice";
import { TagsList } from "components";
import DeleteAndKeepDraftModal from "../DeleteAndKeepDraftModal";
import { LibraryPageType } from "types/library";

export interface TemplateFormGeneralInfoProps {
  page?: LibraryPageType;
  form: FormInstance;
}

export function TemplateFormGeneralInfo({
  page,
  form,
}: TemplateFormGeneralInfoProps) {
  const {
    state,
    id: templateId,
    productTypeId,
    tagIds,
    name,
    version,
  } = useSelector(selectTemplateGeneraInfo);
  const { formatMessage } = useIntl();
  const languageCode = useSelector(selectLanguageCode);
  const refListDictionary = useSelector(selectRefListDictionary);

  const {
    onNameChange,
    onProductSelect,
    onTagsChange,
    showProductChangeModal,
    onProductChangeCancel,
    onProductChangeConfirm,
    onDeleteTemplate,
    onKeepAsDraftTemplate,
  } = useTemplateForm({
    form,
  });

  const isSelectDisabled =
    isLibraryObjectPublished(state) ||
    isLibraryObjectLocked(state) ||
    page === LIBRARY_OBJECTS_PAGES.EDIT;

  return (
    <>
      <StepTitle
        title={formatMessage(templateMessages.createYourTemplate)}
        step={1}
      />
      <LibraryItemName
        onChange={onNameChange}
        defaultValue={name}
        libraryItemId={templateId}
        disableActions={
          isLibraryObjectPublished(state) ||
          isLibraryObjectLocked(state) ||
          version?.number > 1
        }
        disabledOnBlur={true}
        form={form}
      />

      <Form.Item
        required
        name="productTypeId"
        label={formatMessage(messages.productTypeLabel)}
        rules={[{ required: true }]}
      >
        <ReflistSelect
          refListId={REFLIST_IDS.PRODUCT_TYPES}
          allowClear={false}
          disabled={isSelectDisabled}
          name="productTypeId"
          data-testid="productTypeId"
          onSelect={onProductSelect}
          value={productTypeId}
          placeholder={formatMessage(messages.productTypePlaceholder)}
          shouldCallApi={true}
          refListDictionary={refListDictionary}
          filterRefListIds={isFAndVOnly ? [REFLIST_IDS.FOOD_PRODUCT_TYPE] : []}
        />
      </Form.Item>

      <Form.Item
        name="tagIds"
        label={formatMessage(libraryMessages.tagsLabel)}
        wrapperCol={{
          xs: 24,
          md: 20,
        }}
      >
        {isLibraryObjectLocked(state) ? (
          <TagsList tagIds={tagIds} />
        ) : (
          <Tags.Select
            languageCode={languageCode}
            applicationTypeId={APPLICATION_TYPE_IDS.SPEC}
            tagIds={tagIds || []}
            onTagListChange={onTagsChange}
          />
        )}
      </Form.Item>

      {showProductChangeModal && (
        <ConfirmationModal
          onConfirmModal={onProductChangeConfirm}
          onCloseModal={onProductChangeCancel}
          title={
            <FormattedMessage {...templateMessages.templateModification} />
          }
          children={
            <>
              <FormattedMessage
                {...templateMessages.templateModificationDescription}
              />
            </>
          }
          primaryButtonText={formatMessage(generalMessages.confirm)}
        />
      )}

      {isLibraryObjectCreatePage(page) && (
        <DeleteAndKeepDraftModal
          id={templateId}
          title={formatMessage(templateMessages.cancelTemplateCreationTitle)}
          description={formatMessage(
            templateMessages.cancelTemplateCreationDescription
          )}
          primaryButtonText={formatMessage(
            templateMessages.deleteTemplateButtonLabel
          )}
          onKeepAsDraftAction={onKeepAsDraftTemplate}
          onDeleteAction={onDeleteTemplate}
        />
      )}
    </>
  );
}
