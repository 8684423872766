import { useMemo, useState } from "react";
import { useIntl } from "react-intl";
import { useUserInfo } from "hooks";
import { getMasterDataSiteUrl, openUrlInNewWindow } from "utils/general";
import { messages } from "../../messages";
import { otherAppsMessages } from "messages";
import { UseSiteActionsProps } from "./types";

const useSiteActions = ({
  displayActions,
  hasUpdatePermission,
  formData,
  saveTemplateBlock,
}: UseSiteActionsProps) => {
  const { formatMessage } = useIntl();
  const [isSiteActionInProgress, setIsSiteActionInProgress] = useState(false);

  const isUpdateActionDisabled = useMemo(
    () => !hasUpdatePermission || isSiteActionInProgress,
    [isSiteActionInProgress, hasUpdatePermission]
  );

  const { hasMasterDataAccess } = useUserInfo();

  const handleAddSite = async (id: string) => {
    if (isSiteActionInProgress) {
      return null;
    }
    setIsSiteActionInProgress(true);

    const { siteIds = [], ...rest } = formData;
    try {
      if (
        !siteIds.some(item => {
          return item === id;
        })
      ) {
        const payload = {
          siteIds: [...siteIds, id],
          ...rest,
        };

        await saveTemplateBlock?.(JSON.stringify(payload));
      }
    } catch (_) {
    } finally {
      setIsSiteActionInProgress(false);
    }
  };

  const handleRemoveSite = (id: string) => async () => {
    if (isSiteActionInProgress) {
      return null;
    }
    setIsSiteActionInProgress(true);

    try {
      const { siteIds = [], ...rest } = formData;

      const payload = {
        siteIds: siteIds.filter(siteId => siteId !== id),
        ...rest,
      };

      await saveTemplateBlock?.(JSON.stringify(payload));
    } catch (_) {
    } finally {
      setIsSiteActionInProgress(false);
    }
  };

  const getSiteActions = site => {
    let siteActions = [];

    if (!isUpdateActionDisabled && displayActions) {
      siteActions.push({
        disabled: isSiteActionInProgress,
        onClick: handleRemoveSite(site.id),
        label: formatMessage(messages.removeTheSite),
      });
    }

    const onClickSeeInMD = () =>
      openUrlInNewWindow(getMasterDataSiteUrl(site.id));

    if (hasMasterDataAccess) {
      siteActions.push({
        label: formatMessage(otherAppsMessages.seeInMasterData),
        onClick: onClickSeeInMD,
      });
    }

    return siteActions;
  };

  return {
    handleAddSite,
    isUpdateActionDisabled,
    getSiteActions,
  };
};

export default useSiteActions;
