import React from "react";
import { Select } from "@trace-one/design-system";
import ConfirmationTooltip from "components/ConfirmationTooltip";
import ConditionalWrapper from "components/ConditionalWrapper";
import { useSelectItem } from "./hooks";
import { SelectItemProps } from "./types";

const SelectItem: React.FC<SelectItemProps> = ({
  label,
  selectedValue,
  options,
  isLoading,
  hasError,
  onChange,
  placeholder,
  disabled,
  allowClear,
  confirmationTooltipText = null,
  getPopupContainer,
  dropdownClassName,
}) => {
  const {
    confirmChange,
    cancelChange,
    onChangeHandler,
    value,
    confirmOpen,
  } = useSelectItem({
    defaultValue: selectedValue,
    onChange,
    displayConfirmationTooltip: !!confirmationTooltipText,
  });

  const renderWrappedComponent = children => (
    <ConfirmationTooltip
      open={confirmOpen}
      text={confirmationTooltipText}
      onConfirmation={confirmChange}
      onCancel={cancelChange}
    >
      {children}
    </ConfirmationTooltip>
  );

  return (
    <ConditionalWrapper
      condition={!!confirmationTooltipText}
      wrapper={renderWrappedComponent}
    >
      <Select
        loading={isLoading}
        error={hasError}
        options={options}
        value={value}
        onChange={onChangeHandler}
        placeholder={placeholder}
        disabled={disabled}
        allowClear={allowClear}
        {...(typeof getPopupContainer === "function" && { getPopupContainer })}
        {...(dropdownClassName && { dropdownClassName })}
        {...(label && {
          label,
        })}
      />
    </ConditionalWrapper>
  );
};

export default SelectItem;
