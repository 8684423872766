import React, { useEffect, useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { utils } from "@rjsf/core";
import { SelectValue } from "antd/lib/select";
import { CloseCircleFilled } from "@ant-design/icons";
import { Tooltip } from "@trace-one/design-system";
import classNames from "classnames";
import { selectLanguageCode } from "store/user/selectors";
import { fetchReferenceListItemByCode } from "apis/RLMD";
import { useFormSchemaTranslation } from "pages/Specification/components/Details/hooks";
import ConditionalWrapper from "components/ConditionalWrapper";
import ReflistRadio from "components/ReflistRadio";
import ReflistSelect from "components/ReflistSelect";
import RequestModificationTooltipIcon from "components/RequestModificationTooltipIcon";
import ExternalListSelect from "components/ReflistSelect/components/ExternalListSelect";
import { REQUEST_MODIFICATION_MODAL_WIDTH } from "components/RequestModificationPopover";
import { useWidgetActions } from "../../hooks";
import withRequestModificationPopover from "hocs/withRequestModificationPopover";
import { useAppDispatch } from "store";
import { setRequestModification } from "store/specificationDetails/specificationDetailsSlice";
import { isListEmpty, isObjectEmpty } from "utils/general";
import { getCurrentValueFromValue } from "utils/select";
import { isLibraryObjectTesting } from "utils/library";
import {
  canTargetField,
  doesFieldHaveModificationRequest,
  getDraftRequestFromAllRequests,
  getSelectClosestClassname,
  getSelectPopoverStyle,
} from "utils/modificationRequest";
import { getArrayIndexFromId } from "../utils";
import { RefListWidgetProps } from "./types";
import widgetStyle from "../styles.module.less";

const { getUiOptions } = utils;

const RefListWidget: React.FC<RefListWidgetProps> = ({
  placeholder,
  readonly = false,
  schema = {
    referenceList: { listId: null, getItemBy: null, parentItemIds: null },
    list: { api: null, getItemBy: null, route: null },
    propertyId: null,
  },
  value,
  onChange,
  options = { dropdown: true, displayTooltip: false },
  allowClear: defaultAllowClear = false,
  showSearch = true,
  onClear,
  onDeselect: defaultOnDeselect,
  labelInValue = false,
  formContext,
  uiSchema = {},
  id,
  rawErrors,
  onOpenModal,
  setModalStyle,
  setRequestPopoverOptions,
  setSelectedField,
}) => {
  const [textValue, setTextValue] = useState(null);
  const languageCode = useSelector(selectLanguageCode);

  const reflistWidgetWrapperRef = useRef<HTMLDivElement>(null);
  const radioGroupWrapperRef = useRef<HTMLDivElement>(null);

  const { getTranslationForKey } = useFormSchemaTranslation();

  const dispatch = useAppDispatch();

  const {
    referenceList: {
      listId = null,
      getItemBy = null,
      parentItemIds = null,
    } = {},
    list,
    propertyId,
  } = schema;

  const {
    displayActions,
    emptyValue,
    isWidgetEditable = () => {},
    templateBlockId,
    sectionId,
    shouldCallApi,
    refListDictionary,
    isTargetMode,
    chapterData,
    arraySchemaItemKey,
    arraySchemaPropertyId,
    modificationRequests,
    ownerCompanyId,
    formData,
  } = formContext;

  const { isWidgetChanged } = useWidgetActions({ templateBlockId, sectionId });

  const isReadOnly = !isWidgetEditable(propertyId) || readonly;
  const { dropdown = true, displayTooltip = false } = options;

  const uiOptions = getUiOptions(uiSchema);

  const isSearchable = (uiOptions.searchable as boolean) || showSearch;
  const allowClear =
    uiOptions.allowClear || uiOptions["allow:clear"] || defaultAllowClear;

  const isRadioGroupWidget = uiOptions.mode === "radioGroup";

  const index = getArrayIndexFromId(id?.toString());

  const listItemKey =
    (formData?.items?.[index]?.[arraySchemaItemKey] as string) || null;

  const hasDraftModificationRequest = useMemo(() => {
    return getDraftRequestFromAllRequests({
      modificationRequests,
      propertyId:
        arraySchemaItemKey && !isRadioGroupWidget
          ? arraySchemaPropertyId
          : propertyId,
      listItemKey,
    });
  }, [modificationRequests, propertyId, value]);

  const onClearIconClick = () => {
    if (typeof onClear !== "function") {
      onChange(null);
    } else {
      onClear();
    }
  };

  const onSelect = (value: SelectValue, option) => {
    onChange(value, option);
  };

  const onDeselect = (value: SelectValue) => {
    if (typeof defaultOnDeselect === "function") {
      defaultOnDeselect(value);
    }
  };

  const onClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();

    if (
      doesFieldHaveModificationRequest({
        modificationRequests,
        propertyId: arraySchemaItemKey ? arraySchemaPropertyId : propertyId,
        // @ts-ignore
        listItemKey: arraySchemaItemKey
          ? !(event.target as HTMLElement).innerHTML.includes(
              "ant-select-selection-overflow-item"
            )
            ? Object.entries(refListDictionary)?.reduce((acc, curr) => {
                const [key, value] = curr;
                if (value === (event.target as HTMLElement).innerText) {
                  return key;
                }

                return acc;
              }, null)
            : null
          : null,
      })
    ) {
      return;
    }

    const wrapper = event.target;
    const wrapperBoundingClientRect = reflistWidgetWrapperRef.current.getBoundingClientRect();
    const targetBoundingClientRect = (event.target as HTMLElement).getBoundingClientRect();

    if (wrapper) {
      (wrapper as HTMLElement)
        .closest(
          getSelectClosestClassname({
            wrapperBoundingClientRectWidth: wrapperBoundingClientRect?.width,
            targetBoundingClientRectWidth: targetBoundingClientRect?.width,
            isSimpleList: !arraySchemaItemKey,
          })
        )
        .classList.add("target-mode__border");
    }

    setModalStyle(
      getSelectPopoverStyle({
        wrapperBoundingClientRect,
        targetBoundingClientRect,
      })
    );

    setSelectedField({
      propertyId: arraySchemaItemKey ? arraySchemaPropertyId : propertyId,
      listItemKey: arraySchemaItemKey
        ? !(event.target as HTMLElement).innerHTML.includes(
            "ant-select-selection-overflow-item"
          )
          ? Object.entries(refListDictionary)?.reduce((acc, curr) => {
              const [key, value] = curr;
              if (value === (event.target as HTMLElement).innerText) {
                return key;
              }

              return acc;
            }, null)
          : null
        : null,
    });

    onOpenModal?.();
    dispatch(setRequestModification({ isEditorOpen: true }));
  };

  const onRadioGroupWrapperClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();

    if (
      doesFieldHaveModificationRequest({
        modificationRequests,
        listItemKey,
        propertyId,
      }) ||
      !canTargetField({
        isTargetMode,
        chapterOwnerCompanyId: chapterData?.ownerCompanyId,
        specOwnerCompanyId: ownerCompanyId,
      })
    ) {
      return;
    }

    (event.target as HTMLElement)
      .closest(".ant-radio-group")
      .classList.add("target-mode__border");

    const { left, top } = radioGroupWrapperRef.current?.getBoundingClientRect();

    const isOverFlowing =
      event.clientX + REQUEST_MODIFICATION_MODAL_WIDTH > window.innerWidth;

    setSelectedField({
      propertyId,
      listItemKey,
    });

    setModalStyle({
      left: isOverFlowing ? "unset" : event.clientX - left,
      right: isOverFlowing ? 0 : "unset",
      top: event.clientY - top,
    });

    onOpenModal?.();

    dispatch(setRequestModification({ isEditorOpen: true }));
  };

  useEffect(() => {
    const getItemByCode = async () => {
      try {
        const { data } = await fetchReferenceListItemByCode({
          languageCode,
          id: listId,
          code: getCurrentValueFromValue(value),
        });

        setTextValue(data.text);
      } catch (_) {}
    };

    if ((listId && getItemBy && !dropdown) || !displayActions) {
      if (value && getItemBy === "ItemCode") {
        getItemByCode();
      }
    }
  }, [listId, getItemBy, value, languageCode, dropdown]);

  useEffect(() => {
    if (shouldCallApi === false && !isObjectEmpty(refListDictionary)) {
      if (Array.isArray(value)) {
        setTextValue(
          value
            .map(currentValue => {
              return refListDictionary[currentValue?.value || currentValue];
            })
            .join(", ")
        );
      } else {
        if (refListDictionary[value as string]) {
          setTextValue(refListDictionary[value as string]);
        }
      }
    }
  }, [refListDictionary, shouldCallApi]);

  useEffect(() => {
    if (value === null) {
      setTextValue(null);
    }
  }, [value]);

  const renderPopoverIcon = (elementRef: any) => {
    const request = getDraftRequestFromAllRequests({
      modificationRequests,
      propertyId:
        arraySchemaItemKey && !isRadioGroupWidget
          ? arraySchemaPropertyId
          : propertyId,
      listItemKey,
    });

    if (request) {
      return (
        <RequestModificationTooltipIcon
          key={`${propertyId}`}
          onOpenModal={onOpenModal}
          setModalStyle={setModalStyle}
          tableRef={elementRef}
          setRequestPopoverOptions={setRequestPopoverOptions}
          request={request}
        />
      );
    }
  };

  const tagRender = props => {
    const { value, label } = props;

    const request = getDraftRequestFromAllRequests({
      modificationRequests,
      listItemKey: value,
      propertyId: arraySchemaPropertyId,
    });

    return (
      <div
        data-field-id={`${value}-${arraySchemaPropertyId}`}
        className={classNames(
          "ant-select-selection-item",
          "target-mode__multiselect-tag",
          {
            "target-mode__background": !!request,
            "target-mode__border-color": !!request,
          }
        )}
      >
        <span>
          {label}
          {request && (
            <RequestModificationTooltipIcon
              key={`${value}-${propertyId}`}
              onOpenModal={onOpenModal}
              setModalStyle={setModalStyle}
              tableRef={reflistWidgetWrapperRef}
              setRequestPopoverOptions={setRequestPopoverOptions}
              request={request}
            />
          )}
        </span>
      </div>
    );
  };

  const fieldId = `${listItemKey ? `${listItemKey}-` : ""}${propertyId}`;

  if (!displayActions || (isReadOnly && !isTargetMode)) {
    if (Array.isArray(value)) {
      return arraySchemaItemKey ? (
        <span data-field-id={`${arraySchemaPropertyId}`}>
          {value?.map((item, index) => (
            <>
              <span
                data-field-id={`${
                  item?.value || item
                }-${arraySchemaPropertyId}`}
              >
                {refListDictionary[item?.value || item]}
              </span>
              {index === value.length - 1 ? "" : ", "}
            </>
          ))}
        </span>
      ) : (
        <span data-field-id={`${propertyId}`}>{textValue}</span>
      );
    }

    if (textValue) {
      return <span data-field-id={fieldId}>{textValue}</span>;
    }

    if (emptyValue) {
      return <span data-field-id={fieldId}>{emptyValue}</span>;
    }

    return <></>;
  }

  if (isRadioGroupWidget) {
    return (
      <div
        ref={radioGroupWrapperRef}
        className={classNames({
          [widgetStyle.inRevisionChange]: isWidgetChanged(propertyId, id),
          [widgetStyle["target-mode__input-pointer-event"]]: canTargetField({
            isTargetMode,
            specOwnerCompanyId: ownerCompanyId,
            chapterOwnerCompanyId: chapterData?.ownerCompanyId,
          }),
          "target-mode": canTargetField({
            isTargetMode,
            specOwnerCompanyId: ownerCompanyId,
            chapterOwnerCompanyId: chapterData?.ownerCompanyId,
          }),
          "target-mode__background":
            isTargetMode && hasDraftModificationRequest,
          "target-mode__border-color":
            isTargetMode && hasDraftModificationRequest,
          "target-mode__border-width":
            isTargetMode && hasDraftModificationRequest,
        })}
        onClick={onRadioGroupWrapperClick}
      >
        <ReflistRadio
          refListId={listId}
          value={value}
          data-test-id="ref-list-radio-group"
          display="horizontal"
          onChange={e => onChange(e.target.value)}
          disabled={isReadOnly}
        />
        {isTargetMode ? renderPopoverIcon(radioGroupWrapperRef) : null}
      </div>
    );
  }

  if (dropdown) {
    if (!isObjectEmpty(list)) {
      return (
        <ExternalListSelect
          list={list}
          placeholder={getTranslationForKey(
            placeholder || "refListPlaceholder"
          )}
          allowClear={allowClear && !!value}
          {...(uiSchema?.["ui:multipleMode"] && {
            mode: "multiple",
          })}
          showSearch={isSearchable}
          defaultValue={value}
          value={value === undefined ? null : value}
          labelInValue={labelInValue}
          onSelect={onSelect}
          onDeselect={onDeselect}
          disabled={isReadOnly}
        />
      );
    }

    return (
      <ConditionalWrapper
        condition={isTargetMode}
        wrapper={children => (
          <div ref={reflistWidgetWrapperRef}>{children}</div>
        )}
      >
        <>
          <ReflistSelect
            refListId={listId}
            parentItemIds={parentItemIds}
            allowClear={allowClear && !!value}
            {...(uiSchema?.["ui:multipleMode"] && {
              mode: "multiple",
            })}
            className={classNames({
              "target-mode":
                canTargetField({
                  isTargetMode,
                  specOwnerCompanyId: ownerCompanyId,
                  chapterOwnerCompanyId: chapterData?.ownerCompanyId,
                }) && !isLibraryObjectTesting(chapterData?.chapterType),
              "target-mode__background": hasDraftModificationRequest,
              "target-mode__border-color": hasDraftModificationRequest,
              [widgetStyle.inRevisionChange]: isWidgetChanged(propertyId, id),
              [widgetStyle["target-mode__input-pointer-event"]]:
                isTargetMode && !value && !uiSchema?.["ui:multipleMode"],
            })}
            showSearch={isSearchable}
            disabled={isReadOnly}
            name="reflistSelect"
            data-testid="reflist"
            placeholder={getTranslationForKey(
              placeholder || "refListPlaceholder"
            )}
            defaultValue={value}
            value={value === undefined ? null : value}
            // @ts-ignore
            clearIcon={<CloseCircleFilled onClick={onClearIconClick} />}
            onSelect={onSelect}
            onDeselect={onDeselect}
            {...(!isLibraryObjectTesting(chapterData?.chapterType) &&
              canTargetField({
                isTargetMode,
                specOwnerCompanyId: ownerCompanyId,
                chapterOwnerCompanyId: chapterData?.ownerCompanyId,
              }) && {
                onClick,
              })}
            {...(!isLibraryObjectTesting(chapterData?.chapterType) &&
              isTargetMode &&
              arraySchemaItemKey && {
                tagRender,
              })}
            labelInValue={labelInValue}
            shouldCallApi={shouldCallApi}
            refListDictionary={refListDictionary}
            error={!isListEmpty(rawErrors)}
          />

          {isTargetMode ? renderPopoverIcon(reflistWidgetWrapperRef) : null}
        </>
      </ConditionalWrapper>
    );
  }

  if (displayTooltip && textValue) {
    return (
      <Tooltip text={textValue} placement="top">
        {textValue}
      </Tooltip>
    );
  }

  return <>{textValue}</>;
};

export default withRequestModificationPopover(RefListWidget);
