import React from "react";
import { Col, Modal, Row } from "antd";
import { Button } from "@trace-one/design-system";
import StepTitle from "components/StepTitle";
import Library from "components/Library";
import ModalCloseIcon from "components/ModalCloseIcon";
import { useSelectedLibraryObject } from "hooks";
import { isListEmpty } from "utils/general";
import { MultipleSelectLibraryObjectModalProps } from "./types";
import styles from "./styles.module.less";

const MultipleSelectLibraryObjectModal: React.FC<MultipleSelectLibraryObjectModalProps> = ({
  title,
  subTitle,
  type,
  confirmBtnText,
  disabledLibraryIds,
  onClose,
  onConfirm,
  excludeDrafts,
  historyId,
  excludedLibraryItemIds,
  primaryButtonLoading,
}) => {
  const {
    selectedLibraryObjects: currentSelectedObjects,
    onSelectLibraryObject,
  } = useSelectedLibraryObject({});

  return (
    <Modal
      visible
      centered
      className={styles.modal}
      closeIcon={<ModalCloseIcon />}
      width="90%"
      // @ts-ignore
      height="90%"
      footer={null}
      onCancel={onClose}
    >
      <Row className={styles.modalContainer}>
        <Col xs={24} className={styles.wrapper}>
          <StepTitle title={title} subtitle={subTitle} />
          <Library.List
            onItemClick={onSelectLibraryObject}
            displayActions={false}
            hasCreateButton={false}
            hasSwitchView={false}
            type={type}
            selectedLibraryItems={currentSelectedObjects}
            disabledLibraryItems={disabledLibraryIds}
            excludeDrafts={excludeDrafts}
            historyId={historyId}
            excludedLibraryItemIds={excludedLibraryItemIds}
          />
          <Row justify="end" className={styles.validateRow}>
            <Button
              data-test-id="addToTableButtonId"
              onClick={() => onConfirm(currentSelectedObjects)}
              disabled={isListEmpty(currentSelectedObjects)}
              loading={primaryButtonLoading}
            >
              {confirmBtnText}
            </Button>
          </Row>
        </Col>
      </Row>
    </Modal>
  );
};

MultipleSelectLibraryObjectModal.defaultProps = {
  selectedLibraryObjects: [],
  disabledLibraryIds: [],
  excludeDrafts: true,
};

export default MultipleSelectLibraryObjectModal;
