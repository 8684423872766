import { defineMessages } from "react-intl";

export const messages = defineMessages({
  packagingComponentActionsEdit: {
    id: "packaging.component.actions.edit",
    defaultMessage: "Edit",
  },
  packagingComponentActionsDelete: {
    id: "packaging.component.actions.delete",
    defaultMessage: "Delete",
  },
  addMaterial: {
    id: "packaging.component.addMaterial",
    defaultMessage: "Add a material",
  },
  editMaterial: {
    id: "packaging.component.editMaterial",
    defaultMessage: "Edit a material",
  },
  confirm: {
    id: "packaging.component.addMaterial.confirm",
    defaultMessage: "Confirm",
  },
  modify: {
    id: "packaging.component.editMaterial.modify",
    defaultMessage: "Modify",
  },
  cancel: {
    id: "packaging.component.addMaterial.cancel",
    defaultMessage: "Cancel",
  },
  noMaterials: {
    id: "packaging.component.noMaterials",
    defaultMessage: "No materials",
  },
  packagingComponentNameLabel: {
    id: "packaging.component.nameLabel",
    defaultMessage: "Name",
  },
  packagingComponentUsageLevelLabel: {
    id: "packaging.component.usageLevelLabel",
    defaultMessage: "Usage level",
  },
  selectMaterial: {
    id: "packaging.component.materialForm.selectMaterial",
    defaultMessage: "Select a material",
  },
  primary: {
    id: "specificationDetails.widgets.packagingSystem.usageLevelPrimary",
    defaultMessage: "Primary",
  },
  secondary: {
    id: "specificationDetails.widgets.packagingSystem.usageLevelSecondary",
    defaultMessage: "Secondary",
  },
  tertiary: {
    id: "specificationDetails.widgets.packagingSystem.usageLevelTertiary",
    defaultMessage: "Tertiary",
  },
  generalInformationTitle: {
    id: "library.packagingSystemForm.tabs.generalInformationTitle",
    defaultMessage: "General information",
  },
  gtin: {
    id: "packaging.component.gtin",
    defaultMessage: "GTIN",
  },
  setAsMainComponent: {
    id: "packaging.component.action.setAsMainComponent",
    defaultMessage: "Set as main component",
  },
  unsetAsMainComponent: {
    id: "packaging.component.action.unsetAsMainComponent",
    defaultMessage: "Unset as main component",
  },
  linksDetails: {
    id: "packaging.component.action.linksDetails",
    defaultMessage: "Links details",
  },
  main: {
    id: "packaging.component.main",
    defaultMessage: "Main",
  },
  createAndAssociate: {
    id: "packaging.createAndAssociate",
    defaultMessage: "Create & associate",
  },
  restrictionSummaryTabDescription: {
    id: "packaging.restrictionSummaryTabDescription",
    defaultMessage:
      "You find in this tab, all substances defined by regulations such as : Substances subject to restriction, in accordance with regulation n°10/2011 on plastic materials and articles intended to come into contact with food; Dangerous substances, in accordance with article 13 of the AGEC law: SVHC (substances of very high concern) and Substances of comparable concern to SVHCs, Endocrine disruptors, in accordance with article 13 of the AGEC law - Under Article 13 of the AGEC Act, dangerous substances and endocrine disruptors must be notified to consumers if their concentration exceeds 0.1% by mass.",
  },
  selectAComponent: {
    id: "packaging.selectAComponent",
    defaultMessage: "Select a component",
  },
});

export const linksDetailsModalMessages = defineMessages({
  linksDetailsModalSubtitle: {
    id: "packaging.linksDetails.modal.subtitle",
    defaultMessage:
      "Manage all the links between components in this packaging system",
  },
  addALink: {
    id: "packaging.linksDetails.modal.addALink",
    defaultMessage: "Add a link",
  },
  unlinkAll: {
    id: "packaging.linksDetails.modal.unlinkAll",
    defaultMessage: "Unlink all",
  },
  unlinkAllConfirmation: {
    id: "packaging.linksDetails.modal.unlinkAll.confirmationText",
    defaultMessage: "Do you want to unlink all items?",
  },
  emptyState: {
    id: "packaging.linksDetails.modal.emptyState",
    defaultMessage: "No linked component",
  },
  changeComponentConfirmation: {
    id: "packaging.linksDetails.modal.changeComponent.confirmationText",
    defaultMessage:
      "You have unsaved changes, do you want to continue and loose your changes?",
  },
});

export const packagingComponentTabMessages = defineMessages({
  title: {
    id: "packaging.component.tab.title",
    defaultMessage: "Define packaging system components",
  },
  subtitle: {
    id: "packaging.component.tab.subtitle",
    defaultMessage: "Select and edit",
  },
});

export const packagingComponentTableMessages = defineMessages({
  componentName: {
    id: "packaging.component.table.name",
    defaultMessage: "Component name",
  },
  componentType: {
    id: "packaging.component.table.type",
    defaultMessage: "Component type",
  },
  totalPercentageOfRecyclability: {
    id: "packaging.component.table.totalPercentageOfRecyclability",
    defaultMessage: "Total % of recycled material",
  },
});
