import { useIntl } from "react-intl";
import { Datatable } from "@trace-one/design-system";
import TableHeader from "components/TableHeader";
import {
  LibraryItemQuickView,
  LibraryItemInfo,
} from "components/Library/components/";
import LinkedSpecificationsModal from "components/LinkedSpecificationsModal";
import { useLibraryItemVersionHistory } from "hooks";
import { useClaimVersionHistoryActions } from "../../hooks";
import { useVersionHistoryColumns } from "components/Library/hooks";
import { isStateDraft } from "utils/specifications";
import { isListEmpty } from "utils/general";
import { LibraryItemApiType } from "types/library";
import { ClaimData, LibraryItemVersionData } from "models";
import { generalMessages, libraryMessages } from "messages";

export type ClaimVersionHistoryProps = {
  claimId: ClaimData["id"];
  claimType: LibraryItemApiType;
  linkedSpecificationsCallback?: () => Promise<void>;
};

const ClaimVersionHistory: React.FC<ClaimVersionHistoryProps> = ({
  claimId,
  claimType,
  linkedSpecificationsCallback,
}) => {
  const { formatMessage } = useIntl();

  const {
    versionHistoryList = [],
    loadingVersions,
    total,
    getVersionHistoryList,
    activeLibraryItemId,
  } = useLibraryItemVersionHistory({
    libraryItemId: claimId,
    libraryItemType: claimType,
  });

  const {
    isModalOpen,
    handleOpenModal,
    handleCloseModal,
    title,
    subtitle,
    libraryItemId,
    selectedLibraryItemId,
    libraryItemState,
    versionNumber,
    states,
    actionType,
    primaryButtonLabel,
    secondaryButtonLabel,
    onPrimaryButtonClick,
    rowSelection,
    shouldRefetchVersionHistory,
  } = useClaimVersionHistoryActions({
    activeClaimId: activeLibraryItemId,
    versionHistoryCallback: getVersionHistoryList,
    linkedSpecificationsCallback,
  });

  const { columns } = useVersionHistoryColumns({
    displayStatus: true,
    displayActions: true,
    libraryItemType: claimType,
    handleOpenModal,
  });

  const expandedRowRender = ({ id }: LibraryItemVersionData) => (
    <LibraryItemQuickView
      libraryItemId={id}
      libraryItemType={claimType}
      key={id}
      shouldRefetch={shouldRefetchVersionHistory}
    />
  );

  const rowExpandable = ({ state }: LibraryItemVersionData) =>
    !isStateDraft(state);

  return (
    <>
      <div className="mb-3">
        <LibraryItemInfo />
      </div>

      <TableHeader
        listResult={formatMessage(generalMessages.listingResultNumber, {
          current: total,
          total: total,
          libraryObjectType: formatMessage(
            libraryMessages.claimsCountLabel
          ).toLocaleLowerCase(),
        })}
      />

      <Datatable
        loading={loadingVersions}
        columns={columns}
        dataSource={versionHistoryList}
        rowKey={({ id }: LibraryItemVersionData) => id}
        pagination={false}
        rowClassName="cursor-pointer"
        expandable={{
          expandedRowRender,
          rowExpandable,
        }}
      />

      {isModalOpen && !isListEmpty(states) && (
        <LinkedSpecificationsModal
          title={title}
          subtitle={subtitle}
          onCloseModal={handleCloseModal}
          libraryItemId={libraryItemId}
          selectedLibraryItemId={selectedLibraryItemId}
          libraryItemType={claimType}
          libraryItemState={libraryItemState}
          versionNumber={versionNumber}
          rowSelection={rowSelection}
          states={states}
          actionType={actionType}
          primaryButtonLabel={primaryButtonLabel}
          secondaryButtonLabel={secondaryButtonLabel}
          onPrimaryButtonClick={onPrimaryButtonClick}
          onSecondaryButtonClick={handleCloseModal}
        />
      )}
    </>
  );
};

export default ClaimVersionHistory;
