import { useMemo } from "react";
import { useIntl } from "react-intl";
import Debounced from "components/Debounced";
import Panel from "components/Panel";
import PercentageInput from "components/PercentageInput";
import ClassificationDropdown from "components/Library/components/MaterialForm/components/ClassificationDropdown";
import PanelField from "../PanelField";
import RecipePanelActions from "./components/RecipePanelActions";
import { MAX_INPUT_TEXT_LENGTH } from "utils/constants";
import { isMaterialCompositeType } from "utils/library";
import { useRecipePanelActions } from "./hooks";
import { RecipePanelProps } from "./types";
import libraryMessages from "messages/library";
import styles from "./styles.module.less";

const RecipePanel: React.FC<RecipePanelProps> = ({
  onPanelClose,
  initialIngredient,
  onAddExpandedRowKey,
  catalogDictionary,
}) => {
  const { formatMessage } = useIntl();
  const {
    onSetIngredientName,
    onSetClassification,
    onSetPercentage,
    onSaveIngredient,
    onConvertToComposite,
    onAddIngredient,
    onClose,
    isSaveDisabled,
    isConvertToCompositeDisabled,
    ingredient,
  } = useRecipePanelActions({
    onPanelClose,
    initialIngredient,
    onAddExpandedRowKey,
  });

  const panelTitleKey = ingredient.ingredientId
    ? "recipePanelTitleEdit"
    : "recipePanelTitleCreate";

  const isIngredientComposite = useMemo(
    () => isMaterialCompositeType(ingredient.type),
    [ingredient.type]
  );

  return (
    <Panel
      title={formatMessage(libraryMessages[panelTitleKey])}
      onClose={onClose}
    >
      <>
        <PanelField
          isMandatory
          title={formatMessage(libraryMessages["columns"]["name"])}
          rightComponent={
            <Debounced.Input
              maxLength={MAX_INPUT_TEXT_LENGTH}
              defaultValue={ingredient.name}
              onChange={onSetIngredientName}
              data-test-id="recipe-panel-ingredient-name"
              placeholder={formatMessage(
                libraryMessages.ingredientNamePlaceholder
              )}
            />
          }
        />
        <PanelField
          isMandatory
          title={formatMessage(libraryMessages["columns"]["type"])}
          rightComponent={
            <span data-test-id="recipe-panel-ingredient-type">
              {formatMessage(libraryMessages["type"][ingredient.type])}
            </span>
          }
        />
        <PanelField
          isMandatory
          title={formatMessage(libraryMessages["columns"]["classification"])}
          rightComponent={
            <ClassificationDropdown
              value={{
                value: ingredient?.classificationId,
                label: catalogDictionary?.[ingredient?.classificationId],
              }}
              onFullChange={onSetClassification}
              disabled={isIngredientComposite}
            />
          }
        />
        <PanelField
          isMandatory
          title={formatMessage(libraryMessages.percentage)}
          rightComponent={
            <PercentageInput
              defaultValue={ingredient.percentage}
              onChange={onSetPercentage}
              data-test-id="recipe-panel-ingredient-percentage"
              className={styles.input}
              precision={3}
            />
          }
        />
        <RecipePanelActions
          isConvertToCompositeDisabled={isConvertToCompositeDisabled}
          isSaveDisabled={isSaveDisabled}
          onAddIngredient={onAddIngredient}
          onConvertToComposite={onConvertToComposite}
          onSaveIngredient={onSaveIngredient}
          ingredient={ingredient}
        />
      </>
    </Panel>
  );
};
export default RecipePanel;
