import { useEffect, useMemo, useState } from "react";
import { fetchClaimNutrients } from "apis/SPEC";
import useCatalogDictionary from "hooks/useCatalogDictionary";
import useBoolean from "hooks/useBoolean";
import { isListEmpty, isObjectEmpty } from "utils/general";
import { ClaimNutrientData } from "models";
import { paramsSerializer } from "components/Library/components/List/hooks/useLibraryList/utils";
import { ClaimType } from "types/library";

export type UseClaimNutrientsProps = {
  fetchOnEffect?: boolean;
  claimTypes: ClaimType[];
};

const useClaimNutrients = ({
  fetchOnEffect = true,
  claimTypes = [],
}: UseClaimNutrientsProps) => {
  const [claimNutrients, setClaimNutrients] = useState<ClaimNutrientData>(null);
  const { catalogDictionary } = useCatalogDictionary({
    nutrientIds: claimNutrients?.itemIds || [],
  });
  const { value: loadingClaimNutrients, setTrue, setFalse } = useBoolean();

  useEffect(() => {
    if (!fetchOnEffect) return;

    const getClaimNutrients = async () => {
      try {
        setTrue();

        const { data } = await fetchClaimNutrients({
          params: { claimTypes },
          paramsSerializer,
        });

        setClaimNutrients(data);
      } catch {
      } finally {
        setFalse();
      }
    };

    getClaimNutrients();
  }, [fetchOnEffect, JSON.stringify(claimTypes)]);

  const claimNutrientOptions = useMemo(() => {
    if (isObjectEmpty(claimNutrients) || isListEmpty(claimNutrients?.itemIds)) {
      return [];
    }

    return claimNutrients.itemIds.map(nutrientId => ({
      label: catalogDictionary[nutrientId],
      value: nutrientId,
      key: nutrientId,
    }));
  }, [claimNutrients, catalogDictionary]);

  return { loadingClaimNutrients, claimNutrients, claimNutrientOptions };
};

export default useClaimNutrients;
