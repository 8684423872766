import { ErrorSchema } from "@rjsf/core";
import {
  ArraySchema,
  FormContext,
  ObjectSchema,
  Property,
} from "components/SpecificationSection/types";
import { UiSchemaInfo } from "types/general";
import { ClaimFormItemData, ClaimFormData } from "types/specification";
import { ClaimType } from "types/library";
import {
  claimTypeMap,
  isClaimTypeHealth,
  isClaimTypeMarketing,
  isClaimTypeNutritional,
} from "utils/claim";
import { ClaimData } from "models";

export type GetClaimDataByIdAndTypeParams = {
  formData: ClaimFormData;
  claimType: ClaimType;
  id: ClaimFormItemData["claimId"];
};

export const getFormattedRjsfObjects = ({
  schema,
  uiSchema,
  formData,
}: {
  schema: ObjectSchema;
  uiSchema: UiSchemaInfo;
  formData?: FormContext["formData"];
}): {
  schema: ObjectSchema;
  uiSchema: UiSchemaInfo;
  formData?: ClaimFormData;
} => {
  const formattedSchema: ObjectSchema = { ...schema, properties: {} };
  const formattedUiSchema: UiSchemaInfo = { ...uiSchema };
  const formattedFormData: FormContext["formData"] = { ...formData };

  Object.keys(schema?.properties).forEach(propertyKey => {
    const property = schema?.properties?.[propertyKey] as ArraySchema;
    const itemKey = property.itemKey;

    if (
      itemKey &&
      (property.items?.properties as Property)?.[itemKey]?.claimType
    ) {
      const claimType = (property.items?.properties as Property)[itemKey]
        .claimType;
      const claimKey = claimTypeMap[claimType] || propertyKey;

      formattedSchema.properties[claimKey] = { ...property };

      if (claimKey !== propertyKey) {
        formattedFormData[claimKey] = formData?.[propertyKey];
        formattedUiSchema[claimKey] = uiSchema?.[propertyKey];

        // Remove the original propertyKey from uiSchema and formData
        delete formattedUiSchema[propertyKey];
        delete formattedFormData[propertyKey];
      }
    } else {
      formattedSchema.properties[propertyKey] = { ...property };
      formattedUiSchema[propertyKey] = uiSchema?.[propertyKey];
      formattedFormData[propertyKey] = formData?.[propertyKey];
    }
  });

  return {
    schema: formattedSchema,
    uiSchema: formattedUiSchema,
    formData: formattedFormData as ClaimFormData,
  };
};

export const getClaimIdsWithError = ({
  claims,
  errors,
}: {
  claims: ClaimFormItemData[];
  errors?: ErrorSchema;
}) =>
  Object.keys(errors ?? {})
    .map(Number)
    .map(index => claims?.[index]?.claimId)
    .filter(Boolean);

export const getClaimDataByIdAndType = ({
  formData,
  claimType,
  id,
}: GetClaimDataByIdAndTypeParams) => {
  if (isClaimTypeMarketing(claimType)) {
    return formData?.marketingClaims?.find(({ claimId }) => claimId === id);
  }

  if (isClaimTypeHealth(claimType)) {
    return formData?.healthClaims?.find(({ claimId }) => claimId === id);
  }

  return formData?.nutritionalClaims?.find(({ claimId }) => claimId === id);
};

export const getClaimCardTitle = ({
  claimType,
  name,
  claimSentence,
}: {
  claimType: ClaimType;
  name: ClaimData["name"];
  claimSentence: string;
}) => {
  if (isClaimTypeNutritional(claimType)) {
    return claimSentence;
  }

  return name;
};
