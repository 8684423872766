import { useEffect, useState } from "react";
import { useQuery } from "hooks";
import Packaging from "components/Packaging";
import { isObjectEmpty } from "utils/general";
import { PACKAGING_COMPONENT_TAB_KEYS } from "utils/constants";

const usePackagingComponentNavigation = ({ sections, allowsUpdate }) => {
  const query = useQuery();
  const tab = query.get("componentTab");

  const [activeTab, setActiveTab] = useState(
    PACKAGING_COMPONENT_TAB_KEYS.BASIC_INFORMATION
  );
  const [tabContent, setTabContent] = useState(null);

  const tabCodes = sections?.map(section => section.sectionCode);

  const getSectionsByCode = (code: string) =>
    sections.filter(section => section.sectionCode === code);

  useEffect(() => {
    if (tab) {
      setActiveTab(tab as PACKAGING_COMPONENT_TAB_KEYS);
    }
  }, [tab]);

  const tabData = tabCodes?.reduce((acc, curr) => {
    const sections = getSectionsByCode(curr);

    const tabObj = {
      title: sections[0].sectionName,
      sections,
    };

    acc = { ...acc, [curr]: tabObj };

    return acc;
  }, {});

  let tabItems, componentMap;
  if (!isObjectEmpty(tabData)) {
    componentMap = {
      [PACKAGING_COMPONENT_TAB_KEYS.BASIC_INFORMATION]: (
        <Packaging.BasicInformation
          sections={
            tabData[PACKAGING_COMPONENT_TAB_KEYS.BASIC_INFORMATION]?.sections
          }
          isEditable={allowsUpdate}
        />
      ),
      [PACKAGING_COMPONENT_TAB_KEYS.RECYCLABILITY]: (
        <Packaging.Recyclability
          sections={
            tabData[PACKAGING_COMPONENT_TAB_KEYS.RECYCLABILITY]?.sections
          }
          isEditable={allowsUpdate}
        />
      ),
    };

    tabItems = [
      {
        key: PACKAGING_COMPONENT_TAB_KEYS.BASIC_INFORMATION,
        label: tabData[PACKAGING_COMPONENT_TAB_KEYS.BASIC_INFORMATION]?.title,
      },
      {
        key: PACKAGING_COMPONENT_TAB_KEYS.RECYCLABILITY,
        label: tabData[PACKAGING_COMPONENT_TAB_KEYS.RECYCLABILITY]?.title,
      },
    ];
  }

  useEffect(() => {
    if (activeTab && !isObjectEmpty(componentMap)) {
      setTabContent(componentMap[activeTab]);
    }
  }, [activeTab, sections]);

  return {
    activeTab,
    setActiveTab,
    tabItems,
    tabContent,
  };
};

export default usePackagingComponentNavigation;
