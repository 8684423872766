import React, { useEffect, useState } from "react";
import { CertificateFormProps } from "./types";
import { getPrefixClassName } from "src/utils/general";
import { Form } from "antd";
import { UploadFiles } from "IndexComponents";
import { Checkbox, Input, DatePicker, TextArea } from "@trace-one/design-system";
import { useIntl } from "react-intl";
import { certificateModalMessages } from "../../translations/messages";
import { FILE_EXTENSION } from "../../constants";
import moment from "moment";
const CertificateForm: React.FC<CertificateFormProps> = ({ useAI, value, onChange, setButtonDisabled, formData, setFormData, isAIUsed, handleAIChange, extractedFileDataFromAI, isModalClosing, setIsModalClosing }) => {
  const intl = useIntl();
  const [form] = Form.useForm();
  const prefixClassName = getPrefixClassName("certificate-modal");

  const { filesToAdd = [], attachments = [] } = formData || {};

  const [fileList, setFileList] = useState(() => [
    ...filesToAdd,
    ...attachments.map((file) => ({
      id: file?.fileId,
      name: file?.fileName,
    })),
  ]);

  const handleFileUpload = (files) => {
    if (files.length > 0) {
      const uploadedFile = files[0];

      const id = uploadedFile?.id || uploadedFile?.fileId;
      const fileName = uploadedFile?.name || uploadedFile?.fileName;

      if (!fileName) {
        return;
      }

      const fileExt = fileName?.includes(".") ? fileName?.split(".").pop() : null;
      const fileNameWithoutExt = fileName?.split(".").slice(0, -1).join(".");

      if (!fileExt) {
        return;
      }

      setFormData({
        ...formData,
        fileId: id,
        fileExtension: fileExt,
      });

      setFileList((prevFileList) => [
        ...prevFileList,
        { id, name: fileNameWithoutExt },
      ]);
    }
  };

  const isPdfOrJpgFile = () => {
    return formData?.fileExtension === FILE_EXTENSION.PDF || formData?.fileExtension === FILE_EXTENSION.JPG;
  };
  const handleValidFromChange = (date) => {
    const validFromISO = date ? date.toISOString() : null;
    setFormData({
      ...formData,
      validFrom: validFromISO,
    });
  };

  const handleValidToChange = (date) => {
    const validToISO = date ? date.toISOString() : null;
    setFormData({
      ...formData,
      validTo: validToISO,
    });
  };

  useEffect(() => {
    if ((fileList?.length !== 0) && (formData.validTo !== undefined || null)) {
      setButtonDisabled(false);
    } else {
      setButtonDisabled(true);
    }
  }, [fileList, formData.validTo]);
  const handleGradeChange = (e) => {
    const grade = e.target.value;
    setFormData({
      ...formData,
      grade,
    });
  };

  const handleCommentsChange = (e) => {
    const comments = e.target.value;
    setFormData({
      ...formData,
      comments,
    });
  };

  useEffect(() => {
    if (isAIUsed && extractedFileDataFromAI) {
      const { grade, validFrom, validTo, comment } = extractedFileDataFromAI;

      setFormData({
        ...formData,
        grade,
        validFrom,
        validTo,
        comments: comment,
      });

      form.setFieldsValue({
        grade,
        validFrom: moment(validFrom),
        validTo: moment(validTo),
        comments: comment,
      });
    }
  }, [isAIUsed, extractedFileDataFromAI, form, setFormData]);

  useEffect(() => {
    if (isModalClosing) {
      form.resetFields();
      setFileList([]);
      setIsModalClosing(false);
    }
  }, [isModalClosing, form, setIsModalClosing]);

  return (
      <div className={`${prefixClassName}-certificate-form`} data-test-id={`${prefixClassName}-certificate-form`}>
          <Form form={form}>
          <div
              className={`${prefixClassName}-certificate-form-item`}
              data-test-id={`${prefixClassName}-certificate-form-item-certificate-addition`}
          >
              <Form.Item
                label={intl.formatMessage(certificateModalMessages.certificateAddition)}
                required
                help={null}
                labelCol={{ span: 24 }}
              >
                  <UploadFiles.Dragger
                    size={1}
                    multiple={false}
                    dmsControl={false}
                    draggerText={intl.formatMessage(certificateModalMessages.uploadFilePlaceholder)}
                    uploadText={intl.formatMessage(certificateModalMessages.uploadFileBtn)}
                    data-test-id={`${prefixClassName}-certificate-form-item-certificate-import`}
                    fileList={fileList}
                    fileType="proof-of-certification"
                    onUploadFiles={handleFileUpload}
                    onRemove={(removedFile) => {
                      const isFileInFilesToAdd = filesToAdd?.some(
                        (file) => file?.id === removedFile?.id
                      );

                      if (isFileInFilesToAdd) {
                        const updatedFilesToAdd = filesToAdd?.filter(
                          (file) => file?.id !== removedFile?.id
                        );
                        setFormData({
                          ...formData,
                          filesToAdd: updatedFilesToAdd,
                        });
                      } else {
                        const updatedAttachments = attachments?.filter(
                          (file) => file?.fileId !== removedFile?.id
                        );
                        setFormData({
                          ...formData,
                          attachments: updatedAttachments,
                        });
                      }

                      form.setFieldsValue({
                        certificateName: null,
                      });

                      setFileList((prevFileList) =>
                        prevFileList.filter((file) => file.id !== removedFile.id)
                      );
                    }}
                  />
            </Form.Item>
          </div>

           {(isPdfOrJpgFile() && useAI) &&
              <div
                  className={`${prefixClassName}-certificate-form-item`}
                  data-test-id={`${prefixClassName}-certificate-form-item-ai`}
              >
                  <Form.Item name="aiOption">
                      <Checkbox data-test-id={`${prefixClassName}-certificate-form-item-ai-checkbox`}
                                checked={isAIUsed}
                                onChange={handleAIChange}
                      >
                          {intl.formatMessage(certificateModalMessages.aiDescription)}
                      </Checkbox>
                  </Form.Item>
              </div>
           }

        <div className={`${prefixClassName}-certificate-form-item-multiple`}>
            <div data-test-id={`${prefixClassName}-certificate-form-item-certificate-validFrom`}>
              <Form.Item
                  name="validFrom"
                  label={intl.formatMessage(certificateModalMessages.validFrom)}
                  labelCol={{ span: 24 }}
              >
                  <DatePicker
                      value={formData.validFrom ? moment(formData.validFrom) : null}
                      onChange={handleValidFromChange}
                      data-test-id={`${prefixClassName}-certificate-form-item-certificate-validFrom`}
                  />
              </Form.Item>
            </div>

            <div data-test-id={`${prefixClassName}-certificate-form-item-certificate-validTo`}>
              <Form.Item
                  name="validTo"
                  label={intl.formatMessage(certificateModalMessages.validTo)}
                  labelCol={{ span: 24 }}
                  required
              >
                  <DatePicker
                      value={formData.validTo ? moment(formData.validTo) : null}
                      onChange={handleValidToChange}
                      data-test-id={`${prefixClassName}-certificate-form-item-certificate-validTo`}
                  />
              </Form.Item>
            </div>

            <div
              data-test-id={`${prefixClassName}-certificate-form-item-certificate-grade`}
             >
              <Form.Item
                  name="grade"
                  label={intl.formatMessage(certificateModalMessages.grade)}
                  labelCol={{ span: 24 }}
              >
                  <Input
                      onChange={handleGradeChange}
                      value={formData.grade}
                      placeholder={intl.formatMessage(certificateModalMessages.gradePlaceholder)}
                      data-test-id={`${prefixClassName}-certificate-form-item-certificate-grade-input`}
                  />
              </Form.Item>
            </div>
        </div>

        <div
            className={`${prefixClassName}-certificate-form-item-comments`}
            data-test-id={`${prefixClassName}-certificate-form-item-certificate-comments`}
        >
            <Form.Item
                name="comments"
                label={intl.formatMessage(certificateModalMessages.comments)}
                labelCol={{ span: 24 }}
            >
                <TextArea
                    onChange={handleCommentsChange}
                    value={formData.comments}
                    placeholder={intl.formatMessage(certificateModalMessages.commentsPlaceholder)}
                    data-test-id={`${prefixClassName}-certificate-form-item-certificate-textarea`}
                />
            </Form.Item>
        </div>
          </Form>
      </div>
  );
};
export default CertificateForm;
