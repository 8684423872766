import { useIntl } from "react-intl";
import { LIBRARY_OBJECT_TYPES } from "utils/constants";
import messages from "messages/library";
import { generateListPath } from "utils/general";
import useUserPermission from "../useUserPermission";
import useUserInfo from "../useUserInfo";

const useLibraryNavigation = () => {
  const { formatMessage } = useIntl();
  const permissions = useUserPermission();
  const {
    isRetailer,
    hasClaimsAccess,
    hasFAndVAccess,
    hasPackagingSystemsAccess,
  } = useUserInfo();
  const {
    templatesCreate,
    requirementsCreate,
    materialsCreate,
    chaptersCreate,
    packagingSystemsWrite,
    claimsCreate,
    claimsRead,
    claimsRegulationAdministrate,
  } = permissions;

  let tabList = [];

  // the has... checks will become unnecessary when role-based implementation is done in backend

  if (requirementsCreate && hasFAndVAccess) {
    tabList.push({
      key: LIBRARY_OBJECT_TYPES.REQUIREMENTS,
      path: generateListPath(LIBRARY_OBJECT_TYPES.REQUIREMENTS),
      text: formatMessage(messages.requirements),
      title: formatMessage(messages.requirements),
    });
  }

  if (materialsCreate && hasFAndVAccess) {
    tabList.push({
      key: LIBRARY_OBJECT_TYPES.MATERIALS,
      path: generateListPath(LIBRARY_OBJECT_TYPES.MATERIALS),
      text: formatMessage(messages.materials),
      title: formatMessage(messages.materials),
    });
  }

  if (chaptersCreate && hasFAndVAccess) {
    tabList.push({
      key: LIBRARY_OBJECT_TYPES.CHAPTERS,
      path: generateListPath(LIBRARY_OBJECT_TYPES.CHAPTERS),
      text: formatMessage(messages.chapters),
      title: formatMessage(messages.chapters),
    });
  }

  if (claimsCreate && hasClaimsAccess) {
    tabList.push({
      key: LIBRARY_OBJECT_TYPES.CLAIMS,
      path: generateListPath(LIBRARY_OBJECT_TYPES.CLAIMS),
      text: formatMessage(messages.claims),
      title: formatMessage(messages.claims),
    });
  }

  if (claimsRegulationAdministrate && hasClaimsAccess) {
    tabList.push({
      key: LIBRARY_OBJECT_TYPES.REGULATED_HEALTH_CLAIMS,
      path: generateListPath(LIBRARY_OBJECT_TYPES.REGULATED_HEALTH_CLAIMS),
      text: formatMessage(messages.regulatedHealthClaims),
      title: formatMessage(messages.regulatedHealthClaims),
    });
  }

  if (
    (claimsRead && isRetailer && hasClaimsAccess) ||
    (claimsRegulationAdministrate && hasClaimsAccess)
  ) {
    tabList.push({
      key: LIBRARY_OBJECT_TYPES.NUTRITIONAL_CLAIMS,
      path: generateListPath(LIBRARY_OBJECT_TYPES.NUTRITIONAL_CLAIMS),
      text: formatMessage(messages.nutritionalClaims),
      title: formatMessage(messages.nutritionalClaims),
    });
  }

  if (packagingSystemsWrite && hasPackagingSystemsAccess) {
    tabList.push({
      key: LIBRARY_OBJECT_TYPES.PACKAGING_SYSTEMS,
      path: generateListPath(LIBRARY_OBJECT_TYPES.PACKAGING_SYSTEMS),
      text: formatMessage(messages.packagings),
      title: formatMessage(messages.packagings),
    });
  }

  if (templatesCreate && hasFAndVAccess) {
    tabList.push({
      key: LIBRARY_OBJECT_TYPES.TEMPLATES,
      path: generateListPath(LIBRARY_OBJECT_TYPES.TEMPLATES),
      text: formatMessage(messages.templates),
      title: formatMessage(messages.templates),
    });
  }

  return tabList;
};

export default useLibraryNavigation;
