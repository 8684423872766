import { defineMessages } from "react-intl";

export const messages = defineMessages({
  setAsSignatory: {
    id: "specificationDetails.sections.contacts.setSignatory",
    defaultMessage: "Set as signatory",
  },
  unsetAsSignatory: {
    id: "specificationDetails.sections.contacts.unsetSignatory",
    defaultMessage: "Unset as signatory",
  },
  removeTheContact: {
    id: "specificationDetails.sections.contacts.remove",
    defaultMessage: "Remove the contact",
  },
  addAContact: {
    id: "specificationDetails.sections.contacts.add",
    defaultMessage: "Add a contact",
  },
  chooseAContact: {
    id: "specificationDetails.sections.contacts.choose",
    defaultMessage: "Choose a contact",
  },
  noContact: {
    id: "specificationDetails.sections.contacts.noContact",
    defaultMessage: "No contact",
  },
  contactError: {
    id: "specificationDetails.sections.contacts.error",
    defaultMessage: "An error occured: unable to recover the contacts",
  },
  title: {
    id: "specificationDetails.sections.contacts.addCollaboration.title",
    defaultMessage: "User invitation",
  },
});
