import { useMemo } from "react";
import { Row, Col } from "antd";
import { Heading, Icon, Paragraph } from "@trace-one/design-system";
import { useIntl } from "react-intl";
import { formatDateToMMMDYYYYWithSpace } from "utils/date";
import { messages } from "./messages";
import { VersionBoxProps } from "./types";
import styles from "./styles.module.less";

const VersionBox: React.FC<VersionBoxProps> = ({
  versionNumber,
  creationDateUtc,
  signatureDateUtc,
}) => {
  const { formatMessage } = useIntl();

  const signatureDateUtcValue = useMemo(
    () =>
      signatureDateUtc
        ? formatDateToMMMDYYYYWithSpace(signatureDateUtc)
        : formatMessage(messages.notSigned),
    [signatureDateUtc]
  );

  return (
    <div className={styles.versionBox}>
      <Row>
        <Col className={styles.versionIcon}>
          <Icon name="specification" size="medium" color="grey-5" />
        </Col>
        <Col>
          <Heading size="xxs">
            {formatMessage(messages.versionTitle, {
              versionNumber,
            })}
          </Heading>
        </Col>
      </Row>
      <Row wrap={false}>
        <Col span={10}>
          <Paragraph size="xs" color="grey-4">
            {formatMessage(messages.creationTitle)}
          </Paragraph>
        </Col>
        <Col span={12}>
          <Paragraph size="xs" color="grey-5">
            {formatDateToMMMDYYYYWithSpace(creationDateUtc)}
          </Paragraph>
        </Col>
      </Row>
      <Row wrap={false}>
        <Col span={10}>
          <Paragraph size="xs" color="grey-4">
            {formatMessage(messages.signatureTitle)}
          </Paragraph>
        </Col>
        <Col span={12}>
          <Paragraph size="xs" color="grey-5">
            {signatureDateUtcValue}
          </Paragraph>
        </Col>
      </Row>
    </div>
  );
};

export default VersionBox;
