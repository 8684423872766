import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import cleanDeep from "clean-deep";
import useBoolean from "hooks/useBoolean";
import { useAppDispatch } from "store";
import { updateCatalogDictionary } from "store/generalData/asyncActions";
import { selectCatalogDictionary } from "store/generalData/selectors";
import { selectLanguageCode } from "store/user/selectors";
import {
  fetchAllergensByIds,
  fetchClassificationByIds,
  fetchNutrientByIds,
  fetchPartsByIds,
  fetchRecyclabilityRegulations,
  fetchSubstanceFunctionsByIds,
  fetchSubstancesByIds,
} from "apis/CATALOGUE";
import { getDictionary, isListEmpty } from "utils/general";
import { getNutrientsForCatalogDictionary } from "utils/nutrition";
import {
  CatalogListItemData,
  SubstanceData,
  SubstanceRegulationData,
} from "models";
import { Dictionary } from "types/general";

export type UseCatalogDictionaryProps = {
  catalogIds?: string[];
  catalogAllergenIds?: string[];
  catalogClassificationIds?: string[];
  rawMaterialPartIds?: string[];
  nutrientIds?: string[];
  substanceIds?: string[];
  shouldFilterIds?: boolean;
  shouldGetSubstanceRegulationData?: boolean;
};

const useCatalogDictionary = ({
  catalogIds,
  catalogAllergenIds,
  catalogClassificationIds,
  rawMaterialPartIds,
  nutrientIds,
  substanceIds,
  shouldFilterIds = true,
  shouldGetSubstanceRegulationData,
}: UseCatalogDictionaryProps) => {
  const [catalogItems, setCatalogItems] = useState<CatalogListItemData[]>([]);
  const [
    substanceInformationCollection,
    setSubstanceInformationCollection,
  ] = useState<SubstanceData[]>([]);

  const [substanceRegulations, setSubstanceRegulations] = useState<
    SubstanceRegulationData[]
  >([]);

  const dispatch = useAppDispatch();
  const languageCode = useSelector(selectLanguageCode);
  const catalogDictionary = useSelector(selectCatalogDictionary);

  const { value: isLoading, setTrue, setFalse } = useBoolean(false);

  const prepareCatalogDictionary = (list: CatalogListItemData[]) =>
    list.reduce(
      (previousState, { id, name }) => ({
        ...previousState,
        [id]: name,
      }),
      {}
    ) as Dictionary;

  const getFilteredIds = (ids: string[]) =>
    shouldFilterIds ? ids?.filter(id => !catalogDictionary?.[id]) : ids;

  const getCatalogItems = async (catalogItemIds: string[]) => {
    try {
      setTrue();

      const { data } = await fetchSubstanceFunctionsByIds({
        substanceFunctionIds: cleanDeep(catalogItemIds),
        languageCode,
      });

      setCatalogItems(data);

      dispatch(updateCatalogDictionary(prepareCatalogDictionary(data)));
    } catch (error) {
      setCatalogItems([]);
    } finally {
      setFalse();
    }
  };

  const getCatalogAllergens = async (catalogAllergenItemIds: string[]) => {
    try {
      setTrue();

      const { data } = await fetchAllergensByIds({
        allergenIds: cleanDeep(catalogAllergenItemIds),
        languageCode,
      });

      setCatalogItems(data);

      dispatch(updateCatalogDictionary(prepareCatalogDictionary(data)));
    } catch (error) {
      setCatalogItems([]);
    } finally {
      setFalse();
    }
  };

  const getCatalogClassificationIds = async (
    catalogClassificationIds: string[]
  ) => {
    try {
      setTrue();

      const { data } = await fetchClassificationByIds({
        rawMaterialClassificationIds: cleanDeep(catalogClassificationIds),
        languageCode,
      });

      setCatalogItems(data);

      dispatch(updateCatalogDictionary(prepareCatalogDictionary(data)));
    } catch {
      setCatalogItems([]);
    } finally {
      setFalse();
    }
  };

  const getRawMaterialPartIds = async (rawMaterialPartIds: string[]) => {
    try {
      setTrue();

      const { data } = await fetchPartsByIds({
        rawMaterialPartIds: cleanDeep(rawMaterialPartIds),
        languageCode,
      });

      setCatalogItems(data);

      dispatch(updateCatalogDictionary(prepareCatalogDictionary(data)));
    } catch {
      setCatalogItems([]);
    } finally {
      setFalse();
    }
  };

  const getNutrients = async (nutrientIds: string[]) => {
    try {
      const { data } = await fetchNutrientByIds({
        nutrientIds,
        languageCode,
      });

      dispatch(updateCatalogDictionary(getNutrientsForCatalogDictionary(data)));
    } catch {}
  };

  const getSubstances = async (substanceIds: string[]) => {
    try {
      setTrue();

      const { data } = await fetchSubstancesByIds({
        substanceIds,
        languageCode,
      });

      const substancesDictionary = getDictionary({
        data,
        key: "id",
        value: "name",
      });

      setSubstanceInformationCollection(data);

      dispatch(updateCatalogDictionary(substancesDictionary));
    } catch {
      setSubstanceInformationCollection([]);
    } finally {
      setFalse();
    }
  };

  const getSubstancesRecyclabilityRegulations = async (
    substanceIds: string[]
  ) => {
    try {
      setTrue();

      const { data } = await fetchRecyclabilityRegulations({
        substanceIds,
        languageCode,
      });

      setSubstanceRegulations(data);
    } catch {
      setSubstanceRegulations([]);
    } finally {
      setFalse();
    }
  };

  useEffect(() => {
    const filteredIds = getFilteredIds(catalogIds);

    if (!isListEmpty(filteredIds)) {
      getCatalogItems(filteredIds);
    }
  }, [JSON.stringify(catalogIds)]);

  useEffect(() => {
    const filteredIds = getFilteredIds(catalogAllergenIds);

    if (!isListEmpty(filteredIds)) {
      getCatalogAllergens(filteredIds);
    }
  }, [JSON.stringify(catalogAllergenIds)]);

  useEffect(() => {
    const filteredIds = getFilteredIds(catalogClassificationIds);

    if (!isListEmpty(filteredIds)) {
      getCatalogClassificationIds(filteredIds);
    }
  }, [catalogClassificationIds]);

  useEffect(() => {
    const filteredIds = getFilteredIds(rawMaterialPartIds);

    if (isListEmpty(filteredIds)) {
      return;
    }

    getRawMaterialPartIds(filteredIds);
  }, [rawMaterialPartIds]);

  useEffect(() => {
    const filteredIds = getFilteredIds(nutrientIds);

    if (isListEmpty(filteredIds)) {
      return;
    }

    getNutrients(filteredIds);
  }, [JSON.stringify(nutrientIds)]);

  useEffect(() => {
    const filteredIds = getFilteredIds(substanceIds);

    if (isListEmpty(filteredIds)) {
      setSubstanceInformationCollection([]);
      return;
    }

    // endpoint need not be called if a substance is removed
    if (
      filteredIds.every(id =>
        substanceInformationCollection.map(({ id }) => id).includes(id)
      )
    ) {
      setSubstanceInformationCollection(prevState =>
        prevState.filter(({ id }) => substanceIds.includes(id))
      );

      setSubstanceRegulations(prevState =>
        prevState.filter(({ id }) => substanceIds.includes(id))
      );

      return;
    }

    getSubstances(filteredIds);

    if (shouldGetSubstanceRegulationData) {
      getSubstancesRecyclabilityRegulations(filteredIds);
    }
  }, [JSON.stringify(substanceIds)]);

  return {
    catalogDictionary,
    catalogItems,
    substanceInformationCollection,
    substanceRegulations,
    isLoading,
  };
};

export default useCatalogDictionary;
