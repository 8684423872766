import { useState } from "react";
import { EnhancedExtraActions } from "components/SpecificationSection/types";
import { getFilesInfo } from "utils/files";
import { ClaimData, File } from "models";
import { ClaimFormData } from "types/specification";
import { useBoolean } from "hooks";
import { claimTypeMap } from "utils/claim";
import {
  removeClaim,
  removeFile,
  updateClaimsDisplayOnPack,
  addFile,
} from "./utils";

export interface UseClaimCardContentProps {
  formData: ClaimFormData;
  onUpdateClaims: EnhancedExtraActions["onUpdateClaims"];
}

const useClaimCardContent = ({
  formData,
  onUpdateClaims,
}: UseClaimCardContentProps) => {
  const [claimFileMap, setClaimFileMap] = useState({});
  const {
    value: isClaimUpdating,
    setTrue: setClaimUpdatingTrue,
    setFalse: setClaimUpdatingFalse,
  } = useBoolean();

  const handleDisplayOnPackToggle = (claimType: string) => async (
    claimId: ClaimData["id"]
  ) => {
    try {
      setClaimUpdatingTrue();

      const {
        nutritionalClaims = [],
        healthClaims = [],
        marketingClaims = [],
      } = formData || {};

      let updatedFormData = {
        nutritionalClaims,
        healthClaims,
        marketingClaims,
      };

      const claimKey = claimTypeMap[claimType];

      if (claimKey) {
        updatedFormData[claimKey] = updateClaimsDisplayOnPack({
          claimId,
          claims: formData?.[claimKey] || [],
        });
      }

      await onUpdateClaims?.(updatedFormData);
    } catch {
    } finally {
      setClaimUpdatingFalse();
    }
  };

  const handleRemoveClaim = (claimType: string) => async (
    claimId: ClaimData["id"]
  ) => {
    try {
      setClaimUpdatingTrue();

      const {
        nutritionalClaims = [],
        healthClaims = [],
        marketingClaims = [],
      } = formData || {};

      let updatedFormData = {
        nutritionalClaims,
        healthClaims,
        marketingClaims,
      };

      const claimKey = claimTypeMap[claimType];

      if (claimKey) {
        updatedFormData[claimKey] = removeClaim({
          claimId,
          claims: formData?.[claimKey] || [],
        });
      }

      await onUpdateClaims?.(updatedFormData);
    } catch {
    } finally {
      setClaimUpdatingFalse();
    }
  };

  const handleFilesUpload = (claimType: string) => (
    claimId: ClaimData["id"]
  ) => async (files: File[]) => {
    try {
      setClaimUpdatingTrue();

      const {
        nutritionalClaims = [],
        healthClaims = [],
        marketingClaims = [],
      } = formData || {};

      let updatedFormData = {
        nutritionalClaims,
        healthClaims,
        marketingClaims,
      };

      const claimKey = claimTypeMap[claimType];

      if (claimKey) {
        updatedFormData[claimKey] = addFile({
          claimId,
          files,
          claims: formData?.[claimKey] || [],
        });
      }

      await onUpdateClaims?.(updatedFormData);

      setClaimFileMap(prevState => {
        const updatedFiles = [...(prevState?.[claimId] || []), ...files];

        return {
          ...prevState,
          [claimId]: updatedFiles,
        };
      });
    } catch {
    } finally {
      setClaimUpdatingFalse();
    }
  };

  const handleFileRemove = (claimType: string) => (
    claimId: ClaimData["id"]
  ) => async (file: File) => {
    try {
      setClaimUpdatingTrue();

      const {
        nutritionalClaims = [],
        healthClaims = [],
        marketingClaims = [],
      } = formData || {};

      let updatedFormData = {
        nutritionalClaims,
        healthClaims,
        marketingClaims,
      };

      const claimKey = claimTypeMap[claimType];

      if (claimKey) {
        updatedFormData[claimKey] = removeFile({
          claimId,
          file,
          claims: formData?.[claimKey] || [],
        });
      }

      await onUpdateClaims?.(updatedFormData);

      setClaimFileMap(prevState => {
        const updatedFiles = prevState?.[claimId]?.filter(
          existingFiles => existingFiles.id !== file.id
        );

        return {
          ...prevState,
          [claimId]: updatedFiles,
        };
      });
    } catch {
    } finally {
      setClaimUpdatingFalse();
    }
  };

  const handleEnter = async ({
    claimId,
    fileIds,
  }: {
    claimId: string;
    fileIds: string[];
  }) => {
    try {
      const files = await getFilesInfo({ fileIds });

      setClaimFileMap(prevState => ({
        ...prevState,
        [claimId]: files,
      }));
    } catch {}
  };

  return {
    isClaimUpdating,
    handleDisplayOnPackToggle,
    handleRemoveClaim,
    handleFilesUpload,
    handleFileRemove,
    handleEnter,
    claimFileMap,
  };
};

export default useClaimCardContent;
