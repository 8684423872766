import { Heading } from "@trace-one/design-system";
import { getVersionName } from "utils/specifications";
import { RowTitleProps } from "./types";
import styles from "./styles.module.less";

const RowTitle: React.FC<RowTitleProps> = ({
  title,
  version,
  extraComponent,
}) => {
  return (
    <div className={styles.row}>
      <div className={styles.rowTitle}>
        <Heading size="xs">{title}</Heading>
        {version ? (
          <>
            <Heading size="xxs">&nbsp;-&nbsp;</Heading>
            <Heading size="xxs" className={styles.rowVersion}>
              {getVersionName(version)}
            </Heading>
          </>
        ) : null}
      </div>
      {extraComponent}
    </div>
  );
};

export default RowTitle;
