import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  fetchChapterSpecifications,
  fetchClaimSpecifications,
  fetchRequirementSpecifications,
} from "apis/SPEC";
import { Sorter } from "types/pagination";
import {
  CHAPTER_TYPES_URL_API,
  LIBRARY_OBJECT_TYPES,
  REQUIREMENT_TYPES_URL_API,
} from "utils/constants";
import { PaginationProps } from "antd";
import { SpecificationListItemData } from "models";
import { ParamTypes } from "pages/Library/components/types";
import { getLibraryItemApiParams } from "utils/library";
import { LibraryItemApiType } from "types/library";

const FetchApiMapping = {
  [CHAPTER_TYPES_URL_API.production]: fetchChapterSpecifications,
  [CHAPTER_TYPES_URL_API.testing]: fetchChapterSpecifications,
  [CHAPTER_TYPES_URL_API["finished-product"]]: fetchChapterSpecifications,
  [REQUIREMENT_TYPES_URL_API[
    "text-requirements"
  ]]: fetchRequirementSpecifications,
  [REQUIREMENT_TYPES_URL_API[
    "document-requirements"
  ]]: fetchRequirementSpecifications,
  [LIBRARY_OBJECT_TYPES.HEALTH_CLAIMS]: fetchClaimSpecifications,
  [LIBRARY_OBJECT_TYPES.MARKETING_CLAIMS]: fetchClaimSpecifications,
};

const useLinkedSpecifications = ({
  libraryItemId,
  libraryItemType,
  sorter,
  pagination,
}: {
  libraryItemId: string;
  libraryItemType: string;
  sorter?: Sorter;
  pagination?: PaginationProps;
}) => {
  const { id: libraryItemIdFromUrl } = useParams<ParamTypes>();
  const { current = 1, pageSize = 10 } = pagination || {};

  const params = {
    skip: (current - 1) * pageSize,
    take: pageSize,
    orderBy: sorter?.orderBy,
    orderByDescending: sorter?.orderByDescending,
  };

  const apiParams = {
    ...getLibraryItemApiParams({
      libraryItemId,
      libraryItemType: libraryItemType as LibraryItemApiType,
    }),
    params,
  };

  const [specifications, setSpecifications] = useState<
    SpecificationListItemData[]
  >([]);
  const [totalCount, setTotalCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const getLinkedSpecifications = async () => {
    try {
      setIsLoading(true);

      const { data } = await FetchApiMapping[libraryItemType](apiParams);

      setSpecifications(data?.items);
      setTotalCount(data?.totalNumberOfItems || 0);
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (
      !libraryItemId ||
      !libraryItemType ||
      libraryItemId !== libraryItemIdFromUrl
    ) {
      return;
    }

    getLinkedSpecifications();
  }, [
    libraryItemType,
    libraryItemId,
    libraryItemIdFromUrl,
    sorter,
    current,
    pageSize,
  ]);

  return { specifications, isLoading, totalCount, getLinkedSpecifications };
};

export default useLinkedSpecifications;
