import { useEffect } from "react";
import { useSelector } from "react-redux";
import useBoolean from "hooks/useBoolean";
import { useAppDispatch } from "store";
import { updateCatalogDictionary } from "store/generalData/asyncActions";
import { selectLanguageCode } from "store/user/selectors";
import { fetchRecyclabilityRegulationsInfo } from "apis/CATALOGUE";
import { RECYCLABILITY_REGULATION_CODES } from "utils/constants";
import { prepareRecyclabilityRegulationsInfoForDictionary } from "dtos/packagingSystem";

const useRecyclabilityRegulationsInfo = () => {
  const languageCode = useSelector(selectLanguageCode);

  const dispatch = useAppDispatch();
  const { value: loading, setTrue, setFalse } = useBoolean(false);

  const getSubstancesRecyclabilityRegulationsInfo = async () => {
    try {
      setTrue();

      const { data } = await fetchRecyclabilityRegulationsInfo({
        languageCode,
      });

      const { reach, agec, eu } = data;

      const regulationsInfoDictionary = {
        ...prepareRecyclabilityRegulationsInfoForDictionary({
          regulationsInfo: reach,
          regulationNameKey: RECYCLABILITY_REGULATION_CODES.REACH,
        }),
        ...prepareRecyclabilityRegulationsInfoForDictionary({
          regulationsInfo: agec,
          regulationNameKey: RECYCLABILITY_REGULATION_CODES.AGEC,
        }),
        ...prepareRecyclabilityRegulationsInfoForDictionary({
          regulationsInfo: eu,
          regulationNameKey: RECYCLABILITY_REGULATION_CODES.EU,
        }),
      };

      dispatch(updateCatalogDictionary(regulationsInfoDictionary));
    } catch {
    } finally {
      setFalse();
    }
  };

  useEffect(() => {
    getSubstancesRecyclabilityRegulationsInfo();
  }, []);

  return {
    loading,
  };
};

export default useRecyclabilityRegulationsInfo;
