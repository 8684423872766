import { RequirementData } from "models";
import { RequirementInformationViewModel } from "viewModels/requirements";

export const prepareRequirementInformationFromApiData = (
  requirementData: RequirementData
) => {
  let newRequirementInformation: RequirementInformationViewModel = {};

  newRequirementInformation = {
    id: requirementData.id,
    name: requirementData.name,
    tagIds: requirementData.tagIds,
    content: requirementData.content,
    fileId: requirementData.fileId,
    additionalInformation: requirementData.additionalInformation,
    state: requirementData.state,
    version: requirementData.version,
    requirementType: requirementData.requirementType,
  };

  return newRequirementInformation;
};
