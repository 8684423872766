import { File } from "models";
import { ClaimFormItemData } from "types/specification";

export const updateClaimsDisplayOnPack = ({
  claims,
  claimId,
}: {
  claims: ClaimFormItemData[];
  claimId: ClaimFormItemData["claimId"];
}) =>
  claims.map(claim =>
    claim?.claimId === claimId
      ? { ...claim, displayOnPack: !claim?.displayOnPack }
      : claim
  );

export const removeClaim = ({
  claims,
  claimId,
}: {
  claims: ClaimFormItemData[];
  claimId: ClaimFormItemData["claimId"];
}) => claims.filter(claim => claim.claimId !== claimId);

export const addFile = ({
  claims,
  claimId,
  files,
}: {
  claims: ClaimFormItemData[];
  claimId: ClaimFormItemData["claimId"];
  files: File[];
}) =>
  claims.map(claim => {
    if (claim?.claimId === claimId) {
      return {
        ...claim,
        supportingDocumentIds: Array.from(
          new Set([
            ...(claim.supportingDocumentIds || []),
            ...files.map(({ id }) => id),
          ])
        ),
      };
    }
    return claim;
  });

export const removeFile = ({
  claims,
  claimId,
  file,
}: {
  claims: ClaimFormItemData[];
  claimId: ClaimFormItemData["claimId"];
  file: File;
}) =>
  claims.map(claim => {
    if (claim?.claimId === claimId) {
      return {
        ...claim,
        supportingDocumentIds: claim.supportingDocumentIds?.filter(
          documentId => documentId !== file.id
        ),
      };
    }

    return claim;
  });
