import { createRef, RefObject, useEffect, useState } from "react";
import { BadgeList } from "@trace-one/design-system";
import { useMoreInfo } from "hooks";
import { isListEmpty } from "utils/general";
import MaterialLink from "./components/MaterialLink";
import { prepareMaterialName } from "./utils";
import { MaterialListProps } from "./types";
import styles from "./styles.module.less";

const MaterialList: React.FC<MaterialListProps> = ({
  materials,
  displayActions,
}) => {
  const wrapperRef = createRef<HTMLDivElement>();
  const elementsRef = createRef<HTMLDivElement>();
  const moreInfoRef = createRef<HTMLDivElement>();
  const [elementListRefs, setElementListRefs] = useState<
    RefObject<HTMLDivElement>[]
  >([]);

  const { currentElements, moreInfoElements } = useMoreInfo({
    elements: materials.map(prepareMaterialName),
    moreInfoRef,
    elementListRefs: elementListRefs,
    elementsRef: elementsRef,
    wrapperRef,
    elementExtraWidth: 40,
  });

  useEffect(() => {
    setElementListRefs(materials.map(() => createRef()));
  }, [materials.length]);

  if (isListEmpty(materials)) {
    return <div>-</div>;
  }

  return (
    <div className={styles.elementsWrapper} ref={wrapperRef}>
      <div className={styles.elements} ref={elementsRef}>
        {currentElements.map((element, index) =>
          !!materials[index] ? (
            <div
              className={styles.element}
              key={`${element} - ${index}`}
              ref={elementListRefs[index]}
            >
              <MaterialLink
                material={materials[index]}
                displayActions={displayActions}
              />
            </div>
          ) : null
        )}
      </div>
      <div className={styles.moreInfo} ref={moreInfoRef}>
        {!isListEmpty(moreInfoElements) && (
          <BadgeList options={moreInfoElements} trigger="hover" />
        )}
      </div>
    </div>
  );
};

MaterialList.defaultProps = {
  displayActions: true,
};

export default MaterialList;
