import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { getPrefixClassName } from "../../../../utils/general";
import { certificateModalMessages } from "../../translations/messages";
import { CertificationsListProps } from "./types";
import { Select } from "@trace-one/design-system";
import { Form } from "antd";
import CertificationCard from "./components/CertificationCard";
import useCertifications from "../../hooks/useCertifications";
import { useLanguagePreference } from "IndexComponents";

const CertificationsList: React.FC<CertificationsListProps> = ({ setSelectedCertificationId, selectedCertificationId, extractedFileDataFromAI, isAIUsed, objectLink, isModalClosing, aiIsLoading }) => {
  const intl = useIntl();
  const [form] = Form.useForm();
  const languageCode = useLanguagePreference();
  const prefixClassName = getPrefixClassName("certificate-modal");

  const [notFoundCertificationError, setNotFoundCertificationError] = useState<boolean>();
  const { isLoading, certificationsListData } = useCertifications(
    languageCode,
    isAIUsed,
    objectLink
  );
  const getCertificationsOptions = () => {
    const options = [];

    if (extractedFileDataFromAI?.id && extractedFileDataFromAI?.name) {
      options.push({
        value: extractedFileDataFromAI.id,
        label: extractedFileDataFromAI.name,
      });
    }

    const certificationOptions = certificationsListData?.items?.map(certification => ({
      value: certification.id,
      label: certification.name,
    })) || [];

    return [...options, ...certificationOptions];
  };
  const handleCertificationChange = (value: { value: string; label: string }) => {
    setSelectedCertificationId(value.value);
    setNotFoundCertificationError(false);
  };

  const handleCertificationClear = () => {
    setSelectedCertificationId(null);
    form.resetFields(["selectCertifications"]);
    setNotFoundCertificationError(false);
  };
  const getSelectedValue = () => {
    const selectedCert = getCertificationsOptions().find(cert => cert.value === selectedCertificationId);
    return selectedCert ? { value: selectedCert.value, label: selectedCert.label } : undefined;
  };

  useEffect(() => {
    const selectedValue = getSelectedValue();
    if (selectedValue) {
      form.setFieldsValue({ selectCertifications: selectedValue });
    }
  }, [form, extractedFileDataFromAI, certificationsListData, selectedCertificationId]);

  useEffect(() => {
    if (isModalClosing) {
      form.resetFields(["selectCertifications"]);
    }
  }, [isModalClosing, form]);

  return (
  <div className={`${prefixClassName}-certifications-list`} data-test-id={`${prefixClassName}-certifications`}>
    <Form form={form}>
    <Form.Item
        name="selectCertifications"
        label={intl.formatMessage(certificateModalMessages.selectCertification)}
        required
        labelCol={{ span: 24 }}
        validateStatus={notFoundCertificationError && "warning"}
        help={notFoundCertificationError && intl.formatMessage(certificateModalMessages.selectCertificationError)}
    >
      <Select
          placeholder={intl.formatMessage(certificateModalMessages.selectCertificationPlaceholder)}
          data-test-id={`${prefixClassName}-certifications-select`}
          options={getCertificationsOptions()}
          onChange={handleCertificationChange}
          onClear={handleCertificationClear}
          allowClear
          loading={isLoading}
          labelInValue
          showSearch
          filterOption={(input, option) =>
            option?.label?.toLowerCase().includes(input.toLowerCase())
          }
          disabled={aiIsLoading}
      />
    </Form.Item>
    {selectedCertificationId && <CertificationCard certificationId={selectedCertificationId} setNotFoundCertificationError={setNotFoundCertificationError}/>}
    </Form>
  </div>
  );
};
export default CertificationsList;
