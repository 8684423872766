import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { selectLanguageCode } from "store/user/selectors";
import { fetchClaimTypes } from "apis/SPEC";
import useBoolean from "hooks/useBoolean";
import { ClaimTypeData } from "models";
import { ClaimType } from "types/library";

export type UseClaimTypes = {
  excludedClaimTypes?: ClaimType[];
};

const useClaimTypes = ({ excludedClaimTypes = [] }: UseClaimTypes) => {
  const languageCode = useSelector(selectLanguageCode);
  const { value: loadingClaimTypes, setTrue, setFalse } = useBoolean();
  const [claimTypes, setClaimTypes] = useState<ClaimTypeData[]>([]);

  useEffect(() => {
    const getClaimTypes = async () => {
      try {
        setTrue();
        const { data } = await fetchClaimTypes({ languageCode });
        setClaimTypes(data);
      } catch {
      } finally {
        setFalse();
      }
    };

    getClaimTypes();
  }, []);

  const getClaimTypeOptions = (items: ClaimTypeData[]) =>
    items
      ?.filter(({ claimType }) => !excludedClaimTypes.includes(claimType))
      ?.map(({ claimTypeName, claimType }) => ({
        label: claimTypeName,
        value: claimType,
        key: claimType,
      }));

  const claimTypeOptions = useMemo(() => getClaimTypeOptions(claimTypes), [
    claimTypes,
  ]);

  const filteredClaimTypes = useMemo(
    () =>
      claimTypes.filter(
        ({ claimType }) => !excludedClaimTypes.includes(claimType)
      ),
    [claimTypes, JSON.stringify(excludedClaimTypes)]
  );

  return {
    loadingClaimTypes,
    claimTypes: filteredClaimTypes,
    claimTypeOptions,
    getClaimTypeOptions,
  };
};

export default useClaimTypes;
