import { useEffect } from "react";
import { useSelector } from "react-redux";
import FormSchema from "../FormSchema";
import { useBlockActions, useBlockErrors, useBlocks } from "./hooks";
import {
  selectCatalogDictionary,
  selectCategoryDictionary,
  selectRefListDictionary,
} from "store/generalData/selectors";
import {
  selectIsSpecificationUpdating,
  selectModificationRequests,
} from "store/specificationDetails/selectors";
import { selectOwningCompanyId } from "store/user/selectors";
import {
  getArraySchemaItemKey,
  getArraySchemaItemsPropertyId,
} from "utils/general";
import { BlockProps } from "./types";

const Block: React.FC<BlockProps> = props => {
  const {
    displayActions,
    jsonSchema,
    templateBlockId,
    templateSectionId,
    sectionId,
    uiSchema,
    formData,
    specificationData,
    saveTemplateBlock: defaultSaveTemplateBlock,
    hasPermissionToSave,
    hasPermissionToValidate,
    shouldUseQueue,
    onFinish,
    extraTemplateBlockErrors,
    onErrorsChange,
    extraActions,
    chapterData,
    productData,
    packagingSystemData,
    onChangeTypingInProgress,
    page,
    isTargetMode,
    readOnlyFieldIds,
    isSpecContact,
    isRetailerContact,
    isTranslator,
    setSectionBlocks,
    disableExtraActions,
    supplierContacts,
    supplierCompanyId,
    materialData,
    getMaterialData,
    chapterRecipe,
  } = props;
  const refListDictionary = useSelector(selectRefListDictionary);
  const categoryDictionary = useSelector(selectCategoryDictionary);
  const catalogDictionary = useSelector(selectCatalogDictionary);
  const isSpecificationUpdating = useSelector(selectIsSpecificationUpdating);
  const ownerCompanyId = useSelector(selectOwningCompanyId);
  const modificationRequests = useSelector(selectModificationRequests);

  const { extraErrors, setErrors, transformErrors } = useBlockErrors({
    extraTemplateBlockErrors,
    onErrorsChange,
  });

  const {
    isTypingInProgress,
    saveTemplateBlock,
    onUpdateIsTypingInProgress,
    queueLength,
  } = useBlockActions({
    defaultSaveTemplateBlock,
    shouldUseQueue,
    formData,
    onFinish,
    jsonSchema,
    setErrors,
    setIsTypingInProgressAction: extraActions?.setIsTypingInProgressAction,
    uiSchema,
    page,
  });

  const { isWidgetEditable } = useBlocks({ ...props, queueLength });

  useEffect(() => {
    onChangeTypingInProgress?.(isTypingInProgress);
  }, [isTypingInProgress]);

  return (
    <FormSchema
      schema={jsonSchema}
      uiSchema={uiSchema}
      formData={formData}
      extraErrors={extraErrors}
      transformErrors={transformErrors}
      formContext={{
        templateBlockId,
        templateSectionId,
        displayActions,
        formData,
        saveTemplateBlock,
        sectionId,
        isWidgetEditable,
        hasPermissionToSave,
        hasPermissionToValidate,
        setErrors,
        emptyValue: "-",
        shouldCallApi: false,
        refListDictionary,
        categoryDictionary,
        catalogDictionary,
        labelAlign: "left",
        specificationData,
        extraErrors,
        transformErrors,
        extraActions,
        chapterData,
        productData,
        isTypingInProgress,
        onUpdateIsTypingInProgress,
        ownerCompanyId,
        packagingSystemData,
        page,
        isSpecificationUpdating,
        isTargetMode,
        readOnlyFieldIds,
        modificationRequests,
        arraySchemaItemKey: getArraySchemaItemKey(jsonSchema),
        arraySchemaPropertyId: getArraySchemaItemsPropertyId(jsonSchema),
        isSpecContact,
        isRetailerContact,
        isTranslator,
        queueLength,
        setSectionBlocks,
        disableExtraActions,
        supplierContacts,
        supplierCompanyId,
        materialData,
        getMaterialData,
        chapterRecipe,
      }}
    />
  );
};

Block.defaultProps = {
  displayActions: true,
  saveTemplateBlock: () => {},
};

export default Block;
