import { defineMessages } from "react-intl";

export const versionHistoryMessages = defineMessages({
  createARevisionSubtitle: {
    id: "library.details.versionHistory.createARevision.subtitle",
    defaultMessage:
      "Selected specifications will be revised to include the latest chapter version",
  },
  createARequirementRevisionSubtitle: {
    id: "library.details.versionHistory.createARequirementRevision.subtitle",
    defaultMessage:
      "Selected specifications will be revised to include the latest requirement version",
  },
  createARevisionClaimSubtitle: {
    id: "library.details.versionHistory.createARevisionClaim.subtitle",
    defaultMessage:
      "Selected specifications will be revised to include the latest claim version",
  },
  createRevisionBtn: {
    id: "library.details.versionHistory.createRevision.title",
    defaultMessage: "Create revision",
  },
  reviseSpecifications: {
    id: "library.details.versionHistory.reviseSpecifications",
    defaultMessage: "Revise specifications",
  },
  unlinkSpecificationsTitle: {
    id: "library.details.versionHistory.unlinkSpecifications.title",
    defaultMessage: "Unlink specifications",
  },
  unlinkSpecificationsSubtitle: {
    id: "library.details.versionHistory.unlinkSpecifications.subtitle",
    defaultMessage:
      "Selected specifications will be unlinked from the latest chapter version",
  },
  unlinkSpecificationsRequirementSubtitle: {
    id:
      "library.details.versionHistory.unlinkSpecifications.requirement.subtitle",
    defaultMessage:
      "Selected specifications will be unlinked from the latest requirement version",
  },
  unlinkSpecificationsClaimSubtitle: {
    id: "library.details.versionHistory.unlinkSpecifications.claim.subtitle",
    defaultMessage:
      "Selected specifications will be unlinked from the latest claim version",
  },
  updateToLatestVersionTitle: {
    id: "library.details.versionHistory.updateToLatestVersion.title",
    defaultMessage: "Update to latest version",
  },
  updateToLatestVersionSubtitle: {
    id: "library.details.versionHistory.updateToLatestVersion.subtitle",
    defaultMessage:
      "Selected specifications will be updated to the latest chapter version",
  },
  updateToLatestVersionRequirementSubtitle: {
    id:
      "library.details.versionHistory.updateToLatestVersion.requirement.subtitle",
    defaultMessage:
      "Selected specifications will be updated to the latest requirement version",
  },
  updateToLatestVersionClaimSubtitle: {
    id: "library.details.versionHistory.updateToLatestVersion.claim.subtitle",
    defaultMessage:
      "Selected specifications will be updated to the latest claim version",
  },
  unlinkButtonLabel: {
    id: "library.details.versionHistory.unlinkSpecifications.button",
    defaultMessage: "Unlink",
  },
  unlinkSpecificationsToasterMessage: {
    id: "library.details.versionHistory.unlinkSpecifications.toaster.message",
    defaultMessage: "Specifications unlinked",
  },
  unlinkSpecificationsToasterDescription: {
    id:
      "library.details.versionHistory.unlinkSpecifications.toaster.description",
    defaultMessage:
      "The chapter has been unlinked from the selected specifications",
  },
  unlinkSpecificationsRequirementToasterDescription: {
    id:
      "library.details.versionHistory.unlinkSpecifications.requirement.toaster.description",
    defaultMessage:
      "The requirement has been unlinked from the selected specifications.",
  },
  unlinkSpecificationsClaimToasterDescription: {
    id:
      "library.details.versionHistory.unlinkSpecifications.claim.toaster.description",
    defaultMessage:
      "The claim has been unlinked from the selected specifications.",
  },
  updateToLatestVersionToasterMessage: {
    id: "library.details.versionHistory.updateToLatestVersion.toaster.message",
    defaultMessage: "Update successful",
  },
  updateToLatestVersionToasterDescription: {
    id:
      "library.details.versionHistory.updateToLatestVersion.toaster.description",
    defaultMessage:
      "Selected specifications were updated to the latest chapter version",
  },
  updateToLatestVersionRequirementToasterDescription: {
    id:
      "library.details.versionHistory.updateToLatestVersion.requirement.toaster.description",
    defaultMessage:
      "Selected specifications were updated to the latest requirement version.",
  },
  updateToLatestVersionClaimToasterDescription: {
    id:
      "library.details.versionHistory.updateToLatestVersion.claim.toaster.description",
    defaultMessage:
      "Selected specifications were updated to the latest claim version.",
  },
  reviseToasterMessage: {
    id: "library.details.versionHistory.revise.toaster.message",
    defaultMessage: "Revisions created successfully",
  },
  reviseToasterDescription: {
    id: "library.details.versionHistory.revise.toaster.description",
    defaultMessage:
      "Selected specifications were revised to include the latest chapter version.",
  },
  reviseRequirementToasterDescription: {
    id: "library.details.versionHistory.revise.requirement.toaster.description",
    defaultMessage:
      "Selected specifications were revised to include the latest requirement version.",
  },
  reviseClaimToasterDescription: {
    id: "library.details.versionHistory.revise.claim.toaster.description",
    defaultMessage:
      "Selected specifications were revised to include the latest claim version.",
  },
});
