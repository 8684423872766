import { defineMessages } from "react-intl";

export const substancesTableMessages = defineMessages({
  substanceName: {
    id: "substance.name",
    defaultMessage: "Substance name",
  },
  nanoPresence: {
    id: "substance.nanoPresence",
    defaultMessage: "Nano presence",
  },
  casNumber: {
    id: "substance.casNumber",
    defaultMessage: "CAS number",
  },
  endocrineDisruptor: {
    id: "substance.endocrineDisruptor",
    defaultMessage: "Endocrine disruptor",
  },
  FRFApplicable: {
    id: "substance.FRFApplicable",
    defaultMessage: "FRF applicable",
  },
  SML: {
    id: "substance.SML",
    defaultMessage: "SML [mg/kg]",
  },
  editSubstance: {
    id: "substance.action.edit",
    defaultMessage: "Edit substance",
  },
  removeSubstance: {
    id: "substance.action.remove",
    defaultMessage: "Remove substance",
  },
  removeSubstanceConfirmation: {
    id: "substance.action.remove.confirmation",
    defaultMessage: "Do you want to remove this substance?",
  },
});
