import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { fetchReferenceListsItems } from "apis/RLMD";
import { useAppDispatch } from "store";
import { selectRefListDictionary } from "store/generalData/selectors";
import { updateRefListDictionary } from "store/generalData/asyncActions";
import { selectLanguageCode } from "store/user/selectors";
import { isListEmpty } from "utils/general";
import { Dictionary } from "types/general";

const useReferenceListsItems = ({
  refListIds,
  shouldFilterIds = true,
}: {
  refListIds: (string | number)[];
  shouldFilterIds?: boolean;
}) => {
  const dispatch = useAppDispatch();
  const languageCode = useSelector(selectLanguageCode);
  const refListDictionary = useSelector(selectRefListDictionary);
  const [refList, setRefList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const getRefList = async (refListIds: string[]) => {
    try {
      setIsLoading(true);

      const { data } = await fetchReferenceListsItems({
        ids: refListIds,
        languageCode: languageCode,
      });

      setRefList(data);

      dispatch(
        updateRefListDictionary(
          data.reduce(
            (previousState, { id, text }) => ({
              ...previousState,
              [id]: text,
            }),
            {}
          ) as Dictionary
        )
      );
    } catch (error) {
      setRefList([]);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const filteredIds = shouldFilterIds
      ? refListIds?.filter(reflistId => !refListDictionary[reflistId])
      : refListIds;

    if (!isListEmpty(filteredIds)) {
      getRefList(filteredIds as string[]);
    }
  }, [JSON.stringify(refListIds)]);

  return {
    refList,
    refListDictionary,
    isLoading,
  };
};

export default useReferenceListsItems;
