import { createContext, useContext } from "react";
import { FormInstance } from "antd";

export const ClaimFormContext = createContext<{
  setLogoFile?: Function;
  form?: FormInstance;
  isLoading?: boolean;
  isNewVersionPage?: boolean;
}>({
  setLogoFile: () => {},
  form: null,
  isLoading: false,
  isNewVersionPage: false,
});

export const ClaimFormProvider = ClaimFormContext.Provider;

export const useClaimFormContext = () => {
  const context = useContext(ClaimFormContext);
  if (!context) {
    throw new Error(
      "useClaimFormContext must be used within a ClaimFormProvider"
    );
  }
  return context;
};
