import React, { useMemo } from "react";
import {
  Typography,
  InputNumber,
  Radio,
  Button,
  Tooltip,
} from "@trace-one/design-system";
import { useFormSchemaTranslation } from "pages/Specification/components/Details/hooks";
import { Col, Form, Row } from "antd";
import Spin from "components/Spin";
import ConditionalWrapper from "components/ConditionalWrapper";
import { useSubstanceInformation } from "hooks";
import { usePackagingSystemForm } from "../hooks";
import { SubstanceSelect } from "components";
import { ObjectFieldTemplateProps } from "components/SpecificationSection/types";
import {
  findPropertyByKey,
  getFieldTitleFromUiSchemaArrayTemplate,
  getFieldTitleFromUiSchemaObjectTemplate,
} from "utils/general";
import { PackagingSubstance, PackagingSubstanceFormData } from "models";
import { AREA_CATEGORY_TYPES } from "utils/constants";

export interface PackagingSubstanceFormProps
  extends ObjectFieldTemplateProps<PackagingSubstanceFormData> {
  substances: PackagingSubstance[];
  setSubstances?: React.Dispatch<React.SetStateAction<PackagingSubstance[]>>;
  substanceId?: string;
  displayTitle?: boolean;
  disableSubstance?: boolean;
  initialValue?: PackagingSubstance;
  displayAddToSelect?: boolean;
  getSubstanceFormData?: React.Dispatch<
    React.SetStateAction<PackagingSubstance>
  >;
}

export const PackagingSubstanceForm: React.FC<PackagingSubstanceFormProps> = props => {
  const {
    formContext,
    uiSchema,
    schema,
    substances,
    setSubstances,
    formData,
    substanceId,
    disableSubstance,
    initialValue,
    getSubstanceFormData,
    displayTitle = true,
    displayAddToSelect = true,
  } = props;

  const { displayActions } = formContext;
  const substanceIdProperty = findPropertyByKey(schema, "substanceId");
  const { validationRequired } = findPropertyByKey(schema, "items") || {};
  const { getTranslationForKey } = useFormSchemaTranslation();

  const disabledIds = useMemo(() => {
    return [
      ...(substances ?? []).map(substance => substance.selectedSubstance?.id),
      ...(formData?.substances ?? []).map(substance => substance.substanceId),
    ];
  }, [substances, formData]);

  const {
    isLoading,
    options,
    selectedSubstance,
    onSearch,
    onSelect,
    onClear,
    onDropdownVisibleChange,
    fetchingSubstance,
  } = useSubstanceInformation({
    areaCategory: substanceIdProperty?.areaCategory as AREA_CATEGORY_TYPES,
    disabledIds,
    substanceId,
  });

  const {
    form,
    casNumbers,
    percentageInputProps,
    substanceForm,
    sizeInputProps,
    specificSurfaceInputProps,
    hasMandatoryFieldsFilled,
    onPercentageChange,
    onPresentAtNanoScaleChange,
    onPresenceAtNanoscaleChange,
    onAddToSelect,
  } = usePackagingSystemForm({
    schema,
    initialValue,
    getSubstanceFormData,
    selectedSubstance,
    setSubstances,
    onClear,
  });

  if (fetchingSubstance) return <Spin />;

  return (
    <>
      {displayTitle && (
        <Typography variant="body-regular" color="grey-5" className="mb-4 mt-1">
          {getTranslationForKey("addSubstanceSubtitle")}
        </Typography>
      )}

      <Form layout="vertical" form={form}>
        <ConditionalWrapper
          condition={disableSubstance}
          wrapper={(children: React.ReactNode) => (
            <Tooltip
              placement="bottomLeft"
              text={getTranslationForKey("substanceTooltipText")}
            >
              {children}
            </Tooltip>
          )}
        >
          <SubstanceSelect
            options={options}
            isLoading={isLoading}
            onSearch={onSearch}
            onSelect={onSelect}
            onClear={onClear}
            onDropdownVisibleChange={onDropdownVisibleChange}
            displayActions={displayActions}
            disabled={disableSubstance}
            selectedSubstance={selectedSubstance}
            label={getFieldTitleFromUiSchemaArrayTemplate(
              uiSchema.substances,
              "substanceId"
            )}
            placeholder={getTranslationForKey("selectSubstancePlaceholder")}
            width={18}
            data-test-id="substance-name"
            name="substance-name"
          />
        </ConditionalWrapper>

        <Row className="mt-3">
          <Col xs={12} md={4}>
            <Typography variant="heading-xxs" color="grey-5">
              {getTranslationForKey("casNumber")}
            </Typography>
          </Col>
          <Col>
            <Typography variant="body-regular" color="grey-5">
              {casNumbers
                ? casNumbers
                : getTranslationForKey("undefinedMessage")}
            </Typography>
          </Col>
        </Row>

        <Form.Item
          className="mt-2"
          name="percentage"
          label={getFieldTitleFromUiSchemaArrayTemplate(
            uiSchema.substances,
            "percentage"
          )}
          rules={[
            {
              required: validationRequired?.includes("percentage"),
              type: "number",
              min: percentageInputProps.min,
              max: percentageInputProps.max,
            },
          ]}
        >
          <InputNumber
            data-testid="packaging-component-percentage"
            placeholder={0}
            defaultValue={substanceForm?.percentage}
            {...percentageInputProps}
            onChange={onPercentageChange}
          />
        </Form.Item>

        <Row>
          <Col md={6}>
            <Typography variant="heading-xxs">
              {getFieldTitleFromUiSchemaArrayTemplate(
                uiSchema.substances,
                "presentAtNanoscale"
              )}
            </Typography>
          </Col>
          <Col>
            <Radio.Group
              value={substanceForm?.presentAtNanoscale}
              options={[
                { value: true, label: getTranslationForKey("yes") },
                { value: false, label: getTranslationForKey("no") },
              ]}
              onChange={onPresentAtNanoScaleChange}
            />
          </Col>
        </Row>

        {substanceForm?.presentAtNanoscale && (
          <Row className="mt-2">
            <Col md={5}>
              <Form.Item
                name="size"
                label={getFieldTitleFromUiSchemaObjectTemplate(
                  uiSchema?.substances?.items?.presenceAtNanoscale,
                  "size"
                )}
                rules={[
                  {
                    type: "number",
                    min: sizeInputProps.min,
                    max: sizeInputProps.max,
                  },
                ]}
                className="mb-0"
              >
                <InputNumber
                  data-testid="substance-presenceAtNanoscale-size"
                  placeholder={0}
                  defaultValue={substanceForm?.presenceAtNanoscale?.size}
                  {...sizeInputProps}
                  onChange={onPresenceAtNanoscaleChange("size")}
                />
              </Form.Item>
            </Col>
            <Col>
              <Form.Item
                name="specificSurface"
                label={getFieldTitleFromUiSchemaObjectTemplate(
                  uiSchema?.substances?.items?.presenceAtNanoscale,
                  "specificSurface"
                )}
                rules={[
                  {
                    type: "number",
                    min: specificSurfaceInputProps.min,
                    max: specificSurfaceInputProps.max,
                  },
                ]}
                className="mb-0"
              >
                <InputNumber
                  data-testid="substance-presenceAtNanoscale-specificSurface"
                  placeholder={0}
                  defaultValue={
                    substanceForm?.presenceAtNanoscale?.specificSurface
                  }
                  {...specificSurfaceInputProps}
                  onChange={onPresenceAtNanoscaleChange("specificSurface")}
                />
              </Form.Item>
            </Col>
          </Row>
        )}

        {displayAddToSelect && (
          <Button
            type="tertiary"
            className="mt-3 mb-3"
            disabled={!hasMandatoryFieldsFilled}
            onClick={onAddToSelect}
          >
            {getTranslationForKey("addToSelection")}
          </Button>
        )}
      </Form>
    </>
  );
};
