import { useIntl } from "react-intl";
import { Row } from "antd";
import ActionButton from "components/ActionButton";
import Status from "components/Status";
import { useRedirect, useUserInfo } from "hooks";
import { displayValueForSpecificationDraftState } from "pages/Specification/utils";
import { getVersionName } from "utils/specifications";
import { formatDateToLocal } from "utils/date";
import { getActionsList } from "./utils";
import { UserVersionHistoryColumnsProps } from "./type";
import { LibraryItemVersionData, VersionData } from "models";
import { generalMessages, specificationMessages } from "messages";

const useVersionHistoryColumns = ({
  displayStatus = false,
  displayActions = true,
  libraryItemType,
  handleOpenModal,
}: UserVersionHistoryColumnsProps) => {
  const { formatMessage } = useIntl();
  const { redirectToLibraryDetails } = useRedirect();
  const { isRetailer } = useUserInfo();

  const onCellClick = (row: LibraryItemVersionData) => ({
    onClick: () =>
      redirectToLibraryDetails({
        id: row.id,
        type: libraryItemType,
      }),
  });

  const actionRenderer = (
    version: VersionData,
    libraryItem: LibraryItemVersionData
  ) => (
    <Row justify="center">
      <ActionButton
        actions={getActionsList({
          formatMessage: formatMessage,
          activeVersion: version?.activeVersion,
          libraryItemId: libraryItem?.id,
          libraryItemState: libraryItem?.state,
          versionNumber: libraryItem?.version?.number,
          handleOpenModal,
          isRetailer,
        })}
      />
    </Row>
  );

  const columns = [
    {
      title: formatMessage(generalMessages.name),
      dataIndex: "name",
      key: "name",
      onCell: onCellClick,
    },
    {
      title: formatMessage(generalMessages.version),
      dataIndex: "version",
      key: "version",
      render: (version: VersionData) => {
        return getVersionName(version?.number);
      },
      onCell: onCellClick,
    },
    {
      title: formatMessage(specificationMessages.lastModification),
      dataIndex: "lastUpdateDateUtc",
      key: "lastUpdateDateUtc",
      render: (date, specification) =>
        displayValueForSpecificationDraftState({
          specificationState: specification.state,
          valueToDisplay: formatDateToLocal(date),
        }),
      onCell: onCellClick,
    },
    {
      title: formatMessage(generalMessages.links),
      dataIndex: "linkedSpecifications",
      key: "linkedSpecifications",
      onCell: onCellClick,
    },

    ...(displayStatus
      ? [
          {
            title: formatMessage(specificationMessages.status),
            dataIndex: "state",
            key: "state",
            render: (state: LibraryItemVersionData["state"]) => (
              <Status.Tag state={state} />
            ),
            onCell: onCellClick,
          },
        ]
      : []),

    ...(displayActions
      ? [
          {
            title: formatMessage(generalMessages.actions),
            dataIndex: "version",
            key: "actions",
            width: 90,
            render: actionRenderer,
          },
        ]
      : []),
  ];

  return {
    columns,
  };
};

export default useVersionHistoryColumns;
