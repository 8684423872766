import { createContext } from "react";
import { RequirementData } from "models";
import { EnhancedExtraActions } from "components/SpecificationSection/types";
import { ClaimFormData } from "types/specification";

export const SelectLibraryObjectsContext = createContext<{
  onSelectLibraryObject?: Function;
  selectedLibraryObjects: RequirementData[];
  setSelectedLibraryObjects?: Function;
  onClose?: Function;
  updateClaims?: EnhancedExtraActions["onUpdateClaims"];
  formData?: ClaimFormData;
}>({
  onSelectLibraryObject: () => {},
  selectedLibraryObjects: [],
  setSelectedLibraryObjects: () => {},
  onClose: () => {},
  updateClaims: async () => {},
  formData: {},
});
