import { defineMessages } from "react-intl";

export const claimMessages = defineMessages({
  addTranslation: {
    id: "claim.addtranslation",
    defaultMessage: "Add a translation",
  },
  claimTypeLabel: {
    id: "claim.type.label",
    defaultMessage: "Claim type",
  },
  claimTypePlaceholder: {
    id: "claim.type.placeholder",
    defaultMessage: "Enter a claim type",
  },
  stepTwoTitle: {
    id: "claim.stepTwo.title",
    defaultMessage: "Configure the claim",
  },
  stepTwoDescription: {
    id: "claim.stepTwo.description",
    defaultMessage: "Select a claim type to start filling sections information",
  },
  claimSentenceLabel: {
    id: "claim.claimSentence.label",
    defaultMessage: "Write a claim sentence",
  },
  claimLogoLabel: {
    id: "claim.logo.label",
    defaultMessage: "Add a logo to your claim",
  },
  claimUploadFileTitle: {
    id: "claim.upload.title",
    defaultMessage: "You can add logo to complete your claim",
  },
  cancelClaimCreationTitle: {
    id: "claim.cancelCreation.title",
    defaultMessage: "Cancel claim creation",
  },
  cancelCreationDescription: {
    id: "claim.cancelCreation.description",
    defaultMessage:
      "By leaving now, you can either keep your claim creation or cancel and delete it.",
  },
  cancelSaveModalTitle: {
    id: "claim.cancelSave.title",
    defaultMessage: "Modified data not saved",
  },
  cancelSaveDescription: {
    id: "claim.cancelSave.description",
    defaultMessage:
      "Some changes have not been saved. If you leave the page the data changes may be lost.",
  },
  cancelVersionCreationTitle: {
    id: "claim.version.cancelCreation.title",
    defaultMessage: "Cancel claim's new version creation",
  },
  cancelVersionCreationDescription: {
    id: "claim.version.cancelCreation.description",
    defaultMessage:
      "By leaving now, you can either keep as draft your claim new version or cancel the creation.",
  },
  claimTitle: {
    id: "claim.generalInformation.claimTitle",
    defaultMessage: "Claim",
  },
  claimLogoTitle: {
    id: "claim.logo.title",
    defaultMessage: "Claim logo",
  },
  claimLogoEmptyState: {
    id: "claim.logo.emptyState",
    defaultMessage: "There is no claim logo added.",
  },
  claimSentenceTitle: {
    id: "claim.generalInformation.claimSentenceTitle",
    defaultMessage: "Claim sentence",
  },
  claimSentenceTranslationTitle: {
    id: "claim.claimSentence.translation.title",
    defaultMessage: "Claim sentence translation",
  },
  additionalClaimSentenceTranslationTitle: {
    id: "claim.additionalClaimSentenceTranslation.title",
    defaultMessage: "Additional claim sentence translation",
  },
  claimSentenceTranslationEmptyState: {
    id: "claim.claimSentence.translation.emptyState",
    defaultMessage: "There is no translation added.",
  },
  languageTitle: {
    id: "claim.claimSentence.language.title",
    defaultMessage: "Language",
  },
  saveAsDraft: {
    id: "claim.cancelCreation.action.saveAsDraft",
    defaultMessage: "Save as draft",
  },
  deleteClaim: {
    id: "claim.cancelCreation.action.deleteClaim",
    defaultMessage: "Delete claim",
  },
  claimsConfirmationToasterTitle: {
    id: "claim.confirmationToaster.title",
    defaultMessage: "Claims linked",
  },
  claimsConfirmationToasterDescription: {
    id: "claim.confirmationToaster.description",
    defaultMessage: "Claims have been successfully linked to the specification",
  },
  requiresSupportingDocument: {
    id: "claim.generalInformation.requiresSupportingDocument.label",
    defaultMessage: "This claim requires supporting document",
  },
  claimTypeModificationTitle: {
    id: "claim.typeModification.title",
    defaultMessage: "Claim modification",
  },
  claimTypeModificationDescription: {
    id: "claim.typeModification.description",
    defaultMessage:
      "Changing the claim type will erase all filled information and files.",
  },
  claimTypeModificationStartOverDescription: {
    id: "claim.typeModification.startOver.description",
    defaultMessage: "You will need to start over.",
  },
  claimSentencePlaceholder: {
    id: "claim.claimSentencePlaceholder",
    defaultMessage: "Choose a related claim sentence",
  },
  nutrientSubstances: {
    id: "claim.nutrientSubstances",
    defaultMessage: "Nutrient, substances",
  },
  conditionOfUseTranslation: {
    id: "claim.conditionOfUseTranslation",
    defaultMessage: "Claim's condition of use",
  },
  additionalClaimSentence: {
    id: "claim.additionalClaimSentence",
    defaultMessage: "Additional claim sentence",
  },
  additionalClaimSentences: {
    id: "claim.additionalClaimSentences",
    defaultMessage: "Additional claim sentences",
  },
  additionalClaimSentencesPlaceholder: {
    id: "claim.additionalClaimSentencesPlaceholder",
    defaultMessage:
      "You can write an additional claims sentence to explain the claim with a wording that fit your brand",
  },
  aNutrient: {
    id: "claim.aNutrient",
    defaultMessage: "Select a nutrient or a substance",
  },
  nutrientPlaceholder: {
    id: "claim.nutrientPlaceholder",
    defaultMessage: "Choose nutrient",
  },
  nutrientMaxError: {
    id: "claim.nutrientMaxError",
    defaultMessage:
      "An error occured, if this error persists, please contact support.",
  },
});
