import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useIntl } from "react-intl";
import GeneralInformation from "../../components/GeneralInformation";
import LinkedSpecifications from "components/Library/components/ChapterForm/components/LinkedSpecifications";
import RequirementVersionHistory from "components/Library/components/RequirementForm/components/RequirementVersionHistory";
import VersionHistory from "components/Library/components/ChapterForm/components/VersionHistory";
import ClaimVersionHistory from "components/Library/components/ClaimForm/components/ClaimVersionHistory";
import { useLinkedSpecifications } from "hooks";
import { CHAPTER_TYPES_URL_API, LIBRARY_TAB_KEYS } from "utils/constants";
import { tabMessages } from "messages/library";
import {
  isLibraryObjectChapterSubType,
  isLibraryObjectClaimType,
  isLibraryObjectRequirementType,
} from "utils/library";
import { LibraryItemDetails } from "pages/Library/hocs/withLibraryItemDetails/types";
import { LibraryObjectType } from "types/library";
import { TabItem } from "types/general";

const useTabNavigation = ({
  libraryItemDetails,
  type,
}: {
  libraryItemDetails: LibraryItemDetails;
  type: LibraryObjectType;
}) => {
  const { formatMessage } = useIntl();
  const { location } = useHistory();
  const [activeTab, setActiveTab] = useState("");
  const [tabContent, setTabContent] = useState(null);

  const libraryItemId = isLibraryObjectChapterSubType(
    CHAPTER_TYPES_URL_API[type]
  )
    ? libraryItemDetails?.generalInformation?.id
    : libraryItemDetails?.id;

  const {
    totalCount: numberOfLinkedSpecs,
    getLinkedSpecifications,
  } = useLinkedSpecifications({
    libraryItemType: type,
    libraryItemId,
  });

  const getVersionHistoryComponent = () => {
    if (isLibraryObjectChapterSubType(CHAPTER_TYPES_URL_API[type])) {
      return (
        <VersionHistory
          chapterId={libraryItemDetails?.generalInfo?.id}
          chapterType={libraryItemDetails?.generalInfo?.type}
          linkedSpecificationsCallback={getLinkedSpecifications}
        />
      );
    }
    if (isLibraryObjectRequirementType(type)) {
      return (
        <RequirementVersionHistory
          requirementId={libraryItemDetails?.id}
          requirementType={type}
          linkedSpecificationsCallback={getLinkedSpecifications}
        />
      );
    }
    if (isLibraryObjectClaimType(type)) {
      return (
        <ClaimVersionHistory
          claimId={libraryItemDetails?.id}
          claimType={type}
          linkedSpecificationsCallback={getLinkedSpecifications}
        />
      );
    }
  };

  const componentMap = {
    [LIBRARY_TAB_KEYS.GENERAL_INFORMATION]: <GeneralInformation />,
    [LIBRARY_TAB_KEYS.LINKED_SPECIFICATION]: (
      <LinkedSpecifications
        libraryItemId={libraryItemId}
        libraryItemType={type}
      />
    ),
    [LIBRARY_TAB_KEYS.VERSION_HISTORY]: getVersionHistoryComponent(),
  };

  useEffect(() => {
    if (location.pathname.includes(LIBRARY_TAB_KEYS.LINKED_SPECIFICATION)) {
      setActiveTab(LIBRARY_TAB_KEYS.LINKED_SPECIFICATION);
    } else if (location.pathname.includes(LIBRARY_TAB_KEYS.VERSION_HISTORY)) {
      setActiveTab(LIBRARY_TAB_KEYS.VERSION_HISTORY);
    } else {
      setActiveTab(LIBRARY_TAB_KEYS.GENERAL_INFORMATION);
    }
  }, [location]);

  useEffect(() => {
    if (activeTab) {
      setTabContent(componentMap[activeTab]);
    }
  }, [activeTab]);

  let tabItems: TabItem[] = [
    {
      key: LIBRARY_TAB_KEYS.GENERAL_INFORMATION,
      label: formatMessage(tabMessages.generalInformation),
    },
  ];

  if (
    isLibraryObjectChapterSubType(CHAPTER_TYPES_URL_API[type]) ||
    isLibraryObjectRequirementType(type) ||
    isLibraryObjectClaimType(type)
  ) {
    tabItems = [
      ...tabItems,
      {
        key: LIBRARY_TAB_KEYS.LINKED_SPECIFICATION,
        label: formatMessage(tabMessages.linkedSpecifications, {
          numberOfLinkedSpecs,
        }),
      },
      {
        key: LIBRARY_TAB_KEYS.VERSION_HISTORY,
        label: formatMessage(tabMessages.versionHistory),
      },
    ];
  }

  return {
    activeTab,
    setActiveTab,
    tabItems,
    tabContent,
  };
};

export default useTabNavigation;
