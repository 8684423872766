import { Row, Col, Modal } from "antd";
import { Button } from "@trace-one/design-system";
import { useIntl } from "react-intl";
import { Tree } from "@trace-one/react-components";
import { useCheckedChildren, useHierarchyData } from "hooks";
import { isListEmpty } from "utils/general";
import useButtonWithModalTree from "./hooks/useButtonWithModalTree";
import { messages } from "./messages";
import { ButtonWithModalTreeProps } from "./types";
import styles from "./styles.module.less";

const ButtonWithModalTree: React.FC<ButtonWithModalTreeProps> = ({
  modalTitle,
  openBtnLabel,
  onOpenModal: onOpenModalCallback,
  isButtonDisabled,
  cancelModalBtnLabel,
  confirmModalBtnLabel,
  onConfirmModal,
  defaultCheckedItems = [],
  defaultDisabledItems = [],
  buttonType = "primary",
  treeData: treeDataProp,
}) => {
  const { formatMessage } = useIntl();

  const {
    onCheckHandler,
    newlySelectedItems,
    onConfirmModalHandler,
    isModalVisible,
    onOpenModal,
    onCloseModal,
    isLoading: addToTableIsLoading,
    shouldCallApi,
  } = useButtonWithModalTree({ defaultCheckedItems });

  // treeData should come from parent and the part below should be refactor for the spec
  const { treeData, isLoading } = useHierarchyData({
    defaultDisabledItems: [...defaultCheckedItems, ...defaultDisabledItems],
    isReadyToFetch: shouldCallApi && isListEmpty(treeDataProp),
  });

  // treeData should come from parent and the part below should be refactor for the Library>Material
  const data = isListEmpty(treeDataProp) ? treeData : treeDataProp;

  const { checkedChildren } = useCheckedChildren({
    defaultCheckedParents: defaultCheckedItems,
    treeData: data,
  });

  return (
    <>
      <Button
        type={buttonType}
        disabled={isButtonDisabled}
        loading={isLoading || addToTableIsLoading}
        onClick={onOpenModal({ onOpenModalCallback })}
        data-testid="open-modal-tree"
      >
        {openBtnLabel}
      </Button>
      {isModalVisible && !isListEmpty(data) && (
        <Modal
          width="50%"
          open
          onCancel={onCloseModal}
          footer={
            <Row justify="end" gutter={[20, 0]}>
              <Col>
                <Button
                  type="secondary"
                  data-testid="close-modal-tree"
                  onClick={onCloseModal}
                >
                  {cancelModalBtnLabel || formatMessage(messages.cancelBtn)}
                </Button>
              </Col>
              <Col>
                <Button
                  data-testid="add-to-table-btn"
                  type="primary"
                  onClick={onConfirmModalHandler({
                    onConfirmModal,
                  })}
                  disabled={isListEmpty(newlySelectedItems)}
                  loading={addToTableIsLoading}
                >
                  {confirmModalBtnLabel || formatMessage(messages.confirmBtn)}
                </Button>
              </Col>
            </Row>
          }
          title={modalTitle}
          wrapClassName={styles.modalWrapper}
        >
          <Tree
            treeData={data}
            onCheck={onCheckHandler}
            defaultCheckedKeys={[...defaultCheckedItems, ...checkedChildren]}
            defaultExpandAll
          />
        </Modal>
      )}
    </>
  );
};

export default ButtonWithModalTree;
