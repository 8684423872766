import { useMemo } from "react";
import Section from "./components/Section";
import Title from "./components/Title";
import {
  useCatalogDictionary,
  useChapterAllergens,
  useMaterialData,
} from "hooks";
import { getCatalogIdsFromIngredients } from "dtos/material";
import {
  CHAPTER_ICONS,
  LIBRARY_OBJECT_TYPES,
  RECIPE_TYPES,
} from "utils/constants";
import { ChapterProps } from "./types";
import { getMaterialIdFromAllergenSection } from "utils/general";

const Chapter: React.FC<ChapterProps> = ({ chapter }) => {
  const { sectionName, sections, chapterType, ...chapterData } = chapter;

  const materialId = getMaterialIdFromAllergenSection(sections);

  const {
    materialData,
    recipeIngredients,
    totalPercentageOfRecipeIngredients,
  } = useMaterialData({
    materialId,
    materialType: LIBRARY_OBJECT_TYPES.MADE_IN_HOUSE,
    recipeType: RECIPE_TYPES.MADE_IN_HOUSE,
  });

  const {
    rawMaterialsAllergens,
    crossContaminationAllergens,
  } = useChapterAllergens({ materialData });

  const catalogIds = useMemo(
    () => getCatalogIdsFromIngredients({ ingredients: recipeIngredients }),
    [recipeIngredients]
  );

  useCatalogDictionary({ catalogIds });

  return (
    <>
      <Title
        sectionName={sectionName}
        sectionIcon={CHAPTER_ICONS[chapterType]}
      />
      {sections.map(
        ({ sectionId, sectionName, sectionStatus, blocks, sectionCode }) => (
          <Section
            key={sectionId}
            sectionId={sectionId}
            sectionName={sectionName}
            sectionStatus={sectionStatus}
            blocks={blocks}
            sectionCode={sectionCode}
            chapterData={{
              ...chapterData,
              rawMaterialsAllergens,
              crossContaminationAllergens,
            }}
            materialData={materialData}
            chapterRecipe={{
              recipeIngredients,
              totalPercentageOfRecipeIngredients,
            }}
          />
        )
      )}
    </>
  );
};

export default Chapter;
