import { useIntl } from "react-intl";
import { useContext } from "react";
import { Row } from "antd";
import { Button, EmptyState, toaster } from "@trace-one/design-system";
import { SelectLibraryObjectsContext } from "components/SelectLibraryObjectsModal/contexts";
import SelectedLibraryItem from "../SelectedLibraryItem";
import { useBoolean } from "hooks";
import { useFormSchemaTranslation } from "pages/Specification/components/Details/hooks";
import { isListEmpty } from "utils/general";
import { claimTypeMap } from "utils/claim";
import { ClaimData } from "models";
import { claimMessages } from "messages";
import styles from "./styles.module.less";

const SelectedLibraryItems = () => {
  const { formatMessage } = useIntl();

  const {
    selectedLibraryObjects = [],
    setSelectedLibraryObjects,
    updateClaims,
    onClose,
    formData,
  } = useContext(SelectLibraryObjectsContext);

  const { getTranslationForKey } = useFormSchemaTranslation();

  const { value: isLoading, setTrue, setFalse } = useBoolean();

  const onRemoveClaim = (claimId: ClaimData["id"]) => () => {
    const filteredClaims = selectedLibraryObjects.filter(
      ({ id }) => claimId !== id
    );

    setSelectedLibraryObjects(filteredClaims);
  };

  const onAddClaims = async () => {
    const { nutritionalClaims = [], marketingClaims = [], healthClaims = [] } =
      formData || {};

    let updatedFormData = { nutritionalClaims, healthClaims, marketingClaims };

    selectedLibraryObjects.forEach(({ id, type }) => {
      const newClaim = {
        claimId: id,
        displayOnPack: true,
        supportingDocumentIds: [],
      };

      const claimKey = claimTypeMap[type];

      if (claimKey) {
        updatedFormData[claimKey].push(newClaim);
      }
    });

    try {
      setTrue();

      await updateClaims(updatedFormData);

      toaster.confirmation({
        message: formatMessage(claimMessages.claimsConfirmationToasterTitle),
        description: formatMessage(
          claimMessages.claimsConfirmationToasterDescription
        ),
      });
    } catch (e) {
    } finally {
      setFalse();
      onClose();
    }
  };

  return (
    <div className={styles["selected-library-items--wrapper"]}>
      {isListEmpty(selectedLibraryObjects) ? (
        <Row justify="center" align="middle" className="h-100">
          <EmptyState heading={getTranslationForKey("claimModalEmptyState")} />
        </Row>
      ) : (
        <div className={styles["selected-library-items"]}>
          <div className="hide-scrollbar">
            {selectedLibraryObjects.map(selectedLibraryItem => (
              <SelectedLibraryItem
                selectedLibraryItem={selectedLibraryItem}
                key={selectedLibraryItem.id}
                onRemoveClaim={onRemoveClaim}
              />
            ))}
          </div>
          <Row
            justify="center"
            className={styles["selected-library-items__button"]}
          >
            <Button onClick={onAddClaims} loading={isLoading}>
              {getTranslationForKey("addClaim")}
            </Button>
          </Row>
        </div>
      )}
    </div>
  );
};

export default SelectedLibraryItems;
