import { Collapse } from "@trace-one/react-components";
import { Divider } from "@trace-one/design-system";
import classNames from "classnames";
import { useBoolean } from "hooks";
import CardHeader from "./components/CardHeader";
import RemoveAction from "./components/RemoveAction";
import { LibraryObjectType } from "types/library";
import styles from "./styles.module.less";

export interface ExpandableCardWithToggleAndDeleteProps
  extends Omit<React.HTMLAttributes<HTMLDivElement>, "title"> {
  title: React.ReactNode;
  subtitle?: React.ReactNode;
  description?: React.ReactNode;
  onEntered?: Function;
  onRemove?: Function;
  removeConfirmationText?: string;
  isChecked: boolean;
  onToggle?: Function;
  toggleText?: string;
  toggleTextPlacement?: "left" | "right";
  isTargetMode?: boolean;
  libraryObjectType?: LibraryObjectType;
  isToggleDisabled?: boolean;
  iconColor?: string;
  disableRemoveAction?: boolean;
}

const ExpandableCardWithToggleAndDelete: React.FC<ExpandableCardWithToggleAndDeleteProps> = ({
  title,
  subtitle,
  description,
  onRemove,
  onEntered,
  removeConfirmationText,
  className,
  children,
  isChecked,
  onToggle,
  toggleText,
  toggleTextPlacement,
  isTargetMode,
  libraryObjectType,
  isToggleDisabled,
  iconColor,
  disableRemoveAction,
  ...rest
}) => {
  const { value: isOpen, onToggle: toggleCardExpansion } = useBoolean();

  const iconName = isOpen ? "arrow-down" : "arrow-right";

  return (
    <div className={classNames(styles.expandableCard, className)} {...rest}>
      <div>
        <CardHeader
          iconName={iconName}
          toggleCardExpansion={toggleCardExpansion}
          title={title}
          subtitle={subtitle}
          description={description}
          isChecked={isChecked}
          onToggle={onToggle}
          isTargetMode={isTargetMode}
          libraryObjectType={libraryObjectType}
          toggleText={toggleText}
          toggleTextPlacement={toggleTextPlacement}
          isToggleDisabled={isToggleDisabled}
          isCardExpanded={isOpen}
        />
        <Collapse timeout={150} in={isOpen} onEntered={onEntered}>
          <Divider color="grey-2" height={16} />
          {children}
        </Collapse>
      </div>
      {onRemove && (
        <RemoveAction
          onRemove={onRemove}
          removeConfirmationText={removeConfirmationText}
          className={styles.expandableCardRemoveAction}
          color={iconColor}
          disabled={disableRemoveAction}
        />
      )}
    </div>
  );
};

export default ExpandableCardWithToggleAndDelete;
