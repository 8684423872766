import { FormInstance } from "antd";
import { useFilesInfo, useHealthClaimRegulation } from "hooks";
import { ClaimData } from "models";
import { useEffect } from "react";
import { useAppDispatch } from "store";
import {
  selectClaimForm,
  setClaimFormData,
  setClaimFormFile,
} from "store/claimForm/claimFormSlice";
import { isObjectEmpty } from "utils/general";
import { prepareClaimFormData } from "dtos/claim";
import { useSelector } from "react-redux";
import { isClaimTypeHealth } from "utils/claim";
import { isLibraryObjectEditPage } from "utils/library";
import { LibraryPageType } from "types/library";

const useClaimFormInitialization = ({
  initialValues,
  form,
  page,
}: {
  initialValues: ClaimData;
  form: FormInstance;
  page: LibraryPageType;
}) => {
  const dispatch = useAppDispatch();

  const {
    isLoading,
    files: [imageDetails],
  } = useFilesInfo({ fileIds: [initialValues?.logoFileId] });

  const claimForm = useSelector(selectClaimForm);

  const { regulatoryClaimId } = initialValues || {};

  const { regulation } = useHealthClaimRegulation({
    id: regulatoryClaimId,
  });

  useEffect(() => {
    const prepareClaim = () => {
      if (!isObjectEmpty(initialValues)) {
        if (
          isClaimTypeHealth(initialValues?.claimType) &&
          isLibraryObjectEditPage(page) &&
          !regulation
        ) {
          return;
        }
        const {
          generalInfo = {},
          sentenceTranslations = {},
        } = prepareClaimFormData({
          libraryItemDetails: initialValues,
          file: imageDetails,
          regulation,
        });

        dispatch(
          setClaimFormData({
            ...claimForm,
            generalInfo: generalInfo,
            sentenceTranslations: sentenceTranslations,
            regulation,
          })
        );

        dispatch(
          setClaimFormFile({
            file: {
              id: imageDetails?.id,
              fileName: imageDetails?.name,
              fileSize: imageDetails?.size,
            },
          })
        );

        form?.setFieldsValue({
          ...generalInfo,
          nutrient: regulation?.validationRule[0]?.id,
        });
      }
    };

    prepareClaim();
  }, [imageDetails, initialValues, regulation]);

  return { isLoading };
};

export default useClaimFormInitialization;
