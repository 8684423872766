import dayjs, { Dayjs } from "dayjs";
import utc from "dayjs/plugin/utc";
import duration from "dayjs/plugin/duration";
import weekday from "dayjs/plugin/weekday";
import localeData from "dayjs/plugin/localeData";
import LocalizedFormat from "dayjs/plugin/localizedFormat";
import AdvancedFormat from "dayjs/plugin/advancedFormat";
import updateLocale from "dayjs/plugin/updateLocale";
import { INVALID_DATE_STRING, LANGUAGES } from "./constants";

export type DateType = Dayjs;

export type DateInput = dayjs.ConfigType;
export type OpUnitType = dayjs.OpUnitType;

export const configureLocaleUpdatePlugin = () => dayjs.extend(updateLocale);

export const configureDayjsPlugins = () => {
  dayjs.extend(duration);
  dayjs.extend(utc);
  dayjs.extend(weekday);
  dayjs.extend(localeData);
  dayjs.extend(LocalizedFormat);
  dayjs.extend(AdvancedFormat);
};

export async function setDayjsLocale(languageCode: string) {
  const languageLocaleMap = {
    [LANGUAGES.ENGLISH]: "en",
    [LANGUAGES.FRENCH]: "fr",
    [LANGUAGES.DUTCH]: "nl",
    [LANGUAGES.GERMAN]: "de",
    [LANGUAGES.ITALIAN]: "it",
    [LANGUAGES.PORTUGUESE]: "pt",
    [LANGUAGES.SPANISH]: "es",
  };

  try {
    const dayjsLocale = languageLocaleMap[languageCode];
    await import(`dayjs/locale/${dayjsLocale}.js`);
    dayjs.locale(languageLocaleMap[languageCode] || "en");
  } catch (error) {
    console.error("Error loading locale:", error);
  }
}

export const configureFRLocale = () => {
  configureLocaleUpdatePlugin();

  dayjs.updateLocale("fr", {
    durationLabelsStandard: {
      d: "jour",
      dd: "jours",
      M: "mois",
      MM: "mois",
      y: "année",
      yy: "années",
    },
    invalidDate: "Date non valide",

    // this is not yet supported by dayjs
    durationLabelTypes: [
      { type: "standard", string: "__" },
      { type: "short", string: "_" },
    ],
  });
};

export const getDate = () => dayjs();

export const parseDate = (date: DateInput, format?: string) => {
  if (date !== null || date !== undefined) {
    return dayjs(date);
  }

  return dayjs();
};

export const parseDateWithFormat = (date: DateInput, format?: string) => {
  if (format) {
    return dayjs(date, format);
  }

  return dayjs(date);
};

export const getLocalDate = (date: DateInput) => parseDate(date).local();

export const getUtcDate = () => dayjs().utc();

export const getEndOfDay = () => dayjs().endOf("day");

export const isValidDate = (date: DateInput) => {
  if (date === undefined || date === null) {
    return false;
  }

  return parseDate(date).isValid();
};

export const formatDateToLocal = (date: DateInput) => {
  if (!isValidDate(date)) {
    return INVALID_DATE_STRING;
  }

  return getLocalDate(date).format("L");
};

export const formatDateToDDMMYYYYWithSlash = (
  date: DateInput,
  convertDateToLocal: boolean = true
) => {
  if (!isValidDate(date)) {
    return INVALID_DATE_STRING;
  }

  if (convertDateToLocal) {
    return getLocalDate(date).format("DD/MM/YYYY");
  }

  return parseDate(date).format("DD/MM/YYYY");
};

export const formatDateToDDMMYYYYWithDash = (
  date: DateInput,
  convertDateToLocal: boolean = true
) => {
  if (!isValidDate(date)) {
    return INVALID_DATE_STRING;
  }

  if (convertDateToLocal) {
    return getLocalDate(date).format("DD-MM-YYYY");
  }

  return parseDate(date).format("DD-MM-YYYY");
};

export const formatDateToDDMMMMYYYYWithSpace = (
  date: DateInput,
  convertDateToLocal: boolean = true
) => {
  if (!isValidDate(date)) {
    return INVALID_DATE_STRING;
  }

  if (convertDateToLocal) {
    return getLocalDate(date).format("DD MM YYYY");
  }

  return parseDate(date).format("DD MMMM YYYY");
};

export const formatDateToDDMMYYWithTimeKKMM = (
  date: DateInput,
  convertDateToLocal: boolean = true
) => {
  if (!isValidDate(date)) {
    return INVALID_DATE_STRING;
  }

  if (convertDateToLocal) {
    return getLocalDate(date).format("DD/MM/YY - kk:mm");
  }

  return parseDate(date).format("DD/MM/YY - kk:mm");
};

export const formatDateToMMMDYYYYWithSpace = (
  date: DateInput,
  convertDateToLocal: boolean = true
) => {
  if (!isValidDate(date)) {
    return INVALID_DATE_STRING;
  }

  if (convertDateToLocal) {
    return getLocalDate(date).format("MMM D YYYY");
  }

  return parseDate(date).format("MMM D YYYY");
};

export const formatDateToMMMMDDWithSpace = (date: DateType) => {
  if (!date.isValid()) {
    return INVALID_DATE_STRING;
  }

  return date.format("MMMM DD");
};

export const addDays = (date: DateType, count: number) => {
  if (!isValidDate(date)) return;

  return date.add(count, "days");
};

export const subtractDays = (date: DateType, count: number) => {
  if (!isValidDate(date)) return;

  return date.subtract(count, "days");
};

export const isSameDate = (
  date1: DateType,
  date2: DateType,
  unit: OpUnitType = "milliseconds"
) => date1.isSame(date2, unit);

export const getDifferenceBetweenDates = (
  date1: DateType,
  date2: DateType,
  unit: OpUnitType = "milliseconds"
) => date1.diff(date2, unit);

export const getDuration = (time: number) => dayjs.duration(time);
