import { useContext } from "react";
import { Button } from "@trace-one/design-system";
import { LibraryContext } from "components/Library/components/List/contexts";
import { useLibraryItemActions } from "hooks";
import { getActionsListForLibraryItem } from "components/Library/components/List/utils";
import { prepareLibraryItemActionItem } from "dtos/libraryItem";
import { isListEmpty } from "utils/general";
import { ActionsProps } from "./types";
import {
  isMaterialAdditiveType,
  isMaterialProcessingAidType,
} from "utils/library";

const Actions: React.FC<ActionsProps> = ({ libraryItem, disabled }) => {
  const { refreshTypeList } = useContext(LibraryContext);

  const actions = useLibraryItemActions({
    libraryItemInfo: prepareLibraryItemActionItem(libraryItem),
    refetchLibraryItems: refreshTypeList,
  });

  const items = getActionsListForLibraryItem({
    actions,
    type: libraryItem?.type,
    state: libraryItem?.state,
    lastVersion: libraryItem?.lastVersion,
  });
  return (
    <Button
      items={items}
      color="grey"
      type="tertiary"
      iconName="kebab"
      size="small"
      disabled={
        disabled ||
        isMaterialAdditiveType(libraryItem?.type) ||
        isMaterialProcessingAidType(libraryItem?.type) ||
        isListEmpty(items)
      }
    />
  );
};

export default Actions;
