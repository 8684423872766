import { createContext } from "react";
import {
  SelectSectionValidationErrors,
  SetSectionBlockValidationError,
} from "hooks";
import {
  ChapterAllergenData,
  MaterialData,
  MaterialIngredientData,
  MaterialRecipeData,
  TradeItemData,
} from "models";
import { Sorter } from "types/pagination";
import { LibraryPageType } from "types/library";

const SelectedSectionContext = createContext<{
  validateHandler: Function;
  isSectionValidationLoading: Function;
  selectSectionValidationErrors: SelectSectionValidationErrors;
  resetSectionValidationErrors: Function;
  setSectionBlockValidationError: SetSectionBlockValidationError;
  fetchRecipeIngredients?: () => Promise<MaterialIngredientData[]>;
  sortRecipeIngredient?: (sort: Sorter) => Promise<void>;
  recipeIngredients?: MaterialIngredientData[];
  rawMaterialsAllergens?: ChapterAllergenData[];
  crossContaminationAllergens?: ChapterAllergenData[];
  chapterState?: string;
  page?: LibraryPageType;
  recipeSorter?: Sorter;
  totalPercentageOfRecipeIngredients?: MaterialRecipeData["totalPercentage"];
  retailerCompnayId?: string;
  supplierCompnayId?: string;
  translatorCompanyId?: string;
  translationId?: string;
  isTranslationContact?: boolean;
  getTranslation?: Function;
  isTranslator?: boolean;
  getLabelingTranslationLanguages?: Function;
  allTranslationsCompleted?: boolean;
  productData?: TradeItemData;
  materialData?: MaterialData;
  getMaterialData?: () => Promise<void>;
}>({
  validateHandler: () => {},
  isSectionValidationLoading: () => {},
  setSectionBlockValidationError: (sectionId: string) => (blockId: string) => (
    errors: []
  ) => {},
  resetSectionValidationErrors: () => {},
  selectSectionValidationErrors: (sectionId: string) => {
    return {};
  },
});

export default SelectedSectionContext;
