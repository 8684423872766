import { useAppDispatch } from "store";
import {
  removeIngredient,
  updateIngredient,
} from "store/materialForm/asyncActions";
import { useEffect, useState } from "react";
import { addNewIngredientLine } from "store/materialForm/materialFormSlice";
import { isObjectEmpty } from "utils/general";
import { MaterialRecipeIngredientViewModel } from "viewModels";
import { Sorter } from "types/pagination";

const useRecipeTableActions = ({
  sorter,
  sortRecipeIngredients,
}: {
  sorter?: Sorter;
  sortRecipeIngredients?: Function;
}) => {
  const [isPanelOpen, setIsPanelOpen] = useState<boolean>(false);
  const [
    ingredient,
    setIngredient,
  ] = useState<MaterialRecipeIngredientViewModel>({});
  const dispatch = useAppDispatch();

  const onRemoveIngredient = (
    ingredientId: MaterialRecipeIngredientViewModel["ingredientId"],
    parentIdsPath: MaterialRecipeIngredientViewModel["parentIdsPath"]
  ) => async () => {
    await dispatch(removeIngredient({ ingredientId, parentIdsPath }));
  };

  const onUpdateIngredientPercentage = (
    ingredient: MaterialRecipeIngredientViewModel
  ) => async (percentage: number) => {
    await dispatch(
      updateIngredient({
        ingredientId: ingredient.ingredientId,
        name: ingredient.name,
        classificationId: ingredient.classificationId,
        percentage,
      })
    );
  };

  const onPanelOpen = (ingredient?: MaterialRecipeIngredientViewModel) => {
    if (isObjectEmpty(ingredient)) {
      dispatch(addNewIngredientLine({}));
    }

    setIngredient(ingredient);

    setIsPanelOpen(true);
  };

  const onPanelClose = () => {
    setIngredient({});
    setIsPanelOpen(false);
  };

  const onCell = (ingredient: MaterialRecipeIngredientViewModel) => ({
    onClick: () => {
      onPanelOpen(ingredient);
    },
  });

  useEffect(() => {
    if (sorter?.orderBy) {
      sortRecipeIngredients?.({ ...sorter });
    }
  }, [sorter]);

  return {
    onRemoveIngredient,
    onUpdateIngredientPercentage,
    onPanelOpen,
    onPanelClose,
    onCell,
    isPanelOpen,
    ingredient,
  };
};

export default useRecipeTableActions;
