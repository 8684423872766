import { defineMessages } from "react-intl";

export const certificationMessages = defineMessages({
  certificatesLabel: {
    id: "certificates.label",
    defaultMessage: "Certificates",
  },
  addCertificate: {
    id: "certificates.addCertificate",
    defaultMessage: "Add a certificate",
  },
  noCertificateAdded: {
    id: "certificates.noCertificateAdded",
    defaultMessage: "No certificate added",
  },
  endDate: {
    id: "certificates.certification.endDate",
    defaultMessage: "End date",
  },
});
