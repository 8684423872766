import { useIntl } from "react-intl";
import { useParams, useRouteMatch } from "react-router-dom";
import { PageHeader } from "@trace-one/design-system";
import { isLibraryObjectClaimType, isLibraryObjectDraft } from "utils/library";
import { getNameWithVersion } from "utils/general";
import { useGoBack } from "hooks";
import { usePageHeadingInformation } from "pages/Library/hooks";
import { withConfirmEditModal } from "./hocs";
import Content from "./components/Content";
import {
  getPrimaryButtonLabel,
  getStateToDisplay,
  getVersionNumber,
} from "./utils";
import { LibraryEditionProps } from "./types";
import { ParamTypes, goBackParams } from "../types";
import libraryMessages from "messages/library";
import styles from "./styles.module.less";
import { ROUTES } from "utils/constants";

export const Edition: React.FC<LibraryEditionProps> = ({
  libraryItemDetails,
}) => {
  const { formatMessage } = useIntl();
  const { type } = useParams<ParamTypes>();
  const { goBack } = useGoBack(goBackParams[type]);

  const isNewVersionPage = useRouteMatch(ROUTES.LIBRARY.CREATE_NEW_VERSION)
    ?.isExact;

  const primaryButtonLabel = getPrimaryButtonLabel({
    type,
    state: libraryItemDetails?.generalInfo?.state,
    formatMessage,
    isNewVersionPage,
  });

  const versionNumber = getVersionNumber({
    type,
    versionNumber: libraryItemDetails.versionNumber,
    isNewVersionPage,
  });

  const stateToDisplay = getStateToDisplay({
    type,
    state: libraryItemDetails?.generalInformation?.state,
    isNewVersionPage,
  });

  const {
    tabsInformation,
    actionsInformation,
    secondaryActionInformation,
    headerInformation: { tag, title },
    updateTabsInformation,
    updateActionsInformation,
    updateHeaderInformation,
    updateSecondaryActionInformation,
  } = usePageHeadingInformation(
    stateToDisplay,
    libraryItemDetails?.generalInformation?.name
  );

  const isClaimObject = isLibraryObjectClaimType(type);

  return (
    <>
      <PageHeader
        onBack={goBack}
        title={getNameWithVersion(title, versionNumber)}
        tag={tag}
        primaryButtonLabel={primaryButtonLabel}
        onPrimaryButtonClick={actionsInformation.onClick}
        primaryButtonProps={actionsInformation.props}
        tabs={tabsInformation}
        {...(isClaimObject &&
          isLibraryObjectDraft(libraryItemDetails?.state) && {
            secondaryButtonLabel: formatMessage(libraryMessages.publishButton),
            onSecondaryButtonClick: secondaryActionInformation.onClick,
            secondaryButtonProps: secondaryActionInformation.props,
          })}
      />
      <div className={styles.pageBody}>
        <Content
          libraryItemDetails={libraryItemDetails}
          updateActionsInformation={updateActionsInformation}
          updateTabsInformation={updateTabsInformation}
          updateHeaderInformation={updateHeaderInformation}
          updateSecondaryActionInformation={updateSecondaryActionInformation}
        />
      </div>
    </>
  );
};

export default withConfirmEditModal(Edition);
