import { useMemo } from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { Datatable } from "@trace-one/design-system";
import LibraryItemInfo from "components/Library/components/LibraryItemInfo";
import TableHeader from "components/TableHeader";
import { selectCompanyActivity } from "store/oidc/selectors";
import { useLinkedSpecifications, usePagination, useRedirect } from "hooks";
import {
  getNumberPageDisplayed,
  getRowKeyForTable,
  shouldPaginationBeDisplay,
} from "utils/table";
import { getProductOrigin } from "pages/Specification/utils";
import { getColumns } from "./utils";
import { SpecificationListItemData } from "models";
import { LinkedSpecificationsProps } from "./types";
import { specificationMessages } from "messages";
import styles from "./styles.module.less";

const LinkedSpecifications = ({
  libraryItemId,
  libraryItemType,
  hideLibraryItemInfo = false,
}: LinkedSpecificationsProps) => {
  const { formatMessage } = useIntl();
  const productOrigin = getProductOrigin({
    companyActivity: useSelector(selectCompanyActivity),
  });
  const { pagination, sorter, onTableChange } = usePagination();
  const { redirectToSpecificationDetails } = useRedirect();
  const rowKey = useMemo(() => getRowKeyForTable("specification"), []);

  const { specifications, totalCount, isLoading } = useLinkedSpecifications({
    libraryItemType,
    libraryItemId,
    sorter,
    pagination,
  });

  const onRowClick = (row: SpecificationListItemData) => {
    redirectToSpecificationDetails(row.specificationId);
  };

  const { current, total } = useMemo(
    () => ({
      current: getNumberPageDisplayed({
        totalNumber: totalCount,
        current: pagination.current,
        pageSize: pagination.pageSize,
      }),
      total: totalCount,
    }),
    [specifications, pagination.current, pagination.pageSize, totalCount]
  );

  return (
    <>
      <>
        {!hideLibraryItemInfo && (
          <div className="mb-3">
            <LibraryItemInfo />
          </div>
        )}

        <TableHeader
          listResult={formatMessage(specificationMessages.listingResultNumber, {
            current,
            total,
          })}
        />
      </>

      <Datatable
        loading={isLoading}
        rowClassName={styles.row}
        onChange={onTableChange}
        pagination={{
          ...pagination,
          total: totalCount,
          hideOnSinglePage: !shouldPaginationBeDisplay(totalCount),
        }}
        columns={getColumns({
          formatMessage,
          productOrigin,
        })}
        dataSource={specifications}
        rowKey={rowKey}
        onRow={(record: SpecificationListItemData) => {
          return {
            onClick: () => onRowClick(record),
          };
        }}
      />
    </>
  );
};

export default LinkedSpecifications;
