import { defineMessages } from "react-intl";

export const messages = defineMessages({
  alertMessage: {
    id: "library.chapterForm.alerts.warning.message",
    defaultMessage: "Modification are automatically saved.",
  },
  alertDescription: {
    id: "library.chapterForm.alerts.warning.description",
    defaultMessage:
      "Modifications are automatically saved and applied if the chapter is linked to specifications.",
  },
  chapterImpactSpecificationModalConfirmButton: {
    id: "library.chapterForm.impactSpecificationModal.confirmButton",
    defaultMessage: "Confirm and edit",
  },
});
