import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { fetchMaterial, sortMaterialRecipe } from "apis/SPEC";
import { selectLanguageCode } from "store/user/selectors";
import useCatalogDictionary from "hooks/useCatalogDictionary";
import useBoolean from "hooks/useBoolean";
import { isListEmpty } from "utils/general";
import {
  MaterialData,
  MaterialIngredientData,
  MaterialRecipeData,
} from "models";
import { LibraryItemApiType, RecipeType } from "types/library";
import { Sorter } from "types/pagination";
import { getMaterialApiType } from "utils/library";
import { MATERIAL_TYPES } from "utils/constants";
import { prepareMaterialRecipe } from "dtos/material";

export type UseMaterialDataProps = {
  materialId: MaterialData["id"];
  materialType?: LibraryItemApiType;
  recipeType?: RecipeType;
  fetchOnEffect?: boolean;
};

const useMaterialData = ({
  materialId,
  materialType,
  recipeType,
  fetchOnEffect = true,
}: UseMaterialDataProps) => {
  const languageCode = useSelector(selectLanguageCode);
  const [materialData, setMaterialData] = useState<MaterialData>(null);
  const [catalogAllergenIds, setCatalogAllergenIds] = useState<string[]>([]);
  const { value: loadingMaterialData, setTrue, setFalse } = useBoolean();
  const [recipeIngredients, setRecipeIngredients] = useState<
    MaterialIngredientData[]
  >([]);
  const [recipeSorter, setRecipeSorter] = useState<Sorter>(null);
  const [
    totalPercentageOfRecipeIngredients,
    setTotalPercentageOfRecipeIngredients,
  ] = useState<MaterialRecipeData["totalPercentage"]>(null);

  useCatalogDictionary({ catalogAllergenIds });

  const getMaterialData = async () => {
    try {
      setTrue();

      const { data } = await fetchMaterial({
        materialId,
        materialType,
        languageCode,
      });

      const recipe = prepareMaterialRecipe(data?.recipe);

      setMaterialData(data);
      setRecipeIngredients(recipe?.ingredients);
      setRecipeSorter(recipe?.sortingFilter);
      setTotalPercentageOfRecipeIngredients(recipe?.totalPercentage);
    } catch {
    } finally {
      setFalse();
    }
  };

  const sortMaterialRecipeIngredients = async (sort: Sorter) => {
    if (!recipeType) {
      return;
    }

    try {
      setTrue();

      const { data } = await sortMaterialRecipe({
        materialId,
        materialType: getMaterialApiType({
          materialType: MATERIAL_TYPES.COMPOSITE,
          recipeType,
        }),
        ...sort,
      });

      const recipe = prepareMaterialRecipe(data);

      setRecipeIngredients(recipe?.ingredients);
      setRecipeSorter(recipe?.sortingFilter);
      setTotalPercentageOfRecipeIngredients(recipe?.totalPercentage);
    } catch {
    } finally {
      setFalse();
    }
  };

  useEffect(() => {
    if (!materialId || !materialType || !fetchOnEffect) return;

    getMaterialData();
  }, [materialId, materialType, fetchOnEffect]);

  useEffect(() => {
    if (isListEmpty(materialData?.allergenDeclarations)) return;

    setCatalogAllergenIds(
      materialData.allergenDeclarations.map(({ allergenId }) => allergenId)
    );
  }, [materialData?.allergenDeclarations?.length]);

  return {
    materialData,
    getMaterialData,
    loadingMaterialData,
    recipeIngredients,
    recipeSorter,
    totalPercentageOfRecipeIngredients,
    sortMaterialRecipeIngredients,
  };
};

export default useMaterialData;
