import { useMemo } from "react";
import { Row, Col } from "antd";
import { Button, EmptyState } from "@trace-one/design-system";
import { FormattedMessage, useIntl } from "react-intl";
import { isListEmpty } from "utils/general";
import { isMaterialCompositeType } from "utils/library";
import FormCard from "../FormCard";
import SuppliersList from "./components/SuppliersList";
import { useMaterialPermissions } from "hooks";
import { useActions } from "./hooks";
import { isAddButtonDisabled } from "./utils";
import messages from "./messages";
import { SuppliersProps } from "./types";
import styles from "components/Library/components/MaterialForm/styles.module.less";

const Suppliers: React.FC<SuppliersProps> = ({
  displayActions,
  suppliers,
  refListDictionary,
  materialType,
  materialId,
  materialIngredients,
}) => {
  const { formatMessage } = useIntl();
  const {
    suppliersList,
    onAddSupplier,
    onRemoveSupplier,
    onChangeSupplierName,
    onChangeSupplierCode,
  } = useActions({ suppliers });
  const { disableActions } = useMaterialPermissions();

  const isMaterialComposite = useMemo(
    () => isMaterialCompositeType(materialType),
    [materialType]
  );

  return (
    <FormCard
      title={formatMessage(messages.rawMaterialSupplierDetailsCardTitle)}
      name="suppliers"
    >
      <div className={styles.rawMaterialFormSuppliersDetailWrapper}>
        {isListEmpty(suppliersList) ? (
          <Row justify="center">
            <EmptyState heading={formatMessage(messages["noSupplier"])} />
          </Row>
        ) : (
          <SuppliersList
            displayActions={displayActions}
            suppliers={suppliersList}
            onRemoveSupplier={onRemoveSupplier}
            onChangeSupplierName={onChangeSupplierName}
            onChangeSupplierCode={onChangeSupplierCode}
            refListDictionary={refListDictionary}
            materialType={materialType}
            materialId={materialId}
            materialIngredients={materialIngredients}
          />
        )}
        {displayActions && (
          <Row justify="center" className={styles.rowAddButton}>
            <Col>
              <Button
                onClick={onAddSupplier}
                data-test-id="add-supplier-btn"
                disabled={isAddButtonDisabled({
                  isMaterialComposite,
                  disableActions,
                  suppliers: suppliersList,
                })}
              >
                <FormattedMessage
                  {...messages.rawMaterialSupplierDetailsAddSupplier}
                />
              </Button>
            </Col>
          </Row>
        )}
      </div>
    </FormCard>
  );
};

Suppliers.defaultProps = {
  displayActions: true,
  refListDictionary: {},
};

export default Suppliers;
