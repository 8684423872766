import { Datatable } from "@trace-one/design-system";
import LinkedSpecificationsModal from "components/LinkedSpecificationsModal";
import { LibraryItemQuickView } from "components/Library/components";
import { useChapterDetails } from "hooks";
import { useVersionHistory, useVersionHistoryActions } from "./hooks";
import { useVersionHistoryColumns } from "components/Library/hooks";
import { VersionHistoryProps } from "./types";
import { CHAPTER_TYPES_API } from "utils/constants";
import { isStateDraft } from "utils/specifications";
import { LibraryItemVersionData } from "models";

const VersionHistory = ({
  chapterId,
  chapterType,
  linkedSpecificationsCallback,
}: VersionHistoryProps) => {
  const chapterTypeApi = CHAPTER_TYPES_API[chapterType];

  const {
    versionHistoryList,
    loadingVersions,
    isDetailsAndLinksModalOpen,
    modalProps,
    handleOpenModal,
    handleCloseModal,
    rowSelection,
    actionType,
    selectedChapterId,
    activeChapterId,
    shouldRefetchVersionHistory,
    setShouldRefetchVersionHistory,
    states,
  } = useVersionHistory({
    chapterType: chapterTypeApi,
    chapterId,
  });

  const { chapterInfo } = useChapterDetails({
    chapterId: selectedChapterId || chapterId,
    chapterType: chapterType,
    fetchOnEffect: isDetailsAndLinksModalOpen,
  });

  const { handlePrimaryButtonClick } = useVersionHistoryActions({
    chapterId: activeChapterId,
    chapterTypeApi,
    selectedRowKeys: rowSelection?.selectedRowKeys,
    actionType,
    handleCloseModal,
    setShouldRefetchVersionHistory,
    linkedSpecificationsCallback,
  });

  const { columns } = useVersionHistoryColumns({
    libraryItemType: CHAPTER_TYPES_API[chapterType],
    handleOpenModal,
  });

  const expandedRowRender = ({ id }: LibraryItemVersionData) => (
    <LibraryItemQuickView
      libraryItemId={id}
      libraryItemType={chapterTypeApi}
      key={id}
      shouldRefetch={shouldRefetchVersionHistory}
    />
  );

  const rowExpandable = ({ state }: LibraryItemVersionData) =>
    !isStateDraft(state);

  return (
    <>
      <Datatable
        loading={loadingVersions}
        rowClassName="cursor-pointer"
        columns={columns}
        pagination={{
          hideOnSinglePage: true,
        }}
        expandable={{
          expandedRowRender,
          rowExpandable,
        }}
        dataSource={versionHistoryList}
        rowKey={({ id }: LibraryItemVersionData) => id}
      />
      {isDetailsAndLinksModalOpen && (
        <LinkedSpecificationsModal
          onCloseModal={handleCloseModal}
          libraryItemId={chapterId}
          selectedLibraryItemId={selectedChapterId}
          libraryItemType={chapterType}
          libraryItemName={chapterInfo?.name}
          libraryItemState={chapterInfo?.state}
          libraryItemCreationDateUtc={chapterInfo?.creationDateUtc}
          versionNumber={chapterInfo?.version?.number}
          rowSelection={rowSelection}
          states={states}
          title={modalProps.title}
          subtitle={modalProps.subtitle}
          primaryButtonLabel={modalProps.primaryButtonLabel}
          onPrimaryButtonClick={handlePrimaryButtonClick}
          secondaryButtonLabel={modalProps.secondaryButtonLabel}
          onSecondaryButtonClick={modalProps.onSecondaryButtonClick}
          actionType={actionType}
        />
      )}
    </>
  );
};

export default VersionHistory;
