import { toaster as toasterService } from "@trace-one/design-system";
import { useIntl } from "react-intl";
import {
  linkChapterToSpecification,
  reviseChapterSpecification,
  unlinkChapterToSpecification,
} from "apis/SPEC";
import { CHAPTER_TYPES_URL_API } from "utils/constants";
import { versionHistoryMessages } from "messages";
import { UseVersionHistoryActionsProps } from "./types";
import { ModalActionType } from "components/LinkedSpecificationsModal/types";

const useVersionHistoryActions = ({
  chapterId,
  chapterTypeApi,
  selectedRowKeys,
  actionType,
  handleCloseModal,
  setShouldRefetchVersionHistory,
  linkedSpecificationsCallback,
}: UseVersionHistoryActionsProps) => {
  const { formatMessage } = useIntl();

  const onReviseSpecifications = async () => {
    try {
      for (let specificationId of selectedRowKeys) {
        await reviseChapterSpecification({
          specificationId,
          chapterId,
          chapterType: CHAPTER_TYPES_URL_API[chapterTypeApi],
        });
      }

      setShouldRefetchVersionHistory(true);

      toasterService.confirmation({
        message: formatMessage(versionHistoryMessages.reviseToasterMessage),
        description: formatMessage(
          versionHistoryMessages.reviseToasterDescription
        ),
      });

      await linkedSpecificationsCallback?.();
    } catch {
    } finally {
      handleCloseModal();
      setShouldRefetchVersionHistory(false);
    }
  };

  const onUnlinkSpecifications = async () => {
    try {
      for (let specificationId of selectedRowKeys) {
        await unlinkChapterToSpecification({
          specificationId,
          chapterType: CHAPTER_TYPES_URL_API[chapterTypeApi],
        });
      }

      setShouldRefetchVersionHistory(true);

      toasterService.confirmation({
        message: formatMessage(
          versionHistoryMessages.unlinkSpecificationsToasterMessage
        ),
        description: formatMessage(
          versionHistoryMessages.unlinkSpecificationsToasterDescription
        ),
      });
      await linkedSpecificationsCallback?.();
    } catch {
    } finally {
      handleCloseModal();
      setShouldRefetchVersionHistory(false);
    }
  };

  const onLinkSpecifications = async () => {
    try {
      for (let specificationId of selectedRowKeys) {
        await linkChapterToSpecification({
          specificationId,
          chapterId,
          chapterType: CHAPTER_TYPES_URL_API[chapterTypeApi],
        });
      }

      setShouldRefetchVersionHistory(true);

      toasterService.confirmation({
        message: formatMessage(
          versionHistoryMessages.updateToLatestVersionToasterMessage
        ),
        description: formatMessage(
          versionHistoryMessages.updateToLatestVersionToasterDescription
        ),
      });
      await linkedSpecificationsCallback?.();
    } catch {
    } finally {
      handleCloseModal();
      setShouldRefetchVersionHistory(false);
    }
  };

  const primaryButtonActions = {
    [ModalActionType.UPDATE]: onLinkSpecifications,
    [ModalActionType.UNLINK]: onUnlinkSpecifications,
    [ModalActionType.REVISE]: onReviseSpecifications,
  };

  const handlePrimaryButtonClick = primaryButtonActions[actionType];

  return {
    handlePrimaryButtonClick,
  };
};

export default useVersionHistoryActions;
