import { Button, Modal, Paragraph } from "@trace-one/design-system";
import { useIntl, FormattedMessage } from "react-intl";
import NutritionBasic from "components/SpecificationSection/components/FormSchema/components/NutritionBasic";
import NutritionServingForm from "./components/NutritionServingForm";
import { getNutritionFamilyStatus } from "components/SpecificationSection/components/FormSchema/components/NutritionBasic/utils";
import { useNutritionTable } from "components/SpecificationSection/components/FormSchema/components/NutritionBasic/shared/hooks";
import { useNutritionColumns, useNutritionServingForm } from "./hooks";
import { NutritionDeclarationWidgetProps } from "./types";
import { messages } from "./messages";
import ConfirmationModal from "components/ConfirmationModal";
import generalMessages from "messages/general";
import useRemoveServing from "./hooks/useRemoveServing";
import styles from "./styles.module.less";
import { getNutritionDeclarationFormData } from "dtos/nutrition";

const NutritionDeclarationWidget: React.FC<NutritionDeclarationWidgetProps> = ({
  formData: defaultFormData,
  formContext,
  schema,
  uiSchema,
  properties,
}) => {
  const { formatMessage } = useIntl();

  const {
    saveTemplateBlock,
    displayActions,
    extraActions,
    extraErrors,
    specificationData,
    isTargetMode,
    readOnlyFieldIds,
    hasPermissionToSave: hasUpdatePermission,
    isTranslator,
  } = formContext;

  const servingDefinitionsPropertyId =
    schema?.properties?.servingDefinitions?.propertyId;
  const hasAddServingPermission = !readOnlyFieldIds?.includes(
    servingDefinitionsPropertyId
  );

  const formData = getNutritionDeclarationFormData(defaultFormData);

  const {
    dataSource,
    nutrientDictionary,
    mandatoryNutrientIds,
  } = useNutritionTable({
    formData,
  });

  const {
    isModalOpen,
    setIsModalOpen,
    nutritionServingFormData,
    setNutritionServingFormData,
    setFormErrors,
    handleServingNameChange,
    handleServingSizeChange,
    handleAutomaticCalculationChange,
    getIsPrimaryModalButtonDisabled,
    onPrimaryButtonClick,
    onSecondaryButtonClick,
    isServingAddingOrUpdating,
    selectedUnit,
    onClearUnit,
    items,
    buttonProps,
    isCalculationToggleDisabled,
    onEditServing,
    isEditMode,
  } = useNutritionServingForm({
    extraActions,
    hasUpdatePermission,
    schema,
    saveTemplateBlock,
    formData,
    isTranslator,
  });

  const {
    showRemoveServingModal,
    onCloseRemoveServing,
    onConfirmRemoveServing,
    onRemoveServing,
  } = useRemoveServing({
    onDeleteNutritionServing: extraActions?.updateNutritionDeclarationAction,
    formData,
  });

  const { columns } = useNutritionColumns({
    formData,
    formContext,
    uiSchema,
    schema,
    errorSchema: extraErrors,
    statusNutrientFamilyDictionary: getNutritionFamilyStatus(dataSource),
    nutrientDictionary,
    mandatoryNutrientIds,
    saveTemplateBlock,
    extraActions,
    displayActions,
    specificationId: specificationData?.specificationId,
    onRemoveServing,
    onEditServing,
    hasUpdatePermission,
    hasAddServingPermission,
  });

  return (
    <>
      <Button
        onClick={() => setIsModalOpen(true)}
        data-test-id="add-nutrition-declaration-serving"
        disabled={
          !hasUpdatePermission || isTargetMode || !hasAddServingPermission
        }
      >
        {formatMessage(messages.addServing)}
      </Button>
      <Modal.Simple
        title={formatMessage(
          isEditMode ? messages.editServing : messages.modalTitle
        )}
        open={isModalOpen}
        onCancel={onSecondaryButtonClick}
        primaryButtonText={formatMessage(messages.modalPrimaryButton)}
        secondaryButtonText={formatMessage(messages.modalSecondaryButton)}
        onPrimaryButtonClick={onPrimaryButtonClick}
        onSecondaryButtonClick={onSecondaryButtonClick}
        primaryButtonProps={{
          disabled: getIsPrimaryModalButtonDisabled(),
          loading: isServingAddingOrUpdating,
        }}
        secondaryButtonProps={{ disabled: isServingAddingOrUpdating }}
      >
        {!isEditMode && (
          <Paragraph className={styles.modalSubtitle}>
            <FormattedMessage {...messages.modalSubtitle} />
          </Paragraph>
        )}
        <NutritionServingForm
          nutritionServingFormData={nutritionServingFormData}
          setNutritionServingFormData={setNutritionServingFormData}
          setFormErrors={setFormErrors}
          selectedUnit={selectedUnit}
          onClearUnit={onClearUnit}
          items={items}
          buttonProps={buttonProps}
          handleServingNameChange={handleServingNameChange}
          handleServingSizeChange={handleServingSizeChange}
          handleAutomaticCalculationChange={handleAutomaticCalculationChange}
          isCalculationToggleDisabled={isCalculationToggleDisabled}
          isEditMode={isEditMode}
          schema={schema}
          readOnlyFieldIds={readOnlyFieldIds}
        />
      </Modal.Simple>
      <NutritionBasic
        formContext={formContext}
        schema={schema}
        columns={columns}
        dataSource={dataSource}
      />

      {showRemoveServingModal && (
        <ConfirmationModal
          onCloseModal={() => onCloseRemoveServing(false)}
          onConfirmModal={onConfirmRemoveServing}
          title={formatMessage(messages.removeServing)}
          primaryButtonText={formatMessage(generalMessages.confirm)}
          illustrationName="delete"
        >
          {formatMessage(messages.removeServingDescription)}
        </ConfirmationModal>
      )}
    </>
  );
};

export default NutritionDeclarationWidget;
