import { MaterialData, MaterialIngredientData } from "models";
import { isListEmpty, isObjectEmpty, isUuidEmpty } from "./general";
import { isMaterialRawMaterialType } from "./library";
import {
  MaterialRecipeIngredientViewModel,
  MaterialSupplierViewModel,
} from "viewModels";
import { ALLERGEN_DECLARATION_TYPE, RECIPE_TYPES } from "./constants";

export type AddIngredientToMapParams = {
  ingredient: MaterialIngredientData;
  parentMaterialId?: MaterialIngredientData["materialId"];
};

export type GetRecipeTypeFromIngredientMapParams = {
  type: MaterialIngredientData["type"];
  materialId: MaterialIngredientData["materialId"];
};

export const getTotalPercentageRootIngredients = (
  ingredients: MaterialRecipeIngredientViewModel[]
) =>
  ingredients?.reduce((acc, curr) => {
    if (curr.firstLevel) {
      acc += curr.percentage;
    }
    return acc;
  }, 0);

export const getLastParentIngredients = (
  ingredients: MaterialRecipeIngredientViewModel[],
  parentIdsPath: string[] = []
) => {
  const parentIngredients: MaterialRecipeIngredientViewModel[] = parentIdsPath?.reduce(
    (previousData, currentElement) => {
      const parentIngredient = previousData.find(
        ({ ingredientId }) => ingredientId === currentElement
      );

      return parentIngredient?.ingredients || [];
    },
    ingredients
  );

  return parentIngredients;
};

export const getRefListIdsFromSuppliers = (
  suppliers: MaterialSupplierViewModel[] = []
) => {
  let refListIds = [];

  suppliers?.forEach(({ origins }) => {
    if (!isListEmpty(origins)) {
      origins.forEach(origin => {
        refListIds.push.apply(
          refListIds,
          Object.values(origin).filter(value => !isUuidEmpty(value as string))
        );
      });
    }
  });

  return [...new Set(refListIds)];
};

export const getRawMaterialAllergensAndCrossContamination = ({
  materialData,
}: {
  materialData: MaterialData;
}) => {
  if (isObjectEmpty(materialData)) {
    return {
      crossContaminationAllergens: [],
      rawMaterialsAllergens: [],
    };
  }

  const getRecipeTypeForIngredientMap = ({
    type,
    materialId,
  }: GetRecipeTypeFromIngredientMapParams) => {
    if (isMaterialRawMaterialType(type)) {
      if (materialId) {
        return null;
      }

      return RECIPE_TYPES.BOUGHT_TO_THIRD_PARTY;
    }
  };

  const { allergenDeclarations, recipe } = materialData;

  const crossContaminationAllergens = allergenDeclarations.filter(
    ({ declaration }) =>
      declaration === ALLERGEN_DECLARATION_TYPE.UNINTENTIONAL_PRESENT
  );

  const rawMaterialsAllergens = allergenDeclarations.filter(
    ({ declaration }) => declaration === ALLERGEN_DECLARATION_TYPE.PRESENT
  );

  const ingredientMap = new Map();

  const addIngredientToMap = ({
    ingredient,
    parentMaterialId = null,
  }: AddIngredientToMapParams) => {
    if (
      ingredient.ingredientId &&
      !ingredientMap.has(ingredient.ingredientId)
    ) {
      const materialId = ingredient.materialId || parentMaterialId;
      ingredientMap.set(ingredient.ingredientId, {
        ingredientId: ingredient.ingredientId,
        type: ingredient.type,
        materialName: materialId ? ingredient.name : null,
        ingredientName: ingredient.name,
        materialId: materialId,
        recipeType: getRecipeTypeForIngredientMap({
          type: ingredient.type,
          materialId: ingredient.materialId,
        }),
      });
    }
    if (!isListEmpty(ingredient.ingredients)) {
      const currentMaterialId = ingredient.materialId || parentMaterialId;
      ingredient.ingredients.forEach(subIngredient =>
        addIngredientToMap({
          ingredient: subIngredient,
          parentMaterialId: currentMaterialId,
        })
      );
    }
  };

  recipe.ingredients.forEach(ingredient => addIngredientToMap({ ingredient }));

  const crossContaminationAllergensWithIngredients = crossContaminationAllergens.map(
    allergen => {
      const ingredients = allergen.ingredientIds.map(ingredientId =>
        ingredientMap.get(ingredientId)
      );
      return {
        ...allergen,
        ingredients,
      };
    }
  );

  const rawMaterialsAllergensWithIngredients = rawMaterialsAllergens.map(
    allergen => {
      const ingredients = allergen.ingredientIds.map(ingredientId =>
        ingredientMap.get(ingredientId)
      );
      return {
        ...allergen,
        ingredients,
      };
    }
  );

  return {
    crossContaminationAllergens: crossContaminationAllergensWithIngredients,
    rawMaterialsAllergens: rawMaterialsAllergensWithIngredients,
  };
};
