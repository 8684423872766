import { useIntl } from "react-intl";
import { Toggle, Tooltip } from "@trace-one/design-system";
import ConfirmationTooltip from "components/ConfirmationTooltip";
import ConditionalWrapper from "components/ConditionalWrapper";
import { useTranslationCompleted } from "components/TranslationManagement/hooks";
import { LabelingTranslationLaguageData } from "models";
import { translationManagementMessages } from "messages";

export type TranslationCompletedToggleProps = {
  labelingTranslationLanguages: LabelingTranslationLaguageData[];
  getTranslation: Function;
  getLabelingTranslationLanguages?: Function;
  translationId: string;
  setLabelingTranslationLanguages?: Function;
};

const TranslationCompletedToggle: React.FC<TranslationCompletedToggleProps> = ({
  labelingTranslationLanguages,
  getTranslation,
  getLabelingTranslationLanguages,
  translationId,
  setLabelingTranslationLanguages,
}) => {
  const { formatMessage } = useIntl();

  const {
    allTranslationsValidated,
    allTranslationsCompleted,
    checked,
    completing,
    disabled,
    onClick,
  } = useTranslationCompleted({
    labelingTranslationLanguages,
    getTranslation,
    translationId,
    getLabelingTranslationLanguages,
    setLabelingTranslationLanguages,
  });

  return (
    <div>
      <ConditionalWrapper
        condition={!allTranslationsValidated && !allTranslationsCompleted}
        wrapper={children => (
          <Tooltip
            text={formatMessage(
              translationManagementMessages.translationCompletedDisabledTooltip
            )}
            zIndex={5}
            getPopupContainer={trigger => trigger.parentNode}
            data-testid="translation-completed-disabled-tooltip"
          >
            <span className="d-inline-block">{children}</span>
          </Tooltip>
        )}
      >
        <ConfirmationTooltip
          onConfirmation={onClick}
          text={formatMessage(
            translationManagementMessages.translationCompletedConfirmationTooltip
          )}
        >
          <Toggle
            loading={completing}
            disabled={disabled}
            checked={checked}
            text={formatMessage(
              translationManagementMessages.translationCompleted
            )}
            textPlacement="left"
            data-testid="translation-completed-toggle"
          />
        </ConfirmationTooltip>
      </ConditionalWrapper>
    </div>
  );
};

export default TranslationCompletedToggle;
