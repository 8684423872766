import { Row, Col } from "antd";
import { Paragraph } from "@trace-one/design-system";
import { TemplateProperty } from "components/SpecificationSection/types";
import { useFormSchemaTranslation } from "pages/Specification/components/Details/hooks";
import styles from "./styles.module.less";
import { getBooleanTranslation } from "components/SpecificationSection/components/FormSchema/utils";
import { formatDateToMMMDYYYYWithSpace } from "utils/date";

const ProductionSeasonalityWidgetReadOnly = ({
  propertyObjects,
  emptyValue,
}: {
  propertyObjects?: {
    [key: string]: TemplateProperty;
  };
  emptyValue?: string;
}) => {
  const { getTranslationForKey } = useFormSchemaTranslation();

  const { content: isSeasonalContent } = propertyObjects?.["seasonal"];
  const { formData: isSeasonal } = isSeasonalContent?.props;

  const { content: seasonalityContent } = propertyObjects?.["seasonality"];
  const { formData: seasonalityData } = seasonalityContent?.props;

  const seasonalMessage = getBooleanTranslation(
    isSeasonal,
    getTranslationForKey
  );

  const seasonalityDateFields = ["startDateUtc", "endDateUtc"];

  const seasonalityDates = seasonalityDateFields.reduce((acc, curr) => {
    let formattedDate = seasonalityData[curr]
      ? formatDateToMMMDYYYYWithSpace(seasonalityData[curr])
      : emptyValue;

    acc = { ...acc, [curr]: formattedDate };
    return acc;
  }, {});

  return (
    <Col span={24} className={styles.productionSeasonalityReadOnly}>
      <Row>
        <Col xs={4}>
          <Paragraph size="xs" color="grey-5">
            {isSeasonalContent?.props?.uiSchema["ui:title"]}
          </Paragraph>
        </Col>
        <Col xs={8}>
          <Paragraph size="xs" color="grey-4">
            {seasonalMessage}
          </Paragraph>
        </Col>
        <Col xs={12}>
          {isSeasonal &&
            seasonalityDateFields.map(field => (
              <Row
                gutter={[16, 0]}
                key={field}
                className={styles.productionSeasonalityDateRow}
              >
                <Col xs={8}>
                  <Paragraph size="xs" color="grey-5">
                    {seasonalityContent?.props?.uiSchema[field]["ui:title"]}
                  </Paragraph>
                </Col>
                <Col xs={16}>
                  <Paragraph size="xs" color="grey-4">
                    {seasonalityDates[field]}
                  </Paragraph>
                </Col>
              </Row>
            ))}
        </Col>
      </Row>
    </Col>
  );
};

export default ProductionSeasonalityWidgetReadOnly;
