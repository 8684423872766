import { Col, Row } from "antd";
import { Divider, Heading, Paragraph, Button } from "@trace-one/design-system";
import NutriScoreIndividualIndicator from "components/NutriScoreIndividualIndicator";
import NutriScoreIndicator from "components/NutriScoreIndicator";
import NutriscorePointsDetails from "components/NutriscorePointsDetails";
import Status from "components/Status";
import { useBoolean } from "hooks";
import { useFormSchemaTranslation } from "pages/Specification/components/Details/hooks";
import { isListEmpty } from "utils/general";
import { getAdditionalNutriScorePoints } from "utils/nutrition";
import { NutriScoreData } from "models";
import { NutriScoreGrade } from "types/nutrition";
import { UiSchemaInfo } from "types/general";

export type NutriScoreCardProps = {
  nutriScore: NutriScoreData;
  uiSchema?: UiSchemaInfo;
  displayActions?: boolean;
};

const NutriScoreCard: React.FC<NutriScoreCardProps> = ({
  nutriScore,
  uiSchema,
  displayActions,
}) => {
  const { getTranslationForKey } = useFormSchemaTranslation();
  const nutriScoreProperties = {
    totalScore: "totalScore",
    positiveComponentsScore: "positiveComponentsScore",
    negativeComponentsScore: "negativeComponentsScore",
    fruitsAndVegetablesPoint: "fruitsAndVegetablesPoint",
    nonNutritiveSweetenersPoint: "nonNutritiveSweetenersPoint",
  };
  const {
    value: isPointsDetailsOpen,
    onToggle: setPointsDetailsVisibility,
  } = useBoolean();

  const additionalPoints = getAdditionalNutriScorePoints({
    uiSchema,
    nutriScore,
    nutriScoreProperties,
  });

  return (
    <Col span={24}>
      <Row align="middle" className="mb-3">
        <Heading size="xs" className="mr-1">
          {uiSchema?.["ui:title"]}
        </Heading>
        <NutriScoreIndividualIndicator
          nutriScore={nutriScore?.grade as NutriScoreGrade}
        />
      </Row>
      <Row>
        <div>
          <Row>
            <NutriScoreIndicator
              nutriScore={nutriScore?.grade as NutriScoreGrade}
            />
            <div className="ml-8">
              <Divider color="grey-3" type="vertical" height={67} />
            </div>
          </Row>
        </div>

        <div className="ml-8 flex-1">
          <Row justify="center" align="middle">
            <Paragraph color="grey-4" className="mr-2">
              {uiSchema?.[nutriScoreProperties.totalScore]["ui:title"]}
            </Paragraph>
            <Heading size="xs">{nutriScore.totalScore || 0}</Heading>
          </Row>

          <Divider color="grey-3" height={8} />

          <Row justify="center" align="middle">
            <Row align="middle" className="mr-5">
              <Status.Dot className="mr-1 bg-green" />
              <Paragraph color="grey-4" size="s" className="mr-1">
                {
                  uiSchema?.[nutriScoreProperties.positiveComponentsScore][
                    "ui:title"
                  ]
                }
              </Paragraph>
              <Heading size="xxs">
                {nutriScore.positiveComponentsScore || 0}
              </Heading>
            </Row>
            <Row align="middle">
              <Status.Dot className="mr-1 bg-red" />
              <Paragraph color="grey-4" size="s" className="mr-1">
                {
                  uiSchema?.[nutriScoreProperties.negativeComponentsScore][
                    "ui:title"
                  ]
                }
              </Paragraph>
              <Heading size="xxs">
                {nutriScore.negativeComponentsScore || 0}
              </Heading>
            </Row>
          </Row>
        </div>
      </Row>

      {!isListEmpty(nutriScore?.nutriscorePoints) && displayActions && (
        <Button
          type="link"
          iconName={isPointsDetailsOpen ? "arrow-up" : "arrow-down"}
          iconPlacement="right"
          onClick={setPointsDetailsVisibility}
          className="mt-3"
        >
          {getTranslationForKey("nutriscoreSeePointsDetails")}
        </Button>
      )}

      {(isPointsDetailsOpen || !displayActions) && (
        <NutriscorePointsDetails
          nutriscorePoints={[
            ...(nutriScore?.nutriscorePoints ?? []),
            ...additionalPoints,
          ]}
        />
      )}
    </Col>
  );
};

export default NutriScoreCard;
