import { getVersionName } from "utils/specifications";
import {
  LIBRARY_OBJECT_TYPES,
  LIBRARY_OBJECTS_PAGES,
  SPECIFICATION_STATE,
} from "utils/constants";
import { LibraryObjectData } from "models";
import {
  generalMessages,
  libraryMessages,
  specificationMessages,
  versionHistoryMessages,
} from "messages";
import { ModalActionType } from "components/LinkedSpecificationsModal/types";
import { LibraryPageType } from "types/library";
import styles from "../styles.module.less";

export const getOnTagListChange = ({
  onFieldsChange,
  setFieldsValue,
}: {
  setFieldsValue: Function;
  onFieldsChange: Function;
}) => (tagIds: LibraryObjectData["tagIds"]) => {
  setFieldsValue({
    tagIds,
  });

  onFieldsChange(tagIds);
};

export const getHeadingTitle = ({
  name,
  versionNumber,
  page,
  formatMessage,
  type,
}: {
  name?: LibraryObjectData["name"];
  versionNumber?: LibraryObjectData["versionNumber"];
  page: LibraryPageType | LIBRARY_OBJECTS_PAGES.DETAILS;
  formatMessage: Function;
  type?: string;
}) => {
  let title;
  switch (page) {
    case LIBRARY_OBJECTS_PAGES.EDIT:
      title = `${name} ${getVersionName(versionNumber)} - ${formatMessage({
        id: "general.update",
      })}`;
      break;
    case LIBRARY_OBJECTS_PAGES.CREATE_NEW_VERSION:
    case LIBRARY_OBJECTS_PAGES.DUPLICATE:
    case LIBRARY_OBJECTS_PAGES.DETAILS:
      title = `${name} ${getVersionName(versionNumber)}`;
      break;
    default:
      title = formatMessage(libraryMessages[`${type}Title`]);
  }

  return <span className={styles.titleNormalLineHeight}>{title}</span>;
};

export const getLinkedSpecificationsModalActionMap = (
  libraryObjectType: LIBRARY_OBJECT_TYPES = LIBRARY_OBJECT_TYPES.CHAPTERS
) => {
  const subtitleMap = {
    [LIBRARY_OBJECT_TYPES.REQUIREMENTS]: {
      unlinkSubtitle:
        versionHistoryMessages.unlinkSpecificationsRequirementSubtitle,
      updateSubtitle:
        versionHistoryMessages.updateToLatestVersionRequirementSubtitle,
      reviseSubtitle: versionHistoryMessages.createARequirementRevisionSubtitle,
    },
    [LIBRARY_OBJECT_TYPES.CHAPTERS]: {
      unlinkSubtitle: versionHistoryMessages.unlinkSpecificationsSubtitle,
      updateSubtitle: versionHistoryMessages.updateToLatestVersionSubtitle,
      reviseSubtitle: versionHistoryMessages.createARevisionSubtitle,
    },
    [LIBRARY_OBJECT_TYPES.CLAIMS]: {
      unlinkSubtitle: versionHistoryMessages.unlinkSpecificationsClaimSubtitle,
      updateSubtitle: versionHistoryMessages.updateToLatestVersionClaimSubtitle,
      reviseSubtitle: versionHistoryMessages.createARevisionClaimSubtitle,
    },
  };

  const subtitles = subtitleMap[libraryObjectType];

  return {
    [ModalActionType.UNLINK]: {
      states: [
        SPECIFICATION_STATE.inProgress,
        SPECIFICATION_STATE.validatedByRetailer,
        SPECIFICATION_STATE.validatedBySupplier,
      ],
      title: versionHistoryMessages.unlinkSpecificationsTitle,
      subtitle: subtitles.unlinkSubtitle,
      primaryButtonLabel: versionHistoryMessages.unlinkButtonLabel,
    },
    [ModalActionType.UPDATE]: {
      states: [
        SPECIFICATION_STATE.inProgress,
        SPECIFICATION_STATE.validatedByRetailer,
        SPECIFICATION_STATE.validatedBySupplier,
      ],
      title: versionHistoryMessages.updateToLatestVersionTitle,
      subtitle: subtitles.updateSubtitle,
      primaryButtonLabel: generalMessages.update,
    },
    [ModalActionType.REVISE]: {
      states: [SPECIFICATION_STATE.signedByRetailer],
      title: specificationMessages.createARevision,
      subtitle: subtitles.reviseSubtitle,
      primaryButtonLabel: versionHistoryMessages.createRevisionBtn,
    },
  };
};
