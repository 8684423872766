import { useState } from "react";
import { utils } from "@rjsf/core";
import { getParsedData } from "utils/general";
import { usePromiseQueue } from "hooks";
import { prepareArrayBlockData, prepareBlockData } from "./utils";
import { isSpecificationDetailsPage } from "utils/specifications";
import { UseBlockActionsProps } from "./types";
import { ArraySchema } from "components/SpecificationSection/types";
import { PageType } from "types/specification";

const { getSchemaType } = utils;

const useBlockActions = ({
  shouldUseQueue,
  defaultSaveTemplateBlock,
  formData,
  onFinish,
  jsonSchema,
  setErrors,
  setIsTypingInProgressAction,
  uiSchema,
  page,
}: UseBlockActionsProps) => {
  const [isTypingInProgress, setIsTypingInProgress] = useState(false);

  const { enqueue, queueLength } = usePromiseQueue({
    defaultCurrentData: formData,
    onFinish,
  });

  const saveTemplateBlock = async (
    blockJson: string,
    previousBlockJson = ""
  ) => {
    if (shouldUseQueue) {
      const blockData = getParsedData(blockJson);
      const previousBlockData = getParsedData(previousBlockJson);

      if (isSpecificationDetailsPage(page as PageType)) {
        enqueue(
          async () =>
            await defaultSaveTemplateBlock?.(blockJson, previousBlockJson)
        );
      } else {
        enqueue(async (newBlockData = {}) => {
          let result = {
            newData: {},
            differencesKeys: [],
          };

          const blockKeys = Object.keys(jsonSchema.properties);

          // Check if the block is an array block
          if (
            blockKeys.length === 1 &&
            //@ts-ignore
            getSchemaType(jsonSchema.properties[blockKeys[0]]) === "array"
          ) {
            const currentJsonSchema = jsonSchema.properties[
              blockKeys[0]
            ] as ArraySchema;

            // This is the case when we have array of objects with itemKey as unique identifier
            if (currentJsonSchema.hasOwnProperty("itemKey")) {
              result =
                uiSchema?.[blockKeys[0]]?.["ui:widget"] === "TableWidget" // handle Characteristics block in which add and update happens at the same time
                  ? {
                      newData: blockData,
                      differencesKeys: [blockKeys[0]],
                    }
                  : prepareArrayBlockData({
                      previousBlockData,
                      blockData,
                      newBlockData,
                      itemKey: currentJsonSchema.itemKey,
                      propertyName: blockKeys[0],
                    });

              // This is the case when we have array of simple items like string
            } else {
              result = {
                newData: blockData,
                differencesKeys: [blockKeys[0]],
              };
            }
            // This is the case where we have an object
          } else {
            result = prepareBlockData({
              blockData,
              newBlockData,
              previousBlockData,
            });
          }

          const { payload, requestStatus } = await defaultSaveTemplateBlock?.(
            JSON.stringify(result.newData)
          );

          if (requestStatus === "rejected") {
            setErrors(
              result.differencesKeys.map(key => ({
                error: "apiError",
                path: `.${key}`,
              }))
            );

            return blockData;
          }

          return payload;
        });
      }
    } else {
      await defaultSaveTemplateBlock?.(blockJson, previousBlockJson);
    }

    setIsTypingInProgress(false);
  };

  const onUpdateIsTypingInProgress = (value: boolean) => {
    setIsTypingInProgress(value);

    setIsTypingInProgressAction?.(value);
  };

  return {
    saveTemplateBlock,
    isTypingInProgress,
    onUpdateIsTypingInProgress,
    queueLength,
  };
};

export default useBlockActions;
