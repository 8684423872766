import { Button, EmptyState } from "@trace-one/design-system";
import { Col, Row } from "antd";
import classNames from "classnames";
import { withConfigConsumer } from "antd/lib/config-provider/context";
import FlexibleArrayFieldTemplateItem from "./components/FlexibleArrayFieldTemplateItem";
import { isListEmpty } from "utils/general";
import { useArrayFieldTemplateProps } from "../hooks";
import widgetStyles from "components/SpecificationSection/components/FormSchema/styles.module.less";

const FlexibleArrayFieldTemplate = ({
  items,
  onAddClick,
  noItemsMessage,
  addBtnLabel,
  formContext,
  schema,
  uiSchema,
  title,
}) => {
  const { displayActions } = formContext;

  const {
    hasRemove,
    lastChanges,
    onAddBtnClick,
    isAddButtonDisabled,
  } = useArrayFieldTemplateProps({
    onAddClick,
    formContext,
    schema,
    items,
    key: title,
  });

  return (
    <Col span={24}>
      <Col
        span={24}
        className={classNames({
          [widgetStyles.itemsContainerFlex]: uiSchema?.["ui:items:flex:layout"],
        })}
      >
        {!isListEmpty(items)
          ? items.map((itemProps, index) => (
              <Col
                key={itemProps.key}
                span={uiSchema?.["ui:items:flex:col"] ?? 24}
              >
                <FlexibleArrayFieldTemplateItem
                  key={itemProps.key}
                  {...itemProps}
                  displayActions={displayActions}
                  hasRemove={hasRemove}
                  lastChange={lastChanges?.[index]}
                  contentWidth={uiSchema?.items?.["ui:single:item:col"]}
                  hideRemoveButton={
                    uiSchema["ui:remove:button:hide"] || !hasRemove
                  }
                  className={uiSchema?.["ui:className"]}
                />
              </Col>
            ))
          : uiSchema?.["ui:no:items:message:hide"] !== true && (
              <Row gutter={[0, 30]} justify="center">
                <EmptyState heading={noItemsMessage} />
              </Row>
            )}
      </Col>

      {displayActions && uiSchema["ui:add:button:hide"] !== true ? (
        <Row className={widgetStyles.addButtonArrayItem}>
          <Col xs={uiSchema["ui:add:button:wrapper:col"] || 24}>
            <Row justify={uiSchema["ui:add:button:justify"]}>
              <Col offset={uiSchema["ui:add:button:offset"]}>
                <Button
                  type="tertiary"
                  onClick={onAddBtnClick}
                  disabled={isAddButtonDisabled}
                >
                  {addBtnLabel}
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      ) : null}
    </Col>
  );
};

export default withConfigConsumer({ prefixCls: "form" })(
  FlexibleArrayFieldTemplate
);
