import classNames from "classnames";
import { useIntl } from "react-intl";
import { SeeMore, Sidebar } from "components";
import Spin from "components/Spin";
import { useRestrictionSummary } from "./hooks";
import { PACKAGING_SYSTEM_RESTRICTED_SUBSTANCES_KEYS } from "utils/constants";
import { PackagingSystemComponentsViewModel } from "viewModels";
import { generalMessages, packagingMessages } from "messages";
import styles from "./styles.module.less";

export interface RestrictionSummaryProps {
  packagingSystemAllowUpdate: PackagingSystemComponentsViewModel["allowsUpdate"];
}

const RestrictionSummary: React.FC<RestrictionSummaryProps> = () => {
  const { formatMessage } = useIntl();

  const {
    isLoading,
    selectedMenuItem,
    sidebarItems,
    getSelectedMenuItem,
    contentMap,
  } = useRestrictionSummary();

  if (isLoading) {
    return <Spin />;
  }

  return (
    <>
      <SeeMore
        text={formatMessage(packagingMessages.restrictionSummaryTabDescription)}
      />

      <div className="mt-4 d-flex">
        <div>
          <Sidebar
            items={sidebarItems}
            name={formatMessage(generalMessages.components)}
            getSelectedMenuItem={getSelectedMenuItem}
            selected={PACKAGING_SYSTEM_RESTRICTED_SUBSTANCES_KEYS.ALL}
            className={styles["restriction-summary--element__sticky"]}
          />
        </div>

        <div
          className={classNames(
            "pl-2 flex-1",
            styles["restriction-summary--element__sticky"]
          )}
        >
          {contentMap[selectedMenuItem]}
        </div>
      </div>
    </>
  );
};

export default RestrictionSummary;
