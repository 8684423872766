import { defineMessages } from "react-intl";

export const restrictedSubstanceTableMessages = defineMessages({
  emptyMessage: {
    id: "library.packagingSystemForm.restrictionSummary.emptyMessage",
    defaultMessage: "There is no restriction declared.",
  },
  displayCount: {
    id: "library.packagingSystemForm.restrictionSummary.displayCount",
    defaultMessage:
      "Displaying {currentCount} of {totalCount} restricted substances",
  },
  percentageInComponent: {
    id: "library.packagingSystemForm.restrictionSummary.percentageInComponent",
    defaultMessage: "% in component",
  },
  substanceNameAndCASNumber: {
    id:
      "library.packagingSystemForm.restrictionSummary.substanceNameAndCASNumber",
    defaultMessage: "Substance name and CAS number",
  },
  nanoPresence: {
    id: "substance.nanoPresence",
    defaultMessage: "Nano presence",
  },
  endocrineDisruptor: {
    id: "substance.endocrineDisruptor",
    defaultMessage: "Endocrine disruptor",
  },
});
