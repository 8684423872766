import { useIntl } from "react-intl";
import {
  RootNutrientName,
  UnroundedValue,
} from "components/SpecificationSection/components/FormSchema/components/NutritionBasic/shared/components";
import { useColumnProps } from "components/SpecificationSection/components/FormSchema/components/NutritionBasic/shared/hooks";
import { UnitOfMeasure, DeleteNutrientAction } from "./components";
import {
  NutrientTableDataItem,
  NutrientItemDictionary,
} from "components/SpecificationSection/components/FormSchema/components/NutritionBasic/types";
import { accessObjectPropertyWithPath } from "utils/general";
import { NUTRIENT_STATE } from "utils/constants";
import { UseNutritionWidgetNutritionColumnsProps } from "./types";
import { NutrientItem } from "models";
import { messages } from "./messages";

const useNutritionWidgetNutritionColumns = ({
  hasUpdatePermission,
  statusNutrientFamilyDictionary,
  uiSchema,
  formData,
  schema,
  errorSchema,
  nutrientDictionary,
  displayActions,
  updateNutrition,
  nutrientsWithoutFamily,
}: UseNutritionWidgetNutritionColumnsProps) => {
  const { formatMessage } = useIntl();

  const isNotRootNutrient = (nutrient: NutrientTableDataItem) =>
    !nutrient.hasOwnProperty("nutrientFamilyId");

  const isMandatoryNutrient = (nutrient: NutrientTableDataItem) =>
    NUTRIENT_STATE.MANDATORY === nutrient.state;

  const { getColumnProps } = useColumnProps({
    schema,
    formData,
    uiSchema,
  });

  const getColumnErrors = (path: string) => {
    const errors: string[] = accessObjectPropertyWithPath({
      object: errorSchema,
      path,
    })?.__errors;

    return errors;
  };

  const existingNutrientIds =
    formData?.nutrients?.map(({ nutrientId }) => nutrientId) || [];

  let columns = [
    {
      ...getColumnProps("nutrientId"),
      render: (value, nutrient: NutrientTableDataItem) => {
        if (isNotRootNutrient(nutrient)) {
          return (nutrientDictionary[value] as NutrientItemDictionary)?.name;
        }
        if (!statusNutrientFamilyDictionary?.[value]) {
          return null;
        }

        return (
          <RootNutrientName
            nutrientName={
              (nutrientDictionary[value] as NutrientItemDictionary)?.name
            }
            statusData={statusNutrientFamilyDictionary[value]}
          />
        );
      },
    },
    {
      ...getColumnProps("unitId"),
      render: (
        value: NutrientTableDataItem["unitId"],
        nutrient: NutrientTableDataItem
      ) => {
        const displayUnitOfMeasureAction =
          isNotRootNutrient(nutrient) &&
          hasUpdatePermission &&
          displayActions &&
          existingNutrientIds.includes(nutrient?.nutrientId);

        return displayUnitOfMeasureAction ? (
          <UnitOfMeasure
            value={value}
            label={nutrientDictionary[value] as string}
            nutrientData={nutrient as NutrientItem}
            formData={formData}
          />
        ) : (
          nutrientDictionary[value]
        );
      },
    },
    {
      ...getColumnProps("unroundedValue"),
      render: (
        value: NutrientTableDataItem["unroundedValue"],
        nutrient: NutrientTableDataItem
      ) => {
        const displayUnroundedValueAction =
          isNotRootNutrient(nutrient) &&
          existingNutrientIds.includes(nutrient?.nutrientId);

        return (
          displayUnroundedValueAction && (
            <UnroundedValue
              displayActions={displayActions}
              value={value}
              nutrientData={nutrient as NutrientItem}
              errors={getColumnErrors(
                `.nutrientFamilies.${nutrient.key}.unroundedValue`
              )}
              formData={formData}
              updateNutrition={updateNutrition}
            />
          )
        );
      },
    },
  ];

  if (displayActions) {
    columns.push({
      title: formatMessage(messages.action),
      //@ts-ignore
      width: 90,
      render: (_, nutrient: NutrientTableDataItem) => {
        const displayDeleteNutrientAction =
          isNotRootNutrient(nutrient) &&
          !isMandatoryNutrient(nutrient) &&
          existingNutrientIds.includes(nutrient?.nutrientId);

        return (
          displayDeleteNutrientAction && (
            <DeleteNutrientAction
              nutrientData={nutrient as NutrientItem}
              disabled={!hasUpdatePermission}
              formData={formData}
              nutrientsWithoutFamily={nutrientsWithoutFamily}
            />
          )
        );
      },
    });
  }

  return {
    columns,
  };
};

export default useNutritionWidgetNutritionColumns;
