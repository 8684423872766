import { AxiosInstance, AxiosRequestConfig } from "axios";
import { axiosInstance } from ".";
import {
  LibraryItemVersionData,
  LibraryObjectClaimData,
  SpecificationListItemData,
} from "models";
import {
  ClaimData,
  ClaimNutrientData,
  ClaimSentenceTranslation,
  ClaimSubstanceData,
  ClaimTypeData,
  RegulatoryHealthClaim,
} from "models/claim";
import { PaginatedList, PaginationParams } from "types/api";
import { getClaimRoute } from "apis/utils";
import { ClaimType, LibraryItemApiType } from "types/library";
import { axiosInstance as axiosInstanceWithInterception } from "./";

const baseUrl = "/api/";

export type ClaimUrlParams = {
  claimId: ClaimData["id"];
  claimType: LibraryItemApiType;
};

export interface FetchClaimSpecificationsParams extends ClaimUrlParams {
  params: PaginationParams & {
    languageCode?: string;
    states?: string;
  };
}

export const fetchClaimTypes = ({ languageCode }: { languageCode: string }) =>
  axiosInstance.get<ClaimTypeData[]>(`/api/claim-types`, {
    params: {
      languageCode,
    },
  });

export const fetchClaimNutrients = ({
  params,
  paramsSerializer,
}: {
  params: {
    claimTypes: ClaimType[];
  };
  paramsSerializer?: AxiosRequestConfig["paramsSerializer"];
}) =>
  axiosInstance.get<ClaimNutrientData>(`${baseUrl}claims/nutrients`, {
    params,
    paramsSerializer,
  });

export const fetchClaimSubstances = ({
  params,
  paramsSerializer,
}: {
  params: {
    claimTypes: ClaimType[];
  };
  paramsSerializer?: AxiosRequestConfig["paramsSerializer"];
}) =>
  axiosInstance.get<ClaimSubstanceData>(`${baseUrl}claims/substances`, {
    params,
    paramsSerializer,
  });

export const createOrPublishClaim = ({
  claimType,
  payload,
  params,
}: {
  claimType: LibraryItemApiType;
  payload: {
    name: ClaimData["name"];
    claimType: ClaimData["claimType"];
    productTypeId: ClaimData["productTypeId"];
    sentenceTranslations?: ClaimSentenceTranslation[];
    logoFileId: ClaimData["logoFileId"];
    tagIds?: ClaimData["tagIds"];
    requiresSupportingDocuments?: ClaimData["requiresSupportingDocuments"];
    additionalSentenceTranslations?: ClaimSentenceTranslation[];
    regulatoryClaimId?: string;
  };
  params: {
    publish?: boolean;
  };
}) =>
  axiosInstance.post<ClaimData>(`${baseUrl}${claimType}`, payload, {
    params,
  });

export const saveOrPublish = ({
  claimType,
  id,
  payload,
  params,
}: {
  claimType: LibraryItemApiType;
  id: ClaimData["id"];
  payload: {
    name?: ClaimData["name"];
    sentenceTranslations?: ClaimSentenceTranslation[];
    logoFileId: ClaimData["logoFileId"];
    tagIds: ClaimData["tagIds"];
    requiresSupportingDocuments?: ClaimData["requiresSupportingDocuments"];
    additionalSentenceTranslations?: ClaimSentenceTranslation[];
    regulatoryClaimId?: string;
  };
  params: {
    publish?: boolean;
  };
}) =>
  axiosInstance.put<ClaimData>(`${baseUrl}${claimType}/${id}`, payload, {
    params,
  });

export const fetchClaims = ({
  params,
  paramsSerializer,
  type,
}: {
  params: PaginationParams & {
    ownerCompanyId: ClaimData["ownerCompanyId"];
    name?: ClaimData["name"];
    productTypeId?: ClaimData["productTypeId"];
    excludeDrafts?: boolean;
    languageCode?: string;
  };
  paramsSerializer?: AxiosRequestConfig["paramsSerializer"];
  type: "claims" | "nutritional-claims";
}) =>
  axiosInstance.get<PaginatedList<LibraryObjectClaimData>>(
    `${baseUrl}${type}/search`,
    {
      params,
      paramsSerializer,
    }
  );

export const fetchHealthRegulatoryClaims = ({
  params,
  paramsSerializer,
}: {
  params: PaginationParams;
  paramsSerializer?: AxiosRequestConfig["paramsSerializer"];
}) =>
  axiosInstance.get<PaginatedList<RegulatoryHealthClaim>>(
    `${baseUrl}health-claims/regulation/search`,
    {
      params,
      paramsSerializer,
    }
  );

export const fetchClaim = ({
  claimType,
  id,
  languageCode,
}: {
  claimType: LibraryItemApiType;
  id: ClaimData["id"];
  languageCode: string;
}) =>
  axiosInstance.get<ClaimData>(`${baseUrl}${claimType}/${id}`, {
    params: { languageCode },
  });

export const fetchClaimCollection = ({
  claimType,
  payload,
}: {
  claimType: LibraryItemApiType;
  payload: {
    claimIds: ClaimData["id"][];
  };
}) =>
  axiosInstance.post<ClaimData[]>(`${baseUrl}${claimType}/collection`, payload);

export const deleteClaim = ({
  id,
  claimType,
}: {
  id: ClaimData["id"];
  claimType: LibraryItemApiType;
}) => axiosInstance.delete<Promise<void>>(`${baseUrl}${claimType}/${id}`);

export const fetchHealthClaimRegulation = ({
  id,
  languageCode,
}: {
  id: string;
  languageCode: string;
}) =>
  axiosInstance.get<RegulatoryHealthClaim>(
    `${baseUrl}health-claims/regulation/${id}`,
    {
      params: { languageCode },
    }
  );

export const fetchHealthClaimRegulationCollection = ({
  payload,
}: {
  payload: {
    claimIds: string[];
  };
}) =>
  axiosInstance.post<RegulatoryHealthClaim[]>(
    `${baseUrl}health-claims/regulation/collection`,
    payload
  );

export const importClaim = ({
  claimType,
  languageCode,
  delimiter,
  axiosInstance = axiosInstanceWithInterception,
}: {
  claimType: LibraryItemApiType;
  languageCode?: string;
  delimiter?: string;
  axiosInstance?: AxiosInstance;
}) => async (file: File) => {
  let formData = new FormData();
  formData.append("file", file);

  const claimRoute = getClaimRoute(claimType);

  await axiosInstance.post<void>(`${baseUrl}${claimRoute}/import`, formData, {
    params: {
      delimiter,
      languageCode,
    },
  });
};

export const downloadClaimTemplate = ({
  claimType,
}: {
  claimType: LibraryItemApiType;
}) => {
  const claimRoute = getClaimRoute(claimType);

  return axiosInstance.post<File>(
    `${baseUrl}${claimRoute}/download-file-template`,
    null,
    {
      responseType: "blob",
    }
  );
};

export const createOrPublishClaimVersion = ({
  id,
  claimType,
  payload,
  params,
}: {
  id: ClaimData["id"];
  claimType: LibraryItemApiType;
  payload: {
    sentenceTranslations?: ClaimSentenceTranslation[];
    logoFileId: ClaimData["logoFileId"];
    tagIds?: ClaimData["tagIds"];
    requiresSupportingDocuments?: ClaimData["requiresSupportingDocuments"];
    additionalSentenceTranslations?: ClaimSentenceTranslation[];
    regulatoryClaimId?: string;
  };
  params: {
    publish?: boolean;
  };
}) =>
  axiosInstance.post<ClaimData["id"]>(
    `${baseUrl}${claimType}/${id}/new-version`,
    payload,
    {
      params,
    }
  );

export const fetchClaimVersions = ({ claimId, claimType }: ClaimUrlParams) =>
  axiosInstance.get<LibraryItemVersionData[]>(
    `${baseUrl}${claimType}/${claimId}/versions`
  );

export const fetchClaimQuickView = ({ claimId, claimType }: ClaimUrlParams) =>
  axiosInstance.get<PaginatedList<SpecificationListItemData>>(
    `${baseUrl}${claimType}/${claimId}/quick-view`
  );

export const fetchClaimSpecifications = ({
  claimId,
  claimType,
  params,
}: FetchClaimSpecificationsParams) =>
  axiosInstance.get<PaginatedList<SpecificationListItemData>>(
    `${baseUrl}${claimType}/${claimId}/specifications`,
    { params }
  );
