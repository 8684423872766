import { useAppDispatch } from "store";
import {
  updateClassificationId,
  updatePartId,
} from "store/materialForm/asyncActions";
import { MaterialData } from "models";

export type UseRawMaterialActionsProps = {
  setClassificationId?: React.Dispatch<React.SetStateAction<string>>;
  setPartId?: React.Dispatch<React.SetStateAction<string>>;
};

const useRawMaterialActions = ({
  setClassificationId,
  setPartId,
}: UseRawMaterialActionsProps) => {
  const dispatch = useAppDispatch();

  const onChangeClassification = async (
    classificationId?: MaterialData["classificationId"]
  ) => {
    setClassificationId?.(classificationId);
    setPartId?.(null);

    if (!classificationId) {
      return;
    }
    dispatch(updateClassificationId(classificationId));
  };

  const onChangePart = async (partId?: MaterialData["partId"]) => {
    dispatch(updatePartId(partId ?? null));
  };

  return {
    onChangeClassification,
    onChangePart,
  };
};

export default useRawMaterialActions;
