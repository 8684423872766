import { CLAIM_TYPES, LIBRARY_OBJECT_TYPES } from "utils/constants";
import { isLibraryObjectClaimType } from "utils/library";

export const getClaimTypesForParam = ({
  type,
  isLibraryListingPage,
}: {
  type: LIBRARY_OBJECT_TYPES;
  isLibraryListingPage: boolean;
}) => {
  if (isLibraryListingPage) {
    if (isLibraryObjectClaimType(type)) {
      return [CLAIM_TYPES.MARKETING, CLAIM_TYPES.HEALTH];
    }

    return [CLAIM_TYPES.NUTRITION];
  }

  return [CLAIM_TYPES.MARKETING, CLAIM_TYPES.HEALTH, CLAIM_TYPES.NUTRITION];
};
