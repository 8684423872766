import { useBoolean, useModalVisibility } from "hooks";
import {
  PackagingSubstance,
  SubstanceData,
  SubstanceRegulationData,
} from "models";
import { useMemo, useState } from "react";
import { isListEmpty } from "utils/general";
import { isSubstanceRestricted } from "utils/packaging";
import { PackagingSubstanceTableViewModel } from "viewModels";

export interface UseSubstanceWidgetProps {
  substanceInformationCollection: SubstanceData[];
  substances: PackagingSubstance[];
  substanceRegulations: SubstanceRegulationData[];
  saveTemplateBlock?: Function;
}

export const useSubstanceWidget = ({
  substanceInformationCollection,
  substances,
  substanceRegulations,
  saveTemplateBlock,
}: UseSubstanceWidgetProps) => {
  const { value: removing, setTrue, setFalse } = useBoolean(false);

  const {
    isModalOpen: isEditSubstanceModalOpen,
    onOpenModal: defaultOpenModal,
    onCloseModal,
  } = useModalVisibility();
  const [
    selectedSubstance,
    setSelectedSubstance,
  ] = useState<PackagingSubstanceTableViewModel>(null);

  const substancesViewModel: PackagingSubstanceTableViewModel[] = useMemo(() => {
    if (
      isListEmpty(substanceInformationCollection) ||
      isListEmpty(substances) ||
      isListEmpty(substanceRegulations)
    ) {
      return [];
    }

    let substanceMap = substances.reduce((acc, substance) => {
      return {
        ...acc,
        [substance.substanceId]: substance,
      };
    }, {});

    substanceInformationCollection.forEach(({ id, name, casNumbers }) => {
      const substanceInMap = substanceMap[id];

      substanceMap[id] = {
        ...substanceInMap,
        name,
        casNumbers,
      };
    });

    substanceRegulations.forEach(({ id, regulations }) => {
      const { eu, agec } = regulations;

      const substanceInMap = substanceMap[id];
      const isRestricted = isSubstanceRestricted(regulations);

      const { sml, isFrfApplicable } = eu || {};
      const { isHighConcernByArticle59 } = agec || {};

      substanceMap[id] = {
        ...substanceInMap,
        isRestricted,
        sml,
        isFrfApplicable,
        isEndocrineDisruptor: isHighConcernByArticle59,
        regulations,
      };
    });

    return Object.values(substanceMap);
  }, [substanceInformationCollection, substances, substanceRegulations]);

  const onRemoveSubstance = async (value: string) => {
    try {
      setTrue();

      const updatedPayload = (substances ?? []).filter(
        ({ substanceId }) => substanceId !== value
      );

      await saveTemplateBlock(
        JSON.stringify({
          substances: [...updatedPayload],
        })
      );
    } catch {
    } finally {
      setFalse();
    }
  };

  const onOpenModal = (substance: PackagingSubstanceTableViewModel) => () => {
    setSelectedSubstance(substance);
    defaultOpenModal();
  };

  return {
    restrictedSubstances: substancesViewModel.filter(
      substance => substance.isRestricted
    ),
    unRestrictedSubstances: substancesViewModel.filter(
      substance => !substance.isRestricted
    ),
    removing,
    onRemoveSubstance,
    selectedSubstance,
    isEditSubstanceModalOpen,
    onOpenModal,
    onCloseModal,
  };
};
