import { useEffect, useState } from "react";
import { useBoolean, usePromiseQueue, useRedirect } from "hooks";
import {
  ChapterRecipeFormData,
  LibraryObjectData,
  MaterialIngredientData,
} from "models";
import {
  LIBRARY_OBJECT_TYPES,
  LIBRARY_OBJECT_URL_TYPES,
} from "utils/constants";
import { EnhancedExtraActions } from "components/SpecificationSection/types";
import { Sorter } from "types/pagination";
import { MaterialType, RecipeType } from "types/library";
import { MaterialRecipeIngredientViewModel } from "viewModels";

export type UseChapterRecipeTableActionsProps = {
  addRecipeIngredient: EnhancedExtraActions["addRecipeIngredient"];
  updateRecipeIngredient: EnhancedExtraActions["updateRecipeIngredient"];
  removeRecipeIngredient: EnhancedExtraActions["removeRecipeIngredient"];
  sortRecipeIngredient: EnhancedExtraActions["sortRecipeIngredient"];
  sorter: Sorter;
  getMaterialData: () => Promise<void>;
  formData: ChapterRecipeFormData;
};

const useChapterRecipeTableActions = ({
  addRecipeIngredient,
  updateRecipeIngredient,
  removeRecipeIngredient,
  sortRecipeIngredient,
  sorter,
  getMaterialData,
  formData,
}: UseChapterRecipeTableActionsProps) => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const { value: addingIngredients, setTrue, setFalse } = useBoolean();
  const { materialId } = formData || {};
  const materialType = LIBRARY_OBJECT_TYPES.MADE_IN_HOUSE;

  const { redirectToLibraryDetails } = useRedirect();
  const { enqueue, queueLength } = usePromiseQueue({});

  const onAddIngredients = async (selectedObjects: LibraryObjectData[]) => {
    try {
      setTrue();

      for (let object of selectedObjects) {
        const { id } = object;

        await addRecipeIngredient({
          materialId,
          materialType,
          ingredient: {
            materialId: id,
          },
        });
      }
    } catch {
    } finally {
      await getMaterialData();
      onCloseModal();
      setFalse();
    }
  };

  const onUpdateIngredientPercentage = async (
    ingredient: MaterialRecipeIngredientViewModel,
    percentage: number
  ) => {
    const { ingredientId, functionId } = ingredient;

    enqueue(async () => {
      await updateRecipeIngredient({
        materialId,
        materialType,
        ingredientId,
        functionId,
        percentage,
      });
      await getMaterialData();

      return null;
    });
  };

  const onUpdateIngredientFunction = async ({
    ingredientId,
    functionId,
    percentage,
  }: {
    ingredientId: MaterialIngredientData["ingredientId"];
    functionId: MaterialIngredientData["functionId"];
    percentage: MaterialIngredientData["percentage"];
  }) => {
    enqueue(async () => {
      await updateRecipeIngredient({
        materialId,
        materialType,
        ingredientId,
        functionId,
        percentage,
      });

      await getMaterialData();

      return null;
    });
  };

  const onRemoveIngredient = ({
    ingredientId,
  }: {
    ingredientId: MaterialIngredientData["ingredientId"];
  }) => async () => {
    await removeRecipeIngredient({ materialId, materialType, ingredientId });

    await getMaterialData();
  };

  const onViewMaterialAction = ({
    materialId,
    type,
  }: {
    materialId: MaterialIngredientData["materialId"];
    type: MaterialType | RecipeType;
  }) => () => {
    redirectToLibraryDetails({
      id: materialId,
      type: LIBRARY_OBJECT_URL_TYPES[type],
    });
  };

  const onCloseModal = () => {
    setIsModalOpen(false);
  };

  const onOpenModal = () => {
    setIsModalOpen(true);
  };

  useEffect(() => {
    if (!sorter?.orderBy) {
      return;
    }

    sortRecipeIngredient?.({ ...sorter });
  }, [sorter]);

  return {
    isModalOpen,
    onAddIngredients,
    onUpdateIngredientPercentage,
    onUpdateIngredientFunction,
    onRemoveIngredient,
    onViewMaterialAction,
    onCloseModal,
    onOpenModal,
    queueLength,
    addingIngredients,
  };
};

export default useChapterRecipeTableActions;
