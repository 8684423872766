import { useEffect, useMemo } from "react";
import { useRouteMatch } from "react-router-dom";
import { useIntl } from "react-intl";
import { Form } from "antd";
import { Tags } from "@trace-one/react-components";
import { useSelector } from "react-redux";
import { selectLanguageCode } from "store/user/selectors";
import {
  selectRequirementInformation,
  selectRequirementType,
  selectUpdateStatus,
} from "store/requirementForm/selectors";
import { getVersionName } from "utils/specifications";
import { APPLICATION_TYPE_IDS, MAX_CHAR_LENGTH, ROUTES } from "utils/constants";
import { useLibraryFormActions } from "components/Library/hooks";
import { useQuery } from "hooks";
import {
  useRequirementFormActions,
  useRequirementFormInitialisation,
  useRequirementInfoActions,
} from "./hooks";
import {
  isLibraryObjectCreatePage,
  isLibraryObjectDraft,
  isLibraryObjectEditPage,
  isLibraryObjectPublished,
} from "utils/library";
import { getOnTagListChange } from "../utils";
import { getRequiredFields } from "./utils";
import Spin from "components/Spin";
import LibraryItemName from "../LibraryItemName";
import CancelDeleteRequirementModal from "./components/CancelDeleteRequirementModal";
import RequirementType from "./components/RequirementType";
import RequirementContent from "./components/RequirementContent";
import { RequirementFormProps } from "./types";
import libraryMessages from "messages/library";

export const RequirementForm: React.FC<RequirementFormProps> = ({
  initialValues,
  isLocked,
  versionNumber,
  page,
  updateActionsInformation = () => {},
}) => {
  const { formatMessage } = useIntl();
  const languageCode = useSelector(selectLanguageCode);
  const requirementInformation = useSelector(selectRequirementInformation);
  const requirementType = useSelector(selectRequirementType);
  const { inProgress: isUpdateInProgress } = useSelector(selectUpdateStatus);
  const [form] = Form.useForm();

  const isNewVersionPage = useRouteMatch(ROUTES.LIBRARY.CREATE_NEW_VERSION);
  const isNewVersion = isNewVersionPage?.isExact;

  const query = useQuery();
  const prevId = query.get("prevVersionId");

  const { isLoading } = useRequirementFormInitialisation({
    initialFormData: initialValues,
    form,
  });

  const requiredFields = useMemo(() => getRequiredFields(requirementType), [
    requirementType,
  ]);

  const { areActionButtonsDisabled, onFieldsChange } = useLibraryFormActions({
    isUpdateInProgress: isLoading || isUpdateInProgress,
    requiredFields,
    libraryItem: requirementInformation,
    disabled: requiredFields.some(field => !form.getFieldValue(field)),
  });

  const state = useMemo(() => requirementInformation?.state, [
    requirementInformation?.state,
  ]);

  const versionName = useMemo(
    () => (versionNumber ? getVersionName(versionNumber + 1) : ""),
    [versionNumber]
  );

  const { onSaveName, onSaveTags } = useRequirementInfoActions();

  const {
    onKeepAsDraftRequirementFromDropdown,
    onPublishRequirement,
  } = useRequirementFormActions({
    validateFields: form.validateFields,
  });

  const actionButtonItems = [
    ...(isLibraryObjectDraft(state)
      ? [
          {
            name: formatMessage(libraryMessages.publishButton),
            label: formatMessage(libraryMessages.publishButton),
            onClick: onPublishRequirement,
          },
        ]
      : []),
    ...(isLibraryObjectCreatePage(page) || isNewVersion
      ? [
          {
            name: formatMessage(libraryMessages.keepAsDraftButton),
            label: formatMessage(libraryMessages.keepAsDraftButton),
            onClick: onKeepAsDraftRequirementFromDropdown,
          },
        ]
      : []),
  ];

  useEffect(() => {
    updateActionsInformation({
      props: {
        disabled: areActionButtonsDisabled,
        items: actionButtonItems,
      },
      onClick: isLibraryObjectPublished(state) ? null : () => {},
    });
  }, [areActionButtonsDisabled, state]);

  if (isLoading) return <Spin />;

  return (
    <>
      <Form
        name="requirement"
        layout="vertical"
        validateMessages={{
          required: formatMessage(libraryMessages.formRequired),
          whitespace: formatMessage(libraryMessages.formRequired),
          string: {
            max: formatMessage(libraryMessages.formMaxCharLength, {
              max: MAX_CHAR_LENGTH,
            }),
          },
        }}
        initialValues={{
          ...requirementInformation,
        }}
        form={form}
        labelAlign="left"
        onFieldsChange={onFieldsChange}
      >
        <LibraryItemName
          disableActions={
            isLibraryObjectPublished(state) || isLocked || isNewVersion
          }
          versionName={versionName}
          defaultValue={requirementInformation.name}
          libraryItemId={requirementInformation.id}
          onChange={onSaveName}
          dataTestId="requirement-input-name"
          form={form}
          disabledOnBlur={true}
        />
        <Form.Item
          shouldUpdate
          label={formatMessage(libraryMessages.tagsLabel)}
        >
          <Form.Item name="tagIds" noStyle>
            <Tags.Select
              languageCode={languageCode}
              applicationTypeId={APPLICATION_TYPE_IDS.SPEC}
              tagIds={requirementInformation.tagIds}
              onTagListChange={getOnTagListChange({
                setFieldsValue: form.setFieldsValue,
                onFieldsChange: onSaveTags,
              })}
            />
          </Form.Item>
        </Form.Item>
        <Form.Item
          name="type"
          required
          label={formatMessage(libraryMessages.typeLabel)}
        >
          <RequirementType
            requirementType={requirementType}
            isLocked={isLocked}
            disableActions={!isLibraryObjectCreatePage(page)}
          />
        </Form.Item>
        <RequirementContent
          requirementInformation={requirementInformation}
          requirementType={requirementType}
          isLocked={isLocked}
        />
      </Form>

      {isNewVersion && (
        <CancelDeleteRequirementModal
          isNewVersion={isNewVersion}
          prevRequirementId={prevId}
          form={form}
          key="new-version-page"
        />
      )}

      {!isLibraryObjectEditPage(page) && (
        <CancelDeleteRequirementModal form={form} key="create-page" />
      )}
    </>
  );
};

RequirementForm.defaultProps = {
  initialValues: {},
  isLocked: false,
  versionNumber: 0,
};

export default RequirementForm;
