import { GeneralInfoData, LibraryObjectClaimData } from "models";
import { ClaimType } from "types/library";

export interface ClaimTypeData {
  claimTypeName: string;
  claimType: ClaimType;
  productTypeId: string;
}

export interface ClaimSentenceTranslation {
  languageCode: string;
  text: string;
  isDefault: boolean;
}

export interface ClaimFile {
  id?: string;
  fileName?: string;
  fileSize?: number;
}

export interface ClaimData
  extends LibraryObjectClaimData,
    RegulatoryHealthClaim {
  generalInfo?: GeneralInfoData;
  sentenceTranslations?: ClaimSentenceTranslation[];
  sentence?: string;
  file?: ClaimFile;
  logoFileId?: string;
  regulatoryClaimId?: string;
}

export enum CLAIM_VALIDATION_RULE_TYPE {
  NUTRIENT = "nutrient",
  SUBSTANCE = "substance",
}

export interface ClaimValidationRule {
  id: string;
  itemType: CLAIM_VALIDATION_RULE_TYPE;
}

export interface RegulatoryHealthClaim {
  id?: string;
  productTypeId?: string;
  sentenceTranslations?: ClaimSentenceTranslation[];
  additionalSentenceTranslations?: ClaimSentenceTranslation[];
  requiresSupportingDocuments?: boolean;
  regulationDomain?: string;
  conditionOfUse?: ClaimSentenceTranslation[];
  validationRule?: ClaimValidationRule[];
}

export type ClaimNutrientData = {
  itemType: CLAIM_VALIDATION_RULE_TYPE.NUTRIENT;
  itemIds: string[];
};

export type ClaimSubstanceData = {
  itemType: CLAIM_VALIDATION_RULE_TYPE.SUBSTANCE;
  itemIds: string[];
};
