import { LIBRARY_OBJECT_TYPES } from "utils/constants";
import { isFAndVOnly, isObjectEmpty } from "utils/general";
import {
  isLibraryObjectChapterSubType,
  isLibraryObjectClaimType,
  isLibraryObjectDraft,
  isLibraryObjectLocked,
  isLibraryObjectMaterialType,
  isLibraryObjectPackagingSystemsType,
  isLibraryObjectPublished,
  isLibraryObjectRequirement,
  isLibraryObjectTemplatesType,
  isProductTypeFAndV,
  isProductTypeFood,
} from "utils/library";
import {
  isClaimTypeNutritional,
  isClaimTypeRegulatedHealth,
} from "utils/claim";
import { LibraryItemState, LibraryObjectType } from "types/library";
import { LibraryItemActionItemViewModel } from "viewModels";
import { prepareLibraryItemActionItem } from "dtos/libraryItem";
import { ChapterTypeData } from "models";
import { MenuActions } from "types/general";
import { LibraryListFilterValue } from "types/filters";
import { messages } from "./messages";

interface LibraryItemActions extends MenuActions {
  shortName?: string;
}

export type LibraryActionType = {
  details?: LibraryItemActions;
  edit?: LibraryItemActions;
  createNewVersion?: LibraryItemActions;
  duplicate?: LibraryItemActions;
  delete?: LibraryItemActions;
};

export type GetActionsParams = {
  actions: LibraryActionType;
  state?: LibraryItemState;
  lastVersion?: boolean;
};

export const getRequirementActions = ({
  actions,
  state,
  lastVersion,
}: GetActionsParams) => {
  let requirementActions = [];

  if (actions.edit) {
    const { shortName, name, ...rest } = actions.edit;
    requirementActions.push({
      ...rest,
      label: shortName || name,
    });
  }

  if (actions.delete) {
    const { shortName, name, tooltipProps, ...rest } = actions.delete;
    requirementActions.push({
      ...rest,
      label: shortName || name,
      tooltipProps:
        isLibraryObjectDraft(state) || isLibraryObjectPublished(state)
          ? tooltipProps
          : {},
    });
  }

  if (actions.createNewVersion) {
    const { shortName, disabled, name, ...rest } = actions.createNewVersion;
    requirementActions.push({
      ...rest,
      label: shortName || name,
      disabled: disabled || !lastVersion,
    });
  }

  return requirementActions;
};

export const getMaterialActions = ({
  actions,
  lastVersion,
}: GetActionsParams) => {
  let materialActions = [];

  if (actions.details) {
    const { name } = actions.details;
    materialActions.push({ ...actions.details, label: name });
  }

  if (actions.edit) {
    const { shortName, name, ...rest } = actions.edit;
    materialActions.push({ ...rest, name, label: name });
  }

  if (actions.createNewVersion) {
    const { name, disabled } = actions.createNewVersion;
    materialActions.push({
      ...actions.createNewVersion,
      label: name,
      disabled: disabled || !lastVersion,
    });
  }

  return materialActions;
};

export const getChapterActions = ({
  actions,
  state,
  lastVersion,
}: GetActionsParams) => {
  let chapterActions = [];

  if (actions.edit) {
    const { shortName, ...rest } = actions.edit;
    chapterActions.push({
      ...rest,
      label: shortName,
    });
  }

  if (actions.duplicate) {
    const { shortName, ...rest } = actions.duplicate;
    chapterActions.push({
      ...rest,
      label: shortName,
    });
  }

  if (actions.createNewVersion) {
    const { name, disabled } = actions.createNewVersion;
    chapterActions.push({
      ...actions.createNewVersion,
      label: name,
      disabled: disabled || !lastVersion,
    });
  }

  if (actions.delete) {
    const { shortName, name, tooltipProps, ...rest } = actions.delete;
    chapterActions.push({
      ...rest,
      label: shortName || name,
      tooltipProps:
        isLibraryObjectDraft(state) || isLibraryObjectPublished(state)
          ? tooltipProps
          : {},
    });
  }

  return chapterActions;
};

export const getClaimActions = ({
  actions,
  state,
  lastVersion,
}: GetActionsParams) => {
  let claimActions = [];

  if (actions.edit) {
    const { shortName, name, ...rest } = actions.edit;
    claimActions.push({
      ...rest,
      label: shortName || name,
    });
  }

  if (actions.createNewVersion) {
    const { name, disabled } = actions.createNewVersion;
    claimActions.push({
      ...actions.createNewVersion,
      label: name,
      disabled: disabled || !lastVersion,
    });
  }

  if (actions.delete) {
    const { shortName, name, tooltipProps, ...rest } = actions.delete;
    claimActions.push({
      ...rest,
      label: shortName || name,
      tooltipProps:
        isLibraryObjectDraft(state) || isLibraryObjectPublished(state)
          ? tooltipProps
          : {},
    });
  }

  return claimActions;
};

export const getTemplateActions = ({ actions, state }: GetActionsParams) => {
  let templateActions = [];

  if (actions.delete) {
    const { name } = actions.delete;
    templateActions.push({
      ...actions.delete,
      label: name,
      disabled: isLibraryObjectLocked(state),
    });
  }

  return templateActions;
};

export const getActionsListForLibraryItem = ({
  actions,
  type,
  state,
  lastVersion,
}: {
  actions: LibraryActionType;
  type: string;
  state: LibraryItemState;
  lastVersion: boolean;
}) => {
  if (isObjectEmpty(actions)) return [];

  if (isLibraryObjectRequirement(type)) {
    return getRequirementActions({ actions, state, lastVersion });
  }

  if (isLibraryObjectMaterialType(type)) {
    return getMaterialActions({ actions, lastVersion });
  }

  if (isLibraryObjectChapterSubType(type)) {
    return getChapterActions({ actions, state, lastVersion });
  }

  if (isLibraryObjectClaimType(type)) {
    return getClaimActions({ actions, state, lastVersion });
  }

  if (isLibraryObjectTemplatesType(type) && !isLibraryObjectLocked(state)) {
    return getTemplateActions({ actions, state });
  }

  return [];
};

export const shouldNoSearchResultMessageBeDisplayed = ({
  isListLoading,
  totalCount,
  searchFilterName,
}: {
  isListLoading?: boolean;
  totalCount?: number;
  searchFilterName?: string;
}) => !isListLoading && !!searchFilterName && totalCount === 0;

export const shouldDisplayImportButton = ({
  type,
}: {
  type: LIBRARY_OBJECT_TYPES;
}) => isClaimTypeRegulatedHealth(type) || isClaimTypeNutritional(type);

export const shouldDisplayCreateButton = ({
  hasCreateButton,
  type,
}: {
  hasCreateButton?: boolean;
  type: LIBRARY_OBJECT_TYPES;
}) =>
  hasCreateButton &&
  !isLibraryObjectPackagingSystemsType(type) &&
  !isClaimTypeNutritional(type) &&
  !isClaimTypeRegulatedHealth(type);

export const shouldDisplayActions = ({
  type,
  defaultDisplayActions,
}: {
  type: LibraryObjectType;
  defaultDisplayActions: boolean;
}) => {
  const libraryItemsWithoutActions: LibraryObjectType[] = [
    LIBRARY_OBJECT_TYPES.PACKAGING_SYSTEMS,
  ];

  if (libraryItemsWithoutActions.includes(type)) return false;

  return defaultDisplayActions;
};

export const getOnLibraryItemClick = ({
  libraryItem,
  redirectToLibraryDetails,
  redirectToLibraryEdition,
  onItemClick,
}: {
  libraryItem: LibraryItemActionItemViewModel;
  redirectToLibraryDetails: Function;
  redirectToLibraryEdition: Function;
  onItemClick?: Function;
}) => () => {
  if (onItemClick) {
    return onItemClick(libraryItem);
  }

  const { id, type } = prepareLibraryItemActionItem(libraryItem);

  if (isLibraryObjectPackagingSystemsType(libraryItem.type)) {
    return redirectToLibraryEdition({
      id,
      type,
    });
  }

  return redirectToLibraryDetails({
    id,
    type,
  });
};

export const getChaptersForFilter = (chapterTypes: ChapterTypeData[]) =>
  chapterTypes
    .reduce(
      (acc, { isChapterLibrary, chapterType, chapterName, productTypeId }) => {
        if (isChapterLibrary) {
          if (isFAndVOnly) {
            return [
              ...acc,
              isProductTypeFAndV(productTypeId) && {
                label: chapterName,
                value: chapterType,
              },
            ];
          }
          return [
            ...acc,
            isProductTypeFood(productTypeId) && {
              label: chapterName,
              value: chapterType,
            },
          ];
        }
        return acc;
      },
      []
    )
    .filter(Boolean);

export const getSearchPlaceholderMessage = ({
  searchPlaceholder,
  libraryItemType,
  formatMessage,
}: {
  libraryItemType: LIBRARY_OBJECT_TYPES;
  searchPlaceholder?: string;
  formatMessage?: Function;
}): string => {
  if (searchPlaceholder) {
    return searchPlaceholder;
  }

  if (isLibraryObjectClaimType(libraryItemType)) {
    return formatMessage(messages.claimSearchPlaceholder);
  }

  return formatMessage(messages.searchPlaceholder);
};

export const getFilterValue = (
  filterValue: LibraryListFilterValue,
  type: LibraryObjectType
): string => {
  if (isClaimTypeNutritional(type) && filterValue === "name") {
    return "claimSentence";
  } else if (isLibraryObjectClaimType(type) && filterValue === "name") {
    return "claimName";
  }
  return filterValue as string;
};

export const getSearchTermParam = ({
  type,
  searchTerm,
}: {
  type: LIBRARY_OBJECT_TYPES;
  searchTerm: string;
}) => {
  if (isLibraryObjectClaimType(type) || isClaimTypeNutritional(type)) {
    return { searchTerms: searchTerm };
  }

  return { name: searchTerm };
};

export const shouldDisplayClaimStatusFilter = ({
  type,
  isLibraryListingPage,
  claimsRegulationAdministrate,
}: {
  isLibraryListingPage: boolean;
  type: LIBRARY_OBJECT_TYPES;
  claimsRegulationAdministrate: boolean;
}): boolean => {
  if (!isLibraryListingPage) {
    return false;
  }

  if (
    isLibraryObjectClaimType(type) ||
    (isClaimTypeNutritional(type) && claimsRegulationAdministrate)
  ) {
    return true;
  }

  return false;
};
