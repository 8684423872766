import {
  PackagingComponentSubstanceData,
  SubstanceData,
  SubstanceRegulationData,
} from "models";
import { isListEmpty } from "utils/general";
import { isSubstanceRestricted } from "utils/packaging";
import { PackagingComponentSubstanceViewModel } from "viewModels";

export const getRestrictedSubstances = ({
  substanceInformationCollection,
  substanceRegulations,
  substances,
}: {
  substanceInformationCollection: SubstanceData[];
  substanceRegulations: SubstanceRegulationData[];
  substances: PackagingComponentSubstanceViewModel[];
}) => {
  if (
    isListEmpty(substanceInformationCollection) ||
    isListEmpty(substances) ||
    isListEmpty(substanceRegulations)
  ) {
    return [];
  }

  const substanceInformation = substances.map(({ substanceId, ...rest }) => {
    const { name, casNumbers } = substanceInformationCollection.find(
      ({ id }) => substanceId === id
    );

    const { regulations = {} } =
      substanceRegulations.find(({ id }) => substanceId === id) || {};

    const { agec } = regulations;
    const isRestricted = isSubstanceRestricted(regulations);

    return {
      name,
      casNumbers,
      substanceId,
      isRestricted,
      isEndocrineDisruptor: agec?.isHighConcernByArticle59,
      ...rest,
    };
  });

  return substanceInformation.filter(({ isRestricted }) => isRestricted);
};

export const addComponentDetailToSubstance = ({
  substance: { presentAtNanoscale, substanceId, percentage },
  componentId,
  componentName,
}: {
  substance: PackagingComponentSubstanceData;
  componentId: string;
  componentName: string;
}) => ({
  substanceId,
  percentage,
  presentAtNanoscale,
  componentId,
  componentName,
});
