import { useEffect, useState } from "react";

export type UseIsScrollableProps = {
  className: string;
};

const useIsScrollable = ({ className }: UseIsScrollableProps) => {
  const [isScrollable, setIsScrollable] = useState(false);

  useEffect(() => {
    const scrollableElement = document.querySelector(className);

    if (scrollableElement) {
      const checkScrollable = () => {
        const { scrollHeight, clientHeight } = scrollableElement;
        setIsScrollable(scrollHeight > clientHeight);
      };

      checkScrollable(); // Check initially

      const resizeObserver = new ResizeObserver(checkScrollable);
      resizeObserver.observe(scrollableElement);

      return () => {
        resizeObserver.disconnect();
      };
    }
  }, []);

  return {
    isScrollable,
  };
};

export default useIsScrollable;
