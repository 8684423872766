import { Regulations } from "models";
import { RECYCLABILITY_REGULATION_CODES } from "./constants";

export const isRecyclabilityRegulationCodeEU = (code: string) =>
  code === RECYCLABILITY_REGULATION_CODES.EU;

export const isRecyclabilityRegulationCodeREACH = (code: string) =>
  code === RECYCLABILITY_REGULATION_CODES.REACH;

export const isRecyclabilityRegulationCodeAGEC = (code: string) =>
  code === RECYCLABILITY_REGULATION_CODES.AGEC;

export const isSubstanceRestricted = ({
  agec,
  eu,
  reach,
}: Regulations): boolean =>
  reach?.isRestricted || eu?.isRestricted || agec?.isRestricted;
