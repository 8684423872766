import { Row, Col } from "antd";
import { Paragraph, Heading } from "@trace-one/design-system";
import classNames from "classnames";
import styles from "./styles.module.less";

export type ColProps = {
  xs?: number;
  span?: number;
  md?: number;
};

const FieldReadOnly = ({
  title,
  content,
  isLastRow = false,
  uiLabelCol,
}: {
  title: string;
  content: string;
  isLastRow?: boolean;
  uiLabelCol?: ColProps;
}) => {
  const { xs, md } = uiLabelCol || {};

  const labelColumnXS = xs ?? 4;
  const valueColumnXS = xs ? 24 - xs : 20;

  const labelColumnMd = md ?? 8;
  const valueColumMd = md ? 24 - md : 16;

  return (
    <Row
      className={classNames({ [styles.fieldReadOnlyRow]: !isLastRow }, "h-100")}
      align="middle"
    >
      <Col xs={labelColumnXS} md={labelColumnMd}>
        <Heading size="xxs" color="grey-5">
          {title}
        </Heading>
      </Col>
      <Col xs={valueColumnXS} md={valueColumMd}>
        <Paragraph
          size="m"
          color="grey-4"
          dangerouslySetInnerHTML={{ __html: content }}
        />
      </Col>
    </Row>
  );
};

export default FieldReadOnly;
