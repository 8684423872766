import { createContext, useContext } from "react";
import { UiSchema } from "@rjsf/core";
import {
  FormContext,
  ObjectSchema,
} from "components/SpecificationSection/types";
import { PackagingSubstanceFormData } from "models";
import { PackagingSubstanceTableViewModel } from "viewModels";

export type SubstancesTableContextType = {
  schema: ObjectSchema;
  uiSchema: UiSchema;
  onRemove?: (_: string) => Promise<void>;
  removing?: boolean;
  actionsDisabled?: boolean;
  formData?: PackagingSubstanceFormData;
  formContext?: FormContext;
  onOpenModal?: (_: PackagingSubstanceTableViewModel) => () => void;
};

export const initialSubstancesTableContextValue: SubstancesTableContextType = {
  schema: {},
  uiSchema: {},
  onRemove: async () => {},
  removing: false,
  actionsDisabled: false,
  formData: { substances: [] },
  onOpenModal: () => () => {},
};

const SubstancesTableContext = createContext<SubstancesTableContextType>(
  initialSubstancesTableContextValue
);

export const SubstancesTableContextProvider = SubstancesTableContext.Provider;

export const useSubstancesTableContext = () => {
  const context = useContext(SubstancesTableContext);

  return context;
};
