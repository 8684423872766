import RequirementDetails from "./components/RequirementDetails";
import MaterialDetails from "./components/MaterialDetails";
import ChapterDetails from "./components/ChapterDetails";
import TemplateDetails from "./components/TemplateDetails";
import ClaimDetails from "./components/ClaimDetails";
import {
  isLibraryObjectChapterSubType,
  isLibraryObjectClaimType,
  isLibraryObjectMaterialType,
  isLibraryObjectRequirement,
  isLibraryObjectTemplatesType,
} from "utils/library";
import { ContentProps } from "./types";
import { LibraryObjectType } from "types/library";
import { isClaimTypeNutritional } from "utils/claim";

const Content: React.FC<ContentProps> = ({ libraryItemDetails }) => {
  const { materialType, generalInformation } = libraryItemDetails;

  if (isLibraryObjectRequirement(generalInformation?.type)) {
    return <RequirementDetails />;
  }

  if (isLibraryObjectMaterialType(materialType)) {
    return <MaterialDetails />;
  }

  if (
    isLibraryObjectChapterSubType(generalInformation?.type as LibraryObjectType)
  ) {
    return <ChapterDetails />;
  }

  if (isLibraryObjectTemplatesType(generalInformation?.type)) {
    return <TemplateDetails />;
  }

  if (
    isLibraryObjectClaimType(generalInformation?.type) ||
    isClaimTypeNutritional(generalInformation?.type)
  ) {
    return <ClaimDetails />;
  }

  return null;
};

export default Content;
