import React from "react";
import { Col, Form, Row } from "antd";
import { Select } from "@trace-one/design-system";
import RowTitle from "components/RowTitle";
import { useRelativeNetContent } from "../../hooks";
import { useFormSchemaTranslation } from "pages/Specification/components/Details/hooks";
import { ProductInformationFormData } from "components/SpecificationSection/components/FormSchema/types";
import {
  FormContext,
  ObjectFieldTemplateProps,
  TemplateProperty,
} from "components/SpecificationSection/types";
import { Dictionary } from "types/general";

export type RelativeNetContentProps = {
  formData: ProductInformationFormData;
  formContext: FormContext;
  uiSchema: ObjectFieldTemplateProps["uiSchema"];
  propertyObjects: Dictionary<TemplateProperty>;
};

const RelativeNetContent: React.FC<RelativeNetContentProps> = ({
  formData,
  formContext,
  uiSchema,
  propertyObjects,
}) => {
  const { getTranslationForKey } = useFormSchemaTranslation();

  const displayEMarkOnPackPropertyId =
    propertyObjects?.displayEMarkOnPack?.content?.props?.schema?.propertyId;

  const {
    options,
    value,
    disabled,
    hasEMarkError,
    onChange,
    onClear,
  } = useRelativeNetContent({
    formData,
    formContext,
    displayEMarkOnPackPropertyId,
  });

  return (
    <>
      <RowTitle title={getTranslationForKey("eMarkRowTitle")} />
      <Row>
        <Col span={23}>
          <Row align="middle" justify="space-between">
            <Col span={22}>
              <Form.Item
                label={getTranslationForKey("relativeNetContentTitle")}
                labelAlign="left"
                labelCol={{ ...uiSchema?.displayEMarkOnPack?.["ui:label:col"] }}
                wrapperCol={{
                  ...uiSchema?.displayEMarkOnPack?.["ui:wrapper:col"],
                }}
                className="mb-0"
              >
                <Select
                  options={options}
                  value={value}
                  disabled={disabled}
                  placeholder={getTranslationForKey(
                    "relativeNetContentPlaceholder"
                  )}
                  data-test-id="select-eMark-netContent"
                  onChange={onChange}
                  onClear={onClear}
                  filterOption={false}
                  error={hasEMarkError}
                  {...(hasEMarkError && {
                    errorMessage: getTranslationForKey("ValidationRequired"),
                  })}
                />
              </Form.Item>
            </Col>
            <Col span={2}>{propertyObjects?.displayEMarkOnPack?.content}</Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default RelativeNetContent;
