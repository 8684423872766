import { useState, useEffect, useRef, Dispatch, SetStateAction } from "react";
import { useParams } from "react-router-dom";
import cleanDeep from "clean-deep";
import { merge } from "lodash";
import {
  createSpecificationCollaboration,
  createSpecificationContact,
  deleteSpecificationCollaboration,
  deleteSpecificationContact,
  setSignatoryForSpecificationContact,
  updateSpecificationChapterBlock,
  createSpecificationSharedDocuments,
  deleteSpecificationSharedDocument,
  fetchSpecificationContacts,
  updateSpecificationLabeling,
} from "apis/SPEC";
import { useAppDispatch } from "store";
import { setIsSpecificationUpdating } from "store/specificationDetails/specificationDetailsSlice";
import {
  CHAPTER_SECTION_CODE_API,
  CHAPTER_TYPES_API,
  MAX_QUEUE_ITEMS_IN_PROCESS_SPEC,
  SECTION_CODE,
} from "utils/constants";
import { getParsedData } from "utils/general";
import {
  GetSaveTemplateBlockBySectionSignature,
  OnUpdateClaimsSignature,
  OnUpdateFinalPageProofSignature,
  OnUpdateIngredientListSignature,
  OnUpdateNutriScoreSignature,
  OnUpdateNutritionalAnalysisSignature,
  OnUpdateNutritionDeclarationSignature,
  UpdateSitesActionSignature,
} from "./types";
import { EnhancedExtraActions } from "components/SpecificationSection/types";
import { ParamTypes } from "pages/Specification/components/Details/types";
import {
  AddCollaborationBody,
  ChapterStructureBlockData,
  NutriscoreFormData,
} from "models";
import { SitesFormData } from "components/SpecificationSection/components/FormSchema/types";

const useSectionActions = ({
  chapterType,
  specificationId,
  refetchChapter,
  resetSectionValidationErrors,
  sectionId,
  setSectionBlocks,
  apiBlocks = [],
}: {
  specificationId: string;
  sectionId: string;
  chapterType: string;
  refetchChapter: () => Promise<void>;
  resetSectionValidationErrors: Function;
  setSectionBlocks?: Dispatch<
    SetStateAction<ChapterStructureBlockData[] | null>
  >;
  apiBlocks: ChapterStructureBlockData[];
}) => {
  const [isSavingInProgress, setIsSavingInProgress] = useState(false);
  const blocksRef = useRef(apiBlocks);
  const queueRef = useRef(0);

  const dispatch = useAppDispatch();

  const {
    sectionCode: currentChapterApiType = chapterType,
  } = useParams<ParamTypes>();

  const currentChapterApiTypeRef = useRef(currentChapterApiType);

  useEffect(() => {
    currentChapterApiTypeRef.current = currentChapterApiType;
  }, [currentChapterApiType]);

  useEffect(() => {
    blocksRef.current = apiBlocks;
  }, [JSON.stringify(apiBlocks)]);

  const refetchCurrentChapter = async (sectionCode: string = "") => {
    resetSectionValidationErrors(sectionId);

    if (
      currentChapterApiTypeRef.current === CHAPTER_TYPES_API[chapterType] ||
      (!chapterType && CHAPTER_SECTION_CODE_API[sectionCode])
    ) {
      await refetchChapter();
    }
  };

  const getSaveTemplateBlockBySection: GetSaveTemplateBlockBySectionSignature = sectionCode => blockId => async json => {
    try {
      setIsSavingInProgress(true);
      dispatch(setIsSpecificationUpdating(true));
      const apiBlock = blocksRef?.current?.find(
        ({ blockId: id }) => blockId === id
      );
      const isDeadlinePlatformAcceptanceSection =
        sectionCode === SECTION_CODE.DEADLINE_PLATFORM_ACCEPTANCE;
      let updatedData;

      setSectionBlocks?.(prevState => {
        return (prevState || []).map(block => {
          if (block.blockId === blockId) {
            const inputData = getParsedData(json);
            const existingData = getParsedData(apiBlock?.dataAsJson);

            if (isDeadlinePlatformAcceptanceSection) {
              updatedData = merge(existingData, inputData);
            } else {
              updatedData = { ...existingData, ...inputData };
            }

            return {
              ...block,
              dataAsJson: JSON.stringify(updatedData),
            };
          }
          return block;
        });
      });

      await updateSpecificationChapterBlock({
        specificationId,
        chapterType:
          CHAPTER_SECTION_CODE_API[sectionCode] ||
          CHAPTER_TYPES_API[chapterType],
        blockId,
        json: isDeadlinePlatformAcceptanceSection
          ? JSON.stringify(updatedData)
          : json,
      });

      await refetchCurrentChapter(sectionCode);
    } catch (e) {
    } finally {
      setIsSavingInProgress(false);
      dispatch(setIsSpecificationUpdating(false));
    }
  };

  const addContactAction: EnhancedExtraActions["addContactAction"] = async ({
    userId,
    signatory,
    contactType,
  }) => {
    try {
      setIsSavingInProgress(true);

      await createSpecificationContact({
        specificationId,
        userId,
        signatory,
        contactType,
      });

      await refetchCurrentChapter();
    } catch (e) {
    } finally {
      setIsSavingInProgress(false);
    }
  };

  const removeContactAction: EnhancedExtraActions["removeContactAction"] = async ({
    userId,
  }) => {
    try {
      setIsSavingInProgress(true);

      await deleteSpecificationContact({
        specificationId,
        userId,
      });

      await refetchCurrentChapter();
    } catch (e) {
    } finally {
      setIsSavingInProgress(false);
    }
  };

  const setSignatoryContactAction: EnhancedExtraActions["setSignatoryContactAction"] = async ({
    userId,
    signatory,
  }) => {
    try {
      setIsSavingInProgress(true);

      await setSignatoryForSpecificationContact({
        userId,
        signatory,
        specificationId,
      });

      await refetchCurrentChapter();
    } catch (e) {
    } finally {
      setIsSavingInProgress(false);
    }
  };

  const setSpecificationCollaborationAction: EnhancedExtraActions["setSpecificationCollaborationAction"] = async (
    payload: AddCollaborationBody[]
  ) => {
    try {
      setIsSavingInProgress(true);

      await createSpecificationCollaboration({
        specificationId,
        payload,
      });

      await refetchCurrentChapter();
    } catch (e) {
    } finally {
      setIsSavingInProgress(false);
    }
  };

  const removeSpecificationCollaborationAction: EnhancedExtraActions["removeSpecificationCollaborationAction"] = async collaborationId => {
    try {
      setIsSavingInProgress(true);

      await deleteSpecificationCollaboration({
        specificationId,
        collaborationId,
      });

      await refetchCurrentChapter();
    } catch (e) {
    } finally {
      setIsSavingInProgress(false);
    }
  };

  const uploadFilesAction: EnhancedExtraActions["uploadFilesAction"] = async files => {
    try {
      setIsSavingInProgress(true);

      await createSpecificationSharedDocuments({
        specificationId,
        fileIds: files.map(({ id }) => id),
      });

      await refetchChapter();
    } catch (e) {
    } finally {
      setIsSavingInProgress(false);
    }
  };

  const removeFileAction: EnhancedExtraActions["removeFileAction"] = async file => {
    try {
      setIsSavingInProgress(true);

      await deleteSpecificationSharedDocument({
        specificationId,
        fileId: file.id,
      });

      await refetchChapter();
    } catch (e) {
    } finally {
      setIsSavingInProgress(false);
    }
  };

  const updateSitesAction: UpdateSitesActionSignature = (
    blockId: string
  ) => async (payload: SitesFormData) => {
    try {
      setIsSavingInProgress(true);
      resetSectionValidationErrors(sectionId);

      await updateSpecificationChapterBlock({
        specificationId,
        chapterType: CHAPTER_TYPES_API[chapterType],
        blockId,
        json: JSON.stringify(payload),
      });

      await refetchChapter();
    } catch (e) {
    } finally {
      setIsSavingInProgress(false);
    }
  };

  const updateNutritionDeclarationAction: OnUpdateNutritionDeclarationSignature = blockId => async ({
    data,
  }) => {
    try {
      setIsSavingInProgress(true);

      await updateSpecificationLabeling({
        specificationId,
        payload: {
          blockId,
          json: JSON.stringify(data),
        },
      });

      await refetchCurrentChapter();
    } catch (e) {
    } finally {
      setIsSavingInProgress(false);
    }
  };

  const onUpdateIngredientList: OnUpdateIngredientListSignature = blockId => async (
    ingredientData
  ): Promise<void> => {
    try {
      setIsSavingInProgress(true);

      await updateSpecificationLabeling({
        specificationId,
        payload: {
          blockId,
          json: JSON.stringify(ingredientData),
        },
      });

      await refetchChapter();
    } catch (e) {
    } finally {
      setIsSavingInProgress(false);
    }
  };

  const onUpdateClaims: OnUpdateClaimsSignature = blockId => async ({
    healthClaims,
    marketingClaims,
    nutritionalClaims,
  }): Promise<void> => {
    try {
      queueRef.current += 1;
      setIsSavingInProgress(true);

      const dataAsJson = JSON.stringify({
        healthClaims,
        marketingClaims,
        nutritionalClaims,
      });

      setSectionBlocks?.(prevState => {
        return (prevState || []).map(block => {
          if (block.blockId === blockId) {
            return {
              ...block,
              dataAsJson,
            };
          }
          return block;
        });
      });

      await updateSpecificationLabeling({
        specificationId,
        payload: {
          blockId,
          json: dataAsJson,
        },
      });

      await refetchCurrentChapter();
    } catch (e) {
      throw e;
    } finally {
      setIsSavingInProgress(false);
      queueRef.current -= 1;
    }
  };

  const onUpdateNutriscore: OnUpdateNutriScoreSignature = (
    blockId: string
  ) => async (payload: NutriscoreFormData): Promise<void> => {
    try {
      setIsSavingInProgress(true);

      await updateSpecificationLabeling({
        specificationId,
        payload: {
          blockId,
          json: JSON.stringify(cleanDeep(payload)),
        },
      });

      await refetchCurrentChapter();
    } catch (e) {
    } finally {
      setIsSavingInProgress(false);
    }
  };

  const onUpdateFinalPageProof: OnUpdateFinalPageProofSignature = (
    blockId: string
  ) => async (json: string): Promise<void> => {
    try {
      setIsSavingInProgress(true);

      setSectionBlocks?.(prevState => {
        return (prevState || []).map(block => {
          if (block.blockId === blockId) {
            return {
              ...block,
              dataAsJson: json,
            };
          }
          return block;
        });
      });

      await updateSpecificationLabeling({
        specificationId,
        payload: { blockId, json },
      });

      await refetchCurrentChapter();
    } catch (e) {
    } finally {
      setIsSavingInProgress(false);
    }
  };

  const onUpdateNutritionalAnalysis: OnUpdateNutritionalAnalysisSignature = (
    blockId: string
  ) => async (json: string): Promise<void> => {
    try {
      setIsSavingInProgress(true);

      await updateSpecificationLabeling({
        specificationId,
        payload: { blockId, json },
      });

      await refetchCurrentChapter();
    } catch (e) {
    } finally {
      setIsSavingInProgress(false);
    }
  };

  const getSpecificatioContacts: EnhancedExtraActions["getSpecificatioContacts"] = async () => {
    try {
      const data = await fetchSpecificationContacts({
        specificationId,
      });

      return data;
    } catch (e) {}
  };

  return {
    isSavingInProgress,
    getSaveTemplateBlockBySection,
    addContactAction,
    removeContactAction,
    setSignatoryContactAction,
    setSpecificationCollaborationAction,
    removeSpecificationCollaborationAction,
    uploadFilesAction,
    removeFileAction,
    updateSitesAction,
    updateNutritionDeclarationAction,
    onUpdateIngredientList,
    onUpdateClaims,
    onUpdateNutriscore,
    onUpdateFinalPageProof,
    onUpdateNutritionalAnalysis,
    getSpecificatioContacts,
    disableExtraActions: queueRef.current > MAX_QUEUE_ITEMS_IN_PROCESS_SPEC,
  };
};

export default useSectionActions;
