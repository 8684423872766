import { useSelector } from "react-redux";
import { selectLanguageCode } from "store/user/selectors";
import {
  axiosInstanceWithoutErrorInterception,
  downloadClaimTemplate,
  importClaim,
} from "apis/SPEC";
import { downloadFileWithCustomName, openBlobInNewWindow } from "utils/files";
import {
  LIBRARY_OBJECT_API_TYPES,
  LIBRARY_OBJECT_TYPES,
} from "utils/constants";
import { isClaimTypeRegulatedHealth } from "utils/claim";

export type UseImportClaimProps = {
  claimType: LIBRARY_OBJECT_TYPES;
};

const useImportClaim = ({ claimType }: UseImportClaimProps) => {
  const languageCode = useSelector(selectLanguageCode);

  const apiClaimType = isClaimTypeRegulatedHealth(claimType)
    ? LIBRARY_OBJECT_API_TYPES.health
    : LIBRARY_OBJECT_API_TYPES[claimType];

  const onDownloadTemplate = async () => {
    try {
      const { data } = await downloadClaimTemplate({
        claimType: apiClaimType,
      });

      if (data) {
        openBlobInNewWindow({ data, type: "text/csv" });
      }
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  const onDownloadErrorFile = ({
    errorData,
    fileName,
  }: {
    errorData: BlobPart;
    fileName: string;
  }) => () => {
    if (!fileName) {
      return;
    }

    try {
      downloadFileWithCustomName({
        data: errorData,
        fileType: "txt",
        fileName,
      });
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  const onImportConfirmation = importClaim({
    claimType: apiClaimType,
    languageCode,
    axiosInstance: axiosInstanceWithoutErrorInterception,
  });

  return {
    onDownloadTemplate,
    onImportConfirmation,
    onDownloadErrorFile,
  };
};

export default useImportClaim;
