import React, { useState } from "react";
import { Typography, Icon } from "@trace-one/design-system";
import classNames from "classnames";
import styles from "./styles.module.less";

export interface SidebarItem {
  label: string;
  value: string;
}

export interface SidebarProps {
  items: SidebarItem[];
  selected?: string;
  name: string;
  getSelectedMenuItem?: (menuItem: string) => void;
  className?: string;
}

const Sidebar: React.FC<SidebarProps> = ({
  items,
  selected,
  name,
  getSelectedMenuItem,
  className,
}) => {
  const [selectedMenuItem, setSelectedMenuItem] = useState<string>(
    selected || items[0]?.value
  );

  const onMenuClick = (selectedMenuItem: string) => () => {
    setSelectedMenuItem(selectedMenuItem);
    getSelectedMenuItem?.(selectedMenuItem);
  };

  return (
    <div
      data-test-id="sidebar"
      className={classNames(styles["sidebar"], { [className]: !!className })}
    >
      <div className={styles["sidebar-header"]}>
        <Typography variant="heading-xs">{name}</Typography>
      </div>
      <ul data-test-id="sidebar-list" className={styles["sidebar-list"]}>
        {items?.map(item => (
          <li
            key={item.value}
            data-test-id="sidebar-item"
            className={classNames(styles["sidebar-item"], {
              [styles["active"]]: item?.value === selectedMenuItem,
            })}
            onClick={onMenuClick(item.value)}
          >
            <div className="d-flex jc-space-between ai-center">
              <Typography
                data-test-id={item.value}
                className={styles["sidebar-item-label"]}
              >
                {item.label}
              </Typography>
              <Icon name="arrow-right" size="small"></Icon>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Sidebar;
