import { MaterialData } from "models";
import { State } from "./types";
import { MaterialInformationViewModel, RawMaterialViewModel } from "viewModels";
import { prepareMaterialFromApiData } from "dtos/material";

export const checkIfMaterialIsCreated = (
  materialInformation: MaterialInformationViewModel
) => {
  if (!materialInformation.id) {
    throw new Error("material should be created first");
  }
};

export const prepareFormState = (state: State, materialData: MaterialData) => {
  const {
    materialInformation,
    rawMaterial,
    suppliers,
    allergens,
    recipe,
    fileIds,
    substanceId,
    files,
    certificateIds,
    certificates,
  } = prepareMaterialFromApiData(materialData);

  state.materialInformation = materialInformation;
  state.substanceId = substanceId;
  state.rawMaterial = rawMaterial;
  state.suppliers = suppliers;
  state.allergens = allergens;
  state.recipe = recipe;
  state.fileIds = fileIds;
  state.files = files;
  state.certificateIds = certificateIds;
  state.certificates = certificates;
};

export const getActionStatus = (state: State) => {
  if (state.materialInformation.id) {
    return state.updateStatus;
  }

  return state.creationStatus;
};

export const prepareUpdateMaterialPayload = ({
  materialInformation,
  rawMaterial,
}: {
  materialInformation: MaterialInformationViewModel;
  rawMaterial: RawMaterialViewModel;
}) => {
  return {
    name: materialInformation.name,
    active: materialInformation.active,
    internalCode: materialInformation.internalCode,
    classificationId: rawMaterial?.classificationId,
    partId: rawMaterial.partId,
  };
};
