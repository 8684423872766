import { useEffect } from "react";
import { Form } from "antd";
import { useIntl } from "react-intl";
import {
  Input,
  InputNumber,
  Toggle,
  Paragraph,
} from "@trace-one/design-system";
import ConfirmationTooltip from "components/ConfirmationTooltip";
import ConditionalWrapper from "components/ConditionalWrapper";
import {
  generalMessages,
  inputErrorMessages,
  libraryMessages,
  nutritionMessages,
} from "messages";
import { messages } from "../../messages";
import { NutritionServingFormProps } from "./types";
import styles from "./styles.module.less";

const NutritionServingForm: React.FC<NutritionServingFormProps> = ({
  nutritionServingFormData,
  setFormErrors,
  selectedUnit,
  items,
  buttonProps,
  handleServingNameChange,
  handleServingSizeChange,
  handleAutomaticCalculationChange,
  onClearUnit,
  isCalculationToggleDisabled,
  isEditMode,
  schema = {},
  readOnlyFieldIds = [],
}) => {
  const { formatMessage } = useIntl();
  const [form] = Form.useForm();

  useEffect(() => {
    form.setFieldsValue(nutritionServingFormData);
  }, [nutritionServingFormData]);

  const servingNamePropertyId =
    // @ts-ignore
    schema?.properties?.servingDefinitions?.items?.properties?.name?.propertyId;
  const isServingNameDisabled = readOnlyFieldIds.includes(
    servingNamePropertyId
  );
  const servingSizeValuePropertyId = // @ts-ignore
    schema?.properties?.servingDefinitions?.items?.properties?.size?.properties
      ?.value?.propertyId;
  const isServingSizeValueDisabled = readOnlyFieldIds.includes(
    servingSizeValuePropertyId
  );
  const servingSizeUnitPropertyId =
    // @ts-ignore
    schema?.properties?.servingDefinitions?.items?.properties?.size?.properties
      ?.unitId?.propertyId;
  const isServingSizeUnitDisabled = readOnlyFieldIds.includes(
    servingSizeUnitPropertyId
  );
  const servingAutomaticCalculationPropertyId =
    // @ts-ignore
    schema?.properties?.servingDefinitions?.items?.properties
      ?.automaticCalculation?.propertyId;
  const isServingAutomaticCalculationDisabled =
    readOnlyFieldIds.includes(servingAutomaticCalculationPropertyId) ||
    isCalculationToggleDisabled;

  const renderWrappedComponent = (children: React.ReactElement) => (
    <ConfirmationTooltip
      onConfirmation={() =>
        handleAutomaticCalculationChange(
          !nutritionServingFormData?.automaticCalculation
        )
      }
      text={formatMessage(nutritionMessages.editServingConfirmation)}
      confirmButtonText={formatMessage(generalMessages.confirm)}
      cancelButtonText={formatMessage(generalMessages.cancel)}
      placement="right"
    >
      {children}
    </ConfirmationTooltip>
  );

  return (
    <Form
      name="nutritionServing"
      layout="horizontal"
      form={form}
      onFieldsChange={() => setFormErrors(form.getFieldsError())}
      validateMessages={{
        required: formatMessage(libraryMessages.formRequired),
        whitespace: formatMessage(libraryMessages.formRequired),
        string: {
          max: formatMessage(libraryMessages.formMaxCharLength, {
            max: 64,
          }),
        },
        number: {
          min: formatMessage(inputErrorMessages.minimumNumber, {
            min: 0,
          }),
        },
      }}
      labelAlign="left"
      labelCol={{ span: 24 }}
      wrapperCol={{ span: 24 }}
    >
      <Form.Item
        name="servingName"
        label={formatMessage(messages.servingName)}
        rules={[{ required: true, whitespace: true, max: 64 }]}
      >
        <Input
          placeholder={formatMessage(messages.servingNamePlaceholder)}
          onChange={handleServingNameChange}
          disabled={isServingNameDisabled}
        />
      </Form.Item>
      <Form.Item
        name="servingSize"
        label={formatMessage(messages.servingSize)}
        rules={[{ type: "number", required: true, min: 0.001 }]}
        wrapperCol={{ span: 14 }}
      >
        <InputNumber.Dual
          placeholder={0}
          onChange={handleServingSizeChange}
          step={0.001}
          precision={3}
          buttonValue={selectedUnit?.text}
          items={items}
          buttonProps={{ ...buttonProps }}
          onClearButton={onClearUnit}
          className={styles.dualInput}
          disabledButton={isServingSizeUnitDisabled}
          disabledInput={isServingSizeValueDisabled}
        />
      </Form.Item>
      <Form.Item label={formatMessage(messages.automaticCalculation)}>
        {isEditMode && (
          <Paragraph
            size="s"
            className={styles.automaticCalculationDescription}
          >
            {formatMessage(messages.automaticCalculationDescription)}
          </Paragraph>
        )}

        <Form.Item name="automaticCalculation" noStyle>
          <ConditionalWrapper
            condition={isEditMode}
            wrapper={renderWrappedComponent}
          >
            <Toggle
              data-testid="automatic-calculation-switch"
              checked={nutritionServingFormData?.automaticCalculation}
              disabled={isServingAutomaticCalculationDisabled}
              {...(!isEditMode && {
                onChange: handleAutomaticCalculationChange,
              })}
            />
          </ConditionalWrapper>
        </Form.Item>
      </Form.Item>
    </Form>
  );
};

export default NutritionServingForm;
