import { useRef } from "react";
// import { useIntl } from "react-intl";
import { MATERIAL_TYPES } from "utils/constants";
import { useMaterialDetails, useScrollToSection } from "../../hooks";
import Suppliers from "../Suppliers";
import Characteristics from "../Characteristics";
import Allergens from "../Allergens";
import RawMaterial from "./components/RawMaterial";
import ControlPlans from "../ControlPlans";
import Attachment from "../Attachment";
// import FormCard from "../FormCard";
// import Certificates from "components/Certificates";
import { isLibraryObjectLocked } from "utils/library";
import { RawMaterialDetailsProps } from "./types";
// import { certificationMessages } from "messages";

const RawMaterialDetails: React.FC<RawMaterialDetailsProps> = ({
  displayActions,
  rawMaterial,
  allergens,
  suppliers,
  materialId,
  state,
  fileIds,
  certificateIds = [],
}) => {
  // const { formatMessage } = useIntl();
  const allergensRef = useRef<HTMLDivElement | null>(null);

  useScrollToSection({ elementRef: allergensRef });

  const { catalogDictionary, refListDictionary } = useMaterialDetails({
    allergens,
    suppliers,
    rawMaterial,
  });

  return (
    <>
      <RawMaterial
        displayActions={displayActions}
        rawMaterial={rawMaterial}
        catalogDictionary={catalogDictionary}
      />
      <Suppliers
        refListDictionary={refListDictionary}
        displayActions={displayActions}
        suppliers={suppliers}
        materialId={materialId}
        materialType={MATERIAL_TYPES.RAW_MATERIAL}
      />
      <div ref={allergensRef}>
        <Allergens
          allergenDictionary={catalogDictionary}
          displayActions={displayActions}
          selectedAllergens={allergens}
        />
      </div>
      <Attachment
        fileIds={fileIds}
        displayActions={displayActions && !isLibraryObjectLocked(state)}
      />
      {/* uncomment below code and imports when backend impl is done */}
      {/* <FormCard
        title={formatMessage(certificationMessages.certificatesLabel)}
        name="certificates"
      >
        <Certificates
          displayActions={displayActions}
          certificateIds={certificateIds}
        />
      </FormCard> */}
      <Characteristics
        displayActions={displayActions}
        materialId={materialId}
        materialType={MATERIAL_TYPES.RAW_MATERIAL}
        refListDictionary={refListDictionary}
      />
      <ControlPlans
        displayActions={displayActions}
        materialId={materialId}
        materialType={MATERIAL_TYPES.RAW_MATERIAL}
        refListDictionary={refListDictionary}
      />
    </>
  );
};

RawMaterialDetails.defaultProps = {
  displayActions: true,
};

export default RawMaterialDetails;
