import { defineMessages } from "react-intl";

export const deleteChapterVersionMessages = defineMessages({
  modalTitle: {
    id: "library.chapter.deleteVersion.modal.title",
    defaultMessage: "Delete chapter version",
  },
  modalDescription: {
    id: "library.chapter.deleteVersion.modal.description",
    defaultMessage:
      "This chapter version will be deleted, it's data will be removed from any linked specification.",
  },
  toasterTitle: {
    id: "library.chapter.deleteVersion.toaster.title",
    defaultMessage: "Chapter version deleted",
  },
  toasterDescription: {
    id: "library.chapter.deleteVersion.toaster.description",
    defaultMessage:
      "The chapter {libraryItemName} version {versionNumber} has been successfully deleted.",
  },
});

export const deleteRequirementVersionMessages = defineMessages({
  modalTitle: {
    id: "library.requirement.deleteVersion.modal.title",
    defaultMessage: "Delete requirement version",
  },
  modalDescription: {
    id: "library.requirement.deleteVersion.modal.description",
    defaultMessage:
      "This requirement version will be deleted, it's data will be removed from any linked specification.",
  },
  toasterTitle: {
    id: "library.requirement.deleteVersion.toaster.title",
    defaultMessage: "Requirement version deleted",
  },
  toasterDescription: {
    id: "library.requirement.deleteVersion.toaster.description",
    defaultMessage:
      "The requirement {libraryItemName} version {versionNumber} has been successfully deleted.",
  },
});

export const deleteClaimVersionMessages = defineMessages({
  modalTitle: {
    id: "library.claim.deleteVersion.modal.title",
    defaultMessage: "Delete claim version",
  },
  modalDescription: {
    id: "library.claim.deleteVersion.modal.description",
    defaultMessage:
      "This claim version will be deleted, it's data will be removed from any linked specification.",
  },
  toasterTitle: {
    id: "library.claim.deleteVersion.toaster.title",
    defaultMessage: "Claim version deleted",
  },
  toasterDescription: {
    id: "library.claim.deleteVersion.toaster.description",
    defaultMessage:
      "The claim {libraryItemName} version {versionNumber} has been successfully deleted.",
  },
});
