import { useIntl } from "react-intl";
import { useMemo } from "react";
import { Col } from "antd";
import { UserCard } from "@trace-one/design-system";
import { Company } from "@trace-one/react-components";
import { getIsRetailer } from "utils/user";
import { FieldProps } from "components/SpecificationSection/types";
import { useCompanyDetails, useUserInfo } from "hooks";
import {
  getMasterDataSupplierUrl,
  isObjectEmpty,
  openUrlInNewWindow,
} from "utils/general";
import { otherAppsMessages } from "messages";
import styles from "./styles.module.less";

const CompanyCardWidget: React.FC<FieldProps> = ({
  formContext,
  uiSchema,
  value,
}) => {
  const { formatMessage } = useIntl();

  const { isRetailer, hasMasterDataAccess } = useUserInfo();
  const { companyData } = useCompanyDetails({
    companyId: value,
    shouldFetchCompanyType: true,
  });

  const { displayActions } = formContext;

  const colSpans = uiSchema?.["ui:itemSpan"] || {
    xs: displayActions ? 24 : 8,
    sm: 8,
    md: 8,
    lg: 8,
  };

  const isRetailerCompany = getIsRetailer(
    companyData.companyActivityId?.toString()
  );

  const displaySeeInMDAction =
    isRetailer && hasMasterDataAccess && !isRetailerCompany;

  const onClickSeeInMD = (supplierCompanyId: string) => () =>
    openUrlInNewWindow(getMasterDataSupplierUrl(supplierCompanyId));

  const companyProps = useMemo(() => {
    if (!isObjectEmpty(companyData)) {
      return {
        name: companyData.companyDisplayName,
        type: isRetailerCompany ? "retailer" : "supplier",
        photoUrl: companyData.companyLogoUrl,
        address: companyData.companyAddress1,
        city: companyData.companyCity || "",
        country: companyData.companyCountryName,
        postalCode: companyData.companyPostalCode,
        typeName: companyData?.typeName,
        isSelected: true,
        actions: displaySeeInMDAction
          ? [
              {
                name: formatMessage(otherAppsMessages.seeInMasterData),
                onClick: onClickSeeInMD(value),
              },
            ]
          : null,
      };
    } else {
      return {};
    }
  }, [JSON.stringify(companyData)]);

  if (isObjectEmpty(companyProps)) {
    return null;
  }

  if (!displayActions) {
    const { type: title, city, country } = companyProps;
    const userCardProps = {
      ...companyProps,
      type: "company",
      size: "s",
      title: title.charAt(0).toUpperCase() + title.slice(1),
      city: city.toUpperCase(),
      country: country.toUpperCase(),
    };

    return <UserCard {...userCardProps} />;
  }

  return uiSchema?.["ui:no:wrap"] ? (
    <Company.Card {...companyProps} />
  ) : (
    <Col {...colSpans} className={styles.companyCard}>
      <Company.Card {...companyProps} />
    </Col>
  );
};

export default CompanyCardWidget;
