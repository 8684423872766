import PropTypes from "prop-types";
import {
  ArrayItemsWidget,
  BusinessNameAndAddressWidget,
  CheckboxWidget,
  ChapterRecipeWidget,
  CompanyCardWidget,
  CompanyWidget,
  ContactWidget,
  CultivationOriginWidget,
  DateWidget,
  DateFormatWidget,
  DatePickerSwitchWidget,
  DisplayGtinOnPackWidget,
  DisplayGtinWidget,
  DisplayProductNameOnPackWidget,
  GuaranteedShelfLifeWidget,
  HierarchyWidget,
  IngredientListWidget,
  OriginWidget,
  ProductWidget,
  MultipleRefListWidget,
  RadioWidget,
  RadioPopconfirmWidget,
  RangeToleranceWidget,
  RefListWidget,
  ConditionalRefListWidget,
  RoCompactWidget,
  SelectWidget,
  SeasonalityWidget,
  SpecListRadioWidget,
  StaticWidget,
  StorageConditionWidget,
  SwitchWidget,
  TextareaWidget,
  TextWidget,
  VersionWidget,
  ReadOnlyTextWidget,
  CategoryListWidget,
  ProductInformationWidget,
} from "./widgets";
import FieldTemplate from "./templates/FieldTemplate";
import ObjectFieldTemplate from "./templates/ObjectFieldTemplates";
import ArrayFieldTemplate from "./templates/ArrayFieldTemplate/index";
import TitleField from "./fields/TitleField";
import CustomForm from "./components/CustomForm";

const fields = {
  TitleField,
};

const widgets = {
  ArrayItemsWidget,
  BusinessNameAndAddressWidget,
  CheckboxWidget,
  ChapterRecipeWidget,
  CompanyCardWidget,
  CompanyWidget,
  ContactWidget,
  CultivationOriginWidget,
  DateWidget,
  DateFormatWidget,
  DatePickerSwitchWidget,
  DisplayGtinOnPackWidget,
  DisplayGtinWidget,
  DisplayProductNameOnPackWidget,
  GuaranteedShelfLifeWidget,
  HierarchyWidget,
  IngredientListWidget,
  OriginWidget,
  ProductWidget,
  MultipleRefListWidget,
  RadioWidget,
  RadioPopconfirmWidget,
  RangeToleranceWidget,
  RefListWidget,
  ConditionalRefListWidget,
  RoCompactWidget,
  SelectWidget,
  SeasonalityWidget,
  SpecListRadioWidget,
  StaticWidget,
  StorageConditionWidget,
  SwitchWidget,
  TextareaWidget,
  TextWidget,
  VersionWidget,
  ReadOnlyTextWidget,
  CategoryListWidget,
  ProductInformationWidget,
};

const FormSchema = ({
  uiSchema,
  formData,
  schema,
  onChange,
  onSubmit,
  extraErrors,
  formContext,
  transformErrors,
}) => {
  return (
    <CustomForm
      uiSchema={uiSchema}
      schema={schema}
      widgets={widgets}
      fields={fields}
      FieldTemplate={FieldTemplate}
      ObjectFieldTemplate={ObjectFieldTemplate}
      ArrayFieldTemplate={ArrayFieldTemplate}
      onChange={({ formData }) => onChange(formData)}
      onSubmit={onSubmit}
      formData={formData || {}}
      formContext={formContext}
      showErrorList={false}
      extraErrors={extraErrors}
      transformErrors={transformErrors}
    >
      <span />
    </CustomForm>
  );
};

FormSchema.defaultProps = {
  onSubmit: () => {},
  onChange: () => {},
};

FormSchema.propTypes = {
  uiSchema: PropTypes.object,
  formData: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  onChange: PropTypes.func,
  onSubmit: PropTypes.func,
  extraErrors: PropTypes.object,
  formContext: PropTypes.shape({
    displayActions: PropTypes.bool.isRequired,
    isWidgetEditable: PropTypes.func.isRequired,
    saveTemplateBlock: PropTypes.func.isRequired,
    emptyValue: PropTypes.string,
    formData: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    sectionId: PropTypes.string,
    setErrors: PropTypes.func,
    type: PropTypes.oneOf(["library-object", "specification", "packaging"]),
    shouldCallApi: PropTypes.bool,
    refListDictionary: PropTypes.object,
    categoryDictionary: PropTypes.object,
    catalogDictionary: PropTypes.object,
    templateBlockId: PropTypes.string,
    templateSectionId: PropTypes.string,
    hasPermissionToSave: PropTypes.bool,
    hasPermissionToValidate: PropTypes.bool,
    labelAlign: PropTypes.string,
    specificationData: PropTypes.object,
    chapterData: PropTypes.object,
    productData: PropTypes.object,
    extraErrors: PropTypes.object,
    transformErrors: PropTypes.func,
    extraActions: PropTypes.object,
    isTypingInProgress: PropTypes.bool,
    onUpdateIsTypingInProgress: PropTypes.func,
    ownerCompanyId: PropTypes.string,
    packagingSystemData: PropTypes.object,
    page: PropTypes.string,
    isSpecificationUpdating: PropTypes.bool,
    isTargetMode: PropTypes.bool,
    readOnlyFieldIds: PropTypes.arrayOf(PropTypes.string),
    arraySchemaItemKey: PropTypes.string,
    arraySchemaPropertyId: PropTypes.string,
    isSpecContact: PropTypes.bool,
    isRetailerContact: PropTypes.bool,
    isTranslator: PropTypes.bool,
    queueLength: PropTypes.number,
    setSectionBlocks: PropTypes.func,
    disableExtraActions: PropTypes.bool,
    supplierContacts: PropTypes.array,
    supplierCompanyId: PropTypes.string,
    modificationRequests: PropTypes.arrayOf(
      PropTypes.shape({
        requestId: PropTypes.string,
        specificationId: PropTypes.string,
        chapterType: PropTypes.string,
        ownerCompanyId: PropTypes.string,
        ownerUserId: PropTypes.string,
        createdAtUtc: PropTypes.string,
        state: PropTypes.string,
        message: PropTypes.string,
        targetField: PropTypes.shape({
          sectionId: PropTypes.string,
          sectionName: PropTypes.string,
          blockId: PropTypes.string,
          propertyId: PropTypes.string,
          listItemKey: PropTypes.string,
          fieldName: PropTypes.string,
        }),
      })
    ),
    materialData: PropTypes.object,
    getMaterialData: PropTypes.func,
    chapterRecipe: PropTypes.object,
  }),
  transformErrors: PropTypes.func,
};

export default FormSchema;
