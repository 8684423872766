import { useSelector } from "react-redux";
import { Switch, Route, Redirect, useLocation } from "react-router-dom";
import { ErrorPage } from "@trace-one/react-components";
import {
  useAxiosInterceptors,
  useOidcSetup,
  useRedirect,
  useUserInfo,
  useUserPermission,
} from "hooks";
import {
  selectLanguageCode,
  selectUserOrAppsIsLoading,
} from "store/user/selectors";
import MainLayout from "layouts/Main";
import { ROUTES } from "utils/constants";
import { AdministratorRoute, RetailerRoute, FAndVRoute } from "routes";
import {
  Administration,
  Home,
  Folder,
  Library,
  Packaging,
  Specification,
  Translation,
} from "pages";
import Spin from "components/Spin";
import { setDayjsLocale } from "utils/date";
import { shouldShowErrorPage } from "./utils";

const StarterWrapper = () => {
  const isLoading = useSelector(selectUserOrAppsIsLoading);
  const languageCode = useSelector(selectLanguageCode);
  const { pathname } = useLocation();

  const { hasFAndVAccess, hasClaimsAccess } = useUserInfo();
  const { redirectToHome } = useRedirect();

  setDayjsLocale(languageCode);

  useOidcSetup();

  useAxiosInterceptors();

  const { chapterTranslationsRead } = useUserPermission();

  if (isLoading) {
    return <Spin fixed />;
  }

  if (shouldShowErrorPage({ pathname, hasFAndVAccess, hasClaimsAccess })) {
    return (
      <ErrorPage
        hasCustomClickAction
        status={403}
        onButtonPress={redirectToHome}
        secondaryButton={{
          path: "",
          isVisible: false,
        }}
      />
    );
  }

  return (
    <MainLayout>
      <Switch>
        <Redirect exact from="/" to="/home" />
        <Route path="/home" component={Home} />
        <Route
          exact
          path={ROUTES.PACKAGING_COMPONENT.EDIT}
          component={Packaging.Edit}
        />
        {chapterTranslationsRead && (
          <Route
            exact
            path={ROUTES.TRANSLATION_LISTING}
            component={Translation.List}
          />
        )}
        {chapterTranslationsRead && (
          <Route
            exact
            path={ROUTES.SPECIFICATION_TRANSLATIONS_DETAILS}
            component={Translation.Details}
          />
        )}
        <RetailerRoute
          exact
          path={ROUTES.CREATION_DETAILS_STEP}
          component={Folder.Creation.Details}
        />
        <RetailerRoute
          exact
          path={ROUTES.CREATION_PRODUCTS_STEP}
          component={Folder.Creation.Products}
        />
        <RetailerRoute
          exact
          path={ROUTES.CREATION_INVITATION_STEP}
          component={Folder.Creation.Invitation}
        />
        <RetailerRoute
          exact
          path={ROUTES.CREATION_SPECIFICATIONS_STEP}
          component={Folder.Creation.Specifications}
        />
        <RetailerRoute
          exact
          path={ROUTES.CREATION_SUMMARY_STEP}
          component={Folder.Creation.Summary}
        />
        <RetailerRoute
          exact
          path={ROUTES.FOLDER_LISTING}
          component={Folder.List}
        />
        <Route exact path={ROUTES.FOLDER_DETAILS} component={Folder.Details} />
        <Route
          exact
          path={ROUTES.SPECIFICATION_LISTING}
          component={Specification.List}
        />
        <RetailerRoute
          path={ROUTES.SPECIFICATION_CREATION_PRODUCT_STEP}
          component={Specification.Creation.Product}
        />
        <RetailerRoute
          exact
          path={ROUTES.SPECIFICATION_CREATION_INVITATION_STEP}
          component={Specification.Creation.Invitation}
        />
        <RetailerRoute
          exact
          path={ROUTES.SPECIFICATION_CREATION_SPECIFICATION_STEP}
          component={Specification.Creation.Specification}
        />
        <RetailerRoute
          exact
          path={ROUTES.SPECIFICATION_CREATION_SUMMARY_STEP}
          component={Specification.Creation.Summary}
        />
        <Route
          exact
          path={ROUTES.SPECIFICATION_WAITING_DIGITAL_SIGNATURE}
          component={Specification.WaitingDigitalSignature}
        />
        <Route
          path={[
            ROUTES.SPECIFICATION_SECTION_DETAILS,
            ROUTES.SPECIFICATION_DETAILS,
          ]}
          component={Specification.Details}
        />

        <FAndVRoute path={ROUTES.LIBRARY.LISTING} component={Library.List} />
        <FAndVRoute
          path={ROUTES.LIBRARY.CREATION}
          component={Library.Creation}
        />
        <FAndVRoute
          exact
          path={ROUTES.LIBRARY.DETAILS}
          component={Library.Details}
        />
        <FAndVRoute
          exact
          path={[ROUTES.LIBRARY.EDITION]}
          component={Library.Edition}
        />
        <FAndVRoute
          exact
          path={ROUTES.LIBRARY.CREATE_NEW_VERSION}
          component={Library.Edition}
        />
        <AdministratorRoute
          exact
          path={ROUTES.ADMINISTRATION.TAGS}
          component={Administration.Tags}
        />
        <Route component={Home} />
      </Switch>
    </MainLayout>
  );
};

export default StarterWrapper;
