import { useMemo } from "react";
import { useIntl } from "react-intl";
import { utils } from "@rjsf/core";
import { useFormSchemaTranslation } from "pages/Specification/components/Details/hooks";
import { NutrientItemDictionary } from "components/SpecificationSection/components/FormSchema/components/NutritionBasic/types";
import NutritionServingValue from "./components/NutritionServingValue";
import { useReferenceListsItems } from "hooks";
import { UseServingColumnsProps } from "./types";
import { getServingIndex, getServingValue } from "./utils";
import {
  getServingGroupingColor,
  getServingGroupingColumns,
  getServingGroupingName,
} from "utils/nutrition";
import { messages } from "components/SpecificationSection/components/FormSchema/widgets/NutritionDeclarationWidget/messages";
import { NutrientItem } from "models";

const { resolveSchema } = utils;

const useServingColumns = ({
  formData,
  uiSchema,
  schema,
  nutritionServings,
  nutrientDictionary,
  isNotRootNutrient,
  extraActions,
  formContext,
  getColumnErrors,
  onRemoveServing,
  onEditServing,
  hasUpdatePermission,
  hasAddServingPermission,
}: UseServingColumnsProps) => {
  const { getTranslationForKey } = useFormSchemaTranslation();
  const { formatMessage } = useIntl();

  const { readOnlyFieldIds = [] } = formContext;

  const servingIds = useMemo(
    () =>
      nutritionServings?.reduce((acc, serving) => {
        if (serving?.size?.unitId) {
          acc.push(serving?.size?.unitId);
        }

        return acc;
      }, []),
    [nutritionServings]
  );

  useReferenceListsItems({
    refListIds: [...servingIds],
  });

  const roundedDailyIntakeSchema = resolveSchema(
    // @ts-ignore-next
    schema.properties?.nutrients?.items?.properties?.servings?.items?.properties
      ?.roundedDailyIntake,
    schema
  );
  const unroundedDailyIntakeSchema = resolveSchema(
    // @ts-ignore-next
    schema.properties?.nutrients?.items?.properties?.servings?.items?.properties
      ?.unroundedDailyIntake,
    schema
  );

  const roundedValueSchema = resolveSchema(
    // @ts-ignore-next
    schema.properties?.nutrients?.items?.properties?.servings?.items?.properties
      ?.roundedValue,
    schema
  );

  const unroundedValueSchema = resolveSchema(
    // @ts-ignore-next
    schema.properties?.nutrients?.items?.properties?.servings?.items?.properties
      ?.unroundedValue,
    schema
  );

  const servingNamePropertyId =
    // @ts-ignore-next
    schema?.properties?.servingDefinitions?.items?.properties?.name?.propertyId;

  const getAutomaticCalculationServingColumns = (
    key: string,
    servingId?: string,
    isDefaultServing?: boolean
  ) => {
    if (
      isDefaultServing &&
      (key === "unroundedDailyIntake" || key === "roundedDailyIntake")
    ) {
      return {
        dataIndex: ["servings", key],
        title: getTranslationForKey(
          uiSchema?.nutrients?.items?.servings?.items?.[key]?.["ui:title"]
        ),
        render: (_, nutrient: NutrientItem) => {
          const servingIndex = getServingIndex({
            formData,
            nutrient,
            servingId,
          });

          if (
            (nutrientDictionary[nutrient.nutrientId] as NutrientItemDictionary)
              ?.dailyIntake === null &&
            nutrient.unroundedValue &&
            isNotRootNutrient(nutrient)
          ) {
            return (
              <NutritionServingValue
                fieldKey={key}
                formContext={formContext}
                formData={formData}
                extraActions={extraActions}
                roundedDailyIntakeSchema={roundedDailyIntakeSchema}
                unroundedDailyIntakeSchema={unroundedDailyIntakeSchema}
                roundedValueSchema={roundedValueSchema}
                unroundedValueSchema={unroundedValueSchema}
                nutrient={nutrient}
                servingId={servingId}
                errors={getColumnErrors(
                  `nutrientFamilies${nutrient.key}.servings[${servingIndex}].${key}`
                )}
                automaticCalculation={true}
                hasUpdatePermission={hasUpdatePermission}
              />
            );
          } else {
            return getServingValue({
              nutrient,
              formData,
              servingId,
              key,
            });
          }
        },
      };
    }

    return {
      dataIndex: ["servings", key],
      title: getTranslationForKey(
        uiSchema?.nutrients?.items?.servings?.items?.[key]?.["ui:title"]
      ),
      render: (_, nutrient: NutrientItem) => {
        return getServingValue({
          formData,
          nutrient,
          servingId,
          key,
        });
      },
    };
  };

  const getManualCalculationServingColumns = (
    key: string,
    servingId?: string
  ) => ({
    dataIndex: ["servings", key],
    title: getTranslationForKey(
      uiSchema?.nutrients?.items?.servings?.items?.[key]?.["ui:title"]
    ),
    render: (_, nutrient: NutrientItem) => {
      const servingIndex = getServingIndex({
        formData,
        nutrient,
        servingId,
      });

      if (nutrient.unroundedValue) {
        return (
          <NutritionServingValue
            fieldKey={key}
            formContext={formContext}
            formData={formData}
            extraActions={extraActions}
            roundedDailyIntakeSchema={roundedDailyIntakeSchema}
            unroundedDailyIntakeSchema={unroundedDailyIntakeSchema}
            roundedValueSchema={roundedValueSchema}
            unroundedValueSchema={unroundedValueSchema}
            nutrient={nutrient}
            servingId={servingId}
            errors={getColumnErrors(
              `nutrientFamilies${nutrient.key}.servings[${servingIndex}].${key}`
            )}
            automaticCalculation={false}
            hasUpdatePermission={hasUpdatePermission}
          />
        );
      } else {
        return null;
      }
    },
  });

  return nutritionServings.map(nutrition => {
    const {
      name,
      servingId,
      defaultServing: isDefaultServing,
      automaticCalculation,
      size,
    } = nutrition;
    const shouldShowActions =
      !isDefaultServing &&
      hasUpdatePermission &&
      !readOnlyFieldIds.includes(servingNamePropertyId);

    return {
      title: getServingGroupingName({
        isDefaultServing,
        name,
        size,
        nutrientDictionary,
        getTranslationForKey,
      }),
      children: getServingGroupingColumns(isDefaultServing).map(columnName =>
        automaticCalculation
          ? getAutomaticCalculationServingColumns(
              columnName,
              servingId,
              isDefaultServing
            )
          : getManualCalculationServingColumns(columnName, servingId)
      ),
      color: getServingGroupingColor(isDefaultServing),
      ...(shouldShowActions
        ? {
            actions: [
              {
                key: `edit_serving_${servingId}`,
                label: formatMessage(messages.editServing),
                onClick: () => onEditServing(nutrition),
              },
              ...(hasAddServingPermission
                ? [
                    {
                      key: `remove_serving_${servingId}`,
                      label: formatMessage(messages.removeServing),
                      onClick: () => onRemoveServing(servingId),
                    },
                  ]
                : []),
            ],
          }
        : {}),
    };
  });
};

export default useServingColumns;
