import { SizeType } from "@trace-one/design-system";
import { AppListProps } from "./components/AppList/types";
import { DiscoverSolutionsProps } from "./components/DiscoverSolutions/types";
import { CompanyGroupList, UserApplication } from "../../models";

export enum AppSwitcherVariant {
  GRID = "grid",
  VERTICAL = "vertical",
}

export type VariantType = AppSwitcherVariant.GRID | AppSwitcherVariant.VERTICAL;

export interface CompanySwitcherProps {
  selectedCompanyId?: string;
  owningCompanyId?: string;
  owningCompanyName?: string;
  applicationTypeId?: number;
  companyGroupId?: string;
  onCompanySwitch?: (company: CompanyGroupList) => void;
}

export interface AppSwitcherProps {
  accessToken: string;
  userId: string;
  excludeApps?: number[];
  open?: boolean;
  onOpenChange?: (newOpen: boolean) => void;
  showDiscoverSolutions?: boolean;
  withIntercomTrack?: boolean;
  handleClose?: DiscoverSolutionsProps["handleClose"];
  handleCreationPopUp?: AppListProps["handleCreationPopUp"];
  fetchCompaniesByIds?: Function;
  variant?: VariantType;
  includeApps?: UserApplication[];
  orderAppsById?: boolean;
  companySwitcherProps?: CompanySwitcherProps;
  size?: SizeType;
}
