import { useContext } from "react";
import classNames from "classnames";
import { getParsedData } from "utils/general";
import SpecificationSection from "components/SpecificationSection";
import SectionHeader from "./components/SectionHeader";
import RequirementsContent from "./components/RequirementsContent";
import DocumentsContent from "./components/DocumentsContent";
import { SpecificationPdfContext } from "pages/Specification/components/Pdf/contexts";
import { SPECIFICATION_PAGE } from "utils/constants";
import { SectionProps } from "./types";
import styles from "./styles.module.less";

const Section: React.FC<SectionProps> = ({
  blocks,
  sectionId,
  sectionName,
  sectionStatus,
  sectionCode,
  chapterData,
  materialData,
  chapterRecipe,
}) => {
  const { specificationData } = useContext(SpecificationPdfContext);

  const customSectionsContentMap = {
    requirements: (
      <RequirementsContent requirements={chapterData?.requirements} />
    ),
    documents: <DocumentsContent fileIds={chapterData?.documents?.fileIds} />,
  };

  return (
    <div className={classNames(styles.wrapper)}>
      <SectionHeader sectionName={sectionName} sectionStatus={sectionStatus} />
      {!!customSectionsContentMap[sectionCode]
        ? customSectionsContentMap[sectionCode]
        : blocks.map(({ dataAsJson, jsonSchema, uiSchema, blockId }) => {
            return (
              <SpecificationSection.Block
                key={blockId}
                templateBlockId={sectionId}
                jsonSchema={getParsedData(jsonSchema)}
                uiSchema={getParsedData(uiSchema)}
                formData={getParsedData(dataAsJson)}
                sectionId={sectionId}
                displayActions={false}
                specificationData={{
                  specificationId: specificationData.id,
                  supplierId: specificationData.supplierId,
                  ownerCompanyId: specificationData.ownerCompanyId,
                  ownerUserId: specificationData.ownerUserId,
                }}
                chapterData={chapterData}
                page={SPECIFICATION_PAGE.PDF}
                materialData={materialData}
                chapterRecipe={chapterRecipe}
              />
            );
          })}
    </div>
  );
};

export default Section;
