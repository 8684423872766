import { useIntl } from "react-intl";
import { Button } from "@trace-one/design-system";
import { FilesImportModal } from "@trace-one/business-components";
import { useImportFile } from "./hooks";
import { documentMessages } from "messages";

export type ImportFileProps = {
  onDownloadTemplate: () => Promise<void>;
  onImportConfirmation: (file: File) => Promise<void>;
  afterImportCallback?: Function;
  onDownloadErrorFile?: ({
    errorData,
    fileName,
  }: {
    errorData: BlobPart;
    fileName: string;
  }) => () => void;
};

const ImportFile: React.FC<ImportFileProps> = ({
  onDownloadTemplate,
  onImportConfirmation,
  afterImportCallback,
  onDownloadErrorFile,
}) => {
  const { formatMessage } = useIntl();

  const {
    importing,
    open,
    onClick,
    onCloseModal,
    handleImportConfirmation,
  } = useImportFile({
    onImportConfirmation,
    afterImportCallback,
    onDownloadErrorFile,
  });

  return (
    <>
      <Button onClick={onClick} data-testid="import-button">
        {formatMessage(documentMessages.import)}
      </Button>
      {open && (
        <FilesImportModal
          open
          onClose={onCloseModal}
          onDownloadTemplate={onDownloadTemplate}
          onImportConfirmation={handleImportConfirmation}
          primaryButtonProps={{ loading: importing }}
          primaryButtonText={formatMessage(documentMessages.import)}
          downloadButtonText={formatMessage(documentMessages.download)}
          title={formatMessage(documentMessages.importFile)}
          subTitle={formatMessage(documentMessages.importRegulatedClaims)}
          instruction1={formatMessage(documentMessages.importFileInstruction1)}
          instruction2={formatMessage(documentMessages.importFileInstruction2)}
          instruction3={formatMessage(documentMessages.importFileInstruction3)}
          instruction4={formatMessage(documentMessages.importFileInstruction4)}
        />
      )}
    </>
  );
};

export default ImportFile;
