import { LibraryItemState, LibraryObjectType } from "types/library";
import { isLibraryObjectClaimType, isLibraryObjectLocked } from "utils/library";
import { LIBRARY_OBJECT_STATES } from "utils/constants";
import { libraryMessages } from "messages";

export const getPrimaryButtonLabel = ({
  type,
  state,
  formatMessage,
  isNewVersionPage = false,
}: {
  type: LibraryObjectType;
  state: LibraryItemState;
  formatMessage: Function;
  isNewVersionPage?: boolean;
}) => {
  let message;

  if (isLibraryObjectClaimType(type)) {
    message = formatMessage(
      isNewVersionPage ? libraryMessages.publishButton : libraryMessages.save
    );
  } else if (isLibraryObjectLocked(state)) {
    message = formatMessage(libraryMessages.createNewVersion);
  } else {
    message = formatMessage(libraryMessages.editButton);
  }

  return message;
};

export const getVersionNumber = ({
  type,
  versionNumber,
  isNewVersionPage = false,
}: {
  type: LibraryObjectType;
  versionNumber: number;
  isNewVersionPage?: boolean;
}) => {
  if (isLibraryObjectClaimType(type) && isNewVersionPage) {
    return versionNumber + 1;
  }

  return versionNumber;
};

export const getStateToDisplay = ({
  type,
  state,
  isNewVersionPage = false,
}: {
  type: LibraryObjectType;
  state: LibraryItemState;
  isNewVersionPage?: boolean;
}) => {
  if (isLibraryObjectClaimType(type) && isNewVersionPage) {
    return LIBRARY_OBJECT_STATES.DRAFT;
  }

  return state;
};
