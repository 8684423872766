import {
  LibraryItemVersionData,
  RequirementData,
  SpecificationListItemData,
  SpecificationQuickViewData,
} from "models";
import { PaginatedList, PaginationParams } from "types/api";
import { AxiosRequestConfig } from "axios";
import { axiosInstance } from "./";
import { RequirementListItemData } from "models/requirements";
import { LibraryItemApiType, LibraryItemState } from "types/library";

export type RequirementUrlParams = {
  requirementId: RequirementData["id"];
  requirementType: LibraryItemApiType;
};

export interface FetchRequirementSpecificationsParams
  extends RequirementUrlParams {
  params: PaginationParams & {
    languageCode?: string;
    states?: string;
  };
}

export const fetchRequirements = ({ params }) =>
  axiosInstance.get<{
    totalRequirements: number;
    requirements: RequirementData[];
  }>(`api/requirements`, {
    params,
  });

export const fetchRequirement = ({
  requirementId,
  requirementType,
}: RequirementUrlParams) =>
  axiosInstance.get<RequirementData>(
    `/api/${requirementType}/${requirementId}`
  );

export const fetchRequirementHistory = ({
  id,
}: {
  id: RequirementData["id"];
}) => axiosInstance.get(`/api/requirements/${id}/history`);

export const fetchRequirementListing = ({
  params,
  paramsSerializer,
}: {
  params: PaginationParams & {
    ownerCompanyId: string;
    name?: string;
    requirementType?: string;
    states?: LibraryItemState[];
  };
  paramsSerializer?: AxiosRequestConfig["paramsSerializer"];
}) =>
  axiosInstance.get<PaginatedList<RequirementListItemData>>(
    `/api/requirements/search`,
    {
      params,
      paramsSerializer,
    }
  );

export const createRequirement = ({
  requirementType,
  payload,
}: {
  requirementType: LibraryItemApiType;
  payload:
    | Pick<RequirementData, "name" | "content" | "tagIds">
    | Pick<
        RequirementData,
        "name" | "fileId" | "additionalInformation" | "tagIds"
      >;
}) => axiosInstance.post<RequirementData>(`/api/${requirementType}`, payload);

export const updateRequirement = ({
  requirementId,
  requirementType,
  payload,
}: RequirementUrlParams & {
  payload:
    | Pick<RequirementData, "name" | "content">
    | Pick<RequirementData, "name" | "fileId" | "additionalInformation">;
}) =>
  axiosInstance.put<RequirementData>(
    `/api/${requirementType}/${requirementId}`,
    payload
  );

export const addRequirementTags = ({
  requirementId,
  requirementType,
  tagIds,
}: RequirementUrlParams & {
  tagIds: RequirementData["tagIds"];
}) =>
  axiosInstance.post<RequirementData>(
    `/api/${requirementType}/${requirementId}/tags`,
    tagIds,
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );

export const deleteRequirementTag = ({
  requirementId,
  requirementType,
  tagId,
}: RequirementUrlParams & {
  tagId: string;
}) =>
  axiosInstance.delete<RequirementData>(
    `/api/${requirementType}/${requirementId}/tags/${tagId}`
  );

export const shareRequirement = ({
  requirementId,
  requirementType,
  companyId,
}: RequirementUrlParams & {
  companyId?: string;
}) =>
  axiosInstance.post<void>(`/api/${requirementType}/${requirementId}/share`, {
    companyId,
  });

export const deleteRequirement = ({
  requirementId,
  requirementType,
}: RequirementUrlParams) =>
  axiosInstance.delete<void>(`/api/${requirementType}/${requirementId}`);

export const publishRequirement = ({
  requirementId,
  requirementType,
}: RequirementUrlParams) =>
  axiosInstance.post<void>(`/api/${requirementType}/${requirementId}/publish`);

export const validateRequirement = ({
  requirementId,
  requirementType,
}: RequirementUrlParams) =>
  axiosInstance.post<void>(`/api/${requirementType}/${requirementId}/validate`);

export const createRequirementVersion = ({
  requirementType,
  requirementId,
  params,
}: {
  requirementType: RequirementData["requirementType"];
  requirementId: RequirementData["id"];
  params?: {
    languageCode?: string;
  };
}) =>
  axiosInstance.post<RequirementData["id"]>(
    `/api/${requirementType}/${requirementId}/new-version`,
    {},
    { params }
  );

export const fetchRequirementVersions = ({
  requirementId,
  requirementType,
}: RequirementUrlParams) =>
  axiosInstance.get<LibraryItemVersionData[]>(
    `/api/${requirementType}/${requirementId}/versions`
  );

export const fetchRequirementQuickView = ({
  requirementId,
  requirementType,
}: RequirementUrlParams) =>
  axiosInstance.get<SpecificationQuickViewData[]>(
    `/api/${requirementType}/${requirementId}/quick-view`
  );

export const fetchRequirementSpecifications = ({
  requirementId,
  requirementType,
  params,
}: FetchRequirementSpecificationsParams) =>
  axiosInstance.get<PaginatedList<SpecificationListItemData>>(
    `/api/${requirementType}/${requirementId}/specifications`,
    { params }
  );
