import { ChapterFreeFromAllergenFormData, MaterialData } from "models";

export const getDefaultCheckedParents = (
  allergens: ChapterFreeFromAllergenFormData["allergens"] = []
) => allergens.map(({ id }) => id);

export const getDefaultDisabledItems = (
  allergens: MaterialData["allergenDeclarations"]
) => {
  return allergens.map(({ allergenId }) => allergenId);
};
