import classNames from "classnames";
import { Datatable, Typography } from "@trace-one/design-system";
import { useSubstancesTable } from "./hooks";
import RowTitle from "components/RowTitle";
import RegulationsInfo from "./components/RegulationsInfo";
import { PackagingSubstanceTableViewModel } from "viewModels";

export type SubstancesTableProps = {
  title?: string;
  subtitle?: string;
  data: PackagingSubstanceTableViewModel[];
  isRestricted?: boolean;
  isLoading?: boolean;
};

const SubstancesTable: React.FC<SubstancesTableProps> = ({
  title,
  subtitle,
  data,
  isRestricted,
  isLoading,
}) => {
  const { columns } = useSubstancesTable({
    isRestricted,
  });

  const renderExpandedContent = ({
    regulations,
  }: {
    regulations: PackagingSubstanceTableViewModel["regulations"];
  }) => (
    <div className={classNames("pt-1", "pb-1", "pl-3", "pr-3")}>
      <RegulationsInfo regulationsInfo={regulations} />
    </div>
  );

  return (
    <>
      {title && <RowTitle title={title} />}
      {subtitle && (
        <Typography variant="body-regular" color="grey-5" className="mb-2">
          {subtitle}
        </Typography>
      )}
      <Datatable
        columns={columns}
        dataSource={data}
        pagination={false}
        rowKey={({ substanceId, name }) => `${substanceId}-${name}`}
        loading={isLoading}
        {...(isRestricted && {
          expandable: {
            expandedRowRender: renderExpandedContent,
          },
        })}
      />
    </>
  );
};

export default SubstancesTable;
