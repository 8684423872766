import { useEffect, useState } from "react";
import { extractCertificateInformation } from "../../../apis/ai";
import { ExtractedFileInfoData } from "../../../apis/ai/types";
const useExtractedFromAIFileData = (DmsFileId, isAIUsed) => {
  const [extractedFileDataFromAI, setExtractedFileDataFromAI] = useState<ExtractedFileInfoData>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const extractCertificateFileInformation = async () => {
    try {
      setIsLoading(true);
      const { data } = await extractCertificateInformation(DmsFileId);
      setExtractedFileDataFromAI(data);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (isAIUsed && DmsFileId) {
      extractCertificateFileInformation();
    }
  }, [isAIUsed, DmsFileId]);

  return {
    isLoading,
    extractedFileDataFromAI,
  };
};
export default useExtractedFromAIFileData;
