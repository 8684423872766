import { defineMessages } from "react-intl";

export const inputErrorMessages = defineMessages({
  negativePercentage: {
    id: "general.errors.negativePercentage",
    defaultMessage: "Percentage should be greater than 0",
  },
  maximumPercentage: {
    id: "general.errors.maximumPercentage",
    defaultMessage: "Percentage cannot be greater than {max}",
  },
  duplicateName: {
    id: "general.errors.duplicateName",
    defaultMessage:
      "This name is already used by another item, please use another one",
  },
  minimumNumber: {
    id: "general.errors.minimumNumber",
    defaultMessage: "Value should be greater than {min}",
  },
  maximumNumber: {
    id: "general.errors.maximumNumber",
    defaultMessage: "Value cannot be greater than {max}",
  },
});

export const errorMessages = defineMessages({
  somethingWentWrong: {
    id: "general.errors.somethingWentWrong",
    defaultMessage: "Something went wrong",
  },
  searchError: {
    id: "search.errorMessage",
    defaultMessage: "Sorry an error occurred, please try again",
  },
});

export const apiErrorMessages = defineMessages({
  apiError: {
    id: "apis.error",
    defaultMessage:
      "An error occured, if this error persists, please contact support.",
  },
});
