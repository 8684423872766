import { Row } from "antd";
import { EmptyState } from "@trace-one/design-system";
import ClaimCardReadOnly from "../ClaimCardReadOnly";
import RowTitle from "components/RowTitle";
import { useHealthClaimRegulation } from "hooks";
import { useFormSchemaTranslation } from "pages/Specification/components/Details/hooks";
import { isListEmpty, isObjectEmpty } from "utils/general";
import { CLAIM_TYPES } from "utils/constants";
import { ClaimFormData } from "types/specification";
import { ClaimData } from "models";

export type ClaimsReadOnlyProps = {
  marketingClaims: ClaimData[];
  healthClaims: ClaimData[];
  nutritionalClaims: ClaimData[];
  formData: ClaimFormData;
  noItemsMessage: string;
};

const ClaimsReadOnly: React.FC<ClaimsReadOnlyProps> = ({
  marketingClaims = [],
  healthClaims = [],
  nutritionalClaims = [],
  formData = {},
  noItemsMessage,
}) => {
  const { getTranslationForKey } = useFormSchemaTranslation();

  const regulatoryClaimIds = healthClaims
    .map(({ regulatoryClaimId }) => regulatoryClaimId)
    .filter(Boolean);

  const { regulations } = useHealthClaimRegulation({ ids: regulatoryClaimIds });

  if (
    (isListEmpty(marketingClaims) &&
      isListEmpty(healthClaims) &&
      isListEmpty(nutritionalClaims)) ||
    isObjectEmpty(formData)
  ) {
    return (
      <Row justify="center">
        <EmptyState heading={noItemsMessage} />
      </Row>
    );
  }

  return (
    <>
      {!isListEmpty(nutritionalClaims) && (
        <div>
          <RowTitle title={getTranslationForKey("nutritionalClaims")} />
          {nutritionalClaims.map(
            ({
              name,
              sentenceTranslations = [],
              additionalSentenceTranslations = [],
              regulatoryClaimId,
              id,
              conditionOfUse,
            }) => {
              const { supportingDocumentIds = [] } =
                formData?.nutritionalClaims?.find(
                  formDataClaim => formDataClaim?.claimId === id
                ) || {};
              return (
                <ClaimCardReadOnly
                  key={id}
                  name={name}
                  fileIds={supportingDocumentIds}
                  sentenceTranslations={sentenceTranslations}
                  claimType={CLAIM_TYPES.NUTRITION}
                  additionalSentenceTranslations={
                    additionalSentenceTranslations
                  }
                  regulation={regulations.find(
                    ({ id }) => regulatoryClaimId === id
                  )}
                  conditionOfUse={conditionOfUse}
                />
              );
            }
          )}
        </div>
      )}

      {!isListEmpty(healthClaims) && (
        <div>
          <RowTitle title={getTranslationForKey("healthClaims")} />
          {healthClaims.map(
            ({
              name,
              sentenceTranslations = [],
              additionalSentenceTranslations = [],
              regulatoryClaimId,
              id,
            }) => {
              const { supportingDocumentIds = [] } =
                formData?.healthClaims?.find(
                  formDataClaim => formDataClaim?.claimId === id
                ) || {};
              return (
                <ClaimCardReadOnly
                  key={id}
                  name={name}
                  fileIds={supportingDocumentIds}
                  sentenceTranslations={sentenceTranslations}
                  claimType={CLAIM_TYPES.HEALTH}
                  additionalSentenceTranslations={
                    additionalSentenceTranslations
                  }
                  regulation={regulations.find(
                    ({ id }) => regulatoryClaimId === id
                  )}
                />
              );
            }
          )}
        </div>
      )}

      {!isListEmpty(marketingClaims) && (
        <div>
          <RowTitle title={getTranslationForKey("marketingClaims")} />
          {marketingClaims.map(({ name, sentenceTranslations = [], id }) => {
            const { supportingDocumentIds = [] } =
              formData?.marketingClaims?.find(
                formDataClaim => formDataClaim?.claimId === id
              ) || {};
            return (
              <ClaimCardReadOnly
                key={id}
                name={name}
                fileIds={supportingDocumentIds}
                sentenceTranslations={sentenceTranslations}
                claimType={CLAIM_TYPES.MARKETING}
              />
            );
          })}
        </div>
      )}
    </>
  );
};

export default ClaimsReadOnly;
