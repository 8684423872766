import { parseDate } from "utils/date";

export const getInitialTargetDateUtc = targetDateUtc =>
  targetDateUtc && parseDate(targetDateUtc);

export const getOnContactDelete = ({
  dispatch,
  removeFolderContact,
}) => userId => () => {
  dispatch(removeFolderContact(userId));
};

export const getOnDescriptionChange = ({
  dispatch,
  setFolderDescription,
}) => description => {
  dispatch(setFolderDescription(description));
};

export const getOnNameChange = ({ dispatch, setFolderName }) => name => {
  dispatch(setFolderName(name));
};

export const getOnSelectContact = ({
  addFolderContact,
  dispatch,
}) => userId => {
  dispatch(addFolderContact(userId));
};

export const getOnSelectProductTypeId = ({
  dispatch,
  setFolderProductType,
}) => productTypeId => {
  dispatch(setFolderProductType(productTypeId));
};

export const getOnSelectTargetDate = ({
  dispatch,
  setFolderTargetDate,
}) => targetDate => {
  dispatch(setFolderTargetDate(targetDate.format()));
};

export const shouldCurrentDateBeDisabled = currentDate => {
  const current = currentDate.toDate();
  const today = new Date();
  const isSameDay =
    current.getFullYear() === today.getFullYear() &&
    current.getMonth() === today.getMonth() &&
    current.getDate() === today.getDate();

  return current < today && !isSameDay;
};
