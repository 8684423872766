import { useIntl } from "react-intl";
import { Datatable } from "@trace-one/design-system";
import LinkedSpecificationsModal from "components/LinkedSpecificationsModal";
import {
  LibraryItemQuickView,
  LibraryItemInfo,
} from "components/Library/components";
import TableHeader from "components/TableHeader";
import { useVersionHistoryColumns } from "components/Library/hooks";
import { useLibraryItemVersionHistory } from "hooks";
import { useRequirementVersionHistoryActions } from "../../hooks";
import { isStateDraft } from "utils/specifications";
import { isListEmpty } from "utils/general";
import { RequirementData, LibraryItemVersionData } from "models";
import { LibraryItemApiType } from "types/library";
import { generalMessages, libraryMessages } from "messages";

export type RequirementVersionHistoryProps = {
  requirementId: RequirementData["id"];
  requirementType: LibraryItemApiType;
  linkedSpecificationsCallback?: () => Promise<void>;
};

const RequirementVersionHistory: React.FC<RequirementVersionHistoryProps> = ({
  requirementId,
  requirementType,
  linkedSpecificationsCallback,
}) => {
  const { formatMessage } = useIntl();

  const {
    versionHistoryList,
    loadingVersions,
    getVersionHistoryList,
    total,
  } = useLibraryItemVersionHistory({
    libraryItemId: requirementId,
    libraryItemType: requirementType,
  });

  const {
    title,
    subtitle,
    isModalOpen,
    shouldRefetchVersionHistory,
    handleOpenModal,
    handleCloseModal,
    rowSelection,
    actionType,
    libraryItemState,
    selectedLibraryItemId,
    versionNumber,
    states,
    primaryButtonLabel,
    secondaryButtonLabel,
    onPrimaryButtonClick,
  } = useRequirementVersionHistoryActions({
    versionHistoryCallback: getVersionHistoryList,
    linkedSpecificationsCallback,
  });

  const { columns } = useVersionHistoryColumns({
    displayActions: true,
    libraryItemType: requirementType,
    handleOpenModal,
  });

  const expandedRowRender = ({ id }: LibraryItemVersionData) => (
    <LibraryItemQuickView
      libraryItemId={id}
      libraryItemType={requirementType}
      key={id}
      shouldRefetch={shouldRefetchVersionHistory}
    />
  );

  const rowExpandable = ({ state }: LibraryItemVersionData) =>
    !isStateDraft(state);

  return (
    <>
      <div className="mb-3">
        <LibraryItemInfo />
      </div>

      <TableHeader
        listResult={formatMessage(generalMessages.listingResultNumber, {
          current: total,
          total: total,
          libraryObjectType: formatMessage(
            libraryMessages.requirements
          ).toLocaleLowerCase(),
        })}
      />
      <Datatable
        loading={loadingVersions}
        columns={columns}
        dataSource={versionHistoryList}
        rowKey={({ id }: LibraryItemVersionData) => id}
        pagination={false}
        rowClassName="cursor-pointer"
        expandable={{
          expandedRowRender: expandedRowRender,
          rowExpandable,
        }}
      />

      {isModalOpen && !isListEmpty(states) && (
        <LinkedSpecificationsModal
          title={title}
          subtitle={subtitle}
          onCloseModal={handleCloseModal}
          libraryItemId={requirementId}
          selectedLibraryItemId={selectedLibraryItemId}
          libraryItemType={requirementType}
          libraryItemState={libraryItemState}
          versionNumber={versionNumber}
          rowSelection={rowSelection}
          states={states}
          actionType={actionType}
          primaryButtonLabel={primaryButtonLabel}
          secondaryButtonLabel={secondaryButtonLabel}
          onPrimaryButtonClick={onPrimaryButtonClick}
          onSecondaryButtonClick={handleCloseModal}
        />
      )}
    </>
  );
};

export default RequirementVersionHistory;
