import { useState, useEffect } from "react";

export type UseTableInfiniteScrollProps = {
  tableContent: Element;
  canLoadMore: boolean;
};

const useTableInfiniteScroll = ({
  tableContent,
  canLoadMore,
}: UseTableInfiniteScrollProps) => {
  const [currentPage, setCurrentPage] = useState(1);

  const scrollEventListener = (event: Event) => {
    // @ts-ignore
    let maxScroll = event.target.scrollHeight - event.target.clientHeight;
    // Added a buffer of 5 to ensure the page is set near the bottom,
    // accounting for differences in the bottom position across various browsers.
    // @ts-ignore
    let currentScroll = Math.ceil(event.target.scrollTop) + 5;

    if (currentScroll >= maxScroll) {
      if (canLoadMore) {
        setCurrentPage(previousState => previousState + 1);
      }
    }
  };

  const scrollToTop = () => {
    tableContent.scrollTop = 0;
  };

  useEffect(() => {
    tableContent?.addEventListener("scroll", scrollEventListener);

    return () => {
      tableContent?.removeEventListener("scroll", scrollEventListener);
    };
  }, [tableContent]);

  return { currentPage, scrollToTop, setCurrentPage };
};

export default useTableInfiniteScroll;
