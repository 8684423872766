import { createSlice } from "@reduxjs/toolkit";
import { PURGE } from "redux-persist";
import initialState from "./initialState";
import reducers from "./reducers";
import {
  updateName,
  updateStatus,
  updateInternalCode,
  updateClassificationId,
  updatePartId,
  updateAllergenPresent,
  addAllergens,
  removeAllergen,
  createSupplier,
  updateSupplier,
  removeSupplier,
  removeOrigin,
  createOrigin,
  updateOrigin,
  removeIngredient,
  addMadeInHouseIngredient,
  addRawMaterialIngredient,
  addCompositeIngredient,
  updateBoughtIngredientType,
  publishMaterial,
  addTag,
  removeTag,
  getMaterialCharacteristics,
  updateType,
  keepAsDraftMaterial,
  updateRecipe,
  updateIngredient,
  resetMaterial,
  updateSupplierIngredient,
  getMaterialControlPlans,
  updateAttachments,
  updateSubstanceId,
  updateCertificates,
} from "./asyncActions";
import { isObjectEmpty } from "utils/general";
import {
  handleActionFulfilled,
  handleActionPending,
  handleActionRejected,
} from "../helpers";
import { getActionStatus, prepareFormState } from "./utils";

export const slice = createSlice({
  name: "materialForm",
  initialState,
  reducers,
  extraReducers: builder => {
    /* istanbul ignore next*/
    builder.addCase(PURGE, () => ({ ...initialState }));

    builder.addCase(updateName.pending, state => {
      handleActionPending(getActionStatus(state));
    });
    builder.addCase(updateName.fulfilled, (state, { payload, meta }) => {
      if (!isObjectEmpty(payload)) {
        prepareFormState(state, payload);
      } else {
        state.materialInformation.name = meta.arg;
      }

      handleActionFulfilled(getActionStatus(state));
    });
    builder.addCase(updateName.rejected, state => {
      handleActionRejected(getActionStatus(state));
    });

    builder.addCase(addTag.pending, state => {
      handleActionPending(getActionStatus(state));
    });
    builder.addCase(addTag.fulfilled, (state, { payload, meta }) => {
      if (!isObjectEmpty(payload)) {
        prepareFormState(state, payload);
      } else {
        state.materialInformation.tagIds.push(meta.arg);
      }

      handleActionFulfilled(getActionStatus(state));
    });
    builder.addCase(addTag.rejected, state => {
      handleActionRejected(getActionStatus(state));
    });

    builder.addCase(removeTag.pending, state => {
      handleActionPending(getActionStatus(state));
    });
    builder.addCase(removeTag.fulfilled, (state, { payload, meta }) => {
      if (!isObjectEmpty(payload)) {
        prepareFormState(state, payload);
      } else {
        state.materialInformation.tagIds = state.materialInformation.tagIds.filter(
          currentTag => currentTag !== meta.arg
        );
      }

      handleActionFulfilled(getActionStatus(state));
    });
    builder.addCase(removeTag.rejected, state => {
      handleActionRejected(getActionStatus(state));
    });

    builder.addCase(updateStatus.pending, state => {
      handleActionPending(getActionStatus(state));
    });
    builder.addCase(updateStatus.fulfilled, (state, { payload, meta }) => {
      if (!isObjectEmpty(payload)) {
        prepareFormState(state, payload);
      } else {
        state.materialInformation.active = meta.arg;
      }

      handleActionFulfilled(getActionStatus(state));
    });
    builder.addCase(updateStatus.rejected, state => {
      handleActionRejected(getActionStatus(state));
    });

    builder.addCase(updateInternalCode.pending, state => {
      handleActionPending(getActionStatus(state));
    });
    builder.addCase(
      updateInternalCode.fulfilled,
      (state, { payload, meta }) => {
        if (!isObjectEmpty(payload)) {
          prepareFormState(state, payload);
        } else {
          state.materialInformation.internalCode = meta.arg;
        }

        handleActionFulfilled(getActionStatus(state));
      }
    );
    builder.addCase(updateInternalCode.rejected, state => {
      handleActionRejected(getActionStatus(state));
    });

    builder.addCase(updateType.pending, (state, { meta }) => {
      handleActionPending(getActionStatus(state));
    });
    builder.addCase(updateType.fulfilled, (state, { payload, meta }) => {
      if (!isObjectEmpty(payload)) {
        prepareFormState(state, payload);
      } else {
        state.materialInformation.materialType = meta.arg;
      }

      handleActionFulfilled(getActionStatus(state));
    });
    builder.addCase(updateType.rejected, (state, { meta }) => {
      handleActionRejected(getActionStatus(state));

      state.materialInformation.materialType = meta.arg;
    });

    builder.addCase(updateRecipe.pending, state => {
      handleActionPending(getActionStatus(state));
    });
    builder.addCase(updateRecipe.fulfilled, (state, { payload }) => {
      prepareFormState(state, payload);

      handleActionFulfilled(getActionStatus(state));
    });
    builder.addCase(updateRecipe.rejected, (state, { meta }) => {
      handleActionRejected(getActionStatus(state));

      state.materialInformation.recipeType = meta.arg;
    });

    builder.addCase(resetMaterial.fulfilled, state => {
      state.materialInformation.id = null;
      state.materialInformation.recipeType = null;
      state.rawMaterial = initialState.rawMaterial;
      state.suppliers = initialState.suppliers;
      state.allergens = initialState.allergens;
      state.recipe = initialState.recipe;
    });

    builder.addCase(updateClassificationId.pending, state => {
      handleActionPending(getActionStatus(state));
    });
    builder.addCase(updateClassificationId.fulfilled, (state, { payload }) => {
      prepareFormState(state, payload);

      handleActionFulfilled(getActionStatus(state));
    });
    builder.addCase(updateClassificationId.rejected, state => {
      handleActionRejected(getActionStatus(state));
    });

    builder.addCase(updatePartId.pending, state => {
      handleActionPending(getActionStatus(state));
    });
    builder.addCase(updatePartId.fulfilled, (state, { payload }) => {
      prepareFormState(state, payload);

      handleActionFulfilled(getActionStatus(state));
    });
    builder.addCase(updatePartId.rejected, state => {
      handleActionRejected(getActionStatus(state));
    });

    builder.addCase(updateSubstanceId.pending, state => {
      handleActionPending(getActionStatus(state));
    });
    builder.addCase(updateSubstanceId.fulfilled, (state, { payload }) => {
      prepareFormState(state, payload);

      handleActionFulfilled(getActionStatus(state));
    });
    builder.addCase(updateSubstanceId.rejected, state => {
      handleActionRejected(getActionStatus(state));
    });

    builder.addCase(updateAllergenPresent.pending, state => {
      handleActionPending(getActionStatus(state));
    });
    builder.addCase(updateAllergenPresent.fulfilled, (state, { payload }) => {
      prepareFormState(state, payload);

      handleActionFulfilled(getActionStatus(state));
    });
    builder.addCase(updateAllergenPresent.rejected, state => {
      handleActionRejected(getActionStatus(state));
    });

    builder.addCase(createSupplier.pending, state => {
      handleActionPending(getActionStatus(state));
    });
    builder.addCase(createSupplier.fulfilled, (state, { payload }) => {
      prepareFormState(state, payload);

      handleActionFulfilled(getActionStatus(state));
    });
    builder.addCase(createSupplier.rejected, state => {
      handleActionRejected(getActionStatus(state));
    });

    builder.addCase(removeSupplier.pending, state => {
      handleActionPending(getActionStatus(state));
    });
    builder.addCase(removeSupplier.fulfilled, (state, { payload }) => {
      prepareFormState(state, payload);

      handleActionFulfilled(getActionStatus(state));
    });
    builder.addCase(removeSupplier.rejected, state => {
      handleActionRejected(getActionStatus(state));
    });

    builder.addCase(updateSupplier.pending, state => {
      handleActionPending(getActionStatus(state));
    });
    builder.addCase(updateSupplier.fulfilled, (state, { payload }) => {
      prepareFormState(state, payload);

      handleActionFulfilled(getActionStatus(state));
    });
    builder.addCase(updateSupplier.rejected, state => {
      handleActionRejected(getActionStatus(state));
    });

    builder.addCase(updateSupplierIngredient.pending, state => {
      handleActionPending(getActionStatus(state));
    });
    builder.addCase(
      updateSupplierIngredient.fulfilled,
      (state, { payload }) => {
        prepareFormState(state, payload);

        handleActionFulfilled(getActionStatus(state));
      }
    );
    builder.addCase(updateSupplierIngredient.rejected, state => {
      handleActionRejected(getActionStatus(state));
    });

    builder.addCase(createOrigin.pending, state => {
      handleActionPending(getActionStatus(state));
    });
    builder.addCase(createOrigin.fulfilled, (state, { payload }) => {
      prepareFormState(state, payload);

      handleActionFulfilled(getActionStatus(state));
    });
    builder.addCase(createOrigin.rejected, state => {
      handleActionRejected(getActionStatus(state));
    });

    builder.addCase(updateOrigin.pending, state => {
      handleActionPending(getActionStatus(state));
    });
    builder.addCase(updateOrigin.fulfilled, (state, { payload }) => {
      prepareFormState(state, payload);

      handleActionFulfilled(getActionStatus(state));
    });
    builder.addCase(updateOrigin.rejected, state => {
      handleActionRejected(getActionStatus(state));
    });

    builder.addCase(removeOrigin.pending, state => {
      handleActionPending(getActionStatus(state));
    });
    builder.addCase(removeOrigin.fulfilled, (state, { payload }) => {
      prepareFormState(state, payload);

      handleActionFulfilled(getActionStatus(state));
    });
    builder.addCase(removeOrigin.rejected, state => {
      handleActionRejected(getActionStatus(state));
    });

    builder.addCase(addAllergens.pending, state => {
      handleActionPending(getActionStatus(state));
    });
    builder.addCase(addAllergens.fulfilled, (state, { payload }) => {
      prepareFormState(state, payload);

      handleActionFulfilled(getActionStatus(state));
    });
    builder.addCase(addAllergens.rejected, state => {
      handleActionRejected(getActionStatus(state));
    });

    builder.addCase(removeAllergen.pending, state => {
      handleActionPending(getActionStatus(state));
    });
    builder.addCase(removeAllergen.fulfilled, (state, { payload }) => {
      prepareFormState(state, payload);

      handleActionFulfilled(getActionStatus(state));
    });
    builder.addCase(removeAllergen.rejected, state => {
      handleActionRejected(getActionStatus(state));
    });

    builder.addCase(updateBoughtIngredientType.pending, state => {
      handleActionPending(getActionStatus(state));
    });
    builder.addCase(
      updateBoughtIngredientType.fulfilled,
      (state, { payload }) => {
        prepareFormState(state, payload);

        handleActionFulfilled(getActionStatus(state));
      }
    );
    builder.addCase(updateBoughtIngredientType.rejected, state => {
      handleActionRejected(getActionStatus(state));
    });

    builder.addCase(updateIngredient.pending, state => {
      handleActionPending(getActionStatus(state));
    });
    builder.addCase(updateIngredient.fulfilled, (state, { payload }) => {
      prepareFormState(state, payload);

      handleActionFulfilled(getActionStatus(state));
    });
    builder.addCase(updateIngredient.rejected, state => {
      handleActionRejected(getActionStatus(state));
    });

    builder.addCase(removeIngredient.pending, state => {
      handleActionPending(getActionStatus(state));
    });
    builder.addCase(removeIngredient.fulfilled, (state, { payload }) => {
      prepareFormState(state, payload);

      handleActionFulfilled(getActionStatus(state));
    });
    builder.addCase(removeIngredient.rejected, state => {
      handleActionRejected(getActionStatus(state));
    });

    builder.addCase(addMadeInHouseIngredient.pending, state => {
      handleActionPending(getActionStatus(state));
    });
    builder.addCase(
      addMadeInHouseIngredient.fulfilled,
      (state, { payload }) => {
        prepareFormState(state, payload);

        handleActionFulfilled(getActionStatus(state));
      }
    );
    builder.addCase(addMadeInHouseIngredient.rejected, state => {
      handleActionRejected(getActionStatus(state));
    });

    builder.addCase(addRawMaterialIngredient.pending, state => {
      handleActionPending(getActionStatus(state));
    });
    builder.addCase(
      addRawMaterialIngredient.fulfilled,
      (state, { payload }) => {
        prepareFormState(state, payload);

        handleActionFulfilled(getActionStatus(state));
      }
    );
    builder.addCase(addRawMaterialIngredient.rejected, state => {
      handleActionRejected(getActionStatus(state));
    });

    builder.addCase(addCompositeIngredient.pending, state => {
      handleActionPending(getActionStatus(state));
    });
    builder.addCase(addCompositeIngredient.fulfilled, (state, { payload }) => {
      prepareFormState(state, payload);

      handleActionFulfilled(getActionStatus(state));
    });
    builder.addCase(addCompositeIngredient.rejected, state => {
      handleActionRejected(getActionStatus(state));
    });

    builder.addCase(getMaterialCharacteristics.pending, state => {
      handleActionPending(getActionStatus(state));
    });
    builder.addCase(
      getMaterialCharacteristics.fulfilled,
      (state, { payload }) => {
        state.characteristicsSchema = payload;
        handleActionFulfilled(getActionStatus(state));
      }
    );
    builder.addCase(getMaterialCharacteristics.rejected, state => {
      handleActionRejected(getActionStatus(state));
    });

    builder.addCase(getMaterialControlPlans.pending, state => {
      handleActionPending(getActionStatus(state));
    });
    builder.addCase(getMaterialControlPlans.fulfilled, (state, { payload }) => {
      state.controlPlansSchema = payload;
      handleActionFulfilled(getActionStatus(state));
    });
    builder.addCase(getMaterialControlPlans.rejected, state => {
      handleActionRejected(getActionStatus(state));
    });

    builder.addCase(publishMaterial.pending, state => {
      handleActionPending(state.publishStatus);
    });
    builder.addCase(publishMaterial.fulfilled, state => {
      handleActionFulfilled(state.publishStatus);
    });
    builder.addCase(publishMaterial.rejected, state => {
      handleActionRejected(state.publishStatus);
    });

    builder.addCase(keepAsDraftMaterial.pending, state => {
      handleActionPending(state.keepAsDraftStatus);
    });
    builder.addCase(keepAsDraftMaterial.fulfilled, state => {
      handleActionFulfilled(state.keepAsDraftStatus);
    });
    builder.addCase(keepAsDraftMaterial.rejected, state => {
      handleActionRejected(state.keepAsDraftStatus);
    });

    builder.addCase(updateAttachments.pending, state => {
      handleActionPending(getActionStatus(state));
    });
    builder.addCase(updateAttachments.fulfilled, (state, { payload, meta }) => {
      if (!isObjectEmpty(payload)) {
        prepareFormState(state, payload);
      } else {
        state.materialInformation.fileIds = meta.arg;
      }

      handleActionFulfilled(getActionStatus(state));
    });
    builder.addCase(updateAttachments.rejected, state => {
      handleActionRejected(getActionStatus(state));
    });

    builder.addCase(updateCertificates.pending, state => {
      handleActionPending(getActionStatus(state));
    });
    builder.addCase(
      updateCertificates.fulfilled,
      (state, { payload, meta }) => {
        if (isObjectEmpty(payload)) {
          state.materialInformation.certificateIds = meta.arg;
        } else {
          prepareFormState(state, payload);
        }

        handleActionFulfilled(getActionStatus(state));
      }
    );
    builder.addCase(updateCertificates.rejected, state => {
      handleActionRejected(getActionStatus(state));
    });
  },
});

export const {
  setFormData,
  addNewIngredientLine,
  removeLastIngredient,
} = slice.actions;

export default slice.reducer;
