import { useIntl } from "react-intl";
import { ErrorBoundary } from "react-error-boundary";
import { translationManagementMessages } from "messages";
import StepTitle from "components/Library/components/StepTitle";
import { WarningFallback } from "components";
import CreateCollaboration, { messages } from "components/CreateCollaboration";
import { APPLICATION_TYPE_IDS, REFLIST_IDS } from "utils/constants";
import { useParams } from "react-router-dom";
import { isListEmpty } from "utils/general";
import useTranslationCollaboration from "./useTranslationCollaboration";
import Spin from "components/Spin";

export type TranslationUserInviteProps = {
  isLoading?: boolean;
};

const TranslationUserInvite: React.FC<TranslationUserInviteProps> = ({
  isLoading,
}) => {
  const { formatMessage } = useIntl();

  const { specificationId } = useParams<{
    specificationId: string;
  }>();

  const {
    companyIdsWithLinkedProducts,
    onAddCollaboration,
    existingCollaborationIds,
  } = useTranslationCollaboration();

  const isCompaniesSelected = !isListEmpty(companyIdsWithLinkedProducts);

  return (
    <ErrorBoundary FallbackComponent={WarningFallback}>
      <div className="mt-2">
        <StepTitle
          step={3}
          title={formatMessage(translationManagementMessages.inviteUserTitle)}
          subtitle={
            isCompaniesSelected
              ? formatMessage(translationManagementMessages.inviteUserSubtitle)
              : formatMessage(
                  translationManagementMessages.inviteUserEmptyStateSubtitle
                )
          }
        />

        {isLoading ? (
          <Spin />
        ) : (
          <>
            {isCompaniesSelected && (
              <CreateCollaboration
                applicationId={APPLICATION_TYPE_IDS.SPEC}
                parentDataObjectId={specificationId}
                parentDataObjectSystem="SPEC"
                roleIds={[REFLIST_IDS.TRANSLATOR_ROLE]}
                responsibilityIds={[
                  REFLIST_IDS.TRANSLATION_AGENCY_TRANSLATOR_MANAGER,
                ]}
                companyIdsWithLinkedProducts={companyIdsWithLinkedProducts}
                onCreatedCollaboration={onAddCollaboration}
                // Remove is not part of current feature, make changes here in future.
                onRemoveCollaborations={() => {}}
                existingCollaborationIds={existingCollaborationIds}
                onChangeValidation={() => {}}
                onChangeEnableSupplierCollaboration={() => {}}
                isLoading={false}
                hideHeading
                hideRemoveCollaboration
                disableCompanyFetch
                hideEnableCollaboration
                productLinkedMessage={messages.languageLinked}
                ignoreApplicationTypeIds
              />
            )}
          </>
        )}
      </div>
    </ErrorBoundary>
  );
};

export default TranslationUserInvite;
