import { useIntl } from "react-intl";
import { Typography } from "@trace-one/design-system";
import { isClaimTypeHealth, isClaimTypeNutritional } from "utils/claim";
import { ClaimData } from "models";
import { claimMessages } from "messages";

export type ClaimDescriptionProps = {
  claimType: ClaimData["claimType"];
  claimSentence: string;
  additionalClaimSentence?: string;
  conditionOfUse?: string;
};

const ClaimDescription: React.FC<ClaimDescriptionProps> = ({
  claimType,
  additionalClaimSentence,
  claimSentence,
  conditionOfUse,
}) => {
  const { formatMessage } = useIntl();

  return (
    <>
      {!isClaimTypeNutritional(claimType) && (
        <Typography variant="body-regular" color="grey-5" key="claim-sentence">
          {formatMessage(claimMessages.claimSentenceTitle)}: {claimSentence}
        </Typography>
      )}

      <>
        {isClaimTypeHealth(claimType) && (
          <Typography
            variant="body-regular"
            color="grey-5"
            key="addition-claim-sentence"
            className="mt-1"
          >
            {formatMessage(claimMessages.additionalClaimSentence)}:{" "}
            {additionalClaimSentence}
          </Typography>
        )}

        {(isClaimTypeHealth(claimType) ||
          isClaimTypeNutritional(claimType)) && (
          <Typography
            variant="body-regular"
            color="grey-5"
            key="condition-of-use"
            className="mt-1"
          >
            {formatMessage(claimMessages.conditionOfUseTranslation)}:{" "}
            {conditionOfUse}
          </Typography>
        )}
      </>
    </>
  );
};

export default ClaimDescription;
