import { Row, Col } from "antd";
import {
  Paragraph,
  Heading,
  DisplayCard,
  ToggleButtons,
  Tooltip,
} from "@trace-one/design-system";
import { useIntl } from "react-intl";
import classnames from "classnames";
import InfiniteScroll from "react-infinite-scroll-component";
import ConditionalWrapper from "components/ConditionalWrapper";
import Spin from "components/Spin";
import { getVersionName } from "utils/specifications";
import { getIsAnotherVersionLinked } from "utils/library";
import { useDisplayItems } from "./hooks";
import { InfiniteScrollLibraryContentProps } from "./types";
import { generalMessages } from "messages";
import styles from "./styles.module.less";

const InfiniteScrollLibraryContent: React.FC<InfiniteScrollLibraryContentProps> = ({
  title,
  totalNumberOfItems,
  canLoadMore,
  libraryObjectList,
  nextFetchCallback,
  onSelectLibraryItem,
  existingLibraryObjectIds = [],
  existingHistoryIds = [],
  selectedLibraryObjectIds,
  infiniteScrollWrapper,
  conditionalTooltipText,
}) => {
  const { displayItems, options, onDisplayItemChange } = useDisplayItems();
  const { formatMessage } = useIntl();

  return (
    <>
      <Row
        justify="space-between"
        align="middle"
        className={styles.modalBodyTitleRow}
      >
        <Col>
          <Heading className={styles.modalBodyTitle} size="xs">
            {title}
          </Heading>
          <Paragraph className={styles.modalBodyTotalNumber} size="s">
            {` (${totalNumberOfItems})`}
          </Paragraph>
        </Col>
        <Col>
          <ToggleButtons
            onChange={onDisplayItemChange}
            defaultValue="display-4-columns"
            options={options}
          />
        </Col>
      </Row>
      <div
        id="scrollableInfiniteScrollWrapper"
        ref={infiniteScrollWrapper}
        className={styles.infiniteScrollWrapper}
      >
        <InfiniteScroll
          scrollableTarget="scrollableInfiniteScrollWrapper"
          className={classnames(
            styles.infiniteScrollDisplay,
            styles[displayItems],
            styles.infiniteScroll
          )}
          dataLength={libraryObjectList.length}
          hasMore={canLoadMore}
          loader={
            <div className={styles.infiniteScrollLoadingContainer}>
              <Spin />
            </div>
          }
          next={nextFetchCallback}
        >
          {!totalNumberOfItems && (
            <Paragraph size="s">
              {formatMessage(generalMessages.noResultsFound)}
            </Paragraph>
          )}
          {libraryObjectList.map(libraryObject => {
            const {
              id,
              name,
              formattedType,
              iconName,
              versionNumber,
              historyId,
            } = libraryObject;

            const isAnotherVersionLinked = getIsAnotherVersionLinked({
              existingHistoryIds,
              libraryObjectHistoryId: historyId,
              libraryObjectId: id,
              existingLibraryObjectIds,
            });

            const renderWrappedComponent = (children: React.ReactElement) => (
              <Tooltip
                text={
                  conditionalTooltipText ||
                  formatMessage(generalMessages.previousVersionLinked)
                }
                getPopupContainer={(trigger: HTMLElement) =>
                  trigger.parentElement
                }
                data-testid="another-version-linked-tooltip"
              >
                {children}
              </Tooltip>
            );

            return (
              <ConditionalWrapper
                condition={isAnotherVersionLinked}
                wrapper={renderWrappedComponent}
                key={id}
              >
                <DisplayCard
                  key={id}
                  title={name}
                  subtitle={formattedType}
                  iconName={iconName}
                  version={getVersionName(versionNumber)}
                  onClick={() => onSelectLibraryItem(libraryObject)}
                  disabled={
                    existingLibraryObjectIds.includes(id) ||
                    isAnotherVersionLinked
                  }
                  selected={selectedLibraryObjectIds?.includes(id)}
                />
              </ConditionalWrapper>
            );
          })}
        </InfiniteScroll>
      </div>
    </>
  );
};

export default InfiniteScrollLibraryContent;
