import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { selectLanguageCode } from "store/user/selectors";
import { RegulatoryHealthClaim } from "models";
import {
  fetchHealthClaimRegulation,
  fetchHealthClaimRegulationCollection,
} from "apis/SPEC";
import { isListEmpty } from "utils/general";

export interface UseHealthClaimRegulation {
  id?: string;
  ids?: string[];
  skip?: boolean;
}

const useHealthClaimRegulation = ({
  id,
  ids,
  skip,
}: UseHealthClaimRegulation) => {
  const languageCode = useSelector(selectLanguageCode);
  const [regulation, setRegulation] = useState<RegulatoryHealthClaim>(
    undefined
  );
  const [regulations, setRegulations] = useState<RegulatoryHealthClaim[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState(false);

  const getRegulations = async () => {
    if (isListEmpty(ids) && !id) return;

    // endpoint need not be called if a claim data/regulation is removed
    if (
      !isListEmpty(ids) &&
      ids.every(id => regulations.map(({ id }) => id).includes(id))
    ) {
      setRegulations(prevState =>
        prevState.filter(({ id }) => ids.includes(id))
      );

      return;
    }

    try {
      setIsLoading(true);

      if (!isListEmpty(ids)) {
        const { data } = await fetchHealthClaimRegulationCollection({
          payload: { claimIds: ids },
        });
        setRegulations(data);
      }

      if (id) {
        const { data } = await fetchHealthClaimRegulation({
          id,
          languageCode,
        });
        setRegulation(data);
      }
    } catch (error) {
      setHasError(true);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (!skip) {
      getRegulations();
    }
  }, [skip, JSON.stringify(ids), id]);

  return {
    regulation,
    regulations,
    isLoading,
    hasError,
  };
};

export default useHealthClaimRegulation;
