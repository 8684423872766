import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { fetchSpecificationFields } from "apis/SPEC";
import { selectLanguageCode } from "store/user/selectors";
import { addOrUpdateItemToAList, isListEmpty } from "utils/general";
import {
  getInfoFromValue,
  isTypeField,
} from "components/SpecificationHistorisationModal/utils";

import {
  FieldSimpleViewData,
  SectionSimpleViewData,
  SpecificationData,
} from "models";
import {
  FilterTag,
  HistorisationFilters,
} from "components/SpecificationHistorisationModal/types";
import { DateType } from "utils/date";

type CustomRangePickerOnChange = (
  values: [DateType?, DateType?],
  formatString: [string, string]
) => void;

const useFilters = ({
  specificationId,
  onAddFilterTag,
  onRemoveFilterTag,
  resetFilterTags,
}: {
  specificationId: SpecificationData["id"];
  onAddFilterTag: (tagCategoryName: string) => (filter: FilterTag) => void;
  onRemoveFilterTag: (
    tagCategoryName: string
  ) => (value: FilterTag["value"]) => void;
  resetFilterTags: Function;
}) => {
  const getSelectedFiltersInitialState = () => {
    return {
      dates: [null, null],
      actions: [],
      users: [],
      chapters: [],
    };
  };
  const languageCode = useSelector(selectLanguageCode);
  const [chapters, setChapters] = useState<SectionSimpleViewData[]>([]);
  const [selectedFilters, setSelectedFilters] = useState<HistorisationFilters>(
    getSelectedFiltersInitialState()
  );

  const setDates: CustomRangePickerOnChange = values => {
    setSelectedFilterCriterias("dates")(
      isListEmpty(values) ? [null, null] : values
    );
  };

  const setSelectedFilterCriterias = (key: string) => values => {
    setSelectedFilters(previousState => ({
      ...previousState,
      [key]: values,
    }));
  };

  const onClearFiltersClick = (tagCategoryName?: string) => {
    resetFilterTags(tagCategoryName);

    if (tagCategoryName) {
      setSelectedFilters(previousState => ({
        ...previousState,
        [tagCategoryName]: [],
      }));
    } else {
      setSelectedFilters(getSelectedFiltersInitialState());
    }
  };

  const getFields = async () => {
    try {
      const { data } = await fetchSpecificationFields({
        specificationId,
        languageCode,
      });

      setChapters(data);
    } catch (e) {
    } finally {
    }
  };

  const generateIdsInfo = (ids: string[], selectedId: string) => {
    let firstElementId = selectedId;
    let restOfIds = [];

    if (!isListEmpty(ids)) {
      [firstElementId, ...restOfIds] = ids;
    }

    return {
      firstElementId,
      restOfIds,
    };
  };

  const removeFilterFields = ({
    selectedFilters,
    ids,
    type,
    selectedId,
  }: {
    selectedFilters: SectionSimpleViewData[];
    ids?: string[];
    type?: string;
    selectedId: string;
  }) => {
    const { firstElementId, restOfIds } = generateIdsInfo(ids, selectedId);

    let sections: SectionSimpleViewData[] = [];
    let fields: FieldSimpleViewData[] = [];

    //Get the item from the selected filters list
    let filterSelectedItem = selectedFilters.find(
      ({ sectionId }) => sectionId === firstElementId
    );

    if (!isListEmpty(restOfIds)) {
      sections = removeFilterFields({
        selectedFilters: filterSelectedItem.sections,
        ids: restOfIds,
        type,
        selectedId,
      });
    } else {
      if (isTypeField(type)) {
        const [propertyId, templateBlockId] = selectedId.split("@");

        fields = filterSelectedItem.fields.filter(
          selectedFilter =>
            selectedFilter.propertyId !== propertyId &&
            selectedFilter.templateBlockId !== templateBlockId
        );
      } else {
        sections = filterSelectedItem.sections.filter(
          selectedFilter => selectedFilter.sectionId !== selectedId
        );
      }
    }

    // If the current item is not the selected one and the sections and fields list is empty the element is removed from the list
    if (firstElementId !== selectedId) {
      if (isListEmpty(sections) && isListEmpty(fields)) {
        return selectedFilters.filter(
          selectedFilter => selectedFilter.sectionId !== firstElementId
        );
      }
    }

    return addOrUpdateItemToAList({
      list: selectedFilters,
      item: { ...filterSelectedItem, fields, sections },
      propertyName: "sectionId",
    });
  };

  const clearChapter = (value: string) => {
    const { type, ids } = getInfoFromValue(value);

    onRemoveFilterTag("chapters")(value);

    const selectedId = ids.pop();

    if (isListEmpty(ids)) {
      setSelectedFilters(previousState => ({
        ...previousState,
        chapters: previousState.chapters.filter(
          ({ sectionId }) => sectionId !== selectedId
        ),
      }));
    } else {
      setSelectedFilters(previousState => ({
        ...previousState,
        chapters: removeFilterFields({
          selectedFilters: previousState.chapters,
          ids,
          type,
          selectedId,
        }),
      }));
    }
  };

  const addFilterFields = ({
    originalItems,
    selectedFilters,
    ids,
    type,
    selectedId,
  }: {
    originalItems: SectionSimpleViewData[];
    selectedFilters: SectionSimpleViewData[];
    ids?: string[];
    type?: string;
    selectedId: string;
  }) => {
    const { firstElementId, restOfIds } = generateIdsInfo(ids, selectedId);

    //Get the original item with the data from the API
    let originalSelectedItem = originalItems.find(
      ({ sectionId }) => firstElementId === sectionId
    );

    //Get the item from the selected filters list
    let filterSelectedItem = selectedFilters.find(
      ({ sectionId }) => sectionId === firstElementId
    );

    // If the item is not added yet we generate a new one
    if (!filterSelectedItem) {
      filterSelectedItem = {
        sectionId: originalSelectedItem.sectionId,
        sections: [],
        fields: [],
      };
    }

    let sections: SectionSimpleViewData[] = [];
    let fields: FieldSimpleViewData[] = [];

    if (firstElementId !== selectedId) {
      // This is the case to check if we didn't arrive at the selected item in the list
      if (!isListEmpty(restOfIds)) {
        sections = addFilterFields({
          originalItems: originalSelectedItem.sections,
          selectedFilters: filterSelectedItem.sections,
          ids: restOfIds,
          type,
          selectedId,
        });
      } else {
        if (isTypeField(type)) {
          const [propertyId, templateBlockId] = selectedId.split("@");

          // We try to get the selected field from the selected filters list
          let filterSelectedField = filterSelectedItem?.fields.find(
            currentField =>
              propertyId === currentField.propertyId &&
              templateBlockId === currentField.templateBlockId
          );

          // If the field is not added yet we generate a new one
          if (!filterSelectedField) {
            //Get the original field with the data from the API
            let originalSelectedField = originalSelectedItem?.fields.find(
              currentField =>
                propertyId === currentField.propertyId &&
                templateBlockId === currentField.templateBlockId
            );

            filterSelectedField = {
              propertyId: originalSelectedField.propertyId,
              templateBlockId: originalSelectedField.templateBlockId,
            };
          }

          fields = addOrUpdateItemToAList({
            list: filterSelectedItem.fields,
            propertyName: "propertyId",
            item: filterSelectedField,
          });
        } else {
          sections = addOrUpdateItemToAList({
            list: filterSelectedItem.sections,
            propertyName: "sectionId",
            item: {
              sectionId: selectedId,
              sections: [],
              fields: [],
            },
          });
        }
      }
    }

    filterSelectedItem = {
      sectionId: originalSelectedItem.sectionId,
      sections,
      fields,
    };

    return addOrUpdateItemToAList({
      list: selectedFilters,
      item: filterSelectedItem,
      propertyName: "sectionId",
    });
  };

  const addChapter = (value: string, tagName?: string) => {
    const { type, ids } = getInfoFromValue(value);

    const selectedId = ids.pop();

    onAddFilterTag("chapters")({
      name: tagName,
      value,
      onClose: () => {
        clearChapter(value);
      },
    });

    setSelectedFilters(previousState => ({
      ...previousState,
      chapters: addFilterFields({
        originalItems: chapters,
        selectedFilters: previousState.chapters,
        ids,
        type,
        selectedId,
      }),
    }));
  };

  useEffect(() => {
    getFields();
  }, []);

  return {
    selectedFilters,
    chapters,
    setDates,
    setSelectedFilterCriterias,
    onClearFiltersClick,
    addChapter,
    clearChapter,
  };
};

export default useFilters;
