import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { Col, Row } from "antd";
import { Button, Heading } from "@trace-one/design-system";
import { selectRequirements } from "store/folderCreation/selectors";
import { useModalVisibility } from "hooks";
import { isListEmpty } from "utils/general";
import RequirementsTabs from "components/RequirementsTabs";
import SelectLibraryObjectsModal from "components/SelectLibraryObjectsModal";
import AssignedProductsStep from "./components/AssignedProductsStep";
import { useDisableAssignedRequirements } from "./hooks";
import {
  LIBRARY_API_PARAMS_VERSION,
  LIBRARY_OBJECT_TYPES,
} from "utils/constants";
import { messages } from "./messages";
import styles from "./styles.module.less";
import SelectLibraryObjectsStep from "components/SelectLibraryObjectsStep";

const Requirements = () => {
  const { formatMessage } = useIntl();
  const requirements = useSelector(selectRequirements);

  const { isModalOpen, onOpenModal, onCloseModal } = useModalVisibility();

  const { disabledRequirementIds } = useDisableAssignedRequirements();

  return (
    <div className={styles.container}>
      {isListEmpty(requirements) ? (
        <Row
          justify="center"
          gutter={[0, 25]}
          data-test-id="no-requirement-assigned"
        >
          <Heading size="xs">
            {formatMessage(messages.requirementsPlaceholder)}
          </Heading>
        </Row>
      ) : (
        <RequirementsTabs requirements={requirements} />
      )}
      <Row justify="center">
        <Button data-test-id="add-requirements-button" onClick={onOpenModal}>
          {formatMessage(messages.addRequirements)}
        </Button>
      </Row>
      {isModalOpen ? (
        <SelectLibraryObjectsModal onClose={onCloseModal}>
          <Col xs={14}>
            <SelectLibraryObjectsStep
              type={LIBRARY_OBJECT_TYPES.REQUIREMENTS}
              disabledLibraryItems={disabledRequirementIds}
              version={LIBRARY_API_PARAMS_VERSION.activeVersion}
            />
          </Col>
          <Col xs={10}>
            <AssignedProductsStep alreadyAppliedRequirements={requirements} />
          </Col>
        </SelectLibraryObjectsModal>
      ) : null}
    </div>
  );
};

export default Requirements;
