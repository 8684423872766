import { utils } from "@rjsf/core";
import { useFormSchemaTranslation } from "pages/Specification/components/Details/hooks";
import { MultipleRefListWidget, ArrayItemsWidget } from "../../widgets";
import FixedArrayFieldTemplate from "./FixedArrayFieldTemplate";
import FlexibleArrayFieldTemplate from "./FlexibleArrayFieldTemplate";
import NormalArrayFieldTemplate from "./NormalArrayFieldTemplate";
import ContactsArrayFieldTemplate from "./ContactsArrayFieldTemplate";
import CharacteristicsFieldTemplate from "./CharacteristicsFieldTemplate";
import ControlPlansFieldTemplate from "./ControlPlansFieldTemplate";
import ReferenceListArrayFieldTemplate from "./ReferenceListArrayFieldTemplate";
import SelectWithComboArrayFieldTemplate from "./SelectWithComboArrayFieldTemplate";
import withConditionalArray from "./hocs/withConditionalArray";
import { getNoItemsMessageKey, getAddBtnLabelKey } from "./utils";
import PackagingSystemComponentMaterialArrayFieldTemplate from "./PackagingSystemComponentMaterialArrayFieldTemplate";
import AllergensArrayFieldTemplate from "./AllergensArrayFieldTemplate";
import ProductOriginsArrayFieldTemplate from "./ProductOriginsArrayFieldTemplate";
import FileUploadTemplate from "./FileUploadTemplate";

const {
  getDefaultRegistry,
  getUiOptions,
  getWidget,
  isFilesArray,
  isFixedItems,
  isMultiSelect,
  optionsList,
  retrieveSchema,
} = utils;

const ArrayFieldTemplate = props => {
  const {
    autofocus,
    disabled,
    formContext,
    formData,
    idSchema,
    items,
    label,
    name,
    onAddClick,
    onBlur,
    onChange,
    onFocus,
    placeholder,
    rawErrors,
    readonly,
    registry = getDefaultRegistry(),
    required,
    schema,
    uiSchema,
  } = props;

  const { fields, rootSchema, widgets } = registry;
  const { UnsupportedField } = fields;
  const { getTranslationForKey } = useFormSchemaTranslation();

  const noItemsMessage = getTranslationForKey(
    getNoItemsMessageKey(uiSchema["ui:widget"])
  );

  const addBtnLabel = uiSchema["ui:add:button:label"]
    ? getTranslationForKey(uiSchema["ui:add:button:label"])
    : getTranslationForKey(getAddBtnLabelKey(uiSchema["ui:widget"]));

  const { itemKey, items: schemaItems } = schema;

  // @ts-ignore
  const reflistId = schemaItems?.properties?.[itemKey]?.referenceList?.listId;

  const renderFiles = () => {
    const { widget = "files", ...options } = getUiOptions(uiSchema);

    // @ts-ignore
    const Widget = getWidget(schema, widget, widgets);

    return (
      // @ts-ignore
      <Widget
        autofocus={autofocus}
        disabled={disabled}
        formContext={formContext}
        id={idSchema && idSchema.$id}
        multiple
        onBlur={onBlur}
        onChange={onChange}
        onFocus={onFocus}
        options={options}
        rawErrors={rawErrors}
        readonly={readonly}
        schema={schema}
        title={schema.title || name}
        value={formData}
      />
    );
  };

  const renderMultiSelect = () => {
    const itemsSchema = retrieveSchema(schema.items, rootSchema, formData);
    const enumOptions = optionsList(itemsSchema);
    const { widget = "select", ...options } = {
      ...getUiOptions(uiSchema),
      enumOptions,
    };

    const Widget = getWidget(schema, widget, widgets);

    return (
      <Widget
        autofocus={autofocus}
        disabled={disabled}
        formContext={formContext}
        id={idSchema && idSchema.$id}
        label={label}
        multiple
        onBlur={onBlur}
        onChange={onChange}
        onFocus={onFocus}
        options={options}
        placeholder={placeholder}
        rawErrors={rawErrors}
        readonly={readonly}
        // @ts-ignore
        registry={registry}
        required={required}
        schema={schema}
        value={formData}
      />
    );
  };

  if (!Object.prototype.hasOwnProperty.call(schema, "items")) {
    return (
      <UnsupportedField
        idSchema={idSchema}
        reason="Missing items definition"
        schema={schema}
      />
    );
  }

  const arrayFieldTemplateProps = {
    ...props,
    addBtnLabel,
    noItemsMessage,
  };

  if (uiSchema["ui:widget"] === "AllergensWidget") {
    return <AllergensArrayFieldTemplate {...arrayFieldTemplateProps} />;
  }

  if (uiSchema["ui:widget"] === "MultipleRefListWidget") {
    return (
      <MultipleRefListWidget
        {...arrayFieldTemplateProps}
        rootSchema={rootSchema}
      />
    );
  }

  if (uiSchema["ui:widget"] === "ContactsWidget") {
    return <ContactsArrayFieldTemplate {...arrayFieldTemplateProps} />;
  }

  if (uiSchema["ui:widget"] === "TableWidget") {
    return (
      <CharacteristicsFieldTemplate
        // @ts-ignore
        items={items}
        formContext={formContext}
        formData={formData}
        uiSchema={uiSchema}
        schema={schema}
        onChange={onChange}
      />
    );
  }

  if (uiSchema["ui:widget"] === "TablesWidget") {
    return (
      <ControlPlansFieldTemplate
        // @ts-ignore
        items={items}
        formContext={formContext}
        formData={formData}
        uiSchema={uiSchema}
        schema={schema}
        onChange={onChange}
        noItemsMessage={noItemsMessage}
      />
    );
  }

  if (uiSchema["ui:widget"] === "SelectWithComboWidget") {
    return (
      // @ts-ignore
      <SelectWithComboArrayFieldTemplate
        items={items}
        formContext={formContext}
        formData={formData}
        uiSchema={uiSchema}
        onAddClick={onAddClick}
        noItemsMessage={noItemsMessage}
        schema={schema}
        idSchema={idSchema}
      />
    );
  }

  if (uiSchema["ui:widget"] === "FlexibleArrayWidget") {
    return <FlexibleArrayFieldTemplate {...arrayFieldTemplateProps} />;
  }

  if (uiSchema["ui:widget"] === "PackagingSystemComponentMaterialWidget") {
    return (
      <PackagingSystemComponentMaterialArrayFieldTemplate
        {...arrayFieldTemplateProps}
      />
    );
  }

  if (uiSchema["ui:widget"] === "ProductOriginsWidget") {
    return <ProductOriginsArrayFieldTemplate {...arrayFieldTemplateProps} />;
  }

  if (uiSchema["ui:widget"] === "ArrayItemsWidget") {
    return <ArrayItemsWidget {...arrayFieldTemplateProps} />;
  }

  if (uiSchema["ui:widget"] === "FileUploadWidget") {
    return <FileUploadTemplate {...arrayFieldTemplateProps} />;
  }

  if (reflistId) {
    return (
      <ReferenceListArrayFieldTemplate
        {...arrayFieldTemplateProps}
        reflistId={reflistId}
      />
    );
  }

  if (isFixedItems(schema)) {
    return <FixedArrayFieldTemplate {...arrayFieldTemplateProps} />;
  }

  if (isFilesArray(schema, uiSchema, rootSchema)) {
    return renderFiles();
  }

  if (isMultiSelect(schema, rootSchema)) {
    return renderMultiSelect();
  }

  return <NormalArrayFieldTemplate {...arrayFieldTemplateProps} />;
};

export default withConditionalArray(ArrayFieldTemplate);
