import { Toggle } from "@trace-one/design-system";
import NutritionBasic from "components/SpecificationSection/components/FormSchema/components/NutritionBasic";
import { getNutritionFamilyStatus } from "components/SpecificationSection/components/FormSchema/components/NutritionBasic/utils";
import { useNutritionTable } from "components/SpecificationSection/components/FormSchema/components/NutritionBasic/shared/hooks";
import AddANutrient from "./components/AddANutrient";
import { useNutritionWidgetNutritionColumns } from "./hooks";
import { useFormSchemaTranslation } from "pages/Specification/components/Details/hooks";
import { NutritionWidgetProps } from "./types";
import { Row } from "antd";
import { NutritionFormData } from "models";

const NutritionWidget: React.FC<NutritionWidgetProps> = ({
  formData,
  formContext,
  schema,
  uiSchema,
  properties,
}) => {
  const { getTranslationForKey } = useFormSchemaTranslation();
  const {
    isWidgetEditable,
    extraErrors,
    displayActions,
    saveTemplateBlock,
  } = formContext;

  const {
    dataSource,
    existingNutrientIds,
    nutrientDictionary,
    nutrientsWithoutFamily,
  } = useNutritionTable({
    formData,
  });

  const hasUpdatePermission = isWidgetEditable();

  const handleUpdateNutrition = async (payload: NutritionFormData) => {
    await saveTemplateBlock?.(JSON.stringify(payload));
  };

  const onToggleChange = async (value: NutritionFormData) => {
    await saveTemplateBlock?.(
      JSON.stringify({
        ...formData,
        automaticCalculation: value,
      })
    );
  };

  const { columns } = useNutritionWidgetNutritionColumns({
    hasUpdatePermission,
    formData,
    uiSchema,
    schema,
    errorSchema: extraErrors,
    statusNutrientFamilyDictionary: getNutritionFamilyStatus(dataSource),
    nutrientDictionary,
    displayActions,
    updateNutrition: handleUpdateNutrition,
    nutrientsWithoutFamily,
  });

  const { automaticCalculation } = formData || {};

  return (
    <>
      {displayActions && hasUpdatePermission ? (
        <Row justify="space-between">
          <AddANutrient
            existingNutrientIds={existingNutrientIds}
            addNutritionAction={handleUpdateNutrition}
            formData={formData}
          />
          <Toggle
            data-testid="automatic-energy-calculation"
            onChange={onToggleChange}
            checked={automaticCalculation}
            disabled={!hasUpdatePermission}
            text={getTranslationForKey(
              "nutritionAutomaticEnergyCalculationLabel"
            )}
            textPlacement="left"
          />
        </Row>
      ) : null}
      <NutritionBasic
        formContext={formContext}
        schema={schema}
        columns={columns}
        dataSource={dataSource}
      />
    </>
  );
};

export default NutritionWidget;
