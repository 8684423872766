import { useEffect } from "react";
import { useIntl } from "react-intl";
import { Button } from "@trace-one/design-system";
import LinkedSpecificationsModal from "components/LinkedSpecificationsModal";
import Spin from "components/Spin";
import {
  useChapterDetails,
  useLinkedSpecifications,
  useModalVisibility,
} from "hooks";
import {
  isLibraryObjectChapterSubType,
  isLibraryObjectDraft,
} from "utils/library";
import { LibraryListItemViewModel } from "viewModels";
import { messages } from "../../../../messages";

const LinkedSpecifications = ({
  libraryItem: { id, type, state },
}: {
  libraryItem: LibraryListItemViewModel;
}) => {
  const { formatMessage } = useIntl();

  const { isModalOpen, onOpenModal, onCloseModal } = useModalVisibility();

  const {
    totalCount,
    getLinkedSpecifications,
    isLoading,
  } = useLinkedSpecifications({
    libraryItemType: type,
    libraryItemId: id,
  });

  const { chapterInfo } = useChapterDetails({
    chapterId: id,
    chapterType: type,
    fetchOnEffect: isModalOpen,
  });

  useEffect(() => {
    if (!isLibraryObjectDraft(state) && isLibraryObjectChapterSubType(type)) {
      getLinkedSpecifications();
    }
  }, []);

  if (isLoading) return <Spin size="small" />;

  return (
    <>
      <Button
        type="link"
        children={totalCount.toString()}
        onClick={onOpenModal}
        disabled={totalCount === 0}
      />
      {isModalOpen && (
        <LinkedSpecificationsModal
          title={formatMessage(messages.detailsAndLinksChapterModalTitle)}
          libraryItemId={id}
          libraryItemType={type}
          libraryItemName={chapterInfo?.name}
          libraryItemState={chapterInfo?.state}
          libraryItemCreationDateUtc={chapterInfo?.creationDateUtc}
          versionNumber={chapterInfo?.version?.number}
          onCloseModal={onCloseModal}
        />
      )}
    </>
  );
};

export default LinkedSpecifications;
