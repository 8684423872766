import { useState } from "react";
import { useBoolean } from "hooks";

export type UseSelectItemProps = {
  onChange: Function;
  defaultValue?: string;
  displayConfirmationTooltip?: boolean;
};

const useSelectItem = ({
  defaultValue,
  onChange: defaultOnChange,
  displayConfirmationTooltip = false,
}: UseSelectItemProps) => {
  const [value, setValue] = useState(defaultValue);
  const [pendingValue, setPendingValue] = useState(null);

  const {
    value: confirmOpen,
    setTrue: setConfirmOpenTrue,
    setFalse: setConfirmOpenFalse,
  } = useBoolean(false);

  const handleSelectChange = newValue => {
    setPendingValue(newValue);
    setConfirmOpenTrue();
  };

  const confirmChange = () => {
    defaultOnChange(pendingValue);
    setValue(pendingValue);
    setConfirmOpenFalse();
  };

  const cancelChange = () => {
    setPendingValue(null);
    setConfirmOpenFalse();
  };

  return {
    value: displayConfirmationTooltip ? value : defaultValue,
    onChangeHandler: displayConfirmationTooltip
      ? handleSelectChange
      : defaultOnChange,
    confirmOpen,
    confirmChange,
    cancelChange,
  };
};

export default useSelectItem;
