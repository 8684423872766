import { useIntl } from "react-intl";
import { LabeledValue } from "antd/lib/select";
import { Col } from "antd";
import { Picker, Paragraph } from "@trace-one/design-system";
import { useClaimFilters } from "./hooks";
import { LIBRARY_OBJECT_TYPES } from "utils/constants";
import { isClaimTypeNutritional } from "utils/claim";
import { isListEmpty } from "utils/general";
import { ClaimType, LibraryItemState } from "types/library";
import { UpdateFilterType } from "../../types";
import { claimMessages, filterMessages, generalMessages } from "messages";

export type ClaimFiltersProps = {
  type: LIBRARY_OBJECT_TYPES;
  stateOptions: LabeledValue[];
  selectedClaimTypes?: ClaimType[];
  displayStatusFilter?: boolean;
  selectedStates: LibraryItemState[];
  setStates: (_: string[]) => void;
  selectedNutrientIds: string[];
  selectedSubstanceIds: string[];
  updateFilter: UpdateFilterType;
  isLibraryListingPage: boolean;
};

const ClaimFilters: React.FC<ClaimFiltersProps> = ({
  type,
  selectedClaimTypes,
  isLibraryListingPage,
  displayStatusFilter = true,
  stateOptions,
  selectedStates,
  setStates,
  selectedNutrientIds,
  selectedSubstanceIds,
  updateFilter,
}) => {
  const { formatMessage } = useIntl();

  const {
    claimTypeOptions,
    claimNutrientOptions,
    claimSubstanceOptions,
    onStatesFilterChange,
    onClearStatesFilter,
    onClaimFilterChange,
    onClearClaimFilter,
  } = useClaimFilters({
    type,
    isLibraryListingPage,
    updateFilter,
    setStates,
  });

  const isNutritionalClaim = isClaimTypeNutritional(type);

  return (
    <>
      {!isNutritionalClaim && (
        <Col>
          <Picker.Filter
            title={formatMessage(claimMessages.claimTypeLabel)}
            items={claimTypeOptions}
            onChange={onClaimFilterChange("claimTypes")}
            clearSelection={onClearClaimFilter("claimTypes")}
            key="claim-search-filter-status"
            value={selectedClaimTypes || []}
            data-test-id="filter-claims-claim-type"
            {...(isListEmpty(claimTypeOptions) && {
              renderAlternateContent: (
                <Paragraph className="pl-2 pb-2">
                  {formatMessage(generalMessages.noItemFound)}
                </Paragraph>
              ),
            })}
          />
        </Col>
      )}

      {displayStatusFilter && (
        <Col>
          <Picker.Filter
            title={formatMessage(filterMessages.status)}
            items={stateOptions}
            onChange={onStatesFilterChange}
            key="claim-search-filter-status"
            value={selectedStates || []}
            clearSelection={onClearStatesFilter}
            data-test-id="filter-claims-status"
          />
        </Col>
      )}

      <Col>
        <Picker.Filter
          title={formatMessage(filterMessages.nutrient)}
          items={claimNutrientOptions}
          key="claim-search-filter-nutrients"
          value={selectedNutrientIds || []}
          onChange={onClaimFilterChange("nutrientIds")}
          clearSelection={onClearClaimFilter("nutrientIds")}
          data-test-id="filter-claims-nutrient"
          {...(isListEmpty(claimNutrientOptions) && {
            renderAlternateContent: (
              <Paragraph className="pl-2 pb-2">
                {formatMessage(generalMessages.noItemFound)}
              </Paragraph>
            ),
          })}
        />
      </Col>

      <Col>
        <Picker.Filter
          title={formatMessage(generalMessages.substance)}
          items={claimSubstanceOptions}
          onChange={onClaimFilterChange("substanceIds")}
          clearSelection={onClearClaimFilter("substanceIds")}
          key="claim-search-filter-substances"
          value={selectedSubstanceIds || []}
          data-test-id="filter-claims-substance"
          {...(isListEmpty(claimSubstanceOptions) && {
            renderAlternateContent: (
              <Paragraph className="pl-2 pb-2">
                {formatMessage(generalMessages.noItemFound)}
              </Paragraph>
            ),
          })}
        />
      </Col>
    </>
  );
};

export default ClaimFilters;
