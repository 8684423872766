import { useSelector } from "react-redux";
import { useEffect } from "react";
import { Col, Form, Row } from "antd";
import { useIntl } from "react-intl";
import libraryMessages from "messages/library";
import { LIBRARY_OBJECT_STATES, MAX_CHAR_LENGTH } from "utils/constants";
import Spin from "components/Spin";
import { TemplateFormGeneralInfo } from "./TemplateFormGeneralInfo";
import { TemplateFormConfigure } from "./TemplateFormConfigure";
import { useTemplateForm, useTemplateFormInitialization } from "./hooks";
import { useLibraryFormActions } from "components/Library/hooks";
import { useAppDispatch } from "store";
import {
  initialTemplateForm,
  selectTemplateChapters,
  selectTemplateGeneraInfo,
  selectTemplateStatus,
  setTemplateFormData,
} from "store/templates/templateFormSlice";
import { isLibraryObjectDraft, isLibraryObjectCreatePage } from "utils/library";
import { TemplateData } from "models";
import { LibraryPageType } from "types/library";

export interface TemplateFormProps {
  page?: LibraryPageType;
  updateActionsInformation: Function;
  initialValues?: TemplateData;
}

const TemplateForm: React.FC<TemplateFormProps> = ({
  page,
  initialValues,
  updateActionsInformation,
}) => {
  const generalInfo = useSelector(selectTemplateGeneraInfo);
  const chapters = useSelector(selectTemplateChapters);
  const isUpdateInProgress = useSelector(selectTemplateStatus);
  const dispatch = useAppDispatch();

  const { formatMessage } = useIntl();
  const [form] = Form.useForm();

  const { isLoading } = useTemplateFormInitialization({
    initialValues,
    form,
  });

  const {
    onKeepAsDraftTemplateFromDropdown,
    onPublishTemplate,
  } = useTemplateForm({
    form,
    page,
  });

  const { areActionButtonsDisabled, onFieldsChange } = useLibraryFormActions({
    isUpdateInProgress: isUpdateInProgress || isLoading,
    requiredFields: ["name", "productTypeId"],
    libraryItem: generalInfo,
  });

  const actionButtonItems = [
    ...(isLibraryObjectDraft(generalInfo?.state)
      ? [
          {
            name: formatMessage(libraryMessages.publishButton),
            label: formatMessage(libraryMessages.publishButton),
            onClick: onPublishTemplate,
          },
        ]
      : []),
    ...(isLibraryObjectCreatePage(page)
      ? [
          {
            name: formatMessage(libraryMessages.keepAsDraftButton),
            label: formatMessage(libraryMessages.keepAsDraftButton),
            onClick: onKeepAsDraftTemplateFromDropdown,
          },
        ]
      : []),
  ];

  useEffect(() => {
    updateActionsInformation({
      props: {
        disabled: areActionButtonsDisabled,
        items: actionButtonItems,
      },
      onClick: isLibraryObjectDraft(generalInfo?.state) ? () => {} : null,
    });
  }, [
    areActionButtonsDisabled,
    onPublishTemplate,
    onKeepAsDraftTemplateFromDropdown,
    generalInfo?.state,
  ]);

  useEffect(() => {
    return () => {
      dispatch(setTemplateFormData(initialTemplateForm));
    };
  }, []);

  if (isLoading) return <Spin />;

  return (
    <>
      <Form
        name="templateForm"
        layout="horizontal"
        validateMessages={{
          required: formatMessage(libraryMessages.formRequired),
          whitespace: formatMessage(libraryMessages.formRequired),
          string: {
            max: formatMessage(libraryMessages.formMaxCharLength, {
              max: MAX_CHAR_LENGTH,
            }),
          },
        }}
        onFieldsChange={onFieldsChange}
        initialValues={generalInfo}
        form={form}
        labelAlign="left"
        labelCol={{
          span: 4,
        }}
        wrapperCol={{
          xs: 24,
          md: 9,
        }}
      >
        <Row gutter={[20, 0]}>
          <Col xs={24}>
            <TemplateFormGeneralInfo page={page} form={form} />
          </Col>
          <Col xs={24}>
            <TemplateFormConfigure
              chapters={chapters}
              state={generalInfo?.state as LIBRARY_OBJECT_STATES}
            />
          </Col>
        </Row>
      </Form>
    </>
  );
};

export default TemplateForm;
