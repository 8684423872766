import { useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import { useRedirect } from "hooks";
import { selectGeneralInfo } from "store/packagingSystemForm/selectors";
import {
  usePackagingSystemNavigation,
  usePackagingSystemFormInitialisation,
} from "./hooks";
import {
  LIBRARY_OBJECT_TYPES,
  PACKAGING_SYSTEM_TAB_KEYS,
} from "utils/constants";
import { onTabChange } from "pages/Library/utils";
import { preparePackagingSystemFromApiData } from "dtos/packagingSystem";
import { PackagingSystemProps } from "./types";
import { PackagingSystemProvider } from "./context/packagingSystemContext";
import { usePackagingSystemComponents } from "./components/PackagingSystemComponents/hooks";

const PackagingSystem: React.FC<PackagingSystemProps> = ({
  initialPackagingSystemData,
  updateTabsInformation,
  updateActionsInformation,
}) => {
  const { redirectToLibraryEdition } = useRedirect();

  usePackagingSystemFormInitialisation({
    initialFormData: initialPackagingSystemData,
  });

  const packagingSystemGeneralInfo = useSelector(selectGeneralInfo);

  const { generalInformation, components, componentLinks } = useMemo(
    () => preparePackagingSystemFromApiData(packagingSystemGeneralInfo),
    [packagingSystemGeneralInfo]
  );

  const {
    activeTab,
    setActiveTab,
    tabItems,
    tabContent,
  } = usePackagingSystemNavigation({
    generalInfo: generalInformation,
    components,
  });

  const {
    packagingSystemComponentsData,
    packagingSystemComponentsTableView,
    isLoading,
    refListDictionary,
    packagingComponentIds,
  } = usePackagingSystemComponents();

  useEffect(() => {
    updateTabsInformation({
      defaultActiveKey: PACKAGING_SYSTEM_TAB_KEYS.GENERAL_INFORMATION,
      onTabClick: setActiveTab,
      items: tabItems,
    });
  }, []);

  useEffect(() => {
    updateTabsInformation({
      activeKey: activeTab,
    });
  }, [activeTab]);

  useEffect(() => {
    updateTabsInformation({
      onChange: (tabKey: string) =>
        onTabChange({
          tabKey,
          libraryItemId: generalInformation?.packagingSystemId,
          libraryItemType: LIBRARY_OBJECT_TYPES.PACKAGING_SYSTEMS,
          redirectToPage: redirectToLibraryEdition,
        }),
    });
  }, [generalInformation?.packagingSystemId]);

  useEffect(() => {
    updateTabsInformation({
      items: tabItems,
    });
  }, [tabContent]);

  useEffect(() => {
    updateActionsInformation({
      onClick: null,
    });
  }, []);

  return (
    <PackagingSystemProvider
      value={{
        packagingSystemId: generalInformation?.packagingSystemId,
        generalInformation,
        components,
        componentLinks,
        packagingSystemComponentsData,
        packagingSystemComponentsTableView,
        isLoading,
        refListDictionary,
        packagingComponentIds,
      }}
    >
      {tabContent}
    </PackagingSystemProvider>
  );
};

export default PackagingSystem;
