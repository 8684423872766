import { Regulations } from "models";
import { isObjectEmpty } from "utils/general";
import {
  isRecyclabilityRegulationCodeAGEC,
  isRecyclabilityRegulationCodeREACH,
} from "utils/packaging";

export const getShouldDisplayDivider = (
  code: string,
  regulationsInfo: Regulations
): boolean => {
  if (isObjectEmpty(regulationsInfo)) {
    return false;
  }

  if (isRecyclabilityRegulationCodeREACH(code)) {
    return !isObjectEmpty(regulationsInfo.eu);
  }

  if (isRecyclabilityRegulationCodeAGEC(code)) {
    return (
      !isObjectEmpty(regulationsInfo.eu) ||
      !isObjectEmpty(regulationsInfo.reach)
    );
  }

  return false;
};
