import { useEffect, useState } from "react";
import { getCompanyGroupById } from "src/apis/cumd";
import { CompanyGroup } from "src/models/companyDetails";

const useCompanySwitcher = ({
  companyGroupId,
}: {
  companyGroupId: string;
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [companyGroupDetails, setCompanyGroupDetails] = useState<CompanyGroup>(null);

  const fetchDetails = async () => {
    try {
      setLoading(true);
      const {
        data: groupDetails,
      } = await getCompanyGroupById(companyGroupId);

      setCompanyGroupDetails(groupDetails);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!companyGroupId) {
      return;
    }

    fetchDetails();
  }, [companyGroupId]);

  return {
    loading,
    companyGroupDetails,
  };
};

export default useCompanySwitcher;
