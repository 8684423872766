import { ReactElement } from "react";
import {
  COMPANY_ACTIVITY_ID,
  SPECIFICATION_STATE,
  SpecificationContactType,
} from "utils/constants";
import { isStateDraft } from "utils/specifications";
import {
  getDifferenceBetweenDates,
  getDuration,
  getUtcDate,
  isSameDate,
  parseDate,
} from "utils/date";

export const displayValueForSpecificationDraftState = ({
  specificationState,
  valueToDisplay,
}: {
  specificationState: SPECIFICATION_STATE;
  valueToDisplay: string | ReactElement;
}) => {
  if (isStateDraft(specificationState)) {
    return null;
  }
  return valueToDisplay;
};

export const getProductOrigin = ({
  companyActivity,
}: {
  companyActivity: string;
}) => {
  switch (companyActivity) {
    case COMPANY_ACTIVITY_ID.RETAILER.toString():
      return SpecificationContactType.SUPPLIER;
    case COMPANY_ACTIVITY_ID.SUPPLIER.toString():
      return SpecificationContactType.RETAILER;
    case COMPANY_ACTIVITY_ID.TRANSLATOR.toString():
      return SpecificationContactType.TRANSLATOR;
    default:
      return SpecificationContactType.SUPPLIER;
  }
};

export const getDataIndexForProductOrigin = ({
  productOrigin,
}: {
  productOrigin: string;
}) => (productOrigin === "supplier" ? "supplierName" : "ownerCompanyName");

/* istanbul ignore next*/
export const getValueToDisplayforLifeTime = ({
  createdDateUtc,
  todayMessage,
  oneDayMessage,
}) => {
  const today = getUtcDate();
  const parsedCreatedDateUtc = parseDate(createdDateUtc);

  if (isSameDate(parsedCreatedDateUtc, today, "d")) {
    return todayMessage;
  }

  const duration = getDuration(
    getDifferenceBetweenDates(today, parsedCreatedDateUtc)
  );

  // If less than a day.
  if (duration.days() === 0) {
    return oneDayMessage;
  }

  //@ts-ignore
  return duration.format("M [__], d [__]", {
    trunc: true,
  });
};
