import { useFormSchemaTranslation } from "pages/Specification/components/Details/hooks";
import {
  getNetContentOptions,
  getProductInformationPayload,
  updateListWithEMark,
} from "../../utils";
import { ProductInformationFormData } from "components/SpecificationSection/components/FormSchema/types";
import { FormContext } from "components/SpecificationSection/types";

export type UseRelativeNetContentProps = {
  formData?: ProductInformationFormData;
  formContext?: FormContext;
  displayEMarkOnPackPropertyId?: string;
};

const useRelativeNetContent = ({
  formData,
  formContext,
  displayEMarkOnPackPropertyId,
}: UseRelativeNetContentProps) => {
  const { getTranslationForKey } = useFormSchemaTranslation();

  const {
    extraErrors,
    hasPermissionToSave,
    refListDictionary,
    saveTemplateBlock,
    displayActions,
    readOnlyFieldIds = [],
  } = formContext;
  const {
    netContents = [],
    additionalNetContents = [],
    displayEMarkOnPack,
  } = formData;

  const netContentOptions = getNetContentOptions({
    list: netContents,
    listKey: "netContent",
    formDataKey: "netContents",
  });
  const additionalNetContentOptions = getNetContentOptions({
    list: additionalNetContents,
    listKey: "additionalNetContent",
    formDataKey: "additionalNetContents",
  });

  const uniqueOptions = [
    ...netContentOptions,
    ...additionalNetContentOptions,
  ].filter(
    (item, index, self) =>
      index ===
      self.findIndex(
        other => other?.unit === item?.unit && other?.value === item?.value
      )
  );

  const options = uniqueOptions.map(
    ({ unit, value, originalItem, id, type }) => ({
      label: unit
        ? `${value || 0} ${refListDictionary[unit]}`
        : `${value || 0}`,
      value: id,
      key: id,
      originalItem,
      type,
    })
  );

  const onClear = async () => {
    try {
      const updatedPayload = {
        ...formData,
        additionalNetContents: updateListWithEMark({
          list: additionalNetContents,
          originalItem: null,
        }),
        netContents: updateListWithEMark({
          list: netContents,
          originalItem: null,
        }),
      };

      await saveTemplateBlock(JSON.stringify(updatedPayload));
    } catch {}
  };

  const onChange = async (value: string) => {
    const selectedNetContent = options.find(
      ({ value: optionValue }) => value === optionValue
    );

    if (!selectedNetContent) return;

    try {
      const { type, originalItem } = selectedNetContent;

      const updatedPayload = getProductInformationPayload({
        formData,
        type,
        originalItem,
      });

      await saveTemplateBlock(JSON.stringify(updatedPayload));
    } catch {}
  };

  const value =
    options?.find(({ originalItem }) => originalItem?.eMark)?.value ||
    undefined;

  const disabled =
    !displayEMarkOnPack ||
    !hasPermissionToSave ||
    !displayActions ||
    readOnlyFieldIds.includes(displayEMarkOnPackPropertyId);

  const hasEMarkError =
    extraErrors?.__errors?.findIndex(
      error => error === getTranslationForKey("MissingEMark")
    ) > -1;

  return {
    options,
    value,
    hasEMarkError,
    disabled,
    onChange,
    onClear,
  };
};

export default useRelativeNetContent;
