import classnames from "classnames";
import { Col, Row } from "antd";
import { Button } from "@trace-one/design-system";
import ConfirmationTooltip from "components/ConfirmationTooltip";
import { useMaterialPermissions } from "hooks";
import { isMaterialCompositeType } from "utils/library";
import OriginsList from "../OriginsList";
import SupplierName from "./components/SupplierName";
import SupplierCode from "./components/SupplierCode";
import SupplierIngredients from "./components/SupplierIngredients";
import { SuppliersListProps } from "./types";
import styles from "./styles.module.less";

const SuppliersList: React.FC<SuppliersListProps> = ({
  displayActions,
  suppliers,
  onChangeSupplierName,
  onChangeSupplierCode,
  onRemoveSupplier,
  refListDictionary,
  materialType,
  materialId,
  materialIngredients,
}) => {
  const { disableActions } = useMaterialPermissions();

  const colProps = {
    xs: 21,
    md: 6,
    className: classnames({
      [styles.supplierDetailsLabelWrapper]: !displayActions,
    }),
  };

  return (
    <>
      {suppliers.map((supplier, supplierIdx) => {
        const assignedIngredientIds =
          supplier?.ingredients?.reduce((previousState, { ingredientId }) => {
            if (ingredientId !== materialId) {
              return [...previousState, ingredientId];
            }

            return [...previousState];
          }, []) || [];

        const disabled = disableActions || !supplier.name;

        return (
          <div
            className={classnames(styles.supplierDetailsWrapper, {
              [styles.disabled]: disableActions,
            })}
            key={supplier.key || supplier.supplierId}
          >
            <Col xs={22} md={23}>
              <Row gutter={[16, 16]}>
                <Col {...colProps}>
                  <SupplierName
                    disableActions={disableActions}
                    displayActions={displayActions}
                    supplierName={supplier.name}
                    onChange={onChangeSupplierName(supplier.supplierId)}
                  />
                </Col>
                <Col {...colProps}>
                  <SupplierCode
                    disableActions={disabled}
                    displayActions={displayActions}
                    supplierCode={supplier.code}
                    onChange={onChangeSupplierCode(supplier.supplierId)}
                  />
                </Col>
                {isMaterialCompositeType(materialType) && (
                  <Col {...colProps} md={8}>
                    <SupplierIngredients
                      displayActions={displayActions}
                      disableActions={disabled}
                      ingredients={supplier.ingredients}
                      supplierId={supplier.supplierId}
                      assignedIngredientIds={assignedIngredientIds}
                      materialIngredients={materialIngredients}
                    />
                  </Col>
                )}
              </Row>
              <OriginsList
                displayActions={displayActions}
                supplierId={supplier.supplierId}
                origins={supplier.origins}
                refListDictionary={refListDictionary}
                isAddOriginDisabled={disabled}
              />
            </Col>
            {displayActions && (
              <Col
                xs={2}
                md={1}
                className={classnames(styles.supplierDetailsRemoveCardWrapper, {
                  [styles.disabled]: disableActions,
                })}
              >
                <ConfirmationTooltip
                  onConfirmation={onRemoveSupplier(supplier.supplierId)}
                >
                  <Button
                    type="tertiary"
                    iconName="delete"
                    data-test-id="remove-supplier-btn"
                    disabled={disableActions}
                  />
                </ConfirmationTooltip>
              </Col>
            )}
          </div>
        );
      })}
    </>
  );
};

SuppliersList.defaultProps = {
  displayActions: true,
  refListDictionary: {},
};

export default SuppliersList;
