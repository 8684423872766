import { useEffect, useState } from "react";
import { useCatalogDictionary, useReferenceListsItems } from "hooks";
import { isListEmpty } from "utils/general";
import { getRefListIdsFromSuppliers } from "utils/material";
import {
  MaterialAllergenViewModel,
  MaterialRecipeIngredientViewModel,
  MaterialSupplierViewModel,
  RawMaterialViewModel,
} from "viewModels";
import {
  getCatalogIdsFromIngredients,
  getIdsFromIngredients,
} from "dtos/material";

const useMaterialDetails = ({
  allergens,
  suppliers,
  rawMaterial,
  ingredients,
}: {
  allergens: MaterialAllergenViewModel[];
  suppliers: MaterialSupplierViewModel[];
  rawMaterial?: RawMaterialViewModel;
  ingredients?: MaterialRecipeIngredientViewModel[];
}) => {
  const [catalogIds, setCatalogIds] = useState<string[]>([]);
  const [catalogAllergenIds, setCatalogAllergenIds] = useState<string[]>([]);
  const [catalogClassificationIds, setCatalogClassificationIds] = useState<
    string[]
  >([]);
  const [partIds, setPartIds] = useState<string[]>([]);
  const [refListIds, setRefListIds] = useState<string[]>([]);

  const { refListDictionary } = useReferenceListsItems({ refListIds });

  const { catalogDictionary } = useCatalogDictionary({
    catalogIds,
    catalogAllergenIds,
    catalogClassificationIds,
    rawMaterialPartIds: partIds,
  });

  const getReflistIds = () => {
    let refListIds: string[] = [];

    refListIds.push.apply(refListIds, getRefListIdsFromSuppliers(suppliers));

    setRefListIds(refListIds);
  };

  const getCatalogIds = () => {
    let catalogIds: string[] = [];

    catalogIds = getCatalogIdsFromIngredients({ ingredients });

    setCatalogIds(catalogIds);
  };

  const getCatalogAllergenIds = () => {
    let catalogAllergenIds: string[] = [];

    catalogAllergenIds = allergens?.map(allergen => allergen.allergenId);

    setCatalogAllergenIds(catalogAllergenIds);
  };

  const getCatalogClassificationIds = () => {
    let catalogClassificationIds: string[] = [];

    catalogClassificationIds = [rawMaterial?.classificationId].filter(Boolean);

    if (!isListEmpty(ingredients)) {
      catalogClassificationIds = [
        ...catalogClassificationIds,
        ...getIdsFromIngredients({
          ingredients,
          fieldName: "classificationId",
        }),
      ];
    }

    setCatalogClassificationIds(catalogClassificationIds);
  };

  const getPartIds = () => {
    let partIds: string[] = [];

    partIds = [rawMaterial?.partId].filter(Boolean);

    if (!isListEmpty(ingredients)) {
      partIds = [
        ...partIds,
        ...getIdsFromIngredients({ ingredients, fieldName: "partId" }),
      ];
    }

    setPartIds(partIds);
  };

  useEffect(() => {
    getReflistIds();
    getCatalogIds();
    getCatalogAllergenIds();
    getCatalogClassificationIds();
    getPartIds();
  }, [suppliers, allergens, ingredients, rawMaterial]);

  return {
    refListDictionary,
    catalogDictionary,
  };
};

export default useMaterialDetails;
