import Library from "components/Library";
import { LibraryItemDetails } from "pages/Library/hocs/withLibraryItemDetails/types";
import { LibraryPageType } from "types/library";

export interface ClaimProps {
  libraryItemDetails: LibraryItemDetails;
  page: LibraryPageType;
  updateActionsInformation?: Function;
  updateSecondaryActionInformation?: Function;
}

const Claim: React.FC<ClaimProps> = ({
  libraryItemDetails,
  page,
  updateActionsInformation,
  updateSecondaryActionInformation,
}) => {
  return (
    <Library.ClaimForm
      page={page}
      updateActionsInformation={updateActionsInformation}
      updateSecondaryActionInformation={updateSecondaryActionInformation}
      initialValues={libraryItemDetails}
    />
  );
};

export default Claim;
