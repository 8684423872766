import { useIntl } from "react-intl";
import { useBoolean } from "hooks";
import { useAppDispatch } from "store";
import { updateComponentsAssociationForPackagingSystemAsync } from "store/packagingSystemForm/asyncActions";
import { UsePackagingSystemComponentsActionsProps } from "./types";
import { getPackagingComponentIdsForAssociationUpdate } from "./utils";
import { generalMessages, packagingMessages } from "messages";

const usePackagingSystemComponentsActions = ({
  packagingSystemId,
  packagingComponentIds,
  packagingSystemComponent,
  selectedPackagingComponentsIds = [],
  packagingSystemAllowUpdate,
  mainComponentId,
  onOpenLinksDetailsModal,
}: UsePackagingSystemComponentsActionsProps) => {
  const { formatMessage } = useIntl();

  const dispatch = useAppDispatch();
  const { value: saving, setTrue, setFalse } = useBoolean(false);

  const updateComponentsAssociation = async (componentId: string = null) => {
    try {
      setTrue();

      await dispatch(
        updateComponentsAssociationForPackagingSystemAsync({
          packagingSystemId,
          packagingComponentIds: getPackagingComponentIdsForAssociationUpdate({
            packagingComponentIds,
            componentId,
            selectedPackagingComponentsIds,
          }),
          mainComponentId:
            componentId === mainComponentId ? null : mainComponentId,
        })
      );

      setFalse();
    } catch {
      setFalse();
    }
  };

  const setOrUnSetMainComponent = async (mainComponentId: string = null) => {
    try {
      await dispatch(
        updateComponentsAssociationForPackagingSystemAsync({
          packagingSystemId,
          packagingComponentIds: getPackagingComponentIdsForAssociationUpdate({
            packagingComponentIds,
            selectedPackagingComponentsIds,
          }),
          mainComponentId,
        })
      );
    } catch {}
  };

  const actions = [
    {
      key: formatMessage(packagingMessages.setAsMainComponent),
      label:
        packagingSystemComponent?.componentId !== mainComponentId
          ? formatMessage(packagingMessages.setAsMainComponent)
          : formatMessage(packagingMessages.unsetAsMainComponent),
      onClick: () => {
        packagingSystemComponent?.componentId !== mainComponentId
          ? setOrUnSetMainComponent(packagingSystemComponent.componentId)
          : setOrUnSetMainComponent();
      },
      "data-testid": "set-unset-main-component-action",
      disabled:
        !packagingSystemAllowUpdate ||
        (!!mainComponentId
          ? packagingSystemComponent?.componentId !== mainComponentId
          : !!mainComponentId),
    },
    {
      key: formatMessage(packagingMessages.linksDetails),
      label: formatMessage(packagingMessages.linksDetails),
      onClick: () => {
        onOpenLinksDetailsModal(packagingSystemComponent?.componentId);
      },
      "data-testid": "component-links-details",
      disabled: !packagingSystemAllowUpdate,
    },
    {
      key: formatMessage(generalMessages.remove),
      label: formatMessage(generalMessages.remove),
      tooltipProps: {
        onConfirmation: () =>
          updateComponentsAssociation(packagingSystemComponent.componentId),
      },
      "data-testid": "remove-component-from-packaging-system",
      disabled: !packagingSystemAllowUpdate,
    },
  ];

  return {
    actions,
    updateComponentsAssociation,
    saving,
  };
};

export default usePackagingSystemComponentsActions;
