import { useState } from "react";
import { NutritionFormData } from "models";

const useRemoveServing = ({
  onDeleteNutritionServing,
  formData,
}: {
  onDeleteNutritionServing: Function;
  formData: NutritionFormData;
}) => {
  const [showRemoveServingModal, setShowRemoveServingModal] = useState<boolean>(
    false
  );
  const [servingId, setServingId] = useState<string>(undefined);

  const { servingDefinitions = [] } = formData;

  const onRemoveServing = (servingId: string) => {
    setShowRemoveServingModal(true);
    setServingId(servingId);
  };

  const onConfirmRemoveServing = async () => {
    try {
      const updatedServingDefinitions = servingDefinitions.filter(
        ({ servingId: id }) => id !== servingId
      );

      await onDeleteNutritionServing({
        data: {
          ...formData,
          servingDefinitions: updatedServingDefinitions,
        },
      });

      setShowRemoveServingModal(false);
    } catch {}
  };

  return {
    showRemoveServingModal,
    onRemoveServing,
    onConfirmRemoveServing,
    onCloseRemoveServing: setShowRemoveServingModal,
  };
};

export default useRemoveServing;
