import { useEffect, useState } from "react";
import { Form, RadioChangeEvent } from "antd";
import { PackagingSubstanceFormProps } from "../components/PackagingSubstanceForm";
import { findPropertyByKey, isObjectEmpty } from "utils/general";
import { getInputNumberProps } from "../../utils";
import { PackagingSubstance, SubstanceData } from "models";

export interface UsePackagingSystemFormParams
  extends Pick<
    PackagingSubstanceFormProps,
    | "schema"
    | "substanceId"
    | "initialValue"
    | "getSubstanceFormData"
    | "setSubstances"
  > {
  selectedSubstance?: SubstanceData;
  onClear: () => void;
}

const initialSubstanceForm: PackagingSubstance = {
  selectedSubstance: null,
  percentage: null,
  presentAtNanoscale: false,
  presenceAtNanoscale: null,
};

export const usePackagingSystemForm = ({
  schema,
  initialValue = initialSubstanceForm,
  getSubstanceFormData,
  selectedSubstance,
  setSubstances,
  onClear,
}: UsePackagingSystemFormParams) => {
  const percentageInputProps = getInputNumberProps(
    findPropertyByKey(schema, "percentage")
  );
  const sizeInputProps = getInputNumberProps(findPropertyByKey(schema, "size"));
  const specificSurfaceInputProps = getInputNumberProps(
    findPropertyByKey(schema, "specificSurface")
  );

  const { casNumbers: casNumbersList } = selectedSubstance || {};
  const casNumbers = casNumbersList?.join("; ");

  const [substanceForm, setSubstanceForm] = useState(initialValue);
  const [form] = Form.useForm();

  useEffect(() => {
    getSubstanceFormData?.(substanceForm);
  }, [substanceForm]);

  useEffect(() => {
    setSubstanceForm({
      ...substanceForm,
      selectedSubstance,
    });
  }, [selectedSubstance]);

  const { percentage } = substanceForm;

  const hasMandatoryFieldsFilled =
    !isObjectEmpty(selectedSubstance) && percentage !== null;

  const resetSubstanceForm = () => {
    form.setFields([
      {
        name: "percentage",
        value: null,
      },
      {
        name: "size",
        value: null,
      },
      {
        name: "specificSurface",
        value: null,
      },
    ]);
    onClear();
    setSubstanceForm(initialSubstanceForm);
  };

  const onPercentageChange = (value: number) => {
    setSubstanceForm(substanceForm => ({
      ...substanceForm,
      percentage: value,
    }));
  };

  const onPresentAtNanoScaleChange = (event: RadioChangeEvent) => {
    setSubstanceForm(substanceForm => {
      let { presenceAtNanoscale } = substanceForm;

      if (!event.target.value) {
        presenceAtNanoscale = null;

        form.setFields([
          {
            name: "size",
            value: null,
          },
          {
            name: "specificSurface",
            value: null,
          },
        ]);
      }

      return {
        ...substanceForm,
        presenceAtNanoscale,
        presentAtNanoscale: event.target.value,
      };
    });
  };

  const onPresenceAtNanoscaleChange = (field: "size" | "specificSurface") => (
    value: number
  ) => {
    setSubstanceForm(substanceForm => ({
      ...substanceForm,
      presenceAtNanoscale: {
        ...substanceForm.presenceAtNanoscale,
        [field]: value,
      },
    }));
  };

  const onAddToSelect = () => {
    setSubstances(substances => [...substances, substanceForm]);
    resetSubstanceForm();
  };

  return {
    form,
    casNumbers,
    substanceForm,
    percentageInputProps,
    sizeInputProps,
    specificSurfaceInputProps,
    hasMandatoryFieldsFilled,
    onPercentageChange,
    onPresentAtNanoScaleChange,
    onPresenceAtNanoscaleChange,
    onAddToSelect,
  };
};
