import { useIntl } from "react-intl";
import { Row } from "antd";
import { Typography } from "@trace-one/design-system";
import PackagingComponentActionButton from "./components/PackagingComponentActionButton";
import { messages, packagingComponentTableMessages } from "messages/packaging";
import { getVersionName } from "utils/specifications";
import { Dictionary } from "types/general";
import generalMessages from "messages/general";

import { PackagingSystemComponentData, PackagingSystemData } from "models";

const usePackagingSystemComponentsColumns = ({
  refListDictionary,
  packagingSystemId,
  packagingComponentIds = [],
  packagingSystemAllowUpdate,
  mainComponentId,
  onOpenLinksDetailsModal,
}: {
  refListDictionary: Dictionary<string>;
  packagingSystemId: PackagingSystemData["packagingSystemId"];
  packagingComponentIds?: PackagingSystemComponentData["componentId"][];
  packagingSystemAllowUpdate: boolean;
  mainComponentId?: string;
  onOpenLinksDetailsModal?: (_: string) => void;
}) => {
  const { formatMessage } = useIntl();

  const columns = [
    {
      title: formatMessage(packagingComponentTableMessages.componentName),
      dataIndex: "name",
      key: "name",
      width: "30%",
      render: (name, { componentId = null }) => {
        return (
          <>
            <Typography variant="body-regular" color="grey-5">
              {name}
            </Typography>
            {!!mainComponentId && componentId === mainComponentId && (
              <Typography variant="body-s" color="grey-4">
                {formatMessage(messages.main)}
              </Typography>
            )}
          </>
        );
      },
    },
    {
      title: formatMessage(packagingComponentTableMessages.componentType),
      dataIndex: "componentTypeId",
      key: "componentTypeId",
      render: componentTypeId => {
        return refListDictionary[componentTypeId];
      },
      width: "25%",
    },
    {
      title: formatMessage(generalMessages.version),
      dataIndex: "version",
      key: "version",
      render: (version: number) => {
        return getVersionName(version);
      },
    },
    {
      title: formatMessage(messages.packagingComponentUsageLevelLabel),
      dataIndex: "usageLevel",
      key: "usageLevel",
      render: usageLevel => {
        return formatMessage(messages[usageLevel]);
      },
    },
    {
      title: formatMessage(
        packagingComponentTableMessages.totalPercentageOfRecyclability
      ),
      dataIndex: "percentageOfRecycledMaterials",
      key: "percentageOfRecycledMaterials",
    },
    {
      title: formatMessage(generalMessages.actions),
      dataIndex: "actions",
      key: "actions",
      width: "90px",
      render: (_, packagingSystemComponent) => {
        return (
          <Row justify="center">
            <PackagingComponentActionButton
              packagingSystemId={packagingSystemId}
              packagingSystemComponent={packagingSystemComponent}
              packagingComponentIds={packagingComponentIds}
              packagingSystemAllowUpdate={packagingSystemAllowUpdate}
              mainComponentId={mainComponentId}
              onOpenLinksDetailsModal={onOpenLinksDetailsModal}
            />
          </Row>
        );
      },
    },
  ];

  return {
    columns,
  };
};

export default usePackagingSystemComponentsColumns;
