import { Button } from "@trace-one/design-system";
import { useRedirect } from "hooks";
import { MaterialProps } from "./types";
import { LIBRARY_OBJECT_URL_TYPES } from "utils/constants";

const Material: React.FC<MaterialProps> = ({ material, displayActions }) => {
  const { redirectToLibraryDetails } = useRedirect();

  const onButtonClick = (id: string, type: string) => () => {
    redirectToLibraryDetails({
      id,
      type,
      pushState: {
        scrollToMaterialAllergens: true,
      },
    });
  };

  if (
    !!material?.materialName &&
    material?.ingredientName !== material?.materialName
  ) {
    return (
      <>
        {material.ingredientName} (
        {displayActions ? (
          <Button
            type="link"
            onClick={onButtonClick(
              material.materialId,
              LIBRARY_OBJECT_URL_TYPES[material.recipeType || material?.type]
            )}
          >
            {material?.materialName}
          </Button>
        ) : (
          material?.materialName
        )}
        )
      </>
    );
  }

  return (
    <>
      {displayActions ? (
        <Button
          type="link"
          onClick={onButtonClick(
            material.materialId,
            LIBRARY_OBJECT_URL_TYPES[material.recipeType || material?.type]
          )}
        >
          {material?.ingredientName}
        </Button>
      ) : (
        material?.ingredientName
      )}
    </>
  );
};

export default Material;
