import { useEffect, useState } from "react";
import { Col, Row } from "antd";
import { useIntl } from "react-intl";
import { Label } from "@trace-one/react-components";
import FormCard from "../../../FormCard";
import ClassificationDropdown from "../../../ClassificationDropdown";
import PartDropdown from "../../../PartDropdown";
import { useMaterialPermissions } from "hooks";
import { useRawMaterialActions } from "./hooks";
import messages from "./messages";
import { RawMaterialProps } from "./types";
import styles from "components/Library/components/MaterialForm/styles.module.less";

const RawMaterial: React.FC<RawMaterialProps> = ({
  displayActions = true,
  rawMaterial,
  catalogDictionary,
}) => {
  const { formatMessage } = useIntl();

  const [classificationId, setClassificationId] = useState<string>(
    rawMaterial?.classificationId
  );
  const [partId, setPartId] = useState<string>(rawMaterial?.partId);

  useEffect(() => setPartId(rawMaterial?.partId), [rawMaterial?.partId]);

  const { onChangeClassification, onChangePart } = useRawMaterialActions({
    setClassificationId,
    setPartId,
  });

  const { disableActions } = useMaterialPermissions();

  return (
    <FormCard
      className={styles.rawMaterialFormDetailsWrapper}
      title={formatMessage(messages.rawMaterialDetailsCardTitle)}
      name="raw-material"
    >
      <Row gutter={[16, 16]}>
        <Col
          xs={24}
          md={12}
          className={styles.rawMaterialFormDetailsColWrapper}
        >
          <Label
            required
            title={formatMessage(messages.rawMaterialClassification)}
          >
            {displayActions ? (
              <ClassificationDropdown
                name="raw-material-classification"
                value={{
                  value: classificationId,
                  label: catalogDictionary?.[classificationId],
                }}
                data-testid="raw-material-form-details-classification"
                onChange={onChangeClassification}
                disabled={disableActions}
              />
            ) : (
              catalogDictionary?.[classificationId]
            )}
          </Label>
        </Col>
        <Col xs={24} md={6} className={styles.rawMaterialFormDetailsColWrapper}>
          <Label title={formatMessage(messages.rawMaterialDetailsPart)}>
            {displayActions ? (
              <PartDropdown
                name="raw-material-part"
                value={{
                  value: partId,
                  label: catalogDictionary?.[partId],
                }}
                data-testid="raw-material-part"
                onChange={onChangePart}
                classificationId={classificationId}
                disabled={!classificationId}
              />
            ) : (
              catalogDictionary?.[partId]
            )}
          </Label>
        </Col>
      </Row>
    </FormCard>
  );
};

export default RawMaterial;
