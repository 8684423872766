import { SorterResult } from "antd/lib/table/interface";
import { libraryMessages } from "messages";
import { LibraryObjectData } from "models";
import { LIBRARY_OBJECT_TYPES } from "utils/constants";

export const LibraryTypeLabelMap = {
  [LIBRARY_OBJECT_TYPES.REQUIREMENTS]: libraryMessages.requirements,
  [LIBRARY_OBJECT_TYPES.CHAPTERS]: libraryMessages.chaptersCountLabel,
  [LIBRARY_OBJECT_TYPES.CLAIMS]: libraryMessages.claims,
  [LIBRARY_OBJECT_TYPES.REGULATED_HEALTH_CLAIMS]:
    libraryMessages.regulatedHealthClaims,
  [LIBRARY_OBJECT_TYPES.NUTRITIONAL_CLAIMS]: libraryMessages.nutritionalClaims,
  [LIBRARY_OBJECT_TYPES.PACKAGING_SYSTEMS]: libraryMessages.packagings,
  [LIBRARY_OBJECT_TYPES.MATERIALS]: libraryMessages.materials,
  [LIBRARY_OBJECT_TYPES.TEMPLATES]: libraryMessages.templates,
};

export const getOnTableChange = ({
  setOrderBy,
  setOrderByDescending,
}: {
  setOrderBy: Function;
  setOrderByDescending: Function;
}) => (_, __, sorter: SorterResult<LibraryObjectData>) => {
  setOrderBy(sorter.field);
  setOrderByDescending(sorter.order !== "ascend");
};
