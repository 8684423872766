import { useEffect, useState } from "react";
import { fetchClaimCollection } from "apis/SPEC";
import { isListEmpty } from "utils/general";
import { ClaimData } from "models";
import { LIBRARY_OBJECT_TYPES } from "utils/constants";
import { LibraryItemApiType } from "types/library";

const useClaimCollection = ({
  marketingClaimIds,
  healthClaimIds,
  nutritionalClaimIds,
}: {
  marketingClaimIds?: ClaimData["id"][];
  healthClaimIds?: ClaimData["id"][];
  nutritionalClaimIds?: ClaimData["id"][];
}) => {
  const [marketingClaimCollection, setMarketingClaimCollection] = useState<
    ClaimData[]
  >([]);
  const [healthClaimCollection, setHealthClaimCollection] = useState<
    ClaimData[]
  >([]);
  const [nutritionalClaimCollection, setNutritionalClaimCollection] = useState<
    ClaimData[]
  >([]);

  const getClaimCollection = async ({
    claimIds,
    claimType,
    setData,
  }: {
    claimIds: ClaimData["id"][];
    claimType: LibraryItemApiType;
    setData: React.Dispatch<React.SetStateAction<ClaimData[]>>;
  }) => {
    try {
      const payload = {
        claimIds,
      };

      const { data } = await fetchClaimCollection({
        claimType,
        payload,
      });

      setData(data);
    } catch {}
  };

  useEffect(() => {
    if (isListEmpty(marketingClaimIds)) {
      setMarketingClaimCollection([]);
      return;
    }

    // endpoint need not be called if a claim is removed
    if (
      marketingClaimIds.every(id =>
        marketingClaimCollection.map(({ id }) => id).includes(id)
      )
    ) {
      setMarketingClaimCollection(prevState =>
        prevState.filter(({ id }) => marketingClaimIds.includes(id))
      );

      return;
    }

    getClaimCollection({
      claimIds: marketingClaimIds,
      claimType: LIBRARY_OBJECT_TYPES.MARKETING_CLAIMS,
      setData: setMarketingClaimCollection,
    });
  }, [JSON.stringify(marketingClaimIds)]);

  useEffect(() => {
    if (isListEmpty(healthClaimIds)) {
      setHealthClaimCollection([]);
      return;
    }

    // endpoint need not be called if a claim is removed
    if (
      healthClaimIds.every(id =>
        healthClaimCollection.map(({ id }) => id).includes(id)
      )
    ) {
      setHealthClaimCollection(prevState =>
        prevState.filter(({ id }) => healthClaimIds.includes(id))
      );

      return;
    }

    getClaimCollection({
      claimIds: healthClaimIds,
      claimType: LIBRARY_OBJECT_TYPES.HEALTH_CLAIMS,
      setData: setHealthClaimCollection,
    });
  }, [JSON.stringify(healthClaimIds)]);

  useEffect(() => {
    if (isListEmpty(nutritionalClaimIds)) {
      setNutritionalClaimCollection([]);
      return;
    }

    // endpoint need not be called if a claim is removed
    if (
      nutritionalClaimIds.every(id =>
        nutritionalClaimCollection.map(({ id }) => id).includes(id)
      )
    ) {
      setNutritionalClaimCollection(prevState =>
        prevState.filter(({ id }) => nutritionalClaimIds.includes(id))
      );

      return;
    }

    getClaimCollection({
      claimIds: nutritionalClaimIds,
      claimType: LIBRARY_OBJECT_TYPES.NUTRITIONAL_CLAIMS,
      setData: setNutritionalClaimCollection,
    });
  }, [JSON.stringify(nutritionalClaimIds)]);

  return {
    claimCollection: [
      ...marketingClaimCollection,
      ...healthClaimCollection,
      ...nutritionalClaimCollection,
    ],
    marketingClaimCollection,
    healthClaimCollection,
    nutritionalClaimCollection,
  };
};

export default useClaimCollection;
