import { Paragraph } from "@trace-one/design-system";
import { isObjectEmpty } from "utils/general";
import { EMPTY_VALUE } from "utils/constants";
import { CompactWidgetProps } from "./types";

const ReadOnlyCompactWidget: React.FC<CompactWidgetProps> = ({
  formData,
  formContext,
}) => {
  const { refListDictionary } = formContext;
  const { value, unit, unitId } = formData || {};

  if (isObjectEmpty(formData)) {
    return (
      <Paragraph size="m" color="grey-4">
        {EMPTY_VALUE}
      </Paragraph>
    );
  }

  return (
    <Paragraph size="m" color="grey-4">
      {value} {refListDictionary[unit ?? unitId]}
    </Paragraph>
  );
};

export default ReadOnlyCompactWidget;
