import { generatePath } from "react-router-dom";
import { sortBy } from "lodash";
import { ProductSpecification } from "store/folderCreation/types";
import { Dictionary, SortByPropertyParams, UiSchemaInfo } from "types/general";
import { getVersionName } from "./specifications";
import {
  ArraySchema,
  ObjectSchema,
} from "components/SpecificationSection/types";
import {
  ERROR_CODES,
  ROUTES,
  SECTION_BLOCK_CODE,
  SECTION_CODE,
} from "./constants";
import {
  ChapterStructureFormData,
  ChapterStructureFormDataBlock,
  LibraryObjectType,
} from "types/library";

export const getPercentValue = (percent: number): number =>
  Math.round(percent * 100);

export const getDecimalPercentValue = (percent: number): number =>
  parseFloat((percent * 100).toFixed(1));

export const preparePercentageDecimalValue = (
  percentage: number | string | null,
  toFixed = 3
) => {
  if (
    percentage === null ||
    (percentage as number) <= 0 ||
    (percentage as string) === ""
  ) {
    return null;
  }

  if ((percentage as number) > 100) return 1;

  return parseFloat(((percentage as number) / 100).toFixed(toFixed));
};

export const hasProductError = ({
  folderId,
  productSpecifications,
}: {
  folderId: string;
  productSpecifications: ProductSpecification[];
}): boolean =>
  isListEmpty(productSpecifications) ||
  productSpecifications.findIndex(currentSpecification => {
    return folderId === currentSpecification.folderId;
  }) === -1;

export const isListEmpty = (list: any[] | Set<any>) =>
  !list ||
  (!Array.isArray(list) && !(list instanceof Set)) ||
  // @ts-ignore
  (!list.length && !list.size) ||
  (Array.isArray(list) &&
    !list.filter(item => item !== undefined && item !== null).length);

export const isObjectEmpty = (object: object) =>
  !object ||
  typeof object !== "object" ||
  isListEmpty(Object.values(object).filter(value => value !== undefined));

export const isUuidEmpty = (uuid: string): boolean =>
  !uuid || uuid === "00000000-0000-0000-0000-000000000000";

export const isDateEmpty = (value: string) =>
  !value || value === "0001-01-01T00:00:00" || isNaN(Date.parse(value));

export const scrollToTop = (offset?: number, behavior?: ScrollBehavior) => {
  window.scrollTo({
    top: offset || 0,
    ...(!!behavior && { behavior }),
  });
};

export const reloadPage = () => {
  window.location.reload();
};

export const getParsedData = (data: string): { [key: string]: any } => {
  let result = {};

  try {
    result = JSON.parse(data);
  } catch (_) {}

  return result;
};

export const immutableArraySplice = (arr: any[], idx: number) =>
  arr.slice(0, idx).concat(arr.slice(idx + 1));

export const immutableArrayReplaceEl = (arr: any[], idx: number, el: any) => [
  ...arr.slice(0, idx),
  el,
  ...arr.slice(idx + 1),
];

export const getDictionary = ({
  data = [],
  key,
  value,
}: {
  data: object[];
  key: string;
  value: string;
}): Dictionary => {
  let temporaryDictionary = {};

  data.forEach(item => {
    if (item.hasOwnProperty(key) && item.hasOwnProperty(value))
      temporaryDictionary[item[key]] = item[value];
  });

  return temporaryDictionary;
};

export const getUuidValue = (
  value: string,
  defaultValue: string = null
): string => (!isUuidEmpty(value) ? value : defaultValue);

export const sortObjectAlphabetically = (key: string) => (
  a: object,
  b: object
) => {
  if (a[key] === undefined || b[key] === undefined) {
    throw new Error("key does not exist");
  }
  const nameA = a[key].toUpperCase();
  const nameB = b[key].toUpperCase();
  if (nameA < nameB) {
    return -1;
  }
  if (nameA > nameB) {
    return 1;
  }

  // names must be equal
  return 0;
};

export const sortObjectAlphabeticallyByMappingIdWithName = ({
  key,
  dictionary,
}: {
  key: string;
  dictionary: Dictionary;
}) => (a: object, b: object) => {
  if (a[key] === undefined || b[key] === undefined) {
    throw new Error("key does not exist");
  }
  if (isObjectEmpty(dictionary)) {
    throw new Error("dictionary is empty");
  }

  const nameA = dictionary[a[key]].toUpperCase();
  const nameB = dictionary[b[key]].toUpperCase();
  if (nameA < nameB) {
    return -1;
  }
  if (nameA > nameB) {
    return 1;
  }

  // names must be equal
  return 0;
};

export const parseToJSON = (obj: object) =>
  JSON.stringify(obj, Object.keys(obj).sort());

export const getRelativePath = (absolutePath: string) => {
  const foundIndex = absolutePath.indexOf("#");
  if (foundIndex === -1) {
    throw new Error("absolutePath does not have #");
  }
  return absolutePath.substring(foundIndex + 1);
};

export const isFAndVOnly = window.env.SHOW_F_AND_V_ONLY === "true";

export const MAX_CLAIM_NUTRIENT_SIZE = Number(
  window.env.MAX_CLAIM_NUTRIENT_SIZE?.length
    ? window.env.MAX_CLAIM_NUTRIENT_SIZE
    : 100
);

export const masterDataUrl = window.env.MASTER_DATA_URL;

export const getMasterDataSupplierUrl = (supplierId: string) => {
  const suppliersUrl = `${masterDataUrl}/suppliers`;

  if (!supplierId) {
    return suppliersUrl;
  }

  return `${suppliersUrl}/${supplierId}`;
};

export const getMasterDataSiteUrl = (siteId: string) => {
  const sitesUrl = `${masterDataUrl}/sites`;

  if (!siteId) {
    return sitesUrl;
  }

  return `${sitesUrl}/${siteId}`;
};

export const getMasterDataProductUrl = (productId: string) => {
  const productsUrl = `${masterDataUrl}/products`;

  if (!productId) {
    return productsUrl;
  }

  return `${productsUrl}/${productId}`;
};

export const isLocalhost = Boolean(
  window.location.hostname === "localhost" ||
    // [::1] is the IPv6 localhost address.
    window.location.hostname === "[::1]" ||
    // 127.0.0.0/8 are considered localhost for IPv4.
    window.location.hostname.match(
      /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
    )
);

export const transformStringToPathList = (path: string) => {
  if (typeof path !== "string") return path;

  let output: string[] = [];

  path.split(".").forEach(item => {
    if (item) {
      item.split(/\[([^\]]+)\]/g).forEach(key => {
        if (key.length > 0) {
          output.push(key);
        }
      });
    }
  });

  return output;
};

export const getComponentDisplayName = (
  hocFunc: ((...args: unknown[]) => any) | string,
  Component: React.ComponentType | string
) => {
  let name: string;
  if (typeof Component === "string") {
    name = Component;
  } else {
    name = Component?.displayName || Component?.name;
  }

  let wrapper: string;
  if (typeof hocFunc === "string") {
    wrapper = hocFunc;
  } else {
    wrapper = hocFunc.name;
  }
  return `${wrapper}(${name})`;
};

export const sortByKey = <T>({ array, key }: SortByPropertyParams<T>): T[] =>
  sortBy(array, [key]);

export const capitalizeFirstLetter = (str: string) => {
  if (!str) {
    return "";
  }

  return `${str[0].toUpperCase()}${str.slice(1)}`;
};

export const getRefListDictionaryValue = ({
  id,
  refListDictionary,
  emptyValue = "",
}: {
  id: string;
  refListDictionary: Dictionary<string>;
  emptyValue?: string;
}) => {
  if (!id) {
    return emptyValue;
  }

  return refListDictionary[id] || emptyValue;
};

export const addOrUpdateItemToAList = ({
  list,
  propertyName,
  item,
}: {
  list: any[];
  propertyName?: string;
  item: any;
}) => {
  if (
    list.findIndex(
      currentField => currentField?.[propertyName] === item?.[propertyName]
    ) > -1
  ) {
    return list.map(currentField => {
      if (currentField?.[propertyName] === item?.[propertyName]) {
        return { ...item };
      }

      return { ...currentField };
    });
  }

  return [...list, item];
};

export const accessObjectPropertyWithPath = ({
  object,
  path,
}: {
  object: object;
  path: string;
}) => {
  const regex = /(?<=\[)(?!")[^\]]+|(?<=\[")[^"]+|[^."[\]]+/g;
  return path?.match(regex)?.reduce((res, prop) => res?.[prop], object);
};

export const getFieldTitleFromUiSchemaArrayTemplate = (
  uiSchema: UiSchemaInfo,
  fieldName: string
): string =>
  isObjectEmpty(uiSchema) || !fieldName
    ? ""
    : uiSchema?.items?.[fieldName]?.["ui:title"];

export const getFieldTitleFromUiSchemaObjectTemplate = (
  uiSchema: UiSchemaInfo,
  fieldName: string
): string =>
  isObjectEmpty(uiSchema) || !fieldName
    ? ""
    : uiSchema?.[fieldName]?.["ui:title"];

export function prepareTreeFromFlatList({
  items = [],
  primaryKey = "id",
  parentKey = "parentId",
  childrenKey = "children",
}: {
  items;
  primaryKey?: string;
  parentKey?: string;
  childrenKey?: string;
}) {
  let hashTable = {};

  items.forEach(item => (hashTable[item[primaryKey]] = { ...item }));

  let dataTree = [];

  items.forEach(item => {
    if (item[parentKey]) {
      if (!hashTable[item[parentKey]].hasOwnProperty(childrenKey)) {
        hashTable[item[parentKey]][childrenKey] = [];
      }

      hashTable[item[parentKey]][childrenKey].push(hashTable[item[primaryKey]]);
    } else {
      dataTree.push(hashTable[item[primaryKey]]);
    }
  });

  return dataTree;
}

export const isAnElementRemovedFromAList = ({ previousList, currentList }) =>
  !isListEmpty(previousList) && previousList.length > currentList.length;

export const getRemovedElementsFromList = ({ previousList, currentList }) => {
  return previousList.filter(
    previousSection =>
      currentList.findIndex(
        currentItem => currentItem.value === previousSection.value
      ) === -1
  );
};

export const getSkipParamForApi = ({
  isNewSearch,
  currentPage,
  pageSize,
}: {
  isNewSearch: boolean;
  currentPage: number;
  pageSize: number;
}) => (isNewSearch ? 0 : (currentPage - 1) * pageSize);

export const getNameWithVersion = (name: string, version: number) =>
  `${name} ${getVersionName(version)}`;

export const hasNumber = (value: string) => !!value && /\d/.test(value);

export const getArraySchemaItemKey = (jsonSchema: ObjectSchema) =>
  (jsonSchema?.properties?.items as ArraySchema)?.itemKey;

export const getArraySchemaItemsPropertyId = (jsonSchema: ObjectSchema) =>
  (jsonSchema?.properties?.items as ArraySchema)?.propertyId;

export const getArraySchemaItemsPropertyIdByKey = (
  jsonSchema: ArraySchema,
  key: string
) => jsonSchema?.items?.properties?.[key]?.propertyId;

export const findPropertyByKey = (
  schema: ObjectSchema,
  key: string
): ObjectSchema => {
  let result: ObjectSchema = {};

  const searchNestedObject = (schema: ObjectSchema) => {
    if (isObjectEmpty(schema)) {
      return;
    }

    if (schema.hasOwnProperty(key)) {
      result = schema[key];
      return;
    }

    for (const k in schema) {
      if (schema.hasOwnProperty(k)) {
        searchNestedObject(schema[k]);
        if (!isObjectEmpty(result)) {
          return;
        }
      }
    }
  };

  searchNestedObject(schema);
  return result;
};

export const isErrorCodeTagNotFound = (errorCode: ERROR_CODES) =>
  errorCode === ERROR_CODES.TAG_NOT_FOUND;

export const getArraySchemaMaxItems = (jsonSchema: ArraySchema) =>
  jsonSchema?.maxItems;

export const generateListPath = (type: LibraryObjectType) =>
  generatePath(ROUTES.LIBRARY.LISTING, {
    type,
  });

export const toggleListItemSelection = <T extends { id?: string }>({
  item,
  list,
}: {
  item: T;
  list: T[];
}): T[] => {
  if (!item.id) {
    return list;
  }

  const isSelected = list.some(({ id }) => id === item.id);

  if (isSelected) {
    return list.filter(({ id }) => id !== item.id);
  } else {
    return [...list, item];
  }
};

export const filterListByIds = <T extends { id?: string }>({
  ids = [],
  list = [],
}: {
  ids: string[];
  list: T[];
}): T[] => {
  return list.filter(({ id }) => ids.includes(id));
};

export const getUniqueListItems = <T>({
  list,
  fieldName,
}: {
  list: T[];
  fieldName: keyof T;
}) => {
  if (isListEmpty(list)) {
    return [];
  }

  return Array.from(new Set(list.map(list => list[fieldName])));
};

export const filterItemsBySearchValue = <T>(
  items: T[],
  searchValue: string,
  getItemLabel: (item: T) => string
): T[] => {
  if (!searchValue) {
    return items;
  }

  const uppercasedSearchValue = searchValue.trim().toUpperCase();
  return items.filter(item =>
    getItemLabel(item).toString().toUpperCase().includes(uppercasedSearchValue)
  );
};

export const updateBlockData = ({
  blocks,
  blockId,
  dataAsJson,
}: {
  blocks: ChapterStructureFormDataBlock[];
  blockId: string;
  dataAsJson: string;
}) => {
  return (blocks ?? []).map(block => {
    if (block.blockId === blockId) {
      return {
        ...block,
        dataAsJson,
      };
    }
    return block;
  });
};

export const openUrlInNewWindow = (url: string) => {
  if (!url) {
    return;
  }

  window.open(url, "_blank");
};

export const selectFieldsFromObjects = <T extends object, K extends keyof T>({
  arr,
  fields,
}: {
  arr: T[];
  fields: K[];
}): Pick<T, K>[] => {
  if (isListEmpty(fields) || isListEmpty(arr)) {
    return [];
  }

  return arr.map(item =>
    fields.reduce((result, field) => {
      if (item.hasOwnProperty(field)) {
        result[field] = item[field];
      }
      return result;
    }, {} as Pick<T, K>)
  );
};

export const findItemById = <T extends { id: string; children?: T[] }>(
  items: T[],
  id: string
): T | null => {
  let result: T | null = null;

  const searchRecursive = (item: T) => {
    if (item.id === id) {
      result = item;
      return;
    }

    if (item.children && !isListEmpty(item.children)) {
      item.children.forEach(child => {
        if (!result) {
          searchRecursive(child);
        }
      });
    }
  };

  items.forEach(item => {
    if (!result) {
      searchRecursive(item);
    }
  });

  return result;
};

export const getAllNestedIds = <T extends { id: string; children?: T[] }>(
  item: T
): string[] => {
  const ids: string[] = [];

  if (isObjectEmpty(item)) return ids;

  const getIds = (item: T) => {
    ids.push(item.id);

    if (item.children && !isListEmpty(item.children)) {
      for (const child of item.children) {
        getIds(child);
      }
    }
  };

  getIds(item);

  return ids;
};

export const getMaterialIdFromAllergenSection = (
  sections: ChapterStructureFormData[]
) => {
  if (isListEmpty(sections)) return null;

  const allergensSection = sections.find(
    ({ sectionCode }) => sectionCode === SECTION_CODE.ALLERGENS
  );

  if (isObjectEmpty(allergensSection) || isListEmpty(allergensSection?.blocks))
    return null;

  const crossContaminationBlock = allergensSection.blocks.find(
    ({ blockCode }) => blockCode === SECTION_BLOCK_CODE.CROSS_CONTAMINATION
  );

  if (isObjectEmpty(crossContaminationBlock)) return null;

  const materialId = getParsedData(crossContaminationBlock?.dataAsJson)
    ?.compositeId;

  return materialId;
};
