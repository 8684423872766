import { LIBRARY_OBJECT_STATES } from "utils/constants";
import { State } from "./types";

const initialState: State = {
  materialInformation: {
    id: null,
    name: "",
    internalCode: null,
    tagIds: [],
    active: true,
    materialType: null,
    state: LIBRARY_OBJECT_STATES.DRAFT,
  },
  substanceId: null,
  rawMaterial: {
    classificationId: "",
    partId: "",
  },
  recipe: {
    ingredients: [],
  },
  suppliers: [],
  allergens: [],
  characteristicsSchema: { jsonSchema: "", uiSchema: "", json: "" },
  controlPlansSchema: { jsonSchema: "", uiSchema: "", json: "" },
  creationStatus: {
    inProgress: false,
    isDone: false,
    hasError: false,
  },
  updateStatus: {
    inProgress: false,
    isDone: false,
    hasError: false,
  },
  publishStatus: {
    inProgress: false,
    isDone: false,
    hasError: false,
  },
  keepAsDraftStatus: {
    inProgress: false,
    isDone: false,
    hasError: false,
  },
  fileIds: [],
  certificateIds: [],
  certificates: [],
};

export default initialState;
