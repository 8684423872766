import { useState, useEffect } from "react";
import { v4 } from "uuid";
import { useReferenceListsItems } from "hooks";
import { isListEmpty, isObjectEmpty } from "utils/general";
import {
  createCollaboration,
  fetchCollaboration,
  removeCollaboration,
} from "apis/CLB";
import { CollaborationItemData, CollaborationStatus } from "models";
import useExistingCollaborations from "../useExistingCollaborations";
import { UseCollaborationsProps } from "./types";
import { CollaborationData, OnSelectUserProps } from "../../types";
import { useAppDispatch } from "store";
import { setCollaborationData } from "store/collaboration/collaborationSlice";

const useCollaborations = ({
  collaboratingCompanyIds,
  responsibilityIds,
  applicationId,
  parentDataObjectId,
  parentDataObjectSystem,
  roleIds,
  existingCollaborationIds,
  onCreatedCollaboration,
  onRemoveCollaborations,
  skipRemoveCollaborationAPI,
}: UseCollaborationsProps) => {
  const [collaborations, setCollaborations] = useState<CollaborationData[]>([]);
  const dispatch = useAppDispatch();

  const { refListDictionary } = useReferenceListsItems({
    refListIds: responsibilityIds,
  });

  const {
    existingCollaborations,
    isLoadingExistingCollaborations,
    supplierIdsWithExistingCollaborations,
  } = useExistingCollaborations({
    existingCollaborationIds,
    refListDictionary,
  });

  useEffect(() => {
    if (
      !isObjectEmpty(refListDictionary) &&
      !isListEmpty(collaboratingCompanyIds) &&
      !isLoadingExistingCollaborations
    ) {
      const tempCollaborations = [...existingCollaborations];

      collaboratingCompanyIds.forEach(collaboratingCompanyId => {
        if (
          !supplierIdsWithExistingCollaborations.includes(
            collaboratingCompanyId
          )
        ) {
          responsibilityIds.forEach(responsibilityId => {
            const collaborationItem = getInitialCollaborationItem({
              collaboratingCompanyId,
              responsibilityId,
            });
            tempCollaborations.push(collaborationItem);
          });
        }
      });

      dispatch(setCollaborationData(tempCollaborations));

      setCollaborations(tempCollaborations);
    }
  }, [
    refListDictionary,
    isListEmpty(collaboratingCompanyIds),
    isLoadingExistingCollaborations,
    JSON.stringify(existingCollaborations),
    JSON.stringify(supplierIdsWithExistingCollaborations),
  ]);

  const handleSelectUser = ({
    collaborationKey,
    userId,
    userName,
    userEmail,
  }: OnSelectUserProps) => {
    if (userEmail || userEmail === null) {
      setCollaborations(prev => {
        return prev.map(collaboration => {
          if (
            isUpdatedCollaboration({
              existingCollaboration: collaboration,
              collaborationKey,
            })
          ) {
            return {
              ...collaboration,
              status: getStatus(userEmail),
              userEmail: userEmail !== null ? userEmail : undefined,
            };
          } else {
            return { ...collaboration };
          }
        });
      });
    } else {
      setCollaborations(prev => {
        return prev.map(collaboration => {
          if (
            isUpdatedCollaboration({
              existingCollaboration: collaboration,
              collaborationKey,
            })
          ) {
            return {
              ...collaboration,
              status: getStatus(userId),
              userId: userId !== null ? userId : undefined,
              userName: userId !== null ? userName : undefined,
            };
          } else {
            return { ...collaboration };
          }
        });
      });
    }
  };

  const handleCreateCollaboration = ({
    collaborationKey,
    userId,
    userEmail,
    customMessage,
  }: OnSelectUserProps) => async () => {
    updateCollaborationsBeforeCreation({
      collaborationKey,
      userId,
    });
    const searchedCollaboration = collaborations.find(
      ({ key }) => key === collaborationKey
    );
    try {
      const collaboratingCompanyId =
        searchedCollaboration.collaboratingCompanyId;

      const {
        data: { collaborationId },
      } = await createCollaboration({
        collaboratingCompanyId,
        collaboratingUserId: userId,
        collaboratingUserEmail: userEmail,
        customMessage,
        applicationId,
        parentDataObjectId,
        parentDataObjectSystem,
        roleIds,
        responsibilityIds: [searchedCollaboration.responsibilityId],
      });

      onCreatedCollaboration(collaborationId, collaboratingCompanyId);

      const { data } = await fetchCollaboration(collaborationId);

      updateCollaborationsAfterCreation({
        createdCollaboration: data,
        collaborationKey,
      });
    } catch (error) {
      console.error(error);
    }
  };

  const handleRemoveCollaborations = async (collaborationIds: string[]) => {
    if (skipRemoveCollaborationAPI) {
      try {
        await onRemoveCollaborations(collaborationIds);

        setCollaborations(prev => {
          return prev.filter(
            collaboration => !collaborationIds.includes(collaboration.id)
          );
        });
        return;
      } catch (_) {
        return;
      }
    }
    try {
      return Promise.all(
        collaborationIds.map(id => {
          return removeCollaboration(id);
        })
      ).then(() => {
        setCollaborations(prev => {
          return prev.filter(
            collaboration => !collaborationIds.includes(collaboration.id)
          );
        });
        onRemoveCollaborations(collaborationIds);
      });
    } catch (_) {}
  };

  const updateCollaborationsBeforeCreation = ({
    collaborationKey,
  }: OnSelectUserProps) => {
    setCollaborations(prev => {
      return prev.map(collaboration => {
        if (
          isUpdatedCollaboration({
            existingCollaboration: collaboration,
            collaborationKey,
          })
        ) {
          return {
            ...collaboration,
            loading: true,
          };
        } else {
          return { ...collaboration };
        }
      });
    });
  };

  const isUpdatedCollaboration = ({
    existingCollaboration,
    collaborationKey,
  }: {
    existingCollaboration: CollaborationData;
    collaborationKey: string;
  }) => existingCollaboration.key === collaborationKey;

  const updateCollaborationsAfterCreation = ({
    createdCollaboration,
    collaborationKey,
  }: {
    createdCollaboration: CollaborationItemData;
    collaborationKey: string;
  }) => {
    setCollaborations(prev => {
      return prev.map(collaboration => {
        if (
          isUpdatedCollaboration({
            existingCollaboration: collaboration,
            collaborationKey,
          })
        ) {
          return {
            ...collaboration,
            status: createdCollaboration.status,
            sentAt: createdCollaboration.createdAt,
            id: createdCollaboration.collaborationId,
            loading: false,
          };
        } else {
          return { ...collaboration };
        }
      });
    });
  };

  const handleAddCollaboration = (collaboratingCompanyId: string) => {
    setCollaborations(prev => [
      ...prev,
      getInitialCollaborationItem({
        collaboratingCompanyId,
        responsibilityId: responsibilityIds[0],
      }),
    ]);
  };

  const getInitialCollaborationItem = ({
    collaboratingCompanyId,
    responsibilityId,
  }) => {
    return {
      id: undefined,
      key: v4(),
      status: "notInvited" as CollaborationStatus,
      collaboratingCompanyId,
      responsibilityId,
      responsibilityName: refListDictionary[responsibilityId],
    };
  };

  const getStatus = (property: string | null) =>
    property !== null ? "invite" : "notInvited";

  return {
    collaborations,
    handleSelectUser,
    handleCreateCollaboration,
    handleRemoveCollaborations,
    handleAddCollaboration,
  };
};

export default useCollaborations;
