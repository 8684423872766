import { useIntl } from "react-intl";
import {
  EmptyState,
  Card,
  Typography,
  Pagination,
  Graphic,
} from "@trace-one/design-system";
import { UploadFiles } from "@trace-one/business-components";
import { messages } from "../../messages";
import Spin from "components/Spin";
import { Col, Row } from "antd";
import { isListEmpty } from "utils/general";
import { LIBRARY_TYPE_GRAPHICS } from "utils/constants";
import {
  FilesWithMaterialInfo,
  useTechnicalInformation,
} from "../RecipeSummary/hooks";
import { FileViewModel, RecipeSummaryIngredientViewModal } from "viewModels";
import { shouldPaginationBeDisplay } from "utils/table";
import { useClientPagination } from "hooks";
import { generalMessages } from "messages";
import { ErrorBoundary } from "react-error-boundary";
import { WarningFallback } from "components";
import { formatDateToDDMMYYYYWithSlash } from "utils/date";

export interface TechnicalInformationProps {
  files: FileViewModel[];
  isLoading?: boolean;
  ingredients: RecipeSummaryIngredientViewModal[];
}

const TechnicalInformation = ({
  files = [],
  isLoading = false,
  ingredients,
}: TechnicalInformationProps) => {
  const { formatMessage } = useIntl();

  const { filesWithMaterialInfo } = useTechnicalInformation({
    files,
    ingredients,
  });

  const {
    totalCount,
    onPaginationChange,
    filteredItems: visibleFiles,
    filters,
  } = useClientPagination<FilesWithMaterialInfo>({
    items: filesWithMaterialInfo,
  });

  if (isLoading) {
    return <Spin />;
  }

  return (
    <ErrorBoundary FallbackComponent={WarningFallback}>
      <Card title={formatMessage(messages.technicalInformationDescrition)}>
        <Row>
          {isListEmpty(files) ? (
            <Col span={24}>
              <Row justify="center">
                <EmptyState
                  heading={formatMessage(generalMessages.noItemFound)}
                />
              </Row>
            </Col>
          ) : (
            <>
              <Col span={24}>
                <Row gutter={[24, 24]}>
                  {visibleFiles.map(
                    ({
                      id,
                      name,
                      size,
                      createdByUserName,
                      material: { type, name: materialName, recipeType },
                      uploadedAt,
                    }) => {
                      // For composite material type icons are of recipe type
                      const materialType = recipeType ?? type;
                      return (
                        <Col key={id} span={8}>
                          <UploadFiles.Item
                            fileId={id}
                            fileName={name}
                            fileUploadedUserName={createdByUserName}
                            fileUploadedDate={formatDateToDDMMYYYYWithSlash(
                              uploadedAt
                            )}
                            fileSize={size}
                            showFileSize
                            showFileDescription
                            showUploadDate
                            showUploadUserName
                          />

                          <Row className="mt-0-5" align="middle">
                            {materialType && (
                              <Graphic
                                name={LIBRARY_TYPE_GRAPHICS[materialType]}
                                className="mr-1"
                                color="primary"
                              />
                            )}
                            <Typography variant="body-s" color="grey-5">
                              {materialName}
                            </Typography>
                          </Row>
                        </Col>
                      );
                    }
                  )}
                </Row>
              </Col>
              <Col span={24} className="mt-4">
                <Row justify="end">
                  <Pagination
                    hideOnSinglePage={!shouldPaginationBeDisplay(totalCount)}
                    defaultCurrent={1}
                    showSizeChanger
                    current={filters.currentPage}
                    pageSize={filters.pageSize}
                    total={totalCount}
                    onShowSizeChange={onPaginationChange}
                    onChange={onPaginationChange}
                  />
                </Row>
              </Col>
            </>
          )}
        </Row>
      </Card>
    </ErrorBoundary>
  );
};

export default TechnicalInformation;
