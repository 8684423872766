import { Col } from "antd";
import { Button, Modal, Input } from "@trace-one/design-system";
import { useIntl } from "react-intl";
import { isListEmpty } from "utils/general";
import { useAddNutrition } from "../../hooks";
import ModalBody from "./components/ModalBody";
import { messages } from "./messages";
import { AddANutrientProps } from "./types";
import styles from "./styles.module.less";

const AddANutrient: React.FC<AddANutrientProps> = ({
  existingNutrientIds,
  addNutritionAction,
  formData,
}) => {
  const { formatMessage } = useIntl();

  const {
    handleOpenAddNutrientModal,
    handleCloseModal,
    handleAddNutrients,
    handleCheck,
    handleSearch,
    allNutrientsTree,
    isAddNutrientModalOpen,
    disabledNutrientIds,
    newlySelectedItems,
    isLoading,
    addingNutrients,
  } = useAddNutrition({
    defaultCheckedItems: existingNutrientIds,
    addNutritionAction,
    formData,
  });

  return (
    <>
      <Button onClick={handleOpenAddNutrientModal}>
        {formatMessage(messages.addNutrient)}
      </Button>
      {isAddNutrientModalOpen ? (
        <Modal.Simple
          title={formatMessage(messages.addNutrientModalTitle)}
          size="m"
          open
          secondaryButtonText={formatMessage(messages.secondaryButtonText)}
          primaryButtonText={formatMessage(messages.primaryButtonText)}
          onPrimaryButtonClick={handleAddNutrients}
          onSecondaryButtonClick={handleCloseModal}
          primaryButtonProps={{
            disabled: isListEmpty(newlySelectedItems),
            loading: addingNutrients,
          }}
          secondaryButtonProps={{
            disabled: addingNutrients,
          }}
          onCancel={handleCloseModal}
        >
          <>
            <Col xs={24}>
              <div className={styles.searchBar}>
                <Input allowClear={true} onChange={handleSearch} />
              </div>
            </Col>

            <ModalBody
              allNutrientsTree={allNutrientsTree}
              handleCheck={handleCheck}
              disabledNutrientIds={disabledNutrientIds}
              newlySelectedItems={newlySelectedItems}
              isLoading={isLoading}
            />
          </>
        </Modal.Simple>
      ) : null}
    </>
  );
};

export default AddANutrient;
