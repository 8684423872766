import { DateRangePicker } from "@trace-one/design-system";
import { useContext } from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { selectLanguageCode } from "store/user/selectors";
import MoreFilters from "../MoreFilters";
import { HeaderChildrenProps } from "components/SpecificationHistorisationModal/types";
import { HistorisationContext } from "components/SpecificationHistorisationModal/contexts";
import { getEndOfDay } from "utils/date";
import { messages } from "../../messages";
import styles from "../../styles.module.less";

const HeaderChildren: React.FC<HeaderChildrenProps> = ({ setDates }) => {
  const { formatMessage } = useIntl();
  const preferedLanguage = useSelector(selectLanguageCode);

  const { selectedFilters, areFiltersDisabled } = useContext(
    HistorisationContext
  );

  return (
    <>
      <DateRangePicker
        label={formatMessage(messages.choosePeriod)}
        value={selectedFilters.dates}
        onChange={setDates}
        locale={preferedLanguage}
        disabled={areFiltersDisabled}
        disabledDate={current => {
          // Can not select days after today
          return current && current > getEndOfDay();
        }}
      />
      <div className={styles.moreFilters}>
        <MoreFilters />
      </div>
    </>
  );
};

export default HeaderChildren;
