import { useRef } from "react";
// import { useIntl } from "react-intl";
import { MATERIAL_TYPES, RECIPE_TYPES } from "utils/constants";
import { useMaterialDetails, useScrollToSection } from "../../hooks";
import Suppliers from "../Suppliers";
import Characteristics from "../Characteristics";
import Allergens from "./components/Allergens";
import BoughtDetailsRecipeTable from "./components/RecipeTable";
import ControlPlans from "../ControlPlans";
import Attachment from "../Attachment";
// import FormCard from "../FormCard";
// import Certificates from "components/Certificates";
import { isLibraryObjectLocked } from "utils/library";
// import { getCompositeCertificateIds } from "../../utils";
import { BoughtDetailsProps } from "./types";
// import { certificationMessages } from "messages";

const BoughtDetails: React.FC<BoughtDetailsProps> = ({
  recipe,
  materialId,
  suppliers,
  allergens,
  displayActions,
  files,
  state,
  certificates = [],
}) => {
  // const { formatMessage } = useIntl();
  const allergensRef = useRef<HTMLDivElement | null>(null);

  const fileIds = files?.find(fileInfo => fileInfo.materialId === materialId)
    ?.fileId;

  useScrollToSection({ elementRef: allergensRef });

  const { catalogDictionary, refListDictionary } = useMaterialDetails({
    suppliers,
    allergens,
    ingredients: recipe.ingredients,
  });

  // const certificateIds = getCompositeCertificateIds({
  //   certificates: certificates || [],
  // });

  return (
    <>
      <BoughtDetailsRecipeTable
        recipe={recipe}
        displayActions={displayActions}
        catalogDictionary={catalogDictionary}
        materialId={materialId}
      />
      <Suppliers
        displayActions={displayActions}
        suppliers={suppliers}
        materialType={MATERIAL_TYPES.COMPOSITE}
        materialId={materialId}
        refListDictionary={refListDictionary}
        materialIngredients={recipe.ingredients}
      />
      <div ref={allergensRef}>
        <Allergens
          allergenDictionary={catalogDictionary}
          displayActions={displayActions}
          allergens={allergens}
          ingredients={recipe.ingredients}
        />
      </div>
      <Attachment
        fileIds={fileIds}
        displayActions={displayActions && !isLibraryObjectLocked(state)}
      />
      {/* uncomment below code and imports when backend impl is done */}
      {/* <FormCard
        title={formatMessage(certificationMessages.certificatesLabel)}
        name="certificates"
      >
        <Certificates
          displayActions={displayActions}
          certificateIds={certificateIds}
        />
      </FormCard> */}
      <Characteristics
        displayActions={displayActions}
        materialId={materialId}
        refListDictionary={refListDictionary}
        materialType={MATERIAL_TYPES.COMPOSITE}
        recipeType={RECIPE_TYPES.BOUGHT_TO_THIRD_PARTY}
      />
      <ControlPlans
        displayActions={displayActions}
        materialId={materialId}
        materialType={MATERIAL_TYPES.COMPOSITE}
        refListDictionary={refListDictionary}
        recipeType={RECIPE_TYPES.BOUGHT_TO_THIRD_PARTY}
      />
    </>
  );
};

BoughtDetails.defaultProps = {
  displayActions: true,
};

export default BoughtDetails;
