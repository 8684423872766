import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { toaster as toasterService } from "@trace-one/design-system";
import { persistor } from "store";
import { selectLanguageCode } from "store/user/selectors";
import {
  createChapterVersion,
  createMaterialVersion,
  createRequirementVersion,
  deleteChapterType,
  deleteClaim,
  deleteRequirement,
  duplicateChapter,
} from "apis/SPEC";
import useRedirect from "../useRedirect";
import {
  isLibraryObjectChapterSubType,
  isLibraryObjectLocked,
} from "utils/library";
import { CHAPTER_TYPES_URL_API, LIBRARY_OBJECT_TYPES } from "utils/constants";
import { getRedirectToAction } from "./utils";
import { messages } from "./messages";
import libraryMessages from "messages/library";
import { LibraryItemActionItemViewModel } from "viewModels";
import { useDeleteTemplate } from "apis/SPEC/templates";
import { LibraryItemApiType } from "types/library";
import { generalMessages } from "messages";

const useLibraryItemActions = ({
  libraryItemInfo,
  refetchLibraryItems,
  openDeleteModal,
}: {
  libraryItemInfo: LibraryItemActionItemViewModel;
  refetchLibraryItems?: Function;
  openDeleteModal?: () => void;
}) => {
  const { formatMessage } = useIntl();
  const languageCode = useSelector(selectLanguageCode);

  const {
    redirectToLibraryCreateNewVersion,
    redirectToLibraryDetails,
    redirectToLibraryEdition,
  } = useRedirect();

  const [deleteTemplate] = useDeleteTemplate();

  const { id: libraryItemInfoId, type: libraryItemInfoType } = libraryItemInfo;

  const getNewActionName = (action: string) => {
    const mappedKeys = {
      [LIBRARY_OBJECT_TYPES.REQUIREMENTS]: "requirement",
      [LIBRARY_OBJECT_TYPES.RAW_MATERIALS]: "rawMaterial",
      [LIBRARY_OBJECT_TYPES.MADE_IN_HOUSE]: "composite",
      [LIBRARY_OBJECT_TYPES.BOUGHT_TO_THIRD_PARTY]: "composite",
      [LIBRARY_OBJECT_TYPES.TEMPLATES]: "template",
    };

    let messageKey = mappedKeys[libraryItemInfoType];

    return messages?.[`${messageKey}${action}`]
      ? formatMessage(messages?.[`${messageKey}${action}`])
      : action;
  };

  const redirectToAction = getRedirectToAction({
    libraryItemInfo,
    purge: persistor.purge,
  });

  const createNewVersionHandler = () => {
    switch (libraryItemInfoType) {
      case LIBRARY_OBJECT_TYPES.TEXT_REQUIREMENTS:
      case LIBRARY_OBJECT_TYPES.DOCUMENT_REQUIREMENTS:
        onCreateRequirementNewVersion();
        break;

      case LIBRARY_OBJECT_TYPES.PRODUCTION:
      case LIBRARY_OBJECT_TYPES.FINISHED_PRODUCTS:
      case LIBRARY_OBJECT_TYPES.TESTING:
        onCreateChapterNewVersion();
        break;

      case LIBRARY_OBJECT_TYPES.RAW_MATERIALS:
      case LIBRARY_OBJECT_TYPES.MADE_IN_HOUSE:
      case LIBRARY_OBJECT_TYPES.BOUGHT_TO_THIRD_PARTY:
        onCreateMaterialNewVersion();
        break;

      case LIBRARY_OBJECT_TYPES.HEALTH_CLAIMS:
      case LIBRARY_OBJECT_TYPES.MARKETING_CLAIMS:
        onCreateClaimVersion();
        break;

      default:
        break;
    }
  };

  const onCreateRequirementNewVersion = async () => {
    try {
      const { data: id } = await createRequirementVersion({
        requirementId: libraryItemInfoId,
        requirementType: libraryItemInfoType,
        params: {
          languageCode,
        },
      });

      redirectToLibraryCreateNewVersion({
        id,
        type: libraryItemInfoType,
        queryParameters: {
          prevVersionId: libraryItemInfoId,
        },
      });

      toasterService.open({
        message: formatMessage(
          libraryMessages.requirementCreateNewVersionTitle
        ),
        description: formatMessage(
          libraryMessages.createNewVersionDescription,
          {
            libraryItemName: libraryItemInfo?.name,
          }
        ),
        type: "confirmation",
      });
    } catch (_) {}
  };

  const onCreateMaterialNewVersion = async () => {
    try {
      const { data: id } = await createMaterialVersion({
        materialId: libraryItemInfoId,
        materialType: libraryItemInfoType,
        params: {
          languageCode,
        },
      });
      redirectToLibraryCreateNewVersion({
        id,
        type: libraryItemInfoType,
        queryParameters: {
          prevVersionId: libraryItemInfoId,
        },
      });

      toasterService.open({
        message: formatMessage(messages.materialCreateNewVersionTitle),
        description: formatMessage(
          libraryMessages.createNewVersionDescription,
          {
            libraryItemName: libraryItemInfo?.name,
          }
        ),
        type: "confirmation",
      });
    } catch (_) {}
  };

  const onCreateChapterNewVersion = async () => {
    try {
      const { data: id } = await createChapterVersion({
        chapterId: libraryItemInfoId,
        chapterType: libraryItemInfoType,
        params: { languageCode },
      });

      redirectToLibraryCreateNewVersion({
        id,
        type: libraryItemInfoType,
        queryParameters: {
          prevVersionId: libraryItemInfoId,
        },
      });

      toasterService.open({
        message: formatMessage(libraryMessages.createNewVersionTitle),
        description: formatMessage(
          libraryMessages.createNewVersionDescription,
          {
            libraryItemName: libraryItemInfo?.name,
          }
        ),
        type: "confirmation",
      });
    } catch (_) {}
  };

  const onCreateClaimVersion = async () => {
    try {
      redirectToLibraryCreateNewVersion({
        id: libraryItemInfoId,
        type: libraryItemInfoType,
        queryParameters: {
          prevVersionId: libraryItemInfoId,
        },
      });
    } catch {}
  };

  const onDeleteTemplate = async () => {
    await deleteTemplate(libraryItemInfoId);
  };

  const onDeleteChapter = async () => {
    await deleteChapterType({
      chapterId: libraryItemInfoId,
      chapterType: libraryItemInfoType,
    });
  };

  const onDeleteClaim = async () => {
    await deleteClaim({
      id: libraryItemInfoId,
      claimType: libraryItemInfoType as LibraryItemApiType,
    });
  };

  const onDeleteRequirement = async () => {
    await deleteRequirement({
      requirementId: libraryItemInfoId,
      requirementType: libraryItemInfoType as LibraryItemApiType,
    });
  };

  const deleteHandler = async () => {
    try {
      switch (libraryItemInfoType) {
        case LIBRARY_OBJECT_TYPES.TEMPLATES:
          await onDeleteTemplate();
          break;

        case LIBRARY_OBJECT_TYPES.PRODUCTION:
        case LIBRARY_OBJECT_TYPES.TESTING:
        case LIBRARY_OBJECT_TYPES.FINISHED_PRODUCTS:
          await onDeleteChapter();
          break;

        case LIBRARY_OBJECT_TYPES.MARKETING_CLAIMS:
        case LIBRARY_OBJECT_TYPES.HEALTH_CLAIMS:
          await onDeleteClaim();
          break;

        case LIBRARY_OBJECT_TYPES.TEXT_REQUIREMENTS:
        case LIBRARY_OBJECT_TYPES.DOCUMENT_REQUIREMENTS:
          await onDeleteRequirement();
          break;

        default:
          break;
      }

      await refetchLibraryItems?.(true);
    } catch (e) {
      throw e;
    }
  };

  const onDuplicateChapter = async () => {
    try {
      const { data } = await duplicateChapter({
        chapterId: libraryItemInfoId,
        chapterType: libraryItemInfoType,
        params: { languageCode },
      });

      redirectToLibraryEdition({
        id: data?.generalInfo?.id,
        type: libraryItemInfoType,
      });

      toasterService.open({
        message: formatMessage(libraryMessages.chapterDuplicateTitle),
        description: formatMessage(
          libraryMessages.chapterDuplicateDescription,
          {
            chapterName: libraryItemInfo?.name,
          }
        ),
        type: "confirmation",
      });
    } catch (_) {}
  };

  const isChapterObject = isLibraryObjectChapterSubType(
    CHAPTER_TYPES_URL_API[libraryItemInfoType]
  );

  return {
    details: {
      name: getNewActionName("View"),
      onClick: redirectToAction(redirectToLibraryDetails),
    },
    createNewVersion: {
      disabled:
        !isLibraryObjectLocked(libraryItemInfo.state) ||
        !libraryItemInfo.lastVersion,
      name: formatMessage(libraryMessages.createNewVersion),
      onClick: createNewVersionHandler,
    },
    edit: {
      name: getNewActionName("Edit"),
      shortName: formatMessage(libraryMessages.editButton),
      onClick: redirectToAction(redirectToLibraryEdition),
      disabled: isLibraryObjectLocked(libraryItemInfo.state),
    },
    ...(isChapterObject
      ? {
          duplicate: {
            name: getNewActionName("Duplicate"),
            shortName: formatMessage(generalMessages.duplicate),
            onClick: onDuplicateChapter,
          },
        }
      : {}),
    delete: {
      name: getNewActionName("Delete"),
      shortName: formatMessage(libraryMessages.deleteAction),
      tooltipProps: {
        onConfirmation: deleteHandler,
      },
      disabled: isLibraryObjectLocked(libraryItemInfo.state),
    },
    deleteUsingModal: {
      name: formatMessage(libraryMessages.deleteVersion),
      shortName: formatMessage(libraryMessages.deleteAction),
      label: formatMessage(libraryMessages.deleteVersion),
      onClick: openDeleteModal,
      disabled: isLibraryObjectLocked(libraryItemInfo.state),
    },
    deleteHandler,
  };
};

export default useLibraryItemActions;
