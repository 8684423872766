import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { FormInstance } from "antd";
import { RcFile } from "antd/lib/upload";
import {
  createOrPublishClaim,
  createOrPublishClaimVersion,
  fetchClaim,
  saveOrPublish,
} from "apis/SPEC";
import { useBoolean, useRedirect } from "hooks";
import { useAppDispatch } from "store";
import {
  createClaimTag,
  uploadClaimLogoFile,
} from "store/claimForm/asyncActions";
import {
  initialClaimForm,
  ReduxClaimType,
  setClaimFormData,
  setClaimFormFile,
} from "store/claimForm/claimFormSlice";
import { selectLanguageCode } from "store/user/selectors";
import { prepareClaimDetailsData, prepareClaimFormData } from "dtos/claim";
import { LibraryItemDetails } from "pages/Library/hocs/withLibraryItemDetails/types";
import {
  LIBRARY_OBJECT_API_TYPES,
  LIBRARY_OBJECT_TYPES,
  LIBRARY_OBJECT_URL_TYPES,
  MAX_TEXTAREA_LENGTH,
} from "utils/constants";
import { generateListPath, isListEmpty } from "utils/general";
import { libraryMessages } from "messages";
import { isClaimTypeHealth } from "utils/claim";
import { ClaimSentenceTranslation } from "models";

export interface UseClaimFormActionsProps {
  claimForm: ReduxClaimType;
  logoFile: RcFile;
  form?: FormInstance;
  setPreviousClaimData?: React.Dispatch<React.SetStateAction<ReduxClaimType>>;
  setRawClaimData?: React.Dispatch<React.SetStateAction<LibraryItemDetails>>;
  isNewVersionPage?: boolean;
  previousVersionId?: string;
}

const getSentenceTranslationWithoutEmptyText = (
  sentenceTranslations: ClaimSentenceTranslation[]
) => {
  return sentenceTranslations.filter(
    translation => translation?.text?.length > 0
  );
};

const useClaimFormActions = ({
  claimForm,
  logoFile,
  form,
  setPreviousClaimData,
  setRawClaimData,
  isNewVersionPage,
  previousVersionId,
}: UseClaimFormActionsProps) => {
  const { formatMessage } = useIntl();
  const languageCode = useSelector(selectLanguageCode);

  const { redirectToLibraryListing, redirectToLibraryDetails } = useRedirect();
  const dispatch = useAppDispatch();

  const { generalInfo, file, sentenceTranslations, regulation } = claimForm;
  const {
    name,
    claimType,
    productTypeId,
    tags,
    id,
    requiresSupportingDocuments,
    regulatoryClaimId,
  } = generalInfo;

  const {
    value: isClaimCreating,
    setTrue: setClaimCreatingTrue,
    setFalse: setClaimCreatingFalse,
  } = useBoolean();
  const {
    value: isClaimSaving,
    setTrue: setClaimSavingTrue,
    setFalse: setClaimSavingFalse,
  } = useBoolean();
  const {
    value: isClaimPublishing,
    setTrue: setClaimPublishingTrue,
    setFalse: setClaimPublishingFalse,
  } = useBoolean();

  const getLogoFileId = async () => {
    if (file?.id) {
      return file?.id;
    }

    if (!logoFile) {
      return;
    }

    try {
      const uploadedFile = await dispatch(uploadClaimLogoFile(logoFile));

      // @ts-ignore
      return uploadedFile?.payload?.fileId as string;
    } catch {}
  };

  const getTagIds = async () => {
    const tagIdsPayload: string[] = [];

    try {
      const tagPromises = await Promise.allSettled(
        tags?.map(async tag => {
          if (tag?.tagId) {
            return tag;
          }

          const createdTag = await dispatch(createClaimTag(tag?.tagText));
          return createdTag.payload;
        })
      );

      tagPromises.forEach(tagPromise => {
        if (tagPromise.status === "fulfilled") {
          // @ts-ignore
          tagIdsPayload.push(tagPromise?.value?.tagId);
        }
      });

      return tagIdsPayload;
    } catch {}
  };

  const onCreateOrPublish = async (publish: boolean = false) => {
    try {
      const tagIds = await getTagIds();
      const logoFileId = await getLogoFileId();

      await createOrPublishClaim({
        claimType: LIBRARY_OBJECT_API_TYPES[claimType],
        payload: {
          name,
          claimType,
          productTypeId,
          tagIds,
          logoFileId,
          requiresSupportingDocuments,
          ...(isClaimTypeHealth(claimType)
            ? {
                additionalSentenceTranslations: getSentenceTranslationWithoutEmptyText(
                  sentenceTranslations
                ),
              }
            : { sentenceTranslations }),
          ...(isClaimTypeHealth(claimType) ? { regulatoryClaimId } : {}),
        },
        params: {
          publish,
        },
      });
    } catch (e) {
      throw e;
    }
  };

  const onCreateOrPublishVersion = async (publish: boolean = false) => {
    try {
      const tagIds = await getTagIds();
      const logoFileId = await getLogoFileId();

      const { data } = await createOrPublishClaimVersion({
        id,
        claimType: LIBRARY_OBJECT_API_TYPES[claimType],
        payload: {
          tagIds,
          logoFileId,
          requiresSupportingDocuments,
          ...(isClaimTypeHealth(claimType)
            ? {
                additionalSentenceTranslations: getSentenceTranslationWithoutEmptyText(
                  sentenceTranslations
                ),
              }
            : { sentenceTranslations }),
          ...(isClaimTypeHealth(claimType) ? { regulatoryClaimId } : {}),
        },
        params: {
          publish,
        },
      });

      return data;
    } catch (e) {
      throw e;
    }
  };

  const onSaveOrPublish = async (publish: boolean = false) => {
    try {
      const tagIds = await getTagIds();
      const logoFileId = await getLogoFileId();

      const response = await saveOrPublish({
        claimType: LIBRARY_OBJECT_API_TYPES[claimType],
        payload: {
          tagIds,
          logoFileId,
          name,
          requiresSupportingDocuments,
          ...(isClaimTypeHealth(claimType)
            ? {
                additionalSentenceTranslations: getSentenceTranslationWithoutEmptyText(
                  sentenceTranslations
                ),
              }
            : { sentenceTranslations }),
          ...(isClaimTypeHealth(claimType) ? { regulatoryClaimId } : {}),
        },
        id,
        params: { publish },
      });

      return response?.data;
    } catch (e) {
      throw e;
    }
  };

  const onPublishDraftClaim = async () => {
    try {
      setClaimPublishingTrue();

      await onSaveOrPublish(true);

      redirectToLibraryDetails({
        id,
        type: LIBRARY_OBJECT_API_TYPES[claimType],
        pushState: {
          skipDetectLeavePage: true,
        },
      });
    } catch (e) {
      throw e;
    } finally {
      setClaimPublishingFalse();
    }
  };

  const onSaveClaim = async () => {
    try {
      setClaimSavingTrue();

      await onSaveOrPublish();

      const { data } = await fetchClaim({
        claimType: LIBRARY_OBJECT_API_TYPES[claimType],
        id,
        languageCode,
      });

      setRawClaimData?.(prepareClaimDetailsData(data ?? {}, true));

      setPreviousClaimData?.(
        prepareClaimFormData({
          libraryItemDetails: data ?? {},
          shouldSaveTagAsArrayOfString: true,
          regulation,
        })
      );
    } catch (e) {
      throw e;
    } finally {
      setClaimSavingFalse();
    }
  };

  const onKeepAsDraftFromDropdown = async () => {
    try {
      const endpoint = isNewVersionPage
        ? onCreateOrPublishVersion
        : onCreateOrPublish;
      await endpoint();

      redirectToLibraryListing(LIBRARY_OBJECT_TYPES.CLAIMS, {
        skipDetectLeavePage: true,
      });
    } catch (e) {}
  };

  const onPublish = async () => {
    try {
      let newVersionId: string;

      if (isNewVersionPage) {
        newVersionId = await onCreateOrPublishVersion(true);
      } else {
        await onCreateOrPublish(true);
      }

      if (newVersionId && isNewVersionPage) {
        redirectToLibraryDetails({
          id: newVersionId,
          type: LIBRARY_OBJECT_URL_TYPES[claimType],
          pushState: {
            skipDetectLeavePage: true,
          },
        });
      } else {
        redirectToLibraryListing(LIBRARY_OBJECT_TYPES.CLAIMS, {
          skipDetectLeavePage: true,
        });
      }
    } catch {}
  };

  const validateForm = async () => {
    try {
      await form?.validateFields();
    } catch (e) {
      validateClaimSentences();
      throw e;
    }
  };

  const validateClaimSentences = () => {
    if (isClaimTypeHealth(claimType)) {
      return;
    }

    try {
      let errors = [];

      sentenceTranslations.forEach(({ languageCode, text }) => {
        const trimmedText = text?.trim();

        if (!trimmedText) {
          errors.push({
            fieldName: languageCode,
            message: formatMessage(libraryMessages.formRequired),
          });
        } else if (trimmedText.length > MAX_TEXTAREA_LENGTH) {
          errors.push({
            fieldName: languageCode,
            message: formatMessage(libraryMessages.formMaxCharLength, {
              max: MAX_TEXTAREA_LENGTH,
            }),
          });
        }
      });

      if (!isListEmpty(errors)) {
        errors.forEach(({ fieldName, message }) => {
          form.setFields([
            {
              name: fieldName,
              errors: [message],
            },
          ]);
        });

        throw Error();
      }
    } catch (e) {
      throw e;
    }
  };

  const onKeepAsDraft = async () => {
    try {
      await validateForm();

      validateClaimSentences();

      setClaimCreatingTrue();

      const endpoint = isNewVersionPage
        ? onCreateOrPublishVersion
        : onCreateOrPublish;
      await endpoint();
    } catch (e) {
      throw e;
    } finally {
      setClaimCreatingFalse();
    }
  };

  const onCancelVersionCreation = async (
    intendedLeavingPage: string = null
  ) => {
    try {
      if (
        isNewVersionPage &&
        previousVersionId &&
        intendedLeavingPage?.includes(
          generateListPath(LIBRARY_OBJECT_TYPES.CLAIMS)
        )
      ) {
        redirectToLibraryDetails({
          id: previousVersionId,
          type: LIBRARY_OBJECT_URL_TYPES[claimType],
        });
      }

      dispatch(setClaimFormData(initialClaimForm));
      dispatch(setClaimFormFile(initialClaimForm.file));
    } catch (e) {
      throw e;
    }
  };

  return {
    onKeepAsDraftFromDropdown,
    onKeepAsDraft,
    onPublish,
    onPublishDraftClaim,
    onSaveClaim,
    onCancelVersionCreation,
    isClaimCreating,
    isClaimPublishing,
    isClaimSaving,
  };
};

export default useClaimFormActions;
