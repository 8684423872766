/* istanbul ignore file */

import axios, { AxiosRequestConfig } from "axios";
import { CertificateData, CertificatesData } from "models/certification";

export const axiosInstance = axios.create({
  baseURL: window.env.CERTIFICATION_URL,
});

export const getCertificates = ({
  params,
  paramsSerializer,
}: {
  params: {
    certificateIds?: CertificateData["id"][];
    searchTerm?: string;
    includeCertification?: boolean;
    includeSharedCertificates?: boolean;
    pageNumber?: number;
    pageSize?: number;
    languageCode?: string;
  };
  paramsSerializer?: AxiosRequestConfig["paramsSerializer"];
}) =>
  axiosInstance.get<CertificatesData>(`api/certificates`, {
    params,
    paramsSerializer,
  });
