import { ChangeEvent, useEffect, useMemo, useState } from "react";
import { useIntl } from "react-intl";
import {
  LIBRARY_API_PARAMS_VERSION,
  LIBRARY_OBJECT_STATES,
  LIBRARY_OBJECT_TYPES,
} from "utils/constants";
import { LibraryObjectFilters } from "./types";
import { Params } from "../../types";
import { LibraryApiParamsVersion } from "components/Library/components/types";
import statusMessages from "messages/statuses";
import { isListEmpty } from "utils/general";
import { isLibraryObjectClaimType } from "utils/library";
import { isClaimTypeNutritional } from "utils/claim";
import { getFilterValue, getSearchTermParam } from "../../utils";
import { LibraryListFilterValue } from "types/filters";

export interface UseFilterProps {
  type: LIBRARY_OBJECT_TYPES;
  extraParams: Params;
  isLibraryListingPage?: boolean;
}

const useFilters = ({
  type,
  extraParams = {},
  isLibraryListingPage,
}: UseFilterProps) => {
  const intl = useIntl();

  const initialFilters = useMemo(
    () => ({
      orderBy:
        isLibraryObjectClaimType(type) || isClaimTypeNutritional(type)
          ? "creationDateUtc"
          : "lastUpdateDateUtc",
      orderByDescending: true,
      pageSize: 20,
      currentPage: 1,
      version: LIBRARY_API_PARAMS_VERSION.lastVersion,
    }),
    [type, isLibraryListingPage]
  );
  const [defaultFilters, setDefaultFilters] = useState({
    statuses: [
      {
        label: intl.formatMessage(statusMessages.locked),
        value: LIBRARY_OBJECT_STATES.LOCKED,
      },
      {
        label: intl.formatMessage(statusMessages.published),
        value: LIBRARY_OBJECT_STATES.PUBLISHED,
      },
      {
        label: intl.formatMessage(statusMessages.draft),
        value: LIBRARY_OBJECT_STATES.DRAFT,
      },
    ],
  });
  const [filters, setFilters] = useState<LibraryObjectFilters>({
    ...initialFilters,
    type,
    ...extraParams,
  });
  const [searchTerm, setSearchTerm] = useState("");

  const onPaginationChange = (currentPage: number, pageSize: number) => {
    setFilters(previousState => ({
      ...previousState,
      currentPage,
      pageSize,
    }));
  };

  const onSearch = () => {
    setFilters(previousState => ({
      ...previousState,
      currentPage: 1,
      ...getSearchTermParam({ type, searchTerm }),
      ...(extraParams?.productTypeId && {
        productTypeId: extraParams?.productTypeId,
      }),
      ...(extraParams?.version && {
        version: extraParams?.version as LibraryApiParamsVersion,
      }),
    }));
  };

  const onSearchChange = ({
    target: { value },
  }: ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(value);
  };

  const updateFilter = (filterName: string) => (
    filterValue: LibraryListFilterValue
  ) => {
    setFilters(previousValue => ({
      ...previousValue,
      [filterName]: getFilterValue(filterValue, type),
    }));
  };

  const setStates = (selectedStates: string[]) => {
    let states = selectedStates;

    if (isListEmpty(selectedStates) && !isListEmpty(extraParams?.states)) {
      states = extraParams?.states;
    }

    updateFilter("states")(states);
  };

  useEffect(() => {
    setSearchTerm("");
    if (isLibraryObjectClaimType(type)) {
      setFilters({
        ...initialFilters,
        type,
        orderBy: "creationDateUtc",
        ...(extraParams?.version && {
          version: extraParams?.version as LibraryApiParamsVersion,
        }),
      });
      return;
    }
    setFilters({
      ...initialFilters,
      type,
      ...(extraParams?.version && {
        version: extraParams?.version as LibraryApiParamsVersion,
      }),
    });
  }, [type]);

  useEffect(() => {
    if (!isListEmpty(extraParams?.states)) {
      setDefaultFilters(previousState => ({
        ...previousState,
        statuses: previousState.statuses.filter(({ value }) =>
          extraParams.states.includes(value)
        ),
      }));
      updateFilter("states")(extraParams.states);
      updateFilter("chapterTypes")(extraParams.chapterTypes);
      updateFilter("claimTypes")(extraParams.claimTypes);
    }
  }, [
    extraParams?.states?.length,
    extraParams?.chapterTypes?.length,
    extraParams?.claimTypes?.length,
  ]);

  return {
    filters,
    searchTerm,
    defaultFilters,
    updateFilter,
    onPaginationChange,
    onSearch,
    onSearchChange,
    setOrderBy: updateFilter("orderBy"),
    setOrderByDescending: updateFilter("orderByDescending"),
    setRequirementType: updateFilter("requirementType"),
    setChapterTypes: updateFilter("chapterTypes"),
    setStates,
  };
};

export default useFilters;
