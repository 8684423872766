import { isListEmpty } from "./../../../../../../../../../../utils/general";
import { useEffect, useState } from "react";
import { useReferenceListsItems } from "hooks";
import { preparePackagingComponents } from "dtos/packagingSystem";
import { PackagingSystemComponentsTableViewModel } from "viewModels";
import { PackagingSystemComponentData } from "models";
import { useSelector } from "react-redux";
import {
  selectPackagingComponents,
  selectPackagingSystemLoading,
} from "store/packagingSystemForm/selectors";

const usePackagingSystemComponents = () => {
  const [
    packagingSystemComponentsTableView,
    setPackagingSystemComponentsTableView,
  ] = useState<PackagingSystemComponentsTableViewModel[]>([]);
  const [
    packagingSystemComponentsData,
    setPackagingSystemComponentsData,
  ] = useState<PackagingSystemComponentData[]>([]);

  const [
    packagingComponentFieldsFromApi,
    setPackagingComponentFieldsFromApi,
  ] = useState<{
    packagingComponentTypeIds: PackagingSystemComponentData["componentTypeId"][];
    packagingComponentIds: PackagingSystemComponentData["componentId"][];
  }>({ packagingComponentIds: [], packagingComponentTypeIds: [] });

  const components = useSelector(selectPackagingComponents);
  const isLoading = useSelector(selectPackagingSystemLoading);

  useEffect(() => {
    if (!isListEmpty(components)) {
      setPackagingSystemComponentsTableView(
        preparePackagingComponents(components)
      );
      setPackagingSystemComponentsData(components);
      setPackagingComponentFieldsFromApi({
        packagingComponentTypeIds: components?.map(
          component => component?.componentTypeId
        ),
        packagingComponentIds: components?.map(
          component => component?.componentId
        ),
      });
    }
  }, [components]);

  const { refListDictionary } = useReferenceListsItems({
    refListIds: packagingComponentFieldsFromApi.packagingComponentTypeIds || [],
  });

  return {
    packagingSystemComponentsData,
    packagingSystemComponentsTableView,
    refListDictionary,
    packagingComponentIds:
      packagingComponentFieldsFromApi.packagingComponentIds || [],
    isLoading,
  };
};

export default usePackagingSystemComponents;
