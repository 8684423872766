import { useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useIntl } from "react-intl";
import { useParams } from "react-router-dom";
import { Modal, Paragraph } from "@trace-one/design-system";
import Header from "components/Header";
import Spin from "components/Spin";
import SpecificationSection from "components/SpecificationSection";
import ViewPdf from "./components/ViewPdf/ViewPdf";
import SignButton from "./components/SignButton";
import SignModal from "./components/SignModal";
import SelectedChapter from "./components/SelectedChapter";
import {
  useCurrentSectionsRefs,
  useProduct,
  useSpecificationPermissionsFromApi,
  useSpecificationStructure,
  useUserInfo,
  useModalVisibility,
  useBoolean,
  useReferenceListsItems,
  useRedirect,
} from "hooks";
import { selectUserId } from "store/user/selectors";
import {
  useMenuActions,
  useSelectedChapter,
  useSpecificationDetails,
  useModificationRequestModalActions,
} from "./hooks";
import { CHAPTER_TYPES_URL_API, STICKY_POSITION_OFFSET } from "utils/constants";
import {
  getFirstNetContent,
  getSpecificationHeaderTitle,
  getSpecificationName,
} from "utils/specifications";
import { ParamTypes } from "./types";
import { messages as modificationRequestMessages } from "messages/modificationRequest";
import styles from "./styles.module.less";

const Details = () => {
  const userId = useSelector(selectUserId);

  const [
    selectedChapterOwnerCompanyId,
    setSelectedChapterOwnerCompanyId,
  ] = useState(null);

  const { value: isTargetMode, onToggle: onTargetModeToggle } = useBoolean(
    false
  );

  const { specificationId, sectionCode } = useParams<ParamTypes>();
  const { formatMessage } = useIntl();

  const {
    specification,
    companyName,
    isLoading,
    refetchSpecification,
    companiesDetail,
  } = useSpecificationDetails({
    specificationId,
  });
  const { redirectToSpecificationListing } = useRedirect();

  const {
    supplierId,
    ownerCompanyId,
    ownerUserId,
    progression,
    state,
    productTypeId,
    version,
    ownerContacts,
    supplierContacts,
    requirementIds,
    fileIds,
  } = specification;

  const isRetailerContact =
    (ownerContacts ?? []).findIndex(contact => contact.userId === userId) > -1;
  const isSupplierContact =
    (supplierContacts ?? []).findIndex(contact => contact.userId === userId) >
    -1;

  const {
    permissionList,
    fetchSpecificationPermissions,
  } = useSpecificationPermissionsFromApi({
    specificationId,
    state,
  });

  const refetchSpecificationInformation = () => {
    refetchSpecification();
    fetchSpecificationPermissions();
  };

  const { productData } = useProduct({ productId: specification.tradeItemId });

  const { refListDictionary } = useReferenceListsItems({
    refListIds:
      productData?.netContents?.map(({ unitOfMeasure }) => unitOfMeasure) || [],
  });

  const firstNetContent = getFirstNetContent({
    netContents: productData?.netContents,
    refListDictionary,
  });

  const {
    isLoading: isStructureLoading,
    specificationStructure,
    updateChapterInformation,
  } = useSpecificationStructure({
    specificationId,
    productTypeId: specification.productTypeId,
  });

  const { selectedChapter } = useSelectedChapter({
    specificationStructure,
    chapterType: CHAPTER_TYPES_URL_API[sectionCode],
  });

  const { isRetailer } = useUserInfo();

  const {
    selectedSubSectionId,
    currentSectionsRefs,
    onUpdateSelectedSectionId,
  } = useCurrentSectionsRefs({
    sections: selectedChapter?.sections,
  });

  const { onClickSection, onClickSubSection } = useMenuActions({
    specificationId,
    chapterType: CHAPTER_TYPES_URL_API[sectionCode],
    onUpdateSelectedSectionId,
    refetchSpecificationInformation,
  });

  const {
    isModificationRequestNotifyModalOpen,
    onOpenModificationRequestNotifyModal,
    onCloseModificationRequestNotifyModal,
    onSaveAndNotify,
    onKeepAsDraft,
    isNotifying,
  } = useModificationRequestModalActions({ onTargetModeToggle });

  const { isModalOpen, onOpenModal, onCloseModal } = useModalVisibility();

  const specificationName = useMemo(
    () => getSpecificationName(productData?.itemName, version?.number),
    [productData?.itemName, version?.number]
  );
  const specificationHeaderTitle = useMemo(
    () =>
      getSpecificationHeaderTitle({
        tradeItemName: productData?.itemName,
        netContent: firstNetContent,
      }),
    [productData?.itemName, firstNetContent]
  );

  const retailerCompnayId = ownerCompanyId;
  const supplierCompnayId = supplierId;

  if (isLoading) {
    return <Spin />;
  }

  const menuExtraActions = (isMinified: boolean) => (
    <div
      className={
        isMinified ? styles.menuExtraActionsMinified : styles.menuExtraActions
      }
    >
      <SignButton
        isRetailer={isRetailer}
        handleOpenModal={onOpenModal}
        permissionList={permissionList}
        specificationState={state}
        isTargetMode={isTargetMode}
      />
      <ViewPdf
        specificationId={specification?.id}
        state={state}
        tradeItemName={productData?.itemName}
        signature={specification?.signature}
        disabled={isTargetMode}
      />
    </div>
  );

  return (
    <>
      <Header.Specification
        displayActions
        id={specificationId}
        progression={progression}
        state={state}
        specificationName={specificationHeaderTitle}
        productTypeId={productTypeId}
        productData={productData}
        supplierId={supplierId}
        ownerCompanyId={ownerCompanyId}
        version={version}
        ownerContacts={ownerContacts}
        supplierContacts={supplierContacts}
        selectedChapterType={CHAPTER_TYPES_URL_API[sectionCode]}
        selectedChapterOwnerCompanyId={selectedChapterOwnerCompanyId}
        isSelectedChapterLibraryChapter={selectedChapter?.isChapterLibrary}
        isSelectedChapterLinked={selectedChapter?.isLinked}
        isTargetMode={isTargetMode}
        onTargetModeToggle={onTargetModeToggle}
        onOpenModificationRequestNotifyModal={
          onOpenModificationRequestNotifyModal
        }
        onBackClick={redirectToSpecificationListing}
        permissionList={permissionList}
        {...(isRetailer
          ? {
              supplierName: companyName,
            }
          : {
              ownerCompanyName: companyName,
            })}
        companiesDetail={companiesDetail}
        specificationStructure={specificationStructure}
      />

      <div className={styles["spec-content__wrapper"]}>
        {isStructureLoading ? (
          <Spin className="mt-2" />
        ) : (
          <SpecificationSection.Content
            sections={specificationStructure}
            selectedSectionId={selectedChapter?.sectionId}
            selectedSubSectionId={selectedSubSectionId}
            onClickSection={onClickSection}
            onClickSubSection={onClickSubSection}
            menuExtraActions={menuExtraActions}
            specificationId={specificationId}
            selectedChapter={selectedChapter}
            isSpecContact={isRetailerContact || isSupplierContact}
            userId={userId}
            headerHeight={STICKY_POSITION_OFFSET}
            retailerCompnayId={retailerCompnayId}
            supplierCompnayId={supplierCompnayId}
          >
            <SelectedChapter
              selectedChapter={selectedChapter}
              specificationId={specificationId}
              specificationName={specificationName}
              specificationHeaderTitle={specificationHeaderTitle}
              supplierId={supplierId}
              ownerCompanyId={ownerCompanyId}
              ownerUserId={ownerUserId}
              specificationStructure={specificationStructure}
              currentSectionsRefs={currentSectionsRefs}
              updateChapterInformation={updateChapterInformation}
              productTypeId={specification.productTypeId}
              productData={productData}
              permissionList={permissionList}
              refetchSpecificationInformation={refetchSpecificationInformation}
              requirementIds={requirementIds}
              fileIds={fileIds}
              specificationState={state}
              setSelectedChapterOwnerCompanyId={
                setSelectedChapterOwnerCompanyId
              }
              isTargetMode={isTargetMode}
              isSpecContact={isRetailerContact || isSupplierContact}
              isRetailerContact={isRetailerContact}
              retailerCompnayId={retailerCompnayId}
              supplierCompnayId={supplierCompnayId}
              supplierContacts={supplierContacts}
            />
          </SpecificationSection.Content>
        )}
      </div>
      {isModalOpen && (
        <SignModal
          companyName={companyName}
          tradeItem={productData?.itemName}
          onClose={onCloseModal}
          specificationId={specification?.id}
          refetchSpecificationInformation={refetchSpecificationInformation}
        />
      )}

      {isModificationRequestNotifyModalOpen && (
        <Modal.Simple
          open
          size="s"
          onCancel={onCloseModificationRequestNotifyModal}
          illustrationName="settings"
          title={formatMessage(
            modificationRequestMessages.modificationRequestNotifyModalTitle
          )}
          illustrationColor="grey"
          onPrimaryButtonClick={onSaveAndNotify}
          primaryButtonText={formatMessage(
            modificationRequestMessages.modificationRequestNotifyModalPrimaryBtnLabel
          )}
          primaryButtonProps={{ loading: isNotifying }}
          onSecondaryButtonClick={onKeepAsDraft}
          secondaryButtonText={formatMessage(
            modificationRequestMessages.modificationRequestNotifyModalSecondaryBtnLabel
          )}
          secondaryButtonProps={{ disabled: isNotifying }}
        >
          <Paragraph>
            {formatMessage(
              modificationRequestMessages.modificationRequestNotifyModalDescription
            )}
          </Paragraph>
          <br />
          <Paragraph>
            {formatMessage(
              modificationRequestMessages.modificationRequestNotifyModalStartOverDescription
            )}
          </Paragraph>
        </Modal.Simple>
      )}
    </>
  );
};

export default Details;
