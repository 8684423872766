import { useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import { useBoolean } from "hooks";
import {
  createLabelingTranslations,
  createSpecificationCollaboration,
  deleteLabelingTranslations,
  deleteSpecificationCollaboration,
  fetchSpecificationContacts,
  fetchSpecificationTranslators,
  fetchTemplateById,
  startLabelingTranslations,
  updateSpecificationTranslators,
} from "apis/SPEC";
import { useHideSection, useUnhideSection } from "apis/SPEC/templates";
import { selectLanguageCode } from "store/user/selectors";
import {
  AddTranslatorBody,
  SpecificationData,
  SpecificationLabelingTranslationData,
  TemplateData,
  TemplateSectionData,
  TranslatorData,
} from "models";
import { isListEmpty } from "utils/general";
import { selectTranslationManagement } from "store/specificationDetails/selectors";
import { CompanyLangaugeBlock } from "components/TranslationManagement/types";
import { useAppDispatch } from "store";
import { fetchCompanies } from "apis/CUMD";
import { setTranslationManagement } from "store/specificationDetails/specificationDetailsSlice";
import { SpecificationContactType } from "utils/constants";
import { v4 } from "uuid";
import { selectCollaborationData } from "store/collaboration/selectors";

export type UseTranslationManagementProps = {
  specificationId?: SpecificationData["id"];
  onCloseTranslationMgmtOverlay?: () => void;
  translationTemplate?: TemplateData;
  setTranslationTemplate?: (_: TemplateData) => void;
  previousTranslationTemplate?: TemplateData;
  setPreviousTranslationTemplate?: Function;
  shouldDeleteOnCancel?: boolean;
  setShouldDeleteOnCancel?: (_: boolean) => void;
  translationTemplateId?: TemplateData["generalInfo"]["id"];
  setSpecificationLabelingTranslationData?: (
    _: SpecificationLabelingTranslationData
  ) => void;
};

const useTranslationManagement = ({
  specificationId,
  onCloseTranslationMgmtOverlay,
  translationTemplate,
  setTranslationTemplate,
  previousTranslationTemplate,
  setPreviousTranslationTemplate,
  shouldDeleteOnCancel,
  setShouldDeleteOnCancel,
  translationTemplateId,
  setSpecificationLabelingTranslationData,
}: UseTranslationManagementProps) => {
  const languageCode = useSelector(selectLanguageCode);
  const translationManagement = useSelector(selectTranslationManagement);
  const {
    companies,
    collaborations,
    existingCollaborations,
    existingCompanyAndCollaborationsMap,
  } = translationManagement;

  const collaborationData = useSelector(selectCollaborationData);

  const dispatch = useAppDispatch();

  const [hideSection] = useHideSection();
  const [unhideSection] = useUnhideSection();

  const { isChecked, isIndeterminate } = useMemo(() => {
    const visibleCounts = translationTemplate?.chapters?.reduce(
      (acc, chapter) => {
        chapter.sections.forEach(section => {
          if (section.visible) {
            acc.visible++;
          } else {
            acc.hidden++;
          }
        });
        return acc;
      },
      { visible: 0, hidden: 0 }
    );

    return {
      isChecked: visibleCounts?.hidden === 0 && visibleCounts?.visible > 0,
      isIndeterminate: visibleCounts?.visible > 0 && visibleCounts?.hidden > 0,
    };
  }, [JSON.stringify(translationTemplate)]);

  const {
    value: isTemplateLoading,
    setTrue: setLoadingTrue,
    setFalse: setLoadingFalse,
  } = useBoolean(false);

  const {
    value: isSaving,
    setTrue: setSavingTrue,
    setFalse: setSavingFalse,
  } = useBoolean(false);

  const {
    value: isCancelling,
    setTrue: setCancellingTrue,
    setFalse: setCancellingFalse,
  } = useBoolean(false);

  const {
    value: isStartingTranslations,
    setTrue: setStartTranslationsTrue,
    setFalse: setStartTranslationsFalse,
  } = useBoolean(false);

  const updateSectionVisibility = (
    section: TemplateSectionData,
    sectionId: TemplateSectionData["id"],
    visible: TemplateSectionData["visible"]
  ): TemplateSectionData => ({
    ...section,
    visible: section.id === sectionId ? visible : section.visible,
  });

  const updateChapterSection = (
    chapter: TemplateSectionData,
    sectionId: TemplateSectionData["id"],
    visible: TemplateSectionData["visible"]
  ): TemplateSectionData => ({
    ...chapter,
    sections: chapter.sections.map(section =>
      updateSectionVisibility(section, sectionId, visible)
    ),
  });

  const updateAllSectionsVisibility = (
    section: TemplateSectionData,
    visible: TemplateSectionData["visible"]
  ): TemplateSectionData => ({
    ...section,
    visible,
  });

  const updateAllChapterSections = (
    chapter: TemplateSectionData,
    visible: TemplateSectionData["visible"]
  ): TemplateSectionData => ({
    ...chapter,
    sections: chapter.sections.map(section =>
      updateAllSectionsVisibility(section, visible)
    ),
  });

  useEffect(() => {
    if (!isListEmpty(collaborationData)) {
      const companyAndCollaborationsMapLocal = collaborationData.reduce(
        (acc, collaboration) => {
          const key = collaboration.collaboratingCompanyId;

          const collaborationId = collaboration.id;
          const collaborationValues = acc[key];

          if (collaborationId) {
            return {
              ...acc,
              [key]: collaborationValues
                ? [...collaborationValues, collaborationId]
                : [collaborationId],
            };
          }

          return acc;
        },
        {}
      );

      const updatedCompanies = companies?.map(currentBlock => {
        const collaborations =
          companyAndCollaborationsMapLocal[currentBlock?.company?.value];

        if (collaborations) {
          return {
            ...currentBlock,
            collaborations,
          };
        }

        return currentBlock;
      });

      dispatch(
        setTranslationManagement({
          ...translationManagement,
          companies: updatedCompanies,
          ...(existingCompanyAndCollaborationsMap
            ? {}
            : {
                existingCompanyAndCollaborationsMap: companyAndCollaborationsMapLocal,
              }),
        })
      );
    }
  }, [collaborationData]);

  const buildTranslatorsBlock = async (translatorData: TranslatorData[]) => {
    if (isListEmpty(translatorData)) {
      return;
    }

    try {
      // Get companies
      const { data: companies } = await fetchCompanies({
        ids: translatorData.map(({ companyId }) => companyId),
      });

      // Get collaboration contacts
      const { data: contacts } = await fetchSpecificationContacts({
        specificationId,
      });

      const collaborations = contacts
        ?.filter(
          contact =>
            contact.collaborationId &&
            contact.contactType === SpecificationContactType.TRANSLATOR
        )
        .map(contact => contact.collaborationId);

      const companiesUIBlocks: CompanyLangaugeBlock[] = translatorData.map(
        ({ companyId, languageCodes }) => {
          const company = companies.find(
            company => company.companyId === companyId
          );

          return {
            key: v4(),
            company: {
              key: company.companyId,
              value: company.companyId,
              label: company.companyDisplayName,
            },
            languages: languageCodes,
            collaborations: [],
          };
        }
      );

      dispatch(
        setTranslationManagement({
          companies: companiesUIBlocks,
          collaborations,
          existingCollaborations: collaborations,
        })
      );
    } catch (_) {}
  };

  const onOpenOverlay = async () => {
    try {
      setLoadingTrue();

      let translationTemplateData: TemplateData;

      if (translationTemplateId) {
        const { data } = await fetchTemplateById({
          templateId: translationTemplateId,
          languageCode,
        });

        translationTemplateData = data;
        setShouldDeleteOnCancel(false);
      } else {
        const {
          data: labelingTranslationsData,
        } = await createLabelingTranslations({
          specificationId,
        });

        const { data: createdData } = await fetchTemplateById({
          templateId: labelingTranslationsData?.templateId,
          languageCode,
        });

        translationTemplateData = createdData;
        setSpecificationLabelingTranslationData(labelingTranslationsData);
        setShouldDeleteOnCancel(true);
      }

      setPreviousTranslationTemplate(translationTemplateData);
      setTranslationTemplate(translationTemplateData);

      const { data: translatorData } = await fetchSpecificationTranslators({
        specificationId,
      });

      await buildTranslatorsBlock(translatorData);
    } catch {
    } finally {
      setLoadingFalse();
    }
  };

  const onSaveTemplate = async () => {
    const { generalInfo } = translationTemplate;

    let updatedSectionCodes = {};

    if (!isListEmpty(translationTemplate?.chapters)) {
      for (const chapter of translationTemplate.chapters) {
        if (!isListEmpty(chapter.sections)) {
          for (const section of chapter.sections) {
            const previousChapter = previousTranslationTemplate?.chapters?.find(
              prevChapter => prevChapter.chapterType === chapter.chapterType
            );
            const previousSection = previousChapter?.sections?.find(
              prevSection => prevSection.code === section.code
            );

            if (
              previousSection &&
              section.visible !== previousSection.visible
            ) {
              const action = section.visible ? unhideSection : hideSection;
              await action({
                templateId: generalInfo?.id,
                payload: {
                  chapterType: chapter?.chapterType,
                  sectionId: section?.id,
                },
              });

              updatedSectionCodes = {
                ...updatedSectionCodes,
                [section.code]: section.visible,
              };
            }
          }
        }
      }
    }

    const updatedSections = previousTranslationTemplate.chapters[0].sections.map(
      section => {
        return {
          ...section,
          visible: updatedSectionCodes[section.code] ?? section.visible,
        };
      }
    );

    setPreviousTranslationTemplate(prevState => ({
      ...prevState,
      chapters: [{ ...prevState.chapters[0], sections: updatedSections }],
    }));
  };

  const resetCompaniesAndCollaboration = () => {
    dispatch(
      setTranslationManagement({
        companies: [
          {
            company: null,
            languages: [],
            collaborations: [],
          },
        ],
        collaborations: [],
        existingCollaborations: [],
      })
    );
  };

  useEffect(() => {
    return () => {
      resetCompaniesAndCollaboration();
    };
  }, [specificationId]);

  const onSave = async (withLoading: boolean = true) => {
    try {
      if (withLoading) {
        setSavingTrue();
      }

      await onSaveTemplate();
      await saveCompaniesAndCollaboration();

      setSavingFalse();

      if (withLoading) {
        resetCompaniesAndCollaboration();
        onCloseTranslationMgmtOverlay();
      }
    } catch (error) {
      setSavingFalse();
      throw error;
    }
  };

  const onCancel = async () => {
    try {
      if (shouldDeleteOnCancel) {
        setCancellingTrue();
        await deleteLabelingTranslations({ specificationId });
        setSpecificationLabelingTranslationData?.(null);
      }

      resetCompaniesAndCollaboration();
      setTranslationTemplate?.(null);
      setCancellingFalse();
      onCloseTranslationMgmtOverlay();
    } catch (error) {
      setCancellingFalse();
      throw error;
    }
  };

  const onClose = async () => {
    try {
      if (shouldDeleteOnCancel) {
        await deleteLabelingTranslations({ specificationId });
        setSpecificationLabelingTranslationData?.(null);
      }

      setTranslationTemplate?.(null);
      onCloseTranslationMgmtOverlay();
    } catch {}
  };

  const onHideChapterSection = (sectionId: string) => {
    if (!translationTemplate?.generalInfo?.id || !sectionId) {
      return;
    }

    const updatedChapters = translationTemplate?.chapters?.map(chapter =>
      updateChapterSection(chapter, sectionId, false)
    );

    const updatedTemplate: TemplateData = {
      ...translationTemplate,
      chapters: updatedChapters,
    };

    setTranslationTemplate(updatedTemplate);
  };

  const onUnhideChapterSection = (sectionId: string) => {
    if (!translationTemplate?.generalInfo?.id || !sectionId) {
      return;
    }

    const updatedChapters = translationTemplate?.chapters?.map(chapter =>
      updateChapterSection(chapter, sectionId, true)
    );

    const updatedTemplate: TemplateData = {
      ...translationTemplate,
      chapters: updatedChapters,
    };

    setTranslationTemplate(updatedTemplate);
  };

  const onSetAllChapterSectionsVisibility = (visible: boolean) => {
    if (!translationTemplate?.generalInfo?.id) {
      return;
    }

    const updatedChapters = translationTemplate?.chapters?.map(chapter =>
      updateAllChapterSections(chapter, visible)
    );

    const updatedTemplate: TemplateData = {
      ...translationTemplate,
      chapters: updatedChapters,
    };

    setTranslationTemplate(updatedTemplate);
  };

  const isSaveTranslationDisabled = useMemo(() => {
    for (const item of companies) {
      const company = item.company;
      const languages = item.languages;
      const collaborations = item.collaborations;

      if (
        !company ||
        (company && (isListEmpty(languages) || isListEmpty(collaborations)))
      ) {
        return true;
      }
    }

    return false;
  }, [companies]);

  const saveCompaniesAndCollaboration = async () => {
    const newCollaborations = collaborations
      .filter(
        collaborationId => !existingCollaborations?.includes(collaborationId)
      )
      .map(collaborationId => ({
        collaborationId,
        contactType: SpecificationContactType.TRANSLATOR,
      }));

    const translatorCompaniesWithLanguages: AddTranslatorBody[] = companies?.reduce(
      (acc, companyBlock: CompanyLangaugeBlock) => {
        return [
          ...acc,
          {
            companyId: companyBlock?.company?.value,
            languageCodes: companyBlock?.languages,
          },
        ];
      },
      []
    );

    const existingCompaniesIds = Object.keys(
      existingCompanyAndCollaborationsMap || {}
    );

    const deletedCompaniesIds = existingCompaniesIds?.filter(companyId => {
      return !translatorCompaniesWithLanguages
        ?.map(translatorCompany => translatorCompany.companyId)
        ?.includes(companyId);
    });

    const alreadyDeletedCollaborationsIds = deletedCompaniesIds
      ?.map(id => existingCompanyAndCollaborationsMap[id])
      ?.flat();

    const collaborationsToDelete = existingCollaborations?.filter(
      collaborationId =>
        !collaborations.includes(collaborationId) &&
        !alreadyDeletedCollaborationsIds.includes(collaborationId)
    );

    try {
      await updateSpecificationTranslators({
        specificationId,
        payload: translatorCompaniesWithLanguages,
      });

      if (!isListEmpty(collaborationsToDelete)) {
        await Promise.all(
          collaborationsToDelete.map(async collaborationId => {
            await deleteSpecificationCollaboration({
              specificationId,
              collaborationId,
            });
          })
        );
      }

      if (!isListEmpty(newCollaborations)) {
        await createSpecificationCollaboration({
          specificationId,
          payload: newCollaborations,
        });
      }
    } catch (error) {
      throw error;
    }
  };

  const onStartTranslations = async () => {
    try {
      setStartTranslationsTrue();

      await onSave(false);

      const { data } = await startLabelingTranslations({
        specificationId,
      });

      setStartTranslationsFalse();
      setSpecificationLabelingTranslationData(data);
      setTranslationTemplate?.(null);
      resetCompaniesAndCollaboration();
      onCloseTranslationMgmtOverlay();
    } catch (error) {
      setStartTranslationsFalse();
      throw error;
    }
  };

  return {
    isTemplateLoading,
    onOpenOverlay,
    isCancelling,
    onCancel,
    isSaving,
    onSave,
    onHideChapterSection,
    onUnhideChapterSection,
    onSetAllChapterSectionsVisibility,
    isChecked,
    isIndeterminate,
    isSaveTranslationDisabled,
    onClose,
    isStartingTranslations,
    onStartTranslations,
  };
};

export default useTranslationManagement;
