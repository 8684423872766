import { useIntl } from "react-intl";
import { Col, Row } from "antd";
import { Typography } from "@trace-one/design-system";
import Quickview, { QuickViewType } from "components/Quickview";
import {
  fetchChapterQuickView,
  fetchClaimQuickView,
  fetchRequirementQuickView,
} from "apis/SPEC";
import { LIBRARY_OBJECT_TYPES } from "utils/constants";
import { getLibraryItemApiParams } from "utils/library";
import { LibraryItemApiType } from "types/library";
import { GeneralInfoData, QuickViewTradeItemData } from "models";
import { generalMessages, specificationMessages } from "messages";

export type LibraryItemQuickViewProps = {
  libraryItemId: GeneralInfoData["id"];
  libraryItemType: LibraryItemApiType;
  shouldRefetch?: boolean;
};

const FetchApiMapping = {
  [LIBRARY_OBJECT_TYPES.PRODUCTION]: fetchChapterQuickView,
  [LIBRARY_OBJECT_TYPES.TESTING]: fetchChapterQuickView,
  [LIBRARY_OBJECT_TYPES.FINISHED_PRODUCTS]: fetchChapterQuickView,
  [LIBRARY_OBJECT_TYPES.TEXT_REQUIREMENTS]: fetchRequirementQuickView,
  [LIBRARY_OBJECT_TYPES.DOCUMENT_REQUIREMENTS]: fetchRequirementQuickView,
  [LIBRARY_OBJECT_TYPES.MARKETING_CLAIMS]: fetchClaimQuickView,
  [LIBRARY_OBJECT_TYPES.HEALTH_CLAIMS]: fetchClaimQuickView,
};

const QuickViewTypeMapping = {
  [LIBRARY_OBJECT_TYPES.PRODUCTION]: QuickViewType.CHAPTER,
  [LIBRARY_OBJECT_TYPES.TESTING]: QuickViewType.CHAPTER,
  [LIBRARY_OBJECT_TYPES.FINISHED_PRODUCTS]: QuickViewType.CHAPTER,
  [LIBRARY_OBJECT_TYPES.TEXT_REQUIREMENTS]: QuickViewType.REQUIREMENT,
  [LIBRARY_OBJECT_TYPES.DOCUMENT_REQUIREMENTS]: QuickViewType.REQUIREMENT,
  [LIBRARY_OBJECT_TYPES.MARKETING_CLAIMS]: QuickViewType.CLAIM,
  [LIBRARY_OBJECT_TYPES.HEALTH_CLAIMS]: QuickViewType.CLAIM,
};

const LibraryItemQuickView: React.FC<LibraryItemQuickViewProps> = ({
  libraryItemId,
  libraryItemType,
  shouldRefetch = false,
}) => {
  const { formatMessage } = useIntl();

  const fetchQuickView = () =>
    FetchApiMapping[libraryItemType](
      getLibraryItemApiParams({ libraryItemId, libraryItemType })
    );

  const quickViewHeader = (numberOfLinkedSpecs: number) => (
    <Row align="middle" gutter={[8, 0]}>
      <Col>
        <Typography variant="body-regular">
          {formatMessage(specificationMessages.linkedSpecification)}
        </Typography>
      </Col>
      <Col>
        <Typography variant="body-s" color="grey-4">
          {`${numberOfLinkedSpecs} ${formatMessage(
            numberOfLinkedSpecs === 1
              ? generalMessages.link
              : generalMessages.links
          ).toLocaleLowerCase()}`}
        </Typography>
      </Col>
    </Row>
  );

  const quickViewBody = (items: QuickViewTradeItemData[]) =>
    items.map(item => (
      <Quickview.SpecificationRow
        type={QuickViewType.CLAIM}
        key={item.tradeItemId}
        specification={item.tradeItemSpecifications[0]}
        title={<Quickview.ChapterTitle tradeItem={item} />}
      />
    ));

  return (
    <Quickview.Specification
      type={QuickViewTypeMapping[libraryItemType]}
      fetcher={fetchQuickView}
      header={quickViewHeader}
      body={quickViewBody}
      shouldRefetch={shouldRefetch}
    />
  );
};

export default LibraryItemQuickView;
