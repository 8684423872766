import { useState, useEffect, ChangeEvent } from "react";
import { useSelector } from "react-redux";
import { useDebounce } from "@trace-one/business-components";
import { fetchAllNutrients, searchNutrients } from "apis/CATALOGUE";
import { selectLanguageCode } from "store/user/selectors";
import { useBoolean } from "hooks";
import { NutrientFamilyData, NutritionFormData } from "models";
import { isListEmpty } from "utils/general";
import { TreeNode } from "types/general";
import { prepareCheckableTreeData, collectDataFromTreeNode } from "./utils";
import { numberOfCharactersForAutoComplete } from "utils/constants";
import { EnhancedExtraActions } from "components/SpecificationSection/types";

export type UseAddNutritionProps = {
  defaultCheckedItems: string[];
  addNutritionAction: EnhancedExtraActions["updateNutrition"];
  formData: NutritionFormData;
};

const useAddNutrition = ({
  defaultCheckedItems,
  addNutritionAction,
  formData,
}: UseAddNutritionProps) => {
  const languageCode = useSelector(selectLanguageCode);
  const [searchText, setSearchText] = useState(undefined);

  const [allNutrientsTree, setAllNutrientsTree] = useState<TreeNode[]>([]);
  const [rawNutrientsData, setRawNutrientsData] = useState<
    NutrientFamilyData[]
  >([]);
  const [isAddNutrientModalOpen, setIsAddNutrientModalOpen] = useState(false);
  const { value: addingNutrients, setTrue, setFalse } = useBoolean();

  const [newlySelectedItems, setNewlySelectedItems] = useState<string[]>([]);
  const [disabledNutrientIds, setDisabledNutrientIds] = useState<string[]>([]);

  const [isLoading, setIsLoading] = useState(false);

  const debouncedSearchText = useDebounce(searchText, 500);

  const handleOpenAddNutrientModal = async () => {
    setIsAddNutrientModalOpen(true);

    if (isListEmpty(rawNutrientsData)) {
      try {
        setIsLoading(true);

        const { data } = await fetchAllNutrients({ languageCode });

        setRawNutrientsData(data);
      } catch (_) {
      } finally {
        setIsLoading(false);
      }
    }
  };

  const handleSearch = ({
    target: { value },
  }: ChangeEvent<HTMLInputElement>) => {
    setSearchText(value);
  };

  const getNutrientsList = async () => {
    try {
      setIsLoading(true);

      const { data } = await searchNutrients({
        languageCode: languageCode,
        searchTerm: debouncedSearchText,
      });

      setRawNutrientsData(data);
    } catch (_) {
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (typeof debouncedSearchText === "undefined") {
      return;
    }

    if (
      debouncedSearchText.length === 0 ||
      debouncedSearchText.length >= numberOfCharactersForAutoComplete
    ) {
      getNutrientsList();
    }
  }, [debouncedSearchText]);

  useEffect(() => {
    if (!isListEmpty(rawNutrientsData)) {
      const nutrientsTree = prepareCheckableTreeData(
        rawNutrientsData,
        defaultCheckedItems
      );

      const { checkedNutrientIds } = collectDataFromTreeNode(nutrientsTree);

      setDisabledNutrientIds(checkedNutrientIds);
      setAllNutrientsTree(nutrientsTree);
    } else {
      setAllNutrientsTree([]);
    }
  }, [rawNutrientsData, defaultCheckedItems]);

  const handleAddNutrients = async () => {
    try {
      setTrue();

      const newlyAddedNutrients = newlySelectedItems.map(nutrientId => ({
        nutrientId,
      }));

      await addNutritionAction({
        ...formData,
        nutrients: [...(formData.nutrients || []), ...newlyAddedNutrients],
      });
    } catch {
    } finally {
      setFalse();
      handleCloseModal();
    }
  };

  const handleCloseModal = () => {
    setIsAddNutrientModalOpen(false);
    setNewlySelectedItems([]);
    setRawNutrientsData([]);
    setSearchText(undefined);
  };

  const handleCheck = (_, e) => {
    const newItems = e.checkedNodes.reduce(
      (previousValue, { key, checkable }) => {
        if (!defaultCheckedItems?.includes(key) && checkable) {
          previousValue = [...previousValue, key];
        }

        return previousValue;
      },
      []
    );

    setNewlySelectedItems(newItems);
  };

  return {
    handleOpenAddNutrientModal,
    handleCloseModal,
    handleAddNutrients,
    handleCheck,
    handleSearch,
    allNutrientsTree,
    isAddNutrientModalOpen,
    disabledNutrientIds,
    newlySelectedItems,
    isLoading,
    addingNutrients,
  };
};

export default useAddNutrition;
