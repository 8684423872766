import {
  AGECRegulationsInfo,
  EURegulationsInfo,
  PackagingSystemComponentData,
  PackagingSystemData,
  ReachRegulationsInfo,
} from "models";
import { PackagingSystemViewModel } from "viewModels";
import { LIBRARY_OBJECT_STATES } from "utils/constants";
import { getDictionary, isObjectEmpty } from "utils/general";

export const preparePackagingSystemFromApiData = (
  packagingSystemData: PackagingSystemData
) => {
  let packagingSystem: PackagingSystemViewModel = {};

  packagingSystem.generalInformation = {
    tagIds: packagingSystemData?.tagIds,
    name: packagingSystemData?.name,
    packagingSystemId: packagingSystemData?.packagingSystemId,
    version: packagingSystemData?.version,
    state: packagingSystemData?.allowsUpdate
      ? null
      : LIBRARY_OBJECT_STATES.LOCKED,
    components: packagingSystemData?.components,
    sections: packagingSystemData?.sections,
    mainComponentId: packagingSystemData?.mainComponentId,
  };

  packagingSystem.components = {
    packagingSystemId: packagingSystemData?.packagingSystemId,
    allowsUpdate: packagingSystemData?.allowsUpdate,
  };

  packagingSystem.componentLinks = packagingSystemData?.componentLinks;

  return packagingSystem;
};

export const preparePackagingComponents = (
  packagingComponentData: PackagingSystemComponentData[]
) => {
  return packagingComponentData.map(component => ({
    name: component?.name,
    componentId: component?.componentId,
    componentTypeId: component?.componentTypeId,
    version: component?.version?.number,
    usageLevel: component?.usageLevel,
    materialId: component?.materialId,
    percentageOfRecycledMaterials:
      component?.recyclability?.percentageOfRecycledMaterials,
  }));
};

export const prepareRecyclabilityRegulationsInfoForDictionary = ({
  regulationsInfo,
  regulationNameKey,
}: {
  regulationsInfo:
    | ReachRegulationsInfo
    | AGECRegulationsInfo
    | EURegulationsInfo;
  regulationNameKey: string;
}) => {
  if (isObjectEmpty(regulationsInfo)) {
    return null;
  }

  const result = {};

  if (regulationNameKey) {
    result[regulationNameKey] = regulationsInfo.name;
  }

  const propertiesDictionary = getDictionary({
    data: regulationsInfo.properties,
    key: "name",
    value: "description",
  });

  return { ...result, ...propertiesDictionary };
};
