import { useMemo } from "react";
import { Row, Col, Form } from "antd";
import { EmptyState } from "@trace-one/design-system";
import { useIntl } from "react-intl";
import { isListEmpty } from "utils/general";
import { useReferenceListsItems } from "hooks";
import InfiniteScrollSelect from "components/InfiniteScrollSelect";
import { SiteWidget } from "components/SpecificationSection/components/FormSchema/widgets";
import { useSitesArray, useSiteActions } from "./hooks";
import { messages } from "./messages";
import { ObjectFieldTemplateProps } from "components/SpecificationSection/types";
import { SitesFormData } from "../../../types";
import styles from "../styles.module.less";

interface SitesArrayFieldTemplateProps
  extends Partial<ObjectFieldTemplateProps<SitesFormData>> {
  noItemsMessage?: string;
}

const SitesArrayFieldTemplate: React.FC<SitesArrayFieldTemplateProps> = ({
  schema,
  formContext,
}) => {
  const { formatMessage } = useIntl();

  const { propertyId } = schema;
  const {
    displayActions,
    isWidgetEditable,
    specificationData,
    formData,
    saveTemplateBlock,
  } = formContext;

  const { siteIds = [] } = (formData as unknown) as SitesFormData;

  const colStyles = {
    xs: displayActions ? 24 : 12,
    sm: displayActions ? 24 : 12,
    md: 12,
    lg: 8,
  };

  const hasUpdatePermission = useMemo(() => isWidgetEditable(propertyId), [
    propertyId,
  ]);

  const {
    addedSites,
    siteOptions,
    handleDropdownVisibleChange,
    hasSitesError,
    isSitesLoading,
    getSites,
  } = useSitesArray({
    siteIds,
    specificationData,
  });

  const {
    handleAddSite,
    isUpdateActionDisabled,
    getSiteActions,
  } = useSiteActions({
    displayActions,
    hasUpdatePermission,
    formData: (formData as unknown) as SitesFormData,
    saveTemplateBlock,
  });

  const { refListDictionary } = useReferenceListsItems({
    refListIds: addedSites.flatMap(({ siteFunctions }) => siteFunctions),
  });

  return (
    <Row gutter={[0, 30]}>
      <Col span={24}>
        <Row gutter={[30, 30]}>
          {!isListEmpty(addedSites) ? (
            addedSites.map(site => (
              <Col {...colStyles} key={site.id}>
                <SiteWidget
                  refListDictionary={refListDictionary}
                  siteData={{
                    ...site,
                    actions: getSiteActions(site),
                  }}
                />
              </Col>
            ))
          ) : (
            <Col span={24}>
              <Row gutter={[0, 30]} justify="center">
                <EmptyState heading={formatMessage(messages.noSiteAdded)} />
              </Row>
            </Col>
          )}
        </Row>
      </Col>
      {displayActions ? (
        <Col span={24}>
          <Row>
            <Col xs={24} lg={12}>
              <Form.Item
                className={styles.selectLabelWrapper}
                colon={false}
                labelAlign="left"
                label={formatMessage(messages.addASite)}
              >
                <InfiniteScrollSelect
                  placeholder={formatMessage(messages.chooseASite)}
                  disabled={isUpdateActionDisabled}
                  loading={isSitesLoading}
                  notFoundMessage={formatMessage(messages.noSite)}
                  value={null}
                  onSelect={handleAddSite}
                  options={siteOptions}
                  onDropdownVisibleChange={handleDropdownVisibleChange}
                  {...(hasSitesError && {
                    errorMessage: formatMessage(messages.siteError),
                    error: true,
                  })}
                  next={getSites}
                />
              </Form.Item>
            </Col>
          </Row>
        </Col>
      ) : null}
    </Row>
  );
};

export default SitesArrayFieldTemplate;
