import { useIntl } from "react-intl";
import { useParams } from "react-router-dom";
import { PageHeader } from "@trace-one/design-system";
import DeleteVersionModal from "components/DeleteVersionModal";
import {
  useGoBack,
  useLibraryItemActions,
  useLibraryItemPermissions,
  useLibraryItemTypeDictionary,
  useModalVisibility,
  useRedirect,
  useTag,
  useUserPermission,
} from "hooks";
import { useTabNavigation } from "./hooks";
import { LibraryDetails } from "./contexts";
import { prepareLibraryItemActionItem } from "dtos/libraryItem";
import {
  CHAPTER_TYPES_API,
  LIBRARY_OBJECT_API_TYPES,
  LIBRARY_TAB_KEYS,
} from "utils/constants";
import { getNameWithVersion, isListEmpty } from "utils/general";
import {
  isLibraryObjectChapterSubType,
  isLibraryObjectClaimType,
  isLibraryObjectLocked,
  isLibraryObjectRequirement,
  isLibraryObjectTemplatesType,
} from "utils/library";
import { isClaimTypeNutritional } from "utils/claim";
import { onTabChange } from "pages/Library/utils";
import { getLibraryItemInfo } from "./utils";
import { LibraryDetailsProps } from "./types";
import { ParamTypes, goBackParams } from "../types";
import messages from "messages/library";
import { usePageHeadingInformation } from "pages/Library/hooks";
import { generalMessages } from "messages";
import { useEffect } from "react";
import styles from "./styles.module.less";

export const Details: React.FC<LibraryDetailsProps> = ({
  libraryItemDetails,
}) => {
  const { type } = useParams<ParamTypes>();
  const { formatMessage } = useIntl();

  const { redirectToLibraryDetails } = useRedirect();
  const { claimsRegulationAdministrate } = useUserPermission();
  const { goBack } = useGoBack(goBackParams[type]);
  const { hasModifyPermission } = useLibraryItemPermissions(libraryItemDetails);
  const {
    isModalOpen: isDeleteModalOpen,
    onOpenModal: openDeleteModal,
    onCloseModal: closeDeleteModal,
  } = useModalVisibility();

  const {
    edit,
    createNewVersion,
    duplicate,
    deleteUsingModal,
  } = useLibraryItemActions({
    libraryItemInfo: prepareLibraryItemActionItem(
      getLibraryItemInfo({ type, libraryItemDetails })
    ),
    openDeleteModal,
  });

  const libraryObjectState =
    libraryItemDetails.state || libraryItemDetails?.generalInfo?.state;
  const isLastVersion =
    libraryItemDetails?.generalInfo?.version?.lastVersion ||
    libraryItemDetails?.version?.lastVersion;

  const {
    actionsInformation,
    updateActionsInformation,
  } = usePageHeadingInformation(
    libraryObjectState,
    libraryItemDetails?.generalInformation?.name
  );

  const {
    tag: { label, color, mode },
  } = useTag(libraryObjectState);
  const { typeDictionary } = useLibraryItemTypeDictionary({ type });

  const { activeTab, setActiveTab, tabItems, tabContent } = useTabNavigation({
    libraryItemDetails,
    type,
  });

  const isLocked = isLibraryObjectLocked(libraryObjectState);

  const isRequirementObject = isLibraryObjectRequirement(
    libraryItemDetails.generalInformation.type
  );

  const isTemplateObject = isLibraryObjectTemplatesType(
    libraryItemDetails.generalInformation.type
  );

  const isChapterObject = isLibraryObjectChapterSubType(
    libraryItemDetails.generalInformation.type
  );

  const isClaimObject = isLibraryObjectClaimType(
    libraryItemDetails.generalInformation.type
  );

  let onPrimaryButtonClick = isLocked ? createNewVersion.onClick : edit.onClick;

  if (isChapterObject || isRequirementObject || isClaimObject) {
    onPrimaryButtonClick = () => {};
  }

  useEffect(() => {
    let actions = [];

    if (isChapterObject) {
      if (isLocked) {
        actions.push({
          name: formatMessage(messages.createNewVersion),
          label: formatMessage(messages.createNewVersion),
          onClick: createNewVersion?.onClick,
          disabled: !isLastVersion,
        });
      } else {
        actions.push({
          name: formatMessage(messages.editButton),
          label: formatMessage(messages.editButton),
          onClick: edit?.onClick,
        });
      }

      actions.push({
        name: formatMessage(generalMessages.duplicate),
        label: formatMessage(generalMessages.duplicate),
        onClick: duplicate?.onClick,
      });
    }

    if (isRequirementObject) {
      if (isLocked) {
        actions.push({
          name: formatMessage(messages.createNewVersion),
          label: formatMessage(messages.createNewVersion),
          onClick: createNewVersion?.onClick,
          disabled: !isLastVersion,
        });
      } else {
        actions.push({
          name: formatMessage(messages.editButton),
          label: formatMessage(messages.editButton),
          onClick: edit?.onClick,
        });
      }

      actions.push({
        name: formatMessage(generalMessages.delete),
        label: deleteUsingModal?.label,
        onClick: deleteUsingModal?.onClick,
        disabled: deleteUsingModal?.disabled,
      });
    }

    if (isClaimObject) {
      if (isLocked) {
        actions.push({
          name: formatMessage(messages.createNewVersion),
          label: formatMessage(messages.createNewVersion),
          onClick: createNewVersion?.onClick,
          disabled: !isLastVersion,
        });
      } else {
        actions.push({
          name: formatMessage(messages.editButton),
          label: formatMessage(messages.editButton),
          onClick: edit?.onClick,
        });
      }

      actions.push({
        name: formatMessage(generalMessages.delete),
        label: deleteUsingModal?.label,
        onClick: deleteUsingModal?.onClick,
        disabled: deleteUsingModal?.disabled,
      });
    }

    if (!isListEmpty(actions)) {
      updateActionsInformation({
        props: {
          items: actions,
        },
      });
    }
  }, [
    isLastVersion,
    isChapterObject,
    isRequirementObject,
    isClaimObject,
    isLocked,
  ]);

  const getTabsProps = () => {
    const libraryItemId = isChapterObject
      ? libraryItemDetails?.generalInformation?.id
      : libraryItemDetails?.id;

    const libraryItemType = isChapterObject
      ? CHAPTER_TYPES_API[libraryItemDetails?.generalInformation?.type]
      : LIBRARY_OBJECT_API_TYPES[libraryItemDetails?.generalInformation?.type];

    return { libraryItemId, libraryItemType };
  };

  const getTag = () => {
    if (
      isClaimTypeNutritional(libraryItemDetails.claimType) &&
      !claimsRegulationAdministrate
    ) {
      return undefined;
    }

    if (label) {
      return { label, color, mode };
    }
  };

  return (
    <div className={styles.detailsContainer}>
      <PageHeader
        title={
          isTemplateObject
            ? libraryItemDetails?.generalInformation?.name
            : getNameWithVersion(
                libraryItemDetails?.generalInformation?.name || "",
                libraryItemDetails.versionNumber
              )
        }
        onBack={goBack}
        tag={getTag()}
        {...(hasModifyPermission && {
          onPrimaryButtonClick,
          primaryButtonLabel: formatMessage(
            isLocked && !isRequirementObject && !isClaimObject
              ? messages.createNewVersion
              : messages.editButton
          ),
        })}
        primaryButtonProps={{
          ...actionsInformation?.props,
          ...(isLocked && {
            disabled: isTemplateObject,
          }),
        }}
        tabs={{
          defaultActiveKey: LIBRARY_TAB_KEYS.GENERAL_INFORMATION,
          items: tabItems,
          onChange: (tabKey: string) => {
            onTabChange({
              ...getTabsProps(),
              tabKey,
              redirectToPage: redirectToLibraryDetails,
            });
          },
          activeKey: activeTab,
          onTabClick: setActiveTab,
        }}
      />
      <LibraryDetails.Provider
        value={{
          libraryItemDetails,
          typeDictionary,
        }}
      >
        {tabContent}

        {isDeleteModalOpen && (
          <DeleteVersionModal
            libraryItemName={libraryItemDetails?.generalInformation?.name}
            versionNumber={
              libraryItemDetails?.generalInfo?.version?.number ||
              libraryItemDetails?.versionNumber
            }
            onCancel={closeDeleteModal}
            redirect={goBack}
            {...getTabsProps()}
          />
        )}
      </LibraryDetails.Provider>
    </div>
  );
};

export default Details;
