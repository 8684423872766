import { LibraryObjectType } from "types/library";
import {
  LIBRARY_OBJECT_API_TYPES,
  LIBRARY_OBJECT_TYPES,
} from "utils/constants";

export interface ParamTypes {
  type: LibraryObjectType;
  tab?: string;
  id?: string;
  prevVersionId?: string;
}

export interface LocationState {
  shouldRefetch?: boolean;
}

export const goBackParams = {
  [LIBRARY_OBJECT_TYPES.TEXT_REQUIREMENTS]:
    LIBRARY_OBJECT_API_TYPES.requirement,
  [LIBRARY_OBJECT_TYPES.DOCUMENT_REQUIREMENTS]:
    LIBRARY_OBJECT_API_TYPES.requirement,
  [LIBRARY_OBJECT_TYPES.RAW_MATERIALS]: LIBRARY_OBJECT_API_TYPES.material,
  [LIBRARY_OBJECT_TYPES.ADDITIVES]: LIBRARY_OBJECT_API_TYPES.material,
  [LIBRARY_OBJECT_TYPES.PROCESSING_AIDS]: LIBRARY_OBJECT_API_TYPES.material,
  [LIBRARY_OBJECT_TYPES.BOUGHT_TO_THIRD_PARTY]:
    LIBRARY_OBJECT_API_TYPES.material,
  [LIBRARY_OBJECT_TYPES.MADE_IN_HOUSE]: LIBRARY_OBJECT_API_TYPES.material,
  [LIBRARY_OBJECT_TYPES.CONDITIONING]: LIBRARY_OBJECT_API_TYPES.chapter,
  [LIBRARY_OBJECT_TYPES.FINISHED_PRODUCTS]: LIBRARY_OBJECT_API_TYPES.chapter,
  [LIBRARY_OBJECT_TYPES.TESTING]: LIBRARY_OBJECT_API_TYPES.chapter,
  [LIBRARY_OBJECT_TYPES.PRODUCTION]: LIBRARY_OBJECT_API_TYPES.chapter,
  [LIBRARY_OBJECT_TYPES.PACKAGING_SYSTEMS]:
    LIBRARY_OBJECT_API_TYPES["packaging-systems"],
  [LIBRARY_OBJECT_TYPES.TEMPLATES]: LIBRARY_OBJECT_API_TYPES.template,
  [LIBRARY_OBJECT_TYPES.CLAIMS]: LIBRARY_OBJECT_API_TYPES.claim,
  [LIBRARY_OBJECT_TYPES.HEALTH_CLAIMS]: LIBRARY_OBJECT_API_TYPES.claim,
  [LIBRARY_OBJECT_TYPES.MARKETING_CLAIMS]: LIBRARY_OBJECT_API_TYPES.claim,
  [LIBRARY_OBJECT_TYPES.NUTRITIONAL_CLAIMS]:
    LIBRARY_OBJECT_API_TYPES["nutritional-claims"],
};
