import { useEffect, useRef } from "react";
import { Col, Row } from "antd";
import classNames from "classnames";
import { Heading, Paragraph, UserCard } from "@trace-one/design-system";
import { useSelector } from "react-redux";
import { FormattedDate, FormattedTime } from "react-intl";
import { selectUserId } from "store/user/selectors";
import { isDateEmpty } from "utils/general";
import { UserData } from "models";
import { DiscussionPanelMessageViewModel } from "viewModels/modificationRequest";
import styles from "../../styles.module.less";

interface DiscussionPanelMessageProps {
  message: DiscussionPanelMessageViewModel;
  discussionUsers?: UserData[];
}

const DiscussionPanelMessage: React.FC<DiscussionPanelMessageProps> = ({
  message,
  discussionUsers,
}) => {
  const userId = useSelector(selectUserId);

  const messageWrapperRef = useRef(null);
  useEffect(() => {
    messageWrapperRef.current.scrollIntoView?.({
      block: "end",
      inline: "center",
    });
  }, []);

  const dateToDisplay = isDateEmpty(message?.updatedAt)
    ? message?.createdAt
    : message?.updatedAt;

  const isMessageOwner = userId === message?.authorUserId;

  const userDetails = discussionUsers?.find(
    discussionUser => discussionUser?.userId === message?.authorUserId
  );

  return (
    <div
      className={styles["discussion-panel-message-wrapper"]}
      ref={messageWrapperRef}
    >
      {message?.showHeader && (
        <div className={styles["discussion-panel-message-header"]}>
          <Heading size="xxs">
            <FormattedDate
              value={dateToDisplay}
              weekday="long"
              month="short"
              day="2-digit"
              hour12
            />
          </Heading>
        </div>
      )}

      {!isMessageOwner && message?.showUserInfo && (
        <div className={classNames("ml-3", "w-100")}>
          <Row gutter={[8, 0]} className="mb-1">
            <Col>
              <UserCard.Information
                size="l"
                name={`${userDetails?.userFirstName} ${userDetails?.userLastName}`}
                photoUrl={userDetails?.userPhotoUrl}
              />
            </Col>
            <Col>
              <Row className="mb-0-5">
                <Heading size="xxs" color="grey-5">
                  {`${userDetails?.userFirstName} ${userDetails?.userLastName}`}
                </Heading>
              </Row>
              <Row>
                <Paragraph size="xs" color="grey-4">
                  {userDetails?.userJobTitle}
                </Paragraph>
              </Row>
            </Col>
          </Row>
        </div>
      )}

      <div
        className={classNames(
          "overflow-wrap",
          "word-break",
          styles["discussion-panel-message-content"],
          {
            [styles["discussion-panel-message-content-owner"]]: isMessageOwner,
            [styles["discussion-panel-message-content-other"]]: !isMessageOwner,
          }
        )}
      >
        <Paragraph
          size="s"
          color={isMessageOwner ? "white" : "grey-5"}
          dangerouslySetInnerHTML={{ __html: message?.text }}
        />
      </div>
      <div
        className={classNames(styles["discussion-panel-message-footer"], {
          [styles["discussion-panel-message-footer-owner"]]: isMessageOwner,
          [styles["discussion-panel-message-footer-other"]]: !isMessageOwner,
        })}
      >
        <Paragraph size="xs" color="grey-4">
          <FormattedTime
            value={dateToDisplay}
            hour="2-digit"
            minute="2-digit"
            hour12
          />
        </Paragraph>
      </div>
    </div>
  );
};

export default DiscussionPanelMessage;
