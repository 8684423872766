import React from "react";
import { UiSchema } from "@rjsf/core";
import RowTitle from "components/RowTitle";
import ClaimCardContent from "../ClaimCardContent";
import {
  ArraySchema,
  FormContext,
} from "components/SpecificationSection/types";
import { ClaimFormData } from "types/specification";
import { ClaimData } from "models";
import { useHealthClaimRegulation } from "hooks";

export type ClaimListProps = {
  title: string;
  claimCollection: ClaimData[];
  formData: ClaimFormData;
  formContext: FormContext;
  uiSchema: UiSchema;
  schema: ArraySchema;
  supportingDocErrorClaimIds: string[];
};

const ClaimList: React.FC<ClaimListProps> = ({
  title,
  claimCollection = [],
  ...rest
}) => {
  const regulatoryClaimIds = claimCollection
    .map(({ regulatoryClaimId }) => regulatoryClaimId)
    .filter(Boolean);

  const { regulations } = useHealthClaimRegulation({ ids: regulatoryClaimIds });

  return (
    <div>
      <div className="mb-3">
        <RowTitle title={title} />
      </div>
      {claimCollection?.map(claim => (
        <ClaimCardContent
          key={claim?.id}
          claim={claim}
          regulation={regulations.find(
            ({ id }) => claim?.regulatoryClaimId === id
          )}
          {...rest}
        />
      ))}
    </div>
  );
};

export default ClaimList;
