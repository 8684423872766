import { Col, Row } from "antd";
import { Tag } from "@trace-one/design-system";
import { useTranslatorCharacteristicIngredients } from "../../hooks";
import { isListEmpty } from "utils/general";
import { IngredientListFormData } from "components/SpecificationSection/components/FormSchema/types";

export type CharacteristicIngredientsTranslatorProps = {
  characteristicIngredients: IngredientListFormData["ingredients"];
};

const CharacteristicIngredientsTranslator: React.FC<CharacteristicIngredientsTranslatorProps> = ({
  characteristicIngredients,
}) => {
  const {
    translatorCharacteristicIngredients,
  } = useTranslatorCharacteristicIngredients({
    characteristicIngredients,
  });

  return (
    <Row gutter={[8, 8]}>
      {!isListEmpty(translatorCharacteristicIngredients) &&
        translatorCharacteristicIngredients?.map(
          ({ ingredientId, materialId, name }) =>
            ingredientId || materialId ? (
              <Col key={ingredientId || materialId}>
                <Tag mode="full" color="default" label={name} />
              </Col>
            ) : null
        )}
    </Row>
  );
};
export default CharacteristicIngredientsTranslator;
