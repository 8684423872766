import { isLibraryObjectLocked } from "utils/library";
import { ModalActionType } from "components/LinkedSpecificationsModal/types";
import { versionHistoryMessages } from "messages";
import { ActionType, GetActionListParams } from "./type";

export const getActionsList = ({
  formatMessage,
  activeVersion,
  libraryItemId,
  libraryItemState,
  handleOpenModal,
  isRetailer,
  versionNumber,
}: GetActionListParams) => {
  let actions: ActionType[] = [];

  if (activeVersion) {
    actions.push({
      label: formatMessage(versionHistoryMessages.unlinkSpecificationsTitle),
      name: formatMessage(versionHistoryMessages.unlinkSpecificationsTitle),
      onClick: handleOpenModal({
        actionType: ModalActionType.UNLINK,
        selectedLibraryItemId: libraryItemId,
        libraryItemState,
        versionNumber,
      }),
    });
  }

  if (!activeVersion && isLibraryObjectLocked(libraryItemState)) {
    actions.push({
      label: formatMessage(versionHistoryMessages.updateToLatestVersionTitle),
      name: formatMessage(versionHistoryMessages.updateToLatestVersionTitle),
      onClick: handleOpenModal({
        actionType: ModalActionType.UPDATE,
        selectedLibraryItemId: libraryItemId,
        libraryItemState,
        versionNumber,
      }),
    });

    if (isRetailer) {
      actions.push({
        label: formatMessage(versionHistoryMessages.reviseSpecifications),
        name: formatMessage(versionHistoryMessages.reviseSpecifications),
        onClick: handleOpenModal({
          actionType: ModalActionType.REVISE,
          selectedLibraryItemId: libraryItemId,
          libraryItemState,
          versionNumber,
        }),
      });
    }
  }

  return actions;
};
