import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { useIntl } from "react-intl";
import { FormInstance } from "antd";
import DeleteAndKeepDraftModal from "components/Library/components/DeleteAndKeepDraftModal";
import useRequirementFormActions from "../../hooks/useRequirementFormActions";
import { selectRequirementInformation } from "store/requirementForm/selectors";
import { ParamTypes } from "pages/Library/components/types";
import libraryMessages from "messages/library";

export type CancelDeleteRequirementModalProps = {
  form: FormInstance;
  isNewVersion?: boolean;
  prevRequirementId?: string;
};

const CancelDeleteRequirementModal: React.FC<CancelDeleteRequirementModalProps> = ({
  form,
  prevRequirementId,
  isNewVersion,
}) => {
  const { formatMessage } = useIntl();
  const { type } = useParams<ParamTypes>();

  const { id } = useSelector(selectRequirementInformation);

  const title = isNewVersion
    ? formatMessage(libraryMessages.cancelRequirementVersionTitle)
    : formatMessage(libraryMessages.cancelRequirementCreationTitle);
  const description = isNewVersion
    ? formatMessage(libraryMessages.cancelRequirementVersionDescription)
    : formatMessage(libraryMessages.cancelRequirementCreationDescription);
  const primaryButtonText = isNewVersion
    ? formatMessage(libraryMessages.deleteVersion)
    : formatMessage(libraryMessages.deleteRequirement);

  const {
    onKeepAsDraftRequirement,
    onDeleteRequirement,
  } = useRequirementFormActions({
    validateFields: form.validateFields,
    prevRequirementId,
    isNewVersion,
    requirementType: type,
  });

  return (
    <DeleteAndKeepDraftModal
      id={id}
      title={title}
      description={description}
      primaryButtonText={primaryButtonText}
      onKeepAsDraftAction={onKeepAsDraftRequirement}
      onDeleteAction={onDeleteRequirement}
    />
  );
};

export default CancelDeleteRequirementModal;
