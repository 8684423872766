import { NutritionFormData } from "models";

export const getNutritionDeclarationFormData = (
  defaultFormData: NutritionFormData
): NutritionFormData => ({
  ...defaultFormData,
  servingDefinitions: (defaultFormData?.servingDefinitions ?? []).map(
    ({ size: { unitId, value }, ...rest }) => {
      return {
        ...rest,
        ...(unitId ? { size: { unitId, value } } : {}),
      };
    }
  ),
});
