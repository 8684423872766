import { PackagingComponentLink, PackagingSystemComponentData } from "models";

export const getLinkedComponentIds = ({
  componentLinks,
  selectedComponentId,
}: {
  componentLinks: PackagingComponentLink[];
  selectedComponentId: PackagingSystemComponentData["componentId"];
}) =>
  componentLinks.reduce(
    (acc, { packagingComponentId1, packagingComponentId2 }) => {
      acc.push(
        getLinkedComponentId({
          componentLink: { packagingComponentId1, packagingComponentId2 },
          selectedComponentId,
        })
      );

      return acc;
    },
    []
  );

export const getLinkedComponentId = ({
  componentLink: { packagingComponentId1, packagingComponentId2 },
  selectedComponentId,
}: {
  componentLink: PackagingComponentLink;
  selectedComponentId: PackagingSystemComponentData["componentId"];
}) => {
  if (packagingComponentId1 === selectedComponentId) {
    return packagingComponentId2;
  } else if (packagingComponentId2 === selectedComponentId) {
    return packagingComponentId1;
  } else {
    return null;
  }
};
