import { useIntl } from "react-intl";
import { TreeSelect } from "@trace-one/react-components";
import { useInputValidation } from "hooks";
import { useClassificationDropdown } from "./hooks";
import libraryMessages from "messages/library";
import messages from "./messages";
import { ClassificationDropdownProps } from "./types";

const ClassificationDropdown: React.FC<ClassificationDropdownProps> = ({
  value,
  onChange: defaultOnChange,
  onFullChange,
  onDropdownVisibleChange: defaultOnDropdownVisibleChange,
  ...rest
}) => {
  const { formatMessage } = useIntl();

  const { hasError, onInputChange } = useInputValidation(defaultOnChange, true);

  const {
    isLoading,
    valueWithLabel,
    treeData,
    onChange,
    onSearch,
    onDropdownVisibleChange,
    onLoadData,
    searchTerm,
    treeExpandedKeys,
    onTreeExpand,
  } = useClassificationDropdown({
    value,
    onChange: onInputChange,
    onFullChange,
  });

  return (
    <TreeSelect
      {...rest}
      name="classification-dropdown"
      placeholder={formatMessage(messages.classificationPlaceholder)}
      loading={isLoading}
      treeExpandedKeys={treeExpandedKeys}
      loadData={onLoadData}
      value={valueWithLabel}
      treeData={treeData}
      searchValue={searchTerm}
      onChange={onChange}
      onSearch={onSearch}
      onTreeExpand={onTreeExpand}
      onDropdownVisibleChange={onDropdownVisibleChange}
      labelInValue
      treeExpandAction="click"
      filterTreeNode={false}
      error={hasError}
      errorMessage={formatMessage(libraryMessages.formRequired)}
    />
  );
};

export default ClassificationDropdown;
