import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  addChapterTags,
  deleteChapterTag,
  deleteChapterType,
  fetchChapter,
  updateChapterBlock,
  updateChapterName,
  updateChapterType,
  updateChapterNutrition as updateChapterNutritionApi,
  addChapterFreeFromAllergens as addChapterFreeFromAllergensApi,
  deleteChapterFreeFromAllergen,
  updateChapterFreeFromAllergen as updateChapterFreeFromAllergenApi,
} from "apis/SPEC";
import { CHAPTER_TYPES_API } from "utils/constants";
import { getNewBlockData } from "./utils";
import { ChapterData, NutritionFormData } from "models";
import { AsyncThunkConfig } from "store";

export const updateName = createAsyncThunk<
  ChapterData,
  string,
  AsyncThunkConfig
>("chapterForm/updateName", async (chapterName, { getState }) => {
  if (!chapterName) {
    return;
  }

  const {
    chapterForm: { generalInfo },
    user: { userLanguagePreference },
  } = getState();

  if (generalInfo?.id) {
    const { data } = await updateChapterName({
      chapterId: generalInfo.id,
      chapterName,
      chapterType: CHAPTER_TYPES_API[generalInfo.type],
    });

    return data;
  } else if (generalInfo?.type && generalInfo?.productTypeId) {
    const { data } = await updateChapterType({
      chapterName,
      chapterType: CHAPTER_TYPES_API[generalInfo?.type],
      productTypeId: generalInfo?.productTypeId,
      tagIds: generalInfo?.tagIds,
      languageCode: userLanguagePreference,
    });
    return data;
  }
});

export const updateType = createAsyncThunk<
  ChapterData,
  string,
  AsyncThunkConfig
>("chapterForm/updateType", async (chapterType, { getState, dispatch }) => {
  const {
    chapterForm: { generalInfo },
    user: { userLanguagePreference },
  } = getState();

  if (!chapterType || !generalInfo.productTypeId) return;

  await dispatch(resetChapterStructure());

  try {
    const { data } = await updateChapterType({
      chapterType: CHAPTER_TYPES_API[chapterType],
      productTypeId: generalInfo.productTypeId,
      chapterName: generalInfo?.name,
      tagIds: generalInfo?.tagIds,
      languageCode: userLanguagePreference,
    });

    return data;
  } catch (error) {}
});

export const updateProductType = createAsyncThunk<
  ChapterData,
  string,
  AsyncThunkConfig
>(
  "chapterForm/updateProductType",
  async (productTypeId, { getState, dispatch }) => {
    const {
      chapterForm: { generalInfo },
      user: { userLanguagePreference },
    } = getState();

    await dispatch(resetChapterStructure());

    if (productTypeId && generalInfo.type) {
      const { data } = await updateChapterType({
        chapterType: CHAPTER_TYPES_API[generalInfo.type],
        productTypeId,
        chapterName: generalInfo?.name,
        tagIds: generalInfo?.tagIds,
        languageCode: userLanguagePreference,
      });

      return data;
    }
  }
);

export const addTag = createAsyncThunk<ChapterData, string, AsyncThunkConfig>(
  "chapterForm/addTag",
  async (tagId, { getState }) => {
    const {
      chapterForm: { generalInfo },
    } = getState();

    if (generalInfo?.id) {
      const { data } = await addChapterTags({
        chapterId: generalInfo.id,
        tagIds: [tagId],
        chapterType: CHAPTER_TYPES_API[generalInfo.type],
      });

      return data;
    }

    return;
  }
);

export const removeTag = createAsyncThunk<
  ChapterData,
  string,
  AsyncThunkConfig
>("chapterForm/removeTag", async (tagId, { getState }) => {
  const {
    chapterForm: { generalInfo },
  } = getState();

  if (generalInfo?.id) {
    const { data } = await deleteChapterTag({
      chapterId: generalInfo.id,
      tagId: tagId,
      chapterType: CHAPTER_TYPES_API[generalInfo.type],
    });

    return data;
  }

  return;
});

export const updateBlock = createAsyncThunk<
  ChapterData,
  {
    blockId: string;
    blockJson: string;
  },
  AsyncThunkConfig
>("chapterForm/updateBlock", async ({ blockId, blockJson }, { getState }) => {
  const {
    chapterForm: { generalInfo },
  } = getState();

  const { data } = await updateChapterBlock({
    chapterId: generalInfo.id,
    chapterType: CHAPTER_TYPES_API[generalInfo.type],
    blockId,
    blockJson,
  });

  return getNewBlockData({
    blockId,
    currentData: data,
  });
});

export const getChapter = createAsyncThunk<ChapterData, void, AsyncThunkConfig>(
  "chapterForm/getChapter",
  async (_, { getState }) => {
    const {
      chapterForm: { generalInfo },
      user: { userLanguagePreference },
    } = getState();

    const { data } = await fetchChapter({
      chapterId: generalInfo.id,
      chapterType: CHAPTER_TYPES_API[generalInfo.type],
      languageCode: userLanguagePreference,
    });

    return data;
  }
);

export const resetChapterStructure = createAsyncThunk<
  void,
  void,
  AsyncThunkConfig
>("chapterForm/resetChapterStructure", async (_, { getState }) => {
  const {
    chapterForm: { generalInfo },
  } = getState();

  if (generalInfo?.id) {
    try {
      await deleteChapterType({
        chapterId: generalInfo.id,
        chapterType: CHAPTER_TYPES_API[generalInfo.type],
      });
    } catch (error) {}
  }
});

export const updateChapterNutrition = createAsyncThunk<
  ChapterData,
  { nutritionData: NutritionFormData },
  AsyncThunkConfig
>(
  "chapterForm/updateChapterNutrition",
  async ({ nutritionData }, { getState }) => {
    const {
      chapterForm: { generalInfo },
    } = getState();

    const { data } = await updateChapterNutritionApi({
      id: generalInfo?.id,
      chapterType: generalInfo?.type,
      json: JSON.stringify(nutritionData),
    });

    return data;
  }
);

export const addChapterFreeFromAllergens = createAsyncThunk<
  ChapterData,
  {
    allergenIds: string[];
  },
  AsyncThunkConfig
>(
  "chapterForm/addChapterFreeFromAllergens",
  async ({ allergenIds }, { getState }) => {
    const {
      chapterForm: { generalInfo },
    } = getState();

    const { data } = await addChapterFreeFromAllergensApi({
      chapterId: generalInfo?.id,
      chapterType: generalInfo?.type,
      allergenIds,
    });

    return data;
  }
);

export const removeChapterFreeFromAllergen = createAsyncThunk<
  ChapterData,
  {
    allergenId: string;
  },
  AsyncThunkConfig
>(
  "chapterForm/removeChapterFreeFromAllergen",
  async ({ allergenId }, { getState }) => {
    const {
      chapterForm: { generalInfo },
    } = getState();

    const { data } = await deleteChapterFreeFromAllergen({
      chapterId: generalInfo?.id,
      chapterType: generalInfo?.type,
      allergenId,
    });
    return data;
  }
);

export const updateChapterFreeFromAllergen = createAsyncThunk<
  ChapterData,
  {
    allergenId: string;
    fileId: string;
  },
  AsyncThunkConfig
>(
  "chapterForm/updateChapterFreeFromAllergen",
  async ({ allergenId, fileId }, { getState }) => {
    const {
      chapterForm: { generalInfo },
    } = getState();

    const { data } = await updateChapterFreeFromAllergenApi({
      chapterId: generalInfo?.id,
      chapterType: generalInfo?.type,
      allergenId,
      fileId,
    });
    return data;
  }
);
