import {
  CLAIM_VALIDATION_RULE_TYPE,
  ClaimValidationRule,
  RegulatoryHealthClaim,
} from "models";
import { LibraryItemDetails } from "pages/Library/hocs/withLibraryItemDetails/types";
import { isClaimTypeHealth } from "utils/claim";
import { isListEmpty } from "utils/general";
import { ClaimInformationViewModel } from "viewModels/claim";

export const getClaimInformationFormData = ({
  libraryItemDetails,
  regulation,
}: {
  libraryItemDetails: LibraryItemDetails;
  regulation?: RegulatoryHealthClaim;
}) => {
  let formData: ClaimInformationViewModel = {};

  const {
    generalInformation,
    productTypeId,
    additionalSentenceTranslations,
    claimType,
  } = libraryItemDetails;

  const requiresSupportingDocuments = isClaimTypeHealth(claimType)
    ? regulation?.requiresSupportingDocuments
    : generalInformation?.requiresSupportingDocuments;

  formData.name = generalInformation?.name;
  formData.productTypeId = productTypeId;
  formData.requiresSupportingDocuments = requiresSupportingDocuments;
  formData.additionalSentenceTranslations = additionalSentenceTranslations;
  return formData;
};

export const getNutrientSubstanceIdsFromValidationRule = (
  validationRule: ClaimValidationRule[]
) => {
  if (isListEmpty(validationRule)) {
    return {
      nutrientIds: [],
      substanceIds: [],
    };
  }

  const { nutrientIds, substanceIds } = validationRule.reduce(
    (acc, currentRule) => {
      if (currentRule.itemType === CLAIM_VALIDATION_RULE_TYPE.NUTRIENT) {
        acc.nutrientIds.push(currentRule.id);
      } else if (
        currentRule.itemType === CLAIM_VALIDATION_RULE_TYPE.SUBSTANCE
      ) {
        acc.substanceIds.push(currentRule.id);
      }
      return acc;
    },
    { nutrientIds: [], substanceIds: [] }
  );

  return {
    nutrientIds,
    substanceIds,
  };
};
