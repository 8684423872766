import { useIntl } from "react-intl";
import { Col, Row } from "antd";
import {
  Button,
  Typography,
  Toggle,
  DotStatus,
} from "@trace-one/design-system";
import { CardHeaderProps } from "./types";
import { useBoolean, useUserInfo } from "hooks";
import { LIBRARY_OBJECT_TYPES } from "utils/constants";
import generalMessages from "messages/general";
import statusMessages from "messages/statuses";
import styles from "../../styles.module.less";

const CardHeader: React.FC<CardHeaderProps> = ({
  iconName,
  toggleCardExpansion,
  title,
  subtitle,
  description,
  isChecked,
  onToggle,
  toggleText,
  toggleTextPlacement = "right",
  isTargetMode,
  libraryObjectType,
  isToggleDisabled,
  isCardExpanded,
}) => {
  const { formatMessage } = useIntl();
  const { isRetailer } = useUserInfo();

  const { value: isToggleActionInProgress, setTrue, setFalse } = useBoolean();

  const onToggleChange = async () => {
    try {
      setTrue();
      await onToggle?.();
    } finally {
      setFalse();
    }
  };

  const headerRightContentMap = {
    [LIBRARY_OBJECT_TYPES.REQUIREMENTS]: (
      <>
        {isRetailer ? (
          <DotStatus
            color={isChecked ? "green" : "orange"}
            text={
              isChecked
                ? formatMessage(statusMessages.validatedBySupplier)
                : formatMessage(statusMessages.waitingForValidation)
            }
            data-test-id="card-header-dot-status"
          />
        ) : (
          <Toggle
            data-testid="card-header-toggle"
            size="small"
            onChange={onToggleChange}
            checked={isChecked}
            loading={isToggleActionInProgress}
            disabled={!onToggle || isChecked || isTargetMode}
            text={toggleText || formatMessage(generalMessages.validate)}
          />
        )}
      </>
    ),

    [LIBRARY_OBJECT_TYPES.CLAIMS]: (
      <Toggle
        data-testid="card-header-toggle"
        size="small"
        onChange={onToggleChange}
        checked={isChecked}
        loading={isToggleActionInProgress}
        disabled={!onToggle || isTargetMode || isToggleDisabled}
        text={toggleText || formatMessage(generalMessages.validate)}
        textPlacement={toggleTextPlacement}
      />
    ),
  };

  const renderDescription = (): React.ReactNode => {
    if (description && isCardExpanded) {
      if (typeof description === "string") {
        return (
          <Row className="mt-2 overflow-wrap word-break">
            <Typography
              variant="body-regular"
              color="grey-5"
              className="ml-3 white-space-break"
            >
              {description}
            </Typography>
          </Row>
        );
      }

      return (
        <div className="mt-2 overflow-wrap word-break">
          <div className="ml-3 white-space-break">{description}</div>
        </div>
      );
    }

    return null;
  };

  return (
    <div className={styles.expandableCardHeader} data-test-id="card-header">
      <Col span={24}>
        <Row>
          <Col
            className={styles.expandableCardHeaderTitle}
            onClick={() => {
              toggleCardExpansion();
            }}
            span={18}
          >
            <Row align="middle" wrap={false}>
              <Button
                iconName={iconName}
                type="tertiary"
                size="small"
                color="grey"
                data-test-id="card-icon-btn"
              />
              <Typography variant="heading-xxs" color="grey-5">
                {title}
              </Typography>
            </Row>
            {subtitle && (
              <Row className="mt-1">
                <Typography
                  variant="body-regular"
                  color="grey-5"
                  className="ml-3"
                >
                  {subtitle}
                </Typography>
              </Row>
            )}
          </Col>
          <Col span={6}>
            <Row justify="end" align="middle" className="mr-1">
              {headerRightContentMap?.[libraryObjectType] || null}
            </Row>
          </Col>
        </Row>
        {renderDescription()}
      </Col>
    </div>
  );
};

export default CardHeader;
