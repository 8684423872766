import { useState } from "react";
import { Row, Col } from "antd";
import {
  Datatable,
  Button,
  Heading,
  Paragraph,
} from "@trace-one/design-system";
import { useIntl } from "react-intl";
import AddPackagingComponentsModal from "./components/AddPackagingComponentsModal";
import LinksDetailsModal from "./components/LinksDetailsModal";
import { useModalVisibility } from "hooks";
import { usePackagingSystemComponentsColumns } from "./hooks";
import { packagingComponentTabMessages } from "messages/packaging";
import messages from "messages/library";
import { PackagingSystemComponentsProps } from "./types";
import { usePackagingSystemContext } from "../../context/packagingSystemContext";
import styles from "../../styles.module.less";

const PackagingSystemComponents: React.FC<PackagingSystemComponentsProps> = ({
  packagingSystemId,
  packagingSystemAllowUpdate,
  mainComponentId,
}) => {
  const { formatMessage } = useIntl();

  const [
    initialPackagingComponentId,
    setInitialPackagingComponentId,
  ] = useState<string>(null);

  const { isModalOpen, onOpenModal, onCloseModal } = useModalVisibility();
  const {
    isModalOpen: linksDetailsOpen,
    onOpenModal: onOpenLinksDetails,
    onCloseModal: onCloseLinksDetails,
  } = useModalVisibility();

  const {
    packagingSystemComponentsTableView,
    isLoading,
    refListDictionary,
    packagingComponentIds,
  } = usePackagingSystemContext();

  const onOpenLinksDetailsModal = (packagingComponentId: string) => {
    setInitialPackagingComponentId(packagingComponentId);
    onOpenLinksDetails();
  };

  const { columns } = usePackagingSystemComponentsColumns({
    refListDictionary,
    packagingSystemId,
    packagingComponentIds,
    packagingSystemAllowUpdate,
    mainComponentId,
    onOpenLinksDetailsModal,
  });

  return (
    <>
      <Row className={styles.componentsHeader}>
        <Col span={20}>
          <Row className={styles.componentsTitle}>
            <Heading size="xs" color="grey-5">
              {formatMessage(packagingComponentTabMessages.title)}
            </Heading>
          </Row>
          <Row>
            <Paragraph size="m" color="grey-4">
              {formatMessage(packagingComponentTabMessages.subtitle)}
            </Paragraph>
          </Row>
        </Col>
        <Col span={4} className={styles.componentsAction}>
          <Button
            type="tertiary"
            onClick={onOpenModal}
            disabled={!packagingSystemAllowUpdate}
            data-testid="add-component"
          >
            {formatMessage(messages.addComponent)}
          </Button>
        </Col>
      </Row>
      {isModalOpen && (
        <AddPackagingComponentsModal
          onCloseModal={onCloseModal}
          packagingSystemId={packagingSystemId}
          packagingComponentIds={packagingComponentIds}
          mainComponentId={mainComponentId}
        />
      )}
      {linksDetailsOpen && (
        <LinksDetailsModal
          initialPackagingComponentId={initialPackagingComponentId}
          onCancel={onCloseLinksDetails}
        />
      )}
      <Datatable
        dataSource={packagingSystemComponentsTableView}
        columns={columns}
        rowKey={packagingSystemComponent =>
          packagingSystemComponent?.componentId
        }
        loading={isLoading}
        pagination={false}
        tableLayout="fixed"
      />
    </>
  );
};

export default PackagingSystemComponents;
