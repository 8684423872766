import { defineMessages } from "react-intl";

const messages = defineMessages({
  loading: {
    id: "general.loading",
    defaultMessage: "Loading",
  },
  ok: {
    id: "general.ok",
    defaultMessage: "Ok",
  },
  cancel: {
    id: "general.cancel",
    defaultMessage: "Cancel",
  },
  confirm: {
    id: "general.confirm",
    defaultMessage: "Confirm",
  },
  confirmAndEdit: {
    id: "general.confirmAndEdit",
    defaultMessage: "Confirm and edit",
  },
  duplicate: {
    id: "general.duplicate",
    defaultMessage: "Duplicate",
  },
  link: {
    id: "general.link",
    defaultMessage: "Link",
  },
  links: {
    id: "general.links",
    defaultMessage: "Links",
  },
  name: {
    id: "general.name",
    defaultMessage: "Name",
  },
  creationDate: {
    id: "general.creationDate",
    defaultMessage: "Creation date",
  },
  confirmDeleteItem: {
    id: "general.delete.item",
    defaultMessage: "Do you want to delete this item?",
  },
  no: {
    id: "general.no",
    defaultMessage: "No",
  },
  yes: {
    id: "general.yes",
    defaultMessage: "Yes",
  },
  errorMessage: {
    id: "general.error.message",
    defaultMessage: "Sorry an error ocurred, please try again.",
  },
  actions: {
    id: "general.actions",
    defaultMessage: "Actions",
  },
  version: {
    id: "general.version",
    defaultMessage: "Version",
  },
  add: {
    id: "general.add",
    defaultMessage: "Add",
  },
  update: {
    id: "general.update",
    defaultMessage: "Update",
  },
  remove: {
    id: "general.remove",
    defaultMessage: "Remove",
  },
  delete: {
    id: "general.delete",
    defaultMessage: "Delete",
  },
  noTags: {
    id: "general.noTags",
    defaultMessage: "No tags",
  },
  select: {
    id: "general.select",
    defaultMessage: "Select",
  },
  validate: {
    id: "general.validate",
    defaultMessage: "Validate",
  },
  productTypeLabel: {
    id: "productType.label",
    defaultMessage: "Product type",
  },
  templateName: {
    id: "templateName",
    defaultMessage: "Template name",
  },
  productTypeFood: {
    id: "productType.food",
    defaultMessage: "Food",
  },
  productTypeFruitsAndVegetables: {
    id: "productType.fruitsAndVegetables",
    defaultMessage: "Fruits and Vegetables",
  },
  noResultsFound: {
    id: "general.noResultsFound",
    defaultMessage: "No results found",
  },
  exitAndSave: {
    id: "general.exitAndSave",
    defaultMessage: "Exit and save",
  },
  notify: {
    id: "general.notify",
    defaultMessage: "Notify",
  },
  all: {
    id: "general.all",
    defaultMessage: "All",
  },
  allPlural: {
    id: "general.allPlural",
    defaultMessage: "All",
  },
  active: {
    id: "general.active",
    defaultMessage: "Active",
  },
  selectAValue: {
    id: "general.selectAValue",
    defaultMessage: "Select a value",
  },
  save: {
    id: "general.save",
    defaultMessage: "Save",
  },
  saveAndClose: {
    id: "general.saveAndClose",
    defaultMessage: "Save and close",
  },
  company: {
    id: "general.company",
    defaultMessage: "Company",
  },
  languages: {
    id: "general.languages",
    defaultMessage: "Language(s)",
  },
  noItemFound: {
    id: "select.notFoundMessage",
    defaultMessage: "No item found",
  },
  type: {
    id: "general.type",
    defaultMessage: "Type",
  },
  downloadAll: {
    id: "general.downloadAll",
    defaultMessage: "Download all",
  },
  listingResultNumber: {
    id: "general.listingResultNumber",
    defaultMessage: "Displaying {current} of {total} {libraryObjectType}",
  },
  userName: {
    id: "general.userName",
    defaultMessage: "User name",
  },
  component: {
    id: "general.component",
    defaultMessage: "Component",
  },
  components: {
    id: "general.components",
    defaultMessage: "Components",
  },
  seeMore: {
    id: "general.seeMore",
    defaultMessage: "see more",
  },
  previousVersionLinked: {
    id: "general.previousVersionLinked",
    defaultMessage:
      "A previous version of this item is already associated to this specification.",
  },
  substance: {
    id: "general.substance",
    defaultMessage: "Substance",
  },
  errors: {
    id: "general.errors",
    defaultMessage: "Errors",
  },
});

export default messages;
