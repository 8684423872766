import { useState, useEffect, useMemo } from "react";
import { useIntl } from "react-intl";
import { useHistory } from "react-router-dom";
import PackagingGeneralInformation from "components/Library/components/PackagingSystemForm/components/GeneralInformation";
import PackagingSystemComponents from "../../components/PackagingSystemComponents";
import RestrictionSummary from "../../components/RestrictionSummary";
import { PACKAGING_SYSTEM_TAB_KEYS } from "utils/constants";
import {
  PackagingSystemGeneralInformationViewModel,
  PackagingSystemComponentsViewModel,
} from "viewModels";
import { tabMessages } from "../../messages";

const usePackagingSystemNavigation = ({
  generalInfo,
  components,
}: {
  generalInfo: PackagingSystemGeneralInformationViewModel;
  components: PackagingSystemComponentsViewModel;
}) => {
  const { formatMessage } = useIntl();
  const { location } = useHistory();
  const [activeTab, setActiveTab] = useState("");
  const [tabContent, setTabContent] = useState(null);

  const numberOfComponents = useMemo(
    () => generalInfo?.components?.length || 0,
    [generalInfo?.components]
  );

  const componentMap = {
    [PACKAGING_SYSTEM_TAB_KEYS.GENERAL_INFORMATION]: (
      <PackagingGeneralInformation
        onFieldsChange={() => {}}
        generalInfo={generalInfo}
      />
    ),
    [PACKAGING_SYSTEM_TAB_KEYS.COMPONENTS]: (
      <PackagingSystemComponents
        packagingSystemId={components?.packagingSystemId}
        packagingSystemAllowUpdate={components?.allowsUpdate}
        mainComponentId={generalInfo?.mainComponentId}
      />
    ),
    [PACKAGING_SYSTEM_TAB_KEYS.RESTRICTION_SUMMARY]: (
      <RestrictionSummary
        packagingSystemAllowUpdate={components?.allowsUpdate}
      />
    ),
  };

  useEffect(() => {
    if (location.pathname.includes(PACKAGING_SYSTEM_TAB_KEYS.COMPONENTS)) {
      setActiveTab(PACKAGING_SYSTEM_TAB_KEYS.COMPONENTS);
    } else if (
      location.pathname.includes(PACKAGING_SYSTEM_TAB_KEYS.RESTRICTION_SUMMARY)
    ) {
      setActiveTab(PACKAGING_SYSTEM_TAB_KEYS.RESTRICTION_SUMMARY);
    } else {
      setActiveTab(PACKAGING_SYSTEM_TAB_KEYS.GENERAL_INFORMATION);
    }
  }, [location]);

  useEffect(() => {
    if (activeTab) {
      setTabContent(componentMap[activeTab]);
    }
  }, [activeTab, generalInfo]);

  const tabItems = [
    {
      key: PACKAGING_SYSTEM_TAB_KEYS.GENERAL_INFORMATION,
      label: formatMessage(tabMessages.generalInformation),
    },
    {
      key: PACKAGING_SYSTEM_TAB_KEYS.COMPONENTS,
      label: formatMessage(tabMessages.components, {
        numberOfComponents,
      }),
    },
    {
      key: PACKAGING_SYSTEM_TAB_KEYS.RESTRICTION_SUMMARY,
      label: formatMessage(tabMessages.restrictionSummary),
    },
  ];

  return {
    activeTab,
    setActiveTab,
    tabItems,
    tabContent,
  };
};

export default usePackagingSystemNavigation;
