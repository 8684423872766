import { useMemo, useState } from "react";
import { orderBy as lodashOrderBy } from "lodash";
import { Sorter } from "types/pagination";
import { isObjectEmpty } from "utils/general";

export interface UseClientPaginationProps<T> {
  items: T[];
  pageSize?: number;
  sorter?: Sorter;
}

export const useClientPagination = <T>({
  items = [],
  pageSize,
  sorter,
}: UseClientPaginationProps<T>) => {
  const [filters, setFilters] = useState({
    pageSize: pageSize ?? 20,
    currentPage: 1,
  });

  const onPaginationChange = (currentPage: number, pageSize: number) => {
    setFilters(previousState => ({
      ...previousState,
      currentPage,
      pageSize,
    }));
  };

  const visibleFiles = useMemo(() => {
    let sortedItems = [...items];

    if (!isObjectEmpty(sorter)) {
      const { orderBy, orderByDescending } = sorter;

      if (orderBy) {
        if (orderByDescending) {
          sortedItems = lodashOrderBy(sortedItems, orderBy, "desc");
        } else {
          sortedItems = lodashOrderBy(sortedItems, orderBy, "asc");
        }
      }
    }

    const { currentPage, pageSize } = filters;
    const startIndex = (currentPage - 1) * pageSize;
    const endIndex = startIndex + pageSize;
    return sortedItems.slice(startIndex, endIndex);
  }, [
    filters.currentPage,
    filters.pageSize,
    items,
    sorter?.orderBy,
    sorter?.orderByDescending,
  ]);

  return {
    filteredItems: visibleFiles,
    onPaginationChange,
    totalCount: items.length,
    filters,
  };
};

export default useClientPagination;
