import { defineMessages } from "react-intl";

export const messages = defineMessages({
  attachment: {
    id: "attachment",
    defaultMessage: "Attachment",
  },
  noAttachment: {
    id: "noAttachment",
    defaultMessage: "No attachment",
  },
  technicalDataSheet: {
    id: "technicalDataSheet",
    defaultMessage: "Technical data sheet",
  },
  otherDocuments: {
    id: "otherDocuments",
    defaultMessage: "Other documents",
  },
  documents: {
    id: "documents",
    defaultMessage: "Documents",
  },
  creationDocumentSubtitle: {
    id: "creation.document.subtitle",
    defaultMessage: "Share and ask for documents",
  },
  documentInformationTitle: {
    id: "documentInformation.title",
    defaultMessage: "Document information",
  },
  documentsSelectionTitle: {
    id: "documentInformation.documentsSelection.title",
    defaultMessage: "Documents selection ({numberOfDocumentsSelected})",
  },
  selectADocument: {
    id: "documentInformation.selectADocument",
    defaultMessage: "Select a document to display details",
  },
  download: {
    id: "document.download",
    defaultMessage: "Download",
  },
  import: {
    id: "document.import",
    defaultMessage: "Import",
  },
  extension: {
    id: "document.extension",
    defaultMessage: "Extension",
  },
  documentType: {
    id: "document.documentType",
    defaultMessage: "Document type",
  },
  size: {
    id: "document.size",
    defaultMessage: "Size",
  },
  allDocuments: {
    id: "document.allDocuments",
    defaultMessage: "All documents",
  },
  documentsLinkedToTheSpecification: {
    id: "document.documentsLinkedToTheSpecification",
    defaultMessage: "Documents linked to the specification",
  },
  documentsLinkedToChapters: {
    id: "document.documentsLinkedToChapters",
    defaultMessage: "Documents linked to chapters",
  },
  documentsLinkedToLibraryItems: {
    id: "document.documentsLinkedToLibraryItems",
    defaultMessage: "Documents linked to Library items",
  },

  searchPlaceholder: {
    id: "document.searchByName",
    defaultMessage: "Search a document by name",
  },

  importFile: {
    id: "document.importFile",
    defaultMessage: "Import file",
  },
  importRegulatedClaims: {
    id: "document.importRegulatedClaims",
    defaultMessage: "Import regulated claims",
  },
  importFileInstruction1: {
    id: "document.importFile.instruction1",
    defaultMessage: "Download the CSV template to fill.",
  },
  importFileInstruction2: {
    id: "document.importFile.instruction2",
    defaultMessage:
      "Once the CSV template is filled, drag it into the zone below.",
  },
  importFileInstruction3: {
    id: "document.importFile.instruction3",
    defaultMessage: "Select a file to import.",
  },
  importFileInstruction4: {
    id: "document.importFile.instruction4",
    defaultMessage: 'Click on the "Import" button.',
  },
  importFileSucceeded: {
    id: "document.importFile.succeeded",
    defaultMessage: "Import succeeded",
  },
  importFileSucceededDescription: {
    id: "document.importFile.succeeded.description",
    defaultMessage: "Your file: {fileName} has been imported successfully.",
  },
  importFileFailed: {
    id: "document.importFile.failed",
    defaultMessage: "Import failed",
  },
  importFileFailedDescription: {
    id: "document.importFile.failed.description",
    defaultMessage:
      "Your file: {fileName} has not been imported. Click on the link to download the error file.",
  },
  errorFile: {
    id: "document.errorFile",
    defaultMessage: "Error file",
  },
  fileFormatOk: {
    id: "document.fileFormat.ok",
    defaultMessage: "File format: OK",
  },
  fileFormatNotOk: {
    id: "document.fileFormat.notOk",
    defaultMessage: "File format: Not OK",
  },
  importFileFailedInvalidFormat: {
    id: "document.importFile.failed.invalidFormat",
    defaultMessage:
      "The data import has failed due to invalid file format or missing required fields. Please check your file and try again.",
  },
  importFileWithErrors: {
    id: "document.importFile.withErrors",
    defaultMessage: "The CSV import is finished with errors.",
  },
});

export default messages;
