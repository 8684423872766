import { Route, RouteProps } from "react-router-dom";
import { useUserInfo } from "hooks";

const FAndVRoute = (props: RouteProps) => {
  const { hasFAndVAccess } = useUserInfo();

  if (!hasFAndVAccess) {
    return null;
  }

  return <Route {...props} />;
};

export default FAndVRoute;
