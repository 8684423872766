import ActionButton from "components/ActionButton";
import usePackagingSystemComponentsActions from "../../../usePackagingSystemComponentsActions";
import { PackagingComponentActionButtonProps } from "./types";

const PackagingComponentActionButton: React.FC<PackagingComponentActionButtonProps> = ({
  packagingSystemId,
  packagingSystemComponent,
  packagingComponentIds,
  packagingSystemAllowUpdate,
  mainComponentId,
  onOpenLinksDetailsModal,
}) => {
  const { actions } = usePackagingSystemComponentsActions({
    packagingSystemId,
    packagingSystemComponent,
    packagingComponentIds,
    packagingSystemAllowUpdate,
    mainComponentId,
    onOpenLinksDetailsModal,
  });

  return <ActionButton actions={actions} />;
};

export default PackagingComponentActionButton;
