import {
  RootNutrientName,
  UnroundedValue,
} from "components/SpecificationSection/components/FormSchema/components/NutritionBasic/shared/components";
import {
  NutrientTableDataItem,
  NutrientItemDictionary,
} from "components/SpecificationSection/components/FormSchema/components/NutritionBasic/types";
import { useColumnProps } from "components/SpecificationSection/components/FormSchema/components/NutritionBasic/shared/hooks";
import { DisplayToConsumer } from "./components";
import { useFormSchemaTranslation } from "pages/Specification/components/Details/hooks";
import useServingColumns from "../useServingColumns";
import { accessObjectPropertyWithPath } from "utils/general";
import { UseNutritionColumnsProps } from "./types";
import { NutrientItem, NutritionServingData } from "models";

const useNutritionColumns = ({
  statusNutrientFamilyDictionary,
  uiSchema,
  formData,
  formContext,
  schema,
  errorSchema,
  nutrientDictionary,
  mandatoryNutrientIds,
  extraActions,
  displayActions,
  onRemoveServing,
  onEditServing,
  hasUpdatePermission,
  hasAddServingPermission,
}: UseNutritionColumnsProps) => {
  const isNotRootNutrient = (nutrient: NutrientTableDataItem) =>
    !nutrient.hasOwnProperty("nutrientFamilyId");

  const { getTranslationForKey } = useFormSchemaTranslation();

  const existingNutrientIds = formData?.nutrients?.map(
    ({ nutrientId }) => nutrientId
  );

  const nutritionServings: NutritionServingData[] = formData?.servingDefinitions?.map(
    serving => {
      if (serving.defaultServing) {
        return { ...serving, name: getTranslationForKey("per100grTitle") };
      }
      return { ...serving };
    }
  );

  const { getColumnProps } = useColumnProps({
    schema,
    formData,
    uiSchema,
  });

  const getColumnErrors = (path: string) => {
    const errors: string[] = accessObjectPropertyWithPath({
      object: errorSchema,
      path,
    })?.__errors;

    return errors;
  };

  const servingColumns = useServingColumns({
    formData,
    uiSchema,
    nutritionServings,
    schema,
    nutrientDictionary,
    isNotRootNutrient,
    extraActions,
    formContext,
    getColumnErrors,
    onRemoveServing,
    onEditServing,
    hasUpdatePermission,
    hasAddServingPermission,
  });

  const columns = [
    {
      ...getColumnProps("nutrientId"),
      render: (value, nutrient: NutrientTableDataItem) => {
        if (isNotRootNutrient(nutrient)) {
          return (nutrientDictionary[value] as NutrientItemDictionary)?.name;
        }
        if (!statusNutrientFamilyDictionary?.[value]) {
          return null;
        }

        return (
          <RootNutrientName
            nutrientName={
              (nutrientDictionary[value] as NutrientItemDictionary)?.name
            }
            statusData={statusNutrientFamilyDictionary[value]}
          />
        );
      },
    },
    {
      ...getColumnProps("unitId"),
      render: value => nutrientDictionary[value],
      hasDivider: false,
    },
    {
      ...getColumnProps("unroundedValue"),
      render: (value, nutrient: NutrientTableDataItem) => {
        return (
          existingNutrientIds.includes(nutrient.nutrientId) &&
          isNotRootNutrient(nutrient) && (
            <UnroundedValue
              value={value}
              nutrientData={nutrient as NutrientItem}
              errors={getColumnErrors(`${nutrient.key}.unroundedValue`)}
              displayActions={displayActions}
              formData={formData}
            />
          )
        );
      },
      hasDivider: false,
    },
    ...servingColumns,
    {
      ...getColumnProps("displayToConsumer"),
      render: (value, nutrient: NutrientTableDataItem) => {
        return (
          existingNutrientIds.includes(nutrient.nutrientId) &&
          isNotRootNutrient(nutrient) && (
            <DisplayToConsumer
              formData={formData}
              disabled={mandatoryNutrientIds.includes(nutrient.nutrientId)}
              nutrientId={nutrient.nutrientId}
              onChange={extraActions?.updateNutritionDeclarationAction}
            />
          )
        );
      },
    },
  ];

  return {
    columns,
  };
};

export default useNutritionColumns;
