import { UserApplicationData } from "models";
import { APPLICATION_TYPE_IDS } from "./constants";
import { isFAndVOnly, isListEmpty } from "./general";

export const getIsRetailer = (companyActivity: string): boolean =>
  companyActivity === "1";
export const getIsSupplier = (companyActivity: string): boolean =>
  companyActivity === "2";
export const getIsTranslator = (companyActivity: string): boolean =>
  companyActivity === "18";

export const canAccessMasterData = (
  userApplications: UserApplicationData[]
): boolean => {
  if (isListEmpty(userApplications)) {
    return false;
  }

  return (
    userApplications.findIndex(
      ({ applicationTypeId }) =>
        applicationTypeId === APPLICATION_TYPE_IDS.MASTER_DATA
    ) > -1
  );
};

export const canAccessFAndV = ({
  isRetailer,
}: {
  isRetailer: boolean;
}): boolean => {
  if (!isFAndVOnly) {
    return true;
  }

  return isFAndVOnly && isRetailer;
};
