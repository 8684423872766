import { useMemo } from "react";
import { useLocation } from "react-router-dom";
import { Row, Col } from "antd";
import classnames from "classnames";
import {
  Pagination,
  ToggleButtons,
  EmptyState,
  Search,
  Picker,
  Heading,
  Typography,
} from "@trace-one/design-system";
import { useIntl } from "react-intl";
import Spin from "components/Spin";
import TableHeader from "components/TableHeader";
import { getNumberPageDisplayed, shouldPaginationBeDisplay } from "utils/table";
import {
  LIBRARY_OBJECTS_VIEWS,
  LIBRARY_OBJECTS_VIEW_SIZES,
  LIBRARY_OBJECT_TYPES,
} from "utils/constants";
import { isListEmpty } from "utils/general";
import MosaicView from "./components/MosaicView";
import LibraryListView from "./components/ListView";
import CreateButton from "./components/CreateButton/";
import Filters from "components/Filters";
import SelectedLibraryItems from "./components/SelectedLibraryItems";
import ImportClaim from "./components/ImportClaim";
import { ClaimFilters } from "./components";
import ConditionalWrapper from "components/ConditionalWrapper";
import { LibraryContext } from "./contexts";
import { useLibraryItemTypeDictionary, useUserPermission } from "hooks";
import { useFilters, useLibraryList, useSwitchView } from "./hooks";
import {
  getChaptersForFilter,
  getSearchPlaceholderMessage,
  shouldDisplayActions,
  shouldDisplayCreateButton,
  shouldDisplayImportButton,
  shouldDisplayClaimStatusFilter,
  shouldNoSearchResultMessageBeDisplayed,
} from "./utils";
import { ListProps } from "./types";
import { messages } from "./messages";
import filterMessages from "messages/filters";
import { generalMessages, libraryMessages } from "messages";
import { LibraryTypeLabelMap } from "./components/ListView/utils";
import styles from "./styles.module.less";

const LibraryTitleDescriptionMap = {
  [LIBRARY_OBJECT_TYPES.NUTRITIONAL_CLAIMS]: {
    title: libraryMessages.nutritionalClaims,
    description: messages.nutritionalClaimTabDescription,
  },
};

const List: React.FC<ListProps> = ({
  areItemsClickable,
  excludeDrafts,
  disabledLibraryItems,
  displayActions: defaultDisplayActions,
  hasCreateButton,
  hasSwitchView,
  selectedLibraryItems,
  tooltipTitle,
  type,
  view,
  onItemClick,
  historyId,
  extraParams,
  extraActionComponent,
  hideSearchBar,
  hideChapterFilters = false,
  excludedLibraryItemIds,
  displaySelectedLibraryItems = false,
  searchPlaceholder,
}) => {
  const { formatMessage } = useIntl();
  const { viewType, viewList, onSwitchViewChange } = useSwitchView();
  const { pathname } = useLocation();
  const isLibraryListingPage = pathname.includes("/list");

  const { claimsRegulationAdministrate } = useUserPermission();
  const { typeDictionary, chapterTypes } = useLibraryItemTypeDictionary({
    type,
  });

  const chapters = useMemo(() => getChaptersForFilter(chapterTypes), [
    chapterTypes,
  ]);

  const {
    filters,
    searchTerm,
    defaultFilters,
    updateFilter,
    onPaginationChange,
    onSearch,
    onSearchChange,
    setOrderBy,
    setOrderByDescending,
    setRequirementType,
    setChapterTypes,
    setStates,
  } = useFilters({
    type,
    extraParams: extraParams,
    isLibraryListingPage,
  });

  const {
    isListLoading,
    libraryList,
    totalCount,
    refreshTypeList,
  } = useLibraryList({
    excludeDrafts,
    historyId,
    filters,
    updateFilter,
    excludedLibraryItemIds,
    typeDictionary,
    isLibraryListingPage,
    displaySelectedLibraryItems,
  });

  const LIBRARY_TYPE_WITHOUT_SEARCH_BAR = [
    LIBRARY_OBJECT_TYPES.PACKAGING_SYSTEMS,
    LIBRARY_OBJECT_TYPES.REGULATED_HEALTH_CLAIMS,
  ];

  const showSearchBar =
    !hideSearchBar && !LIBRARY_TYPE_WITHOUT_SEARCH_BAR.includes(type);

  const displayActions = useMemo(
    () => shouldDisplayActions({ type, defaultDisplayActions }),
    [defaultDisplayActions, type]
  );

  const claimFilterProps = {
    type,
    displayStatusFilter: shouldDisplayClaimStatusFilter({
      type,
      isLibraryListingPage,
      claimsRegulationAdministrate,
    }),
    stateOptions: defaultFilters.statuses,
    selectedStates: filters.states,
    setStates,
    selectedNutrientIds: filters.nutrientIds,
    selectedSubstanceIds: filters.substanceIds,
    updateFilter,
    isLibraryListingPage: isLibraryListingPage,
  };

  const libraryObjectFilterMap = {
    [LIBRARY_OBJECT_TYPES.REQUIREMENTS]: (
      <>
        <Col>
          <Filters.RequirementType
            getSelectedPickerItems={setRequirementType}
          />
        </Col>
        <Col>
          <Picker.FilterV1
            title={formatMessage(filterMessages.status)}
            getSelectedPickerItems={setStates}
            data={defaultFilters.statuses}
            key="requirement-search-filter-status"
          />
        </Col>
      </>
    ),
    ...(!hideChapterFilters && {
      [LIBRARY_OBJECT_TYPES.CHAPTERS]: (
        <>
          <Col>
            <Picker.FilterV1
              title={formatMessage(filterMessages.chapterType)}
              getSelectedPickerItems={setChapterTypes}
              data={chapters}
              key="chapter-search-filter-chapter-type"
            />
          </Col>
          <Col>
            <Picker.FilterV1
              title={formatMessage(filterMessages.status)}
              getSelectedPickerItems={setStates}
              data={defaultFilters.statuses}
              key="chapter-search-filter-status"
            />
          </Col>
        </>
      ),
    }),
    [LIBRARY_OBJECT_TYPES.CLAIMS]: (
      <ClaimFilters
        {...claimFilterProps}
        selectedClaimTypes={filters.claimTypes}
        key="filter-claims"
      />
    ),
    [LIBRARY_OBJECT_TYPES.NUTRITIONAL_CLAIMS]: (
      <ClaimFilters {...claimFilterProps} key="filter-nutritional-claims" />
    ),
  };

  const renderPrimaryAction = () => (
    <>
      {shouldDisplayImportButton({ type }) && claimsRegulationAdministrate ? (
        <Col data-testid={`import-${type}`}>
          <ImportClaim
            claimType={type}
            refreshTypeList={() => refreshTypeList(true)}
          />
        </Col>
      ) : (
        shouldDisplayCreateButton({
          hasCreateButton,
          type,
        }) && (
          <Col>
            <CreateButton type={type} />
          </Col>
        )
      )}
    </>
  );

  const renderListTitleAndDiscription = (type: LIBRARY_OBJECT_TYPES) => {
    const { title, description } = LibraryTitleDescriptionMap[type] || {};

    return (
      <div className="mb-2">
        {title && (
          <Typography variant="heading-m" color="grey-5" className="mb-1">
            {formatMessage(LibraryTitleDescriptionMap[type]?.title)}
          </Typography>
        )}
        {description && (
          <Typography variant="body-regular" color="grey-5">
            {formatMessage(LibraryTitleDescriptionMap[type]?.description)}
          </Typography>
        )}
      </div>
    );
  };

  const searchPlaceholderMessage = getSearchPlaceholderMessage({
    libraryItemType: type,
    searchPlaceholder,
    formatMessage,
  });

  return (
    <div
      className={classnames(styles.libraryListContainer, {
        [styles["libraryListContainer__height"]]: displaySelectedLibraryItems,
      })}
    >
      {renderListTitleAndDiscription(type)}
      <Row className={styles.bottomSpaceSearchAndCreate}>
        <Col span={12}>
          {showSearchBar && (
            <>
              <Row data-testid="search-bar">
                <Col span={24}>
                  <Search
                    placeholder={searchPlaceholderMessage}
                    onSearch={onSearch}
                    onChange={onSearchChange}
                    value={searchTerm}
                    data-testid="search"
                  />
                </Col>
              </Row>
              {libraryObjectFilterMap?.[type] ? (
                <Row
                  data-testid="search-filters"
                  gutter={[8, 0]}
                  className={styles.searchFilters}
                >
                  {libraryObjectFilterMap?.[type]}
                </Row>
              ) : null}
            </>
          )}
        </Col>
        <Col span={12}>
          <Row justify="end">{renderPrimaryAction()}</Row>
        </Col>
      </Row>
      <Row
        className={styles.toggleViewBar}
        justify="space-between"
        align="middle"
      >
        {hasSwitchView && !isListEmpty(libraryList) && (
          <>
            <Col span={12}>
              <Typography variant="body-s" color="grey-4">
                {formatMessage(generalMessages.listingResultNumber, {
                  current: getNumberPageDisplayed({
                    totalNumber: totalCount,
                    current: filters.currentPage,
                    pageSize: filters.pageSize,
                  }),
                  total: totalCount,
                  libraryObjectType:
                    LibraryTypeLabelMap[type] &&
                    formatMessage(LibraryTypeLabelMap[type])?.toLowerCase(),
                })}
              </Typography>
            </Col>
            <Col span={12}>
              <ToggleButtons
                value={viewType}
                onChange={onSwitchViewChange}
                options={viewList}
                className="jc-flex-end"
              />
            </Col>
          </>
        )}
      </Row>

      {shouldNoSearchResultMessageBeDisplayed({
        isListLoading,
        totalCount,
        searchFilterName: filters.name,
      }) && !displaySelectedLibraryItems ? (
        <div className={styles.emptyContainer}>
          <EmptyState heading={formatMessage(messages.noItemFound)} />
        </div>
      ) : (
        <LibraryContext.Provider
          value={{
            areItemsClickable,
            disabledLibraryItems,
            displayActions,
            isListLoading,
            libraryList,
            orderBy: filters.orderBy,
            orderByDescending: filters.orderByDescending,
            pageSize: filters.pageSize,
            currentPage: filters.currentPage,
            totalCount: totalCount,
            selectedLibraryItems,
            setOrderBy,
            setOrderByDescending,
            tooltipTitle,
            type,
            view,
            onItemClick,
            refreshTypeList,
            typeDictionary,
          }}
        >
          {displaySelectedLibraryItems && (
            <Row>
              <Col span={16}>
                <TableHeader
                  listResult={formatMessage(
                    generalMessages.listingResultNumber,
                    {
                      current: libraryList?.length,
                      total: totalCount,
                      libraryObjectType: formatMessage(
                        libraryMessages.claimsCountLabel
                      ),
                    }
                  )}
                  color="grey-4"
                />
              </Col>
              <Col span={8} className="pl-2">
                <Heading size="xs" color="grey-5">
                  {formatMessage(messages.libraryItemSelectionTitle)}
                </Heading>
              </Col>
            </Row>
          )}

          <ConditionalWrapper
            condition={displaySelectedLibraryItems}
            wrapper={children => (
              <div className="d-flex overflow-auto h-100">{children}</div>
            )}
          >
            <>
              <div
                className={classnames(styles.scrollableContainer, {
                  [styles[
                    "library-list__scrollable-container--overflow"
                  ]]: isLibraryListingPage,
                  "hide-scrollbar": !isLibraryListingPage,
                })}
              >
                <Spin spinning={isListLoading}>
                  {isListEmpty(libraryList) ? (
                    <div className={styles.emptyContainer}>
                      <EmptyState
                        heading={formatMessage(messages.noItemFound)}
                      />
                    </div>
                  ) : (
                    <>
                      {viewType === LIBRARY_OBJECTS_VIEWS.MOSAIC ? (
                        <MosaicView />
                      ) : (
                        <LibraryListView />
                      )}
                    </>
                  )}
                </Spin>
              </div>

              {displaySelectedLibraryItems && (
                <Col xs={8} className="mb-3">
                  <SelectedLibraryItems />
                </Col>
              )}
            </>
          </ConditionalWrapper>
        </LibraryContext.Provider>
      )}
      <Row
        className={classnames({
          [styles[
            "library-list__pagination--min-height"
          ]]: displaySelectedLibraryItems,
        })}
      >
        <Col span={displaySelectedLibraryItems ? 16 : 24}>
          <Row justify="end">
            <Pagination
              hideOnSinglePage={!shouldPaginationBeDisplay(totalCount)}
              showSizeChanger
              current={filters.currentPage}
              pageSize={filters.pageSize}
              total={totalCount}
              onShowSizeChange={onPaginationChange}
              onChange={onPaginationChange}
            />
          </Row>
        </Col>
      </Row>
      {extraActionComponent && (
        <div className={styles.bottomExtraActionSpace}>
          {extraActionComponent}
        </div>
      )}
    </div>
  );
};

List.defaultProps = {
  areItemsClickable: false,
  disabledLibraryItems: [],
  displayActions: true,
  hasCreateButton: true,
  hasSwitchView: true,
  selectedLibraryItems: [],
  tooltipTitle: "",
  view: LIBRARY_OBJECTS_VIEW_SIZES.LARGE,
};

export default List;
