import { useEffect } from "react";
import { Col, Row } from "antd";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import {
  selectAllergens,
  selectCertificateIds,
  selectCertificates,
  selectFileIds,
  selectFiles,
  selectMaterialInformation,
  selectRawMaterial,
  selectRecipe,
  selectSubstanceId,
  selectSuppliers,
  selectUpdateStatus,
} from "store/materialForm/selectors";
import Spin from "components/Spin";
import { isLibraryObjectCreatePage, isLibraryObjectDraft } from "utils/library";
import { useLibraryFormActions } from "components/Library/hooks";
import { useMaterialFormActions, useMaterialFormInitialisation } from "./hooks";
import MaterialInfo from "./components/MaterialInfo";
import Configure from "./components/Configure";
import libraryMessages from "messages/library";
import { MaterialFormProps } from "./types";

export const MaterialForm: React.FC<MaterialFormProps> = ({
  initialValues = {},
  versionNumber = 0,
  page,
  updateActionsInformation = () => {},
}) => {
  const { formatMessage } = useIntl();
  const materialInformation = useSelector(selectMaterialInformation);
  const rawMaterial = useSelector(selectRawMaterial);
  const recipe = useSelector(selectRecipe);
  const suppliers = useSelector(selectSuppliers);
  const allergens = useSelector(selectAllergens);
  const fileIds = useSelector(selectFileIds);
  const files = useSelector(selectFiles);
  const substanceId = useSelector(selectSubstanceId);
  const certificateIds = useSelector(selectCertificateIds);
  const certificates = useSelector(selectCertificates);
  const { inProgress: isUpdateInProgress } = useSelector(selectUpdateStatus);

  const { isLoading } = useMaterialFormInitialisation({
    initialFormData: initialValues,
  });

  const { areActionButtonsDisabled, onFieldsChange } = useLibraryFormActions({
    requiredFields: ["name", "materialType"],
    isUpdateInProgress,
    libraryItem: materialInformation,
  });

  const { onKeepAsDraft, onPublish } = useMaterialFormActions({
    page,
  });

  useEffect(() => {
    updateActionsInformation({
      props: {
        disabled: areActionButtonsDisabled,
        items: [
          ...(isLibraryObjectDraft(materialInformation.state)
            ? [
                {
                  name: formatMessage(libraryMessages.publishButton),
                  label: formatMessage(libraryMessages.publishButton),
                  onClick: onPublish,
                },
              ]
            : []),
          ...(isLibraryObjectCreatePage(page)
            ? [
                {
                  name: formatMessage(libraryMessages.keepAsDraftButton),
                  label: formatMessage(libraryMessages.keepAsDraftButton),
                  onClick: onKeepAsDraft,
                },
              ]
            : []),
        ],
      },
      ...(!isLibraryObjectDraft(materialInformation.state) && {
        onClick: null,
      }),
    });
  }, [areActionButtonsDisabled, materialInformation?.state]);

  if (isLoading) return <Spin />;

  return (
    <>
      <Row gutter={[20, 0]}>
        <Col xs={24}>
          <MaterialInfo
            materialInformation={materialInformation}
            onFieldsChange={onFieldsChange}
            versionNumber={versionNumber}
            page={page}
          />
        </Col>
        <Col xs={24}>
          <Configure
            rawMaterial={rawMaterial}
            recipe={recipe}
            suppliers={suppliers}
            allergens={allergens}
            materialId={materialInformation.id}
            materialType={materialInformation.materialType}
            recipeType={materialInformation.recipeType}
            state={materialInformation.state}
            fileIds={fileIds}
            files={files}
            substanceId={substanceId}
            certificateIds={certificateIds}
            certificates={certificates}
          />
        </Col>
      </Row>
    </>
  );
};

export default MaterialForm;
