import useCatalogDictionary from "hooks/useCatalogDictionary";
import { ChapterAllergenData, MaterialData } from "models";
import { useEffect, useState } from "react";
import { getRawMaterialAllergensAndCrossContamination } from "utils/material";

const useChapterAllergens = ({
  materialData,
}: {
  materialData?: MaterialData;
}) => {
  const [rawMaterialsAllergens, setRawMaterialsAllergens] = useState<
    ChapterAllergenData[]
  >([]);
  const [
    crossContaminationAllergens,
    setCrossContaminationAllergens,
  ] = useState<ChapterAllergenData[]>([]);
  const [categoryItemsIds, setCategoryItemsIds] = useState<string[]>([]);

  useCatalogDictionary({ catalogAllergenIds: categoryItemsIds });

  useEffect(() => {
    if (materialData) {
      const {
        rawMaterialsAllergens,
        crossContaminationAllergens,
      } = getRawMaterialAllergensAndCrossContamination({ materialData });

      setRawMaterialsAllergens(rawMaterialsAllergens);
      setCrossContaminationAllergens(crossContaminationAllergens);
    }
  }, [materialData]);

  useEffect(() => {
    if (
      Array.isArray(rawMaterialsAllergens) &&
      Array.isArray(crossContaminationAllergens)
    ) {
      setCategoryItemsIds(
        [...rawMaterialsAllergens, ...crossContaminationAllergens].map(
          ({ allergenId }) => allergenId
        )
      );
    }
  }, [rawMaterialsAllergens?.length, crossContaminationAllergens?.length]);

  return {
    crossContaminationAllergens,
    rawMaterialsAllergens,
  };
};

export default useChapterAllergens;
