export const shouldShowErrorPage = ({
  pathname,
  hasFAndVAccess,
  hasClaimsAccess,
}: {
  pathname: string;
  hasFAndVAccess?: boolean;
  hasClaimsAccess?: boolean;
}): boolean => {
  if (pathname.includes("library") && !hasFAndVAccess) {
    return true;
  }
  if (pathname.includes("claims") && !hasClaimsAccess) {
    return true;
  }
  return false;
};
