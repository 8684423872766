import { defineMessages } from "react-intl";

export const otherAppsMessages = defineMessages({
  seeInMasterData: {
    id: "masterData.seeIn",
    defaultMessage: "See in Master Data",
  },
  seeProductInMasterData: {
    id: "masterData.seeProductIn",
    defaultMessage: "See product in Master Data",
  },
});
