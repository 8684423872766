import { useState } from "react";
import { Button, EmptyState } from "@trace-one/design-system";
import { ModalAssociate, ModalService } from "@trace-one/react-components";
import ConfirmationTooltip from "components/ConfirmationTooltip";
import { Row, Col } from "antd";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { PackagingSystem } from "@trace-one/business-components";
import {
  selectLanguageCode,
  selectOwningCompanyId,
} from "store/user/selectors";
import RowTitle from "components/RowTitle";
import PackagingSystemTable from "./components/PackagingSystemTable";
import { usePackagingSystem } from "hooks";
import { PackagingSystemTableContext } from "./contexts";
import { isObjectEmpty } from "utils/general";
import { PackagingSystemWidgetProps } from "./types";
import { messages } from "./messages";
import generalMessages from "messages/general";
import formSchemaStyles from "components/SpecificationSection/components/FormSchema/styles.module.less";
import { packagingMessages } from "messages";
import styles from "./styles.module.less";

const PackagingSystemWidget: React.FC<PackagingSystemWidgetProps> = ({
  formContext,
  formData,
}) => {
  const { formatMessage } = useIntl();
  const {
    displayActions,
    refListDictionary,
    isWidgetEditable,
    saveTemplateBlock,
    chapterData,
    specificationData,
  } = formContext;

  const languageCode = useSelector(selectLanguageCode);
  const ownerCompanyId = useSelector(selectOwningCompanyId);

  const { packagingSystemData, packagingComponents } = usePackagingSystem({
    packagingSystemId: formData.packagingSystemId,
  });

  const [showCreateAndAssociate, setShowCreateAndAssociate] = useState(false);

  const hasAddPackagingSystemPermission = isWidgetEditable();

  const onAssociatePackaingSystem = () => {
    ModalService.openModal({
      component: (
        <ModalAssociate
          languageCode={languageCode}
          onAssociate={packagingSystemIds =>
            saveTemplateBlock(
              JSON.stringify({ packagingSystemId: packagingSystemIds[0] })
            )
          }
          modalAssociateLabels={{
            title: formatMessage(messages.modalAssociateTitle),
            subTitle: formatMessage(messages.modalAssociateSubtitle),
          }}
          ownerCompanyId={ownerCompanyId}
          isLatestOnly
        />
      ),
    });
  };

  if (showCreateAndAssociate) {
    return (
      <PackagingSystem
        languageCode={languageCode}
        onAssociate={packagingSystemIds => {
          saveTemplateBlock(
            JSON.stringify({ packagingSystemId: packagingSystemIds[0] })
          );
          setShowCreateAndAssociate(false);
        }}
        ownerCompanyId={ownerCompanyId}
        closePackagingSystemCreation={() => {
          setShowCreateAndAssociate(false);
        }}
      />
    );
  }

  if (isObjectEmpty(formData)) {
    return (
      <>
        <Row justify="center">
          <EmptyState
            heading={formatMessage(messages.noPackagingSystemAdded)}
          />
        </Row>
        {displayActions && hasAddPackagingSystemPermission && (
          <Row justify="center" className={formSchemaStyles.rowAddButton}>
            <Col>
              <Button.Split
                data-testid="add-a-packaging-system-btn"
                type="primary"
                onClick={() => {
                  onAssociatePackaingSystem();
                }}
                items={[
                  {
                    key: "see-all-requests",
                    label: formatMessage(packagingMessages.createAndAssociate),
                    onClick: () => {
                      setShowCreateAndAssociate(true);
                    },
                  },
                ]}
              >
                {formatMessage(messages.addAPackagingSystem)}
              </Button.Split>
            </Col>
          </Row>
        )}
      </>
    );
  }

  return (
    <Row gutter={[10, 0]}>
      <Col span={24}>
        <div className={styles.buttonBar}>
          <RowTitle
            title={packagingSystemData?.name}
            version={packagingSystemData?.version?.number}
            displayActions={displayActions}
          />
          {hasAddPackagingSystemPermission && (
            <ConfirmationTooltip
              onConfirmation={() => saveTemplateBlock(JSON.stringify({}))}
            >
              <Button
                data-testid="remove-a-packaging-system-btn"
                type="tertiary"
                color="red"
              >
                {formatMessage(generalMessages.remove)}
              </Button>
            </ConfirmationTooltip>
          )}
        </div>
      </Col>
      <Col span={24}>
        <PackagingSystemTableContext.Provider
          value={{
            displayActions,
            hasAddPackagingSystemPermission,
            chapterData,
            specificationData,
          }}
        >
          <PackagingSystemTable
            packagingComponents={packagingComponents}
            refListDictionary={refListDictionary}
          />
        </PackagingSystemTableContext.Provider>
      </Col>
    </Row>
  );
};

export default PackagingSystemWidget;
