import { v4 } from "uuid";
import { isEqual } from "lodash";
import cleanDeep from "clean-deep";
import { isListEmpty } from "utils/general";
import {
  AdditionalNetContent,
  NetContent,
  ProductInformationFormData,
  ProductInformationNetContentItem,
} from "../../types";

export const updateListWithEMark = ({
  list,
  originalItem,
}: {
  list:
    | ProductInformationFormData["additionalNetContents"]
    | ProductInformationFormData["netContents"];
  originalItem: AdditionalNetContent | NetContent;
}):
  | ProductInformationFormData["additionalNetContents"]
  | ProductInformationFormData["netContents"] => {
  const cleanedList = cleanDeep(list);
  if (isListEmpty(cleanedList)) {
    return [];
  }

  let isUpdated = false;

  return (cleanedList ?? [])?.map((item: AdditionalNetContent | NetContent) => {
    if (!isUpdated && isEqual(item, originalItem)) {
      isUpdated = true;
      return { ...item, eMark: true }; // Set eMark to true for the first match
    }
    return { ...item, eMark: false }; // Set eMark to false for all other items
  });
};

export const getNetContentOptions = ({
  list,
  listKey,
  formDataKey,
}: {
  list:
    | ProductInformationFormData["additionalNetContents"]
    | ProductInformationFormData["netContents"];
  listKey: keyof AdditionalNetContent | keyof NetContent;
  formDataKey: keyof ProductInformationFormData;
}): {
  id: string;
  originalItem: AdditionalNetContent | NetContent;
  type: keyof ProductInformationFormData;
  unit: ProductInformationNetContentItem["unit"];
  value: ProductInformationNetContentItem["value"];
}[] => {
  const cleanedList = cleanDeep(list);
  if (isListEmpty(cleanedList)) {
    return [];
  }

  return (
    cleanedList
      ?.map((item: AdditionalNetContent | NetContent) =>
        item[listKey]
          ? {
              id: v4(),
              originalItem: item,
              type: formDataKey,
              unit: item[listKey]?.unit,
              // netContents returns value as string and additionalNetContents returns value as number
              value: parseFloat(item[listKey]?.value),
            }
          : null
      )
      .filter(Boolean) || []
  );
};

export const getProductInformationPayload = ({
  formData,
  type,
  originalItem,
}: {
  formData: ProductInformationFormData;
  type: keyof ProductInformationFormData;
  originalItem: AdditionalNetContent | NetContent;
}): ProductInformationFormData =>
  // only update the relevant list and ensure the other list has eMark set to false
  ({
    ...formData,
    [type]: updateListWithEMark({
      list: formData[type as string],
      originalItem,
    }),
    ...(type === "netContents"
      ? {
          additionalNetContents: updateListWithEMark({
            list: formData?.additionalNetContents,
            originalItem: null,
          }),
        }
      : {
          netContents: updateListWithEMark({
            list: formData?.netContents,
            originalItem: null,
          }),
        }),
  });
