import { createSlice } from "@reduxjs/toolkit";
import { PURGE } from "redux-persist";
import initialState from "./initialState";
import {
  addTag,
  getChapter,
  removeTag,
  resetChapterStructure,
  updateBlock,
  updateName,
  updateProductType,
  updateType,
  updateChapterNutrition,
  addChapterFreeFromAllergens,
  removeChapterFreeFromAllergen,
  updateChapterFreeFromAllergen,
} from "./asyncActions";
import reducers from "./reducers";
import {
  handleActionFulfilled,
  handleActionPending,
  handleActionRejected,
} from "../helpers";
import { isObjectEmpty } from "utils/general";

export const slice = createSlice({
  name: "chapterForm",
  initialState,
  reducers,
  extraReducers: builder => {
    /* istanbul ignore next*/
    builder.addCase(PURGE, () => ({ ...initialState }));

    builder.addCase(updateName.pending, state => {
      handleActionPending(state.updateStatus);
    });
    builder.addCase(updateName.fulfilled, (state, action) => {
      if (!isObjectEmpty(action.payload)) {
        state.generalInfo = action.payload.generalInfo;
        state.progressState = action.payload.progressState;
        state.sections = action.payload.sections;
        if (action.payload.structure) {
          state.structure = action.payload.structure;
        }
      } else {
        state.generalInfo.name = action.meta.arg;
      }
      handleActionFulfilled(state.updateStatus);
    });
    builder.addCase(updateName.rejected, (state, action) => {
      state.generalInfo.name = action.meta.arg;
      handleActionRejected(state.updateStatus);
    });

    builder.addCase(updateType.pending, state => {
      handleActionPending(state.updateStatus);
    });
    builder.addCase(updateType.fulfilled, (state, action) => {
      if (!isObjectEmpty(action.payload)) {
        state.generalInfo = action.payload.generalInfo;
        state.progressState = action.payload.progressState;
        state.sections = action.payload.sections;
        state.structure = action.payload.structure;
      } else {
        state.generalInfo.type = action.meta.arg;
      }

      handleActionFulfilled(state.updateStatus);
    });
    builder.addCase(updateType.rejected, state => {
      handleActionRejected(state.updateStatus);
    });

    builder.addCase(updateProductType.pending, state => {
      handleActionPending(state.updateStatus);
    });
    builder.addCase(updateProductType.fulfilled, (state, action) => {
      if (!isObjectEmpty(action.payload)) {
        state.generalInfo = action.payload.generalInfo;
        state.progressState = action.payload.progressState;
        state.sections = action.payload.sections;
        state.structure = action.payload.structure;
      } else {
        state.generalInfo.productTypeId = action.meta.arg;
      }

      handleActionFulfilled(state.updateStatus);
    });
    builder.addCase(updateProductType.rejected, (state, action) => {
      state.generalInfo.productTypeId = action.meta.arg;
      handleActionRejected(state.updateStatus);
    });

    builder.addCase(addTag.pending, state => {
      handleActionPending(state.updateStatus);
    });
    builder.addCase(addTag.fulfilled, (state, action) => {
      if (!isObjectEmpty(action.payload)) {
        state.generalInfo = action.payload.generalInfo;
        state.progressState = action.payload.progressState;
        state.sections = action.payload.sections;
      } else {
        state.generalInfo.tagIds.push(action.meta.arg);
      }
      handleActionFulfilled(state.updateStatus);
    });
    builder.addCase(addTag.rejected, state => {
      handleActionRejected(state.updateStatus);
    });

    builder.addCase(removeTag.pending, state => {
      handleActionPending(state.updateStatus);
    });
    builder.addCase(removeTag.fulfilled, (state, action) => {
      if (!isObjectEmpty(action.payload)) {
        state.generalInfo = action.payload.generalInfo;
        state.progressState = action.payload.progressState;
        state.sections = action.payload.sections;
      } else {
        state.generalInfo.tagIds = state.generalInfo.tagIds.filter(
          currentTag => currentTag !== action.meta.arg
        );
      }
      handleActionFulfilled(state.updateStatus);
    });
    builder.addCase(removeTag.rejected, state => {
      handleActionRejected(state.updateStatus);
    });

    builder.addCase(updateBlock.pending, state => {
      handleActionPending(state.updateStatus);
    });
    builder.addCase(updateBlock.fulfilled, (state, action) => {
      handleActionFulfilled(state.updateStatus);
    });
    builder.addCase(updateBlock.rejected, state => {
      handleActionRejected(state.updateStatus);
    });

    builder.addCase(getChapter.fulfilled, (state, action) => {
      state.generalInfo = action.payload.generalInfo;
      state.progressState = action.payload.progressState;
      state.sections = action.payload.sections;
      state.structure = action.payload.structure;
    });

    builder.addCase(resetChapterStructure.fulfilled, state => {
      state.generalInfo.id = null;
      state.progressState = null;
      state.sections = [];
      state.structure = [];
    });

    builder.addCase(updateChapterNutrition.pending, state => {
      handleActionPending(state.updateStatus);
    });
    builder.addCase(updateChapterNutrition.fulfilled, (state, action) => {
      state.generalInfo = action.payload.generalInfo;
      state.progressState = action.payload.progressState;
      state.sections = action.payload.sections;

      handleActionFulfilled(state.updateStatus);
    });
    builder.addCase(updateChapterNutrition.rejected, state => {
      handleActionRejected(state.updateStatus);
    });

    builder.addCase(addChapterFreeFromAllergens.pending, state => {
      handleActionPending(state.updateStatus);
    });
    builder.addCase(addChapterFreeFromAllergens.fulfilled, (state, action) => {
      state.generalInfo = action.payload.generalInfo;
      state.progressState = action.payload.progressState;
      state.sections = action.payload.sections;

      handleActionFulfilled(state.updateStatus);
    });
    builder.addCase(addChapterFreeFromAllergens.rejected, state => {
      handleActionRejected(state.updateStatus);
    });

    builder.addCase(removeChapterFreeFromAllergen.pending, state => {
      handleActionPending(state.updateStatus);
    });
    builder.addCase(
      removeChapterFreeFromAllergen.fulfilled,
      (state, action) => {
        state.generalInfo = action.payload.generalInfo;
        state.progressState = action.payload.progressState;
        state.sections = action.payload.sections;

        handleActionFulfilled(state.updateStatus);
      }
    );
    builder.addCase(removeChapterFreeFromAllergen.rejected, state => {
      handleActionRejected(state.updateStatus);
    });

    builder.addCase(updateChapterFreeFromAllergen.pending, state => {
      handleActionPending(state.updateStatus);
    });
    builder.addCase(
      updateChapterFreeFromAllergen.fulfilled,
      (state, action) => {
        state.generalInfo = action.payload.generalInfo;
        state.progressState = action.payload.progressState;
        state.sections = action.payload.sections;

        handleActionFulfilled(state.updateStatus);
      }
    );
    builder.addCase(updateChapterFreeFromAllergen.rejected, state => {
      handleActionRejected(state.updateStatus);
    });
  },
});

export const { setFormData } = slice.actions;

export default slice.reducer;
