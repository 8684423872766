import { useState } from "react";
import { EnhancedExtraActions } from "components/SpecificationSection/types";
import { findItemById, getAllNestedIds } from "utils/general";
import { NutritionFormData, NutrientItemData, NutrientItem } from "models";

export type UseNutritionActionProps = {
  deleteNutritionAction: EnhancedExtraActions["updateNutrition"];
  formData: NutritionFormData;
  nutrientsWithoutFamily?: NutrientItemData[];
};

const useNutrientAction = ({
  deleteNutritionAction,
  formData,
  nutrientsWithoutFamily = [],
}: UseNutritionActionProps) => {
  const [isTooltipVisible, setIsTooltipVisible] = useState(false);

  const onDeleteNutrient = (
    nutrientId: NutrientItem["nutrientId"]
  ) => async () => {
    const nutrient = findItemById(nutrientsWithoutFamily, nutrientId);

    const idsToBeRemoved = getAllNestedIds(nutrient);

    try {
      const updatedNutrients = formData?.nutrients?.filter(
        ({ nutrientId: id }) => !idsToBeRemoved.includes(id)
      );

      await deleteNutritionAction({ ...formData, nutrients: updatedNutrients });
    } catch {
    } finally {
      setIsTooltipVisible(false);
    }
  };

  const onTooltipVisibleChange = value => {
    if (!value) {
      setIsTooltipVisible(false);
    } else {
      setIsTooltipVisible(value);
    }
  };

  return {
    isTooltipVisible,
    onTooltipVisibleChange,
    onDeleteNutrient,
  };
};

export default useNutrientAction;
