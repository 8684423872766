import { useIntl } from "react-intl";
import ActionButton from "components/ActionButton";
import { PackagingSubstanceTableViewModel } from "viewModels";
import { substancesTableMessages } from "components/SubstancesTable/messages";
import { MenuActions } from "types/general";
import { useMemo } from "react";

export type SubstancesTableActionsProps = {
  substance: PackagingSubstanceTableViewModel;
  onRemove: (_: string) => Promise<void>;
  disabled: boolean;
  onOpenModal: () => void;
};

const SubstancesTableActions: React.FC<SubstancesTableActionsProps> = ({
  substance,
  onRemove,
  disabled,
  onOpenModal,
}) => {
  const { formatMessage } = useIntl();

  const actions: MenuActions[] = useMemo(
    () => [
      {
        label: formatMessage(substancesTableMessages.editSubstance),
        "data-test-id": "edit-substance",
        disabled,
        onClick: onOpenModal,
      },
      {
        label: formatMessage(substancesTableMessages.removeSubstance),
        "data-test-id": "delete-substance",
        disabled,
        tooltipProps: {
          placement: "left",
          text: formatMessage(
            substancesTableMessages.removeSubstanceConfirmation
          ),
          onConfirmation: async () => await onRemove(substance?.substanceId),
        },
      },
    ],
    [disabled, substance]
  );

  return <ActionButton actions={actions} disabled={disabled} />;
};

export default SubstancesTableActions;
