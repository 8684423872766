import { isListEmpty } from "utils/general";
import { BlockProps } from "../../types";
import { isSpecificationDetailsPage } from "utils/specifications";
import {
  MAX_QUEUE_ITEMS_IN_PROCESS_LIBRARY,
  MAX_QUEUE_ITEMS_IN_PROCESS_SPEC,
  MAX_QUEUE_ITEMS_IN_PROCESS_TESTING_CHAPTER,
  SECTION_CODE,
} from "utils/constants";
import {
  isLibraryObjectCreatePage,
  isLibraryObjectEditPage,
} from "utils/library";
import { PageType as SpecificationPageType } from "types/specification";
import { LibraryPageType } from "types/library";

const useBlocks = ({
  readOnlyFieldIds,
  hasPermissionToSave,
  displayActions,
  isTargetMode,
  page,
  queueLength,
  sectionCode,
}: BlockProps) => {
  const isWidgetEditable = (propertyId?: string) => {
    if (isTargetMode) return false;

    if (
      isSpecificationDetailsPage(page as SpecificationPageType) &&
      queueLength > MAX_QUEUE_ITEMS_IN_PROCESS_SPEC
    ) {
      return false;
    }

    if (
      isLibraryObjectEditPage(page as LibraryPageType) ||
      isLibraryObjectCreatePage(page as LibraryPageType)
    ) {
      const maxQueueLength = [
        SECTION_CODE.CHARACTERISTICS,
        SECTION_CODE.CONTROL_PLANS,
      ].includes(sectionCode as SECTION_CODE)
        ? MAX_QUEUE_ITEMS_IN_PROCESS_TESTING_CHAPTER
        : MAX_QUEUE_ITEMS_IN_PROCESS_LIBRARY;

      if (queueLength > maxQueueLength) {
        return false;
      }
    }

    if (!displayActions) return false;

    if (!hasPermissionToSave) return false;

    if (!propertyId) return true;

    if (isListEmpty(readOnlyFieldIds)) return true;

    return readOnlyFieldIds.indexOf(propertyId) === -1;
  };

  return {
    isWidgetEditable,
  };
};

export default useBlocks;
