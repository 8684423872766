import { Tooltip } from "@trace-one/design-system";
import classnames from "classnames";
import { ColumnsType } from "antd/lib/table";
import ActionButton from "components/ActionButton";
import IngredientFunctionDropdown from "components/IngredientFunctionDropdown";
import PercentageInput from "components/PercentageInput";
import ConditionalWrapper from "components/ConditionalWrapper";
import { getSortOrder } from "utils/pagination";
import { Dictionary } from "types/general";
import { Sorter } from "types/pagination";
import { MaterialRecipeIngredientViewModel } from "viewModels";
import { MaterialIngredientData } from "models";
import libraryMessages from "messages/library";
import generalMessages from "messages/general";
import messages from "./messages";
import styles from "components/Library/components/MaterialForm/styles.module.less";

export const getColumns = ({
  formatMessage,
  onRemoveIngredient,
  onUpdateIngredientPercentage,
  displayActions,
  disableActions,
  updateIngredientFunction,
  recipeSorter,
  canSort,
  catalogDictionary,
}: {
  formatMessage: Function;
  onRemoveIngredient?: (
    id: MaterialIngredientData["ingredientId"]
  ) => () => void;
  onUpdateIngredientPercentage?: (
    id: MaterialIngredientData["ingredientId"],
    functionId: MaterialIngredientData["ingredientId"]
  ) => (percentage: MaterialIngredientData["percentage"]) => void;
  displayActions?: boolean;
  disableActions?: boolean;
  updateIngredientFunction: Function;
  recipeSorter?: Sorter;
  canSort?: boolean;
  catalogDictionary?: Dictionary;
}): ColumnsType<MaterialRecipeIngredientViewModel> => [
  {
    title: formatMessage(libraryMessages["columns"]["name"]),
    dataIndex: "name",
    width: "20%",
    sorter: canSort,
    sortDirections: ["ascend", "descend", "ascend"],
    sortOrder:
      recipeSorter?.orderBy === "name"
        ? getSortOrder(recipeSorter?.orderByDescending)
        : null,
    render: (_, ingredient) => {
      return ingredient.name;
    },
  },
  {
    title: formatMessage(libraryMessages["columns"]["type"]),
    dataIndex: "type",
    width: "10%",
    sorter: canSort,
    sortDirections: ["ascend", "descend", "ascend"],
    sortOrder:
      recipeSorter?.orderBy === "type"
        ? getSortOrder(recipeSorter?.orderByDescending)
        : null,
    render: (_, ingredient) =>
      formatMessage(libraryMessages["type"][ingredient.type]),
  },
  {
    title: formatMessage(libraryMessages["columns"]["classification"]),
    dataIndex: "classificationId",
    width: "25%",
    render: (_, ingredient) => {
      return catalogDictionary?.[ingredient.classificationId];
    },
  },
  {
    title: (
      <span>
        %<span className={styles?.["recipe-table__percentage-required"]}></span>
      </span>
    ),
    dataIndex: "percentage",
    width: "10%",
    sorter: canSort,
    sortDirections: ["ascend", "descend", "ascend"],
    sortOrder:
      recipeSorter?.orderBy === "percentage"
        ? getSortOrder(recipeSorter?.orderByDescending)
        : null,
    render: (_, ingredient) => {
      const { percentage, relativePercentage } = ingredient;
      const value = relativePercentage ?? percentage;

      return displayActions ? (
        <PercentageInput
          defaultValue={value}
          onChange={onUpdateIngredientPercentage(
            ingredient.ingredientId,
            ingredient.functionId
          )}
          keepDefaultValue
          disabled={disableActions || !ingredient.firstLevel}
          precision={3}
        />
      ) : (
        value
      );
    },
  },
  {
    title: formatMessage(libraryMessages["columns"]["function"]),
    dataIndex: "function",
    className: styles.unsetPosition,
    render: (_, ingredient) =>
      displayActions && !!ingredient.firstLevel ? (
        <IngredientFunctionDropdown
          ingredient={ingredient}
          disableActions={disableActions}
          update={updateIngredientFunction}
        />
      ) : (
        catalogDictionary?.[ingredient.functionId]
      ),
  },
  {
    ...(displayActions && {
      key: "action",
      title: formatMessage(libraryMessages["columns"]["action"]),
      width: 100,
      render: (_, { ingredientId, firstLevel }) =>
        !!firstLevel && (
          <ActionButton
            {...(!disableActions && {
              actions: [
                {
                  label: formatMessage(generalMessages.remove),
                  tooltipProps: {
                    onConfirmation: onRemoveIngredient(ingredientId),
                  },
                },
              ],
            })}
            className={styles.actionColumnAlignment}
          />
        ),
    }),
  },
];

export const getProcessingAidIngredientsColumn = ({
  formatMessage,
  onRemoveIngredient,
  onUpdateIngredientPercentage,
  updateIngredientFunction,
  displayActions,
  disableActions,
  recipeSorter,
  canSort,
  catalogDictionary,
}: {
  formatMessage: Function;
  onRemoveIngredient?: (
    id: MaterialIngredientData["ingredientId"]
  ) => () => void;
  onUpdateIngredientPercentage?: (
    id: MaterialIngredientData["ingredientId"],
    functionId: MaterialIngredientData["ingredientId"]
  ) => (percentage: MaterialIngredientData["percentage"]) => void;
  updateIngredientFunction: Function;
  displayActions?: boolean;
  disableActions?: boolean;
  recipeSorter?: Sorter;
  canSort?: boolean;
  catalogDictionary?: Dictionary;
}): ColumnsType<MaterialRecipeIngredientViewModel> => [
  {
    title: formatMessage(libraryMessages["columns"]["name"]),
    dataIndex: "name",
    sorter: canSort,
    sortDirections: ["ascend", "descend", "ascend"],
    sortOrder:
      recipeSorter?.orderBy === "name"
        ? getSortOrder(recipeSorter?.orderByDescending)
        : null,
    render: (_, ingredient) => {
      return ingredient?.name;
    },
  },
  {
    title: "%",
    dataIndex: "percentage",
    width: "10%",
    sorter: canSort,
    sortDirections: ["ascend", "descend", "ascend"],
    sortOrder:
      recipeSorter?.orderBy === "percentage"
        ? getSortOrder(recipeSorter?.orderByDescending)
        : null,
    render: (_, ingredient) => {
      const { percentage, relativePercentage } = ingredient;
      const value = relativePercentage ?? percentage;

      return displayActions ? (
        <ConditionalWrapper
          condition={!ingredient?.firstLevel}
          wrapper={children => (
            <Tooltip
              placement="left"
              text={formatMessage(messages.processingAidsTableDisabledTooltip, {
                materialName: ingredient?.parentName,
              })}
            >
              <span className={styles.tooltipChildrenWrapper}>{children}</span>
            </Tooltip>
          )}
        >
          <PercentageInput
            defaultValue={value}
            onChange={onUpdateIngredientPercentage(
              ingredient?.ingredientId,
              ingredient?.functionId
            )}
            keepDefaultValue
            disabled={disableActions || !ingredient?.firstLevel}
            precision={3}
            required={false}
          />
        </ConditionalWrapper>
      ) : (
        value
      );
    },
  },
  {
    title: formatMessage(libraryMessages["columns"]["function"]),
    dataIndex: "function",
    className: styles.unsetPosition,
    width: "30%",
    render: (_, ingredient) =>
      displayActions && !!ingredient.firstLevel ? (
        <IngredientFunctionDropdown
          ingredient={ingredient}
          disableActions={disableActions}
          update={updateIngredientFunction}
        />
      ) : (
        catalogDictionary?.[ingredient.functionId]
      ),
  },
  {
    ...(displayActions && {
      key: "action",
      title: formatMessage(libraryMessages["columns"]["action"]),
      width: 100,
      render: (_, { ingredientId, firstLevel, parentName }) => (
        <ConditionalWrapper
          condition={!firstLevel}
          wrapper={children => (
            <Tooltip
              placement="left"
              text={formatMessage(messages.processingAidsTableDisabledTooltip, {
                materialName: parentName,
              })}
            >
              <span
                className={classnames(
                  styles.tooltipChildrenWrapper,
                  styles.actionColumnAlignment
                )}
              >
                {children}
              </span>
            </Tooltip>
          )}
        >
          <ActionButton
            {...(!disableActions && {
              actions: [
                {
                  label: formatMessage(generalMessages.remove),
                  tooltipProps: {
                    onConfirmation: onRemoveIngredient(ingredientId),
                  },
                },
              ],
              disabled: !firstLevel,
            })}
            className={classnames({
              [`${styles.actionColumnAlignment}`]: firstLevel,
            })}
          />
        </ConditionalWrapper>
      ),
    }),
  },
];
